import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png';
import { userStore } from '../../../core/userStore';
import Switch from '../../../core/switch';
import CustomModal from '../../../core/CustomModal';
import Messages from '../../messages/Messages';
import { translator } from '../../../core/languages/translator';

/**
 * Menu Mav component
 * @returns Nav Menu component
 */

export default function MenuNav(props) {
  const [menuLinks, setmenuLinks] = useState([]);
  const [menuOpen, setmenuOpen] = useState(false);
  const [msgsopen, setmsgsopen] = useState(false);
  const [notiOpen, setnotiOpen] = useState(false);
  const userId = userStore((state) => state.userId);
  const senderName = userStore((state) => state.senderName);
  const [search, setSearch] = useState('');
  const theme = userStore((state) => state.theme);
  const newmessage = userStore((state) => state.newmessage);
  const notifications = userStore((state) => state.notifications);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    if (userId > 0) {
      setmenuLinks([
        { title: 'Home', path: '/' },
        { title: 'Contact Us', path: '/contactus' },
        { title: 'Sell', path: '/sell' },
        { title: 'Chat', path: '/chat' },
        { title: `My Account`, path: '/account' },
        { title: 'Logout', path: '/logout' },
      ]);
    } else {
      setmenuLinks([
        { title: 'Home', path: '/' },
        { title: 'Contact Us', path: '/contactus' },
        { title: 'Login', path: '/login' },
        { title: 'Register', path: '/register' },
      ]);
    }
  }, [userId]);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light p-1 mt-0 mb-2"
      style={{
        // eslint-disable-next-line react/prop-types
        backgroundColor: theme === 'dark' ? 'black' : 'white',
        // eslint-disable-next-line react/prop-types
        color: theme === 'dark' ? 'white' : 'black',
      }}
    >
      <button
        id="navbarBtn"
        onClick={() => setmenuOpen(!menuOpen)}
        className="navbar-toggler"
        type="button"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: theme === 'dark' ? 'white' : 'black',
          // eslint-disable-next-line react/prop-types
          color: theme === 'dark' ? 'white' : 'black',
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="logo">
        <Link to="/">
          <img className="animated  rollIn" src={logo} alt="logo"></img>
          {/* {translator('kitab4less')} */}
        </Link>
      </div>

      <div
        className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`}
        id="navbarNav"
        style={{
          // eslint-disable-next-line react/prop-types
          backgroundColor: theme === 'dark' ? 'black' : 'white',
          // eslint-disable-next-line react/prop-types
          color: theme === 'dark' ? 'white' : 'black',
        }}
      >
        <form className="center">
          {/* <input type="text" placeholder="  Search..." className="search" onChange={handleSearch}></input> */}
          <input
            type="text"
            className={`border bg-${props?.theme === 'dark' ? 'dark' : 'black'}`}
            onChange={handleSearch}
            style={{
              // eslint-disable-next-line react/prop-types
              backgroundColor: theme === 'dark' ? 'white' : 'white',
              // eslint-disable-next-line react/prop-types
              color: theme === 'dark' ? 'white' : 'white',
            }}
          ></input>
          <Link to={`/search?q=${search}`}>
            <button className="button-85 search-btn" type="submit">
              {translator('Search')}
            </button>
          </Link>
        </form>
        <ul
          className="navbar-nav m-auto mt-2 mt-lg-0"
          style={{
            // eslint-disable-next-line react/prop-types
            backgroundColor: theme === 'dark' ? 'black' : 'white',
            // eslint-disable-next-line react/prop-types
            color: theme === 'dark' ? 'white' : 'black',
          }}
        >
          {userId > 0 && (
            <li
              className="nav-item p-2  pt-0"
              data-bs-toggle="modal"
              data-bs-target="#messages"
              href="#messages"
              role="button"
              onClick={() => {
                setmsgsopen(!msgsopen);
                userStore.setState({ newmessage: !msgsopen });
              }}
              style={{
                // eslint-disable-next-line react/prop-types
                backgroundColor: newmessage ? 'green' : theme === 'dark' ? 'black' : 'white',
                // eslint-disable-next-line react/prop-types
                color: theme === 'dark' ? 'white' : 'black',
                fontSize: 30,
              }}
            >
              <div href="#" dangerouslySetInnerHTML={{ __html: '&#9993;' }}></div>
            </li>
          )}
          {/* <li
            className="nav-item p-2  pt-0"
            data-bs-toggle="modal"
            data-bs-target="#notifications"
            href="#notifications"
            role="button"
            onClick={() => setmsgsopen(!msgsopen)}
            style={{
              // eslint-disable-next-line react/prop-types
              backgroundColor: theme === 'dark' ? 'black' : 'white',
              // eslint-disable-next-line react/prop-types
              color: theme === 'dark' ? 'white' : 'black',
              fontSize: 30,
            }}
          >
            <span href="#" dangerouslySetInnerHTML={{ __html: '&#9872;' }}></span>
          </li> */}
          {menuLinks.length > 0
            ? menuLinks.map((x) => {
                return (
                  <li
                    key={x.title + Math.random()}
                    className="nav-item"
                    style={{
                      // eslint-disable-next-line react/prop-types
                      backgroundColor: theme === 'dark' ? 'black' : 'white',
                      // eslint-disable-next-line react/prop-types
                      color: theme === 'dark' ? 'white' : 'black',
                    }}
                  >
                    <Link
                      className="nav-link  p-2 pb-0"
                      to={x.path}
                      style={{
                        // eslint-disable-next-line react/prop-types
                        backgroundColor: theme === 'dark' ? 'black' : 'white',
                        // eslint-disable-next-line react/prop-types
                        color: theme === 'dark' ? 'white' : 'black',
                      }}
                      aria-label={translator(x.title)}
                    >
                      {translator(x.title)}
                    </Link>
                  </li>
                );
              })
            : []}
        </ul>

        <Switch />
        <CustomModal
          steps={false}
          title1={translator('My Messages')}
          className="text-black"
          classid={'messages'}
          details1={<Messages className="col-md-10" />}
        />
      </div>
    </nav>
  );
}
