export const fullLangs = [
    { 'ar': ['تسجيل الدخول'], 'en': ['login'] },
    { 'ar': ['اشتراك'], 'en': ['register'] },
    { 'ar': ['الصفحة الرئيسية'], 'en': ['home'] },
    { 'ar': ['كتاب بسعر أقل'], 'en': ['kitab4less'] },
    { 'ar': ['حقوق النشر'], 'en': ['copyright'] },
    { 'ar': ['بريد إلكتروني'], 'en': ['email'] },
    { 'ar': ['تسجيل الدخول'], 'en': ['signin'] },
    { 'ar': ['اشتراك'], 'en': ['signup'] },
    { 'ar': ['اتصل بنا'], 'en': ['contact us'] },
    { 'ar': ['فشل'], 'en': ['failed'] },
    { 'ar': ['كلمة المرور'], 'en': ['password'] },
    { 'ar': ['خنق'], 'en': ['throttle'] },
    { 'ar': ['اسم المستخدم'], 'en': ['user name'] },
    { 'ar': ['سابق'], 'en': ['previous'] },
    { 'ar': ['التالي'], 'en': ['next'] },
    { 'ar': ['قبلت'], 'en': ['accepted'] },
    { 'ar': ['مقبول إذا'], 'en': ['accepted_if'] },
    { 'ar': ['عنوان url نشط'], 'en': ['active_url'] },
    { 'ar': ['بعد'], 'en': ['after'] },
    { 'ar': ['مجموعة مصفوفة'], 'en': ['array'] },
    { 'ar': ['قبل'], 'en': ['before'] },
    { 'ar': ['بين'], 'en': ['between'] },
    { 'ar': ['يبيع'], 'en': ['sell'] },
    { 'ar': ['منطقي'], 'en': ['boolean'] },
    { 'ar': ['مؤكد'], 'en': ['confirmed'] },
    { 'ar': ['كلمة السر الحالية'], 'en': ['current_password'] },
    { 'ar': ['تاريخ'], 'en': ['date'] },
    { 'ar': ['التاريخ يساوي'], 'en': ['date_equals'] },
    { 'ar': ['مختلف'], 'en': ['different'] },
    { 'ar': ['ينتهي بـ'], 'en': ['ends_with'] },
    { 'ar': ['موجود'], 'en': ['exists'] },
    { 'ar': ['ملف'], 'en': ['file'] },
    { 'ar': ['رفع'], 'en': ['upload'] },
    { 'ar': ['مملوء'], 'en': ['filled'] },
    { 'ar': ['موقع'], 'en': ['location'] },
    { 'ar': ['دولة'], 'en': ['country'] },
    { 'ar': ['ip'], 'en': ['ip'] },
    { 'ar': ['أحرف صغيرة'], 'en': ['lowercase'] },
    { 'ar': ['التمثيل الصامت'], 'en': ['mimes'] },
    { 'ar': ['ليس في'], 'en': ['not_in'] },
    { 'ar': ['مطلوب'], 'en': ['required'] },
    { 'ar': ['نفس'], 'en': ['same'] },
    { 'ar': ['مقاس'], 'en': ['size'] },
    { 'ar': ['خيط'], 'en': ['string'] },
    { 'ar': ['بالفعل'], 'en': ['already'] },
    { 'ar': ['فريد'], 'en': ['unique'] },
    { 'ar': ['تم الرفع'], 'en': ['uploaded'] },
    { 'ar': ['url'], 'en': ['url'] },
    { 'ar': ['عنوان'], 'en': ['title'] },
    { 'ar': ['سبب'], 'en': ['reason'] },
    { 'ar': ['تسجيل خروج'], 'en': ['logout'] },
    { 'ar': ['الصفحة الرئيسية'], 'en': ['homepage'] },
    { 'ar': ['خلف'], 'en': ['back'] },
    { 'ar': ['إلى الأمام'], 'en': ['forward'] },
    { 'ar': ['ابحث الآن'], 'en': ['search'] },
    { 'ar': ['بريد إلكتروني'], 'en': ['e-mail'] },
    { 'ar': ['بريد إلكتروني'], 'en': ['email'] },
    { 'ar': ['بريد'], 'en': ['mail'] },
    { 'ar': ['مدينة'], 'en': ['city'] },
    { 'ar': ['هاتف'], 'en': ['phone'] },
    { 'ar': ['رقم'], 'en': ['number'] },
    { 'ar': ['امتحان'], 'en': ['test'] },
    { 'ar': ['انقر'], 'en': ['click'] },
    { 'ar': ['يستخدم'], 'en': ['use'] },
    { 'ar': ['يحصل'], 'en': ['get'] },
    { 'ar': ['بريد'], 'en': ['post'] },
    { 'ar': ['يشتري'], 'en': ['buy'] },
    { 'ar': ['يصنع'], 'en': ['make'] },
    { 'ar': ['يعرض'], 'en': ['offer'] },
    { 'ar': ['اسم المستخدم'], 'en': ['username'] },
    { 'ar': ['اسم'], 'en': ['name'] },
    { 'ar': ['أولاً'], 'en': ['first'] },
    { 'ar': ['آخر'], 'en': ['last'] },
    { 'ar': ['تأكيد'], 'en': ['confirmation'] },
    { 'ar': ['مدينة'], 'en': ['city'] },
    { 'ar': ['دولة'], 'en': ['country'] },
    { 'ar': ['عنوان'], 'en': ['address'] },
    { 'ar': ['هاتف'], 'en': ['phone'] },
    { 'ar': ['متحرك'], 'en': ['mobile'] },
    { 'ar': ['عمر'], 'en': ['age'] },
    { 'ar': ['الجنس'], 'en': ['sex'] },
    { 'ar': ['جنس'], 'en': ['gender'] },
    { 'ar': ['يوم'], 'en': ['day'] },
    { 'ar': ['شهر'], 'en': ['month'] },
    { 'ar': ['سنة'], 'en': ['year'] },
    { 'ar': ['ساعة'], 'en': ['hour'] },
    { 'ar': ['دقيقة'], 'en': ['minute'] },
    { 'ar': ['ثانية'], 'en': ['second'] },
    { 'ar': ['محتوى'], 'en': ['content'] },
    { 'ar': ['وصف'], 'en': ['description'] },
    { 'ar': ['مقتطفات'], 'en': ['excerpt'] },
    { 'ar': ['تاريخ'], 'en': ['date'] },
    { 'ar': ['وقت'], 'en': ['time'] },
    { 'ar': ['متاح'], 'en': ['available'] },
    { 'ar': ['مقاس'], 'en': ['size'] },
    { 'ar': ['سعر'], 'en': ['price'] },
    { 'ar': ['وصف'], 'en': ['desc'] },
    { 'ar': ['عنوان'], 'en': ['title'] },
    { 'ar': ['ف'], 'en': ['q'] },
    { 'ar': ['وصلة'], 'en': ['link'] },
    { 'ar': ['سبيكة'], 'en': ['slug'] },
    { 'ar': ['كتاب ليس'], 'en': ['kitab4less'] },
    { 'ar': ['رسالة'], 'en': ['message'] },
    { 'ar': ['إيجار'], 'en': ['rent'] },
    { 'ar': ['تقديم عرض'], 'en': ['make an offer'] },
    { 'ar': ['تم الارسال'], 'en': ['message sent'] },
    { 'ar': ['هل هناك خطب ما'], 'en': ['something wrong'] },
    { 'ar': ['مرسل'], 'en': ['sent'] },
    { 'ar': ['موقع'], 'en': ['location'] },
    { 'ar': ['ارسل رسالة'], 'en': ['send a message'] },
    { 'ar': ['رسالتك'], 'en': ['your message'] },
    { 'ar': ['عنوان البريد الإلكتروني'], 'en': ['email address'] },
    { 'ar': ['نوع الاستفسار'], 'en': ['type of enquiry'] },
    { 'ar': ['عنوان البريد الإلكتروني غير صالح'], 'en': ['invalid email address'] },
    { 'ar': ['يجب ألا يقل عن 25 حرفًا'], 'en': ['must be at least 25 characters'] },
    { 'ar': ['تحميل'], 'en': ['loading'] },
    { 'ar': ['تأكيد كلمة المرور'], 'en': ['password confirmation'] },
    { 'ar': ['الاسم الأول'], 'en': ['first name'] },
    { 'ar': ['اسم العائلة'], 'en': ['last name'] },
    { 'ar': ['إرسال'], 'en': ['submit'] },
    { 'ar': ['حذف'], 'en': ['delete'] },
    { 'ar': ['تعديل'], 'en': ['edit'] },
    { 'ar': ['تم تسجيل المستخدم بنجاح!'], 'en': ['user was registered successfully!'] },
    { 'ar': ['أشيائي'], 'en': ['my items'] },
    { 'ar': ['رفع'], 'en': ['upload'] },
    { 'ar': ['تم الرفع'], 'en': ['uploaded'] },
    { 'ar': ['اختر صورة'], 'en': ['select picture'] },
    { 'ar': ['إنشاء إعلان جديد'], 'en': ['add new item'] },
    { 'ar': ['لا توجد منتجات للبيع بعد'], 'en': ['no items to sell yet'] },
    { 'ar': ['صورة'], 'en': ['photo'] },
    { 'ar': ['حالة'], 'en': ['condition'] },
    { 'ar': ['عملة'], 'en': ['currency'] },
    { 'ar': ['اختار'], 'en': ['select'] },
    { 'ar': ['لا توجد رسائل بعد'], 'en': ['no messages yet'] },
    { 'ar': ['رسائلي'], 'en': ['my messages'] },
    { 'ar': ['سلع أخرى للبيع'], 'en': ['other items for sale'] },
    { 'ar': ['غير مصرح به من فضلك'], 'en': ['unauthorized, please'] },
    { 'ar': ['انقر هنا لتسجيل الدخول'], 'en': ['click here to login'] },
    { 'ar': ['انقر هنا'], 'en': ['click here'] },
    { 'ar': ['السياسة والشروط'], 'en': ['policy and terms'] },
    { 'ar': ['مُباع'], 'en': ['sold'] },
    { 'ar': ['اللغة الإنجليزية وآدابها'], 'en': ['english language and literature'] },
    { 'ar': ['الرياضيات'], 'en': ['mathematics'] },
    { 'ar': ['علوم (فيزياء , كيمياء , أحياء)'], 'en': ['science (physics, chemistry, biology)'] },
    { 'ar': ['العلوم الاجتماعية (التاريخ والجغرافيا والتربية المدنية)'], 'en': ['social sciences (history, geography, civics)'] },
    { 'ar': ['اللغات الأجنبية (الفرنسية , الإسبانية , الألمانية , إلخ.)'], 'en': ['foreign languages (french, spanish, german, etc.)'] },
    { 'ar': ['دراسات الأعمال / الاقتصاد'], 'en': ['business studies/economics'] },
    { 'ar': ['علوم الكمبيوتر / تكنولوجيا المعلومات'], 'en': ['computer science/information technology'] },
    { 'ar': ['الفنون والعلوم الإنسانية'], 'en': ['arts and humanities'] },
    { 'ar': ['دراسات بيئية'], 'en': ['environmental studies'] },
    { 'ar': ['روايات'], 'en': ['novels'] },
    { 'ar': ['كتب واقعية'], 'en': ['non-fiction books'] },
    { 'ar': ['النمو الشخصي وتطوير الذات'], 'en': ['personal growth and self development'] },
    { 'ar': ['الأعمال وريادة الأعمال'], 'en': ['business and entrepreneurship'] },
    { 'ar': ['علم النفس'], 'en': ['psychology'] },
    { 'ar': ['فلسفة'], 'en': ['philosophy'] },
    { 'ar': ['السفر'], 'en': ['travel'] },
    { 'ar': ['الطبخ / الطعام'], 'en': ['cooking/food'] },
    { 'ar': ['صحة / لياقة'], 'en': ['health/fitness'] },
    { 'ar': ['التعليم / الأسرة'], 'en': ['education/family'] },
    { 'ar': ['الشعر'], 'en': ['poetry'] },
    { 'ar': ['الدين / الروحانية'], 'en': ['religion/spirituality'] },
    { 'ar': ['آخر'], 'en': ['other'] },
    { 'ar': ['فئة'], 'en': ['category'] },
    { 'ar': ['أفغانستان'], 'en': ['afghanistan'] },
    { 'ar': ['ألبانيا'], 'en': ['albania'] },
    { 'ar': ['الجزائر'], 'en': ['algeria'] },
    { 'ar': ['أندورا'], 'en': ['andorra'] },
    { 'ar': ['أنغولا'], 'en': ['angola'] },
    { 'ar': ['أنغيلا'], 'en': ['anguilla'] },
    { 'ar': ['أنتيغوا وأمبير'], 'en': ['antigua & barbuda'] },
    { 'ar': ['الأرجنتين'], 'en': ['argentina'] },
    { 'ar': ['أرمينيا'], 'en': ['armenia'] },
    { 'ar': ['أروبا'], 'en': ['aruba'] },
    { 'ar': ['أستراليا'], 'en': ['australia'] },
    { 'ar': ['النمسا'], 'en': ['austria'] },
    { 'ar': ['أذربيجان'], 'en': ['azerbaijan'] },
    { 'ar': ['جزر الباهاما'], 'en': ['bahamas'] },
    { 'ar': ['البحرين'], 'en': ['bahrain'] },
    { 'ar': ['بنغلاديش'], 'en': ['bangladesh'] },
    { 'ar': ['باربادوس'], 'en': ['barbados'] },
    { 'ar': ['بيلاروس'], 'en': ['belarus'] },
    { 'ar': ['بلجيكا'], 'en': ['belgium'] },
    { 'ar': ['بليز'], 'en': ['belize'] },
    { 'ar': ['بنين'], 'en': ['benin'] },
    { 'ar': ['برمودا'], 'en': ['bermuda'] },
    { 'ar': ['بوتان'], 'en': ['bhutan'] },
    { 'ar': ['بوليفيا'], 'en': ['bolivia'] },
    { 'ar': ['البوسنة وأمبير. الهرس'], 'en': ['bosnia & herzegovina'] },
    { 'ar': ['بوتسوانا'], 'en': ['botswana'] },
    { 'ar': ['البرازيل'], 'en': ['brazil'] },
    { 'ar': ['جزر فيرجن البريطانية'], 'en': ['british virgin islands'] },
    { 'ar': ['بروناي'], 'en': ['brunei'] },
    { 'ar': ['بلغاريا'], 'en': ['bulgaria'] },
    { 'ar': ['بوركينا فاسو'], 'en': ['burkina faso'] },
    { 'ar': ['بوروندي'], 'en': ['burundi'] },
    { 'ar': ['كمبوديا'], 'en': ['cambodia'] },
    { 'ar': ['الكاميرون'], 'en': ['cameroon'] },
    { 'ar': ['الرأس الأخضر'], 'en': ['cape verde'] },
    { 'ar': ['جزر كايمان'], 'en': ['cayman islands'] },
    { 'ar': ['تشاد'], 'en': ['chad'] },
    { 'ar': ['تشيلي'], 'en': ['chile'] },
    { 'ar': ['الصين'], 'en': ['china'] },
    { 'ar': ['كولومبيا'], 'en': ['colombia'] },
    { 'ar': ['الكونغو'], 'en': ['congo'] },
    { 'ar': ['جزر كوك'], 'en': ['cook islands'] },
    { 'ar': ['كوستا ريكا'], 'en': ['costa Rica'] },
    { 'ar': ['كوت د ايفوار'], 'en': ['cote d ivoire'] },
    { 'ar': ['كرواتيا'], 'en': ['croatia'] },
    { 'ar': ['سفينه سياحيه'], 'en': ['cruise ship'] },
    { 'ar': ['كوبا'], 'en': ['cuba'] },
    { 'ar': ['قبرص'], 'en': ['cyprus'] },
    { 'ar': ['الجمهورية التشيكية'], 'en': ['czech Republic'] },
    { 'ar': ['الدنمارك'], 'en': ['denmark'] },
    { 'ar': ['جيبوتي'], 'en': ['djibouti'] },
    { 'ar': ['دومينيكا'], 'en': ['dominica'] },
    { 'ar': ['جمهورية الدومينيكان'], 'en': ['dominican Republic'] },
    { 'ar': ['الاكوادور'], 'en': ['ecuador'] },
    { 'ar': ['مصر'], 'en': ['egypt'] },
    { 'ar': ['السلفادور'], 'en': ['el salvador'] },
    { 'ar': ['غينيا الإستوائية'], 'en': ['equatorial guinea'] },
    { 'ar': ['استونيا'], 'en': ['estonia'] },
    { 'ar': ['أثيوبيا'], 'en': ['ethiopia'] },
    { 'ar': ['جزر فوكلاند'], 'en': ['falkland islands'] },
    { 'ar': ['جزر فاروس'], 'en': ['faroe islands'] },
    { 'ar': ['فيجي'], 'en': ['fiji'] },
    { 'ar': ['فنلندا'], 'en': ['finland'] },
    { 'ar': ['فرنسا'], 'en': ['france'] },
    { 'ar': ['بولينيزيا الفرنسية'], 'en': ['french polynesia'] },
    { 'ar': ['جزر الهند الغربية الفرنسية'], 'en': ['french west indies'] },
    { 'ar': ['جابون'], 'en': ['gabon'] },
    { 'ar': ['غامبيا'], 'en': ['gambia'] },
    { 'ar': ['جورجيا'], 'en': ['georgia'] },
    { 'ar': ['ألمانيا'], 'en': ['germany'] },
    { 'ar': ['غانا'], 'en': ['ghana'] },
    { 'ar': ['جبل طارق'], 'en': ['gibraltar'] },
    { 'ar': ['اليونان'], 'en': ['greece'] },
    { 'ar': ['الأرض الخضراء'], 'en': ['greenland'] },
    { 'ar': ['غرينادا'], 'en': ['grenada'] },
    { 'ar': ['غوام'], 'en': ['guam'] },
    { 'ar': ['غواتيمالا'], 'en': ['guatemala'] },
    { 'ar': ['جيرنزي'], 'en': ['guernsey'] },
    { 'ar': ['غينيا'], 'en': ['guinea'] },
    { 'ar': ['غينيا بيساو'], 'en': ['guinea bissau'] },
    { 'ar': ['غيانا'], 'en': ['guyana'] },
    { 'ar': ['هايتي'], 'en': ['haiti'] },
    { 'ar': ['هندوراس'], 'en': ['honduras'] },
    { 'ar': ['هونغ كونغ'], 'en': ['hong kong'] },
    { 'ar': ['هنغاريا'], 'en': ['hungary'] },
    { 'ar': ['أيسلندا'], 'en': ['iceland'] },
    { 'ar': ['الهند'], 'en': ['india'] },
    { 'ar': ['إندونيسيا'], 'en': ['indonesia'] },
    { 'ar': ['إيران'], 'en': ['iran'] },
    { 'ar': ['العراق'], 'en': ['iraq'] },
    { 'ar': ['أيرلندا'], 'en': ['ireland'] },
    { 'ar': ['جزيرة آيل أوف مان'], 'en': ['isle of man'] },
    { 'ar': ['إسرائيل'], 'en': ['israel'] },
    { 'ar': ['إيطاليا'], 'en': ['italy'] },
    { 'ar': ['جامايكا'], 'en': ['jamaica'] },
    { 'ar': ['اليابان'], 'en': ['japan'] },
    { 'ar': ['جيرسي'], 'en': ['jersey'] },
    { 'ar': ['الأردن'], 'en': ['jordan'] },
    { 'ar': ['كازاخستان'], 'en': ['kazakhstan'] },
    { 'ar': ['كينيا'], 'en': ['kenya'] },
    { 'ar': ['الكويت'], 'en': ['kuwait'] },
    { 'ar': [' جمهورية قيرغيزستان '], 'en': ['kyrgyz Republic'] },
    { 'ar': ['لاوس'], 'en': ['laos'] },
    { 'ar': ['لاتفيا'], 'en': ['latvia'] },
    { 'ar': ['لبنان'], 'en': ['lebanon'] },
    { 'ar': ['ليسوتو'], 'en': ['lesotho'] },
    { 'ar': ['ليبيريا'], 'en': ['liberia'] },
    { 'ar': ['ليبيا'], 'en': ['libya'] },
    { 'ar': ['ليختنشتاين'], 'en': ['liechtenstein'] },
    { 'ar': ['ليتوانيا'], 'en': ['lithuania'] },
    { 'ar': ['لوكسمبورغ'], 'en': ['luxembourg'] },
    { 'ar': ['ماكاو'], 'en': ['macau'] },
    { 'ar': ['مقدونيا'], 'en': ['macedonia'] },
    { 'ar': ['مدغشقر'], 'en': ['madagascar'] },
    { 'ar': ['ملاوي'], 'en': ['malawi'] },
    { 'ar': ['ماليزيا'], 'en': ['malaysia'] },
    { 'ar': ['جزر المالديف'], 'en': ['maldives'] },
    { 'ar': ['مالي'], 'en': ['mali'] },
    { 'ar': ['مالطا'], 'en': ['malta'] },
    { 'ar': ['موريتانيا'], 'en': ['mauritania'] },
    { 'ar': ['موريشيوس'], 'en': ['mauritius'] },
    { 'ar': ['المكسيك'], 'en': ['mexico'] },
    { 'ar': ['مولدوفا'], 'en': ['moldova'] },
    { 'ar': ['موناكو'], 'en': ['monaco'] },
    { 'ar': ['منغوليا'], 'en': ['mongolia'] },
    { 'ar': ['الجبل الأسود'], 'en': ['montenegro'] },
    { 'ar': ['مونتسيرات'], 'en': ['montserrat'] },
    { 'ar': ['المغرب'], 'en': ['morocco'] },
    { 'ar': ['موزمبيق'], 'en': ['mozambique'] },
    { 'ar': ['ناميبيا'], 'en': ['namibia'] },
    { 'ar': ['نيبال'], 'en': ['nepal'] },
    { 'ar': ['هولندا'], 'en': ['netherlands'] },
    { 'ar': ['جزر الأنتيل الهولندية'], 'en': ['netherlands antilles'] },
    { 'ar': ['كاليدونيا الجديدة'], 'en': ['new caledonia'] },
    { 'ar': ['نيوزيلندا'], 'en': ['new zealand'] },
    { 'ar': ['نيكاراغوا'], 'en': ['nicaragua'] },
    { 'ar': ['النيجر'], 'en': ['niger'] },
    { 'ar': ['نيجيريا'], 'en': ['nigeria'] },
    { 'ar': ['النرويج'], 'en': ['norway'] },
    { 'ar': ['سلطنة عمان'], 'en': ['oman'] },
    { 'ar': ['باكستان'], 'en': ['pakistan'] },
    { 'ar': ['فلسطين'], 'en': ['palestine'] },
    { 'ar': ['بنما'], 'en': ['panama'] },
    { 'ar': ['بابوا غينيا الجديدة'], 'en': ['papua new guinea'] },
    { 'ar': ['باراغواي'], 'en': ['paraguay'] },
    { 'ar': ['بيرو'], 'en': ['peru'] },
    { 'ar': ['فيلبيني'], 'en': ['philippines'] },
    { 'ar': ['بولندا'], 'en': ['poland'] },
    { 'ar': ['البرتغال'], 'en': ['portugal'] },
    { 'ar': ['بورتوريكو'], 'en': ['puerto Rico'] },
    { 'ar': ['دولة قطر'], 'en': ['qatar'] },
    { 'ar': ['جمع شمل'], 'en': ['Reunion'] },
    { 'ar': ['رومانيا'], 'en': ['Romania'] },
    { 'ar': ['روسيا'], 'en': ['Russia'] },
    { 'ar': ['رواندا'], 'en': ['Rwanda'] },
    { 'ar': [' القديس بيير & أمبير ؛ ميكلون'], 'en': ['saint pierre & miquelon'] },
    { 'ar': ['ساموا'], 'en': ['samoa'] },
    { 'ar': ['سان مارينو'], 'en': ['san marino'] },
    { 'ar': ['الأقمار الصناعية'], 'en': ['satellite'] },
    { 'ar': ['المملكة العربية السعودية'], 'en': ['saudi arabia'] },
    { 'ar': ['السنغال'], 'en': ['senegal'] },
    { 'ar': ['صربيا'], 'en': ['serbia'] },
    { 'ar': ['سيشل'], 'en': ['seychelles'] },
    { 'ar': ['سيرا ليون'], 'en': ['sierra leone'] },
    { 'ar': ['سنغافورة'], 'en': ['singapore'] },
    { 'ar': ['سلوفاكيا'], 'en': ['slovakia'] },
    { 'ar': ['سلوفينيا'], 'en': ['slovenia'] },
    { 'ar': ['جنوب أفريقيا'], 'en': ['south africa'] },
    { 'ar': ['كوريا الجنوبية'], 'en': ['south korea'] },
    { 'ar': ['إسبانيا'], 'en': ['spain'] },
    { 'ar': ['سيريلانكا'], 'en': ['sri lanka'] },
    { 'ar': ['سانت كيتس وأمبير. نيفيس '], 'en': ['st kitts & nevis'] },
    { 'ar': ['شارع لوسيا'], 'en': ['st lucia'] },
    { 'ar': ['شارع فنسنت'], 'en': ['st vincent'] },
    { 'ar': ['شارع. لوسيا'], 'en': ['st. lucia'] },
    { 'ar': ['السودان'], 'en': ['sudan'] },
    { 'ar': ['سورينام'], 'en': ['suriname'] },
    { 'ar': ['سوازيلاند'], 'en': ['swaziland'] },
    { 'ar': ['السويد'], 'en': ['sweden'] },
    { 'ar': ['سويسرا'], 'en': ['switzerland'] },
    { 'ar': ['سوريا'], 'en': ['syria'] },
    { 'ar': ['تايوان'], 'en': ['taiwan'] },
    { 'ar': ['طاجيكستان'], 'en': ['tajikistan'] },
    { 'ar': ['تنزانيا'], 'en': ['tanzania'] },
    { 'ar': ['تايلاند'], 'en': ['thailand'] },
    { 'ar': ['تيمور ليستي'], 'en': ["timor l'este"] },
    { 'ar': ['توجو'], 'en': ['togo'] },
    { 'ar': ['تونجا'], 'en': ['tonga'] },
    { 'ar': [' ترينيداد وأمبير. توباغو'], 'en': ['trinidad & tobago'] },
    { 'ar': ['تونس'], 'en': ['tunisia'] },
    { 'ar': ['ديك رومى'], 'en': ['turkey'] },
    { 'ar': ['تركمانستان'], 'en': ['turkmenistan'] },
    { 'ar': [' الأتراك وأمبير. كايكوس'], 'en': ['turks & caicos'] },
    { 'ar': ['أوغندا'], 'en': ['uganda'] },
    { 'ar': ['أوكرانيا'], 'en': ['ukraine'] },
    { 'ar': ['الإمارات العربية المتحدة'], 'en': ['united arab emirates'] },
    { 'ar': ['المملكة المتحدة'], 'en': ['united kingdom'] },
    { 'ar': ['أوروغواي'], 'en': ['uruguay'] },
    { 'ar': ['أوزبكستان'], 'en': ['uzbekistan'] },
    { 'ar': ['فنزويلا'], 'en': ['venezuela'] },
    { 'ar': ['فيتنام'], 'en': ['vietnam'] },
    { 'ar': ['جزر العذراء (الولايات المتحدة)'], 'en': ['virgin islands (us)'] },
    { 'ar': ['اليمن'], 'en': ['yemen'] },
    { 'ar': ['زامبيا'], 'en': ['zambia'] },
    { 'ar': ['زيمبابوي'], 'en': ['zimbabwe'] },
    { 'ar': ['بيع عنصر'], 'en': ['sell item'] },
    { 'ar': ['عام'], 'en': ['general'] },
    { 'ar': ['الدعم'], 'en': ['support'] },
    { 'ar': ['اتجاه'], 'en': ['direction'] },
    { 'ar': ['تحتاج مساعدة'], 'en': ['need help'] },
    { 'ar': ['نص الرسالة'], 'en': ['message body'] },
    { 'ar': ['الوارد'], 'en': ['inbox'] },
    { 'ar': ['الرسائل'], 'en': ['messages'] },
    { 'ar': ['جديد'], 'en': ['new'] },
    { 'ar': ['مستخدم'], 'en': ['used'] },
    { 'ar': ['مملوكة من قبل'], 'en': ['preowned'] },
    { 'ar': ['غير مستعمل'], 'en': ['not used'] },
    { 'ar': ['عروضي'], 'en': ['my offers'] },
    { 'ar': ['حالة'], 'en': ['status'] },
    { 'ar': ['قيد الانتظار'], 'en': ['pending'] },
    { 'ar': ['مرفوض'], 'en': ['rejected'] },
    { 'ar': ['قبلت'], 'en': ['accepted'] },
    { 'ar': ['رفوض'], 'en': ['reject'] },
    { 'ar': ['قبلت'], 'en': ['accept'] },
    { 'ar': ['دردشة'], 'en': ['chat'] },
    { 'ar': ['إرسال'], 'en': ['send'] },
    { 'ar': ['نشط'], 'en': ['active'] },
    { 'ar': ['على الخط'], 'en': ['online'] },
    { 'ar': ['المستخدمون النشطون'], 'en': ['active users'] },
    { 'ar': ['خارج الشبكة'], 'en': ['offline'] },
    { 'ar': ['غادر'], 'en': ['leave'] },
    { 'ar': ['مغادرة الدردشة'], 'en': ['leave chat'] },
    { 'ar': ['اكتب رسالة'], 'en': ['write message'] },
    { 'ar': ['لقد تلقيت رسالة جديدة'], 'en': ['you recieved a new message'] },
    { 'ar': ['أنت'], 'en': ['you'] },
    { 'ar': ['حسابي'], 'en': [`my account`] },

    { 'ar': ['لماذا الدردشة؟'], 'en': ['why to chat?'] },
    { 'ar': ['أي سؤال'], 'en': ['any question'] },
    { 'ar': ['اي طلب'], 'en': ['any request'] },
    { 'ar': ['أبحث عن كتاب'], 'en': ['looking for a book'] },
    { 'ar': ['امتلاك كتاب فريد'], 'en': ['having a unique book'] },
    { 'ar': ['التدقيق في الكتاب'], 'en': ['checking on book'] },
    { 'ar': ['انت في المكان الصحيح'], 'en': ['you are at the right place'] },
    { 'ar': ['إرسال رسالة عامة'], 'en': ['send a public message'] },
    { 'ar': ['احصل على إجابتك'], 'en': ['get your answer'] },
    { 'ar': ['نتمنى لك التوفيق في بحثك'], 'en': ['good luck in your search'] },
    { 'en': ['There', 'are', 'no', 'problems.'], 'ar': ['ليس', 'هناك', 'أي', 'مشاكل.'] },
    { 'en': ['I', 'know', 'Tom', 'loves', 'Mary.'], 'ar': ['أنا', 'أعلم', 'أن', 'توم', 'يحب', 'ماري'] },
    { 'en': ['He', 'struck', 'a', 'match.'], 'ar': ['أشعل', 'عود', 'ثقاب.'] },
    { 'en': ['They', 'went', 'on', 'talking', 'for', 'hours.'], 'ar': ['استمروا', 'في', 'الحديث', 'لساعات.'] },
    { 'en': ['If', 'I', 'were', 'you,', "I'd", 'follow', 'his', 'advice.'], 'ar': ['لو', 'كنت', 'مكانك', 'لأخذت', 'بنصيحته.'] },
    { 'en': ['What', 'he', 'said', 'would', 'happen', 'has', 'happened.'], 'ar': ['حصل', 'ما', 'قال', 'أنه', 'سيحصل.'] },
    { 'en': ['Tom', 'went', 'inside', 'the', 'apartment.'], 'ar': ['دخل', 'توم', 'الشقة.'] },
    { 'en': ['Keep', 'your', 'eyes', 'open.'], 'ar': ['أبقِ', 'عينيك', 'مفتوحتين.'] },
    { 'en': ['I', "don't", 'like', 'it,', 'either.'], 'ar': ['لا', 'يعجبني', 'ذلك', 'أيضاً.'] },
    { 'en': ['Where', 'is', 'Tom?'], 'ar': ['أين', 'توم؟'] },
    { 'en': ['I', 'live', 'in', 'Japan.'], 'ar': ['أنا', 'أعيش', 'في', 'اليابان'] },
    { 'en': ["I'll", 'help', 'you', 'if', 'possible.'], 'ar': ['سأساعدك', 'إن', 'أمكن.'] },
    { 'en': ['Your', 'dog', 'is', 'very', 'fat.'], 'ar': ['كلبك', 'سمين', 'للغاية.'] },
    { 'en': ['What', 'do', 'you', 'intend', 'to', 'do?'], 'ar': ['ما', 'الذي', 'تنوي', 'فعله؟'] },
    { 'en': ['Look', 'at', 'the', 'price.'], 'ar': ['انظر', 'إلى', 'السعر'] },
    { 'en': ['Which', 'country', 'are', 'you', 'from?'], 'ar': ['من', 'أي', 'بلد', 'أنت؟'] },
    { 'en': ['I', 'believe', 'in', 'God.'], 'ar': ['أنا', 'اؤمن', 'بالله.'] },
    { 'en': ['Did', 'you', 'show', 'it', 'to', 'your', 'parents?'], 'ar': ['هل', 'أريتها', 'والديك؟'] },
    { 'en': ['It', 'was', 'an', 'incredible', 'job.'], 'ar': ['كان', 'عملاً', 'استثنائياً.'] },
    { 'en': ['Tom', "isn't", 'sure', 'whether', "he's", 'ready', 'or', 'not.'], 'ar': ['توم', 'غير', 'متأكد', 'من', 'أنه', 'جاهز', 'أم', 'لا.'] },
    { 'en': ['They', 'say', 'that', 'he', 'will', 'never', 'return.'], 'ar': ['يقال', 'أنه', 'لن', 'يعود.'] },
    { 'en': ['What', 'would', 'you', 'like', 'to', 'eat?'], 'ar': ['ماذا', 'تريد', 'أن', 'تأكل؟'] },
    { 'en': ['He', 'reached', 'for', 'the', 'apple.'], 'ar': ['مدّ', 'يده', 'إلى', 'التفاحة.'] },
    { 'en': ["I'm", 'old', 'enough', 'to', 'drink.'], 'ar': ['أنا', 'كبير', 'بما', 'يكفي', 'لأشرب.'] },
    { 'en': ['Stop', 'yelling!'], 'ar': ['توقفي', 'عن', 'الصراخ!'] },
    { 'en': ['They', 'have', 'elected', 'a', 'new', 'government.'], 'ar': ['لقد', 'انتخبوا', 'حكومة', 'جديدة.'] },
    { 'en': ['Your', 'dog', 'is', 'here.'], 'ar': ['ها', 'هو', 'كلبك.'] },
    { 'en': ['I', 'took', 'him', 'to', 'the', 'hospital.'], 'ar': ['أنا', 'أخذتهُ', 'إلي', 'المستشفي.'] },
    { 'en': ['The', 'earth', 'moves', 'around', 'the', 'sun.'], 'ar': ['الأرض', 'تدور', 'حول', 'الشمس.'] },
    { 'en': ['She', 'came', 'up', 'to', 'me', 'and', 'asked', 'my', 'name.'], 'ar': ['أتتني', 'وسألتني', 'عن', 'اسمي.'] },
    { 'en': ['I', 'had', 'my', 'car', 'stolen', 'last', 'night.'], 'ar': ['سُرقت', 'سيارتي', 'بالأمس.'] },
    { 'en': ['I', 'worry', 'about', 'him.'], 'ar': ['إنني', 'قلق', 'عليه.'] },
    { 'en': ['I', 'really', 'love', 'dogs.'], 'ar': ['أنا', 'أحبّ', 'الكلاب', 'حقّا.'] },
    { 'en': ['Pass', 'me', 'the', 'salt', 'and', 'pepper,', 'please.'], 'ar': ['من', 'فضلك', 'مرر', 'إليّ', 'الملح', 'و', 'الفلفل.'] },
    { 'en': ['It', 'leaves', 'you', 'breathless.'], 'ar': ['يحبس', 'أنفاسك.'] },
    { 'en': ['This', 'castle', 'is', 'beautiful.'], 'ar': ['هذه', 'القلعة', 'جميلة.'] },
    { 'en': ['Come', 'alone.'], 'ar': ['تعالَ', 'بمفردك.'] },
    { 'en': ['My', 'house', 'is', 'near', 'the', 'station.'], 'ar': ['بيتي', 'قريب', 'من', 'المحطة.'] },
    { 'en': ['Tom', "doesn't", 'need', 'to', 'buy', 'anything.'], 'ar': ['لا', 'يحتاج', 'توم', 'لشراء', 'أي', 'شيء.'] },
    { 'en': ['I', 'saw', 'one', 'once.'], 'ar': ['رأيتُ', 'واحدة', 'مرة.'] },
    { 'en': ['Tom', "isn't", 'so', 'tall.'], 'ar': ['توم', 'ليس', 'طويلاً', 'جداً.'] },
    { 'en': ['At', 'last,', 'she', 'solved', 'the', 'problem.'], 'ar': ['و', 'أخيراً', 'حلّت', 'المشكلة.'] },
    { 'en': ['You', 'look', 'very', 'good.'], 'ar': ['أنتِ', 'تبدين', 'بحالة', 'جيدة', 'جداً.'] },
    { 'en': ['I', 'guess', 'we', "can't", 'say', 'no.'], 'ar': ['اعتقد', 'بأنه', 'لا', 'يمكننا', 'الرفض.'] },
    { 'en': ['It', 'was', 'their', 'fault.'], 'ar': ['كان', 'ذلك', 'خطأهم.'] },
    { 'en': ['The', 'birds', 'sang.'], 'ar': ['غردت', 'العصافير.'] },
    { 'en': ['I', 'met', 'Mary', 'yesterday.'], 'ar': ['التقيت', 'بماري', 'البارحة.'] },
    { 'en': ['Tom', 'wants', 'to', 'meet', 'me.'], 'ar': ['يريد', 'توم', 'اللقاء', 'بي.'] },
    { 'en': ['Have', 'you', 'gone', 'nuts?'], 'ar': ['هل', 'جننت؟'] },
    { 'en': ['What?', 'I', "can't", 'hear', 'you', 'guys.'], 'ar': ['ماذا؟', 'لا', 'أستطيع', 'سماعكم', 'أيها', 'الرفاق.'] },
    { 'en': ['The', 'earth', 'goes', 'around', 'the', 'sun.'], 'ar': ['تدور', 'الأرض', 'حول', 'الشمس.'] },
    { 'en': ['They', 'have', 'lost', 'a', 'lot.'], 'ar': ['لقد', 'خسروا', 'الكثير.'] },
    { 'en': ['Tom', 'made', 'me', 'angry.'], 'ar': ['أغضبني', 'توم.'] },
    { 'en': ['I', "don't", 'know', 'how', 'to', 'handle', 'children.'], 'ar': ['لا', 'أعرف', 'كيف', 'أتعامل', 'مع', 'الأطفال.'] },
    { 'en': ['Thanks', 'a', 'bunch.'], 'ar': ['شكرا', 'جزيلا.'] },
    { 'en': ['I', 'can', 'hardly', 'walk.'], 'ar': ['بالكاد', 'يمكنني', 'المشي.'] },
    { 'en': ["Let's", 'forget', 'about', 'this.'], 'ar': ['دعينا', 'ننسى', 'هذا.'] },
    { 'en': ["You're", 'a', 'philosopher,', "aren't", 'you?'], 'ar': ['أنت', 'فيلسوف،', 'أليس', 'كذلك؟'] },
    { 'en': ['What', 'are', 'you', 'reading?'], 'ar': ['ماذا', 'تقرأ'] },
    { 'en': ['Darwin', 'changed', 'everything.'], 'ar': ['داروين', 'غيرَ', 'كل', 'شيء.'] },
    { 'en': ["I've", 'won', 'first', 'prize!'], 'ar': ['ربحت', 'الجائزة', 'الكبرى.'] },
    { 'en': ['I', 'just', "don't", 'know', 'what', 'to', 'say.'], 'ar': ['لا', 'أعلم', 'ماذا', 'أقول.'] },
    { 'en': ['What', 'happened', 'in', 'the', 'meeting?'], 'ar': ['ماذا', 'حدث', 'في', 'الاجتماع؟'] },
    { 'en': ["I'm", 'just', 'having', 'some', 'fun.'], 'ar': ['أنا', 'أتسلى', 'قليلا', 'فقط.'] },
    { 'en': ['You', 'should', 'eat', 'more', 'fruit.'], 'ar': ['يجب', 'أن', 'تأكل', 'المزيد', 'من', 'الفاكهة.'] },
    { 'en': ['She', 'must', 'be', 'angry', 'with', 'me.'], 'ar': ['لا', 'بد', 'أنها', 'غاضبة', 'مني.'] },
    { 'en': ['I', 'can', 'do', 'it', 'by', 'myself.'], 'ar': ['أستطيع', 'فعلها', 'بنفسي.'] },
    { 'en': ['That', 'is', 'intriguing.'], 'ar': ['ذلك', 'مثير', 'للإهتمام.'] },
    { 'en': ["I'm", 'your', 'partner.'], 'ar': ['أنا', 'شريكك.'] },
    { 'en': ['This', 'is', 'the', 'very', 'CD', "I've", 'been', 'looking', 'for.'], 'ar': ['هذه', 'هي', 'الأسطوانة', 'نفسها', 'التي', 'كنت', 'أبحث', 'عنها.'] },
    { 'en': ['Are', 'you', 'sure?'], 'ar': ['أأنت', 'متأكد', 'من', 'ذلك؟'] },
    { 'en': ['You', 'are', 'selling', 'him', 'short.'], 'ar': ['أنت', 'تستخف', 'به', '.'] },
    { 'en': ['I', 'had', 'my', 'brother', 'repair', 'my', 'bicycle.'], 'ar': ['طلبت', 'من', 'أخي', 'إصلاح', 'دراجتي.'] },
    { 'en': ['There', 'are', 'lots', 'of', 'animals', 'in', 'the', 'park.'], 'ar': ['هناك', 'الكثير', 'من', 'الحيوانات', 'في', 'الحديقة.'] },
    { 'en': ['I', 'have', 'an', 'Italian', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جار', 'إيطالي.'] },
    { 'en': ['You', 'are', 'exaggerating', 'the', 'problem.'], 'ar': ['إنك', 'تعطي', 'المسألة', 'أكثر', 'مما', 'تستحقه.'] },
    { 'en': ['Let', 'me', 'think', 'for', 'a', 'minute.'], 'ar': ['دعني', 'أفكر', 'للحظة.'] },
    { 'en': ['Where', 'is', 'her', 'house?'], 'ar': ['أين', 'بيتها؟'] },
    { 'en': ['I', 'want', 'to', 'eat', 'out', 'tonight.'], 'ar': ['أريد', 'أن', 'آكل', 'في', 'مطعم', 'الليلة.'] },
    { 'en': ['Who', 'took', 'my', 'keys?'], 'ar': ['من', 'أخذ', 'مفاتيحي؟'] },
    { 'en': ['Thanks', 'for', 'the', 'advice.'], 'ar': ['شكرا', 'على', 'النصيحة.'] },
    { 'en': ['You', 'must', 'keep', 'your', 'promise.'], 'ar': ['عليك', 'أن', 'تفي', 'بوعدك.'] },
    { 'en': ['Leaky', 'faucets', 'waste', 'a', 'lot', 'of', 'water.'], 'ar': ['الصنبور', 'المسرب', 'يهدر', 'الكثير', 'من', 'الماء'] },
    { 'en': ['Could', 'you', 'please', 'tell', 'me', 'why', 'you', 'love', 'her?'], 'ar': ['أيمكنك', 'إخباري', 'عن', 'سبب', 'حبك', 'لها؟'] },
    { 'en': ['My', 'father', 'drives', 'to', 'work.'], 'ar': ['يقود', 'أبي', 'السيارة', 'إلى', 'عمله.'] },
    { 'en': ['My', 'sister', 'goes', 'to', 'a', 'university.'], 'ar': ['تدرس', 'أختي', 'في', 'الجامعة.'] },
    { 'en': ['He', 'likes', 'to', 'travel', 'by', 'himself.'], 'ar': ['يعجبه', 'السفر', 'لوحده.'] },
    { 'en': ['Do', 'you', 'know', 'what', 'Tom', 'does', 'for', 'a', 'living?'], 'ar': ['هل', 'تعلم', 'ما', 'يعمله', 'توم', 'لكسب', 'رزقه؟'] },
    { 'en': ['Did', 'you', 'show', 'it', 'to', 'your', 'parents?'], 'ar': ['أأريتها', 'والديك؟'] },
    { 'en': ['He', "isn't", 'afraid', 'of', 'death.'], 'ar': ['إنه', 'لا', 'يخاف', 'من', 'الموت.'] },
    { 'en': ['He', 'was', 'blind', 'from', 'birth.'], 'ar': ['هو', 'كان', 'أعمى', 'منذ', 'ولادته.'] },
    { 'en': ['I', 'remember', 'seeing', 'him', 'somewhere', 'before.'], 'ar': ['أتذكر', 'بأني', 'رأيته', 'من', 'قبل', 'في', 'مكان', 'ما.'] },
    { 'en': ['What', 'happened', "isn't", 'a', 'secret.'], 'ar': ['ما', 'حدث', 'ليس', 'سرًّا.'] },
    { 'en': ['Which', 'ice', 'cream', 'shop', 'are', 'you', 'going', 'to?'], 'ar': ['أي', 'مَحَل', 'مثلجات', 'أنتَ', 'ذاهب', 'إليه؟'] },
    { 'en': ["There's", 'no', 'mistake.'], 'ar': ['ليس', 'هناك', 'أي', 'خطأ.'] },
    { 'en': ['Do', 'you', 'speak', 'Latin?'], 'ar': ['هل', 'تتحدث', 'اللاتينية؟'] },
    { 'en': ['Tom', 'is', 'poor,', 'but', "he's", 'happy.'], 'ar': ['توم', 'سعيدٌ', 'على', 'الرّغم', 'من', 'فقره.'] },
    { 'en': ['I', 'have', 'a', 'test', 'tomorrow.'], 'ar': ['لدي', 'امتحان', 'غداً.'] },
    { 'en': ['Is', 'that', 'too', 'expensive?'], 'ar': ['هل', 'هو', 'غال', 'جدا؟'] },
    { 'en': ['I', 'advise', 'you', 'not', 'to', 'keep', 'company', 'with', 'Tom.'], 'ar': ['أنصحك', 'بعدم', 'مصاحبة', 'توم.'] },
    { 'en': ['She', 'was', 'forced', 'to', 'confess.'], 'ar': ['أجبرت', 'على', 'الإعتراف.'] },
    { 'en': ['They', 'went', 'to', 'the', 'movies', 'last', 'night.'], 'ar': ['ذهبوا', 'إلى', 'السينما', 'ليلة', 'أمس.'] },
    { 'en': ['I', "don't", 'know', 'how,', 'but', 'I', 'did', 'it.'], 'ar': ['لا', 'أعرف', 'كيف،', 'لكنّي', 'فعلتها.'] },
    { 'en': ['I', 'need', 'time.'], 'ar': ['أحتاج', 'وقتا.'] },
    { 'en': ['I', 'will', 'buy', 'a', 'car.'], 'ar': ['سأشتري', 'سيارة.'] },
    { 'en': ['Tom', "didn't", 'fire', 'Mary.'], 'ar': ['توم', 'لم', 'يطرد', 'ماري'] },
    { 'en': ["Here's", 'my', 'telephone', 'number.'], 'ar': ['هذا', 'رقم', 'هاتفي.'] },
    { 'en': ['That', 'is', 'why', 'he', 'got', 'angry.'], 'ar': ['لهذا', 'غضب.'] },
    { 'en': ["Where's", 'your', 'money?'], 'ar': ['أين', 'مالكَ؟'] },
    { 'en': ['Everything', 'happens', 'for', 'a', 'reason.'], 'ar': ['كل', 'شيء', 'يحدث', 'لسبب', 'ما.'] },
    { 'en': ['How', 'did', 'you', 'know', 'what', 'she', 'wanted?'], 'ar': ['كيف', 'علمتَ', 'ماذا', 'كانت', 'تريد؟'] },
    { 'en': ['They', 'were', 'punished', 'for', 'their', 'crimes.'], 'ar': ['لقد', 'عوقبوا', 'على', 'جرائمهم.'] },
    { 'en': ['I', "didn't", 'study', 'math', 'at', 'all.'], 'ar': ['لم', 'أدرس', 'مادة', 'الرياضيات', 'على', 'الإطلاق.'] },
    { 'en': ['Do', 'I', 'have', 'to', 'stay', 'in', 'the', 'hospital?'], 'ar': ['هل', 'يتوجب', 'علي', 'البقاء', 'في', 'المستشفى؟'] },
    { 'en': ['Some', 'people', 'seem', 'to', 'agree', 'with', 'you.'], 'ar': ['يبدو', 'أن', 'بعض', 'الأناس', 'يتفقون', 'معك.'] },
    { 'en': ['Can', 'you', 'drive', 'a', 'car?'], 'ar': ['هل', 'بإمكانك', 'قيادة', 'سيارة؟'] },
    { 'en': ['How', 'are', 'you,', 'Tom?'], 'ar': ['كيف', 'حالك', 'يا', 'توم؟'] },
    { 'en': ['Clean', 'the', 'mirror.'], 'ar': ['نظف', 'المرايا.'] },
    { 'en': ['The', 'students', 'laughed.'], 'ar': ['ضحك', 'الطلاب.'] },
    { 'en': ['You', 'must', 'study', 'hard.'], 'ar': ['عليك', 'أن', 'تدرس', 'بجدّ.'] },
    { 'en': ['I', 'am', 'from', 'Ecuador.'], 'ar': ['أنا', 'من', 'الإكوادور.'] },
    { 'en': ['What', 'happened', 'here?'], 'ar': ['ما', 'الذي', 'حدث', 'هنا؟'] },
    { 'en': ['Try', 'to', 'improve', 'your', 'English.'], 'ar': ['حاول', 'أن', 'تحسن', 'من', 'لغتك', 'الإنجليزية.'] },
    { 'en': ["I'll", 'personally', 'visit', 'you.'], 'ar': ['سأزورك', 'شخصياً.'] },
    { 'en': ['Tom', 'got', 'these', 'tickets', 'for', 'nothing.'], 'ar': ['حصل', 'توم', 'على', 'هذه', 'التذاكر', 'دون', 'مقابل.'] },
    { 'en': ['These', 'windows', 'are', 'bulletproof.'], 'ar': ['هذه', 'النافذة', 'ضد', 'الرصاص.'] },
    { 'en': ["What's", 'she', 'doing?'], 'ar': ['ماذا', 'تفعل؟'] },
    { 'en': ['Tom', 'was', 'hit', 'by', 'a', 'truck', 'and', 'died', 'instantly.'], 'ar': ['توم', 'صدمته', 'شاحنة', 'ومات', 'في', 'الحال'] },
    { 'en': ['How', 'embarrassing!'], 'ar': ['يا', 'للإحراج!'] },
    { 'en': ['They', 'are', 'the', 'same', 'age.'], 'ar': ['هم', 'في', 'نفس', 'السن.'] },
    { 'en': ['No,', 'thank', 'you.', "I'm", 'full.'], 'ar': ['لا،', 'شكراً', 'لك.', 'لقد', 'شبعت.'] },
    { 'en': ['If', 'you', 'get', 'sleepy,', 'just', 'tell', 'me.'], 'ar': ['إذا', 'شعرت', 'بالنعسان،', 'فقط', 'أخبرني.'] },
    { 'en': ['"Thank', 'you."', '"You\'re', 'welcome."'], 'ar': ['"شُكْراً"', '"عَفْواً"'] },
    { 'en': ['What', 'if', 'I', 'fail?'], 'ar': ['ماذا', 'لو', 'فشلت؟'] },
    { 'en': ['May', 'I', 'come', 'in?'], 'ar': ['هل', 'أستطيع', 'أن', 'آتي؟'] },
    { 'en': ["I'm", 'in', 'my', 'car.'], 'ar': ['أنا', 'في', 'السيارة'] },
    { 'en': ['I', 'just', 'finished', 'reading', 'the', 'book.'], 'ar': ['أنهيت', 'قراءة', 'الكتاب', 'للتو.'] },
    { 'en': ['You', "didn't", 'work', 'hard', 'enough.'], 'ar': ['أنت', 'لم', 'تعمل', 'بما', 'فيه', 'الكفاية.'] },
    { 'en': ['Why', 'did', 'you', 'leave', 'your', 'job?'], 'ar': ['لمَ', 'تركت', 'وظيفتك؟'] },
    { 'en': ['Mind', 'your', 'own', 'business!'], 'ar': ['لا', 'تتدخل', 'في', 'ما', 'لا', 'يعنيك.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'sell', 'your', 'car?'], 'ar': ['هل', 'تريدُ', 'أن', 'تبيعَ', 'سيّارتك؟'] },
    { 'en': ['I', 'need', 'another', 'one.'], 'ar': ['أريد', 'واحدا', 'آخر.'] },
    { 'en': ['Tom', 'turned', 'the', 'faucet', 'off.'], 'ar': ['توم', 'أغلق', 'الصنبور'] },
    { 'en': ['He', 'loves', 'her.'], 'ar': ['يحبها.'] },
    { 'en': ['Do', 'you', 'know', 'where', 'Tom', 'hid?'], 'ar': ['أتعلم', 'أين', 'اختبأ', 'توم؟'] },
    { 'en': ["Don't", 'underestimate', 'my', 'power.'], 'ar': ['لا', 'تستهن', 'بقوتي.'] },
    { 'en': ['Do', 'not', 'come', 'here.'], 'ar': ['لا', 'تأت', 'هنا.'] },
    { 'en': ['The', 'door', 'will', 'be', 'painted', 'tomorrow.'], 'ar': ['سيُطلى', 'الباب', 'غداً.'] },
    { 'en': ['A', 'fire', 'broke', 'out', 'near', 'my', 'house.'], 'ar': ['اندلع', 'حريق', 'قرب', 'منزلي.'] },
    { 'en': ['Tom', 'wrote', 'a', 'story', 'about', 'that.'], 'ar': ['كتب', 'توم', 'قصة', 'عن', 'ذلك.'] },
    { 'en': ['Did', 'something', 'happen?'], 'ar': ['هل', 'حصل', 'شيء', 'ما؟'] },
    { 'en': ['She', 'is', 'smarter', 'than', 'he', 'is.'], 'ar': ['هي', 'أذكى', 'منه.'] },
    { 'en': ['May', 'I', 'help', 'you?'], 'ar': ['أيمكنني', 'مساعدتك؟'] },
    { 'en': ["It's", 'not', 'a', 'perfect', 'system.'], 'ar': ['ليس', 'بنظام', 'كامل.'] },
    { 'en': ["It's", 'too', 'dangerous', 'for', 'you', 'to', 'stay', 'here.'], 'ar': ['خطير', 'جداً', 'لكِ', 'أن', 'تكوني', 'هنا.'] },
    { 'en': ['He', 'told', 'me', 'he', 'would', 'go', 'to', 'Venice.'], 'ar': ['أخبرني', 'أنه', 'سيذهب', 'إلى', 'فينس.'] },
    { 'en': ["It's", 'up', 'to', 'you.'], 'ar': ['كما', 'تريد.'] },
    { 'en': ['Is', 'something', 'troubling', 'you?'], 'ar': ['هل', 'يوجد', 'شيء', 'يُزعِجَك؟'] },
    { 'en': ['She', 'has', 'never', 'been', 'to', 'Hokkaido.'], 'ar': ['لم', 'يسبق', 'لها', 'أن', 'زارت', 'هوكايدو.'] },
    { 'en': ['His', 'help', 'is', 'indispensable', 'to', 'us.'], 'ar': ['لا', 'غِنى', 'لنا', 'عن', 'مساعدته.'] },
    { 'en': ['Give', 'me', 'some', 'milk,', 'too.'], 'ar': ['أعطني', 'أيضاً', 'بعض', 'الحليب', '.'] },
    { 'en': ['Make', 'yourself', 'at', 'home.'], 'ar': ['خذ', 'راحتك.'] },
    { 'en': ['How', 'much', 'is', 'it?'], 'ar': ['كم', 'ثمنها؟'] },
    { 'en': ['I', 'was', 'happy', 'then.'], 'ar': ['كنت', 'سعيداً', 'يومها.'] },
    { 'en': ['I', 'am', 'a', 'student', 'of', 'this', 'school.'], 'ar': ['أنا', 'طالب', 'في', 'هذه', 'المدرسة.'] },
    { 'en': ['They', 'were', 'not', 'invited.'], 'ar': ['إنهم', 'لم', 'يكونوا', 'مدعويين.'] },
    { 'en': ['You', 'have', 'six', 'unread', 'messages.'], 'ar': ['لديك', 'ستّ', 'رسائل', 'غير', 'مقروءة.'] },
    { 'en': ['Were', 'you', 'with', 'anyone?'], 'ar': ['أكنت', 'مع', 'أحد؟'] },
    { 'en': ['I', 'assume', 'the', 'two', 'of', 'you', 'know', 'each', 'other.'], 'ar': ['أفترض', 'أنكما', 'تعرفان', 'بعضكما.'] },
    { 'en': ['You', 'idiot!'], 'ar': ['يا', 'أحمق!'] },
    { 'en': ['I', 'want', 'something', 'to', 'read.'], 'ar': ['أريد', 'أن', 'أقرأ', 'شيئاً', 'ما.'] },
    { 'en': ['I', 'promise', "I'll", 'never', 'do', 'it', 'again.'], 'ar': ['أنا', 'أعد,', 'لن', 'أفعلها', 'مجدداً.'] },
    { 'en': ['Be', 'prepared.'], 'ar': ['كُن', 'مُستعداً.'] },
    { 'en': ['His', 'son', 'became', 'a', 'famous', 'pianist.'], 'ar': ['أصبح', 'ابنه', 'عازف', 'بيانو', 'شهير.'] },
    { 'en': ['Sensing', 'danger,', 'he', 'ran', 'away.'], 'ar': ['أحس', 'بالخطر', 'فهرب.'] },
    { 'en': ['Rome', 'is', 'an', 'old', 'city.'], 'ar': ['روما', 'مدينة', 'قديمة.'] },
    { 'en': ['My', 'father', 'passed', 'away', 'two', 'years', 'ago.'], 'ar': ['توفي', 'أبي', 'قبل', 'عامين.'] },
    { 'en': ['It', 'is', 'rude', 'to', 'speak', 'with', 'your', 'mouth', 'full.'], 'ar': ['ليس', 'من', 'الأدب', 'أن', 'تتكلم', 'و', 'فمك', 'ممتلئ.'] },
    { 'en': ['This', 'book', 'was', 'printed', 'in', 'England.'], 'ar': ['طبع', 'هذا', 'الكتاب', 'في', 'انجلترا.'] },
    { 'en': ['Be', 'sensible.'], 'ar': ['كُن', 'معقول.'] },
    { 'en': ["It's", 'impossible.'], 'ar': ['هذا', 'مستحيل.'] },
    { 'en': ['A', 'cat', 'scratched', 'me.'], 'ar': ['خربشني', 'قط.'] },
    { 'en': ["I've", 'had', 'a', 'bad', 'day.'], 'ar': ['أمضيت', 'يوما', 'سيّئا.'] },
    { 'en': ["I'm", 'a', 'translator.'], 'ar': ['أنا', 'مترجم'] },
    { 'en': ['My', 'aunt', 'gave', 'me', 'a', 'book', 'for', 'Christmas.'], 'ar': ['أهدتني', 'خالتي', 'كتابًا', 'في', 'عيد', 'ميلاد', 'المسيح.'] },
    { 'en': ['We', 'have', 'to', 'get', 'results.'], 'ar': ['علينا', 'الحصول', 'على', 'نتائج.'] },
    { 'en': ['She', 'was', 'born', 'in', '1960.'], 'ar': ['هى', 'ولدت', 'في', 'العام', '1960.'] },
    { 'en': ["I'm", 'glad', 'I', "wasn't", 'here.'], 'ar': ['أنا', 'مسرور', 'لعدم', 'وجودي', 'هناك.'] },
    { 'en': ['Each', 'cat', 'has', 'its', 'own', 'personality.'], 'ar': ['كل', 'قطة', 'لها', 'شخصيتها', 'المستقلة.'] },
    { 'en': ["He's", 'her', 'friend.'], 'ar': ['هو', 'صديقها'] },
    { 'en': ['Promise', 'me', 'you', "won't", 'get', 'mad', 'if', 'I', 'tell', 'you.'], 'ar': ['عدني', 'أنك', 'لن', 'تغضب', 'إن', 'قلت', 'لك.'] },
    { 'en': ['Tom', 'was', 'shot', 'and', 'killed.'], 'ar': ['أُطلق', 'على', 'توم', 'فقُتل.'] },
    { 'en': ['You', 'have', 'to', 'change', 'trains', 'at', 'the', 'next', 'stop.'], 'ar': ['عليك', 'أن', 'تركب', 'قطاراً', 'آخر', 'عند', 'المحطة', 'القادمة.'] },
    { 'en': ['I', 'got', 'up', 'at', 'six-thirty', 'this', 'morning.'], 'ar': ['استيقظت', 'الساعة', 'السادسة', 'و', 'النصف', 'هذا', 'الصباح.'] },
    { 'en': ['Can', 'you', 'hear', 'me?'], 'ar': ['أيمكنك', 'أن', 'تسمعني؟'] },
    { 'en': ['Where', 'is', 'the', 'school?'], 'ar': ['أين', 'المدرسة؟'] },
    { 'en': ['You', 'can', 'go', 'to', 'the', 'station', 'by', 'bus.'], 'ar': ['يمكنك', 'الذهاب', 'إلى', 'المحطة', 'بالحافلة.'] },
    { 'en': ['Is', 'this', 'a', 'gift', 'for', 'me?'], 'ar': ['هل', 'هذهِ', 'هدية', 'لي؟'] },
    { 'en': ['She', 'took', 'her', 'own', 'life.'], 'ar': ['إنتحرت.'] },
    { 'en': ['Fantastic!'], 'ar': ['رائع!'] },
    { 'en': ['Tom', "hasn't", 'yet', 'told', 'us', 'about', 'that.'], 'ar': ['لم', 'يخبرنا', 'توم', 'عن', 'ذلك', 'بعد.'] },
    { 'en': ['What', 'a', 'nice', 'surprise!'], 'ar': ['يا', 'لها', 'من', 'مفاجأة', 'جميلة.'] },
    { 'en': ['He', 'is', 'preparing', 'for', 'the', 'test.'], 'ar': ['إنه', 'يجهز', 'نفسه', 'للإمتحان.'] },
    { 'en': ['Generally', 'speaking,', 'history', 'repeats', 'itself.'], 'ar': ['غالباً', 'ما', 'يعيد', 'التاريخ', 'نفسه.'] },
    { 'en': ['I', 'know', 'he', 'is', 'busy.'], 'ar': ['أنا', 'أعلم', 'أنه', 'مشغول.'] },
    { 'en': ['He', 'does', 'not', 'smoke.'], 'ar': ['إنه', 'ليس', 'مدخنا.'] },
    { 'en': ['Please', 'help', 'me', 'take', 'this', 'down.'], 'ar': ['ساعدني', 'في', 'حمل', 'هذا', 'إلى', 'الأسفل', 'من', 'فضلك.'] },
    { 'en': ['I', 'really', 'liked', 'talking', 'to', 'Tom.'], 'ar': ['أحببت', 'حقا', 'الحديث', 'مع', 'توم.'] },
    { 'en': ['I', 'trust', 'you.'], 'ar': ['أنا', 'أثق', 'بك.'] },
    { 'en': ['Write', 'your', 'name', 'in', 'full.'], 'ar': ['اكتب', 'اسمك', 'كاملاً'] },
    { 'en': ["We're", 'at', 'home.'], 'ar': ['نحن', 'في', 'المنزل'] },
    { 'en': ['I', "don't", 'for', 'a', 'moment', 'doubt', 'your', 'honesty.'], 'ar': ['لا', 'أشكّ', 'لحظة', 'في', 'صدقك.'] },
    { 'en': ['I', 'have', 'to', 'go', 'now.'], 'ar': ['يجب', 'علي', 'أن', 'أذهب', 'الآن.'] },
    { 'en': ['This', 'hotel', 'was', 'built', 'last', 'year.'], 'ar': ['بُني', 'هذا', 'الفندق', 'العام', 'الماضي.'] },
    { 'en': ['Are', 'you', 'ready', 'to', 'go', 'home', 'now?'], 'ar': ['هل', 'أنت', 'جاهز', 'للذهاب', 'للبيت', 'الآن؟'] },
    { 'en': ['Kyoto', 'is', 'visited', 'by', 'many', 'tourists.'], 'ar': ['كيوتو', 'يزورها', 'الكثير', 'من', 'السياح', '.'] },
    { 'en': ['I', 'cannot', 'tell', 'how', 'this', 'is', 'done.'], 'ar': ['لا', 'أعرف', 'كيف', 'يُفعل', 'ذلك.'] },
    { 'en': ["You'd", 'better', 'not', 'eat', 'too', 'much.'], 'ar': ['من', 'الأفضل', 'أن', 'لا', 'تُكثر', 'من', 'الأكل.'] },
    { 'en': ['A', 'lot', 'of', 'people', 'do', 'that.'], 'ar': ['كثير', 'من', 'الناس', 'يقومون', 'بذلك.'] },
    { 'en': ["I've", 'met', 'Tom', 'before.'], 'ar': ['التقيت', 'بتوم', 'قبل', 'الآن.'] },
    { 'en': ['Thank', 'you', 'for', 'your', 'interest.'], 'ar': ['شكراً', 'لكَ', 'على', 'إهتمامك.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'radio?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'مذياعك؟'] },
    { 'en': ['I', "didn't", 'do', 'anything', 'illegal.'], 'ar': ['أنا', 'لم', 'أفعل', 'أى', 'شىء', 'غير', 'قانوني.'] },
    { 'en': ['He', 'was', 'wounded', 'in', 'the', 'shoulder.'], 'ar': ['جرح', 'كتفه.'] },
    { 'en': ['We', 'only', 'have', 'tea.'], 'ar': ['ليس', 'عندنا', 'إلا', 'الشاي.'] },
    { 'en': ['Charity', 'begins', 'at', 'home.'], 'ar': ['الأقربون', 'أولى', 'بالمعروف.'] },
    { 'en': ["Nobody's", 'perfect.'], 'ar': ['لا', 'يوجد', 'شخص', 'معصوم.'] },
    { 'en': ['Tom', 'became', 'depressed', 'after', 'Mary', 'left', 'him.'], 'ar': ['أصاب', 'الإحباط', 'توم', 'بعدما', 'تركته', 'ماري.'] },
    { 'en': ["I'm", 'not', 'your', 'slave.'], 'ar': ['أنا', 'لست', 'عبدك.'] },
    { 'en': ['We', 'require', 'your', 'help.'], 'ar': ['نحتاج', 'إلى', 'مساعدتك.'] },
    { 'en': ["It's", 'very', 'cold.'], 'ar': ['إن', 'الجو', 'قارس.'] },
    { 'en': ['You', 'can', 'do', 'it.'], 'ar': ['يمكنك', 'أن', 'تفعلها!'] },
    { 'en': ['I', 'dropped', 'my', 'keys.'], 'ar': ['سقطت', 'منّي', 'مفاتيحي.'] },
    { 'en': ['Do', 'what', 'you', 'will.'], 'ar': ['افعل', 'ما', 'شئت.'] },
    { 'en': ['I', 'understand,', 'but', 'I', 'cannot', 'agree.'], 'ar': ['فهمت،', 'لكني', 'لا', 'أوافق.'] },
    { 'en': ['There', 'are', 'fifty', 'members', 'in', 'this', 'club.'], 'ar': ['في', 'هذا', 'النادي', 'خمسون', 'عضوا.'] },
    { 'en': ['Maybe', 'Tom', 'can', 'do', 'something', 'to', 'help.'], 'ar': ['ربما', 'توم', 'يستطيع', 'أن', 'يفعل', 'شيئاً', 'للمساعدة.'] },
    { 'en': ['When', 'did', 'you', 'have', 'time', 'to', 'do', 'that?'], 'ar': ['متى', 'كان', 'لديك', 'الوقت', 'لتفعل', 'ذلك؟'] },
    { 'en': ['Japan', 'is', 'in', 'the', 'eastern', 'part', 'of', 'Asia.'], 'ar': ['اليابان', 'هي', 'جزء', 'آسيا', 'الشرقي.'] },
    { 'en': ["I'm", 'married', 'and', 'I', 'have', 'children.'], 'ar': ['أنا', 'متزوّجة', 'و', 'لديّ', 'أطفال.'] },
    { 'en': ['She', "didn't", 'like', 'him.'], 'ar': ['لم', 'تكن', 'تحبه.'] },
    { 'en': ['Tom', "didn't", 'want', 'to', 'look', 'like', 'a', 'tourist.'], 'ar': ['توم', 'لا', 'يريد', 'ان', 'يبدو', 'كسائح', '.'] },
    { 'en': ["Let's", 'help', 'each', 'other.'], 'ar': ['لنساعد', 'بعضنا.'] },
    { 'en': ['If', 'it', 'rains', 'tomorrow,', 'will', 'you', 'stay', 'at', 'home?'], 'ar': ['هل', 'ستبقى', 'في', 'المنزل', 'إذا', 'أمطرت', 'غداً؟'] },
    { 'en': ['May', 'I', 'use', 'your', 'telephone?'], 'ar': ['هل', 'لي', 'أن', 'أستخدم', 'هاتفك؟'] },
    { 'en': ['He', 'is', 'the', 'baby', 'of', 'the', 'family.'], 'ar': ['هو', 'طفل', 'العائلة.'] },
    { 'en': ["There's", 'no', 'doubt.'], 'ar': ['لا', 'يوجد', 'شك.'] },
    { 'en': ['Some', 'people', 'in', 'the', 'world', 'suffer', 'from', 'hunger.'], 'ar': ['في', 'هذا', 'العالم', 'يعاني', 'بعضُ', 'الناسِ', 'الجوعَ.'] },
    { 'en': ['Show', 'me', 'your', 'passport,', 'please.'], 'ar': ['أريني', 'جواز', 'سفرك،', 'لو', 'سمحت.'] },
    { 'en': ['I', "won't", 'lose!'], 'ar': ['لن', 'أُهزم!'] },
    { 'en': ['Watch', 'out!'], 'ar': ['انتبه'] },
    { 'en': ['I', 'cannot', 'give', 'you', 'a', 'definite', 'answer', 'today.'], 'ar': ['لا', 'يمكنني', 'إعطائك', 'جواباً', 'نهائياً', 'اليوم.'] },
    { 'en': ['How', 'much', 'did', 'the', 'tickets', 'cost', 'you?'], 'ar': ['كم', 'كلّفتك', 'التذاكر؟'] },
    { 'en': ['Come', 'in.'], 'ar': ['تفضل', 'بالدخول.'] },
    { 'en': ['She', 'cooked', 'herself', 'a', 'good', 'meal.'], 'ar': ['أعدت', 'لنفسها', 'طعاماً', 'شهياً.'] },
    { 'en': ['You', 'work', 'too', 'hard.'], 'ar': ['إنك', 'تجهد', 'نفسك', 'بالعمل.'] },
    { 'en': ['Clean', 'the', 'window', 'with', 'a', 'damp', 'cloth.'], 'ar': ['نظف', 'زجاج', 'النافذة', 'بقطعة', 'قماش', 'مبلولة.'] },
    { 'en': ['I', 'am', 'grateful', 'to', 'you', 'for', 'your', 'help.'], 'ar': ['أنا', 'شاكر', 'لمساعدتك', 'لي.'] },
    { 'en': ['I', 'was', 'in', 'Tokyo', 'yesterday.'], 'ar': ['كنت', 'في', 'طوكيو', 'بالأمس.'] },
    { 'en': ['Please', 'open', 'your', 'suitcase.'], 'ar': ['الرجاء', 'فتح', 'حقيبتك.'] },
    { 'en': ['You', 'can', 'go', 'home', 'if', 'you', 'like.'], 'ar': ['يمكنك', 'العودة', 'إلى', 'البيت', 'لو', 'أردت.'] },
    { 'en': ['I', 'know', 'what', "you're", 'trying', 'to', 'do.'], 'ar': ['أنا', 'أعرف', 'ما', 'الذى', 'تحاول', 'فعله.'] },
    { 'en': ['Check', 'again.'], 'ar': ['راجع', 'مُجدداً.'] },
    { 'en': ['I', "don't", 'like', 'to', 'cook', 'when', "it's", 'hot', 'outside.'], 'ar': ['لا', 'أحب', 'أن', 'أطبخ', 'عندما', 'يكون', 'الجو', 'حارّاً.'] },
    { 'en': ['Tom', 'did', 'the', 'same', 'thing', 'Mary', 'did.'], 'ar': ['قام', 'توم', 'بنفس', 'الشيء', 'الذي', 'قامت', 'به', 'ماريا'] },
    { 'en': ['I', 'forgot', 'to', 'buy', 'bread.'], 'ar': ['نسيتُ', 'شراء', 'الخبز.'] },
    { 'en': ['I', 'have', 'no', 'money.'], 'ar': ['لدي', 'نقود'] },
    { 'en': ['We', 'are', 'facing', 'a', 'violent', 'crisis.'], 'ar': ['نحن', 'بصدد', 'كارثة', 'عنيفة.'] },
    { 'en': ['Look', 'over', 'the', 'papers,', 'please.'], 'ar': ['من', 'فضلك', 'راجع', 'تلك', 'الأوراق.'] },
    { 'en': ['My', 'brother', 'skips', 'school', 'often.'], 'ar': ['غالبا', 'ما', 'يغيب', 'أخي', 'عن', 'المدرسة.'] },
    { 'en': ["I'll", 'drive', 'you', 'home.'], 'ar': ['سأوصلك', 'إلى', 'البيت.'] },
    { 'en': ['He', 'died', 'yesterday.'], 'ar': ['مات', 'البارحة.'] },
    { 'en': ['It', 'is', 'unfortunately', 'true.'], 'ar': ['للأسف', 'ذلك', 'صحيح.'] },
    { 'en': ['The', 'storm', 'caused', 'a', 'lot', 'of', 'damage.'], 'ar': ['تسببت', 'العاصفة', 'بخسائر', 'فادحة.'] },
    { 'en': ['Have', 'you', 'been', 'shot?'], 'ar': ['هل', 'أصبت', 'بالرصاص؟'] },
    { 'en': ["I'll", 'call', 'you', 'back', 'later.'], 'ar': ['سأتصل', 'بك', 'لاحقاً.'] },
    { 'en': ['It', 'is', 'almost', 'three.'], 'ar': ['لقد', 'قاربت', 'الساعة', 'على', 'الثالثة.'] },
    { 'en': ['Come', 'swim', 'with', 'me.'], 'ar': ['تعال', 'و', 'اسبح', 'معي.'] },
    { 'en': ['He', 'went', 'to', 'the', 'shop.'], 'ar': ['ذهب', 'إلى', 'الدكان.'] },
    { 'en': ['I', "don't", 'know', 'anything', 'about', 'his', 'past.'], 'ar': ['لا', 'أعرف', 'شيئاً', 'عن', 'ماضيه.'] },
    { 'en': ['I', 'saw', 'Tom', 'at', 'the', 'library.'], 'ar': ['رأيت', 'توم', 'في', 'المكتبة'] },
    { 'en': ['The', 'clock', 'stopped.'], 'ar': ['توقفت', 'الساعة', 'عن', 'العمل.'] },
    { 'en': ['I', 'want', 'something', 'sweet.'], 'ar': ['أريد', 'شيئاً', 'حلواً.'] },
    { 'en': ["Don't", 'look', 'at', 'him!'], 'ar': ['لا', 'تنظر', 'إليه!'] },
    { 'en': ['Never', 'in', 'my', 'life', 'have', 'I', 'heard', 'such', 'a', 'thing.'], 'ar': ['لم', 'أسمع', 'في', 'حياتي', 'بهذا', 'البتة.'] },
    { 'en': ['He', 'has', 'a', 'lot', 'of', 'money.'], 'ar': ['لديه', 'الكثير', 'من', 'المال.'] },
    { 'en': ['What', 'is', 'the', 'time?'], 'ar': ['كَم', 'الساعة؟'] },
    { 'en': ['The', 'rear', 'gate', 'was', 'open.'], 'ar': ['كانت', 'البوابة', 'الخلفية', 'مفتوحة', '.'] },
    { 'en': ['Your', 'bath', 'is', 'ready.'], 'ar': ['حمّامك', 'جاهز.'] },
    { 'en': ['I', 'am', 'hungry.'], 'ar': ['أشعر', 'بالجوع.'] },
    { 'en': ['We', 'met', 'a', 'writer.'], 'ar': ['قابلنا', 'كاتباً.'] },
    { 'en': ['What', 'do', 'you', 'think?'], 'ar': ['ماذا', 'ترون؟'] },
    { 'en': ["Tom's", 'here.'], 'ar': ['توم', 'هنا'] },
    { 'en': ['I', "didn't", 'know', 'he', 'drank', 'so', 'much.'], 'ar': ['لم', 'أعلم', 'أنه', 'شَرِبَ', 'كثيرًا.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'your', 'Halloween', 'party.'], 'ar': ['أنا', 'أتتطلع', 'لحفلة', 'الهالووين', 'خاصتك.'] },
    { 'en': ['Tom', 'refused', 'to', 'leave', 'without', 'Mary.'], 'ar': ['توم', 'رفض', 'أن', 'يغادر', 'بدون', 'ماري'] },
    { 'en': ['We', 'found', 'the', 'front', 'door', 'locked.'], 'ar': ['وجدنا', 'الباب', 'الأمامي', 'مغلق.'] },
    { 'en': ['Tom', 'was', 'naked.'], 'ar': ['كان', 'توم', 'عاريًا.'] },
    { 'en': ['She', 'is', 'very', 'pretty.'], 'ar': ['إنها', 'جميلة', 'جداً.'] },
    { 'en': ['Write', 'your', 'address', 'here.'], 'ar': ['اِكتب', 'عنوانك', 'هنا.'] },
    { 'en': ['Are', 'your', 'hands', 'clean?'], 'ar': ['هل', 'يداك', 'نظيفتان؟'] },
    { 'en': ["I'm", 'really', 'not', 'hungry.'], 'ar': ['أنا', 'حقاً', 'لستُ', 'جائعاً.'] },
    { 'en': ['Give', 'me', 'the', 'key.'], 'ar': ['أعطني', 'المفتاح.'] },
    { 'en': ["I'll", 'go', 'and', 'have', 'another', 'look.'], 'ar': ['سأذهب', 'وألقي', 'نظرة', 'أخرى.'] },
    { 'en': ['Those', 'three', 'are', 'his', 'daughters.'], 'ar': ['هؤلاء', 'الثلاثة', 'بناته.'] },
    { 'en': ['Do', 'you', 'remember', 'the', 'day', 'when', 'we', 'first', 'met?'], 'ar': ['هل', 'تذكر', 'اليوم', 'الذي', 'تقابلنا', 'فيه', 'أول', 'مرة؟'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'drive', 'a', 'car?'], 'ar': ['هل', 'تعرف', 'كيف', 'تقود', 'سيارة؟'] },
    { 'en': ['Translate', 'the', 'underlined', 'part.'], 'ar': ['ترجم', 'ما', 'تحته', 'خط.'] },
    { 'en': ['I', "don't", 'want', 'to', 'speak', 'about', 'her.'], 'ar': ['لا', 'أُريد', 'أن', 'أتحدث', 'عنها.'] },
    { 'en': ["What's", 'she', 'doing?'], 'ar': ['ما', 'الذي', 'تفعله؟'] },
    { 'en': ['I', 'slept', 'on', 'the', 'ground.'], 'ar': ['لقد', 'نمتُ', 'على', 'الأرض.'] },
    { 'en': ['All', 'Tom', 'really', 'needed', 'was', 'to', 'be', 'left', 'alone.'], 'ar': ['كل', 'ما', 'كان', 'توم', 'يحتاجه', 'كان', 'أن', 'يُترك', 'وحيداً.'] },
    { 'en': ['He', 'looks', 'very', 'good.'], 'ar': ['إنهُ', 'يبدو', 'بحالة', 'جيداً', 'جداً.'] },
    { 'en': ['He', 'came', 'to', 'ask', 'us', 'to', 'help', 'him.'], 'ar': ['جاء', 'ليطلب', 'منا', 'أن', 'نساعده.'] },
    { 'en': ["I'd", 'be', 'happy', 'to', 'help', 'you.'], 'ar': ['سيسعدني', 'مساعدتك.'] },
    { 'en': ['Do', 'you', 'believe', 'in', 'the', 'existence', 'of', 'ghosts?'], 'ar': ['هل', 'تؤمن', 'بوجود', 'الأشباح؟'] },
    { 'en': ['I', 'recognized', 'him', 'immediately.'], 'ar': ['عرفته', 'مباشرة.'] },
    { 'en': ['Just', 'breathe', 'normally.'], 'ar': ['فقط', 'تنفس', 'طبيعي.'] },
    { 'en': ["It's", 'fall', 'now.'], 'ar': ['نحن', 'في', 'الخريف', 'الآن.'] },
    { 'en': ['Tom', 'still', 'thinks', "we're", 'friends.'], 'ar': ['ما', 'يزال', 'توم', 'يظننا', 'أصدقاء.'] },
    { 'en': ['I', 'slapped', 'his', 'face.'], 'ar': ['صفعته', 'على', 'وجهه.'] },
    { 'en': ['I', "don't", 'pretend', 'to', 'understand', 'women.'], 'ar': ['لا', 'أدعي', 'فهم', 'النساء.'] },
    { 'en': ['Good', 'morning.', "It's", 'time', 'to', 'wake', 'up.'], 'ar': ['صباح', 'الخير.', 'إنهُ', 'وقت', 'الإستيقاظ.'] },
    { 'en': ['That', 'changes', 'nothing.'], 'ar': ['هذا', 'لا', 'يغير', 'شيء.'] },
    { 'en': ['Here', 'comes', 'the', 'bus!'], 'ar': ['ها', 'قد', 'وصلت', 'الحافلة!'] },
    { 'en': ['Tom', "couldn't", 'stand', 'it', 'any', 'longer.'], 'ar': ['لم', 'يكن', 'يعد', 'توم', 'يطيقه', 'أكثر', 'من', 'ذلك.'] },
    { 'en': ["I'm", 'sure', "you're", 'right.'], 'ar': ['أنا', 'متأكد', 'أنكَ', 'محق.'] },
    { 'en': ['Give', 'me', 'the', 'spoon.'], 'ar': ['أعطني', 'الملعقة.'] },
    { 'en': ["Here's", 'how', 'to', 'use', 'it.'], 'ar': ['هاك', 'طريقة', 'استخدامها.'] },
    { 'en': ['My', 'son', 'came', 'to', 'my', 'room.'], 'ar': ['جاء', 'ابني', 'إلى', 'غرفتي.'] },
    { 'en': ['Take', 'me', 'to', 'your', 'leader.'], 'ar': ['خذني', 'إلى', 'قائدكم.'] },
    { 'en': ["Where's", 'my', 'book?'], 'ar': ['أين', 'كتابي؟'] },
    { 'en': ["It's", 'time', 'to', 'do', 'something.'], 'ar': ['حان', 'الوقت', 'لفعل', 'شيئاً', 'ما.'] },
    { 'en': ['Eleven', 'students', 'received', 'the', 'award.'], 'ar': ['استلم', 'الجائزة', 'أحد', 'عشر', 'طالباً.'] },
    { 'en': ['Madrid', 'is', 'the', 'capital', 'of', 'Spain.'], 'ar': ['مدريد', 'عاصمة', 'أسبانيا.'] },
    { 'en': ['How', 'is', 'the', 'weather?'], 'ar': ['كيف', 'حال', 'الطقس؟'] },
    { 'en': ["I'll", 'never', 'forget', 'that', 'feeling.'], 'ar': ['لن', 'أنسى', 'ذلك', 'الشعور', 'أبدا.'] },
    { 'en': ["I'd", 'rather', 'be', 'a', 'bird', 'than', 'a', 'fish.'], 'ar': ['أُفضِّلُ', 'أن', 'أكون', 'عصفورًا', 'على', 'أن', 'أكون', 'سمكة.'] },
    { 'en': ['Are', 'you', 'happy', 'here?'], 'ar': ['هل', 'أنت', 'مبسوط', 'هنا؟'] },
    { 'en': ['It', 'looks', 'like', 'this', 'car', 'is', 'his.'], 'ar': ['كأنّ', 'هذه', 'السيارة', 'له.'] },
    { 'en': ['You', 'have', 'made', 'many', 'mistakes.'], 'ar': ['لقد', 'ارتكبت', 'الكثير', 'من', 'الأخطاء.'] },
    { 'en': ['I', 'still', "can't", 'believe', 'we', 'won.'], 'ar': ['ما', 'زلت', 'غير', 'مصدّقًا', 'أننا', 'فزنا.'] },
    { 'en': ['Can', 'you', 'find', 'it?'], 'ar': ['أيمكنك', 'إيجادها؟'] },
    { 'en': ['How', 'many', 'magazines', 'are', 'on', 'the', 'desk?'], 'ar': ['كم', 'مجلة', 'على', 'المكتب؟'] },
    { 'en': ['Mary', 'is', 'my', 'wife.'], 'ar': ['تكون', 'ماري', 'زوجتي.'] },
    { 'en': ['I', 'love', 'movies.'], 'ar': ['أحب', 'الأفلام.'] },
    { 'en': ['He', 'worked', 'hard.'], 'ar': ['عمل', 'بجد.'] },
    { 'en': ['I', 'must', 'adjust', 'my', 'watch.', "It's", 'slow.'], 'ar': ['يجب', 'أن', 'أضبط', 'ساعتي.', 'إنها', 'بطيئة.'] },
    { 'en': ["I'm", 'afraid', 'of', 'bats.'], 'ar': ['أنا', 'اخشى', 'الخفافيش'] },
    { 'en': ['Do', 'you', 'really', 'want', 'me', 'to', 'go', 'there', 'right', 'now?'], 'ar': ['هل', 'تريدني', 'حقاً', 'أن', 'أذهب', 'إلى', 'هناك', 'الأن؟'] },
    { 'en': ['I', 'work', 'every', 'day', 'except', 'for', 'Sundays.'], 'ar': ['أعمل', 'كل', 'يوم', 'إلا', 'يوم', 'الأحد.'] },
    { 'en': ['I', 'have', 'three', 'cameras.'], 'ar': ['لدي', 'ثلاث', 'كاميرات.'] },
    { 'en': ['I', 'was', 'here', 'yesterday', 'for', 'three', 'hours.'], 'ar': ['كنت', 'هنا', 'البارحة', 'لمدة', 'ثلاث', 'ساعات.'] },
    { 'en': ['This', 'is', 'enough', 'for', 'me.'], 'ar': ['هذا', 'يكفيني.'] },
    { 'en': ['How', 'much', 'did', 'you', 'win?'], 'ar': ['كم', 'رَبِحتَ؟'] },
    { 'en': ['Please', 'delete', 'this', 'file.'], 'ar': ['من', 'فضلك', 'إلغِ', 'هذا', 'الملفّ.'] },
    { 'en': ['Stand', 'back,', 'please.'], 'ar': ['تنحّى', 'جانبا', 'إذا', 'سمحت.'] },
    { 'en': ["I'm", 'a', 'baker.'], 'ar': ['أنا', 'خباز'] },
    { 'en': ['Do', 'you', 'like', 'to', 'travel', 'by', 'yourself?'], 'ar': ['أتحب', 'السفر', 'بمفردك؟'] },
    { 'en': ['You', 'have', 'cleaned', 'your', 'shoes,', "haven't", 'you?'], 'ar': ['لقد', 'نظفت', 'حذائك،', 'أليس', 'كذلك؟'] },
    { 'en': ['How', 'many', 'books', 'did', 'you', 'read?'], 'ar': ['كم', 'كتاباً', 'قرأت؟'] },
    { 'en': ['Did', 'you', 'drive', 'her', 'home', 'last', 'night?'], 'ar': ['هل', 'أوصلتها', 'إلى', 'المنزل', 'ليلة', 'الأمس؟'] },
    { 'en': ['Tom', 'is', 'living', 'with', 'his', 'uncle', 'now.'], 'ar': ['توم', 'يعيش', 'مع', 'عمه', 'الآن.'] },
    { 'en': ["Haven't", 'we', 'met', 'before?'], 'ar': ['هل', 'إلتقينا', 'من', 'قبل؟'] },
    { 'en': ["Don't", 'argue.'], 'ar': ['لا', 'تُجادِل.'] },
    { 'en': ['I', 'know', 'only', 'this.'], 'ar': ['لا', 'أعرف', 'إلّا', 'هذا.'] },
    { 'en': ['I', "don't", 'want', 'to', 'look', 'silly.'], 'ar': ['لا', 'أريد', 'أن', 'أبدو', 'غبيّا.'] },
    { 'en': ['You', 'must', 'control', 'yourself.'], 'ar': ['عليك', 'أن', 'تُحكم', 'السيطرة', 'على', 'نفسك.'] },
    { 'en': ['Tom', 'lives', 'in', 'a', 'poor', 'neighborhood.'], 'ar': ['يسكن', 'توم', 'في', 'حي', 'فقير.'] },
    { 'en': ['The', 'grass', 'needs', 'cutting.'], 'ar': ['العشب', 'بحاجة', 'إلى', 'التجديب.'] },
    { 'en': ['Have', 'a', 'seat.'], 'ar': ['استرح.'] },
    { 'en': ['What', 'happened', 'to', 'your', 'daughter?'], 'ar': ['ماذا', 'حدث', 'لطفلتك؟'] },
    { 'en': ['I', 'love', 'reading', 'books.'], 'ar': ['أحب', 'قراءة', 'الكتب.'] },
    { 'en': ['Are', 'you', 'coming?'], 'ar': ['هل', 'أنت', 'آتٍ؟'] },
    { 'en': ['That', "wouldn't", 'surprise', 'me.'], 'ar': ['لن', 'يفاجئني', 'ذلك.'] },
    { 'en': ['I', 'wish', 'I', 'could', 'go', 'to', 'the', 'concert.'], 'ar': ['ليتني', 'كنت', 'أستطيع', 'الذهاب', 'إلى', 'الحفلة', 'الغنائية.'] },
    { 'en': ['I', 'have', 'been', 'walking', 'for', 'over', 'four', 'hours.'], 'ar': ['مشيت', 'لأكثر', 'من', 'أربع', 'ساعات.'] },
    { 'en': ['Did', 'you', 'go', 'out', 'last', 'night?'], 'ar': ['هل', 'خرجت', 'ليلة', 'الأمس؟'] },
    { 'en': ['Muslims', 'worship', 'God.'], 'ar': ['المسلمون', 'يعبدون', 'الله.'] },
    { 'en': ['He', 'shut', 'the', 'door.'], 'ar': ['أغلقَ', 'الباب.'] },
    { 'en': ['You', 'may', 'speak.'], 'ar': ['يمكنك', 'التحدث.'] },
    { 'en': ["Don't", 'do', 'it!'], 'ar': ['لا', 'تفعل', 'ذلك!'] },
    { 'en': ['My', 'arm', 'still', 'hurts.'], 'ar': ['ما', 'زال', 'ذراعي', 'يؤلمني.'] },
    { 'en': ['What', 'time', 'do', 'you', 'usually', 'go', 'to', 'bed?'], 'ar': ['أي', 'ساعة', 'تنام؟'] },
    { 'en': ['We', 'have', 'no', 'school', 'tomorrow.'], 'ar': ['نحن', 'مجازون', 'من', 'المدرسة', 'غدًا.'] },
    { 'en': ['You', 'must', 'go', 'at', 'once.'], 'ar': ['عليك', 'أن', 'تذهب', 'حالاً.'] },
    { 'en': ["I'm", 'just', 'looking,', 'thank', 'you.'], 'ar': ['شكراً,', 'أنا', 'فقط', 'أتفرج.'] },
    { 'en': ["Let's", 'go', 'see', 'if', 'Tom', 'can', 'help', 'us.'], 'ar': ['هيا', 'بنا', 'نذهب', 'لنرى', 'إذا', 'كان', 'توم', 'يستطيع', 'أن', 'يساعدنا.'] },
    { 'en': ["I'm", 'afraid', 'the', 'doctor', 'is', 'out.'], 'ar': ['الطبيب', 'غير', 'موجود', 'الآن', 'للأسف.'] },
    { 'en': ['The', 'door', 'is', 'locked.'], 'ar': ['الباب', 'مغلق.'] },
    { 'en': ['He', 'finally', 'arrived.'], 'ar': ['وصل', 'أخيرا.'] },
    { 'en': ['Do', 'you', 'have', 'an', 'address?'], 'ar': ['هل', 'لديك', 'عنوان؟'] },
    { 'en': ['Stop', 'hitting', 'the', 'cat!'], 'ar': ['توقف', 'عن', 'ضرب', 'القِطة!'] },
    { 'en': ['Earth', 'is', 'a', 'beautiful', 'planet.'], 'ar': ['الأرض', 'كوكبٌ', 'جميل.'] },
    { 'en': ["Let's", 'split', 'the', 'bill', 'four', 'ways.'], 'ar': ['دعينا', 'نقسم', 'الفاتورة', 'بأربعة', 'طرق.'] },
    { 'en': ["That's", 'not', 'your', 'job.'], 'ar': ['هذا', 'ليس', 'عملك.'] },
    { 'en': ['You', 'are', 'responsible', 'for', 'what', 'you', 'do.'], 'ar': ['أنت', 'مسؤول', 'عمّا', 'تفعل.'] },
    { 'en': ["I'll", 'go', 'to', 'France', 'to', 'study', 'painting.'], 'ar': ['سأذهب', 'إلى', 'فرنسا', 'لأدرس', 'الرسم.'] },
    { 'en': ['This', 'letter', 'is', 'addressed', 'to', 'you.'], 'ar': ['هذه', 'الرسالة', 'موجهة', 'إليك.'] },
    { 'en': ['I', "don't", 'want', 'you', 'to', 'worry', 'about', 'me.'], 'ar': ['لا', 'أريدك', 'أن', 'تقلق', 'بشأني.'] },
    { 'en': ['Giant', 'pandas', 'live', 'only', 'in', 'China.'], 'ar': ['حيوانات', 'الباندا', 'الضخمة', 'تعيش', 'في', 'الصين', 'فقط.'] },
    { 'en': ['Why', 'do', 'you', 'work', 'here?'], 'ar': ['لمَ', 'تعمل', 'هنا؟'] },
    { 'en': ['Our', 'teacher', 'will', 'go', 'to', 'the', 'USA', 'next', 'month.'], 'ar': ['سيذهب', 'أستاذنا', 'إلى', 'الولايات', 'المتحدة', 'الشهر', 'القادم.'] },
    { 'en': ['When', 'will', 'you', 'come', 'home?'], 'ar': ['متى', 'سترجع', 'إلى', 'المنزل؟'] },
    { 'en': ['Some', 'snakes', 'are', 'poisonous.'], 'ar': ['بعض', 'الثعابين', 'سامة.'] },
    { 'en': ['Their', 'friendship', 'moved', 'us', 'deeply.'], 'ar': ['أثّرتْ', 'فينا', 'صداقتهم', 'بشدة.'] },
    { 'en': ['You', 'can', 'take', 'off', 'the', 'bandage', 'tomorrow.'], 'ar': ['بإمكانك', 'خلع', 'الضّمادة', 'غدا.'] },
    { 'en': ["I've", 'been', 'here', 'before.'], 'ar': ['سبق', 'لي', 'أن', 'كنت', 'هنا.'] },
    { 'en': ['Tom', 'went', 'downtown', 'this', 'afternoon.'], 'ar': ['ذهب', 'توم', 'لمركز', 'المدينة', 'بعد', 'الظهر.'] },
    { 'en': ['Your', 'house', 'is', 'three', 'times', 'as', 'large', 'as', 'mine.'], 'ar': ['منزلك', 'أكبر', 'من', 'منزلي', 'بثلاث', 'مرات.'] },
    { 'en': ['I', 'am', 'not', 'thirsty.'], 'ar': ['لا', 'أشعر', 'بالعطش.'] },
    { 'en': ['The', 'rain', 'has', 'stopped.'], 'ar': ['توقّف', 'المطر.'] },
    { 'en': ["I'll", 'stay', 'there', 'till', 'six', "o'clock."], 'ar': ['سأبقى', 'هناك', 'حتى', 'الساعة', 'السادسة.'] },
    { 'en': ['I', 'understand', 'everything.'], 'ar': ['أفهم', 'كل', 'شيء'] },
    { 'en': ['Tom', "could've", 'said', 'something.'], 'ar': ['يمكن', 'لتوم', 'أن', 'يقول', 'شيئا.'] },
    { 'en': ['Tom', 'said', "that's", 'what', "he's", 'always', 'wanted.'], 'ar': ['توم', 'قال', 'بماذا', 'يريد', 'هو', 'دائما.'] },
    { 'en': ['Tom', 'gave', 'the', 'police', 'a', 'false', 'address.'], 'ar': ['أعطى', 'توم', 'الشرطة', 'عنوانا', 'خاطئا.'] },
    { 'en': ['I', 'play', 'violin.'], 'ar': ['أعزف', 'الكمان.'] },
    { 'en': ['You', 'may', 'not', 'come', 'in.'], 'ar': ['لا', 'تستطيع', 'الدخول'] },
    { 'en': ['It', 'was', 'very', 'hot', 'this', 'afternoon.'], 'ar': ['كان', 'الجو', 'حاراً', 'جداً', 'ظهيرة', 'اليوم.'] },
    { 'en': ['We', 'just', 'have', 'to', 'explain', 'why.'], 'ar': ['علينا', 'توضيح', 'السبب', 'فحسب.'] },
    { 'en': ['The', 'man', 'lost', 'all', 'hope.'], 'ar': ['فقد', 'الرجل', 'الأمل', 'كلياً.'] },
    { 'en': ['I', 'have', 'a', 'big', 'family.'], 'ar': ['لدي', 'عائلة', 'كبيرة'] },
    { 'en': ['Tom', "didn't", 'expect', 'any', 'help', 'from', 'Mary.'], 'ar': ['لم', 'يتوقّع', 'توم', 'أيّة', 'مساعدة', 'من', 'ماري.'] },
    { 'en': ['Did', 'you', 'hear', 'that', 'sound?'], 'ar': ['هل', 'سمعت', 'ذلك', 'الصوت؟'] },
    { 'en': ['Do', 'I', 'have', 'to', 'answer', 'all', 'of', 'the', 'questions?'], 'ar': ['هل', 'يجب', 'علي', 'أن', 'أجيب', 'علی', 'أسئلتك', 'كلها؟'] },
    { 'en': ['He', 'is', 'on', 'the', 'point', 'of', 'leaving', 'for', 'Canada.'], 'ar': ['إنه', 'على', 'وشك', 'المغادرة', 'إلى', 'كندا.'] },
    { 'en': ['Which', 'do', 'you', 'prefer,', 'spring', 'or', 'autumn?'], 'ar': ['أتفضل', 'الربيع', 'أم', 'الخريف؟'] },
    { 'en': ['Can', 'I', 'speak', 'to', 'the', 'doctor?'], 'ar': ['أيمكنني', 'أن', 'أكلم', 'الطبيب؟'] },
    { 'en': ['This', 'doll', 'is', 'a', 'gift', 'from', 'my', 'aunt.'], 'ar': ['هذه', 'الدمية', 'هدية', 'من', 'خالتي.'] },
    { 'en': ['He', 'loves', 'her.'], 'ar': ['إنه', 'يحبها.'] },
    { 'en': ['The', 'signal', 'was', 'red.'], 'ar': ['كانت', 'الإشارة', 'حمراء.'] },
    { 'en': ['They', 'are', 'watching', "what's", 'happening', 'closely.'], 'ar': ['إنهم', 'يراقبون', 'ما', 'يحدث', 'عن', 'كثب.'] },
    { 'en': ['No', 'problem!'], 'ar': ['لا', 'مشكلة!'] },
    { 'en': ['Where', 'is', 'the', 'Japanese', 'Embassy?'], 'ar': ['أين', 'السفارة', 'اليابانية؟'] },
    { 'en': ['Have', 'you', 'finished', 'your', 'duties?'], 'ar': ['هل', 'أنهيت', 'واجباتك؟'] },
    { 'en': ["Don't", 'touch', 'me', 'with', 'your', 'soapy', 'hands.'], 'ar': ['لا', 'تلمسني', 'وفي', 'يدك', 'أثر', 'الصابون.'] },
    { 'en': ['Call', 'the', 'police!'], 'ar': ['اطلب', 'الشرطة!'] },
    { 'en': ['Thanks.'], 'ar': ['شكرا.'] },
    { 'en': ['I', 'am', 'loved', 'by', 'all', 'my', 'family.'], 'ar': ['تحبني', 'كل', 'عائلتي.'] },
    { 'en': ['My', 'sister', 'goes', 'to', 'a', 'university.'], 'ar': ['ترتاد', 'أختي', 'الجامعة.'] },
    { 'en': ['Tom', 'and', 'Mary', 'are', 'playing', 'tennis.'], 'ar': ['يلعب', 'توم', 'وماري', 'كرة', 'المضرب.'] },
    { 'en': ['He', 'asked', 'for', 'a', 'glass', 'of', 'water.'], 'ar': ['لقد', 'طَلَبَ', 'كوب', 'من', 'الماء.'] },
    { 'en': ['Their', 'wedding', 'is', 'tomorrow.'], 'ar': ['حفل', 'زفافهم', 'غداً.'] },
    { 'en': ['Tom', 'was', 'not', 'impressed.'], 'ar': ['توم', 'لم', 'يكن', 'متأثر.'] },
    { 'en': ['He', 'finally', 'decided', 'to', 'get', 'married.'], 'ar': ['وأخيراً', 'قرر', 'أن', 'يتزوج.'] },
    { 'en': ['He', 'must', 'be', 'over', 'sixty.'], 'ar': ['لا', 'شكّ', 'أنه', 'قد', 'تجاوز', 'الستين.'] },
    { 'en': ['He', 'entered', 'the', 'room', 'after', 'you', 'left.'], 'ar': ['دخل', 'الغرفة', 'بعد', 'خروجك.'] },
    { 'en': ['We', 'can', 'open', 'the', 'window.'], 'ar': ['يمكننا', 'فتح', 'النافذة.'] },
    { 'en': ['I', 'talked', 'to', 'friends.'], 'ar': ['تحدثتُ', 'إلى', 'الأصدقاء.'] },
    { 'en': ['You', 'are', 'not', 'a', 'coward.'], 'ar': ['إنك', 'لست', 'جباناً.'] },
    { 'en': ["Let's", 'not', 'talk.'], 'ar': ['دعنا', 'لا', 'نتحدث.'] },
    { 'en': ['My', 'uncle', 'came', 'to', 'see', 'me.'], 'ar': ['أتی', 'عمي', 'لرؤيتي.'] },
    { 'en': ["It's", 'pretty', 'weird.'], 'ar': ['الأمر', 'غريب.'] },
    { 'en': ['I', 'have', 'some', 'stuff', 'to', 'do', 'at', 'home.'], 'ar': ['هناك', 'أمور', 'عليّ', 'فعلها', 'في', 'المنزل.'] },
    { 'en': ["I'm", 'waiting', 'for', 'his', 'telephone', 'call.'], 'ar': ['إني', 'أنتظر', 'اتصالاً', 'هاتفياً', 'منه.'] },
    { 'en': ['Where', 'are', 'the', 'girls?'], 'ar': ['أين', 'الفتيات؟'] },
    { 'en': ['The', 'lake', 'is', 'very', 'deep.'], 'ar': ['البحيرة', 'عميقة', 'جداً.'] },
    { 'en': ['Tom', 'came', 'home', 'after', 'midnight.'], 'ar': ['عاد', 'توم', 'إلى', 'المنزل', 'بعد', 'منتصف', 'الليل.'] },
    { 'en': ['I', "don't", 'know', 'what', 'to', 'do', 'tomorrow.'], 'ar': ['لا', 'أعرف', 'ما', 'أفعلُهُ', 'غداً.'] },
    { 'en': ["I'm", 'really', 'embarrassed.'], 'ar': ['أنا', 'حقاً', 'محرج.'] },
    { 'en': ['I', 'need', 'you.'], 'ar': ['أحتاجك.'] },
    { 'en': ['He', 'looks', 'like', 'an', 'honest', 'man.'], 'ar': ['يبدو', 'أنه', 'رجل', 'أمين', '.'] },
    { 'en': ["I've", 'done', 'business', 'with', 'Tom', 'before.'], 'ar': ['لقد', 'تعاملت', 'مع', 'توم', 'من', 'قبل.'] },
    { 'en': ["It's", 'hot', 'today.'], 'ar': ['الجو', 'حار', 'اليوم.'] },
    { 'en': ['Do', 'you', 'have', 'any', 'advice', 'for', 'me?'], 'ar': ['هل', 'تريد', 'أن', 'تنصحني', 'بشيء', 'ما', '؟'] },
    { 'en': ['Tom', 'washed', 'his', 'face', 'and', 'hands.'], 'ar': ['غسل', 'توم', 'وجهه', 'وكلتا', 'يديه.'] },
    { 'en': ['I', 'like', 'to', 'eat', 'watermelon.'], 'ar': ['أحب', 'أن', 'آكل', 'البطيخ.'] },
    { 'en': ['Everybody', 'gets', 'old.'], 'ar': ['الكل', 'يكبر.'] },
    { 'en': ['Babies', 'often', 'fall', 'down.'], 'ar': ['عادة', 'ما', 'يقع', 'الأطفال.'] },
    { 'en': ['Tom', 'tried', 'to', 'kill', 'me.'], 'ar': ['حاول', 'توم', 'أن', 'يقتلني.'] },
    { 'en': ['The', 'monkey', 'climbed', 'up', 'a', 'tree.'], 'ar': ['تسلق', 'القرد', 'الشجرة.'] },
    { 'en': ['When', 'does', 'it', 'end?'], 'ar': ['متى', 'ينتهي', 'ذلك؟'] },
    { 'en': ['Tell', 'Tom', 'who', 'you', 'met', 'today.'], 'ar': ['أخبر', 'توم', 'بمن', 'التقيت', 'اليوم.'] },
    { 'en': ['I', 'often', 'catch', 'colds.'], 'ar': ['كثيراً', 'ما', 'أصاب', 'بالزكام.'] },
    { 'en': ['It', 'was', 'a', 'one', 'hundred', 'dollar', 'bill.'], 'ar': ['إنها', 'مئة', 'دولار.'] },
    { 'en': ["I've", 'got', 'a', 'headache.'], 'ar': ['لدي', 'صداع', 'في', 'رأسي.'] },
    { 'en': ['I', 'know.'], 'ar': ['أعلم', 'ذلك.'] },
    { 'en': ['This', 'is', 'definitely', 'the', 'best', 'method.'], 'ar': ['لا', 'ريب', 'بأنّ', 'هذه', 'هي', 'أفضل', 'طريقة.'] },
    { 'en': ['I', 'am', 'busy', 'now.'], 'ar': ['أنا', 'مشغول', 'الأن.'] },
    { 'en': ['The', 'boy', 'is', 'thirsty.'], 'ar': ['الولد', 'عطشان.'] },
    { 'en': ['I', 'need', 'to', 'get', 'a', 'stamp.'], 'ar': ['أحتاج', 'طابعاً', 'بريدياً.'] },
    { 'en': ['We', 'went', 'to', 'the', 'movies', 'last', 'night.'], 'ar': ['ذهبنا', 'لمشاهدة', 'فيلم', 'ليلة', 'البارحة.'] },
    { 'en': ["I'm", 'from', 'Zambia.'], 'ar': ['أنا', 'من', 'زامبيا.'] },
    { 'en': ['Tom', 'is', 'in', 'his', 'room', 'sleeping.'], 'ar': ['توم', 'نائم', 'في', 'غرفته.'] },
    { 'en': ['Tom', 'married', 'a', 'local', 'girl.'], 'ar': ['توم', 'تجوز', 'فتاة', 'محلية'] },
    { 'en': ['Are', 'you', 'looking', 'for', 'someone?'], 'ar': ['هل', 'تبحث', 'عن', 'أحد؟'] },
    { 'en': ['I', 'really', 'dig', 'that', 'singer.'], 'ar': ['أحبّ', 'ذلك', 'المغنّي', 'حقّا.'] },
    { 'en': ['My', 'mother', 'is', 'angry.'], 'ar': ['أمي', 'غاضبة.'] },
    { 'en': ['Tell', 'me', 'what', 'happened.'], 'ar': ['أخبرني', 'ما', 'حدث.'] },
    { 'en': ['He', 'began', 'to', 'feel', 'ill.'], 'ar': ['بدأ', 'يشعر', 'بالمرض.'] },
    { 'en': ['How', 'long', 'have', 'you', 'been', 'teaching', 'Spanish?'], 'ar': ['كم', 'المدة', 'وأنتَ', 'تُعلم', 'الإسبانية؟'] },
    { 'en': ['I', 'thought', 'Tom', 'had', 'red', 'hair.'], 'ar': ['ظننت', 'أن', 'لتوم', 'شعرا', 'أحمر.'] },
    { 'en': ['He', 'yelled', 'with', 'pain.'], 'ar': ['صرخ', 'ألماً.'] },
    { 'en': ['I', 'lost', 'my', 'trust', 'in', 'him.'], 'ar': ['فقدت', 'ثقتي', 'به.'] },
    { 'en': ['I', 'will', 'be', 'back', 'soon.'], 'ar': ['سَوْفَ', 'أَعُوْدُ', 'قَرِيْبًا.'] },
    { 'en': ['I', 'hate', 'chemistry.'], 'ar': ['أكره', 'مادة', 'الكيمياء.'] },
    { 'en': ['Legend', 'has', 'it', 'that', 'this', 'house', 'is', 'haunted.'], 'ar': ['تقول', 'الاسطورة', 'أن', 'هذا', 'البيت', 'مسكون.'] },
    { 'en': ['Whenever', 'they', 'meet,', 'they', 'quarrel.'], 'ar': ['كلما', 'تقابلا', 'بدآ', 'بالجدال.'] },
    { 'en': ['Stop', 'yelling!'], 'ar': ['توقف', 'عن', 'الصراخ!'] },
    { 'en': ['This', 'story', 'has', 'an', 'unhappy', 'ending.'], 'ar': ['لهذه', 'القصّة', 'نهاية', 'حزينة.'] },
    { 'en': ['Can', 'you', 'really', 'do', 'it?'], 'ar': ['أحقّا', 'تستطيع', 'فعلها؟'] },
    { 'en': ['What', 'were', 'we', 'talking', 'about?'], 'ar': ['عن', 'ماذا', 'كنا', 'نتحدث؟'] },
    { 'en': ['Tom', 'is', 'his', 'brother.'], 'ar': ['توم', 'هو', 'أخوه'] },
    { 'en': ['How', 'long', 'does', 'it', 'take', 'to', 'get', 'to', 'the', 'station?'], 'ar': ['كم', 'من', 'الوقت', 'يلزم', 'للوصول', 'إلى', 'المحطّة.'] },
    { 'en': ['I', 'often', 'see', 'him.'], 'ar': ['عادة', 'ما', 'أراه.'] },
    { 'en': ['I', 'go', 'every', 'year.'], 'ar': ['أذهب', 'كل', 'سنة.'] },
    { 'en': ['Tom', 'deserves', 'a', 'promotion.'], 'ar': ['يستحق', 'توم', 'ترقيةً.'] },
    { 'en': ['Eat', 'whatever', 'you', 'like.'], 'ar': ['كُلْ', 'ما', 'تريد.'] },
    { 'en': ['She', 'gave', 'him', 'a', 'slap', 'in', 'the', 'face.'], 'ar': ['لقد', 'صفعته', 'في', 'الوجه.'] },
    { 'en': ["I'm", 'sleepy!'], 'ar': ['أنا', 'نعسان!'] },
    { 'en': ['No', 'one', 'I', 'know', 'wears', 'a', 'tie', 'anymore.'], 'ar': ['لا', 'أحد', 'أعرفهُ', 'يرتدي', 'ربطة', 'العُنُق', 'بعد', 'الآن.'] },
    { 'en': ["You're", 'very', 'perceptive.'], 'ar': ['أنت', 'دقيق', 'الملاحظة.'] },
    { 'en': ['You', 'can', 'call', 'me', 'Tom.'], 'ar': ['أنتَ', 'يمكنكَ', 'أن', 'تناديني', 'توم.'] },
    { 'en': ['Earth', 'is', 'a', 'beautiful', 'planet.'], 'ar': ['كوكب', 'الأرض', 'كوكبٌ', 'جميل.'] },
    { 'en': ["I'll", 'pay', 'the', 'bill.'], 'ar': ['الحساب', 'عليّ.'] },
    { 'en': ['I', 'have', 'some', 'questions', 'for', 'you.'], 'ar': ['لدي', 'بعض', 'الأسئلة', 'لك.'] },
    { 'en': ["He's", 'strong.'], 'ar': ['إنه', 'قوي.'] },
    { 'en': ['He', 'was', 'obviously', 'in', 'pain.'], 'ar': ['لقد', 'كان', 'من', 'الواضح', 'أنهُ', 'في', 'ألم.'] },
    { 'en': ['I', 'must', 'buy', 'one.'], 'ar': ['عليّ', 'أن', 'أشتري', 'واحدة.'] },
    { 'en': ['My', 'head', 'hurts.'], 'ar': ['رأسي', 'يؤلمني.'] },
    { 'en': ["Where's", 'the', 'nearest', 'subway', 'station?'], 'ar': ['أين', 'أقرب', 'محطة', 'لقطار', 'الأنفاق؟'] },
    { 'en': ['Is', 'it', 'far', 'from', 'here?'], 'ar': ['هل', 'هي', 'بعيدة', 'عن', 'هنا؟'] },
    { 'en': ['Help!'], 'ar': ['النجدة!'] },
    { 'en': ['Can', 'your', 'brother', 'drive', 'a', 'car?'], 'ar': ['هل', 'يستطيع', 'أخوك', 'أن', 'يقود', 'سيارة؟'] },
    { 'en': ['This', 'is', 'her', 'book.'], 'ar': ['هذا', 'كتابها.'] },
    { 'en': ['Tom', 'told', 'me', 'that', 'I', "shouldn't", 'ever', 'do', 'that.'], 'ar': ['أخبرني', 'توم', 'بألّا', 'أفعل', 'ذلك', 'أبدًا.'] },
    { 'en': ['You', 'can', 'always', 'come', 'here.'], 'ar': ['أنتِ', 'دائماً', 'يمكنكِ', 'القدوم', 'هنا.'] },
    { 'en': ['She', 'kept', 'working.'], 'ar': ['تابعت', 'عملها.'] },
    { 'en': ['The', 'door', 'opened.'], 'ar': ['فُتح', 'الباب.'] },
    { 'en': ['We', 'got', 'up', 'at', 'four', 'in', 'the', 'morning.'], 'ar': ['استيقظنا', 'في', 'الرابعة', 'صباحاً.'] },
    { 'en': ['She', "can't", 'play', 'piano', 'very', 'well.'], 'ar': ['لا', 'يمكنها', 'عزف', 'البيانو', 'بتلك', 'المهارة.'] },
    { 'en': ['You', 'may', 'choose', 'whichever', 'you', 'want.'], 'ar': ['يمكنك', 'اختيار', 'أيا', 'كان', 'ما', 'تريد.'] },
    { 'en': ['He', 'asked', 'us', 'to', 'help', 'him.'], 'ar': ['طلب', 'منا', 'أن', 'نساعده.'] },
    { 'en': ['Quick,', 'run', 'after', 'him.'], 'ar': ['بسرعة', '،', 'إلحق', 'به.'] },
    { 'en': ['It', 'was', 'very', 'hot', 'this', 'afternoon.'], 'ar': ['كان', 'الجو', 'حاراً', 'جداً', 'عصر', 'اليوم.'] },
    { 'en': ['We', 'arrived', 'first.'], 'ar': ['وصلنا', 'أولاً'] },
    { 'en': ['Only', 'adults', 'may', 'watch', 'that', 'film.'], 'ar': ['هذا', 'الفيلم', 'للكبار', 'فقط.'] },
    { 'en': ['I', 'dug', 'a', 'pit.'], 'ar': ['حفرت', 'حفرة.'] },
    { 'en': ['He', 'let', 'me', 'go.'], 'ar': ['هو', 'تركني', 'أذهب.'] },
    { 'en': ["Don't", 'forget', 'your', 'coat.'], 'ar': ['لا', 'تنسَ', 'معطفك.'] },
    { 'en': ['You', 'always', 'tell', 'me', 'that.'], 'ar': ['أنت', 'دائما', 'تقول', 'لي', 'ذلك.'] },
    { 'en': ['Please', 'sit', 'down.'], 'ar': ['من', 'فضلك', 'اجلس.'] },
    { 'en': ['My', 'tie', 'is', 'orange.'], 'ar': ['ربطة', 'عنقي', 'برتقالية.'] },
    { 'en': ['Please', 'tell', 'us', 'about', 'your', 'family.'], 'ar': ['من', 'فضلك', '،', 'حدثنا', 'عن', 'عائلتك', '.'] },
    { 'en': ["You're", 'always', 'criticizing', 'me!'], 'ar': ['دائماً', 'ما', 'تنتقدني!'] },
    { 'en': ['Please', 'tell', 'me', 'where', 'to', 'go', 'next.'], 'ar': ['أخبرني', 'من', 'فضلك', 'إلى', 'أي', 'مكان', 'أذهب', 'بعد', 'ذلك.'] },
    { 'en': ['Can', 'I', 'talk', 'to', 'you', 'a', 'moment?'], 'ar': ['هل', 'يمكنني', 'ان', 'اتكلم', 'معك', 'للحظة؟'] },
    { 'en': ["I'm", 'not', 'happy', 'with', 'it.'], 'ar': ['أنا', 'لست', 'سعيداً', 'به'] },
    { 'en': ['Take', 'off', 'your', 'clothes.'], 'ar': ['اخلع', 'ملابسك!'] },
    { 'en': ['I', 'dipped', 'my', 'finger', 'into', 'the', 'honey.'], 'ar': ['غمست', 'اصبعي', 'في', 'العسل.'] },
    { 'en': ['I', "don't", 'agree', 'with', 'him.'], 'ar': ['لا', 'أوافقه.'] },
    { 'en': ['Bees', 'make', 'honey.'], 'ar': ['يصنع', 'النحل', 'العسل.'] },
    { 'en': ['I', 'know', 'how', 'old', 'you', 'are.'], 'ar': ['أعرف', 'كم', 'عمرك.'] },
    { 'en': ['Tom', 'got', 'shampoo', 'in', 'his', 'eyes.'], 'ar': ['دخل', 'الشامبو', 'في', 'عيني', 'توم.'] },
    { 'en': ['I', "can't", 'bear', 'the', 'noise', 'any', 'longer.'], 'ar': ['لا', 'يمكنني', 'احتمال', 'الضجيج', 'أكثر', 'من', 'ذلك.'] },
    { 'en': ['You', 'have', 'to', 'get', 'enough', 'sleep.'], 'ar': ['عليك', 'أن', 'تحصل', 'على', 'كفايتك', 'من', 'النوم.'] },
    { 'en': ['I', 'have', 'to', 'go.'], 'ar': ['يجب', 'أن', 'أذهب.'] },
    { 'en': ['Please', 'call', 'me', 'up', 'later.'], 'ar': ['من', 'فضلك', 'إتّصل', 'بي', 'لاحقاً.'] },
    { 'en': ['Give', 'me', 'the', 'bill,', 'please.'], 'ar': ['أعطني', 'الحساب', 'من', 'فضلك.'] },
    { 'en': ['Watch', 'out,', 'the', 'man', 'has', 'a', 'gun.'], 'ar': ['انتبه،', 'ذاك', 'الرجل', 'معه', 'مسدس.'] },
    { 'en': ['Those', 'are', 'my', 'trousers.'], 'ar': ['ذاك', 'بنطالي.'] },
    { 'en': ['I', 'want', 'to', 'go', 'home.'], 'ar': ['أريد', 'أن', 'أذهب', 'إلى', 'البيت'] },
    { 'en': ['She', 'asked', 'him', 'if', 'he', 'was', 'happy.'], 'ar': ['سألته', 'إن', 'كان', 'سعيدا.'] },
    { 'en': ['I', 'was', 'nearly', 'run', 'over', 'by', 'a', 'truck.'], 'ar': ['كادت', 'شاحنةٌ', 'تدهسُنِي.'] },
    { 'en': ['The', 'decision', "hasn't", 'yet', 'been', 'made.'], 'ar': ['لم', 'يُتّخذ', 'القرار', 'بعد.'] },
    { 'en': ['I', "can't", 'see', 'anything.'], 'ar': ['لا', 'أرى', 'شيئاً.'] },
    { 'en': ['You', "can't", 'ride', 'a', 'horse.'], 'ar': ['لا', 'تستطيع', 'ركوب', 'حصان.'] },
    { 'en': ['The', 'enemy', 'kept', 'up', 'the', 'attack', 'all', 'night.'], 'ar': ['داوم', 'العدو', 'على', 'الهجوم', 'طوال', 'الليل.'] },
    { 'en': ['She', 'went', 'there', 'yesterday.'], 'ar': ['ذهبت', 'إلى', 'هناك', 'بالأمس.'] },
    { 'en': ['I', "don't", 'want', 'to', 'look', 'like', 'a', 'tourist.'], 'ar': ['لا', 'ارغب', 'ان', 'ابدو', 'كسائح', '.'] },
    { 'en': ['What', 'I', 'did', 'was', 'wrong.'], 'ar': ['ما', 'فعلته', 'أنا', 'كان', 'خاطئاً.'] },
    { 'en': ['Everybody', 'loves', 'her.'], 'ar': ['الجميع', 'يحبونها.'] },
    { 'en': ['Do', 'you', 'write', 'poetry?'], 'ar': ['هل', 'تكتب', 'الشعر؟'] },
    { 'en': ["It's", 'cold', 'today.'], 'ar': ['إنه', 'يوم', 'بارد'] },
    { 'en': ["That's", 'my', 'book.'], 'ar': ['هذا', 'كتابي.'] },
    { 'en': ['Stop', 'hitting', 'your', 'brother.'], 'ar': ['توقفي', 'عن', 'ضرب', 'أخيكِ.'] },
    { 'en': ["I've", 'seen', 'that.'], 'ar': ['سبق', 'و', 'رأيت', 'ذلك.'] },
    { 'en': ['Maybe', 'someday', "you'll", 'understand.'], 'ar': ['ربما', 'ستفهم', 'يومًا', 'ما.'] },
    { 'en': ['I', 'was', 'looking', 'for', 'the', 'remote.'], 'ar': ['كنت', 'أبحث', 'عن', 'جهاز', 'التحّكم.'] },
    { 'en': ['Are', 'you', 'talking', 'to', 'me?'], 'ar': ['هل', 'تكلمني؟'] },
    { 'en': ["She's", 'at', 'a', 'meeting.'], 'ar': ['إنها', 'في', 'اجتماع', 'الآن.'] },
    { 'en': ['Hey,', 'what', 'are', 'you', 'looking', 'at?'], 'ar': ['الى', 'ماذا', 'تنظر', '؟'] },
    { 'en': ['We', 'have', 'to', 'do', 'this', 'every', 'day.'], 'ar': ['واجب', 'علينا', 'فعل', 'ذلك', 'يوميا.'] },
    { 'en': ['This', 'one', 'is', 'prettier.'], 'ar': ['هذا', 'أجمل.'] },
    { 'en': ['The', 'women', 'of', 'France', 'are', 'beautiful.'], 'ar': ['نساء', 'فرنسا', 'جميلات.'] },
    { 'en': ["I'll", 'leave', 'the', 'decision', 'to', 'you.'], 'ar': ['سأترك', 'القرار', 'لك.'] },
    { 'en': ['The', 'rear-view', 'mirror', 'fell', 'off.'], 'ar': ['وقعت', 'مرآة', 'الرؤية', 'الخلفية', '.'] },
    { 'en': ['He', 'lived', 'abroad', 'for', 'many', 'years.'], 'ar': ['عاش', 'خارج', 'البلاد', 'لسنوات', 'عدة.'] },
    { 'en': ['You', 'are', 'new', 'students.'], 'ar': ['أنتم', 'طلبة', 'جدد.'] },
    { 'en': ["I'm", 'taking', 'a', 'couple', 'of', 'days', 'off.'], 'ar': ['سآخذ', 'إذنا', 'ليومين.'] },
    { 'en': ['Will', 'you', 'be', 'home', 'for', 'dinner?'], 'ar': ['هل', 'ستكون', 'في', 'المنزل', 'لتناول', 'العشاء؟'] },
    { 'en': ["Let's", 'split', 'the', 'bill', 'four', 'ways.'], 'ar': ['دعونا', 'نقسم', 'الفاتورة', 'بأربعة', 'طرق.'] },
    { 'en': ['Kuala', 'Lumpur', 'is', 'worth', 'visiting.'], 'ar': ['كوالالمبور', 'تستحق', 'الزيارة.'] },
    { 'en': ['I', 'know', 'who', 'it', 'is', "you're", 'looking', 'for.'], 'ar': ['أنا', 'أعرف', 'من', 'هو', 'الذي', 'تبحث', 'عنهُ.'] },
    { 'en': ["I'm", 'in', 'trouble.'], 'ar': ['أنا', 'في', 'ورطة.'] },
    { 'en': ['Have', 'you', 'ever', 'been', 'to', 'Hawaii?'], 'ar': ['هل', 'سبق', 'لك', 'أن', 'زرت', 'هاواي؟'] },
    { 'en': ['I', 'saw', 'him', 'running.'], 'ar': ['رأيته', 'يجري'] },
    { 'en': ['It', 'looks', 'like', 'you', 'are', 'from', 'India.'], 'ar': ['كأنّك', 'من', 'الهند.'] },
    { 'en': ['She', 'has', 'beautiful', 'eyes.'], 'ar': ['لديها', 'عينان', 'جميلتان.'] },
    { 'en': ['Speak', 'clearly.'], 'ar': ['تكلم', 'بوضوح.'] },
    { 'en': ['What', 'do', 'you', 'see', 'in', 'him?'], 'ar': ['ما', 'الذي', 'تراهُ', 'فيه؟'] },
    { 'en': ['You', "can't", 'speak', 'English,', 'can', 'you?'], 'ar': ['أنتِ', 'لا', 'تستطيعين', 'تحدث', 'الإنجليزية,', 'هل', 'تستطيعين؟'] },
    { 'en': ['Law', 'and', 'politics', 'are', 'two', 'different', 'things.'], 'ar': ['القانون', 'والسياسة', 'هما', 'شيئان', 'مختلفان.'] },
    { 'en': ["I'm", 'at', 'a', 'loss', 'for', 'words.'], 'ar': ['لا', 'أعرف', 'ماذا', 'أقول.'] },
    { 'en': ['Do', 'you', 'have', 'any', 'idea', 'who', 'would', 'do', 'this?'], 'ar': ['هل', 'لديك', 'اي', 'فكرة', 'عن', 'من', 'يستطيع', 'فعل', 'هذا؟'] },
    { 'en': ["It's", 'not', 'important.'], 'ar': ['ليس', 'هذا', 'مهماً.'] },
    { 'en': ['I', 'love', 'rock.'], 'ar': ['أعشق', 'موسيقى', 'الروك.'] },
    { 'en': ["Let's", 'not', 'talk.'], 'ar': ['دعينا', 'لا', 'نتحدث.'] },
    { 'en': ['She', 'has', 'been', 'dead', 'for', 'ten', 'years.'], 'ar': ['إنها', 'ميتة', 'منذ', 'عشر', 'سنوات.'] },
    { 'en': ['Do', 'you', 'like', 'tennis?'], 'ar': ['هل', 'تحب', 'كرة', 'المضرب؟'] },
    { 'en': ['Someone', 'stole', 'my', 'dartboard.'], 'ar': ['احدهم', 'سرق', 'لوح', 'السهام', 'المريشة', 'خاصتي.'] },
    { 'en': ['The', 'noise', 'was', 'quite', 'loud.'], 'ar': ['كان', 'ذلك', 'الضّجيج', 'قويّا', 'جدّا.'] },
    { 'en': ['My', 'daughter', 'is', 'getting', 'married', 'in', 'June.'], 'ar': ['ستتزوج', 'ابنتي', 'في', 'يونيو'] },
    { 'en': ["It's", 'already', 'past', 'your', 'bedtime.'], 'ar': ['لقد', 'تجاوزت', 'وقت', 'نومك.'] },
    { 'en': ['There', 'is', 'not', 'enough', 'demand', 'for', 'this', 'product.'], 'ar': ['لا', 'يوجد', 'إقبال', 'كافٍ', 'على', 'هذه', 'البضاعة.'] },
    { 'en': ['Nobody', 'cares', 'what', 'you', 'think.'], 'ar': ['لا', 'يهتم', 'أحد', 'برأيك.'] },
    { 'en': ['Bring', 'him', 'to', 'me.'], 'ar': ['أحضره', 'إلي.'] },
    { 'en': ['No', 'one', 'knows', 'his', 'address', 'but', 'Tom.'], 'ar': ['لا', 'يعرف', 'أحد', 'عنوانه', 'إلّا', 'توم.'] },
    { 'en': ['May', 'I', 'see', 'your', "driver's", 'license?'], 'ar': ['هلا', 'أريتني', 'رخصتك؟'] },
    { 'en': ['There', 'is', 'nothing', 'to', 'be', 'proud', 'of.'], 'ar': ['ليس', 'هناك', 'ما', 'نفخر', 'به.'] },
    { 'en': ["There's", 'something', 'I', 'forgot', 'to', 'say.'], 'ar': ['ثمّة', 'ما', 'نسيت', 'قوله.'] },
    { 'en': ["Who's", 'that', 'girl?'], 'ar': ['من', 'تلك', 'البنت؟'] },
    { 'en': ['Get', 'out!'], 'ar': ['أُخرج!'] },
    { 'en': ['She', 'cleaned', 'her', 'room', 'in', 'a', 'hurry.'], 'ar': ['نظفت', 'غرفتها', 'في', 'عجلة.'] },
    { 'en': ['Are', 'you', 'mad?'], 'ar': ['هل', 'أنت', 'غاضب؟'] },
    { 'en': ['I', 'showered', 'before', 'breakfast.'], 'ar': ['استحممت', 'قبل', 'تناول', 'الإفطار.'] },
    { 'en': ['I', 'just', 'arrived', 'now.'], 'ar': ['وصلت', 'للتوّ.'] },
    { 'en': ['Tom', 'seemed', 'bored.'], 'ar': ['بدى', 'توم', 'ضجرا.'] },
    { 'en': ['Hope', 'is', 'not', 'a', 'strategy.'], 'ar': ['الأمل', 'ليس', 'استراتيجية.'] },
    { 'en': ["I'm", 'just', 'looking.'], 'ar': ['إني', 'أنظر', 'فحسب.'] },
    { 'en': ['Have', 'you', 'spoken', 'to', 'Tom?'], 'ar': ['أتحدّثت', 'مع', 'توم؟'] },
    { 'en': ['They', 'had', 'a', 'dent', 'in', 'the', 'rear', 'door.'], 'ar': ['لديهم', 'بعجة', 'في', 'الباب', 'الخلفي', '.'] },
    { 'en': ['You', 'were', 'absolutely', 'right.'], 'ar': ['كنت', 'محقا', 'قطعا.'] },
    { 'en': ['He', 'likes', 'fishing.'], 'ar': ['إنه', 'يحب', 'صيد', 'الأسماك.'] },
    { 'en': ['We', 'love', 'each', 'other.'], 'ar': ['نحن', 'نحب', 'بعضنا', 'البعض.'] },
    { 'en': ['That', 'team', 'has', 'strong', 'players.'], 'ar': ['لدى', 'ذلك', 'الفريق', 'لاعبون', 'أقوياء', 'البنية.'] },
    { 'en': ['They', 'agreed', 'on', 'a', 'price.'], 'ar': ['إتّفقوا', 'على', 'سعرٍ', 'مُعيّن.'] },
    { 'en': ["It's", 'very', 'nice', 'of', 'you.'], 'ar': ['إنها', 'رائعة', 'جداً', 'عليك.'] },
    { 'en': ['Is', 'this', 'radio', 'yours?'], 'ar': ['هل', 'هذا', 'الراديو', 'لك؟'] },
    { 'en': ['I', 'have', 'little', 'money', 'now.'], 'ar': ['لدي', 'الآن', 'القليل', 'من', 'المال.'] },
    { 'en': ['We', 'painted', 'the', 'house', 'green.'], 'ar': ['طلينا', 'المنزل', 'باللون', 'الأخضر.'] },
    { 'en': ['This', 'surprised', 'many', 'people.'], 'ar': ['فاجأ', 'ذلك', 'الكثير.'] },
    { 'en': ['Next', 'Monday', 'and', 'Tuesday', 'are', 'holidays.'], 'ar': ['يوما', 'الإثنين', 'والثلاثاء', 'القادمين', 'عطلة.'] },
    { 'en': ['You', 'hate', 'tourists,', "don't", 'you?'], 'ar': ['انت', 'تكره', 'السياح', '،', 'أليس', 'كذلك', '؟'] },
    { 'en': ['Our', 'team', 'lost.'], 'ar': ['خسر', 'فريقنا.'] },
    { 'en': ["Let's", 'not', 'waste', 'time', 'arguing', 'about', 'it.'], 'ar': ['لنتوقف', 'عن', 'تضييع', 'الوقت', 'في', 'الجدال', 'بشأن', 'ذلك.'] },
    { 'en': ['My', 'father', 'does', 'not', 'eat', 'much', 'fruit.'], 'ar': ['أبي', 'لا', 'يأكل', 'الكثير', 'من', 'الفواكه'] },
    { 'en': ['I', 'like', 'sports.'], 'ar': ['أحب', 'ممارسة', 'الألعاب', 'الرياضية.'] },
    { 'en': ['Sign', 'at', 'the', 'bottom,', 'please.'], 'ar': ['إمض', 'اسمك', 'في', 'الأسفل', 'إذا', 'سمحت.'] },
    { 'en': ["I'll", 'never', 'let', 'you', 'do', 'that', 'again.'], 'ar': ['لن', 'أدعك', 'تفعل', 'ذلك', 'ثانية.'] },
    { 'en': ['I', 'saw', 'you', 'cooking.'], 'ar': ['رأيتك', 'تطبخ.'] },
    { 'en': ["I'm", 'afraid', 'Tom', "doesn't", 'want', 'to', 'talk', 'to', 'you.'], 'ar': ['أخشى', 'أن', 'توم', 'لا', 'يريد', 'التحدث', 'معك.'] },
    { 'en': ["I'd", 'like', 'to', 'talk', 'to', 'the', 'manager.'], 'ar': ['أود', 'التحدث', 'مع', 'المدير.'] },
    { 'en': ['I', 'admire', 'his', 'courage.'], 'ar': ['أقدّر', 'شجاعته.'] },
    { 'en': ['Make', 'me', 'happy.'], 'ar': ['أسعدني/اجعلني', 'سعيداً'] },
    { 'en': ['This', 'is', 'the', 'only', 'way', 'out.'], 'ar': ['إنه', 'المخرج', 'الوحيد'] },
    { 'en': ['I', 'agree', 'with', 'you', 'absolutely.'], 'ar': ['أوافقك', 'الرأي', 'بالتأكيد.'] },
    { 'en': ['I', 'tried', 'to', 'change', 'the', 'subject.'], 'ar': ['حاولت', 'أن', 'أغير', 'الموضوع.'] },
    { 'en': ['I', 'am', 'at', 'home.'], 'ar': ['أنا', 'في', 'البيت.'] },
    { 'en': ['She', 'went', 'to', 'the', 'market', 'once', 'a', 'week.'], 'ar': ['ذهبت', 'إلى', 'السوق', 'مرة', 'في', 'الأسبوع.'] },
    { 'en': ['A', 'mouse', 'is', 'a', 'timid', 'creature.'], 'ar': ['الفئران', 'حيوانات', 'خجولة.'] },
    { 'en': ['I', "don't", 'really', 'care', 'one', 'way', 'or', 'another.'], 'ar': ['أنا', 'حقاً', 'غير', 'مهتم', 'بطريقة', 'أو', 'بأخرى.'] },
    { 'en': ['What', 'were', 'you', 'doing?'], 'ar': ['ما', 'الذي', 'كنت', 'تفعله؟'] },
    { 'en': ['I', 'agreed', 'with', 'her.'], 'ar': ['وافقتها', 'الرأي.'] },
    { 'en': ['I', 'know', 'your', 'name.'], 'ar': ['أعرف', 'اسمك.'] },
    { 'en': ['As', 'far', 'as', 'I', 'know,', 'he', 'is', 'honest.'], 'ar': ['على', 'حد', 'علمي،', 'هو', 'شخص', 'أمين.'] },
    { 'en': ['I', 'only', 'need', 'one', 'onion', 'for', 'this', 'recipe.'], 'ar': ['أحتاج', 'بصلة', 'واحدة', 'فقط', 'لهذه', 'الوصفة.'] },
    { 'en': ['How', 'many', 'hours', 'of', 'sleep', 'do', 'you', 'need?'], 'ar': ['كم', 'ساعة', 'نوم', 'تحتاج؟'] },
    { 'en': ['I', 'like', 'chocolate.'], 'ar': ['أحب', 'الشوكولاتة.'] },
    { 'en': ['It', 'was', 'not', 'one', 'battle,', 'but', 'many.'], 'ar': ['لم', 'تكن', 'معركةً', 'واحدةً،', 'بل', 'أكثر.'] },
    { 'en': ['He', 'went', 'to', 'the', 'shop.'], 'ar': ['ذهب', 'إلى', 'المتجر.'] },
    { 'en': ['We', 'are', 'going', 'to', 'a', 'dance', 'tomorrow.'], 'ar': ['سنذهب', 'إلى', 'حفلة', 'رقص', 'غداً.'] },
    { 'en': ["I'm", 'busy.'], 'ar': ['إنني', 'مشغول.'] },
    { 'en': ['She', 'asked', 'me', 'a', 'question.'], 'ar': ['سألتني', 'سؤالاً.'] },
    { 'en': ['I', "don't", 'think', "he'll", 'come.'], 'ar': ['لا', 'أعتقد', 'أنه', 'قادم.'] },
    { 'en': ['Have', 'you', 'finished', 'writing', 'the', 'letter', 'yet?'], 'ar': ['هل', 'أنهيت', 'كتابة', 'الرسالة', 'بعد؟'] },
    { 'en': ['My', 'mom', "doesn't", 'speak', 'English', 'very', 'well.'], 'ar': ['أمي', 'لا', 'تتحدث', 'الإنجليزية', 'بشكل', 'جيد', 'جداً.'] },
    { 'en': ['He', 'will', 'arrive', 'in', 'Paris', 'tomorrow.'], 'ar': ['سوف', 'يصل', 'الى', 'باريس', 'غداً.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'open', 'this', 'box?'], 'ar': ['هل', 'تعرف', 'كيف', 'تفتح', 'هذا', 'الصندوق؟'] },
    { 'en': ['Welcome', 'back.', 'We', 'missed', 'you.'], 'ar': ['أهلاً', 'بعودتك.', 'لقد', 'اشتقنا', 'إليك!'] },
    { 'en': ['I', 'have', 'the', 'ace', 'of', 'spades.'], 'ar': ['لدي', 'الآس', 'البستوني.'] },
    { 'en': ["I'll", 'be', 'leaving', 'in', 'March.'], 'ar': ['سوف', 'أسافر', 'في', 'مارس-آذار.'] },
    { 'en': ["Don't", 'worry.'], 'ar': ['لا', 'تقلق.'] },
    { 'en': ['I', 'still', 'miss', 'Tom.'], 'ar': ['ما', 'أزال', 'أفتقد', 'توم.'] },
    { 'en': ['Would', 'you', 'like', 'me', 'to', 'call', 'a', 'taxi?'], 'ar': ['هل', 'تريدني', 'أن', 'أستدعي', 'لك', 'سيارة', 'أجرة؟'] },
    { 'en': ['I', 'forgot', 'to', 'ask', 'him.'], 'ar': ['نسيت', 'أن', 'أسأله.'] },
    { 'en': ["Where's", 'the', 'map?'], 'ar': ['أين', 'الخريطة؟'] },
    { 'en': ['How', 'do', 'you', 'know?'], 'ar': ['و', 'كيف', 'لك', 'أن', 'تعرف؟'] },
    { 'en': ["I'm", 'not', 'going.'], 'ar': ['أنا', 'لستُ', 'ذاهبة.'] },
    { 'en': ['Today,', 'I', 'was', 'done', 'with', 'my', 'homework', 'early.'], 'ar': ['أنجزت', 'واجباتي', 'مبكراً', 'اليوم.'] },
    { 'en': ['He', 'let', 'us', 'go.'], 'ar': ['هو', 'تركنا', 'نذهب.'] },
    { 'en': ['The', 'snow', 'has', 'disappeared.'], 'ar': ['اختفى', 'الثلج.'] },
    { 'en': ["Aren't", 'you', 'Tom?'], 'ar': ['ألست', 'طوم؟'] },
    { 'en': ['You', 'are', 'better', 'than', 'me.'], 'ar': ['أنت', 'أحْسَنُ', 'منّي.'] },
    { 'en': ['Does', 'she', 'like', 'oranges?'], 'ar': ['هل', 'تحب', 'البرتقال؟'] },
    { 'en': ['She', 'lost', 'her', 'handbag.'], 'ar': ['فقدت', 'حقيبة', 'يدها.'] },
    { 'en': ['Whose', 'is', 'this?'], 'ar': ['لمن', 'هذا؟'] },
    { 'en': ["Haven't", 'you', 'seen', 'the', 'doctor?'], 'ar': ['لم', 'تر', 'طبيباً؟'] },
    { 'en': ['No', 'one', 'knows.'], 'ar': ['لا', 'أحد', 'يعلم.'] },
    { 'en': ['Time', 'to', 'get', 'up.'], 'ar': ['حان', 'وقت', 'الإستيقاظ.'] },
    { 'en': ['Why', 'are', 'you', 'in', 'such', 'a', 'hurry?'], 'ar': ['لم', 'أنت', 'مستعجل؟'] },
    { 'en': ["Let's", 'split', 'up', 'into', 'teams.'], 'ar': ['دعونا', 'ننقسم', 'إلى', 'فرق.'] },
    { 'en': ['The', 'meeting', 'was', 'held', 'here.'], 'ar': ['انعقد', 'الإجتماع', 'هنا.'] },
    { 'en': ['What', 'time', 'did', 'he', 'say', "he'd", 'come?'], 'ar': ['متى', 'قال', 'أنه', 'سيأتي؟'] },
    { 'en': ['Can', 'you', 'pick', 'me', 'up', 'at', 'the', 'station?'], 'ar': ['أيمكنك', 'أن', 'تأتي', 'لأخذي', 'من', 'المحطة؟'] },
    { 'en': ['I', 'wrote', 'to', 'Tom', 'yesterday.'], 'ar': ['راسلت', 'توم', 'البارحة'] },
    { 'en': ["I've", 'been', 'a', 'teacher', 'for', 'two', 'years.'], 'ar': ['لقد', 'كنتُ', 'مدرساً', 'لمدة', 'عامين.'] },
    { 'en': ['I', 'use', 'Firefox.'], 'ar': ['أنا', 'أستخدم', 'برنامج', 'فايرفوكس.'] },
    { 'en': ['The', 'curtain', 'has', 'not', 'yet', 'risen.'], 'ar': ['لم', 'يرفع', 'الستار', 'حتى', 'الآن.'] },
    { 'en': ['He', 'is', 'one', 'of', 'the', 'greatest', 'artists', 'in', 'Japan.'], 'ar': ['هو', 'أحد', 'أعظم', 'فناني', 'اليابان.'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['إنها', 'ساخنة', 'جداً.'] },
    { 'en': ['He', 'lived', 'next', 'to', 'his', 'uncle.'], 'ar': ['كان', 'يسكن', 'بجانب', 'عمه.'] },
    { 'en': ['Please', 'send', 'us', 'more', 'information.'], 'ar': ['أرسل', 'إلينا', 'المزيد', 'من', 'المعلومات', 'إذا', 'تكرمت.'] },
    { 'en': ['There', 'is', 'a', 'big', 'market', 'for', 'coffee.'], 'ar': ['هناك', 'سوق', 'كبيرة', 'للقهوة.'] },
    { 'en': ['Come', 'on.', 'You', 'can', 'do', 'it.'], 'ar': ['هيا.', 'أنتِ', 'يمكنكِ', 'أن', 'تفعليها.'] },
    { 'en': ["That's", 'hard', 'to', 'say.'], 'ar': ['من', 'الصعب', 'التأكد.'] },
    { 'en': ['She', 'tore', 'the', 'letter', 'into', 'pieces.'], 'ar': ['مزقت', 'الرسالة', 'تمزيقاً.'] },
    { 'en': ['Generally', 'speaking,', 'history', 'repeats', 'itself.'], 'ar': ['على', 'العموم', '،', 'التاريخ', 'يكرّر', 'نفسه.'] },
    { 'en': ['Tom', 'and', 'Mary', 'are', 'playing', 'mahjong.'], 'ar': ['ماري', 'وتوم', 'يلعبون', 'ما', 'جونج.'] },
    { 'en': ['Take', 'a', 'short', 'cut.'], 'ar': ['خذ', 'الطريق', 'المختصر.'] },
    { 'en': ['Cats', 'are', 'very', 'clean', 'animals.'], 'ar': ['القطط', 'حيوانات', 'في', 'غاية', 'النظافة.'] },
    { 'en': ['What', 'did', 'Tom', 'make?'], 'ar': ['ماذا', 'صنع', 'توم؟'] },
    { 'en': ['No', 'one', 'I', 'know', 'writes', 'letters', 'anymore.'], 'ar': ['لا', 'أحد', 'أعرفهُ', 'يكتب', 'خِطابات', 'بعد', 'الآن.'] },
    { 'en': ['You', "can't", 'all', 'come', 'in.'], 'ar': ['ليس', 'بإمكانكم', 'أن', 'تدخلوا', 'جميعكم.'] },
    { 'en': ['You', 'need', 'to', 'study', 'harder.'], 'ar': ['يجب', 'عليك', 'أن', 'تدرس', 'بجد', 'أكثر.'] },
    { 'en': ["I'm", 'in', 'love', 'with', 'someone', 'else.'], 'ar': ['أنا', 'مغرمة', 'برجل', 'آخر.'] },
    { 'en': ['Who', 'broke', 'this?'], 'ar': ['من', 'كسر', 'هذا؟'] },
    { 'en': ['A', 'truck', 'hit', 'the', 'dog.'], 'ar': ['الشاحنة', 'صدمت', 'الكلب.'] },
    { 'en': ['I', 'used', 'to', 'swim', 'every', 'day', 'when', 'I', 'was', 'a', 'child.'], 'ar': ['كنت', 'أسبح', 'كل', 'يوم', 'عندما', 'كنت', 'صغيراً.'] },
    { 'en': ['There', 'are', 'many', 'foreign', 'tourists', 'in', 'Asakusa.'], 'ar': ['هنالك', 'العديد', 'من', 'السياح', 'الاجانب', 'في', 'أساكوسا', '.'] },
    { 'en': ["Let's", 'share', 'the', 'expenses.'], 'ar': ['دعنا', 'نتقاسم', 'النفقات.'] },
    { 'en': ['You', "haven't", 'finished', 'your', 'coffee.'], 'ar': ['لم', 'تنهِ', 'قهوتك.'] },
    { 'en': ['You', 'can', 'use', 'it', 'anytime.'], 'ar': ['بإمكانك', 'استخدامها', 'في', 'أي', 'وقت.'] },
    { 'en': ['I', 'think', 'she', 'is', 'very', 'attractive.'], 'ar': ['أعتقد', 'أنها', 'جذابةً', 'جداً.'] },
    { 'en': ["I'll", 'pay', 'the', 'bill.'], 'ar': ['أنا', 'سأدفع', 'الحساب.'] },
    { 'en': ['I', 'left', 'your', 'book', 'in', 'the', 'car.'], 'ar': ['تركتُ', 'كتابك', 'في', 'السيارة.'] },
    { 'en': ['Is', 'this', 'information', 'right?'], 'ar': ['هل', 'هذه', 'المعلومة', 'صحيحة؟'] },
    { 'en': ['The', 'swans', 'migrate', 'from', 'here', 'to', 'Florida.'], 'ar': ['يهاجر', 'البجع', 'من', 'هنا', 'إلى', 'فلوريدا.'] },
    { 'en': ['Kids', 'love', 'dogs.'], 'ar': ['الأطفال', 'يحبّون', 'الكلاب.'] },
    { 'en': ['I', 'just', 'wanted', 'to', 'be', 'popular.'], 'ar': ['أردت', 'الشهرة', 'فقط.'] },
    { 'en': ['The', 'lake', 'is', 'deep', 'here.'], 'ar': ['البحيرة', 'عميقة', 'هنا.'] },
    { 'en': ["Let's", 'go!'], 'ar': ['هيا', 'بنا!'] },
    { 'en': ["I'm", 'old', 'enough', 'to', 'make', 'my', 'own', 'decisions.'], 'ar': ['أنا', 'نضج', 'كفايةً', 'لأتّخذ', 'قراراتي', 'الخاصّة.'] },
    { 'en': ['Give', 'me', 'the', 'book.'], 'ar': ['أعطني', 'الكتاب.'] },
    { 'en': ['She', 'likes', 'blue', 'dresses.'], 'ar': ['تحب', 'الفساتين', 'الزرقاء.'] },
    { 'en': ['I', 'know', 'where', 'you', 'live.'], 'ar': ['أنا', 'أعلم', 'أين', 'تسكن'] },
    { 'en': ['About', '10', 'people', 'are', 'waiting.'], 'ar': ['حوالي', '10', 'أشخاص', 'ينتظرون.'] },
    { 'en': ["Isn't", 'it', 'black?'], 'ar': ['أليس', 'أسوداً؟'] },
    { 'en': ['He', 'read', 'the', 'document', 'aloud.'], 'ar': ['قرأ', 'الورقة', 'بصوت', 'عالٍ.'] },
    { 'en': ['Your', 'chair', 'is', 'identical', 'to', 'mine.'], 'ar': ['كرسيك', 'مطابقٌ', 'لكرسيي.'] },
    { 'en': ['When', 'and', 'where', 'shall', 'we', 'meet?'], 'ar': ['متى', 'وأين', 'سوف', 'نلتقي؟'] },
    { 'en': ['Why', 'is', 'he', 'angry', 'about', 'something', 'like', 'that?'], 'ar': ['لماذا', 'هو', 'غاضب', 'بسبب', 'شيء', 'مثل', 'ذلك؟'] },
    { 'en': ['What', 'made', 'her', 'do', 'such', 'a', 'thing?'], 'ar': ['ما', 'الذي', 'جعلها', 'تفعل', 'ذلك؟'] },
    { 'en': ["Here's", 'your', 'dog.'], 'ar': ['ها', 'هو', 'كلبك.'] },
    { 'en': ['Forget', 'it.'], 'ar': ['انسَ', 'ذلك'] },
    { 'en': ['She', 'fell', 'in', 'love', 'with', 'a', 'younger', 'man.'], 'ar': ['أحبت', 'شابا', 'أصغر', 'منها', 'سنا.'] },
    { 'en': ['May', 'I', 'say', 'something?'], 'ar': ['أيمكنني', 'أن', 'أقول', 'شيئاً؟'] },
    { 'en': ['I', "can't", 'thank', 'you', 'enough.'], 'ar': ['لا', 'أستطيع', 'أن', 'أشكركِ', 'بما', 'فيه', 'الكفاية.'] },
    { 'en': ['You', 'can', 'always', 'sleep', 'on', 'my', 'couch.'], 'ar': ['أنتَ', 'يمكنكَ', 'دائماً', 'النوم', 'على', 'أريكتي.'] },
    { 'en': ['They', 'lost', 'their', 'trust', 'in', 'Tom.'], 'ar': ['لقد', 'فَقَدوا', 'ثِقَتَهُم', 'في', 'توم.'] },
    { 'en': ['Please', 'help', 'me.'], 'ar': ['من', 'فضلك', 'ساعدني.'] },
    { 'en': ['He', 'is', 'a', 'genius.'], 'ar': ['إنه', 'عبقري.'] },
    { 'en': ['That', "wasn't", 'my', 'intention.'], 'ar': ['لم', 'يكن', 'ذلك', 'قصدي.'] },
    { 'en': ['The', 'best', 'hairdressers', 'are', 'gay.'], 'ar': ['أحسن', 'مصففي', 'الشعر', 'مثليو', 'الجنس'] },
    { 'en': ['Which', 'do', 'you', 'prefer,', 'rice', 'or', 'bread?'], 'ar': ['ماذا', 'تفضل', 'أكثر،', 'الرز', 'أم', 'الخبز؟'] },
    { 'en': ['But', "you're", 'not', 'there.'], 'ar': ['لكنك', 'لست', 'هناك.'] },
    { 'en': ["There's", 'one', 'thing', 'I', "didn't", 'mention.'], 'ar': ['هناك', 'شيء', 'لم', 'أذكره.'] },
    { 'en': ['I', 'study', 'for', '3', 'hours', 'every', 'day.'], 'ar': ['أدرس', 'لمدة', 'ثلاث', 'ساعات', 'كل', 'يوم.'] },
    { 'en': ["She's", 'accustomed', 'to', 'getting', 'up', 'early.'], 'ar': ['هي', 'معتادة', 'على', 'الاستيقاظ', 'باكرا.'] },
    { 'en': ["Let's", 'try', 'something.'], 'ar': ['لنجرب', 'فعل', 'شيءٍ', 'ما.'] },
    { 'en': ["I'll", 'fix', 'you', 'some', 'coffee.'], 'ar': ['سأعد', 'لك', 'بعض', 'القهوة.'] },
    { 'en': ["Don't", 'believe', 'what', 'she', 'says.'], 'ar': ['لا', 'تصدق', 'ما', 'تقوله.'] },
    { 'en': ['I', 'like', 'him', 'very', 'much.'], 'ar': ['أحبه', 'كثيراً.'] },
    { 'en': ['Your', 'letter', 'made', 'me', 'happy.'], 'ar': ['أدخلت', 'رسالتك', 'السعادة', 'على', 'قلبي.'] },
    { 'en': ["I'll", 'miss', 'you.'], 'ar': ['سأفتقدك.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'tennis', 'racket', 'today?'], 'ar': ['أيمكنني', 'أن', 'أستعير', 'مضرب', 'التنس', 'خاصتك', 'لليوم؟'] },
    { 'en': ['I', 'got', 'tired', 'of', 'lying', 'in', 'bed', 'all', 'day.'], 'ar': ['تعبت', 'من', 'الرقود', 'في', 'السرير', 'طوال', 'اليوم.'] },
    { 'en': ["You're", 'wrong.', "That's", 'not', 'the', 'case.'], 'ar': ['أنتَ', 'مخطئ,', 'تلك', 'ليست', 'القضية.'] },
    { 'en': ['Please', 'come', 'in.'], 'ar': ['تفضّل', 'بالدّخول.'] },
    { 'en': ['This', 'medicine', 'will', 'relieve', 'your', 'headache.'], 'ar': ['هذا', 'الدواء', 'سيخفف', 'من', 'صداعك.'] },
    { 'en': ["We'll", 'just', 'follow', 'you.'], 'ar': ['سنتبعك', 'فحسب.'] },
    { 'en': ['My', 'father', 'does', 'not', 'eat', 'much', 'fruit.'], 'ar': ['لا', 'يُكثِرُ', 'أبي', 'من', 'أكل', 'الفاكهة.'] },
    { 'en': ['He', 'is', 'happy.'], 'ar': ['هو', 'سعيد'] },
    { 'en': ['Why', 'did', 'you', 'keep', 'it', 'a', 'secret?'], 'ar': ['لمَ', 'أبقيته', 'سرًّا؟'] },
    { 'en': ['This', 'is', 'the', 'worst', 'movie', 'I', 'have', 'ever', 'seen.'], 'ar': ['هذا', 'أسوأ', 'فلم', 'شاهدته', 'في', 'حياتي.'] },
    { 'en': ['Do', 'you', 'know', 'the', 'answer?'], 'ar': ['أتعرف', 'ما', 'الإجابة؟'] },
    { 'en': ['May', 'I', 'take', 'a', 'photo?'], 'ar': ['هل', 'لي', 'أن', 'آخذ', 'صورة؟'] },
    { 'en': ["Let's", 'meet', 'again', 'soon.'], 'ar': ['لنلتقي', 'مرة', 'أخرى', 'قريباً.'] },
    { 'en': ['The', 'kids', 'are', 'asleep.'], 'ar': ['الأولاد', 'نائمون.'] },
    { 'en': ['I', 'wish', 'I', 'could', 'go', 'to', 'Japan.'], 'ar': ['كَم', 'أتمنّى', 'الذهاب', 'إلى', 'اليابان.'] },
    { 'en': ['I', "don't", 'know', 'exactly', 'when', 'I', 'will', 'be', 'back.'], 'ar': ['لا', 'أعلم', 'متى', 'سأعود', 'بالتحديد.'] },
    { 'en': ['I', 'saw', 'that.'], 'ar': ['لقد', 'رأيت', 'ذلك'] },
    { 'en': ['One', 'of', 'the', 'suitcases', 'is', 'completely', 'empty.'], 'ar': ['إحدى', 'الحقائب', 'فارغة', 'تماماً.'] },
    { 'en': ['I', 'understood', 'the', 'reason', 'for', 'this.'], 'ar': ['فهمت', 'سبب', 'هذا.'] },
    { 'en': ['I', 'have', 'the', 'ace', 'of', 'hearts.'], 'ar': ['انا', 'املك', 'الآس', 'قلوب.'] },
    { 'en': ['The', 'birds', 'flew', 'away', 'in', 'all', 'directions.'], 'ar': ['حلقت', 'الطيور', 'في', 'كل', 'اتجاه.'] },
    { 'en': ['My', 'husband', 'is', 'a', 'very', 'good', 'cook.'], 'ar': ['زوجي', 'طباخ', 'ماهر.'] },
    { 'en': ['This', 'school', 'has', 'no', 'heating.'], 'ar': ['هذه', 'المدرسة', 'ليس', 'لديها', 'نظام', 'تدفئة.'] },
    { 'en': ["I'll", 'never', 'let', 'you', 'go.'], 'ar': ['لن', 'أتركك', 'ترحل', 'أبدا.'] },
    { 'en': ["I'm", 'glad', 'no', 'one', 'is', 'around.'], 'ar': ['أنا', 'سعيد', 'لأنّه', 'لا', 'يوجد', 'أحد', 'هنا.'] },
    { 'en': ['Do', 'you', 'see', 'that?'], 'ar': ['هل', 'تري', 'هذا؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'speak', 'to', 'the', 'head', 'nurse.'], 'ar': ['أود', 'التحدث', 'إلى', 'رئيسة', 'الممرضات.'] },
    { 'en': ['No', 'problem', 'at', 'all!'], 'ar': ['لا', 'مشكلة', 'مطلقا!'] },
    { 'en': ["I'm", 'going', 'to', 'go', 'take', 'a', 'shower.'], 'ar': ['سأذهب', 'وآخذ', 'حمّاما.'] },
    { 'en': ["We're", 'hot.'], 'ar': ['نشعر', 'بالحرارة'] },
    { 'en': ['Are', 'you', 'a', 'teacher', 'or', 'a', 'student?'], 'ar': ['أمدرّس', 'أنت', 'أم', 'طالب؟'] },
    { 'en': ['Will', 'you', 'give', 'me', 'a', 'light?'], 'ar': ['هلا', 'أعطيتني', 'ولاعة؟'] },
    { 'en': ['You', 'must', 'get', 'ready', 'quickly.'], 'ar': ['عليك', 'أن', 'تتجهز', 'بسرعة.'] },
    { 'en': ['That', 'could', 'be', 'significant.'], 'ar': ['يمكن', 'أن', 'يكون', 'عظيما.'] },
    { 'en': ['This', 'watch', 'is', 'broken.'], 'ar': ['هذه', 'الساعة', 'مكسورة'] },
    { 'en': ['His', 'illness', 'may', 'be', 'cancer.'], 'ar': ['قد', 'يكون', 'مرضها', 'السرطان.'] },
    { 'en': ["What's", 'the', 'width', 'of', 'this', 'road?'], 'ar': ['ما', 'عرض', 'هذا', 'الشارع؟'] },
    { 'en': ['What', 'time', 'is', 'it?'], 'ar': ['ما', 'الساعة', 'الآن؟'] },
    { 'en': ['How', 'do', 'you', 'feel', 'now?'], 'ar': ['كيف', 'حالك', 'الآن؟'] },
    { 'en': ['Where', 'are', 'you', 'going?'], 'ar': ['أين', 'تذهب؟'] },
    { 'en': ["That's", 'not', 'my', 'car.'], 'ar': ['تلك', 'ليست', 'سيارتي'] },
    { 'en': ['I', 'believe', 'what', 'you', 'said', 'is', 'true.'], 'ar': ['أعتقد', 'أن', 'ما', 'تقوله', 'صحيح.'] },
    { 'en': ['You', 'should', 'have', 'refused', 'his', 'offer.'], 'ar': ['كان', 'من', 'المفترض', 'أن', 'ترفض', 'عرضه.'] },
    { 'en': ["Let's", 'go', 'somewhere', 'else.'], 'ar': ['هيا', 'بنا', 'نذهب', 'إلى', 'أي', 'مكان', 'آخر.'] },
    { 'en': ['I', 'believe', 'we', 'spoke', 'on', 'the', 'phone.'], 'ar': ['أظن', 'أننا', 'تحادثنا', 'عبر', 'الهاتف.'] },
    { 'en': ['You', 'should', 'head', 'west.'], 'ar': ['يجب', 'أن', 'تذهب', 'غربا'] },
    { 'en': ['When', 'in', 'Rome,', 'do', 'as', 'the', 'Romans', 'do.'], 'ar': ['عندما', 'تكون', 'في', 'روما', 'تصرف', 'كما', 'يتصرف', 'الرومان.'] },
    { 'en': ['He', 'said', 'that', 'he', 'was', 'happy.'], 'ar': ['لقد', 'قال', 'أنه', 'سعيد.'] },
    { 'en': ['When', 'did', 'you', 'come?'], 'ar': ['متىَ', 'أتيتِ؟'] },
    { 'en': ['My', 'home', 'is', 'your', 'home.'], 'ar': ['بيتي', 'هو', 'بيتك'] },
    { 'en': ['You', 'had', 'better', 'go.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تذهب.'] },
    { 'en': ['She', 'was', 'almost', 'late', 'for', 'school.'], 'ar': ['كادت', 'أن', 'تتأخر', 'عن', 'المدرسة.'] },
    { 'en': ['She', 'called', 'me', 'in', 'the', 'afternoon.'], 'ar': ['اتصلت', 'بي', 'بعد', 'الظهر.'] },
    { 'en': ['Tom', 'quit.'], 'ar': ['توم', 'استقال.'] },
    { 'en': ["She's", 'lost', 'her', 'car', 'keys.'], 'ar': ['فقدت', 'مفاتيح', 'سيارتها.'] },
    { 'en': ["Who's", 'the', 'boy', 'swimming', 'over', 'there?'], 'ar': ['من', 'ذلك', 'الصبي', 'الذي', 'يسبح', 'هناك؟'] },
    { 'en': ['I', 'drank', 'the', 'coffee.'], 'ar': ['شربت', 'القهوة.'] },
    { 'en': ['I', "don't", 'like', 'the', 'way', 'he', 'speaks.'], 'ar': ['لا', 'أحب', 'الطريقة', 'التي', 'يتكلم', 'بها.'] },
    { 'en': ['Who', 'won?'], 'ar': ['من', 'الذي', 'ربح؟'] },
    { 'en': ["She's", 'way', 'taller', 'than', 'me.'], 'ar': ['إنها', 'تفوقني', 'طولاً', 'بمقدار', 'كبير.'] },
    { 'en': ["I'm", 'not', 'ready', 'yet.'], 'ar': ['لست', 'جاهزاً', 'بعد.'] },
    { 'en': ['You', 'can', 'say', 'whatever', 'you', 'want', 'to.'], 'ar': ['يمكنك', 'أنت', 'تقول', 'أي', 'شيء', 'تريد.'] },
    { 'en': ['Everyone', 'has', 'strengths', 'and', 'weaknesses.'], 'ar': ['كل', 'شخص', 'لديه', 'ضعف', 'وقوة.'] },
    { 'en': ['That', 'boy', 'is', 'running.'], 'ar': ['ذلك', 'الولد', 'يركض.'] },
    { 'en': ['My', 'house', 'is', 'near', 'the', 'school.'], 'ar': ['منزِلي', 'قريب', 'من', 'المدرسة.'] },
    { 'en': ["You're", 'an', 'angel.'], 'ar': ['أنت', 'ملاك'] },
    { 'en': ['Food', 'is', 'necessary', 'for', 'life.'], 'ar': ['الغذاء', 'ضروري', 'للعيش.'] },
    { 'en': ['I', 'study', 'for', '3', 'hours', 'every', 'day.'], 'ar': ['أدرس', 'ثلاث', 'ساعات', 'في', 'اليوم.'] },
    { 'en': ['Where', 'do', 'you', 'work?'], 'ar': ['أين', 'تعمل؟'] },
    { 'en': ['I', 'think', "you're", 'right.'], 'ar': ['أعتقد', 'أنك', 'مصيب.'] },
    { 'en': ["I've", 'got', 'to', 'get', 'out', 'of', 'this', 'town.'], 'ar': ['علي', 'أن', 'أغادر', 'هذه', 'المدينة.'] },
    { 'en': ['Were', 'you', 'sleeping?'], 'ar': ['هل', 'كنت', 'نائما؟'] },
    { 'en': ['You', 'are', 'nothing', 'but', 'a', 'student.'], 'ar': ['أنت', 'مجرد', 'طالب.'] },
    { 'en': ['Where', 'was', 'he', 'born?'], 'ar': ['أين', 'وُلد؟'] },
    { 'en': ['That', 'is', 'a', 'table.'], 'ar': ['تلك', 'طاولةٌ.'] },
    { 'en': ['My', 'upper', 'right', 'wisdom', 'tooth', 'hurts.'], 'ar': ['يؤلمني', 'سن', 'العقل', 'في', 'أعلى', 'يمين', 'أسناني.'] },
    { 'en': ['She', 'had', 'nothing', 'to', 'do', 'yesterday.'], 'ar': ['لم', 'يكن', 'لديها', 'ما', 'تفعله', 'بالأمس.'] },
    { 'en': ['You', 'can', 'do', 'it.'], 'ar': ['تشجع!'] },
    { 'en': ["I'm", 'at', 'work', 'now,', 'so', "I'll", 'call', 'you', 'later.'], 'ar': ['أنا', 'في', 'العمل', 'الأن,', 'لذلك', 'سوف', 'أتصل', 'بكِ', 'لاحقاً.'] },
    { 'en': ['I', 'read', 'at', 'least', 'one', 'book', 'every', 'month.'], 'ar': ['أقرأ', 'على', 'الأقل', 'كتاباً', 'واحداً', 'في', 'الشهر.'] },
    { 'en': ['Of', 'course.'], 'ar': ['طبعاً!'] },
    { 'en': ['When', 'was', 'she', 'born?'], 'ar': ['متى', 'ولدت؟'] },
    { 'en': ["That's", 'my', 'cat.'], 'ar': ['ذلك', 'قطي.'] },
    { 'en': ['I', 'pointed', 'at', 'him.'], 'ar': ['أشرت', 'إليه.'] },
    { 'en': ["What's", 'going', 'on', 'here?'], 'ar': ['ماذا', 'يحدث', 'هنا؟'] },
    { 'en': ['The', 'lake', 'is', 'deepest', 'at', 'this', 'point.'], 'ar': ['هذه', 'أعمق', 'نقطة', 'في', 'البحيرة.'] },
    { 'en': ['Out', 'of', 'sight,', 'out', 'of', 'mind.'], 'ar': ['البُعد', 'يُنسي.'] },
    { 'en': ['She', "doesn't", 'need', 'to', 'work.'], 'ar': ['إنها', 'لا', 'تحتاج', 'إلى', 'أن', 'تعمل.'] },
    { 'en': ['It', "isn't", 'new.'], 'ar': ['ليست', 'جديدة.'] },
    { 'en': ['What', 'he', 'says', 'does', 'not', 'make', 'any', 'sense.'], 'ar': ['ما', 'يقوله', 'ليس', 'له', 'أي', 'معنى.'] },
    { 'en': ['TV', 'plays', 'an', 'important', 'part', 'in', 'everyday', 'life.'], 'ar': ['يلعب', 'التلفاز', 'دوراً', 'هاماً', 'في', 'الحياة', 'اليومية.'] },
    { 'en': ["I'm", 'trying', 'to', 'sleep.'], 'ar': ['أحاول', 'النوم.'] },
    { 'en': ['There', 'are', 'a', 'lot', 'of', 'students', 'in', 'the', 'gym.'], 'ar': ['هناك', 'الكثير', 'من', 'الطلبة', 'في', 'القاعة', 'الرياضية.'] },
    { 'en': ["I've", 'baked', 'a', 'cake', 'for', 'Tom.'], 'ar': ['خبزت', 'كعكة', 'لتوم.'] },
    { 'en': ['Tom', "isn't", 'lonely', 'now.'], 'ar': ['توم', 'ليس', 'وحيداً', 'الآن.'] },
    { 'en': ["We're", 'eating', 'apples.'], 'ar': ['نحن', 'نأكل', 'التفاح.'] },
    { 'en': ['Do', 'what', 'Tom', 'tells', 'you', 'to', 'do.'], 'ar': ['إفعل', 'كل', 'ما', 'أخبركَ', 'توم', 'أن', 'تفعله.'] },
    { 'en': ['Plastic', 'does', 'not', 'burn', 'easily.'], 'ar': ['لا', 'يحترق', 'البلاستيك', 'بسهولة.'] },
    { 'en': ['Whenever', 'they', 'meet,', 'they', 'quarrel.'], 'ar': ['كلما', 'تقابلا', 'تجادلا.'] },
    { 'en': ['He', 'sometimes', 'watches', 'TV.'], 'ar': ['يشاهد', 'التلفاز', 'أحياناً.'] },
    { 'en': ['Is', 'that', 'a', 'cat', 'or', 'a', 'dog?'], 'ar': ['هل', 'ذاك', 'كلب', 'أم', 'قطة؟'] },
    { 'en': ['She', 'cut', 'the', 'apple', 'with', 'a', 'knife.'], 'ar': ['قطعت', 'التفاحة', 'بالسكين.'] },
    { 'en': ['The', 'pond', 'is', 'very', 'deep.'], 'ar': ['البركة', 'عميقة', 'جدًّا.'] },
    { 'en': ['My', 'aunt', 'brought', 'me', 'some', 'flowers.'], 'ar': ['جلبت', 'لي', 'عمتي', 'بعض', 'الأزهار.'] },
    { 'en': ["I'm", 'happy', 'about', 'that.'], 'ar': ['أنا', 'سعيد', 'بذلك'] },
    { 'en': ['He', 'paid', 'no', 'attention', 'to', 'what', 'she', 'said.'], 'ar': ['لم', 'يعر', 'أي', 'اهتمام', 'لما', 'قالت.'] },
    { 'en': ["I'm", 'tired', 'of', 'watching', 'TV.'], 'ar': ['أنا', 'تعب', 'من', 'مشاهدة', 'التلفاز'] },
    { 'en': ['Come', 'forward.'], 'ar': ['تعالَ', 'إلى', 'الأمام.'] },
    { 'en': ["I'm", 'not', 'worried', 'about', 'it.'], 'ar': ['لست', 'قلقا', 'بشأنه'] },
    { 'en': ['I', "didn't", 'know', 'that', 'she', 'was', 'ill.'], 'ar': ['لم', 'أعرف', 'أنها', 'مريضة.'] },
    { 'en': ['The', 'hammer', 'and', 'sickle', 'symbolizes', 'communism.'], 'ar': ['المطرقة', 'والمنجل', 'يرمزان', 'للشيوعية.'] },
    { 'en': ["I'm", 'so', 'fat.'], 'ar': ['أنا', 'بدين', 'جداً.'] },
    { 'en': ['May', 'I', 'play', 'the', 'piano?'], 'ar': ['هل', 'لي', 'أن', 'أعزف', 'البيانو؟'] },
    { 'en': ['I', 'opened', 'the', 'door', 'and', 'got', 'out', 'of', 'the', 'car.'], 'ar': ['فتحتُ', 'الباب', 'وخرجت', 'من', 'السيارة.'] },
    { 'en': ['You', 'look', 'like', 'a', 'boy.'], 'ar': ['أنتِ', 'تبدينَ', 'مثل', 'الولد.'] },
    { 'en': ['People', 'are', 'living', 'in', 'all', 'parts', 'of', 'the', 'world.'], 'ar': ['الناس', 'يعيشون', 'في', 'جميع', 'أنحاء', 'العالم.'] },
    { 'en': ["Tom's", 'strict.'], 'ar': ['إن', 'توم', 'شديد.'] },
    { 'en': ['I', "don't", 'believe', 'in', 'fairy', 'tales.'], 'ar': ['أنا', 'لا', 'أؤمن', 'بالحكايات', 'الخرافية.'] },
    { 'en': ['Who', 'broke', 'this', 'pen?'], 'ar': ['من', 'كسر', 'هذا', 'القلم؟'] },
    { 'en': ['He', 'told', 'me', 'not', 'to', 'drive', 'too', 'fast.'], 'ar': ['نصحني', 'ألا', 'أقود', 'بسرعة.'] },
    { 'en': ['She', 'used', 'to', 'visit', 'me', 'regularly.'], 'ar': ['كان', 'من', 'عادتها', 'أنها', 'كانت', 'تزورني', 'باستمرار.'] },
    { 'en': ['He', 'was', 'brave.'], 'ar': ['كان', 'شجاعاً.'] },
    { 'en': ['I', 'gave', 'him', 'all', 'the', 'money', 'I', 'had.'], 'ar': ['أعطيته', 'كل', 'ما', 'كان', 'عندي', 'من', 'المال.'] },
    { 'en': ['I', 'met', 'her', 'in', 'a', 'coffee', 'shop', 'near', 'the', 'station.'], 'ar': ['قابلتها', 'في', 'المقهى', 'القريب', 'من', 'المحطة.'] },
    { 'en': ['I', 'can', 'peel', 'an', 'apple.'], 'ar': ['أستطيع', 'تقشير', 'تفاحة.'] },
    { 'en': ['Mr.', 'Jackson', 'is', 'our', 'history', 'teacher.'], 'ar': ['الاستاذ', 'جاكسون', 'هو', 'معلم', 'التاريخ'] },
    { 'en': ['He', 'is', 'getting', 'better.'], 'ar': ['بدأ', 'يتحسن'] },
    { 'en': ['It', 'feels', 'like', 'a', 'dream.'], 'ar': ['إنهُ', 'يبدو', 'كالحلم.'] },
    { 'en': ['I', 'had', 'no', 'time', 'to', 'eat.'], 'ar': ['لم', 'يكن', 'لديّ', 'وقت', 'للأكل.'] },
    { 'en': ['I', "didn't", 'mean', 'to', 'hurt', 'you.'], 'ar': ['لم', 'أقصد', 'أن', 'أؤذيك.'] },
    { 'en': ["Let's", 'stop', 'here.'], 'ar': ['سنكتفي', 'بهذا', 'الحد', 'اليوم', 'و', 'نقف', 'هنا.'] },
    { 'en': ['Put', 'the', 'gun', 'on', 'the', 'table.'], 'ar': ['ضع', 'المسدس', 'على', 'الطاولة.'] },
    { 'en': ['How', 'about', 'a', 'walk?'], 'ar': ['ما', 'رأيك', 'أن', 'نتمشّى؟'] },
    { 'en': ['You', 'are', 'a', 'tennis', 'player.'], 'ar': ['أنت', 'لاعب', 'كرة', 'مضرب.'] },
    { 'en': ['My', 'house', 'is', 'close', 'to', 'a', 'bus', 'stop.'], 'ar': ['يقع', 'بيتي', 'بالقرب', 'من', 'نقطة', 'انتظار', 'الحافلة.'] },
    { 'en': ["I'll", 'ask', 'when', 'the', 'train', 'will', 'get', 'in.'], 'ar': ['سأسأل', 'عن', 'موعد', 'وصول', 'القطار.'] },
    { 'en': ['"May', 'I', 'come', 'in?"', '"Yes,', 'certainly."'], 'ar': ['"هل', 'تأذن', 'لي', 'بالدخول؟"', '"نعم،', 'بالطبع".'] },
    { 'en': ['We', "don't", 'need', 'your', 'help.'], 'ar': ['لا', 'نحتاج', 'مساعدتك.'] },
    { 'en': ['They', 'studied', 'English', 'yesterday.'], 'ar': ['درسوا', 'الإنجليزية', 'البارحة.'] },
    { 'en': ['I', 'was', 'bothered', 'by', 'the', "baby's", 'crying.'], 'ar': ['أزعجني', 'بكاء', 'الطفل.'] },
    { 'en': ['Thank', 'you', 'so', 'much', 'for', 'inviting', 'me.'], 'ar': ['شكرا', 'جزيلا', 'على', 'دعوتك', 'لي.'] },
    { 'en': ['Can', 'I', 'stay?'], 'ar': ['هل', 'أستطيع', 'أن', 'أبقى؟'] },
    { 'en': ['They', 'grow', 'oranges', 'in', 'California.'], 'ar': ['يزرع', 'البرتقال', 'في', 'كاليفورنيا.'] },
    { 'en': ['It', 'was', 'cold', 'yesterday.'], 'ar': ['كان', 'الجو', 'بارداً', 'البارحة.'] },
    { 'en': ['Be', 'tolerant.'], 'ar': ['كُن', 'مُتسامحاً.'] },
    { 'en': ['Tom', "isn't", 'a', 'kid', 'anymore.'], 'ar': ['لم', 'يعد', 'توم', 'طفلا', 'بعد', 'الآن.'] },
    { 'en': ['Tom', 'swims', 'very', 'fast.'], 'ar': ['سباحة', 'توم', 'سريعة', 'جداً.'] },
    { 'en': ['Is', 'this', 'a', 'picture', 'that', 'you', 'yourself', 'drew?'], 'ar': ['هل', 'هذه', 'صورة', 'أنتِ', 'رسمتِها', 'بنفسك؟'] },
    { 'en': ['He', 'likes', 'to', 'go', 'to', 'the', 'beach', 'now', 'and', 'then.'], 'ar': ['يعجبه', 'الذهاب', 'بالشاطئ', 'بين', 'الفينة', 'والأخرى.'] },
    { 'en': ['Arabic', 'is', 'written', 'from', 'right', 'to', 'left.'], 'ar': ['تكتب', 'العربية', 'من', 'اليمين', 'إلى', 'اليسار.'] },
    { 'en': ['My', 'mother', 'took', 'my', 'temperature.'], 'ar': ['قاست', 'أمي', 'حرارتي.'] },
    { 'en': ['She', 'slapped', 'his', 'face.'], 'ar': ['صفعته', 'كفاً', 'على', 'وجهه.'] },
    { 'en': ['May', 'I', 'borrow', 'your', 'dictionary?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'قاموسك؟'] },
    { 'en': ['I', 'love', 'to', 'dance.'], 'ar': ['أحب', 'أن', 'أرقص.'] },
    { 'en': ['He', 'went', 'to', 'the', 'shop.'], 'ar': ['ذهب', 'إلى', 'المحل', 'التجاري.'] },
    { 'en': ["Let's", 'get', 'some', 'sleep.'], 'ar': ['دعنا', 'نأخذ', 'قسطاً', 'من', 'الراحة.'] },
    { 'en': ['I', 'want', 'you', 'to', 'leave', 'my', 'house.'], 'ar': ['أريد', 'منك', 'مغادرة', 'منزلي.'] },
    { 'en': ['How', 'long', 'do', 'we', 'have', 'to', 'wait?'], 'ar': ['كم', 'من', 'الوقت', 'علينا', 'أن', 'ننتظر؟'] },
    { 'en': ['Why', 'did', 'you', 'tell', 'them', 'it', 'was', 'my', 'fault?'], 'ar': ['لماذا', 'أخبرتهم', 'بأنه', 'كان', 'خطئي؟'] },
    { 'en': ['I', "don't", 'believe', 'she', 'would', 'understand.'], 'ar': ['لا', 'أعتقد', 'أنها', 'ستفهم.'] },
    { 'en': ['What?', 'I', "can't", 'hear', 'you', 'guys.'], 'ar': ['ماذا؟', 'أنا', 'لا', 'أستطيع', 'سماعكم', 'أيها', 'الرفاق.'] },
    { 'en': ['It', 'is', 'really', 'nice', 'of', 'you.'], 'ar': ['هذا', 'حقاً', 'لطفاً', 'منك.'] },
    { 'en': ['Congratulations!'], 'ar': ['تهانينا!'] },
    { 'en': ['Why', 'did', 'you', 'go', 'to', 'Tokyo?'], 'ar': ['لماذا', 'ذهبت', 'إلى', 'طوكيو؟'] },
    { 'en': ['Tom', "wouldn't", 'have', 'known', 'the', 'difference.'], 'ar': ['توم', 'لم', 'يكن', 'ليعرف', 'الفرق.'] },
    { 'en': ["It's", 'not', 'enough.'], 'ar': ['انها', 'ليست', 'كافيه'] },
    { 'en': ["I'm", 'unsure.'], 'ar': ['لست', 'متأكّدا.'] },
    { 'en': ['Muslims', 'believe', 'that', 'there', 'is', 'only', 'one', 'God.'], 'ar': ['يؤمن', 'المسلمون', 'أنّه', 'هناك', 'ربّ', 'واحد', 'فقط.'] },
    { 'en': ['Why', 'do', 'you', 'think', 'I', 'told', 'her', 'about', 'it?'], 'ar': ['لم', 'تظن', 'أنني', 'أخبرتها', 'بذلك؟'] },
    { 'en': ['Tom', 'pushed', 'Mary', 'down', 'the', 'stairs.'], 'ar': ['دفع', 'توم', 'ماري', 'من', 'على', 'الدرج.'] },
    { 'en': ["It's", 'time', 'to', 'get', 'up.'], 'ar': ['حان', 'وقت', 'النهوض.'] },
    { 'en': ['Do', 'I', 'have', 'to', 'stay', 'in', 'the', 'hospital?'], 'ar': ['هل', 'يجب', 'علي', 'أن', 'أبقى', 'في', 'المستشفى؟'] },
    { 'en': ['I', 'prefer', 'biking.'], 'ar': ['أفضّل', 'ركوب', 'الدرّاجة.'] },
    { 'en': ['I', 'was', 'born', 'in', 'Kyoto', 'in', '1980.'], 'ar': ['وُلدت', 'في', 'كيوتو', 'سنة', '١٩٨٠م.'] },
    { 'en': ['Driving', 'a', 'car', 'is', 'really', 'very', 'simple.'], 'ar': ['قيادة', 'السيارة', 'أمر', 'في', 'غاية', 'البساطة', 'حقاً.'] },
    { 'en': ['I', 'received', 'your', 'letter', 'yesterday.'], 'ar': ['استلمت', 'رسالتك', 'البارحة.'] },
    { 'en': ["I'll", 'call', 'you', 'later.'], 'ar': ['سأتصل', 'بك', 'لاحقاً.'] },
    { 'en': ['He', 'is', 'my', 'friend.', 'Do', 'you', 'guys', 'know', 'him?'], 'ar': ['إنه', 'صديقي،', 'هل', 'تعرفونه', 'يا', 'شباب؟'] },
    { 'en': ['He', 'has', 'been', 'warned', 'on', 'several', 'occasions.'], 'ar': ['قد', 'أُنذِر', 'مرات', 'عديدة.'] },
    { 'en': ['He', 'must', 'be', 'homesick.'], 'ar': ['لا', 'بد', 'أنه', 'مشتاق', 'إلى', 'بيته.'] },
    { 'en': ['The', 'door', 'opened', 'automatically.'], 'ar': ['فتح', 'الباب', 'آلياً.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'car', 'tonight?'], 'ar': ['هل', 'يُمكنني', 'استعارة', 'سيارتك', 'لهذه', 'الليلة؟'] },
    { 'en': ['He', 'was', 'very', 'busy', 'all', 'day.'], 'ar': ['كان', 'مشغولاً', 'طوال', 'اليوم.'] },
    { 'en': ["He's", 'writing', 'a', 'long', 'letter.'], 'ar': ['إنه', 'يكتب', 'رسالة', 'طويلة.'] },
    { 'en': ['Why', "didn't", 'you', 'tell', 'us', 'you', 'knew', 'French?'], 'ar': ['لماذا', 'لم', 'تخبرنا', 'بانك', 'تعرف', 'الفرنسية؟'] },
    { 'en': ['What', 'are', 'you', 'laughing', 'at?'], 'ar': ['ما', 'الذي', 'تصحك', 'عليه؟'] },
    { 'en': ['You', 'must', 'work', 'hard', 'in', 'order', 'not', 'to', 'fail.'], 'ar': ['يجب', 'أن', 'تعمل', 'بجد', 'حتى', 'لا', 'تفشل.'] },
    { 'en': ['She', 'talks', 'a', 'lot.'], 'ar': ['إنها', 'تتحدث', 'أكثر', 'من', 'اللازم.'] },
    { 'en': ['I', 'like', 'castles.'], 'ar': ['أحب', 'القلاع.'] },
    { 'en': ['Stop', 'teasing', 'your', 'sister!'], 'ar': ['توقف', 'عن', 'غيظ', 'أختك!'] },
    { 'en': ['The', 'dog', 'ran', 'after', 'a', 'fox.'], 'ar': ['لحِق', 'الكلب', 'ثعلبًا.'] },
    { 'en': ['An', 'accident', 'has', 'happened.'], 'ar': ['وقع', 'حادث.'] },
    { 'en': ["I'm", 'not', 'taking', 'a', 'vacation', 'this', 'year.'], 'ar': ['لن', 'أخذ', 'عطلة', 'هذا', 'العام.'] },
    { 'en': ['The', 'wild', 'wind', 'was', 'blowing.'], 'ar': ['كانت', 'الرياح', 'العاتية', 'تهب.'] },
    { 'en': ['Why', 'do', 'I', 'have', 'to', 'do', 'this', 'by', 'myself?'], 'ar': ['لم', 'علي', 'القيام', 'بذلك', 'بنفسي؟'] },
    { 'en': ['Who', 'else', 'helped', 'you?'], 'ar': ['من', 'ساعدك', 'أيضا؟'] },
    { 'en': ['Shut', 'all', 'the', 'doors', 'and', 'windows.'], 'ar': ['اغلق', 'جميع', 'الأبواب', 'والنوافذ.'] },
    { 'en': ['I', 'have', 'lived', 'here', 'since', '1990.'], 'ar': ['أنا', 'أسكنُ', 'هنا', 'مُنذُ', 'سنة', '1990.'] },
    { 'en': ["She's", 'very', 'innocent.'], 'ar': ['تبدو', 'طيبة', 'جدا.'] },
    { 'en': ['We', 'miss', 'you', 'so', 'much.'], 'ar': ['افتقدناك', 'كثيرا!'] },
    { 'en': ['I', "don't", 'come', 'here', 'very', 'often.'], 'ar': ['لا', 'آتي', 'إلى', 'هنا', 'كثيرا.'] },
    { 'en': ["It's", 'bedtime.'], 'ar': ['حان', 'وقت', 'النوم.'] },
    { 'en': ["Don't", 'depend', 'on', 'your', 'parents', 'too', 'much.'], 'ar': ['لا', 'تعتمد', 'على', 'والديك', 'كثيراً.'] },
    { 'en': ["It's", 'exactly', 'what', 'I', 'wanted.'], 'ar': ['هذا', 'بالضبط', 'ما', 'أردته.'] },
    { 'en': ["What's", 'the', 'matter?'], 'ar': ['ما', 'الأمر؟'] },
    { 'en': ['My', 'friend', 'has', 'arrived.'], 'ar': ['صديقي', 'قد', 'وصل.'] },
    { 'en': ['The', 'man', 'spoke', 'in', 'a', 'low', 'voice.'], 'ar': ['تكلم', 'الرجل', 'بصوت', 'خافت.'] },
    { 'en': ['Everybody', 'laughed', 'at', 'me.'], 'ar': ['ضحك', 'الجميع', 'عليّ.'] },
    { 'en': ['"Is', 'he', 'coming?"', '"No,', 'I', "don't", 'think', 'so."'], 'ar': ['"هل', 'هو', 'قادم؟"،', '"لا', 'أظن', 'أنه', 'سيأتي."'] },
    { 'en': ['Many', 'tourists', 'visit', 'Kyoto', 'in', 'the', 'spring.'], 'ar': ['يزور', 'العديد', 'من', 'السياح', 'مدينة', 'كيوتو', 'في', 'الربيع', '.'] },
    { 'en': ['What', 'made', 'you', 'come', 'here?'], 'ar': ['ما', 'الذي', 'جاء', 'بك', 'إلى', 'هنا؟'] },
    { 'en': ['She', 'is', 'wearing', 'a', 'valuable', 'ring.'], 'ar': ['هي', 'ترتدي', 'خاتماً', 'قيماً.'] },
    { 'en': ['Did', 'you', 'miss', 'me?'], 'ar': ['هل', 'اشتقت', 'إليّ؟'] },
    { 'en': ["Tom's", 'speech', 'was', 'excellent.'], 'ar': ['كانت', 'الكلمة', 'التي', 'ألقاها', 'توم', 'رائعة.'] },
    { 'en': ['Yes,', 'I', 'know', 'it.'], 'ar': ['نعم,', 'أعلم'] },
    { 'en': ['He', 'went', 'up', 'Mt.', 'Fuji.'], 'ar': ['لقد', 'صعد', 'جبل', 'فوجي.'] },
    { 'en': ['Are', 'all', 'the', 'passengers', 'aboard?'], 'ar': ['هل', 'صعد', 'كل', 'الركاب؟'] },
    { 'en': ['She', 'poured', 'me', 'a', 'cup', 'of', 'tea.'], 'ar': ['صبّت', 'لي', 'كأساً', 'من', 'الشاي.'] },
    { 'en': ['The', 'nurse', 'gave', 'Tom', 'a', 'flu', 'shot.'], 'ar': ['أعطت', 'الممرضة', 'توم', 'لقاح', 'الانفلونزا.'] },
    { 'en': ['Because', 'I', 'had', 'a', 'cold,', 'I', 'stayed', 'at', 'home.'], 'ar': ['لقد', 'بقيت', 'في', 'المنزل', 'لأنني', 'كنت', 'مصاباً', 'بالزكام', '.'] },
    { 'en': ['I', "didn't", 'need', 'to', 'tell', 'him.'], 'ar': ['لم', 'أكن', 'بحاجة', 'لإخباره.'] },
    { 'en': ['Do', 'you', 'have', 'change', 'for', 'a', 'fifty?'], 'ar': ['أيمكنك', 'صرف', 'خمسين؟'] },
    { 'en': ['Try', 'it', 'again.'], 'ar': ['حاول', 'مجدداً.'] },
    { 'en': ['He', "couldn't", 'possibly', 'succeed.'], 'ar': ['لم', 'يكن', 'هناك', 'احتمال', 'لنجاحه.'] },
    { 'en': ['I', 'smell', 'something', 'burning,', 'Mother.'], 'ar': ['أشم', 'رائحة', 'شيء', 'يحترق', 'يا', 'أمي.'] },
    { 'en': ['Your', 'examination', 'results', 'are', 'excellent.'], 'ar': ['درجتك', 'في', 'الإمتحان', 'ممتازة.'] },
    { 'en': ['Can', 'you', 'hear', 'me?'], 'ar': ['هل', 'بإمكانك', 'سماعي؟'] },
    { 'en': ["I'm", 'not', 'sure', 'how', 'it', 'happened.'], 'ar': ['لست', 'متأكدا', 'كيف', 'حدث', 'ذلك.'] },
    { 'en': ['Is', 'that', 'clear?'], 'ar': ['أهذا', 'واضح؟'] },
    { 'en': ["That's", 'really', 'great!'], 'ar': ['ذلك', 'رائع', 'حقاً!'] },
    { 'en': ['He', 'was', 'innocent', 'of', 'the', 'crime.'], 'ar': ['كان', 'بريئاً', 'من', 'الجريمة.'] },
    { 'en': ['Muslims', 'always', 'pray', 'facing', 'toward', 'Mecca.'], 'ar': ['يصّلي', 'المسلمون', 'دائما', 'و', 'هم', 'متوجّهون', 'نحو', 'الكعبة.'] },
    { 'en': ['There', 'is', 'no', 'hope', 'of', 'his', 'recovery.'], 'ar': ['ليس', 'هناك', 'أمل', 'بتعافيه.'] },
    { 'en': ['This', 'is', 'a', 'good', 'textbook.'], 'ar': ['هذا', 'كتاب', 'جيد.'] },
    { 'en': ['Are', 'you', 'laughing', 'at', 'me?'], 'ar': ['هل', 'تضحك', 'علي؟'] },
    { 'en': ['I', "don't", 'like', 'it,', 'either.'], 'ar': ['أنا', 'أيضاً', 'لا', 'يعجبني', 'ذلك.'] },
    { 'en': ["I'll", 'lend', 'it', 'to', 'you.'], 'ar': ['سأعيرك', 'إياها.'] },
    { 'en': ['What', 'time', 'does', 'your', 'plane', 'leave?'], 'ar': ['متى', 'ستغادر', 'طائرتك؟'] },
    { 'en': ['You', 'can', 'rely', 'on', 'her.'], 'ar': ['بإمكانك', 'أن', 'تعتمد', 'عليها.'] },
    { 'en': ["I'm", 'sad.'], 'ar': ['أنا', 'حزين.'] },
    { 'en': ["I'm", 'not', 'scared.'], 'ar': ['أنا', 'لستُ', 'خائفاً.'] },
    { 'en': ['Is', 'that', 'yours?'], 'ar': ['هل', 'ذلك', 'يخصك؟'] },
    { 'en': ['You', 'only', 'have', '24', 'hours', 'left', 'to', 'decide.'], 'ar': ['لديك', 'فقط', '24', 'ساعة', 'أخرى', 'لتقرّر.'] },
    { 'en': ['She', 'has', 'a', 'lot', 'of', 'English', 'books.'], 'ar': ['عندها', 'الكثير', 'من', 'الكتب', 'الإنجليزية.'] },
    { 'en': ['The', 'murder', 'remains', 'a', 'mystery.'], 'ar': ['ظلت', 'جريمة', 'القتل', 'لغزاً.'] },
    { 'en': ['Tom', "hasn't", 'been', 'indicted.'], 'ar': ['لم', 'يتم', 'اتهام', 'توم.'] },
    { 'en': ['I', "didn't", 'like', 'it.'], 'ar': ['لم', 'يعجبني', 'ذلك.'] },
    { 'en': ['She', 'listened', 'to', 'me.'], 'ar': ['استمعت', 'لي.'] },
    { 'en': ['Not', 'many', 'students', 'attended', 'the', 'meeting.'], 'ar': ['لم', 'يحضر', 'الاجتماع', 'طلاب', 'كثر.'] },
    { 'en': ['This', 'book', 'is', 'hard', 'for', 'me', 'to', 'read.'], 'ar': ['بالنسبة', 'لي،', 'هذا', 'الكتاب', 'صعب', 'القراءة.'] },
    { 'en': ['We', 'all', 'had', 'such', 'a', 'good', 'time.'], 'ar': ['قضينا', 'وقتاً', 'ممتعاً.'] },
    { 'en': ['Tom', 'likes', 'you.'], 'ar': ['توم', 'معجب', 'بكي.'] },
    { 'en': ['I', 'know.'], 'ar': ['أنا', 'أعلم'] },
    { 'en': ['I', 'read', 'his', 'book.'], 'ar': ['أنا', 'أقرأ', 'كتابه'] },
    { 'en': ['Tom', 'is', 'a', 'student.'], 'ar': ['توم', 'طالب.'] },
    { 'en': ['The', 'new', 'plan', 'has', 'been', 'very', 'successful.'], 'ar': ['الخِطة', 'الجديدة', 'كانت', 'ناجِحة', 'جداً.'] },
    { 'en': ['Happy', 'New', 'Year!'], 'ar': ['كل', 'سنة', 'و', 'أنت', 'بخير.'] },
    { 'en': ["I've", 'been', 'here', 'before.'], 'ar': ['جئت', 'إلى', 'هنا', 'من', 'قبل.'] },
    { 'en': ['What', 'if', "I'm", 'right?'], 'ar': ['ماذا', 'إذا', 'كنت', 'على', 'حق؟'] },
    { 'en': ['What', 'happened', 'last', 'night?'], 'ar': ['ما', 'الذي', 'حصل', 'البارحة؟'] },
    { 'en': ['Tom', 'knows', 'that', 'you', 'know.'], 'ar': ['يعرف', 'توم', 'أنك', 'تعرف'] },
    { 'en': ['It', 'may', 'rain', 'tomorrow.'], 'ar': ['ربما', 'ستمطر', 'غداً.'] },
    { 'en': ['We', 'used', 'to', 'swim', 'in', 'this', 'river', 'a', 'lot.'], 'ar': ['كنّا', 'نسبح', 'في', 'النهر.'] },
    { 'en': ['Truer', 'words', 'have', 'never', 'been', 'spoken.'], 'ar': ['ما', 'قيل', 'أصدق', 'من', 'هذا', 'قط.'] },
    { 'en': ['My', 'grandfather', 'comes', 'from', 'Osaka.'], 'ar': ['جدي', 'من', 'أهل', 'أوساكا.'] },
    { 'en': ['I', 'work', 'for', 'an', 'oil', 'company.'], 'ar': ['أعمل', 'لدى', 'شركة', 'نفط.'] },
    { 'en': ['We', 'saw', 'a', 'funny', 'movie', 'last', 'Sunday.'], 'ar': ['شاهدنا', 'فلماً', 'مضحكاً', 'يوم', 'الأحد', 'الماضي.'] },
    { 'en': ["Didn't", 'I', 'tell', 'you', 'so?'], 'ar': ['ألم', 'أقل', 'لك', 'ذلك؟'] },
    { 'en': ["I'm", 'proud', 'of', 'myself.'], 'ar': ['أنا', 'فخورٌ', 'بنفسي.'] },
    { 'en': ['He', 'often', 'quotes', 'Shakespeare.'], 'ar': ['عادة', 'ما', 'يذكر', 'مقولات', 'لشيكسبير.'] },
    { 'en': ['Tom', 'spent', 'the', 'entire', 'afternoon', 'with', 'Mary.'], 'ar': ['توم', 'أمضى', 'فترة', 'بعد', 'الظهر', 'بأكملها', 'مع', 'ماري.'] },
    { 'en': ['You', 'should', 'rewrite', 'this', 'sentence.'], 'ar': ['من', 'الأفضل', 'أن', 'تعيد', 'كتابة', 'هذه', 'الجملة.'] },
    { 'en': ['I', 'know', 'her.'], 'ar': ['أنا', 'أعرفها'] },
    { 'en': ["I've", 'climbed', 'Mt.', 'Fuji.'], 'ar': ['لقد', 'تسلقت', 'جبل', 'فوجي.'] },
    { 'en': ['Tom', 'wants', 'to', 'stay', 'with', 'Mary.'], 'ar': ['توم', 'يُريد', 'أن', 'يبقى', 'مع', 'ماري'] },
    { 'en': ['I', 'was', 'happy', 'to', 'see', 'him.'], 'ar': ['سررت', 'برؤيته.'] },
    { 'en': ['You', "can't", 'all', 'come', 'in.'], 'ar': ['لا', 'تستطيعون', 'أن', 'تدخلوا', 'جميعكم.'] },
    { 'en': ['We', "haven't", 'seen', 'you', 'in', 'the', 'past', 'four', 'years.'], 'ar': ['لم', 'يرك', 'أحد', 'منذ', 'أربع', 'سنوات.'] },
    { 'en': ["Let's", 'turn', 'off', 'the', 'TV.'], 'ar': ['فلنغلق', 'التلفاز', '.'] },
    { 'en': ['Why', "can't", 'you', 'come?'], 'ar': ['لماذا', 'لا', 'يمكنك', 'القدوم؟'] },
    { 'en': ['Rats', 'breed', 'rapidly.'], 'ar': ['تتكاثر', 'الفئران', 'بسرعة.'] },
    { 'en': ['Are', 'you', 'busy', 'tomorrow', 'morning?'], 'ar': ['هل', 'انت', 'مشغول', 'غداً', 'صباحاً', '؟'] },
    { 'en': ['Bring', 'it', 'to', 'me.'], 'ar': ['أحضرها', 'لي.'] },
    { 'en': ["I'm", 'a', 'bit', 'hungry.'], 'ar': ['أنا', 'جائع', 'قليلا.'] },
    { 'en': ['The', 'clock', 'has', 'stopped.'], 'ar': ['توقفت', 'الساعة.'] },
    { 'en': ['I', 'can', 'do', 'everything', 'myself.'], 'ar': ['استطيع', 'فعل', 'كل', 'شيء', 'بنفسي.'] },
    { 'en': ["He's", 'in', 'trouble.'], 'ar': ['إنهُ', 'في', 'مشكلة.'] },
    { 'en': ['You', 'should', 'keep', 'your', 'desk', 'clean.'], 'ar': ['ينبغي', 'عليك', 'ان', 'تُبقي', 'مكتبك', 'نظيف'] },
    { 'en': ['Deal', 'us', 'the', 'cards.'], 'ar': ['وزع', 'علينا', 'الورق.'] },
    { 'en': ['Mary', 'came', 'in.'], 'ar': ['دخلت', 'ماري.'] },
    { 'en': ['Tom', 'said', 'that', 'you', 'need', 'to', 'go.'], 'ar': ['قال', 'توم', 'بانك', 'تحتاج', 'ان', 'تذهب.'] },
    { 'en': ['I', 'am', 'four', 'months', 'pregnant.'], 'ar': ['أنا', 'حامل', 'في', 'الشهر', 'الرابع.'] },
    { 'en': ["I'm", 'sure', "I've", 'seen', 'him', 'before.'], 'ar': ['أنا', 'واثق', 'أني', 'رأيتهُ', 'من', 'قبل.'] },
    { 'en': ['There', 'was', 'nothing', 'I', 'could', 'do', 'for', 'Tom.'], 'ar': ['لا', 'يمكنني', 'القيام', 'بشيء', 'لأجل', 'توم.'] },
    { 'en': ['He', 'is', 'more', 'lucky', 'than', 'clever.'], 'ar': ['حظه', 'يسبق', 'ذكاءه.'] },
    { 'en': ["Let's", 'sing', 'and', 'dance.'], 'ar': ['لنغني', 'و', 'نرقص.'] },
    { 'en': ['Are', 'you', 'the', 'person', 'in', 'charge?'], 'ar': ['هل', 'أنت', 'الشخص', 'المسؤول؟'] },
    { 'en': ['You', 'should', 'pay', 'back', 'your', 'debts.'], 'ar': ['ينبغي', 'أن', 'ترد', 'الديون', 'التي', 'عليك.'] },
    { 'en': ['I', 'am', 'sorry.'], 'ar': ['أنا', 'آسف'] },
    { 'en': ['Answer', 'the', 'question.'], 'ar': ['أجب', 'على', 'السؤال.'] },
    { 'en': ['He', 'is', 'eating.'], 'ar': ['إنه', 'يأكل.'] },
    { 'en': ['Who', 'do', 'you', 'think', 'she', 'lives', 'with?'], 'ar': ['مع', 'من', 'تظن', 'أنها', 'تسكن؟'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'car?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'سيارتك؟'] },
    { 'en': ['How', 'did', 'Tom', 'start', 'the', 'fire?'], 'ar': ['كيف', 'أشعل', 'توم', 'النار؟'] },
    { 'en': ['Please', 'tell', 'Tom', "I'm", 'sorry', 'that', 'happened.'], 'ar': ['رجاء', 'أخبر', 'توم', 'أني', 'آسف', 'لما', 'حدث.'] },
    { 'en': ['Listen', 'to', 'Tom.'], 'ar': ['استمع', 'إلى', 'توم'] },
    { 'en': ["You'll", 'feel', 'better.'], 'ar': ['ستشعر', 'أنك', 'أفضل'] },
    { 'en': ['She', 'said', 'goodbye.'], 'ar': ['قالت', 'وداعا.'] },
    { 'en': ['Listen.'], 'ar': ['استمع'] },
    { 'en': ['This', 'book', "isn't", 'worth', 'reading.'], 'ar': ['هذا', 'الكتاب', 'لا', 'يستحق', 'أن', 'يُقرأ.'] },
    { 'en': ['How', 'should', 'I', 'know?'], 'ar': ['و', 'كيف', 'لي', 'أن', 'أعرف؟'] },
    { 'en': ['Did', 'you', 'know', 'my', 'father?'], 'ar': ['أكنت', 'تعرف', 'والدي؟'] },
    { 'en': ['They', 'will', 'get', 'married', 'next', 'month.'], 'ar': ['سيتزوجا', 'الشهر', 'القادم.'] },
    { 'en': ['Please', 'explain', 'why', 'you', "can't", 'come.'], 'ar': ['من', 'فضلك', 'اشرح', 'لماذا', 'لا', 'يمكنك', 'المجيء.'] },
    { 'en': ['I', 'have', 'no', 'time', 'to', 'do', 'my', 'homework.'], 'ar': ['ليس', 'عندي', 'وقت', 'لأنجز', 'واجباتي.'] },
    { 'en': ['German', 'is', 'not', 'an', 'easy', 'language.'], 'ar': ['الألمانية', 'ليست', 'لغة', 'سهلة.'] },
    { 'en': ["You're", 'in', 'better', 'shape', 'than', 'I', 'am.'], 'ar': ['أنت', 'في', 'حال', 'أفضل', 'من', 'حالي.'] },
    { 'en': ['All', 'my', 'friends', 'can', 'speak', 'French.'], 'ar': ['يستطيع', 'كل', 'أصدقائي', 'التحدث', 'بالفرنسية.'] },
    { 'en': ['By', 'the', 'way,', 'have', 'you', 'ever', 'been', 'to', 'Hokkaido?'], 'ar': ['بالمناسبة', '،', 'هل', 'زرت', 'هوكايدو', 'من', 'قبل؟'] },
    { 'en': ['She', 'is', 'very', 'clever.'], 'ar': ['إنها', 'ماهرة', 'جداً.'] },
    { 'en': ['They', 'demanded', 'damages', 'from', 'the', 'driver.'], 'ar': ['طالبوا', 'بتعويض', 'من', 'السائق.'] },
    { 'en': ['You', 'swim', 'well,', "don't", 'you?'], 'ar': ['تسبح', 'بمهارة', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['Can', 'I', 'pay', 'by', 'credit', 'card?'], 'ar': ['أيمكنني', 'الدفع', 'عن', 'طريق', 'بطاقة', 'الإئتمان؟'] },
    { 'en': ['Everything', 'I', 'did,', 'I', 'did', 'for', 'Tom.'], 'ar': ['كلّ', 'ما', 'فعلتُ', 'كان', 'لِتوم.'] },
    { 'en': ['English', 'is', 'not', 'my', 'mother', 'tongue.'], 'ar': ['الإنجليزية', 'ليست', 'لغتي', 'الأم.'] },
    { 'en': ['"What', 'time', 'is', 'it?"', '"It\'s', '3:20."'], 'ar': ['"كم', 'الساعة', 'الآن؟"', '"إنها', 'الثالثة', 'وعشرون', 'دقيقة."'] },
    { 'en': ['Children', 'are', 'dying.'], 'ar': ['الأطفال', 'يموتون.'] },
    { 'en': ['Is', 'this', 'your', 'first', 'visit', 'to', 'this', 'town?'], 'ar': ['هل', 'هذه', 'أول', 'زيارة', 'لك', 'للمدينة؟'] },
    { 'en': ['Nothing', 'is', 'achieved', 'without', 'effort.'], 'ar': ['لا', 'إنجاز', 'بلا', 'جهد', '.'] },
    { 'en': ['Never', 'underestimate', 'Tom.'], 'ar': ['لا', 'تستخفَّ', 'بتوم', 'أبداً.'] },
    { 'en': ['Many', 'thanks.'], 'ar': ['شكراً', 'جزيلاً!'] },
    { 'en': ['The', 'Arctic', 'is', 'the', 'area', 'round', 'the', 'North', 'Pole.'], 'ar': ['المنطقة', 'القطبية', 'هي', 'تلك', 'الواقعة', 'حول', 'القطب', 'الشمالي.'] },
    { 'en': ['Continue', 'digging.'], 'ar': ['واصِل', 'الحفر.'] },
    { 'en': ['I', 'saw', 'a', 'group', 'of', 'tourists', 'who', 'spoke', 'Turkish.'], 'ar': ['لقد', 'رأيت', 'مجموعة', 'من', 'السياح', 'الذين', 'يتكلمون', 'التركية', '.'] },
    { 'en': ['Seeing', 'the', 'policeman,', 'the', 'man', 'ran', 'away.'], 'ar': ['هرب', 'الرجل', 'بعد', 'أن', 'رأى', 'الشرطي.'] },
    { 'en': ["I'm", 'listening', 'to', 'music.'], 'ar': ['أنا', 'أستمع', 'إلى', 'الموسيقى.'] },
    { 'en': ['May', 'I', 'ask', 'some', 'questions?'], 'ar': ['أيمكنني', 'أن', 'أطرح', 'بعض', 'الأسئلة؟'] },
    { 'en': ['Wait', 'a', 'moment.'], 'ar': ['انتظر', 'لحظة.'] },
    { 'en': ['Come', 'along.'], 'ar': ['تعال', 'معنا.'] },
    { 'en': ['He', 'was', 'very', 'friendly', 'to', 'everybody.'], 'ar': ['كان', 'لطيفًا', 'جدًّا', 'مع', 'الجميع.'] },
    { 'en': ['Are', 'they', 'friends?'], 'ar': ['هل', 'هم', 'أصدقاء؟'] },
    { 'en': ['He', 'was', 'very', 'patient.'], 'ar': ['كان', 'صبوراً', 'جداً.'] },
    { 'en': ['Calm', 'down,', 'son.'], 'ar': ['اهدأ', 'يا', 'بني.'] },
    { 'en': ['My', 'uncle', 'lives', 'in', 'an', 'apartment.'], 'ar': ['يسكن', 'عمي', 'في', 'شقة.'] },
    { 'en': ['Dr.', 'Smith', 'has', 'a', 'lot', 'of', 'patients.'], 'ar': ['هناك', 'الكثير', 'من', 'المرضى', 'لدى', 'الدكتور', 'سميث.'] },
    { 'en': ['Tom', 'pretended', 'he', "didn't", 'see', 'Mary.'], 'ar': ['تظاهر', 'توم', 'بأنه', 'لم', 'يرى', 'ماري.'] },
    { 'en': ['Add', 'a', 'little', 'milk.'], 'ar': ['أضف', 'القليل', 'من', 'الحليب.'] },
    { 'en': ['Would', 'you', 'play', 'with', 'me?'], 'ar': ['أيمكنك', 'اللعب', 'معي؟'] },
    { 'en': ['Please', 'answer', 'all', 'the', 'questions.'], 'ar': ['من', 'فضلك', 'أجب', 'على', 'كل', 'الأسئلة.'] },
    { 'en': ['I', 'am', 'a', 'student.'], 'ar': ['أنا', 'طالب'] },
    { 'en': ['Tom', 'collects', 'old', 'coins.'], 'ar': ['يجمع', 'توم', 'العملات', 'القديمة.'] },
    { 'en': ['My', 'father', 'and', 'mother', 'have', 'a', 'big', 'farm', 'there.'], 'ar': ['أمي', 'وأبي', 'عندهما', 'مزرعة', 'كبيرة', 'هناك.'] },
    { 'en': ['She', 'can', 'count', 'from', 'one', 'to', 'ten.'], 'ar': ['يمكنها', 'ان', 'تَعُدَّ', 'من', 'الواحد', 'إلى', 'العشرة.'] },
    { 'en': ["It's", 'a', 'very', 'slow', 'process.'], 'ar': ['إنها', 'عملية', 'بطيئة', 'جداً.'] },
    { 'en': ['Today', 'is', 'Independence', 'Day.'], 'ar': ['اليوم', 'هو', 'عيد', 'الإستقلال.'] },
    { 'en': ['I', 'began', 'to', 'cry.'], 'ar': ['بدأتُ', 'بالبكاء.'] },
    { 'en': ['Leave', 'me', 'alone!'], 'ar': ['اتركني', 'و', 'شأني!'] },
    { 'en': ['Look', 'after', 'Tom.'], 'ar': ['اعتني', 'بتوم'] },
    { 'en': ['I', 'missed', 'you', 'guys', 'so', 'much!'], 'ar': ['كم', 'افتقدتكم', 'يا', 'رفاق!'] },
    { 'en': ['We', "don't", 'know', 'him.'], 'ar': ['إننا', 'لا', 'نعرفه.'] },
    { 'en': ['Is', 'he', 'speaking', 'English,', 'French,', 'or', 'German?'], 'ar': ['هل', 'هو', 'يتحدث', 'الإنجليزية,', 'الفرنسية', 'أو', 'الألمانية؟'] },
    { 'en': ['The', 'lights', 'are', 'out.'], 'ar': ['انطفأت', 'الأضواء.'] },
    { 'en': ['He', 'loves', 'her.'], 'ar': ['هو', 'يحبها'] },
    { 'en': ['He', 'works', 'in', 'the', 'car', 'industry.'], 'ar': ['إنه', 'يعمل', 'في', 'قطاع', 'صناعة', 'السيارات.'] },
    { 'en': ['Are', 'you', 'all', 'right?'], 'ar': ['هل', 'أنتَ', 'بخير؟'] },
    { 'en': ["I'm", 'going', 'to', 'go', 'visit', 'my', 'uncle', 'tomorrow.'], 'ar': ['أنوي', 'زيارة', 'عمّي', 'في', 'الغد.'] },
    { 'en': ['Tom', 'sold', 'his', 'house.'], 'ar': ['باع', 'توم', 'منزله.'] },
    { 'en': ['For', 'me,', "it's", 'important.'], 'ar': ['إن', 'ذلك', 'مهم', 'بالنسبة', 'لي.'] },
    { 'en': ['Tom', 'lost', 'his', 'sense', 'of', 'smell.'], 'ar': ['فقد', 'توم', 'حاسة', 'الشم.'] },
    { 'en': ['That', 'old', 'woman', 'lives', 'by', 'herself.'], 'ar': ['تلك', 'المرأة', 'العجوز', 'تسكن', 'لوحدها.'] },
    { 'en': ['I', 'need', 'more', 'time', 'to', 'finish', 'my', 'homework.'], 'ar': ['أحتاج', 'لمزيد', 'من', 'الوقت', 'لأنجز', 'فروضي.'] },
    { 'en': ['I', 'cried', 'all', 'night', 'long.'], 'ar': ['بكيتُ', 'طوال', 'الليل.'] },
    { 'en': ["I'm", 'sure', 'of', 'his', 'success.'], 'ar': ['متأكد', 'من', 'نجاحه.'] },
    { 'en': ['Say', 'it', 'in', 'English.'], 'ar': ['قُلها', 'فى', 'الإنجليزية.'] },
    { 'en': ['Can', 'you', 'teach', 'me', 'English?'], 'ar': ['هل', 'يمكنكِ', 'تعليمي', 'الإنجليزية.'] },
    { 'en': ['Do', 'you', 'want', 'me', 'to', 'help', 'you', 'with', 'the', 'cooking?'], 'ar': ['أتريد', 'مني', 'مساعدتك', 'في', 'الطبخ؟'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'tie', 'your', 'shoes?'], 'ar': ['أتعرف', 'كيف', 'تربط', 'ربطات', 'حذائك؟'] },
    { 'en': ["Don't", 'worry,', "I'll", 'translate', 'that', 'for', 'you.'], 'ar': ['لا', 'تقلق،', 'سأترجم', 'لك', 'ذلك.'] },
    { 'en': ['Tom', 'sighed', 'deeply.'], 'ar': ['توم', 'تنهد', 'بعمق'] },
    { 'en': ['What', 'has', 'become', 'of', 'your', 'sister?'], 'ar': ['ما', 'الذي', 'حصل', 'لأختك؟'] },
    { 'en': ['She', 'was', 'very', 'proud', 'of', 'her', 'father.'], 'ar': ['كانت', 'فخورة', 'للغاية', 'بوالدها.'] },
    { 'en': ['He', 'is', 'busy', 'doing', 'something.'], 'ar': ['إنه', 'مشغول.'] },
    { 'en': ['The', 'area', 'around', 'here', 'was', 'bombed.'], 'ar': ['لقد', 'تم', 'قصف', 'المنطقة', 'المحيطة', 'هنا.'] },
    { 'en': ['I', 'am', 'afraid.'], 'ar': ['أنا', 'خائف.'] },
    { 'en': ["I'll", 'get', 'some', 'glasses.'], 'ar': ['سأحضر', 'بعض', 'كؤوس', 'الشرب.'] },
    { 'en': ['He', 'climbed', 'down', 'from', 'the', 'tree.'], 'ar': ['نزل', 'الولد', 'من', 'على', 'الشجرة.'] },
    { 'en': ['Who', 'owns', 'this', 'villa?'], 'ar': ['من', 'يملك', 'هذه', 'الفِلّا؟'] },
    { 'en': ['Nobody', 'owns', 'the', 'moon.'], 'ar': ['لا', 'أحد', 'يملك', 'القمر.'] },
    { 'en': ['We', 'watched', 'the', 'soccer', 'game', 'on', 'TV.'], 'ar': ['شاهدنا', 'مباراة', 'كرة', 'القدم', 'على', 'التلفاز.'] },
    { 'en': ['You', 'never', 'change,', 'do', 'you?'], 'ar': ['ألا', 'تتغير', 'أبداً؟'] },
    { 'en': ["Don't", 'touch', 'my', 'camera.'], 'ar': ['لا', 'تلمس', 'آلة', 'تصويري.'] },
    { 'en': ['Who', 'will', 'help', 'me?'], 'ar': ['من', 'سيساعدني؟'] },
    { 'en': ['Tom', 'needs', 'a', 'ride', 'to', 'Boston.'], 'ar': ['بدأ', 'توم', 'بتعلم', 'الفرنسية', 'عندما', 'كان', 'عمره', 'ثلاثة', 'عشرة', 'سنة.'] },
    { 'en': ["I'll", 'visit', 'a', 'museum', 'today.'], 'ar': ['أنا', 'سوف', 'ازور', 'المتحف', 'اليوم.'] },
    { 'en': ['Are', 'you', 'going', 'to', "Tom's", 'party?'], 'ar': ['أستذهب', 'إلى', 'حفلة', 'توم؟'] },
    { 'en': ['I', "won't", 'lose!'], 'ar': ['لن', 'أخسر!'] },
    { 'en': ["I'm", 'not', 'even', 'sure', 'I', 'want', 'to', 'see', 'that', 'movie.'], 'ar': ['أنا', 'حتى', 'لستُ', 'متأكداً', 'ما', 'إذا', 'كُنتُ', 'أريد', 'أن', 'أرى', 'هذا', 'الفيلم.'] },
    { 'en': ["I'm", 'a', 'member', 'of', 'the', 'swimming', 'club.'], 'ar': ['أنا', 'عضو', 'في', 'نادي', 'السباحة.'] },
    { 'en': ["We'll", 'do', 'it', 'tomorrow.'], 'ar': ['نحن', 'سنفعل', 'ذلك', 'غداً.'] },
    { 'en': ['I', "don't", 'want', 'to', 'see', 'you', 'again.'], 'ar': ['لا', 'أريد', 'رؤيتك', 'مرّة', 'ثانية'] },
    { 'en': ['My', 'mother', 'is', 'crazy.'], 'ar': ['أمي', 'مجنونه.'] },
    { 'en': ["I'm", 'sure', "you're", 'right.'], 'ar': ['أنا', 'متأكدة', 'أنكَ', 'محق.'] },
    { 'en': ['Tom', "didn't", 'take', 'it', 'with', 'him.'], 'ar': ['توم', 'لم', 'يأخذها', 'معه.'] },
    { 'en': ['Is', 'that', 'even', 'possible?'], 'ar': ['هل', 'هذا', 'حتيَ', 'ممكن؟'] },
    { 'en': ['She', 'put', 'on', 'her', 'coat', 'and', 'went', 'out.'], 'ar': ['ارتدت', 'معطفها', 'ثم', 'خرجت.'] },
    { 'en': ['May', 'I', 'borrow', 'this', 'book?'], 'ar': ['أيمكنني', 'استعارة', 'هذا', 'الكتاب؟'] },
    { 'en': ['Would', 'you', 'ever', 'go', 'skinny', 'dipping?'], 'ar': ['هل', 'لك', 'ان', 'تذهب', 'للسباحة', 'عارياً.'] },
    { 'en': ['What', 'happened', 'here?'], 'ar': ['ماذا', 'حصل', 'هنا؟'] },
    { 'en': ['No', 'one', 'can', 'help', 'me.'], 'ar': ['لا', 'يستطيع', 'أحدٌ', 'مساعدتي.'] },
    { 'en': ['Will', 'you', 'marry', 'me?'], 'ar': ['هلا', 'تزوّجتني؟'] },
    { 'en': ['Tom', 'is', 'a', 'good', 'person.'], 'ar': ['توم', 'شخص', 'جيد'] },
    { 'en': ["That's", 'not', 'what', 'I', 'wanted', 'to', 'tell', 'you.'], 'ar': ['ليس', 'هذا', 'ما', 'أردت', 'إخبارك', 'عنه.'] },
    { 'en': ['I', 'have', 'to', 'help', 'my', 'mother.'], 'ar': ['يجب', 'علي', 'أن', 'أساعد', 'أمي.'] },
    { 'en': ['She', 'pretended', 'that', 'she', 'was', 'sick.'], 'ar': ['تظاهرت', 'بأنها', 'مريضة.'] },
    { 'en': ['You', 'might', 'have', 'told', 'me.'], 'ar': ['من', 'الممكن', 'أن', 'تكون', 'قد', 'أخبرتني.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'she', 'is?'], 'ar': ['هل', 'تعرف', 'من', 'هي؟'] },
    { 'en': ['Is', 'your', 'school', 'far', 'from', 'your', 'home?'], 'ar': ['هل', 'المدرسة', 'بعيدة', 'عن', 'منزلك؟'] },
    { 'en': ['The', 'accused', 'was', 'sentenced', 'to', 'death.'], 'ar': ['حُكم', 'على', 'المتهم', 'بالإعدام.'] },
    { 'en': ['Please', 'make', 'the', 'bed.'], 'ar': ['من', 'فضلك', 'رتب', 'السرير.'] },
    { 'en': ['In', 'Japan', 'a', 'new', 'school', 'year', 'starts', 'in', 'April.'], 'ar': ['في', 'اليابان', 'السنة', 'الدراسية', 'الجديدة', 'تبدأ', 'في', 'أبريل.'] },
    { 'en': ['You', 'may', 'go', 'anywhere.'], 'ar': ['يمكنك', 'أن', 'تذهب', 'أينما', 'شئت.'] },
    { 'en': ['Is', 'this', 'French?'], 'ar': ['هل', 'هذه', 'فرنسية؟'] },
    { 'en': ['He', 'is', 'good', 'at', 'driving.'], 'ar': ['إنه', 'ماهر', 'في', 'القيادة.'] },
    { 'en': ['School', 'begins', 'at', 'half', 'past', 'eight.'], 'ar': ['يبدأ', 'الدوام', 'الدراسي', 'في', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ['I', 'was', 'hoping', "you'd", 'volunteer.'], 'ar': ['كُنتُ', 'أتمنى', 'أن', 'تتطوّع.'] },
    { 'en': ['My', 'life', 'has', 'changed', 'quite', 'a', 'lot.'], 'ar': ['تغيّرت', 'حياتي', 'بشكل', 'هام.'] },
    { 'en': ['Americans', 'have', 'lost', 'their', 'trust', 'in', 'Toyota.'], 'ar': ['فقد', 'الأمريكيون', 'ثقتهم', 'في', 'تويوتا.'] },
    { 'en': ["How's", 'Tom?'], 'ar': ['كيف', 'توم؟'] },
    { 'en': ['I', "don't", 'like', 'him', 'because', 'he', 'is', 'sly', 'as', 'a', 'fox.'], 'ar': ['لا', 'أحبه', 'لأنه', 'ماكر', 'كالثعلب.'] },
    { 'en': ['I', 'am', 'in', 'the', 'house.'], 'ar': ['أنا', 'في', 'المنزل.'] },
    { 'en': ['My', 'brother', 'helped', 'me', 'with', 'my', 'homework.'], 'ar': ['ساعدني', 'أخي', 'أن', 'أحل', 'واجبي.'] },
    { 'en': ['This', 'problem', 'is', 'really', 'annoying.'], 'ar': ['هذه', 'المشكلة', 'مزعجة', 'حقًّا.'] },
    { 'en': ['I', "don't", 'understand', 'this', 'question', 'at', 'all.'], 'ar': ['لست', 'أستعوب', 'هذا', 'السؤال', 'أبدا.'] },
    { 'en': ['I', 'hugged', 'her.'], 'ar': ['احتضنتها.'] },
    { 'en': ['You', 'should', 'tell', 'the', 'truth.'], 'ar': ['عليك', 'أن', 'تقول', 'الحقيقة.'] },
    { 'en': ['Actions', 'speak', 'louder', 'than', 'words.'], 'ar': ['الأفعال', 'أبلغُ', 'مِنَ', 'الأقوالِ.'] },
    { 'en': ['Was', 'it', 'cloudy', 'in', 'Tokyo', 'yesterday?'], 'ar': ['أكان', 'الجو', 'غائماً', 'في', 'طوكيو', 'البارحة؟'] },
    { 'en': ['I', 'had', 'my', 'car', 'stolen', 'last', 'night.'], 'ar': ['سُرقت', 'سيارتي', 'ليلة', 'أمس.'] },
    { 'en': ["We've", 'just', 'finished', 'breakfast.'], 'ar': ['لقد', 'أنهينا', 'أكل', 'طعام', 'الفطور', 'للتو.'] },
    { 'en': ['No', 'problem', 'at', 'all!'], 'ar': ['لا', 'مشكلة', 'على', 'الإطلاق!'] },
    { 'en': ['Tom', 'gave', 'me', 'some', 'books.'], 'ar': ['أعطاني', 'توم', 'بعض', 'الكتب.'] },
    { 'en': ["I'm", 'ready', 'to', 'leave', 'now.'], 'ar': ['أنا', 'مستعد', 'للمغادرة', 'الآن.'] },
    { 'en': ['I', 'just', 'wish', 'I', 'knew', 'how', 'to', 'speak', 'French.'], 'ar': ['أتمنتى', 'فقط', 'لو', 'أستطعتُ', 'تكلّمَ', 'الفرنسيّةِ.'] },
    { 'en': ['You', 'took', 'the', 'wrong', 'key.'], 'ar': ['أخذتَ', 'مفتاحًا', 'خطأً.'] },
    { 'en': ['Some', 'children', 'play', 'video', 'games', 'all', 'the', 'time.'], 'ar': ['بعض', 'الأطفال', 'يلعبون', 'ألعاب', 'الفيديو', 'في', 'جميع', 'الأوقات.'] },
    { 'en': ['Tom', "isn't", 'interested', 'in', 'politics', 'at', 'all.'], 'ar': ['توم', 'ليس', 'مهتماً', 'بالسياسة', 'بعد', 'الآن.'] },
    { 'en': ['I', 'thought', 'it', 'was', 'true.'], 'ar': ['ظننته', 'صحيحًا.'] },
    { 'en': ['I', 'want', 'to', 'be', 'here.'], 'ar': ['أريد', 'أن', 'أكون', 'هنا'] },
    { 'en': ['I', 'have', 'a', 'sister.'], 'ar': ['لدي', 'أخت'] },
    { 'en': ['Do', 'they', 'love', 'each', 'other?'], 'ar': ['أتحبان', 'بعضكما؟'] },
    { 'en': ['It', 'was', 'cold', 'yesterday.'], 'ar': ['البارحة', 'كان', 'برداً.'] },
    { 'en': ['What', 'is', 'the', 'purpose', 'of', 'your', 'visit?'], 'ar': ['ما', 'غرض', 'زيارتك؟'] },
    { 'en': ['Destroy', 'this', 'temple.'], 'ar': ['دَمّر', 'هذا', 'المعبَد.'] },
    { 'en': ['I', 'fixed', 'the', 'bike', 'yesterday.'], 'ar': ['أصلحت', 'الدراجة', 'البارحة.'] },
    { 'en': ['Tom', 'liked', 'working', 'here.'], 'ar': ['استمتع', 'توم', 'بالعمل', 'هنا.'] },
    { 'en': ['I', "didn't", 'know', 'Tom', 'had', 'to', 'do', 'that', 'by', 'himself.'], 'ar': ['لم', 'أعلم', 'بأنه', 'كان', 'على', 'توم', 'فعل', 'ذلك', 'بنفسه.'] },
    { 'en': ['He', 'called', 'for', 'help.'], 'ar': ['صرخ', 'طالباً', 'النجدة.'] },
    { 'en': ['Please', 'knock', 'before', 'you', 'come', 'in.'], 'ar': ['من', 'فضلك', 'انقر', 'على', 'الباب', 'قبل', 'أن', 'تدخل.'] },
    { 'en': ['Tom', 'was', 'sitting', 'at', 'a', 'nearby', 'table.'], 'ar': ['توم', 'كان', 'جالسا', 'على', 'طاولة', 'قريبة.'] },
    { 'en': ['I', 'paid', 'for', 'the', 'tickets.'], 'ar': ['دفعتُ', 'ثمن', 'التذاكر.'] },
    { 'en': ['You', 'can', 'come', 'with', 'us.'], 'ar': ['أنتِ', 'يمكنكِ', 'أن', 'تأتي', 'معنا.'] },
    { 'en': ['He', 'who', 'laughs', 'last', 'laughs', 'best.'], 'ar': ['من', 'يضحك', 'أخيرًا', 'يضحك', 'كثيرًا.'] },
    { 'en': ['Was', 'it', 'an', 'earthquake?'], 'ar': ['هل', 'كان', 'ذلك', 'زلزالا؟'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'drive', 'a', 'car?'], 'ar': ['أيمكنك', 'قيادة', 'سيارة؟'] },
    { 'en': ['How', 'dare', 'you', 'say', 'such', 'a', 'thing!'], 'ar': ['كيف', 'تجرء', 'على', 'قول', 'شيءٍ', 'كهذا؟'] },
    { 'en': ['Stand', 'up!'], 'ar': ['قف!'] },
    { 'en': ["I've", 'changed', 'my', 'daily', 'routine.'], 'ar': ['غيرت', 'نمط', 'حياتي', 'اليومي.'] },
    { 'en': ['Could', 'you', 'help', 'me?'], 'ar': ['من', 'فضلك', 'ساعدني.'] },
    { 'en': ['I', 'want', 'to', 'live', 'here.'], 'ar': ['أريد', 'العيش', 'هنا.'] },
    { 'en': ['Is', 'there', 'anyone', 'who', 'might', 'want', 'to', 'hurt', 'Tom?'], 'ar': ['هل', 'يوجد', 'اي', 'شخص', 'ربما', 'يريد', 'أن', 'يؤذي', 'توم؟'] },
    { 'en': ['You', 'look', 'gloomy.'], 'ar': ['تبدو', 'كئيبا'] },
    { 'en': ['He', "doesn't", 'like', 'us.'], 'ar': ['إنه', 'لا', 'يحبنا.'] },
    { 'en': ['Give', 'me', 'a', 'chance!'], 'ar': ['اعطني', 'فرصة!'] },
    { 'en': ["We're", 'Canadians.'], 'ar': ['نحن', 'من', 'كندا.'] },
    { 'en': ['What', 'are', 'you', 'thinking', 'about?'], 'ar': ['بماذا', 'تفكر؟'] },
    { 'en': ['I', 'need', 'your', 'advice.'], 'ar': ['أحتاج', 'نصيحتك.'] },
    { 'en': ['What', "you're", 'suggesting', 'is', 'stupid.'], 'ar': ['ما', 'تقترحه', 'غباء.'] },
    { 'en': ['No', 'one', 'believes', 'that', 'anymore.'], 'ar': ['لا', 'أحد', 'يصدق', 'ذلك', 'بعد', 'الآن.'] },
    { 'en': ["What're", 'you', 'talking', 'about?'], 'ar': ['ما', 'الذي', 'تتحدث', 'عنه؟'] },
    { 'en': ['I', 'went', 'there', 'many', 'times.'], 'ar': ['ذهبت', 'إلى', 'هناك', 'مرات', 'عديدة.'] },
    { 'en': ['I', 'have', 'no', 'siblings.'], 'ar': ['ليس', 'لدي', 'أشقاء.'] },
    { 'en': ['Tom', 'got', 'here', 'before', 'sunrise.'], 'ar': ['توم', 'جاء', 'الى', 'هنا', 'قبل', 'الغروب'] },
    { 'en': ['I', 'was', 'hoping', 'I', 'was', 'wrong.'], 'ar': ['أملت', 'أن', 'أكون', 'مخطئا.'] },
    { 'en': ['I', 'am', 'an', 'honest', 'person.'], 'ar': ['أنا', 'إنسان', 'صادق.'] },
    { 'en': ['Tom', 'forgot', 'to', 'pay', 'the', 'bill.'], 'ar': ['نسي(', 'توم', ')', 'أن', 'يدفع', 'الفاتورة.'] },
    { 'en': ['May', 'I', 'sit', 'here?'], 'ar': ['أيمكنني', 'الجلوس', 'هنا؟'] },
    { 'en': ['He', "doesn't", 'run.'], 'ar': ['هو', 'لا', 'يجري'] },
    { 'en': ['Tom', 'wants', 'to', 'go', 'to', 'Australia.'], 'ar': ['يريد', 'توم', 'الذهاب', 'إلى', 'أستراليا.'] },
    { 'en': ['All', 'Tom', 'does', 'is', 'complain.'], 'ar': ['جلّ', 'ما', 'يفعله', 'توم', 'هو', 'التذمر.'] },
    { 'en': ['I', 'think', "I'm", 'right.'], 'ar': ['أعتقد', 'بأني', 'محق.'] },
    { 'en': ['Rabbits', 'have', 'long', 'ears.'], 'ar': ['للأرانب', 'آذان', 'طويلة.'] },
    { 'en': ['Stop', 'teasing', 'your', 'brother!'], 'ar': ['توقف', 'عن', 'مضايقة', 'أخيك!'] },
    { 'en': ['I', 'was', 'nearly', 'run', 'over', 'by', 'a', 'truck.'], 'ar': ['أوشكتْ', 'شاحنة', 'على', 'دهسي.'] },
    { 'en': ['She', 'asked', 'me', 'how', 'old', 'I', 'was.'], 'ar': ['سألتني', 'عن', 'عمري.'] },
    { 'en': ['I', 'have', 'a', 'surprise', 'for', 'you.'], 'ar': ['لدي', 'مفاجأة', 'لك.'] },
    { 'en': ['I', 'want', 'some', 'money.'], 'ar': ['أريد', 'بعض', 'المال.'] },
    { 'en': ['Please', 'tell', 'her', 'to', 'call', 'me', 'back.'], 'ar': ['من', 'فضلك', 'أبلغها', 'أن', 'تتصل', 'بي.'] },
    { 'en': ['Has', 'Tom', 'been', 'indicted?'], 'ar': ['هل', 'تم', 'اتهام', 'توم؟'] },
    { 'en': ['I', 'sent', 'you', 'an', 'email.'], 'ar': ['لقد', 'أرسلتُ', 'إليكَ', 'بريداً', 'إلكترونياً.'] },
    { 'en': ['Her', 'speech', 'moved', 'the', 'audience.'], 'ar': ['خطابها', 'أثَر', 'في', 'الجمهور.'] },
    { 'en': ['Stop!'], 'ar': ['قف!'] },
    { 'en': ['They', 'gave', 'him', 'a', 'chance', 'to', 'escape.'], 'ar': ['أعطوه', 'فرصة', 'للهروب.'] },
    { 'en': ['Just', 'follow', 'me.'], 'ar': ['اتبعني', 'فحسب.'] },
    { 'en': ["Let's", 'get', 'drunk.'], 'ar': ['هيا', 'بنا', 'لنسكر.'] },
    { 'en': ["She's", 'not', 'my', 'sister.'], 'ar': ['هىَ', 'ليست', 'أختي.'] },
    { 'en': ['Are', 'you', 'ready?'], 'ar': ['هل', 'أنت', 'جاهز؟'] },
    { 'en': ['Do', 'you', 'know', 'what', 'Tom', 'does', 'for', 'a', 'living?'], 'ar': ['هل', 'تعرف', 'ما', 'هي', 'مهنة', 'توم؟'] },
    { 'en': ["There's", 'no', 'chance', 'of', 'winning.'], 'ar': ['ليس', 'هناك', 'فرصة', 'للفوز.'] },
    { 'en': ['I', 'bought', 'her', 'a', 'clock.'], 'ar': ['اشتريت', 'لها', 'ساعة.'] },
    { 'en': ['Cut', 'the', 'potatoes.'], 'ar': ['قطع', 'البطاطا.'] },
    { 'en': ['I', 'know', 'your', 'father.'], 'ar': ['أعرف', 'أباك.'] },
    { 'en': ['Bring', 'it', 'back', 'to', 'me.'], 'ar': ['أعيده', 'إلي.'] },
    { 'en': ['My', 'front', 'tooth', 'fell', 'out.'], 'ar': ['وقعت', 'سنّي', 'الأمامية.'] },
    { 'en': ['Are', 'you', 'asking', 'me', 'to', 'marry', 'you?'], 'ar': ['هل', 'تطلب', 'مني', 'الزواج', 'منك؟'] },
    { 'en': ["Let's", 'make', 'a', 'trade.'], 'ar': ['دعونا', 'نصنع', 'تجارة.'] },
    { 'en': ['We', "aren't", 'going', 'to', 'win.'], 'ar': ['لن', 'نفوز.'] },
    { 'en': ['Our', 'father', 'goes', 'to', 'work', 'by', 'car.'], 'ar': ['والدنا', 'يذهب', 'الي', 'العمل', 'بالسيارة.'] },
    { 'en': ['You', 'must', 'follow', 'school', 'rules.'], 'ar': ['عليك', 'أن', 'تلتزم', 'بقوانين', 'المدرسة.'] },
    { 'en': ['Is', 'that', 'even', 'possible?'], 'ar': ['هل', 'هذا', 'ممكن؟'] },
    { 'en': ['The', 'conference', 'will', 'end', 'tomorrow.'], 'ar': ['سيختتم', 'المؤتمر', 'أعماله', 'غداً.'] },
    { 'en': ['Do', 'you', 'love', 'each', 'other', 'very', 'much?'], 'ar': ['هل', 'تحبان', 'بعضكما', 'البعض', 'بشدة؟'] },
    { 'en': ['What', 'other', 'options', 'do', 'I', 'have?'], 'ar': ['ما', 'الخيارات', 'الأخرى', 'التي', 'أمامي؟'] },
    { 'en': ['I', 'smell', 'something', 'burning,', 'Mother.'], 'ar': ['أشتم', 'رائحة', 'حريق', 'يا', 'أمي.'] },
    { 'en': ['What', 'do', 'you', 'like', 'about', 'me?'], 'ar': ['ما', 'الذي', 'يعجبك', 'بشأني؟'] },
    { 'en': ['He', 'excelled', 'in', 'music', 'even', 'as', 'a', 'child.'], 'ar': ['كان', 'متفوقا', 'في', 'الموسيقى', 'حتى', 'في', 'طفولته.'] },
    { 'en': ["I've", 'seen', 'him', 'before.'], 'ar': ['لقد', 'رأيتهُ', 'من', 'قبل.'] },
    { 'en': ['What', 'did', 'you', 'answer?'], 'ar': ['ماذا', 'أجبته؟'] },
    { 'en': ['Look', 'at', 'those', 'black', 'clouds.'], 'ar': ['أنظر', 'إلى', 'تلك', 'الغيوم', 'السوداء.'] },
    { 'en': ["I'll", 'take', 'this', 'one.'], 'ar': ['سآخذ', 'هذا.'] },
    { 'en': ['How', 'many', 'cats', 'are', 'there', 'in', 'this', 'house?'], 'ar': ['كم', 'عدد', 'القطط', 'الموجودة', 'في', 'هذا', 'المنزل', '؟'] },
    { 'en': ['When', 'did', 'you', 'see', 'the', 'film?'], 'ar': ['متى', 'رأيت', 'الفلم؟'] },
    { 'en': ['You', 'are', 'tired,', "aren't", 'you?'], 'ar': ['إنك', 'متعب', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['I', 'must', 'have', 'my', 'watch', 'repaired.'], 'ar': ['عليّ', 'أن', 'آخذ', 'ساعتي', 'لِتُصْلح.'] },
    { 'en': ["Don't", 'stand', 'near', 'me.'], 'ar': ['لا', 'تقف', 'قربي.'] },
    { 'en': ["We're", 'coming.'], 'ar': ['نحن', 'آتون'] },
    { 'en': ['My', 'car', 'burns', 'a', 'lot', 'of', 'gas.'], 'ar': ['تستخدم', 'سيارتي', 'الكثير', 'من', 'الوقود.'] },
    { 'en': ['Love', 'is', 'strong,', 'but', 'money', 'is', 'stronger.'], 'ar': ['الحُب', 'قوي,', 'ولكن', 'المال', 'أقوى.'] },
    { 'en': ['I', 'stood', 'at', 'the', 'end', 'of', 'the', 'line.'], 'ar': ['وقفت', 'في', 'آخر', 'الصف.'] },
    { 'en': ['I', 'love', 'reading', 'books.'], 'ar': ['أحب', 'قراءة', 'الكتب'] },
    { 'en': ['Can', 'you', 'lend', 'me', 'some', 'money?'], 'ar': ['أيمكنك', 'أن', 'تقرضني', 'بعض', 'المال؟'] },
    { 'en': ['You', 'should', 'rest', 'after', 'exercise.'], 'ar': ['عليك', 'أن', 'ترتاح', 'بعد', 'التمرين.'] },
    { 'en': ["I'd", 'like', 'to', 'see', 'her.'], 'ar': ['أود', 'أن', 'أراها'] },
    { 'en': ['Do', 'you', 'want', 'me', 'to', 'come?'], 'ar': ['هل', 'تريدني', 'أن', 'آتي؟'] },
    { 'en': ['It', 'is', 'not', 'easy', 'to', 'solve', 'the', 'problem.'], 'ar': ['ليس', 'من', 'السهل', 'حل', 'المشكلة.'] },
    { 'en': ['I', 'am', 'a', 'tourist.'], 'ar': ['أنا', 'سائح.'] },
    { 'en': ['Always', 'tell', 'the', 'truth.'], 'ar': ['قل', 'الحقيقة', 'دائماً.'] },
    { 'en': ['Physics', 'is', 'my', 'favorite', 'subject.'], 'ar': ['الفيزياء', 'هي', 'مادتي', 'المفضلة.'] },
    { 'en': ['He', 'tried', 'writing', 'a', 'short', 'story.'], 'ar': ['حاول', 'أن', 'يكتب', 'قصةً', 'قصيرةً.'] },
    { 'en': ['The', 'noise', 'was', 'quite', 'loud.'], 'ar': ['كانت', 'الضّجّة', 'صاخبة', 'جدّا.'] },
    { 'en': ['I', "don't", 'remember', 'what', 'happened', 'anymore.'], 'ar': ['لم', 'أعد', 'أذكر', 'ما', 'حدث.'] },
    { 'en': ['You', 'talk', 'too', 'much.'], 'ar': ['أنت', 'كثير', 'الكلام.'] },
    { 'en': ['Her', 'face', 'turned', 'white.'], 'ar': ['انقلب', 'وجهه', 'أبيضا.'] },
    { 'en': ["I've", 'only', 'got', 'one', 'left.'], 'ar': ['لم', 'يعد', 'لدي', 'إلا', 'واحدة.'] },
    { 'en': ['What', 'do', 'they', 'want?'], 'ar': ['ماذا', 'يريدون؟'] },
    { 'en': ['When', 'did', 'the', 'accident', 'take', 'place?'], 'ar': ['متى', 'حصل', 'الحادث؟'] },
    { 'en': ['Yesterday', 'was', 'my', 'birthday.'], 'ar': ['البارحة', 'كان', 'عيد', 'ميلادي.'] },
    { 'en': ['The', 'boy', "didn't", 'change', 'his', 'opinion.'], 'ar': ['لم', 'يغير', 'الولد', 'رأيه.'] },
    { 'en': ['They', 'were', 'attacked.'], 'ar': ['لقد', 'هوجموا.'] },
    { 'en': ["Don't", 'tell', 'me.', 'Let', 'me', 'guess.'], 'ar': ['إياك', 'أن', 'تخبرني،', 'دعني', 'أخمن.'] },
    { 'en': ['Your', 'questions', 'were', 'too', 'direct.'], 'ar': ['كانت', 'أسئلتك', 'مباشرة', 'جداً.'] },
    { 'en': ['I', 'asked', 'him', 'to', 'send', 'us', 'the', 'book.'], 'ar': ['طلبت', 'منه', 'أن', 'يبعث', 'إلينا', 'بالكتاب.'] },
    { 'en': ['I', "don't", 'want', 'to', 'look', 'silly.'], 'ar': ['لا', 'أريد', 'أن', 'أبدو', 'سخيفا.'] },
    { 'en': ['My', 'uncle', 'gave', 'me', 'a', 'camera.'], 'ar': ['أعطاني', 'عمي', 'كاميرا.'] },
    { 'en': ['Walk', 'ahead', 'of', 'me.'], 'ar': ['امشِ', 'أمامي.'] },
    { 'en': ["We're", 'listening.'], 'ar': ['نحن', 'نستمع'] },
    { 'en': ['Tell', 'them', 'what', 'I', 'told', 'you.'], 'ar': ['أخبرهم', 'ما', 'أخبرتك', 'بهِ.'] },
    { 'en': ["What's", 'wrong?'], 'ar': ['ما', 'المشكلة؟'] },
    { 'en': ['I', 'have', 'a', 'question', 'I', 'need', 'to', 'ask', 'you.'], 'ar': ['لدي', 'سؤال', 'أريد', 'سؤالك', 'عنه.'] },
    { 'en': ['We', 'put', 'up', 'the', 'flags', 'on', 'national', 'holidays.'], 'ar': ['نرفع', 'الأعلام', 'في', 'الأعياد', 'الوطنية.'] },
    { 'en': ['My', 'dream', 'is', 'to', 'become', 'a', 'firefighter.'], 'ar': ['أريد', 'أن', 'أكون', 'رجل', 'إطفاء.'] },
    { 'en': ['I', 'wish', 'to', 'go', 'to', 'Hawaii.'], 'ar': ['أتمنّى', 'الذهاب', 'إلى', 'هاواي.'] },
    { 'en': ['I', 'admit', 'that', 'I', 'was', 'careless.'], 'ar': ['أعترف', 'بأنني', 'كنت', 'مهملاً.'] },
    { 'en': ['What', 'time', 'did', 'you', 'eat?'], 'ar': ['متى', 'أكلت؟'] },
    { 'en': ["I've", 'worked', 'as', 'a', 'waiter', 'for', 'three', 'years.'], 'ar': ['عملت', 'نادلًا', 'لثلاث', 'سنوات.'] },
    { 'en': ['He', 'broke', 'into', 'a', 'house.'], 'ar': ['اقتحم', 'المنزل.'] },
    { 'en': ["It's", 'not', 'rocket', 'science.'], 'ar': ['ليس', 'الأمر', 'صعباً', 'كعلم', 'صناعة', 'الصواريخ.'] },
    { 'en': ['Can', 'you', 'speak', 'French?'], 'ar': ['أتستطيع', 'تحدث', 'الفرنسية؟'] },
    { 'en': ['The', 'grand', 'jury', 'indicted', 'Tom', 'for', 'murder.'], 'ar': ['إتهمت', 'هيئة', 'المحلفين', 'الكبرى', 'توم', 'بجريمة', 'قتل.'] },
    { 'en': ['I', "don't", 'have', 'anything', 'to', 'give', 'you.'], 'ar': ['لا', 'شيء', 'معي', 'لأعطيك', 'إياه.'] },
    { 'en': ['My', 'mother', 'made', 'me', 'a', 'new', 'dress.'], 'ar': ['صنعت', 'لي', 'أمي', 'فستانًا', 'جديدًا.'] },
    { 'en': ["They've", 'decided', 'to', 'get', 'married', 'next', 'month.'], 'ar': ['قررا', 'الزواج', 'الشهر', 'المقبل.'] },
    { 'en': ["You're", 'mocking', 'me.'], 'ar': ['أنت', 'تسخر', 'مني.'] },
    { 'en': ['Snails', 'move', 'slowly.'], 'ar': ['تتحرك', 'الحلزونات', 'ببطء.'] },
    { 'en': ['Tom', "isn't", 'very', 'happy.'], 'ar': ['توم', 'ليس', 'سعيداً', 'جداً'] },
    { 'en': ['I', "can't", 'thank', 'you', 'enough.'], 'ar': ['لا', 'أستطيع', 'أن', 'أشكركَ', 'كفاية.'] },
    { 'en': ['I', 'hope', 'war', "doesn't", 'break', 'out.'], 'ar': ['آمل', 'ألا', 'تنشب', 'الحرب.'] },
    { 'en': ['Thanks', 'for', 'your', 'explanation.'], 'ar': ['شكراً', 'على', 'الشرح.'] },
    { 'en': ['She', 'goes', 'to', 'school', 'on', 'foot.'], 'ar': ['هي', 'تذهبُ', 'إلى', 'المدرسة', 'مشياً', 'على', 'الأقدام.'] },
    { 'en': ['Tom', 'told', 'me', 'not', 'to', 'talk', 'to', 'Mary.'], 'ar': ['أخبرني', 'توم', 'بألا', 'أتكلم', 'مع', 'ماري.'] },
    { 'en': ['I', "can't", 'live', 'that', 'kind', 'of', 'life.'], 'ar': ['لا', 'أستطيع', 'أن', 'أعيش', 'ذلك', 'النوع', 'من', 'الحياة.'] },
    { 'en': ['Two', 'detectives', 'followed', 'the', 'suspect.'], 'ar': ['تَبِعَ', 'المشتبهَ', 'محققان.'] },
    { 'en': ['Why', 'do', 'you', 'want', 'to', 'know', 'that?'], 'ar': ['لماذا', 'تريد', 'أن', 'تعرف', 'ذلك؟'] },
    { 'en': ['Go', 'brush', 'your', 'teeth.'], 'ar': ['إذهب', 'واغسل', 'أسنانك.'] },
    { 'en': ['Is', 'this', 'book', 'yours?'], 'ar': ['هل', 'ذاك', 'الكتاب', 'لك؟'] },
    { 'en': ['You', 'almost', 'died.'], 'ar': ['أنتَ', 'تقريباً', 'مت.'] },
    { 'en': ['They', 'reached', 'their', 'goal.'], 'ar': ['لَقَد', 'وَصَلوا', 'إلى', 'هَدَفَهُم.'] },
    { 'en': ["She's", 'very', 'innocent.'], 'ar': ['تبدو', 'شديدة', 'البراءة.'] },
    { 'en': ["Don't", 'worry', 'about', 'us.'], 'ar': ['لا', 'تقلق', 'حيالنا.'] },
    { 'en': ['What', 'is', 'your', 'date', 'of', 'birth?'], 'ar': ['ما', 'هو', 'تاريخ', 'ميلادك؟'] },
    { 'en': ['Study', 'hard,', 'and', "you'll", 'succeed.'], 'ar': ['ادرس', 'بجد', 'و', 'ستنجح.'] },
    { 'en': ['Tom', 'is', 'a', 'very', 'nice', 'guy.'], 'ar': ['توم', 'رجل', 'ممتاز'] },
    { 'en': ['Great', 'weather,', "isn't", 'it?'], 'ar': ['إنه', 'جو', 'رائع', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['I', 'love', 'this', 'picture.'], 'ar': ['أحب', 'هذه', 'الصورة.'] },
    { 'en': ["Where's", 'Tom?'], 'ar': ['أين', 'توم؟'] },
    { 'en': ['Language', 'acquisition', 'requires', 'creativity.'], 'ar': ['يحتاج', 'اكتساب', 'اللغة', 'إلى', 'الإبداع.'] },
    { 'en': ['I', 'read', 'a', 'book', 'while', 'eating.'], 'ar': ['قرأت', 'كتاباً', 'و', 'أنا', 'آكل.'] },
    { 'en': ['Cheer', 'him', 'up', 'when', 'you', 'see', 'him.'], 'ar': ['سلّه', 'عندما', 'تراه.'] },
    { 'en': ['I', 'wonder', 'if', 'he', 'loves', 'me.'], 'ar': ['أتساءل', 'إن', 'كان', 'يحبني.'] },
    { 'en': ['I', 'know', 'him.'], 'ar': ['أنا', 'أعرفه'] },
    { 'en': ['The', 'lecture', 'lasted', 'for', 'two', 'hours.'], 'ar': ['استمرت', 'المحاضرة', 'مدة', 'ساعتين.'] },
    { 'en': ['Tom', 'hung', 'his', 'new', 'dartboard', 'on', 'the', 'wall.'], 'ar': ['توم', 'علق', 'لوح', 'السهام', 'المريشة', 'خاصته', 'على', 'الحائط.'] },
    { 'en': ["Don't", 'use', 'my', 'pen.'], 'ar': ['لا', 'تستخدم', 'قلمي.'] },
    { 'en': ["We're", 'not', 'getting', 'anywhere.'], 'ar': ['لن', 'نصل', 'إلى', 'أي', 'مكان.'] },
    { 'en': ['He', 'asked', 'us', 'what', 'we', 'wanted.'], 'ar': ['هو', 'سألنا', 'ماذا', 'نريد.'] },
    { 'en': ['We', "didn't", 'see', 'him', 'anywhere.'], 'ar': ['لم', 'نره', 'في', 'أي', 'مكان.'] },
    { 'en': ['When', 'did', 'you', 'graduate', 'from', 'college?'], 'ar': ['متى', 'تخرّجت', 'من', 'الكليّة؟'] },
    { 'en': ['Do', 'you', 'know', 'my', 'father?'], 'ar': ['أتعرف', 'والدي؟'] },
    { 'en': ["I'm", 'cold.'], 'ar': ['أشعر', 'بالبرد.'] },
    { 'en': ['He', 'declined', 'my', 'proposal.'], 'ar': ['رفض', 'عرضي.'] },
    { 'en': ['Please', 'be', 'serious.'], 'ar': ['كُن', 'جادًّا', 'أرجوك.'] },
    { 'en': ['It', 'tastes', 'really', 'good.'], 'ar': ['إنها', 'لذيذة', 'المذاق.'] },
    { 'en': ['You', 'owe', 'me', 'a', 'kiss.'], 'ar': ['إنّك', 'مدين', 'لي', 'بقبلة.'] },
    { 'en': ['Tom', 'won.'], 'ar': ['لقد', 'ربح', 'توم.'] },
    { 'en': ["What's", 'the', 'problem?'], 'ar': ['ما', 'المشكلة؟'] },
    { 'en': ["You'll", 'learn', 'how', 'to', 'do', 'it', 'sooner', 'or', 'later.'], 'ar': ['ستتعلم', 'كيف', 'تفعلها', 'عاجلًا', 'أو', 'آجلًا.'] },
    { 'en': ['Like', 'father,', 'like', 'son.'], 'ar': ['هذا', 'الشبل', 'من', 'ذاك', 'الأسد.'] },
    { 'en': ['The', 'tourists', 'wandered', 'around', 'the', 'stores.'], 'ar': ['تجول', 'السياح', 'حول', 'المحلات', 'التجارية', '.'] },
    { 'en': ['I', 'thought', 'that', "you'd", 'never', 'come', 'here.'], 'ar': ['اعتقدت', 'انك', 'لا', 'تريد', 'ان', 'تأتي', 'الى', 'هنا', '.'] },
    { 'en': ['I', 'have', 'a', 'pain', 'in', 'my', 'foot.'], 'ar': ['لدي', 'ألم', 'في', 'قدمي.'] },
    { 'en': ['I', 'wish', 'you', 'had', 'more', 'time', 'for', 'me.'], 'ar': ['أتمنى', 'أن', 'يكون', 'لديك', 'وقت', 'أكثر', 'لي.'] },
    { 'en': ['Tom', 'lives', 'in', 'an', 'old', 'building.'], 'ar': ['يعيش', 'توم', 'في', 'مبنى', 'قديم'] },
    { 'en': ['Both', 'Tom', 'and', 'Mary', 'were', 'absent', 'today.'], 'ar': ['كان', 'كلا', 'من', 'توم', 'وماري', 'غائبان', 'اليوم.'] },
    { 'en': ['It', "wasn't", 'easy', 'to', 'solve', 'this', 'problem.'], 'ar': ['لم', 'يكن', 'من', 'السهل', 'حل', 'هذه', 'المشكلة'] },
    { 'en': ["Don't", 'lie.'], 'ar': ['إياك', 'و', 'الكذب.'] },
    { 'en': ['We', 'sat', 'in', 'the', 'center', 'of', 'the', 'room.'], 'ar': ['جلسنا', 'وسط', 'الغرفة.'] },
    { 'en': ['He', 'lost', 'his', 'job.'], 'ar': ['خسر', 'وظيفته.'] },
    { 'en': ['To', 'make', 'matters', 'worse,', 'her', 'husband', 'died.'], 'ar': ['ومما', 'زاد', 'الطين', 'بلةً،', 'أن', 'زوجها', 'توفي.'] },
    { 'en': ['Where', 'is', 'the', 'boy?'], 'ar': ['أين', 'الولد؟'] },
    { 'en': ['Who', 'built', 'the', 'snowman?'], 'ar': ['من', 'صنع', 'رجل', 'الثلج', 'ذاك؟'] },
    { 'en': ['My', 'aunt', 'is', 'older', 'than', 'my', 'mother.'], 'ar': ['عمتي', 'أكبر', 'من', 'أمي.'] },
    { 'en': ['The', 'clock', 'ticked.'], 'ar': ['تكت', 'الساعة', '.'] },
    { 'en': ['You', 'like', 'English,', "don't", 'you?'], 'ar': ['إنك', 'تحب', 'الإنجليزية،', 'أليس', 'كذلك؟'] },
    { 'en': ['Listen', 'to', 'me,', 'OK?'], 'ar': ['استمع', 'إلي,', 'حسناً؟'] },
    { 'en': ['My', 'aunt', 'was', 'pleased', 'with', 'my', 'success.'], 'ar': ['فرحت', 'عمتي', 'بنجاحي.'] },
    { 'en': ['Can', 'you', 'show', 'me', 'another', 'room?'], 'ar': ['هل', 'تستطيع', 'أن', 'تريني', 'غرفة', 'أخرى؟'] },
    { 'en': ['One', 'lump', 'of', 'sugar,', 'please.'], 'ar': ['مكعب', 'سكر', 'واحد', 'من', 'فضلك.'] },
    { 'en': ['I', 'know', 'where', 'you', 'are.'], 'ar': ['أنا', 'أعلم', 'أين', 'أنت'] },
    { 'en': ['Is', 'it', 'really', 'all', 'over?'], 'ar': ['هل', 'انتهى', 'كل', 'شيء', 'حقا؟'] },
    { 'en': ['She', 'bought', 'eggs', 'by', 'the', 'dozen.'], 'ar': ['اشترت', 'البيض', 'بالدزينة.'] },
    { 'en': ['Did', 'you', 'catch', 'the', 'train?'], 'ar': ['هل', 'لحقت', 'بالقطار؟'] },
    { 'en': ["What's", 'happening', 'here?'], 'ar': ['ما', 'الذي', 'يحدث', 'هنا؟'] },
    { 'en': ['How', 'often', 'do', 'you', 'go', 'abroad?'], 'ar': ['كم', 'مرة', 'تسافر', 'إلى', 'الخارج', 'عادةً؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'hear', 'your', 'opinion.'], 'ar': ['أود', 'الاستماع', 'إلى', 'رأيك.'] },
    { 'en': ['Please', 'lend', 'me', 'your', 'knife.'], 'ar': ['من', 'فضلك', 'أعرني', 'سكينك.'] },
    { 'en': ['She', 'told', 'us', 'the', 'road', 'was', 'closed.'], 'ar': ['أخبرتنا', 'بأن', 'الطريق', 'مغلق.'] },
    { 'en': ['I', 'have', 'a', 'cold.'], 'ar': ['أصبت', 'بالزكام.'] },
    { 'en': ['Just', 'tell', 'me', 'what', 'you', 'want.'], 'ar': ['فقط', 'أخبرني', 'ما', 'الذي', 'تريده.'] },
    { 'en': ['Do', 'you', 'eat', 'in', 'the', 'classroom?'], 'ar': ['هل', 'تأكل', 'في', 'الفصل؟'] },
    { 'en': ['I', 'am', 'very', 'happy', 'in', 'Georgia.'], 'ar': ['أنا', 'سعيدٌ', 'جداً', 'في', 'جورجيا.'] },
    { 'en': ['This', 'website', 'is', 'very', 'useful.'], 'ar': ['هذا', 'الموقع', 'مفيد', 'جدا.'] },
    { 'en': ['He', 'used', 'his', 'head.'], 'ar': ['لقد', 'إستخدم', 'رأسه.'] },
    { 'en': ['What', 'you', 'did', 'was', 'wrong.'], 'ar': ['ما', 'فعلتيهِ', 'أنتِ', 'كان', 'خاطئاً.'] },
    { 'en': ['Where', 'can', 'I', 'buy', 'a', 'ticket?'], 'ar': ['أين', 'يمكنني', 'أن', 'أشتري', 'تذكرة؟'] },
    { 'en': ["It's", 'up', 'to', 'you', 'to', 'decide.'], 'ar': ['القرار', 'قرارك.'] },
    { 'en': ['They', 'achieved', 'their', 'goal.'], 'ar': ['لَقَد', 'حَقَقوا', 'هَدَفَهُم.'] },
    { 'en': ['Just', 'a', 'minute.'], 'ar': ['دقيقة', 'من', 'فضلك.'] },
    { 'en': ['We', "can't", 'wait', 'much', 'longer.'], 'ar': ['لا', 'يمكننا', 'الانتظار', 'أكثر.'] },
    { 'en': ['Please', 'be', 'quiet.'], 'ar': ['ابقَ', 'هادئاً', 'من', 'فضلك.'] },
    { 'en': ['I', 'congratulated', 'him', 'on', 'his', 'success.'], 'ar': ['هنأته', 'على', 'نجاحه.'] },
    { 'en': ['Tom', 'is', 'a', 'French', 'teacher.'], 'ar': ['توم', 'مدرس', 'فرنسية.'] },
    { 'en': ['It', 'was', 'worth', 'a', 'try.'], 'ar': ['كان', 'يستحق', 'المحاولة.'] },
    { 'en': ['Are', 'you', 'free', 'tomorrow?'], 'ar': ['هل', 'أنت', 'غير', 'مشغول', 'غداً؟'] },
    { 'en': ["I'll", 'meet', 'him', 'tomorrow.'], 'ar': ['سوف', 'اقابله', 'غدا.'] },
    { 'en': ['My', 'father', 'used', 'to', 'be', 'a', 'strong', 'man.'], 'ar': ['أبي', 'اعتاد', 'أن', 'يكون', 'رجلاً', 'قوياً.'] },
    { 'en': ['The', 'earth', 'rotates.'], 'ar': ['تدور', 'الأرض.'] },
    { 'en': ["I'm", 'just', 'curious.'], 'ar': ['أشعر', 'بالفضول', 'فحسب.'] },
    { 'en': ["I'm", 'not', 'a', 'student.'], 'ar': ['أنا', 'لست', 'طالباً.'] },
    { 'en': ['Was', 'her', 'story', 'true?'], 'ar': ['هل', 'كانت', 'قصتها', 'حقيقية؟'] },
    { 'en': ["Don't", 'you', 'want', 'to', 'stop', 'Tom?'], 'ar': ['ألا', 'تريد', 'إيقاف', 'توم؟'] },
    { 'en': ['Tom', 'is', 'playing', 'cards', 'with', 'Mary.'], 'ar': ['توم', 'يلعب', 'الورق', 'مع', 'ماري.'] },
    { 'en': ['I', 'have', 'no', 'more', 'than', '1,000', 'yen', 'with', 'me.'], 'ar': ['ليس', 'لدي', 'أكثر', 'من', '1,000', 'ين', '.'] },
    { 'en': ['You', 'look', 'very', 'tired.'], 'ar': ['يبدو', 'عليك', 'التعب.'] },
    { 'en': ['This', 'is', 'my', "father's", 'house.'], 'ar': ['هذا', 'بيت', 'أبي.'] },
    { 'en': ['They', 'need', 'more', 'time.'], 'ar': ['يحتاجون', 'لمزيد', 'من', 'الوقت.'] },
    { 'en': ['Too', 'much', 'drinking', 'will', 'make', 'you', 'sick.'], 'ar': ['الإكثار', 'من', 'شرب', 'المسكر', 'سيصيبك', 'بالمرض.'] },
    { 'en': ['French', 'is', 'spoken', 'in', 'France.'], 'ar': ['الفرنسية', 'يتكلم', 'بها', 'في', 'فرنسا.'] },
    { 'en': ['There', 'is', 'a', 'picture', 'on', 'the', 'wall.'], 'ar': ['هناك', 'صورة', 'معلقة', 'على', 'الحائط.'] },
    { 'en': ['We', 'went', 'on', 'a', 'picnic', 'together.'], 'ar': ['تنزهنا', 'معًا.'] },
    { 'en': ['Sometimes', 'you', 'scare', 'me.'], 'ar': ['إنك', 'تخيفني', 'في', 'بعض', 'الأحيان.'] },
    { 'en': ['Please', 'fill', 'out', 'this', 'form', 'first.'], 'ar': ['رجاءً،', 'املأ', 'هذا', 'النموذج', 'أولًا.'] },
    { 'en': ['Tom', 'is', 'outside', 'watering', 'the', 'flowers.'], 'ar': ['توم', 'في', 'الخارج', 'يسقي', 'الأزهار.'] },
    { 'en': ['He', 'tried', 'it', 'over', 'and', 'over', 'again.'], 'ar': ['جرّبها', 'مرة', 'بعد', 'أخرى.'] },
    { 'en': ['Tokyo', 'is', 'the', 'largest', 'city', 'in', 'Japan.'], 'ar': ['طوكيو', 'هي', 'أكبر', 'مدينة', 'في', 'اليابان.'] },
    { 'en': ["I'd", 'never', 'hit', 'a', 'woman.'], 'ar': ['لن', 'أضرب', 'امرأة', 'أبدا.'] },
    { 'en': ['Come', 'if', 'you', 'can.'], 'ar': ['تعال', 'إذا', 'أمكنك.'] },
    { 'en': ['May', 'I', 'use', 'your', 'telephone?'], 'ar': ['أيمكنني', 'استعمال', 'هاتفك؟'] },
    { 'en': ['I', 'am', 'sure.'], 'ar': ['أنا', 'متأكد.'] },
    { 'en': ['He', 'concentrated', 'on', 'his', 'studies.'], 'ar': ['ركز', 'في', 'دراسته.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'use', 'a', 'dictionary?'], 'ar': ['هل', 'تعرف', 'كيف', 'تستخدم', 'معجمًا؟'] },
    { 'en': ['You', 'broke', 'the', 'rules.'], 'ar': ['أنتَ', 'كسرتَ', 'القواعد.'] },
    { 'en': ['Now', 'we', 'know', 'what', 'happened.'], 'ar': ['عرفنا', 'الآن', 'ما', 'حدث.'] },
    { 'en': ['Do', 'you', 'want', 'to', 'go', 'to', 'the', 'movies?'], 'ar': ['هل', 'تريد', 'الذهاب', 'إلى', 'السينما؟'] },
    { 'en': ['Are', 'you', 'happy', 'with', 'that?'], 'ar': ['هل', 'أنتَ', 'سعيد', 'مع', 'ذلك؟'] },
    { 'en': ["It's", 'not', 'something', 'anyone', 'can', 'do.'], 'ar': ['ليس', 'شيئًا', 'يمكن', 'لأيّ', 'شخص', 'فعله.'] },
    { 'en': ['He', 'is', 'not', 'available.'], 'ar': ['ليس', 'موجوداً.'] },
    { 'en': ['Please', 'insert', 'your', 'card.'], 'ar': ['من', 'فضلك', 'أدخل', 'بطاقتك.'] },
    { 'en': ['Would', 'three', "o'clock", 'be', 'all', 'right?'], 'ar': ['هل', 'الساعة', 'الثالثة', 'توقيت', 'مناسب؟'] },
    { 'en': ['You', 'idiot!'], 'ar': ['يا', 'غبي!'] },
    { 'en': ['Is', 'it', 'true', 'that', 'you', "weren't", 'here', 'yesterday?'], 'ar': ['هل', 'صحيح', 'أنك', 'لم', 'تكن', 'هنا', 'البارحة؟'] },
    { 'en': ['We', "don't", 'know', 'her.'], 'ar': ['إننا', 'لا', 'نعرفها.'] },
    { 'en': ['Come', 'on.', "Let's", 'get', 'out', 'of', 'here.'], 'ar': ['هيا.', 'دعونا', 'نخرج', 'من', 'هنا.'] },
    { 'en': ['Do', 'you', 'like', 'running?'], 'ar': ['هل', 'تحب', 'الجري؟'] },
    { 'en': ['Details', "weren't", 'immediately', 'available.'], 'ar': ['لم', 'تكن', 'التفاصيل', 'جاهزة', 'مباشرة.'] },
    { 'en': ['He', 'is', 'worthy', 'to', 'be', 'captain', 'of', 'our', 'team.'], 'ar': ['إنه', 'جدير', 'بأن', 'يكون', 'قائد', 'فريقنا.'] },
    { 'en': ['The', 'car', 'crashed', 'into', 'the', 'wall.'], 'ar': ['اصطدمت', 'السيارة', 'بالحائط.'] },
    { 'en': ["I'll", 'drive', 'you', 'home.'], 'ar': ['سأوصلك', 'إلى', 'المنزل', 'بالسيارة.'] },
    { 'en': ['The', 'boat', 'is', 'on', 'fire.'], 'ar': ['السّفينة', 'تحترق.'] },
    { 'en': ['Will', 'he', 'come', 'tomorrow?'], 'ar': ['هل', 'سيأتي', 'غداً؟'] },
    { 'en': ['No', 'one', 'could', 'deny', 'this.'], 'ar': ['لا', 'يستطيع', 'أحد', 'أن', 'ينكر', 'هذا.'] },
    { 'en': ['Where', 'are', 'my', 'books?'], 'ar': ['أين', 'كتبي؟'] },
    { 'en': ["There's", 'nothing', 'to', 'discuss.'], 'ar': ['ليس', 'هناك', 'ما', 'نناقشه.'] },
    { 'en': ['I', 'have', 'no', 'homework', 'today.'], 'ar': ['ليس', 'عندي', 'وظيفة', 'اليوم.'] },
    { 'en': ['I', 'would', 'rather', 'not', 'go.'], 'ar': ['أفضل', 'أن', 'لا', 'أذهب.'] },
    { 'en': ["It's", 'on', 'me.'], 'ar': ['الحساب', 'عليّ.'] },
    { 'en': ['He', 'came', 'several', 'times.'], 'ar': ['أتى', 'عدة', 'مرات.'] },
    { 'en': ['Tom', 'is', 'angry', 'now.'], 'ar': ['توم', 'غاضب', 'الآن.'] },
    { 'en': ['I', 'was', 'happy', 'yesterday.'], 'ar': ['كنت', 'فرِحًا', 'بالأمس.'] },
    { 'en': ['I', "can't", 'believe', 'it!'], 'ar': ['لا', 'أستطيع', 'أن', 'أُصدق', 'هذا!'] },
    { 'en': ['Are', 'you', 'coming', 'or', 'not?'], 'ar': ['هل', 'ستأتي', 'أم', 'لا؟'] },
    { 'en': ["Let's", 'shake', 'hands.'], 'ar': ['لنتصافح.'] },
    { 'en': ['Tom', 'promised', 'to', 'be', 'here', 'before', '2:30.'], 'ar': ['وعدني', 'توم', 'بأنّه', 'سيكون', 'هنا', 'قبل', '2:30.'] },
    { 'en': ['I', 'have', 'a', 'good', 'life', 'now.'], 'ar': ['لدي', 'حياة', 'جيدة', 'الان'] },
    { 'en': ['He', 'has', 'been', 'waiting', 'for', 'an', 'hour.'], 'ar': ['انتظر', 'لساعة.'] },
    { 'en': ['The', 'police', 'are', 'after', 'me.'], 'ar': ['الشرطة', 'تبحث', 'عنّي.'] },
    { 'en': ['This', 'is', 'where', 'Tom', 'usually', 'parks', 'his', 'car.'], 'ar': ['هنا', 'حيث', 'توم', 'يركن', 'سيارته', 'غالبا.'] },
    { 'en': ['We', 'all', 'desire', 'success.'], 'ar': ['كلنا', 'يرغب', 'بالنجاح.'] },
    { 'en': ['The', 'war', 'finally', 'broke', 'out.'], 'ar': ['اِندلعت', 'الحرب', 'أخيراً.'] },
    { 'en': ['They', 'say', 'that', 'she', 'was', 'born', 'in', 'Germany.'], 'ar': ['يُقال', 'أنها', 'وُلدت', 'في', 'ألمانيا.'] },
    { 'en': ['Any', 'child', 'can', 'do', 'that.'], 'ar': ['بإمكان', 'أي', 'ولد', 'القيام', 'بذلك.'] },
    { 'en': ["You're", 'still', 'young.'], 'ar': ['ما', 'زلت', 'يافعاً.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'to', 'do.'], 'ar': ['لدي', 'الكثير', 'لأفعله.'] },
    { 'en': ["Shouldn't", 'you', 'go', 'home?'], 'ar': ['يجب', 'ان', 'تذهب', 'للبيت', '؟'] },
    { 'en': ['Tom', 'served', 'two', 'terms', 'in', 'Congress.'], 'ar': ['خدم', 'توم', 'فترتين', 'في', 'الكونغرس.'] },
    { 'en': ['Do', 'as', 'you', 'like.'], 'ar': ['إفعل', 'ما', 'تريد.'] },
    { 'en': ['She', "didn't", 'give', 'me', 'my', 'money', 'back.'], 'ar': ['لم', 'تعد', 'إليّ', 'نقودي.'] },
    { 'en': ['These', 'books', 'belong', 'to', 'me.'], 'ar': ['هذه', 'الكتب', 'لي.'] },
    { 'en': ['I', 'came', 'here', 'yesterday.'], 'ar': ['جئت', 'هنا', 'بالأمس.'] },
    { 'en': ['Please', 'clean', 'the', 'floor', 'with', 'this', 'mop.'], 'ar': ['من', 'فضلك', 'نظف', 'الارض', 'بهذه', 'الممسحة'] },
    { 'en': ['A', 'friend', 'in', 'need', 'is', 'a', 'friend', 'indeed.'], 'ar': ['الصديقُ', 'وقتَ', 'الضيقِ.'] },
    { 'en': ['Tom', 'thinks', 'Mary', 'should', 'apologize', 'to', 'John.'], 'ar': ['يظن', 'توم', 'أن', 'على', 'ماري', 'الاعتذار', 'من', 'جون.'] },
    { 'en': ['What', 'are', 'you', 'doing?'], 'ar': ['ما', 'الذي', 'تفعله؟'] },
    { 'en': ['I', 'chose', 'to', 'look', 'the', 'other', 'way.'], 'ar': ['إخترت', 'أن', 'أنظر', 'للجهة', 'الأخرى.'] },
    { 'en': ['He', 'took', 'a', 'picture', 'of', 'the', 'koala.'], 'ar': ['أخذ', 'صورة', 'للكوالا.'] },
    { 'en': ['I', 'learned', 'a', 'new', 'technique.'], 'ar': ['تعلمت', 'تقنية', 'جديدة.'] },
    { 'en': ['Is', 'everything', 'all', 'right?'], 'ar': ['هل', 'كل', 'شيء', 'على', 'ما', 'يرام؟'] },
    { 'en': ['The', 'jet', 'landed', 'at', 'Tokyo.'], 'ar': ['هبطت', 'الطائرة', 'في', 'طوكيو.'] },
    { 'en': ['I', 'met', 'him', 'yesterday.'], 'ar': ['قابلته', 'البارحة.'] },
    { 'en': ['Try', 'and', 'calm', 'down.'], 'ar': ['حاول', 'أن', 'تهدأ.'] },
    { 'en': ['Where', 'are', 'your', 'things?'], 'ar': ['أين', 'أشياءك؟'] },
    { 'en': ['I', 'speak', 'a', 'little', 'Slovenian.'], 'ar': ['أتحدث', 'السلوفينية', 'قليلا.'] },
    { 'en': ['Italy', 'is', 'far', 'from', 'Brazil.'], 'ar': ['إيطاليا', 'بعيدة', 'عن', 'البرازيل.'] },
    { 'en': ['They', 'are', 'having', 'a', 'party', 'tomorrow.'], 'ar': ['سيقيمون', 'حفلة', 'غداً.'] },
    { 'en': ['Keep', 'your', 'room', 'as', 'neat', 'as', 'you', 'can.'], 'ar': ['أبقِ', 'غرفتك', 'نظيفةً', 'بقدر', 'الإمكان.'] },
    { 'en': ['How', 'did', 'Tom', 'know', 'where', 'Mary', 'was?'], 'ar': ['كيف', 'عَلِم', 'توم', 'مكان', 'ماري؟'] },
    { 'en': ['We', 'have', 'an', 'emergency.'], 'ar': ['لدينا', 'حالة', 'طارئة.'] },
    { 'en': ['I', "can't", 'live', 'that', 'kind', 'of', 'life.'], 'ar': ['لا', 'أستطيع', 'أن', 'أعيش', 'حياةً', 'كتِلْك.'] },
    { 'en': ['Hand', 'me', 'that', 'book,', 'please.'], 'ar': ['ناولني', 'ذاك', 'الكتاب', 'من', 'فضلك.'] },
    { 'en': ['Will', 'you', 'help', 'me?'], 'ar': ['هل', 'ستساعدني؟'] },
    { 'en': ['He', 'kept', 'a', 'seat', 'for', 'me.'], 'ar': ['حجز', 'مقعداً', 'لي.'] },
    { 'en': ['Tom', "didn't", 'want', 'to', 'go', 'there.'], 'ar': ['لم', 'يرد', 'توم', 'الذهاب', 'إلى', 'هناك.'] },
    { 'en': ["I'm", 'not', 'going', 'to', 'school', 'on', 'Saturday.'], 'ar': ['لن', 'أذهب', 'للمدرسة', 'يوم', 'السبت.'] },
    { 'en': ['He', 'hurt', 'his', 'left', 'foot', 'when', 'he', 'fell.'], 'ar': ['لقد', 'أصاب', 'قدمه', 'اليسرى', 'عندما', 'سقط.'] },
    { 'en': ['When', 'are', 'you', 'going', 'to', 'quit', 'smoking?'], 'ar': ['متى', 'ستقلع', 'عن', 'التدخين؟'] },
    { 'en': ['Last', 'summer', 'I', 'traveled', 'to', 'Italy.'], 'ar': ['سافرت', 'إلى', 'إيطاليا', 'الصيف', 'الفائت.'] },
    { 'en': ['He', "couldn't", 'get', 'the', 'job.'], 'ar': ['لم', 'يستطع', 'الحصول', 'على', 'الوظيفة.'] },
    { 'en': ['I', 'realize', 'I', 'messed', 'up.'], 'ar': ['أدركت', 'الآن', 'أنني', 'عبثت.'] },
    { 'en': ['She', 'despises', 'him', 'only', 'because', 'he', 'is', 'poor.'], 'ar': ['تبغضه', 'لأنه', 'فقير', 'فحسب.'] },
    { 'en': ["It's", 'up', 'to', 'you', 'to', 'decide.'], 'ar': ['القرار', 'لك.'] },
    { 'en': ['I', 'can', 'help', 'Tom', 'out.'], 'ar': ['يمكن', 'أن', 'أخرج', 'توم', 'من', 'المأزق.'] },
    { 'en': ['What', 'would', 'you', 'say', 'if', 'you', 'were', 'in', 'my', 'place?'], 'ar': ['ماذا', 'كُنتِ', 'ستقولينَ', 'لو', 'كنتِ', 'في', 'مكاني؟'] },
    { 'en': ['He', 'is', 'angry', 'with', 'you.'], 'ar': ['إنهُ', 'غاضب', 'معكَ.'] },
    { 'en': ['Tom', 'looks', 'tired', 'this', 'morning.'], 'ar': ['يبدو', 'توم', 'متعبا', 'هذا', 'الصباح'] },
    { 'en': ['Do', 'you', 'want', 'me', 'to', 'give', 'you', 'a', 'hand?'], 'ar': ['هل', 'تريدني', 'أن', 'أساعدك؟'] },
    { 'en': ["She'll", 'do', 'it', 'in', '24', 'hours.'], 'ar': ['ستنجزه', 'خلال', 'أربعاً', 'و', 'عشرين', 'ساعة.'] },
    { 'en': ['His', 'car', 'ran', 'into', 'a', 'tree.'], 'ar': ['اصطدمت', 'سيارته', 'بشجرة.'] },
    { 'en': ['Get', 'out', 'of', 'my', 'room!'], 'ar': ['اخرج', 'من', 'غرفتي'] },
    { 'en': ["I'm", 'here', 'for', 'you.'], 'ar': ['أنا', 'هنا', 'من', 'أجلك.'] },
    { 'en': ['If', 'it', 'rains', 'tomorrow,', "I'll", 'stay', 'at', 'home.'], 'ar': ['إذا', 'أمطرت', 'غداً', 'سأبقى', 'في', 'المنزل.'] },
    { 'en': ['When', 'shall', 'we', 'go?'], 'ar': ['متى', 'علينا', 'أن', 'نذهب؟'] },
    { 'en': ['My', 'grandfather', 'is', 'in', 'his', 'nineties.'], 'ar': ['جدي', 'في', 'التسعينات', 'من', 'عمره.'] },
    { 'en': ['Who', 'says', 'that?'], 'ar': ['من', 'يقول', 'هذا؟'] },
    { 'en': ["It's", 'incredible.'], 'ar': ['لا', 'يُصدّق.'] },
    { 'en': ["That's", 'enough.'], 'ar': ['هذا', 'يكفي.'] },
    { 'en': ['Check', 'around.'], 'ar': ['تحقق', 'حولها.'] },
    { 'en': ['Most', 'Americans', 'liked', 'Roosevelt.'], 'ar': ['أحب', 'أكثر', 'الأمريكيين', 'روزفلت.'] },
    { 'en': ['This', 'knife', 'was', 'very', 'useful', 'to', 'me.'], 'ar': ['هذه', 'السكين', 'أفادتني', 'كثيرًا.'] },
    { 'en': ['I', 'want', 'to', 'know', 'your', 'opinion.'], 'ar': ['أريد', 'أن', 'أعرف', 'رأيك.'] },
    { 'en': ['Do', 'you', 'have', 'an', 'appointment?'], 'ar': ['هل', 'لديك', 'موعد؟'] },
    { 'en': ['He', 'wrote', 'the', 'report.'], 'ar': ['كتب', 'التقرير.'] },
    { 'en': ['I', 'am', 'not', 'afraid.'], 'ar': ['أنا', 'لست', 'خائف.'] },
    { 'en': ['Tom', 'is', 'one', 'of', 'our', 'best', 'detectives.'], 'ar': ['توم', 'واحد', 'من', 'أفضل', 'محققينا.'] },
    { 'en': ['This', 'castle', 'is', 'beautiful.'], 'ar': ['هذه', 'القلعة', 'رائعة.'] },
    { 'en': ['Football', 'is', 'my', 'favorite', 'game.'], 'ar': ['كرة', 'القدم', 'أحب', 'رياضة', 'إلى', 'قلبي.'] },
    { 'en': ['The', 'house', 'went', 'cheap.'], 'ar': ['بيع', 'المنزل', 'بثمن', 'رخيص.'] },
    { 'en': ["I'm", 'sure', 'Tom', 'is', 'planning', 'to', 'do', 'that.'], 'ar': ['متأكد', 'بأن', 'توم', 'يخطط', 'لفعل', 'ذلك.'] },
    { 'en': ["Don't", 'read', 'in', 'that', 'room.'], 'ar': ['لا', 'تقرأ', 'في', 'تلك', 'الغرفة.'] },
    { 'en': ['This', 'is', 'a', 'present', 'for', 'you.'], 'ar': ['هذه', 'الهدية', 'لك.'] },
    { 'en': ['Did', 'you', 'watch', 'the', 'game?'], 'ar': ['هل', 'شاهدت', 'المباراة؟'] },
    { 'en': ['We', 'need', 'your', 'help.'], 'ar': ['نحتاج', 'إلى', 'مساعدتك.'] },
    { 'en': ['Shame', 'on', 'you!'], 'ar': ['عار', 'عليك!'] },
    { 'en': ['Like', 'father,', 'like', 'son.'], 'ar': ['الإبن', 'سر', 'أبيه.'] },
    { 'en': ['I', 'give', 'my', 'word.'], 'ar': ['أنا', 'أعدك'] },
    { 'en': ['The', 'phone', 'rang', 'when', 'I', 'was', 'having', 'lunch.'], 'ar': ['رنّ', 'الجرس', 'عندما', 'كنت', 'أتغدّى.'] },
    { 'en': ['Sometimes', 'you', 'scare', 'me.'], 'ar': ['إنك', 'تشعرني', 'بالخوف', 'أحيانا.'] },
    { 'en': ['Tom', 'told', 'us', 'what', 'to', 'do.'], 'ar': ['أخبرنا', 'توم', 'بما', 'علينا', 'فعله.'] },
    { 'en': ['Call', 'a', 'doctor', 'immediately.'], 'ar': ['اتّصل', 'بطبيب', 'في', 'الحال!'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['إنه', 'ساخن', 'جداً.'] },
    { 'en': ['Is', 'it', 'love?'], 'ar': ['هل', 'هذا', 'هو', 'الحب؟'] },
    { 'en': ['Do', 'you', 'sometimes', 'go', 'abroad', 'on', 'your', 'holidays?'], 'ar': ['هل', 'تذهب', 'أحيانا', 'إلى', 'خارج', 'البلاد', 'لقضاء', 'عطلتك؟'] },
    { 'en': ['Where', 'will', 'you', 'be', 'on', 'Monday?'], 'ar': ['أين', 'ستكون', 'يوم', 'الإثنين؟'] },
    { 'en': ['Who', 'is', 'that', 'person?'], 'ar': ['من', 'ذلك', 'الشخص؟'] },
    { 'en': ['Do', 'you', 'hear', 'any', 'sound?'], 'ar': ['هل', 'تسمع', 'أي', 'صوت؟'] },
    { 'en': ['Please', 'iron', 'the', 'shirt.'], 'ar': ['اكوِ', 'القميص', 'من', 'فضلك.'] },
    { 'en': ['I', 'lost', 'my', 'glasses.'], 'ar': ['لقد', 'فقدتُ', 'نظّاراتي.'] },
    { 'en': ['We', 'have', 'a', 'big', 'dog.'], 'ar': ['لدينا', 'كلبٌ', 'كبير.'] },
    { 'en': ['I', 'am', 'looking', 'for', 'a', 'job,', 'sir.'], 'ar': ['أبحث', 'عن', 'عمل', 'يا', 'سيدي.'] },
    { 'en': ['She', 'is', 'angry', 'with', 'me.'], 'ar': ['إنها', 'غاضبة', 'معي.'] },
    { 'en': ['I', 'bought', 'him', 'a', 'clock.'], 'ar': ['ابتعتُ', 'لهُ', 'ساعة.'] },
    { 'en': ['One', 'can', 'always', 'find', 'time.'], 'ar': ['يستطيع', 'الواحد', 'أن', 'يجد', 'الوقتَ', 'دائمًا.'] },
    { 'en': ['How', 'will', 'he', 'pay', 'his', 'debts?'], 'ar': ['كيفً', 'سيسدد', 'ديونهُ؟'] },
    { 'en': ['These', 'books', 'belong', 'to', 'me.'], 'ar': ['هذه', 'كتبي.'] },
    { 'en': ['She', 'began', 'crying.'], 'ar': ['أخذت', 'تبكي.'] },
    { 'en': ['She', 'rides', 'a', 'motorcycle', 'well.'], 'ar': ['قيادتها', 'للدراجة', 'النارية', 'جيدة.'] },
    { 'en': ['I', 'was', 'hungry.'], 'ar': ['كنت', 'جائعاً.'] },
    { 'en': ['A', 'cat', 'came', 'out', 'from', 'under', 'the', 'desk.'], 'ar': ['خرجت', 'قطة', 'من', 'تحت', 'المكتب.'] },
    { 'en': ['Hurry!'], 'ar': ['استعجل!'] },
    { 'en': ['I', 'want', 'you', 'to', 'get', 'out', 'of', 'town.'], 'ar': ['أريد', 'أن', 'تخرج', 'من', 'المدينة.'] },
    { 'en': ['He', 'killed', 'that', 'man.'], 'ar': ['قتل', 'ذلك', 'الرجل.'] },
    { 'en': ['I', 'think', 'otherwise.'], 'ar': ['اعتقد', 'غير', 'ذلك.'] },
    { 'en': ['I', 'promise', 'not', 'to', 'disappoint', 'you.'], 'ar': ['أعدك', 'أنّني', 'لن', 'أخيّب', 'آمالك.'] },
    { 'en': ['Please', 'take', 'your', 'shoes', 'off.'], 'ar': ['من', 'فضلك', 'اخلع', 'نعليك.'] },
    { 'en': ["I'm", 'not', 'a', 'tourist.'], 'ar': ['انا', 'لست', 'سائحاً.'] },
    { 'en': ["That's", 'just', 'a', 'personal', 'opinion.'], 'ar': ['ذلك', 'فقط', 'رأيٌ', 'شخصي.'] },
    { 'en': ["What's", 'in', 'this', 'bag?'], 'ar': ['ماذا', 'يوجد', 'في', 'الحقيبة؟'] },
    { 'en': ["Tomorrow's", 'your', 'day', 'off.'], 'ar': ['غدا', 'عطلتك.'] },
    { 'en': ['I', 'had', 'my', 'thirtieth', 'birthday', 'last', 'week.'], 'ar': ['لقد', 'كان', 'عيد', 'ميلادي', 'الثلاثين', 'الأسبوع', 'الماضي.'] },
    { 'en': ['He', 'runs.'], 'ar': ['هو', 'يجري'] },
    { 'en': ['I', "don't", 'like', 'these', 'remarks.'], 'ar': ['لا', 'تعجبني', 'هذه', 'الملاحظات.'] },
    { 'en': ['You', 'must', 'consider', 'this', 'problem', 'as', 'a', 'whole.'], 'ar': ['يجب', 'عليك', 'أن', 'تنظر', 'في', 'هذه', 'المشكلة', 'بشكلٍ', 'كلي.'] },
    { 'en': ['Bread', 'is', 'made', 'from', 'wheat.'], 'ar': ['يصنع', 'الخبز', 'من', 'القمح.'] },
    { 'en': ['Did', 'you', 'buy', 'it', 'on', 'the', 'black', 'market?'], 'ar': ['هل', 'اشتريت', 'ذلك', 'من', 'السوق', 'السوداء؟'] },
    { 'en': ["It's", 'dangerous!'], 'ar': ['إنك', 'في', 'خطر!'] },
    { 'en': ['I', 'want', 'a', 'massage.', 'I', 'need', 'to', 'relax.'], 'ar': ['أريد', 'التدليك.', 'أحتاج', 'إلى', 'الاسترخاء.'] },
    { 'en': ['Just', 'put', 'it', 'anywhere.'], 'ar': ['فقط', 'ضعه', 'في', 'أي', 'مكان.'] },
    { 'en': ['Once', 'again.'], 'ar': ['مجدداً.'] },
    { 'en': ['Stop', 'that.'], 'ar': ['أوقف', 'ذلك'] },
    { 'en': ['You', 'had', 'better', 'go.'], 'ar': ['من', 'الأفضل', 'لك', 'الذهاب.'] },
    { 'en': ['It', 'was', 'stupid', 'of', 'me', 'to', 'make', 'such', 'a', 'mistake.'], 'ar': ['كان', 'من', 'الغباء', 'مني', 'أن', 'أقترف', 'خطأً', 'كهذا.'] },
    { 'en': ['Both', 'of', 'my', 'parents', "don't", 'know', 'how', 'to', 'swim.'], 'ar': ['كلا', 'والداي', 'لا', 'يعرفان', 'كيف', 'يسبحان'] },
    { 'en': ['I', 'am', 'going', 'to', 'play', 'tennis', 'in', 'the', 'afternoon.'], 'ar': ['سألعب', 'التنس', 'بعد', 'الظهر.'] },
    { 'en': ['I', "don't", 'think', "he'll", 'come.'], 'ar': ['لا', 'أظن', 'أنه', 'سيأتي.'] },
    { 'en': ['Nobody', 'came.'], 'ar': ['لم', 'يأتي', 'أحد.'] },
    { 'en': ['Press', 'any', 'key', 'to', 'continue.'], 'ar': ['اضغط', 'أي', 'مفتاح', 'للمتابعة.'] },
    { 'en': ['Tom', 'went', 'to', 'Boston', 'with', 'a', 'friend.'], 'ar': ['ذهب', 'توم', 'إلى', 'بوسطن', 'مع', 'صديق.'] },
    { 'en': ['You', "don't", 'seem', 'to', 'care', 'what', 'happens.'], 'ar': ['لا', 'يبدو', 'أنك', 'تهتم', 'بما', 'يحدث.'] },
    { 'en': ['Tom', "didn't", 'know', 'what', 'to', 'say.'], 'ar': ['توم', 'لم', 'يعرفْ', 'ماذا', 'يقول.'] },
    { 'en': ['I', 'will', 'stay', 'here', 'all', 'this', 'month.'], 'ar': ['سأظل', 'هنا', 'هذا', 'الشهر', 'بأكمله.'] },
    { 'en': ['That', 'house', 'belongs', 'to', 'him.'], 'ar': ['ذاك', 'البيت', 'له.'] },
    { 'en': ['Tom', 'is', 'wanted', 'for', 'murder.'], 'ar': ['توم', 'مطلوب', 'بتهمة', 'القتل.'] },
    { 'en': ['What', 'made', 'you', 'come', 'here', 'so', 'early?'], 'ar': ['ما', 'الذي', 'جاء', 'بك', 'إلى', 'هنا', 'باكراً', 'هكذا؟'] },
    { 'en': ['The', 'cops', 'are', 'here.'], 'ar': ['إنّ', 'الشّرطة', 'هنا.'] },
    { 'en': ["It's", 'raining', 'again!'], 'ar': ['إنها', 'تمطر', 'مجدداً!'] },
    { 'en': ['How', 'did', 'you', 'know', 'that?'], 'ar': ['أنتَ', 'كيف', 'عرفتَ', 'ذلك؟'] },
    { 'en': ['Are', 'you', 'really', 'a', 'princess?'], 'ar': ['هل', 'حقا', 'أنت', 'أميرة', '؟'] },
    { 'en': ['We', 'are', 'hungry.'], 'ar': ['إننا', 'جائعون.'] },
    { 'en': ['We', 'have', 'to', 'be', 'ready', 'for', 'anything.'], 'ar': ['يجب', 'ان', 'نكون', 'مستعدين', 'لأي', 'شيء'] },
    { 'en': ['Many', 'museums', 'are', 'closed', 'on', 'Mondays.'], 'ar': ['الكثير', 'من', 'المتاحف', 'تكون', 'مغلقة', 'أيام', 'الإثنين.'] },
    { 'en': ['Is', 'that', 'yours?'], 'ar': ['هل', 'هذا', 'لكَ؟'] },
    { 'en': ['I', 'remember', 'hearing', 'that', 'music', 'somewhere.'], 'ar': ['أتذكر', 'أني', 'سمعت', 'هذه', 'الموسيقى', 'في', 'مكان', 'ما', 'من', 'قبل.'] },
    { 'en': ['Police', 'are', 'investigating', 'Tom.'], 'ar': ['تحقق', 'الشرطة', 'مع', 'توم'] },
    { 'en': ['There', 'are', 'a', 'lot', 'of', 'problems', 'we', "can't", 'avoid.'], 'ar': ['هناك', 'الكثير', 'من', 'المشاكل', 'التي', 'لا', 'يمكننا', 'تجنبها.'] },
    { 'en': ['Why', 'have', 'you', 'come', 'here?'], 'ar': ['لمذا', 'أتيت', 'إلى', 'هنا؟'] },
    { 'en': ["I'm", 'reading', 'your', 'book.'], 'ar': ['أنا', 'أقرأ', 'كتابك'] },
    { 'en': ['Tom', 'was', 'fired.'], 'ar': ['طرد', 'توم', 'من', 'عمله.'] },
    { 'en': ['He', "doesn't", 'need', 'to', 'work.'], 'ar': ['إنه', 'لا', 'يحتاج', 'إلى', 'أن', 'يعمل.'] },
    { 'en': ['My', 'brother', 'is', 'now', 'in', 'Australia.'], 'ar': ['أخي', 'في', 'أستراليا', 'حالياً.'] },
    { 'en': ['How', 'tall', 'you', 'are!'], 'ar': ['ما', 'أطول', 'عودك!'] },
    { 'en': ['He', 'is', 'at', 'his', 'office.'], 'ar': ['إنه', 'في', 'مكتبه.'] },
    { 'en': ['I', 'was', 'wrong.'], 'ar': ['كنت', 'مخطئاً.'] },
    { 'en': ['Come', 'here.'], 'ar': ['تعال', 'هنا.'] },
    { 'en': ['Tourists', 'have', 'increased', 'in', 'number.'], 'ar': ['زاد', 'السياح', 'عدداً.'] },
    { 'en': ['I', 'think', "that's", "what's", 'happening.'], 'ar': ['أظنّ', 'أن', 'هذا', 'ما', 'يحدث.'] },
    { 'en': ["What're", 'you', 'saying?'], 'ar': ['ما', 'الذي', 'تقوله؟'] },
    { 'en': ['They', 'are', 'crazy', 'about', 'jazz.'], 'ar': ['إنهم', 'يعشقون', 'موسيقى', 'الجاز', 'بجنون.'] },
    { 'en': ['How', 'is', 'everyone?'], 'ar': ['كيف', 'حالكم', 'جميعاً؟'] },
    { 'en': ['Put', 'your', 'weapons', 'down.'], 'ar': ['ضع', 'سلاحك', 'على', 'الأرض.'] },
    { 'en': ['She', 'is', 'accustomed', 'to', 'sitting.'], 'ar': ['إنّها', 'معتادة', 'على', 'الجلوس.'] },
    { 'en': ["I'm", 'afraid', 'of', 'earthquakes.'], 'ar': ['أخاف', 'من', 'الزلازل.'] },
    { 'en': ['Come', 'on!', "We'll", 'be', 'late.'], 'ar': ['هيا', 'بنا!', 'سنتأخر.'] },
    { 'en': ['That', 'woman', 'is', 'much', 'older', 'than', 'I', 'am.'], 'ar': ['تلك', 'السيدة', 'أكبر', 'مني', 'بكثير.'] },
    { 'en': ['Will', 'you', 'drive', 'me', 'home?'], 'ar': ['هل', 'لك', 'أن', 'توصلني', 'إلى', 'البيت؟'] },
    { 'en': ["I'm", 'so', 'fat.'], 'ar': ['أنا', 'شديد', 'البدانة.'] },
    { 'en': ['I', 'take', 'a', 'bath', 'almost', 'every', 'day.'], 'ar': ['أستحم', 'كل', 'يوم', 'تقريباً.'] },
    { 'en': ['The', 'doctor', 'advised', 'me', 'to', 'give', 'up', 'smoking.'], 'ar': ['نصحني', 'الطبيب', 'بالإقلاع', 'عن', 'التدخين.'] },
    { 'en': ['Tell', 'him', 'to', 'wait.'], 'ar': ['أخبره', 'بأن', 'ينتظر'] },
    { 'en': ['He', 'drives', 'his', 'own', 'car.'], 'ar': ['إنه', 'يقود', 'سيارته', 'الخاصة.'] },
    { 'en': ['Tom', 'is', 'very', 'special.'], 'ar': ['توم', 'مميز', 'جدا.'] },
    { 'en': ["I've", 'never', 'seen', 'anything', 'like', 'that.'], 'ar': ['أنا', 'لم', 'أرىَ', 'شيئاً', 'مثل', 'ذلك.'] },
    { 'en': ['I', 'saw', 'you', 'outside.'], 'ar': ['رأيتك', 'في', 'الخارج.'] },
    { 'en': ['Tom', 'is', 'a', 'pretty', 'good', 'skier.'], 'ar': ['توم', 'متزلج', 'جيد.'] },
    { 'en': ['Think', 'about', 'it.'], 'ar': ['فكر', 'بذلك'] },
    { 'en': ['We', "don't", 'need', 'your', 'help.'], 'ar': ['لسنا', 'بحاجة', 'لمساعدتك.'] },
    { 'en': ['My', 'family', 'lives', 'here.'], 'ar': ['عائلتي', 'تسكن', 'هنا'] },
    { 'en': ['I', 'took', 'a', 'walk.'], 'ar': ['تمشّيت.'] },
    { 'en': ["She's", 'really', 'smart,', "isn't", 'she?'], 'ar': ['إنها', 'ذكية', 'جداً', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['You', 'must', 'find', 'work.'], 'ar': ['أنتَ', 'لابد', 'أن', 'تجد', 'عملاً.'] },
    { 'en': ['I', 'feel', 'awkward', 'in', 'his', 'presence.'], 'ar': ['أشعر', 'بارتباكٍ', 'في', 'حضوره.'] },
    { 'en': ['Close', 'your', 'eyes.'], 'ar': ['أغلق', 'عينيك.'] },
    { 'en': ['He', 'looked', 'up', 'at', 'the', 'sky.'], 'ar': ['نظر', 'إلى', 'السماء.'] },
    { 'en': ['Tom', 'got', 'very', 'mad.'], 'ar': ['غضب', 'توم', 'جدا.'] },
    { 'en': ['Take', 'your', 'time.'], 'ar': ['خذ', 'وقتك.'] },
    { 'en': ['Tom', "doesn't", 'know', 'how', 'to', 'milk', 'a', 'goat.'], 'ar': ['توم', 'لا', 'يعرف', 'كيفية', 'حَلْب', 'الماعز.'] },
    { 'en': ['Tom', 'is', 'on', 'the', 'phone', 'with', 'Mary.'], 'ar': ['توم', 'يحادث', 'ماري', 'على', 'الهاتف.'] },
    { 'en': ['This', 'chair', 'needs', 'to', 'be', 'fixed.'], 'ar': ['هذا', 'الكرسي', 'يحتاج', 'إلى', 'الإصلاح.'] },
    { 'en': ["She's", 'not', 'a', 'child.'], 'ar': ['ليست', 'طفلة.'] },
    { 'en': ['Tom', 'turned', 'the', 'faucet', 'on.'], 'ar': ['توم', 'فتح', 'الصنبور'] },
    { 'en': ['You', 'make', 'me', 'happy.'], 'ar': ['أنت', 'تجعلني', 'سعيدا/أنت', 'تسعدني'] },
    { 'en': ['Stop', 'hitting', 'your', 'sister.'], 'ar': ['توقف', 'عن', 'ضرب', 'أختك.'] },
    { 'en': ['I', 'go', 'shopping', 'every', 'morning.'], 'ar': ['أتسوق', 'كل', 'صباح.'] },
    { 'en': ['Thank', 'you', 'for', 'inviting', 'me', 'to', 'the', 'party.'], 'ar': ['شكرأ', 'على', 'دعوتكَ', 'لي', 'إلى', 'الحفلة.'] },
    { 'en': ['Where', 'do', 'you', 'work?'], 'ar': ['أين', 'مكان', 'عملك؟'] },
    { 'en': ['She', 'gave', 'her', 'father', 'a', 'tie.'], 'ar': ['أعطت', 'والدها', 'ربطة', 'عنق.'] },
    { 'en': ['I', 'love', 'the', 'sound', 'of', 'rain', 'on', 'the', 'roof.'], 'ar': ['أحب', 'صوت', 'الغيث', 'أثناء', 'هطوله', 'علی', 'السقف.'] },
    { 'en': ['She', 'asked', 'me', 'to', 'wake', 'her', 'at', 'six.'], 'ar': ['طلبت', 'مني', 'أن', 'أوقظها', 'عند', 'السادسة.'] },
    { 'en': ['We', 'are', 'very', 'grateful', 'for', 'what', 'you', 'did.'], 'ar': ['نحن', 'ممتنون', 'لك', 'جدا', 'لما', 'فعلت.'] },
    { 'en': ['May', 'I', 'ask', 'where', 'are', 'you', 'going?'], 'ar': ['هل', 'يمكنني', 'أن', 'أسأل,', 'إلى', 'أين', 'أنتِ', 'ذاهبة؟'] },
    { 'en': ['I', 'work', 'in', 'a', 'tourist', 'agency.'], 'ar': ['انا', 'اعمل', 'في', 'وكالة', 'سياحية', '.'] },
    { 'en': ['I', "don't", 'feel', 'good', 'about', 'it.'], 'ar': ['لست', 'مرتاحا', 'لهذا.'] },
    { 'en': ['I', 'like', 'tennis.'], 'ar': ['أحب', 'لعب', 'التنس.'] },
    { 'en': ["It's", 'very', 'important', 'to', 'get', 'enough', 'rest.'], 'ar': ['انه', 'لمن', 'المهم', 'جداً', 'الحصول', 'على', 'ما', 'يكفي', 'من', 'الراحة.'] },
    { 'en': ['We', 'walked', 'for', 'about', '6', 'kilometers.'], 'ar': ['مشينا', 'ست', 'كيلومترات.'] },
    { 'en': ['We', 'are', 'against', 'war.'], 'ar': ['نحن', 'ضد', 'الحرب.'] },
    { 'en': ['Our', 'school', 'is', 'near', 'the', 'station.'], 'ar': ['مدرستنا', 'قريبة', 'من', 'المحطة.'] },
    { 'en': ['I', 'hate', 'coffee.'], 'ar': ['أكره', 'القهوة.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['من', 'أينَ', 'تعرف', 'هذا؟'] },
    { 'en': ['I', 'waited', 'for', 'you.'], 'ar': ['إنتظرتك.'] },
    { 'en': ['Will', 'you', 'be', 'home', 'for', 'Christmas?'], 'ar': ['هل', 'ستكون', 'في', 'البلاد', 'وقت', 'عيد', 'الميلاد؟'] },
    { 'en': ['Tom', 'took', 'his', 'camera', 'with', 'him.'], 'ar': ['أخذ', 'توم', 'كمرته', 'معه.'] },
    { 'en': ['My', 'mother', 'is', 'on', 'a', 'diet.'], 'ar': ['تتبع', 'أمي', 'حميةً.'] },
    { 'en': ['He', 'came', 'in', 'person.'], 'ar': ['لقد', 'أتى', 'بنفسه'] },
    { 'en': ['Tom', 'understands.'], 'ar': ['توم', 'يفهم'] },
    { 'en': ['That', 'was', 'my', 'mistake.'], 'ar': ['إنه', 'خطأي.'] },
    { 'en': ['She', 'graduated', 'with', 'honors.'], 'ar': ['تخرجت', 'بإمتياز.'] },
    { 'en': ['The', 'rain', 'changed', 'to', 'snow.'], 'ar': ['تغير', 'المطر', 'إلى', 'ثلج.'] },
    { 'en': ['I', 'studied', 'English', 'when', 'I', 'was', 'there.'], 'ar': ['درست', 'الإنجليزية', 'عندما', 'كنت', 'هناك.'] },
    { 'en': ['You', "can't", 'swim,', 'can', 'you?'], 'ar': ['لا', 'يمكنك', 'السباحة،', 'صحيح؟'] },
    { 'en': ['Stop', 'acting', 'like', 'a', 'child.'], 'ar': ['كف', 'عن', 'التصرف', 'كالأطفال.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'working', 'with', 'you.'], 'ar': ['أنا', 'أتتطلع', 'لأعمل', 'معك.'] },
    { 'en': ['My', 'mother', 'is', 'angry.'], 'ar': ['والدتي', 'غاضبة.'] },
    { 'en': ['She', 'answered', 'in', 'tears.'], 'ar': ['أجابت', 'و', 'هي', 'تبكي.'] },
    { 'en': ['She', 'married', 'a', 'sailor.'], 'ar': ['هىَ', 'متزوجة', 'من', 'بحار.'] },
    { 'en': ['She', "doesn't", 'live', 'there', 'any', 'more.'], 'ar': ['لم', 'تعد', 'تسكن', 'هناك.'] },
    { 'en': ['Destiny', 'is', 'sometimes', 'cruel.'], 'ar': ['القدر', 'في', 'بعض', 'الأحيان', 'يكون', 'قاسياً.'] },
    { 'en': ['Just', 'tell', 'me', 'what', 'you', 'want.'], 'ar': ['فقط', 'أخبرني', 'ماذا', 'تريد.'] },
    { 'en': ["I'd", 'like', 'to', 'introduce', 'some', 'friends', 'of', 'mine.'], 'ar': ['أريد', 'أن', 'أعرّفك', 'ببعض', 'أصدقائي.'] },
    { 'en': ["I'm", 'so', 'glad', 'that', 'you', 'succeeded.'], 'ar': ['سُرِرتُ', 'بنجاحِكَ.'] },
    { 'en': ["What's", 'that', 'building?'], 'ar': ['ما', 'هذا', 'المبنى؟'] },
    { 'en': ['The', 'girl', 'released', 'the', 'birds', 'from', 'the', 'cage.'], 'ar': ['الفتاة', 'حررت', 'الطيور', 'من', 'القفص.'] },
    { 'en': ['She', 'owns', 'a', 'gigantic', 'house.'], 'ar': ['هي', 'تملك', 'منزلا', 'ضخما.'] },
    { 'en': ['Do', 'whatever', 'it', 'takes.'], 'ar': ['افعل', 'كل', 'ما', 'يحتاجه', 'الأمر'] },
    { 'en': ["I'll", 'pay', 'for', 'lunch.'], 'ar': ['طعام', 'العشاء', 'سأشتريه', 'أنا.'] },
    { 'en': ['You', "don't", 'smoke,', 'do', 'you?'], 'ar': ['إنك', 'لا', 'تدخن،', 'صحيح؟'] },
    { 'en': ['I', 'will', 'shoot', 'him.'], 'ar': ['سأطلق', 'عليه', 'الرصاص.'] },
    { 'en': ['What', 'do', 'you', 'do?'], 'ar': ['ما', 'هو', 'عملك؟'] },
    { 'en': ["She'll", 'be', 'getting', 'married', 'next', 'year.'], 'ar': ['إنها', 'ستتزوج', 'العام', 'المقبل.'] },
    { 'en': ['I', 'met', 'him', 'on', 'the', 'street', 'the', 'other', 'day.'], 'ar': ['قابلته', 'في', 'الطريق', 'ذلك', 'اليوم.'] },
    { 'en': ["I'm", 'not', 'going', 'unless', 'Tom', 'goes.'], 'ar': ['لن', 'أذهب', 'ما', 'لَم', 'يذهب', 'توم.'] },
    { 'en': ['My', 'dog', 'follows', 'me', 'wherever', 'I', 'go.'], 'ar': ['يتبعني', 'كلبي', 'حيثما', 'ذهبت.'] },
    { 'en': ['Hey,', 'get', 'off', 'my', 'bike.'], 'ar': ['أنتَ،', 'انزل', 'عن', 'درّاجتي.'] },
    { 'en': ['I', 'look', 'up', 'to', 'you.'], 'ar': ['أنا', 'احترمك.'] },
    { 'en': ['You', 'are', 'not', 'a', 'student.'], 'ar': ['إنك', 'لست', 'طالباً.'] },
    { 'en': ['Please', 'let', 'me', 'take', 'your', 'picture.'], 'ar': ['دعني', 'آخذ', 'صورة', 'لك', 'إذا', 'سمحت.'] },
    { 'en': ['I', 'know', 'Tom', 'is', 'bald.'], 'ar': ['أعرف', 'أن', 'توم', 'أصلع.'] },
    { 'en': ['She', 'was', 'scared', 'to', 'cross', 'the', 'road.'], 'ar': ['خافت', 'أن', 'تعبر', 'الشارع.'] },
    { 'en': ['I', 'did', 'see', 'him.'], 'ar': ['قد', 'رأيته.'] },
    { 'en': ['How', 'dare', 'you', 'insult', 'my', 'sister!'], 'ar': ['كيف', 'تجرأ', 'على', 'شتم', 'أختي!'] },
    { 'en': ['That', 'feast', 'was', 'one', 'of', 'the', 'best', 'I', 'ever', 'had.'], 'ar': ['كانت', 'الوليمة', 'من', 'أطيب', 'ما', 'ذقت.'] },
    { 'en': ['You', 'have', 'to', 'come', 'back.'], 'ar': ['عليك', 'العودة.'] },
    { 'en': ['The', "TV's", 'on.'], 'ar': ['التلفاز', 'مفتوح.'] },
    { 'en': ['I', 'like', 'you.'], 'ar': ['أحبك.'] },
    { 'en': ['When', 'were', 'you', 'born?'], 'ar': ['متى', 'وُلِدْت؟'] },
    { 'en': ['I', 'was', 'absent.'], 'ar': ['كنت', 'غائبا.'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['الجو', 'حار', 'جداً'] },
    { 'en': ['I', 'broke', 'a', 'glass.'], 'ar': ['كسرت', 'كوبا.'] },
    { 'en': ['He', 'read', 'the', 'book', 'from', 'cover', 'to', 'cover.'], 'ar': ['قرأ', 'الكتاب', 'من', 'الجلدة', 'إلى', 'الجلدة.'] },
    { 'en': ['Tom', "isn't", 'married.'], 'ar': ['توم', 'ليس', 'متزوجاً.'] },
    { 'en': ['The', 'parcel', 'weighs', 'more', 'than', 'one', 'pound.'], 'ar': ['يزن', 'الطرد', 'أكثر', 'من', 'باوند.'] },
    { 'en': ["I'd", 'like', 'to', 'sit', 'by', 'the', 'window.'], 'ar': ['أُفضل', 'أن', 'أجلس', 'بجوار', 'النافذة.'] },
    { 'en': ['You', 'can', 'go', 'home', 'now.'], 'ar': ['بإمكانك', 'العودة', 'إلى', 'المنزل', 'الآن.'] },
    { 'en': ['He', 'taught', 'me', 'history.'], 'ar': ['درّسني', 'التاريخ.'] },
    { 'en': ['Tom', 'drinks', 'coffee', 'every', 'day.'], 'ar': ['يشرب', 'توم', 'القهوة', 'يوميا.'] },
    { 'en': ['Madrid', 'is', 'the', 'capital', 'of', 'Spain.'], 'ar': ['مدريد', 'هي', 'عاصمة', 'أسبانيا.'] },
    { 'en': ['I', "don't", 'live', 'here.'], 'ar': ['أنا', 'لا', 'أسكن', 'هنا'] },
    { 'en': ["I'm", 'going', 'through', 'changes.'], 'ar': ['أمر', 'بتغيرات.'] },
    { 'en': ['He', 'is', 'strong', 'as', 'a', 'horse.'], 'ar': ['إنه', 'قوي', 'كالحصان.'] },
    { 'en': ['Are', 'those', 'your', 'dogs?'], 'ar': ['هل', 'تلك', 'كلابك؟'] },
    { 'en': ['School', 'begins', 'at', 'eight-thirty.'], 'ar': ['يبدأ', 'الدوام', 'الدراسي', 'في', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ['I', 'use', 'Firefox.'], 'ar': ['أنا', 'أستخدم', 'Firefox'] },
    { 'en': ["Let's", 'face', 'the', 'facts!'], 'ar': ['لنواجه', 'الحقائق.'] },
    { 'en': ["Let's", 'go!'], 'ar': ['هيا', 'لنذهب!'] },
    { 'en': ['She', 'is', 'good', 'at', 'speaking', 'English.'], 'ar': ['إنها', 'ماهرة', 'في', 'تحدث', 'الإنجليزية.'] },
    { 'en': ["I'll", 'explain', 'this', 'later.'], 'ar': ['سأشرح', 'هذا', 'لاحقا.'] },
    { 'en': ['He', 'is', 'ill.'], 'ar': ['هو', 'مريض'] },
    { 'en': ['I', 'just', 'got', 'up.'], 'ar': ['استيقظت', 'للتوّ.'] },
    { 'en': ['There', 'was', 'a', 'light', 'rain', 'yesterday.'], 'ar': ['أمطرت', 'مطراً', 'خفيفاً', 'البارحة.'] },
    { 'en': ['Few', 'people', 'know', 'about', 'the', 'plan.'], 'ar': ['القليل', 'يعرف', 'الخطة.'] },
    { 'en': ['I', 'wanted', 'to', 'buy', 'one', 'of', "Tom's", 'paintings.'], 'ar': ['أردت', 'شراء', 'إحدى', 'لوحات', 'توم.'] },
    { 'en': ["He's", 'a', 'good', 'person.'], 'ar': ['إنه', 'شخص', 'جيد.'] },
    { 'en': ['The', 'ship', 'sails', 'for', 'Honolulu', 'tomorrow.'], 'ar': ['ستبحر', 'السفينة', 'إلى', 'هونولولو', 'غداً.'] },
    { 'en': ['Tom', "can't", 'let', 'go', 'of', 'the', 'past.'], 'ar': ['لا', 'يمكن', 'لتوم', 'أن', 'ينسى', 'الماضي.'] },
    { 'en': ['My', 'aunt', 'showed', 'me', 'how', 'to', 'make', 'good', 'coffee.'], 'ar': ['علمتني', 'عمتي', 'كيف', 'أحضّر', 'قهوةً', 'لذيذة.'] },
    { 'en': ['He', 'can', 'come.'], 'ar': ['بإمكانه', 'المجيء'] },
    { 'en': ['The', 'situation', 'is', 'worse', 'than', 'we', 'believed.'], 'ar': ['الموقف', 'أسوأ', 'مما', 'نظن.'] },
    { 'en': ["I'm", 'really', 'embarrassed.'], 'ar': ['لقد', 'أُحرجت', 'حقاً.'] },
    { 'en': ['Maybe', 'I', "shouldn't", 'invite', 'Tom', 'to', 'the', 'party.'], 'ar': ['ربما', 'علي', 'أن', 'لا', 'أدعوا', 'توم', 'إلى', 'الحفلة.'] },
    { 'en': ['Who', 'teaches', 'you', 'French?'], 'ar': ['من', 'يدرّسك', 'الفرنسية؟'] },
    { 'en': ["It's", 'not', 'so', 'far.'], 'ar': ['ليس', 'بعيداً.'] },
    { 'en': ['She', 'is', 'two', 'years', 'older', 'than', 'you.'], 'ar': ['إنها', 'أكبر', 'مني', 'بسنتين.'] },
    { 'en': ['None', 'of', 'my', 'friends', 'drink', 'coffee.'], 'ar': ['كل', 'أصحابي', 'لا', 'يشربون', 'القهوة.'] },
    { 'en': ['Where', 'is', 'the', 'elevator?'], 'ar': ['أين', 'المصعد؟'] },
    { 'en': ['We', 'appreciate', 'everything', 'you', 'have', 'done.'], 'ar': ['نقدر', 'كل', 'ما', 'عملته.'] },
    { 'en': ['You', 'should', 'listen', 'to', 'what', 'Tom', 'says.'], 'ar': ['عليك', 'أن', 'تصغي', 'لما', 'يقوله', 'توم.'] },
    { 'en': ['How', 'many', 'people', 'did', 'you', 'see', 'in', 'the', 'park?'], 'ar': ['كم', 'شخصًا', 'رأيت', 'في', 'المنتزه؟'] },
    { 'en': ['This', 'is', 'a', 'dog.'], 'ar': ['هذا', 'كلب.'] },
    { 'en': ['Where', 'is', "Tom's", 'classroom?'], 'ar': ['أين', 'فصل', 'توم؟'] },
    { 'en': ['Where', 'does', 'your', 'uncle', 'live?'], 'ar': ['أين', 'يسكن', 'خالك؟'] },
    { 'en': ['Why', 'is', 'Tom', 'not', 'here?'], 'ar': ['لماذا', 'توم', 'ليس', 'هنا؟'] },
    { 'en': ['I', 'reckon', 'that', "I'm", 'right.'], 'ar': ['أحسَب', 'أني', 'مُحِق.'] },
    { 'en': ['All', 'the', 'phones', 'were', 'ringing.'], 'ar': ['جميع', 'الهواتف', 'كانت', 'ترنّ.'] },
    { 'en': ['I', 'was', 'thinking', 'of', 'getting', 'a', 'dog.'], 'ar': ['كنت', 'أفكر', 'في', 'الحصول', 'على', 'كلب.'] },
    { 'en': ['Can', 'I', 'speak', 'to', 'the', 'person', 'in', 'charge?'], 'ar': ['هل', 'يمكنني', 'التحدث', 'مع', 'المسؤول', 'هنا؟'] },
    { 'en': ['Do', 'you', 'think', 'Tom', 'saw', 'Mary?'], 'ar': ['أتظنّ', 'أنّ', 'توم', 'رأى', 'ماري؟'] },
    { 'en': ['I', 'was', 'busy', 'yesterday.'], 'ar': ['كنت', 'مشغولاً', 'البارحة.'] },
    { 'en': ['Tom', 'is', 'young', 'and', 'single.'], 'ar': ['توم', 'شاب', 'و', 'أعزب'] },
    { 'en': ['He', 'kicked', 'in', 'a', 'lot', 'of', 'money.'], 'ar': ['تبرّعَ', 'بالكثير', 'من', 'المال.'] },
    { 'en': ['The', 'hospital', 'is', 'crowded.'], 'ar': ['المستشفى', 'مزدحم.'] },
    { 'en': ['That', 'is', 'his', 'house.'], 'ar': ['هذا', 'هو', 'منزله.'] },
    { 'en': ['The', 'price', 'of', 'oil', 'is', 'down', 'this', 'week.'], 'ar': ['هبط', 'سعر', 'البترول', 'هذا', 'الأسبوع.'] },
    { 'en': ['Is', 'that', 'pure', 'gold?'], 'ar': ['هل', 'هذا', 'ذهب', 'خالص؟'] },
    { 'en': ['She', 'is', 'a', 'noted', 'singer.'], 'ar': ['هي', 'مغنية', 'مشهورة.'] },
    { 'en': ['A', 'lot', 'of', 'tourists', 'invaded', 'the', 'island.'], 'ar': ['غزى', 'الكثير', 'من', 'السياح', 'الجزيرة.'] },
    { 'en': ['You', 'should', 'always', 'keep', 'your', 'word.'], 'ar': ['عليك', 'دائماً', 'أن', 'تفي', 'بوعدك.'] },
    { 'en': ['Correct', 'the', 'mistakes,', 'if', 'there', 'are', 'any.'], 'ar': ['صحح', 'الأخطاء', '،', 'إن', 'وُجدت.'] },
    { 'en': ['He', 'hurt', 'his', 'left', 'hand', 'with', 'a', 'knife.'], 'ar': ['جرح', 'يده', 'اليسرى', 'بسكين.'] },
    { 'en': ['Why', 'is', 'Tom', 'being', 'so', 'nice', 'to', 'us?'], 'ar': ['لماذا', 'يعاملنا', 'توم', 'بلطف', 'بالغ؟'] },
    { 'en': ["I'm", 'going', 'to', 'change', 'all', 'that.'], 'ar': ['سأغيّر', 'هذا', 'كله.'] },
    { 'en': ['You', 'must', 'give', 'up', 'smoking.'], 'ar': ['عليك', 'أن', 'تقلع', 'عن', 'التدخين.'] },
    { 'en': ['Will', 'you', 'give', 'me', 'some', 'time?'], 'ar': ['هلا', 'منحتني', 'بعض', 'الوقت؟'] },
    { 'en': ['You', 'keep', 'out', 'of', 'this.'], 'ar': ['ابق', 'خارج', 'هذا', 'الموضوع.'] },
    { 'en': ['I', "don't", 'agree', 'with', 'you.'], 'ar': ['لا', 'اتفق', 'معك.'] },
    { 'en': ['We', 'made', 'him', 'go.'], 'ar': ['جعلناه', 'يذهب.'] },
    { 'en': ["Let's", 'ponder', 'that', 'for', 'a', 'moment.'], 'ar': ['دعونا', 'نفكر', 'بذلك', 'للحظة'] },
    { 'en': ['Can', 'I', 'have', 'your', 'full', 'name?'], 'ar': ['هلّا', 'أعطيتني', 'اسمك', 'الكامل؟'] },
    { 'en': ['We', 'are', 'coming.'], 'ar': ['نحن', 'آتون.'] },
    { 'en': ["Where's", 'the', 'whipped', 'cream?'], 'ar': ['أين', 'الكريمة', 'المخفوقة؟'] },
    { 'en': ['Would', 'you', 'tell', 'me', "what's", 'going', 'on', 'here?'], 'ar': ['أيمكنك', 'إخباري', 'بما', 'يحدث', 'هنا؟'] },
    { 'en': ['My', 'son', 'is', 'now', 'as', 'tall', 'as', 'I', 'am.'], 'ar': ['إبني', 'بنفس', 'طولي', 'حالياً.'] },
    { 'en': ['It', 'is', 'not', 'necessarily', 'so.'], 'ar': ['ليس', 'بالضرورة.'] },
    { 'en': ['Could', 'you', 'please', 'repeat', 'what', 'you', 'just', 'said?'], 'ar': ['هل', 'يمكنك', 'أن', 'تكرر', 'ما', 'قلته', 'للتو؟'] },
    { 'en': ["It's", 'my', 'job.'], 'ar': ['هذا', 'واجبي.'] },
    { 'en': ['I', 'do', 'hope', 'so.'], 'ar': ['آمُلُ', 'ذلك.'] },
    { 'en': ['I', 'was', 'too', 'happy', 'to', 'sleep.'], 'ar': ['لقد', 'كنت', 'مسرورا', 'جدا', 'لكى', 'أنام.'] },
    { 'en': ['Be', 'polite', 'to', 'your', 'parents.'], 'ar': ['كن', 'مؤدباً', 'مع', 'والديك.'] },
    { 'en': ['This', 'is', 'a', 'list', 'of', 'what', 'we', 'need.'], 'ar': ['هذا', 'قائمة', 'ما', 'نحتاج.'] },
    { 'en': ['I', 'met', 'a', 'dog', 'on', 'my', 'way', 'home.'], 'ar': ['مررت', 'بكلب', 'في', 'طريقي', 'إلى', 'البيت.'] },
    { 'en': ['Wait', 'for', 'me', 'in', 'my', 'office.'], 'ar': ['انتظرني', 'في', 'مكتبي.'] },
    { 'en': ['Look', 'at', 'him.'], 'ar': ['انظر', 'إليه'] },
    { 'en': ['Have', 'any', 'letters', 'arrived', 'for', 'me?'], 'ar': ['هل', 'وصلت', 'أي', 'خطابات', 'لي؟'] },
    { 'en': ['None', 'of', 'that', 'actually', 'happened.'], 'ar': ['لا', 'شيء', 'من', 'هذا', 'حدث.'] },
    { 'en': ["I've", 'got', 'a', 'better', 'idea.'], 'ar': ['لديَ', 'فكرة', 'أفضل.'] },
    { 'en': ['I', 'caused', 'him', 'a', 'lot', 'of', 'trouble.'], 'ar': ['لقد', 'سببت', 'له', 'الكثير', 'من', 'المتاعب.'] },
    { 'en': ['Your', 'time', 'is', 'up.'], 'ar': ['إنتهى', 'الوقت', 'المخصص', 'لك.'] },
    { 'en': ['That', 'dog', 'is', 'big.'], 'ar': ['ذلك', 'الكلب', 'ضخم.'] },
    { 'en': ["He's", 'smart.'], 'ar': ['هو', 'ذكي.'] },
    { 'en': ['They', 'are', 'demanding', 'shorter', 'working', 'hours.'], 'ar': ['هم', 'يطالبون', 'بساعات', 'عمل', 'أقل.'] },
    { 'en': ['We', 'make', 'milk', 'into', 'cheese', 'and', 'butter.'], 'ar': ['نصنع', 'الزبدة', 'و', 'الجبنة', 'من', 'الحليب.'] },
    { 'en': ['I', 'would', 'like', 'to', 'see', 'it.'], 'ar': ['سيسعدني', 'رؤية', 'ذلك.'] },
    { 'en': ['He', 'tried', 'writing', 'a', 'short', 'story.'], 'ar': ['حاول', 'أن', 'يكتب', 'أقصوصة.'] },
    { 'en': ['This', 'is', 'a', 'good', 'question.'], 'ar': ['هذا', 'سؤال', 'جيد.'] },
    { 'en': ["I'll", 'buy', 'a', 'watch', 'for', 'my', 'son.'], 'ar': ['سأشتري', 'ساعةً', 'لابني.'] },
    { 'en': ['This', 'car', 'is', 'like', 'new.'], 'ar': ['هذه', 'السيارة', 'تبدو', 'كالجديدة'] },
    { 'en': ["You'll", 'never', 'be', 'alone.'], 'ar': ['لن', 'تصير', 'وحيداً', 'أبداً', '.'] },
    { 'en': ['What', 'are', 'you', 'looking', 'for?'], 'ar': ['علامَ', 'تبحث؟'] },
    { 'en': ['I', 'tried', 'not', 'to', 'look', 'disappointed.'], 'ar': ['حاولت', 'أن', 'لا', 'أظهر', 'بأنني', 'خائب', 'الأمل.'] },
    { 'en': ['I', "don't", 'want', 'a', 'full-time', 'job.'], 'ar': ['لا', 'أريد', 'وظيفة', 'بدوام', 'كامل.'] },
    { 'en': ['You', 'had', 'to', 'study', 'English.'], 'ar': ['كان', 'عليك', 'أن', 'تدرس', 'الإنجليزية.'] },
    { 'en': ['Who', 'will', 'take', 'care', 'of', 'the', 'baby?'], 'ar': ['من', 'سيعتني', 'بالطفل', '؟'] },
    { 'en': ["We're", 'in', 'mourning.'], 'ar': ['نحن', 'في', 'حداد.'] },
    { 'en': ['Tom', "doesn't", 'want', 'me', 'here,', 'does', 'he?'], 'ar': ['توم', 'لا', 'يريدني', 'هنا.', 'أليس', 'كذلك؟'] },
    { 'en': ['Instead', 'of', 'going', 'myself,', 'I', 'sent', 'a', 'letter.'], 'ar': ['بعثت', 'برسالة', 'بدلاً', 'من', 'الذهاب', 'بنفسي.'] },
    { 'en': ['You', 'can', 'ask', 'him', 'for', 'help.'], 'ar': ['بإمكانك', 'أن', 'تطلب', 'منه', 'المساعدة.'] },
    { 'en': ['Why', 'do', 'I', 'even', 'care?'], 'ar': ['لِمَ', 'أُتْعِبُ', 'نفسي.'] },
    { 'en': ['He', 'recovered', 'his', 'stolen', 'wallet.'], 'ar': ['لقد', 'استعاد', 'محفظته', 'المسروقة.'] },
    { 'en': ['At', 'least', 'let', 'me', 'help', 'set', 'the', 'table.'], 'ar': ['على', 'الأقل', 'دعني', 'أساعد', 'في', 'ترتيب', 'الطاولة.'] },
    { 'en': ['None', 'of', 'us', 'speak', 'French.'], 'ar': ['لا', 'أحد', 'منا', 'يتحدث', 'اللغة', 'الفرنسية.'] },
    { 'en': ['The', 'same', 'thing', 'happened', 'Monday.'], 'ar': ['حدث', 'الأمر', 'ذاته', 'يوم', 'الإثنين.'] },
    { 'en': ['I', 'have', 'never', 'seen', 'a', 'red', 'refrigerator.'], 'ar': ['لم', 'أرَ', 'براداً', 'أحمر', 'من', 'قبل.'] },
    { 'en': ['He', 'continued', 'walking', 'for', 'a', 'while.'], 'ar': ['واصل', 'المشي', 'لفترة.'] },
    { 'en': ['I', 'wish', 'I', 'could', 'go', 'to', 'Japan.'], 'ar': ['أتمنّى', 'الذهاب', 'إلى', 'اليابان.'] },
    { 'en': ['I', 'really', "wasn't", 'expecting', 'that', 'from', 'you.'], 'ar': ['لم', 'أكن', 'أتوقع', 'هذا', 'منك', 'حقا.'] },
    { 'en': ['She', 'looks', 'as', 'if', 'she', 'were', 'drunk.'], 'ar': ['تبدو', 'كأنها', 'سكرانة.'] },
    { 'en': ['Tom', 'walks', 'slowly.'], 'ar': ['توم', 'يمشي', 'ببطئ.'] },
    { 'en': ['The', 'cake', 'is', 'still', 'in', 'the', 'oven.'], 'ar': ['ما', 'زالت', 'الكعكة', 'في', 'الفرن.'] },
    { 'en': ["I'm", 'one', 'of', 'your', 'students.'], 'ar': ['انا', 'واحد', 'من', 'طلابك'] },
    { 'en': ['You', "can't", 'buy', 'happiness.'], 'ar': ['لا', 'يمكنك', 'شراء', 'السعادة.'] },
    { 'en': ["I've", 'got', 'a', 'reservation.'], 'ar': ['لدي', 'حجز.'] },
    { 'en': ['He', 'is', 'nice.'], 'ar': ['إنه', 'لطيف.'] },
    { 'en': ["I've", 'got', 'to', 'speak', 'to', 'you.'], 'ar': ['أنا', 'تحدثت', 'معك.'] },
    { 'en': ['Put', 'on', 'your', 'cap.'], 'ar': ['البس', 'قبّعتك.'] },
    { 'en': ["I'm", 'not', 'sure', 'what', "I'm", 'looking', 'at.'], 'ar': ['لست', 'متأكدا', 'على', 'ماذا', 'أنظر.'] },
    { 'en': ['She', 'came', 'to', 'see', 'me.'], 'ar': ['أتت', 'لزيارتي.'] },
    { 'en': ['Have', 'you', 'done', 'your', 'homework?'], 'ar': ['هل', 'أنهيت', 'واجباتك؟'] },
    { 'en': ['There', 'are', 'sixty', 'seconds', 'in', 'a', 'minute.'], 'ar': ['هناك', 'ستون', 'ثانية', 'في', 'الدقيقة.'] },
    { 'en': ['They', 'hate', 'you.'], 'ar': ['إنهم', 'يكرهونك.'] },
    { 'en': ['A', 'glass', 'of', 'water,', 'please.'], 'ar': ['قارورة', 'مياه', '،', 'من', 'فضلك.'] },
    { 'en': ['He', 'painted', 'his', 'bicycle', 'red.'], 'ar': ['طلى', 'دراجته', 'بالأحمر.'] },
    { 'en': ['The', 'old', 'man', 'fell', 'down', 'on', 'the', 'ground.'], 'ar': ['وقع', 'الرجل', 'العجوز', 'على', 'الأرض.'] },
    { 'en': ['Is', 'the', 'snake', 'alive', 'or', 'dead?'], 'ar': ['هل', 'الثعبان', 'حي', 'أم', 'ميت؟'] },
    { 'en': ['You', "don't", 'know', 'the', 'whole', 'story.'], 'ar': ['أنت', 'لا', 'تعلم', 'بالقصة', 'كاملة.'] },
    { 'en': ['Take', 'these', 'pills.'], 'ar': ['تناول', 'هذه', 'الأقراص.'] },
    { 'en': ['I', 'was', 'taken', 'in', 'by', 'the', 'salesman.'], 'ar': ['خُدِعتُ', 'من', 'قبل', 'البائع.'] },
    { 'en': ['English', 'is', 'difficult,', "isn't", 'it?'], 'ar': ['الإنجليزية', 'لغة', 'صعبة', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['You', 'must', 'do', 'your', 'duty.'], 'ar': ['يجب', 'عليك', 'تأدية', 'واجباتك.'] },
    { 'en': ['Do', 'you', 'know', 'anyone', 'in', 'Australia?'], 'ar': ['أتعرف', 'أحدًا', 'في', 'أستراليا؟'] },
    { 'en': ["We're", 'very', 'sorry.'], 'ar': ['نحن', 'آسفون', 'جداً'] },
    { 'en': ['Tell', 'me', 'what', "you're", 'thinking.'], 'ar': ['أخبرني', 'بماذا', 'تفكر.'] },
    { 'en': ['Luck', 'is', 'blind.'], 'ar': ['الحظ', 'أعمى.'] },
    { 'en': ["I'll", 'have', 'to', 'think', 'it', 'over.'], 'ar': ['يجب', 'ان', 'أفكر', 'بالأمر'] },
    { 'en': ['You', 'must', 'realize', 'that', 'I', "can't", 'help', 'you.'], 'ar': ['يجب', 'أن', 'تدرك', 'بأننا', 'لن', 'نستطيع', 'تقديم', 'يد', 'العون.'] },
    { 'en': ["You're", 'always', 'criticizing', 'me!'], 'ar': ['إنك', 'تعيبني', 'دائماً.'] },
    { 'en': ['Our', 'company', 'makes', 'use', 'of', 'the', 'Internet.'], 'ar': ['تستفيد', 'شركتنا', 'من', 'الإنترنت.'] },
    { 'en': ['They', 'died', 'one', 'after', 'another.'], 'ar': ['ماتوا', 'واحداً', 'تلو', 'الآخر.'] },
    { 'en': ['What', 'time', 'does', 'the', 'movie', 'start?'], 'ar': ['متى', 'يبدأ', 'الفلم؟'] },
    { 'en': ['Tom', 'has', 'a', 'poor', 'memory.'], 'ar': ['توم', 'له', 'ذاكرة', 'ضعيفة.'] },
    { 'en': ["I'm", 'going', 'to', 'take', 'my', 'car.'], 'ar': ['سآخذ', 'سيّارتي.'] },
    { 'en': ['She', 'has', 'a', 'lot', 'of', 'books.'], 'ar': ['لديها', 'الكثير', 'من', 'الكتب.'] },
    { 'en': ["I'm", 'OK.'], 'ar': ['أنا', 'بخير.'] },
    { 'en': ['We', 'encourage', 'your', 'participation.'], 'ar': ['إننا', 'نشجع', 'مساهمتكم.'] },
    { 'en': ['I', 'missed', 'the', 'bus.'], 'ar': ['لقد', 'فاتتني', 'الحافلة'] },
    { 'en': ['Did', 'you', 'see', 'how', 'he', 'looked', 'at', 'me?'], 'ar': ['هل', 'لاحظت', 'كيف', 'كان', 'ينظر', 'إليّ؟'] },
    { 'en': ['Make', 'your', 'bed.'], 'ar': ['رتب', 'سريرك.'] },
    { 'en': ['Can', 'you', 'swim?'], 'ar': ['هل', 'بإمكانك', 'السباحة؟'] },
    { 'en': ['I', 'want', 'to', 'have', 'a', 'cup', 'of', 'coffee.'], 'ar': ['أريد', 'أن', 'أشرب', 'فنجاناً', 'من', 'القهوة.'] },
    { 'en': ['I', 'went', 'to', 'the', 'coffee', 'shop', 'yesterday.'], 'ar': ['ذهبت', 'إلى', 'المقهى', 'بالأمس.'] },
    { 'en': ['A', 'pelican', 'can', 'fit', 'a', 'lot', 'of', 'fish', 'in', 'its', 'beak.'], 'ar': ['يمكن', 'للبجعة', 'أن', 'تستوعب', 'الكثير', 'من', 'الأسماك', 'في', 'منقارها.'] },
    { 'en': ['My', 'friend', 'helped', 'me.'], 'ar': ['صديقي', 'ساعدني.'] },
    { 'en': ['It', 'was', 'a', 'one', 'hundred', 'dollar', 'bill.'], 'ar': ['إنها', 'ورقة', 'نقدية', 'من', 'فئة', 'المئة', 'دولار.'] },
    { 'en': ["We'll", 'have', 'plenty', 'of', 'food.'], 'ar': ['سوف', 'يكون', 'عندنا', 'الكثير', 'من', 'الطعام.'] },
    { 'en': ['Please', 'leave', 'me', 'alone.'], 'ar': ['من', 'فضلك', 'اتركني', 'وحدي.'] },
    { 'en': ['He', 'was', 'so', 'mad', 'that', 'he', 'forgot', 'to', 'eat', 'dinner.'], 'ar': ['كان', 'غاضباً', 'لدرجة', 'أنّه', 'نسي', 'أن', 'يتناول', 'طعام', 'العشاء.'] },
    { 'en': ['Kyoto', 'depends', 'on', 'the', 'tourist', 'industry.'], 'ar': ['كيوتو', 'تعتمد', 'على', 'السياحة', '.'] },
    { 'en': ['My', 'hobby', 'is', 'collecting', 'old', 'toys.'], 'ar': ['هوايتي', 'جمع', 'اللعب', 'القديمة.'] },
    { 'en': ['Congratulations!'], 'ar': ['مبروك.'] },
    { 'en': ['Is', 'the', 'bank', 'far', 'from', 'here?'], 'ar': ['هل', 'البنك', 'بعيد', 'عن', 'هنا؟'] },
    { 'en': ['That', 'was', 'what', 'Tom', 'told', 'Mary.'], 'ar': ['هذا', 'ما', 'قاله', 'توم', 'لماري.'] },
    { 'en': ['Could', 'you', 'do', 'me', 'a', 'favor?'], 'ar': ['هل', 'لك', 'أن', 'تسدي', 'إلي', 'معروفاً؟'] },
    { 'en': ['I', 'just', 'saw', 'an', 'opossum.'], 'ar': ['لقد', 'رأيت', 'للتو', 'حيوان', 'الأبوسوم.'] },
    { 'en': ['Where', 'are', 'you', 'from?'], 'ar': ['من', 'أي', 'بلد', 'أنت؟'] },
    { 'en': ['Turn', 'off', 'the', 'TV.'], 'ar': ['أطفأ', 'التلفاز.'] },
    { 'en': ['What', 'a', 'beautiful', 'garden!'], 'ar': ['يا', 'لها', 'من', 'حديقة', 'جميلة.'] },
    { 'en': ['There', 'were', 'no', 'roses', 'in', 'the', 'garden.'], 'ar': ['الحديقة', 'كانت', 'خالية', 'من', 'الورود.'] },
    { 'en': ['He', 'is', 'just', 'now', 'speaking', 'with', 'her.'], 'ar': ['إنه', 'يتحدث', 'الأن', 'معها.'] },
    { 'en': ['What', 'do', 'you', 'like?'], 'ar': ['ماذا', 'تحب؟'] },
    { 'en': ['Why', 'did', 'you', 'turn', 'down', 'his', 'offer?'], 'ar': ['لماذا', 'رفضت', 'عرضه؟'] },
    { 'en': ['I', 'called', 'him', 'up', 'yesterday.'], 'ar': ['اتصلت', 'به', 'بالأمس.'] },
    { 'en': ['Who', 'is', 'your', 'teacher?'], 'ar': ['من', 'مدرسك؟'] },
    { 'en': ['I', "don't", 'speak', 'Japanese.'], 'ar': ['لا', 'يمكنني', 'تحدث', 'اليابانية.'] },
    { 'en': ["I'll", 'teach', 'you', 'how', 'to', 'drive', 'a', 'car.'], 'ar': ['سأعلمك', 'القيادة.'] },
    { 'en': ['Tom', 'converted', 'his', 'bedroom', 'into', 'an', 'office.'], 'ar': ['حوّل', 'توم', 'غرفة', 'نومه', 'إلى', 'مكتب.'] },
    { 'en': ['She', 'has', 'sunglasses.'], 'ar': ['عندها', 'نظارة', 'شمسية.'] },
    { 'en': ['She', 'hopes', 'to', 'become', 'a', 'designer.'], 'ar': ['إنها', 'تأمل', 'أن', 'تصبح', 'مصممة', 'أزياء.'] },
    { 'en': ['I', 'go', 'to', 'school.'], 'ar': ['أذهب', 'إلى', 'المدرسة.'] },
    { 'en': ['Can', 'I', 'speak', 'to', 'the', 'person', 'in', 'charge?'], 'ar': ['هل', 'لي', 'أن', 'أكلم', 'المسؤول', 'هنا؟'] },
    { 'en': ['He', 'got', 'to', 'London', 'yesterday.'], 'ar': ['وصل', 'إلى', 'لندن', 'البارحة.'] },
    { 'en': ['I', 'have', 'to', 'get', 'up', 'anyways.'], 'ar': ['علي', 'أن', 'أنهض', 'على', 'كل', 'حال.'] },
    { 'en': ['Does', 'your', 'friend', 'like', 'tea?'], 'ar': ['هل', 'صاحبك', 'يحب', 'الشاي؟'] },
    { 'en': ['I', 'want', 'to', 'see', 'Tom', 'before', 'I', 'leave.'], 'ar': ['أريد', 'رؤية', 'توم', 'قبل', 'مغادرتي.'] },
    { 'en': ['You', "don't", 'know', 'what', 'you', 'are', 'doing,', 'do', 'you?'], 'ar': ['لا', 'تدري', 'ماذا', 'تفعل،', 'أليس', 'كذلك؟'] },
    { 'en': ['The', 'first', 'stage', 'is', 'complete.'], 'ar': ['اكتملت', 'المرحلة', 'الأولى.'] },
    { 'en': ['I', 'have', 'a', 'week', 'to', 'do', 'my', 'homework.'], 'ar': ['لدي', 'أسبوع', 'لأنهي', 'واجبي.'] },
    { 'en': ['What', 'should', 'we', 'say?'], 'ar': ['ماذا', 'يجب', 'أن', 'نقول؟'] },
    { 'en': ['Why', "don't", 'we', 'just', 'agree', 'to', 'disagree?'], 'ar': ['لمَ', 'لا', 'نتّفق', 'على', 'ألّا', 'نتّفق', 'فحسب؟'] },
    { 'en': ['I', 'left', 'the', 'rest', 'to', 'him', 'and', 'went', 'out.'], 'ar': ['تركت', 'الباقي', 'عليه', 'وخرجت.'] },
    { 'en': ['You', 'may', 'have', 'read', 'this', 'book', 'already.'], 'ar': ['ربما', 'قرأت', 'هذا', 'الكتاب', 'أصلًا.'] },
    { 'en': ['He', 'is', 'talking', 'on', 'the', 'phone.'], 'ar': ['إنهُ', 'يتحدث', 'على', 'الهاتف.'] },
    { 'en': ['Tom', 'broke', 'the', 'window.'], 'ar': ['كسر', 'توم', 'زجاج', 'النافذة.'] },
    { 'en': ["Let's", 'flip', 'a', 'coin.'], 'ar': ['دعونا', 'نقلب', 'العملة.'] },
    { 'en': ['You', 'have', 'to', 'forgive', 'me.'], 'ar': ['عليك', 'أن', 'تسامحني.'] },
    { 'en': ['The', 'budget', 'is', 'extremely', 'small.'], 'ar': ['الميزانية', 'قليلة', 'جدا.'] },
    { 'en': ['You', 'are', 'in', 'my', 'way.'], 'ar': ['أنت', 'تقف', 'في', 'طريقي.'] },
    { 'en': ['This', 'pond', 'has', 'a', 'lot', 'of', 'carp.'], 'ar': ['في', 'هذه', 'البركة', 'الكثير', 'من', 'سمك', 'الشبوط.'] },
    { 'en': ["That's", 'a', 'good', 'idea.'], 'ar': ['تلك', 'فكرة', 'جيدة.'] },
    { 'en': ['He', 'works', 'in', 'the', 'car', 'industry.'], 'ar': ['إنه', 'يعمل', 'في', 'مجال', 'صناعة', 'السيارات.'] },
    { 'en': ['I', "haven't", 'finished', 'my', 'homework', 'yet.'], 'ar': ['لم', 'أُنهِ', 'واجباتي', 'بعد.'] },
    { 'en': ['Where', 'is', 'the', 'mistake?'], 'ar': ['أين', 'الخطأ؟'] },
    { 'en': ['Today', 'is', 'your', 'birthday.'], 'ar': ['اليوم', 'هو', 'يوم', 'ميلادك'] },
    { 'en': ['Where', 'are', 'my', 'keys?'], 'ar': ['أين', 'هي', 'مفاتيحي؟'] },
    { 'en': ["That's", 'a', 'hotel.'], 'ar': ['ذاك', 'فندقٌ.'] },
    { 'en': ['Will', 'you', 'help', 'them?'], 'ar': ['أستساعدهم؟'] },
    { 'en': ['Tom', 'stole', 'money', 'from', 'his', 'friends.'], 'ar': ['سرق', 'توم', 'مالًا', 'من', 'أصدقائه.'] },
    { 'en': ['I', 'think', "I'll", 'ask', 'Mary', 'to', 'marry', 'me.'], 'ar': ['أظن', 'بأني', 'سأطلب', 'من', 'ماري', 'أن', 'تتزوجني.'] },
    { 'en': ['I', 'met', 'him', 'on', 'several', 'occasions.'], 'ar': ['قابلته', 'في', 'عدة', 'مناسبات.'] },
    { 'en': ['Where', 'will', 'we', 'eat?'], 'ar': ['أين', 'سنأكل؟'] },
    { 'en': ['I', 'thought', "you'd", 'like', 'it.'], 'ar': ['ظننتك', 'ستحبه.'] },
    { 'en': ['I', 'persuaded', 'him', 'to', 'give', 'up', 'the', 'idea.'], 'ar': ['أقنعته', 'بالتخلي', 'عن', 'الفكرة.'] },
    { 'en': ['Be', 'ruthless.'], 'ar': ['كُن', 'عديم', 'الرحمة.'] },
    { 'en': ["Let's", 'ask', 'her', 'when', 'she', 'gets', 'back', 'home.'], 'ar': ['فلنسألها', 'عندما', 'تعود', 'إلى', 'المنزل.'] },
    { 'en': ['This', 'is', 'shameful.'], 'ar': ['هذا', 'مخجل.'] },
    { 'en': ['I', 'am', 'at', 'home.'], 'ar': ['إني', 'في', 'المنزل.'] },
    { 'en': ["Let's", 'just', 'be', 'friends.'], 'ar': ['دعينا', 'فقط', 'نصبح', 'أصدقاء.'] },
    { 'en': ['She', 'patiently', 'waited', 'for', 'him.'], 'ar': ['انتظرته', 'صابرة.'] },
    { 'en': ["I'm", 'not', 'free.'], 'ar': ['أنا', 'مشغول.'] },
    { 'en': ['My', 'father', 'is', 'retiring', 'next', 'spring.'], 'ar': ['سيتقاعد', 'أبي', 'الربيع', 'المقبل.'] },
    { 'en': ['This', 'is', 'not', 'a', 'time', 'for', 'celebration.'], 'ar': ['هذا', 'ليس', 'وقت', 'الإحتفال.'] },
    { 'en': ['Most', 'people', "don't", 'drink', 'enough', 'water.'], 'ar': ['لا', 'يشرب', 'أغلب', 'الناس', 'ما', 'يكفيهم', 'من', 'المياه.'] },
    { 'en': ['I', 'thank', 'you', 'from', 'the', 'bottom', 'of', 'my', 'heart.'], 'ar': ['إني', 'شاكر', 'لك', 'من', 'أعماق', 'قلبي.'] },
    { 'en': ['He', 'likes', 'to', 'sing', 'in', 'the', 'bathtub.'], 'ar': ['يعجبه', 'أن', 'يغني', 'في', 'حوض', 'الاستحمام.'] },
    { 'en': ['I', 'wanted', 'Tom', 'to', 'be', 'happy.'], 'ar': ['أردت', 'أن', 'أسعد', 'توم.'] },
    { 'en': ['I', 'learned', 'a', 'lot', 'about', 'Greek', 'culture.'], 'ar': ['تعلمت', 'الكثير', 'عن', 'الحضارة', 'الإغريقية.'] },
    { 'en': ['Where', 'did', 'you', 'buy', 'that', 'dress?'], 'ar': ['من', 'أين', 'إشتريتِ', 'هذا', 'الثوب؟'] },
    { 'en': ['Everything', 'will', 'change.'], 'ar': ['كل', 'شيئ', 'سيتغير.'] },
    { 'en': ['The', 'match', 'ended', 'in', 'a', 'draw.'], 'ar': ['انتهت', 'المباراة', 'بالتعادل.'] },
    { 'en': ["I'm", 'depending', 'on', 'you.'], 'ar': ['إني', 'أعتمد', 'عليك.'] },
    { 'en': ['My', 'office', 'is', 'on', 'the', 'fifth', 'floor.'], 'ar': ['مكتبي', 'في', 'الطابق', 'الخامس.'] },
    { 'en': ['That', 'house', 'belongs', 'to', 'him.'], 'ar': ['ذلك', 'البيت', 'ملكه.'] },
    { 'en': ['Tom', 'loves', 'cooking.'], 'ar': ['آمل', 'أن', 'يسير', 'كل', 'شيء', 'على', 'ما', 'يرام'] },
    { 'en': ["Don't", 'fix', 'it', 'if', 'it', "ain't", 'broke.'], 'ar': ['لا', 'تُصلحهُ', 'إذا', 'لم', 'يكن', 'منكسراً.'] },
    { 'en': ['There', "don't", 'seem', 'to', 'be', 'any', 'problems.'], 'ar': ['لا', 'يبدو', 'بأن', 'هناك', 'أي', 'مشكلة.'] },
    { 'en': ['I', 'had', 'my', 'car', 'stolen', 'last', 'night.'], 'ar': ['سُرقت', 'سيارتي', 'ليلة', 'البارحة.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'he', 'is?'], 'ar': ['هل', 'تعرفه؟'] },
    { 'en': ["I'm", 'feeling', 'fit.'], 'ar': ['أشعر', 'أني', 'رشيق.'] },
    { 'en': ['We', 'must', 'do', 'away', 'with', 'violence.'], 'ar': ['يجب', 'علينا', 'أن', 'نتخلص', 'من', 'العنف', '.'] },
    { 'en': ['He', 'invited', 'me', 'to', 'his', 'house.'], 'ar': ['هو', 'دعاني', 'إلى', 'منزله.'] },
    { 'en': ["I'm", 'a', 'woman.'], 'ar': ['أنا', 'امرأةٌ.'] },
    { 'en': ['I', 'wonder', 'why', 'Tom', 'said', 'that.'], 'ar': ['أتساءل', 'لماذا', 'قال', 'توم', 'ذلك'] },
    { 'en': ['I', 'am', 'from', 'Russia.'], 'ar': ['أنا', 'من', 'روسيا.'] },
    { 'en': ["Here's", 'to', 'your', 'health!'], 'ar': ['ها', 'هي', 'بصحتك.'] },
    { 'en': ['That', 'music', 'always', 'reminded', 'me', 'of', 'you.'], 'ar': ['تلك', 'الموسيقى', 'تذكرني', 'دائماً', 'بك.'] },
    { 'en': ['I', 'wake', 'up', 'very', 'early.'], 'ar': ['أنا', 'أستيقظ', 'مبكراً', 'جداً.'] },
    { 'en': ['Why', 'do', 'you', 'ask?'], 'ar': ['لماذا', 'تسأل؟'] },
    { 'en': ["I'll", 'stay', 'here', 'a', 'whole', 'month.'], 'ar': ['سأبقى', 'هنا', 'لشهر', 'كامل.'] },
    { 'en': ['This', 'wall', 'is', 'painted', 'green.'], 'ar': ['هذا', 'الحائط', 'مطلي', 'باللون', 'الأخضر.'] },
    { 'en': ['The', 'story', 'reminded', 'me', 'of', 'my', 'father.'], 'ar': ['ذكّرتني', 'القصة', 'بأبي.'] },
    { 'en': ['Is', 'that', 'black', 'bag', 'yours?'], 'ar': ['هل', 'الحقيبة', 'السوداء', 'ملكك؟'] },
    { 'en': ['We', 'succeeded.'], 'ar': ['نجحنا.'] },
    { 'en': ["Let's", 'not', 'jump', 'the', 'gun.'], 'ar': ['دعنا', 'لا', 'نستبق', 'الأمور.'] },
    { 'en': ['Tom', 'is', 'big.'], 'ar': ['توم', 'كبير'] },
    { 'en': ['Tom', 'swims', 'very', 'fast.'], 'ar': ['توم', 'يسبح', 'بسرعة', 'كبيرة.'] },
    { 'en': ['You', 'have', 'to', 'go.'], 'ar': ['عليك', 'أن', 'تذهب.'] },
    { 'en': ['If', 'I', 'were', 'rich,', "I'd", 'give', 'you', 'money.'], 'ar': ['أنا', 'لو', 'كنت', 'غنياً,', 'كنت', 'أود', 'أن', 'أعطيكِ', 'مال.'] },
    { 'en': ['You', 'depend', 'too', 'much', 'on', 'others.'], 'ar': ['أنت', 'تعتمد', 'على', 'الآخرين', 'كثيرًا.'] },
    { 'en': ['I', 'am', 'to', 'go', 'there', 'today.'], 'ar': ['سأذهب', 'إلى', 'هناك', 'اليوم.'] },
    { 'en': ['Your', 'cake', 'is', 'delicious.'], 'ar': ['كعكتك', 'شهية.'] },
    { 'en': ['A', 'good', 'idea', 'crossed', 'his', 'mind.'], 'ar': ['خطر', 'له', 'فكرة', 'جيدة.'] },
    { 'en': ['The', 'book', 'is', 'easy.'], 'ar': ['إنّ', 'الكتابَ', 'سهلٌ.'] },
    { 'en': ["They're", 'all', 'tourists.'], 'ar': ['جميعهم', 'سياح', '.'] },
    { 'en': ['I', 'turned', 'off', 'the', 'radio.'], 'ar': ['أطفأت', 'المذياع.'] },
    { 'en': ['You', 'must', 'gather', 'further', 'information.'], 'ar': ['عليك', 'أن', 'تجمع', 'المزيد', 'من', 'المعلومات.'] },
    { 'en': ['Definitely!'], 'ar': ['قطعاً'] },
    { 'en': ['This', 'may', 'take', 'a', 'while.'], 'ar': ['قد', 'يستغرق', 'بعض', 'الوقت'] },
    { 'en': ['I', "can't", 'believe', 'my', 'eyes.'], 'ar': ['لا', 'أستطيع', 'تصديق', 'عينيّ.'] },
    { 'en': ['I', 'think', 'Tom', 'is', 'right.'], 'ar': ['أظن', 'أن', 'توم', 'على', 'حق.'] },
    { 'en': ['Did', 'you', 'miss', 'me?'], 'ar': ['هل', 'افتقدتني؟'] },
    { 'en': ['It', "can't", 'be', 'true.'], 'ar': ['لا', 'يمكن', 'أن', 'يكون', 'ذلك', 'صحيحاً.'] },
    { 'en': ['We', 'were', 'just', 'talking', 'about', 'you.'], 'ar': ['كنا', 'نتحدث', 'عنك', 'قبل', 'قليل.'] },
    { 'en': ['The', 'wounded', 'woman', 'was', 'a', 'Brazilian', 'tourist.'], 'ar': ['كانت', 'المرأة', 'الجريحة', 'سائحة', 'برازيلية', '.'] },
    { 'en': ['Tom', "isn't", 'as', 'tolerant', 'as', 'I', 'am.'], 'ar': ['توم', 'ليس', 'متسامحا', 'مثلي.'] },
    { 'en': ['Tom', "didn't", 'touch', 'anything.'], 'ar': ['توم', 'لم', 'يلمس', 'أي', 'شيء.'] },
    { 'en': ['I', 'decided', 'to', 'tell', 'her', 'that', 'I', 'love', 'her.'], 'ar': ['قررت', 'أن', 'أخبرها', 'بأني', 'أحبها.'] },
    { 'en': ["It's", 'not', 'that', 'hard.'], 'ar': ['ليس', 'بتلك', 'الصعوبة.'] },
    { 'en': ['Tom', 'hopes', "you're", 'able', 'to', 'attend', 'his', 'party.'], 'ar': ['يأمل', 'توم', 'بأن', 'تكون', 'قادراً', 'على', 'حضور', 'حفلته'] },
    { 'en': ['Where', 'is', 'the', 'laundry?'], 'ar': ['أين', 'الملابس', 'التي', 'تحتاج', 'إلى', 'غسل؟'] },
    { 'en': ['Tell', 'me', 'about', 'your', 'childhood.'], 'ar': ['أخبرني', 'عن', 'طفولتك.'] },
    { 'en': ["It's", 'ready', 'now.'], 'ar': ['إنها', 'جاهزة', 'الأن.'] },
    { 'en': ['This', 'house', 'is', 'mine.'], 'ar': ['هذا', 'بيتي.'] },
    { 'en': ['I', 'love', 'parties.'], 'ar': ['أحب', 'الحفلات.'] },
    { 'en': ['He', 'started', 'going', 'bald', 'quite', 'young.'], 'ar': ['أصابه', 'الصلع', 'في', 'شبابه.'] },
    { 'en': ['What', 'happened', 'last', 'week?'], 'ar': ['ماذا', 'حصل', 'الأسبوع', 'الماضي؟'] },
    { 'en': ['She', 'bought', 'two', 'pounds', 'of', 'butter.'], 'ar': ['اشترت', 'رطليْ', 'زبدة.'] },
    { 'en': ['I', 'want', 'my', 'mommy.'], 'ar': ['أريد', 'أمي.'] },
    { 'en': ['She', 'is', 'almost', 'as', 'tall', 'as', 'you.'], 'ar': ['إنها', 'بنفس', 'طولك', 'تقريباً.'] },
    { 'en': ['How', 'many', 'pens', 'do', 'you', 'have?'], 'ar': ['كم', 'قلماً', 'عندك؟'] },
    { 'en': ['Leave', 'my', 'camera', 'alone.'], 'ar': ['دع', 'كاميرتي', 'و', 'شأنها.'] },
    { 'en': ['He', 'is', 'very', 'talented.'], 'ar': ['هو', 'موهوب', 'جدا.'] },
    { 'en': ['Control', 'yourselves.'], 'ar': ['تَحَكَموا', 'بأنفُسَكُم.'] },
    { 'en': ['I', 'want', 'to', 'spend', 'more', 'time', 'alone', 'with', 'you.'], 'ar': ['أريد', 'أن', 'أقضي', 'وقتًا', 'أكثر', 'معك', 'وحدنا.'] },
    { 'en': ["We're", 'being', 'attacked.'], 'ar': ['لقد', 'هوجمنا!'] },
    { 'en': ['What', 'should', 'I', 'bring?'], 'ar': ['ماذا', 'عليّ', 'أن', 'أحضر؟'] },
    { 'en': ['Please', 'help', 'me', 'with', 'my', 'homework.'], 'ar': ['من', 'فضلك', 'ساعدني', 'في', 'حل', 'واجبي.'] },
    { 'en': ["It's", 'impossible.'], 'ar': ['إن', 'ذلك', 'مستحيل.'] },
    { 'en': ['Can', 'you', 'translate', 'English', 'into', 'Japanese?'], 'ar': ['أيمكنك', 'ترجمة', 'الإنجليزية', 'إلى', 'اليابانية؟'] },
    { 'en': ['Time', 'seemed', 'to', 'stop.'], 'ar': ['بدى', 'الأمر', 'وكأن', 'الزمن', 'توقف.'] },
    { 'en': ["Let's", 'take', 'a', 'look.'], 'ar': ['لنلقِ', 'نظرة.'] },
    { 'en': ['Can', 'I', 'take', 'a', 'break', 'now?'], 'ar': ['أيمكنني', 'أخذ', 'قسط', 'من', 'الراحة', 'الآن؟'] },
    { 'en': ['The', 'movie', 'starts', 'at', 'ten', "o'clock."], 'ar': ['يبدأ', 'عرض', 'الفلم', 'الساعة', 'العاشرة.'] },
    { 'en': ["Let's", 'sing', 'the', 'song', 'in', 'English.'], 'ar': ['لنغني', 'هذه', 'الأغنية', 'بالإنجليزية.'] },
    { 'en': ['Tom', 'lost', 'his', 'job.'], 'ar': ['خسر', 'توم', 'وظيفته.'] },
    { 'en': ['Ottawa', 'is', 'the', 'capital', 'of', 'Canada.'], 'ar': ['أتوا', 'هي', 'عاصمة', 'كندا.'] },
    { 'en': ['The', 'sand', 'on', 'the', 'beach', 'is', 'very', 'hot.'], 'ar': ['الرمل', 'على', 'الشاطىء', 'حار', 'جدا'] },
    { 'en': ['Tom', 'lives', 'in', 'a', 'nice', 'little', 'apartment.'], 'ar': ['يعيش', 'توم', 'في', 'شقة', 'صغيرة', 'لطيفة'] },
    { 'en': ['I', "didn't", 'expect', 'you', 'to', 'be', 'here.'], 'ar': ['لم', 'اتوقع', 'ان', 'تكون', 'هنا', '.'] },
    { 'en': ['I', 'understand.'], 'ar': ['أنا', 'أفهم'] },
    { 'en': ['How', 'long', 'is', 'this?'], 'ar': ['كم', 'طول', 'ذلك؟'] },
    { 'en': ['I', 'need', 'to', 'study.'], 'ar': ['أحتاج', 'إلى', 'أن', 'أدرس.'] },
    { 'en': ['Do', 'you', 'live', 'here?'], 'ar': ['هل', 'تسكن', 'هنا؟'] },
    { 'en': ['I', 'needed', 'to', 'make', 'sure', "you'd", 'come', 'here.'], 'ar': ['أردتُ', 'أن', 'أتأكد', 'أنك', 'ستأتي', 'إلى', 'هنا.'] },
    { 'en': ['They', "didn't", 'fool', 'us.'], 'ar': ['لم', 'يخدعونا'] },
    { 'en': ['He', 'speaks', 'Chinese', 'fluently.'], 'ar': ['إنه', 'يتكلم', 'الصينية', 'بطلاقة.'] },
    { 'en': ['Respect', 'yourself', 'and', 'you', 'will', 'be', 'respected.'], 'ar': ['احترم', 'نفسك', 'و', 'سيحترمك', 'الآخرون.'] },
    { 'en': ['Where', 'did', 'she', 'buy', 'books?'], 'ar': ['أين', 'اشترت', 'كتبا؟'] },
    { 'en': ['She', 'wore', 'a', 'red', 'dress.'], 'ar': ['لبست', 'فستاناً', 'أحمراً.'] },
    { 'en': ['My', 'brother', 'plays', 'guitar.'], 'ar': ['أخي', 'يعزف', 'على', 'الغيتار.'] },
    { 'en': ['You', 'are', 'a', 'good', 'customer.'], 'ar': ['أنت', 'مشتر', 'جيد.'] },
    { 'en': ['I', 'wrote', 'down', 'his', 'phone', 'number.'], 'ar': ['سجلت', 'رقم', 'هاتفه.'] },
    { 'en': ['They', "didn't", 'succeed.'], 'ar': ['لم', 'ينجحوا'] },
    { 'en': ["Where's", 'the', 'rest', 'of', 'our', 'class?'], 'ar': ['أين', 'بقية', 'صفنا؟'] },
    { 'en': ['Where', 'do', 'you', 'think', 'we', 'should', 'stay?'], 'ar': ['أين', 'تظن', 'أن', 'علينا', 'أن', 'نمكث؟'] },
    { 'en': ['Love', 'your', 'neighbors.'], 'ar': ['أحِبَّ', 'جيرانك.'] },
    { 'en': ['How', 'rude', 'of', 'you!'], 'ar': ['يا', 'لوقاحتك!'] },
    { 'en': ['Can', 'you', 'recommend', 'a', 'good', 'restaurant?'], 'ar': ['هل', 'يمكنك', 'أن', 'تنصحني', 'بمطعم', 'جيد؟'] },
    { 'en': ['What', 'do', 'you', 'call', 'this', 'flower?'], 'ar': ['ماذا', 'تُسَمّى', 'هذه', 'الوردة؟'] },
    { 'en': ['He', "couldn't", 'run', 'very', 'fast.'], 'ar': ['لم', 'يكن', 'بمقدوره', 'الركض', 'بسرعة', 'كبيرة.'] },
    { 'en': ['Last', 'weekend', 'was', 'boring', 'for', 'me.'], 'ar': ['نهاية', 'الإسبوع', 'الماضية', 'كانت', 'مملة', 'لي.'] },
    { 'en': ['Is', 'this', 'translation', 'correct?'], 'ar': ['هل', 'هذه', 'الترجمة', 'صحيحة؟'] },
    { 'en': ['She', 'despised', 'him.'], 'ar': ['احتقرته.'] },
    { 'en': ['I', 'have', 'no', 'knife', 'to', 'cut', 'with.'], 'ar': ['ليس', 'لدي', 'سكين', 'لأقطع', 'به.'] },
    { 'en': ['If', 'I', 'were', 'rich,', "I'd", 'give', 'you', 'money.'], 'ar': ['أنا', 'لو', 'كنت', 'غنياً,', 'كنت', 'أود', 'أن', 'أعطيكَ', 'مال.'] },
    { 'en': ['Last', 'summer', 'we', 'went', 'to', 'Hokkaido.'], 'ar': ['ذهبنا', 'إلى', 'هوكايدو', 'الصيف', 'الماضي.'] },
    { 'en': ['Playing', 'tennis', 'is', 'fun.'], 'ar': ['لعب', 'التنس', 'أمر', 'ممتع.'] },
    { 'en': ['Would', 'you', 'mind', 'shutting', 'the', 'door?'], 'ar': ['أيمكنك', 'أن', 'تقفل', 'الباب؟'] },
    { 'en': ['Get', 'out', 'of', 'bed!'], 'ar': ['انهض', 'من', 'سريرك!'] },
    { 'en': ['She', 'may', 'have', 'left', 'her', 'umbrella', 'on', 'the', 'bus.'], 'ar': ['ربّما', 'نسيت', 'المظلّة', 'في', 'الحافلة.'] },
    { 'en': ['Someone', 'is', 'calling', 'you.'], 'ar': ['هناك', 'شخصٌ', 'ما', 'يريدك', 'على', 'الهاتف.'] },
    { 'en': ["He's", 'as', 'strong', 'as', 'a', 'horse.'], 'ar': ['إنه', 'قوي', 'كالحصان.'] },
    { 'en': ['Come', 'with', 'us.'], 'ar': ['تعال', 'معنا.'] },
    { 'en': ['Plants', 'need', 'sunlight', 'to', 'grow.'], 'ar': ['تحتاج', 'النباتاتُ', 'ضوءَ', 'الشمس', 'لتنمو.'] },
    { 'en': ['After', 'you.'], 'ar': ['من', 'بعدك'] },
    { 'en': ['You', 'are', 'my', 'best', 'friend.'], 'ar': ['أنت', 'صديقي', 'المفضل.'] },
    { 'en': ['Do', 'you', 'speak', 'Swahili?'], 'ar': ['هل', 'تتحدث', 'السواحلية؟'] },
    { 'en': ['You', "don't", 'have', 'to', 'listen', 'to', 'what', 'he', 'says.'], 'ar': ['ليس', 'عليك', 'أن', 'تستمع', 'إلی', 'ما', 'يقول.'] },
    { 'en': ['I', 'will', 'stay', 'at', 'home.'], 'ar': ['سوف', 'أبقى', 'في', 'المنزل.'] },
    { 'en': ["I've", 'never', 'climbed', 'Mt.', 'Fuji.'], 'ar': ['لم', 'يسبق', 'لي', 'أن', 'تسلقت', 'جبل', 'فوجي.'] },
    { 'en': ['Money', 'was', 'tight.'], 'ar': ['كان', 'المال', 'قليلا.'] },
    { 'en': ['Please', 'repair', 'the', 'car.'], 'ar': ['من', 'فضلك', 'أصلح', 'السيارة.'] },
    { 'en': ['You', 'broke', 'the', 'rule.'], 'ar': ['لقد', 'خالفت', 'القوانين.'] },
    { 'en': ['Please', 'shut', 'the', 'door', 'behind', 'you.'], 'ar': ['من', 'فضلك', 'أغلق', 'الباب', 'خلفك.'] },
    { 'en': ['I', 'have', 'a', 'French', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جارة', 'فرنسية.'] },
    { 'en': ['I', 'love', 'to', 'read', 'books.'], 'ar': ['أحب', 'أن', 'أقرأ', 'الكتب'] },
    { 'en': ['Sit', 'down,', 'please.'], 'ar': ['تفضل', 'بالجلوس.'] },
    { 'en': ['The', 'king', 'abused', 'his', 'power.'], 'ar': ['أساء', 'الملك', 'استخدام', 'سلطته.'] },
    { 'en': ['This', 'is', 'the', 'flag', 'of', 'Japan.'], 'ar': ['هذا', 'علم', 'اليابان.'] },
    { 'en': ['Say', 'it', 'in', 'English.'], 'ar': ['قُل', 'ذلك', 'فى', 'الإنجليزية؟'] },
    { 'en': ['Have', 'you', 'finished', 'your', 'homework?'], 'ar': ['هل', 'أنهيت', 'واجباتك؟'] },
    { 'en': ['Lightning', 'normally', 'accompanies', 'thunder.'], 'ar': ['يصاحب', 'البرق', 'الرعد', 'عادة.'] },
    { 'en': ['You', 'must', 'endure', 'the', 'pain.'], 'ar': ['عليك', 'أن', 'تتحمل', 'الألم.'] },
    { 'en': ['What', 'did', 'you', 'major', 'in', 'at', 'college?'], 'ar': ['ما', 'الشهادة', 'التي', 'تخرجت', 'بها', 'من', 'الجامعة؟'] },
    { 'en': ['I', 'met', 'Tom', 'in', 'the', 'library', 'on', 'Monday.'], 'ar': ['قابلت', 'توم', 'في', 'المكتبة', 'يوم', 'الاثنين.'] },
    { 'en': ['Who', 'drew', 'it?'], 'ar': ['من', 'رسم', 'ذلك؟'] },
    { 'en': ['Madrid', 'is', 'the', 'capital', 'of', 'Spain.'], 'ar': ['عاصمة', 'أسبانيا', 'هي', 'مدريد.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'tennis', 'racket', 'today?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'مضربك', 'اليوم؟'] },
    { 'en': ['I', 'remember', 'everything', 'you', 'tell', 'me.'], 'ar': ['أتذكر', 'كل', 'ما', 'تقوله', 'لي.'] },
    { 'en': ["You're", 'such', 'a', 'chicken.'], 'ar': ['يالك', 'من', 'جبان.'] },
    { 'en': ['I', "haven't", 'had', 'dessert', 'yet.'], 'ar': ['لم', 'أتناول', 'حلويات', 'بعد'] },
    { 'en': ["I'd", 'like', 'to', 'ask', 'a', 'favor', 'of', 'you.'], 'ar': ['أود', 'أن', 'أطلب', 'منك', 'معروفا.'] },
    { 'en': ["I'm", 'very', 'happy', 'to', 'know', 'you.'], 'ar': ['أنا', 'سعيدٌ', 'بمعرفتك.'] },
    { 'en': ['Tom', 'has', 'no', 'choice', 'but', 'to', 'trust', 'us.'], 'ar': ['ليس', 'لدى', 'توم', 'خيار', 'إلا', 'أن', 'يثق', 'بنا.'] },
    { 'en': ['I', 'admit', 'that', 'I', 'was', 'wrong.'], 'ar': ['أعترف', 'أني', 'أخطأت.'] },
    { 'en': ['The', 'station', 'is', 'not', 'far', 'from', 'here.'], 'ar': ['المحطة', 'ليست', 'بعيدة', 'عن', 'هنا.'] },
    { 'en': ['Tom', 'is', 'no', 'angel.'], 'ar': ['توم', 'ليس', 'ملاكاً'] },
    { 'en': ["I'll", 'take', 'him.'], 'ar': ['سآخذه', 'معي.'] },
    { 'en': ['These', 'shoes', 'are', 'hers.'], 'ar': ['ذلك', 'حذاءها.'] },
    { 'en': ["It's", 'raining.'], 'ar': ['إنها', 'تمطر.'] },
    { 'en': ["I'm", 'very', 'proud', 'of', 'my', 'father.'], 'ar': ['أنا', 'فخورة', 'جدا', 'بأبي.'] },
    { 'en': ['What', 'do', 'you', 'think?'], 'ar': ['ما', 'رأيكم؟'] },
    { 'en': ['When', 'did', 'you', 'get', 'up', 'this', 'morning?'], 'ar': ['متى', 'استيقظت', 'هذا', 'الصباح؟'] },
    { 'en': ['Tom', 'is', 'reading', 'the', 'Bible.'], 'ar': ['توم', 'يقرأ', 'الإنجيل.'] },
    { 'en': ['Have', 'you', 'turned', 'in', 'your', 'report?'], 'ar': ['هل', 'سلّمت', 'تقريرك؟'] },
    { 'en': ['Call', 'me', 'as', 'soon', 'as', 'you', 'find', 'Tom.'], 'ar': ['اتّصل', 'بي', 'حالما', 'تجد', 'توم.'] },
    { 'en': ['You', 'should', 'work', 'hard.'], 'ar': ['عليك', 'أن', 'تعمل', 'بجدّ.'] },
    { 'en': ['The', 'library', 'is', 'on', 'the', '4th', 'floor.'], 'ar': ['المكتبة', 'في', 'الطابق', 'الرابع.'] },
    { 'en': ['The', 'man', 'spoke', 'in', 'a', 'low', 'voice.'], 'ar': ['تحدث', 'الرجل', 'بصوت', 'منخفض.'] },
    { 'en': ['He', 'tried', 'to', 'get', 'me', 'to', 'help', 'him.'], 'ar': ['حاولت', 'أن', 'أجعله', 'يساعدني.'] },
    { 'en': ['All', 'of', 'a', 'sudden,', 'a', 'dog', 'began', 'barking.'], 'ar': ['فجأةً', 'بدأ', 'كلبٌ', 'بالنباح.'] },
    { 'en': ['I', 'hope', 'that', 'all', 'your', 'dreams', 'come', 'true.'], 'ar': ['آمل', 'أن', 'تتحقّق', 'كلّ', 'أحلامك.'] },
    { 'en': ['Who', 'are', 'you?'], 'ar': ['من', 'أنت؟'] },
    { 'en': ['I', "don't", 'care.'], 'ar': ['الأمر', 'لا', 'يعنيني.'] },
    { 'en': ['Come', 'on.', "Let's", 'go', 'home.'], 'ar': ['هيا.', 'دعينا', 'نذهب', 'إلى', 'البيت.'] },
    { 'en': ['Please', 'let', 'me', 'go.'], 'ar': ['من', 'فضلك', 'دعني', 'أذهب.'] },
    { 'en': ['She', 'cooked', 'us', 'a', 'wonderful', 'meal.'], 'ar': ['أعدت', 'لنا', 'وجبة', 'رائعة.'] },
    { 'en': ['My', 'name', "doesn't", 'appear', 'on', 'the', 'list.'], 'ar': ['اسمي', 'لا', 'يبدو', 'في', 'القائمة.'] },
    { 'en': ['What', 'are', 'you', 'doing?'], 'ar': ['ماذا', 'تفعل؟'] },
    { 'en': ['"How', 'old', 'are', 'you?"', '"I\'m', '16', 'years', 'old."'], 'ar': ['"كم', 'عمرك؟"', '"ستة', 'عشر', 'عاماً"'] },
    { 'en': ['I', 'can', 'swim.'], 'ar': ['يمكنني', 'السباحة.'] },
    { 'en': ['Be', 'prepared.'], 'ar': ['كونوا', 'مستعدين'] },
    { 'en': ['How', 'strange', 'life', 'is!'], 'ar': ['ما', 'أغرب', 'الحياة!'] },
    { 'en': ['The', 'boys', 'kept', 'quiet.'], 'ar': ['بقي', 'الأولاد', 'هادئين.'] },
    { 'en': ['A', 'girl', 'phoned', 'me.'], 'ar': ['اتصلت', 'بي', 'فتاة.'] },
    { 'en': ['Who', 'painted', 'this', 'painting?'], 'ar': ['من', 'رسم', 'هذه', 'اللوحة؟'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Kyoto.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'كيوتو.'] },
    { 'en': ['Tom', 'wanted', 'to', 'have', 'dinner', 'in', 'bed.'], 'ar': ['توم', 'أراد', 'العَشَاء', 'في', 'السرير.'] },
    { 'en': ["I'm", '19.'], 'ar': ['أنا', 'في', '19'] },
    { 'en': ["You'd", 'better', 'go', 'by', 'bus.'], 'ar': ['الأفضل', 'أن', 'تذهب', 'بالحافلة.'] },
    { 'en': ['Have', 'you', 'ever', 'been', 'to', 'America?'], 'ar': ['هل', 'زرت', 'أمريكا', 'من', 'قبل؟'] },
    { 'en': ['Where', 'is', 'the', 'laundry?'], 'ar': ['أين', 'الغسيل؟'] },
    { 'en': ['I', 'saw', 'her', 'enter', 'the', 'room.'], 'ar': ['رأيتها', 'تدخل', 'الغرفة.'] },
    { 'en': ['I', 'can', 'do', 'it', 'all', 'myself.'], 'ar': ['أستطيع', 'فعلها', 'بنفسي.'] },
    { 'en': ["It's", 'doable,', 'but', 'not', 'easy.'], 'ar': ['يمكن', 'عمله،', 'لكنه', 'ليس', 'سهلا.'] },
    { 'en': ['This', 'theory', 'consists', 'of', 'three', 'parts.'], 'ar': ['تتألف', 'هذه', 'النظرية', 'من', 'ثلاثة', 'أجزاء.'] },
    { 'en': ["I'm", 'hungry!'], 'ar': ['أشعر', 'بالجوع.'] },
    { 'en': ['I', 'returned', 'his', 'book', 'to', 'him.'], 'ar': ['أعدت', 'إليه', 'كتابه.'] },
    { 'en': ['Why', 'did', 'you', 'come', 'to', 'Japan?'], 'ar': ['لماذا', 'أتيت', 'إلى', 'اليابان؟'] },
    { 'en': ['No', 'one', 'came', 'except', 'Mary.'], 'ar': ['لم', 'يأت', 'أحد', 'سوى', 'ماري.'] },
    { 'en': ['It', "can't", 'be!'], 'ar': ['لا', 'يمكن', 'أن', 'يحصل', 'ذلك!'] },
    { 'en': ['Why', "can't", 'you', 'come?'], 'ar': ['لمَ', 'لا', 'يمكنك', 'المجيء؟'] },
    { 'en': ['Everybody', 'is', 'waiting', 'for', 'you.'], 'ar': ['الكل', 'ينتظرك.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'credit', 'card?'], 'ar': ['ألديك', 'بطاقة', 'إئتمان؟'] },
    { 'en': ['His', 'eyes', 'are', 'blue.'], 'ar': ['عيناه', 'زرقاوتان.'] },
    { 'en': ['Let', 'me', 'try', 'it.'], 'ar': ['دعني', 'أجربها.'] },
    { 'en': ['Maybe', 'what', 'you', 'said', 'is', 'true.'], 'ar': ['من', 'الممكن', 'أن', 'يكون', 'ما', 'تقوله', 'صحيح.'] },
    { 'en': ['Awesome!'], 'ar': ['رائع!'] },
    { 'en': ['Call', 'me', 'again', 'in', 'two', 'days.'], 'ar': ['اتصل', 'بي', 'مرة', 'أخرى', 'خلال', 'يومين.'] },
    { 'en': ['She', 'went', 'to', 'the', 'hospital', 'yesterday.'], 'ar': ['ذهبت', 'إلى', 'المستشفى', 'البارحة.'] },
    { 'en': ['Who', 'is', 'the', 'man', 'playing', 'the', 'piano?'], 'ar': ['من', 'يعزف', 'على', 'البيانو؟'] },
    { 'en': ['I', 'went', 'to', 'the', 'zoo', 'yesterday.'], 'ar': ['ذهبت', 'إلى', 'حديقة', 'الحيوان', 'البارحة.'] },
    { 'en': ['I', 'was', 'going', 'to', 'write', 'to', 'you,', 'but', 'I', 'forgot.'], 'ar': ['كنت', 'سأراسلك', 'ولكني', 'نسيت.'] },
    { 'en': ['I', 'want', 'to', 'drive.'], 'ar': ['أريد', 'أن', 'أقود.'] },
    { 'en': ['The', 'city', 'was', 'bombed', 'by', 'enemy', 'planes.'], 'ar': ['قصفت', 'طائرات', 'العدوّ', 'المدينة.'] },
    { 'en': ['I', 'made', 'it', 'myself.'], 'ar': ['صنعتها', 'بنفسي.'] },
    { 'en': ['Write', 'down', 'your', 'name', 'here.'], 'ar': ['اكتب', 'اسمك', 'هنا.'] },
    { 'en': ['This', 'is', 'extremely', 'hard.'], 'ar': ['هذا', 'صعبٌ', 'جداً.'] },
    { 'en': ['Well,', 'stranger', 'things', 'have', 'happened.'], 'ar': ['حصل', 'أغرب', 'من', 'ذلك.'] },
    { 'en': ['Animals', 'are', 'afraid', 'of', 'fire.'], 'ar': ['تخاف', 'الحيوانات', 'من', 'النار.'] },
    { 'en': ['I', 'know', 'the', 'girl.'], 'ar': ['أعرف', 'تلك', 'الصبية.'] },
    { 'en': ['How', 'should', 'I', 'eat', 'this?'], 'ar': ['كيف', 'آكل', 'هذا؟'] },
    { 'en': ['How', 'do', 'I', 'get', 'to', 'Park', 'Street?'], 'ar': ['كيف', 'أصل', 'إلى', 'شارع', 'بارك؟'] },
    { 'en': ['Be', 'serious.'], 'ar': ['كُن', 'جدياً.'] },
    { 'en': ['They', "didn't", 'give', 'up.'], 'ar': ['لم', 'يستسلموا'] },
    { 'en': ['Tom', 'left.'], 'ar': ['لقد', 'غادر', 'توم.'] },
    { 'en': ['This', 'is', 'the', 'worst', 'thing', "I've", 'ever', 'done.'], 'ar': ['هذأ', 'أسوأ', 'شيء', 'أرتكبتُه.'] },
    { 'en': ["Don't", 'treat', 'me', 'as', 'if', 'I', 'were', 'a', 'child.'], 'ar': ['لا', 'تعاملني', 'وكأني', 'طفل.'] },
    { 'en': ['My', 'grandfather', 'was', 'born', 'in', '1920.'], 'ar': ['ولد', 'جدي', 'عام', 'ألف', 'و', 'تسعمئة', 'و', 'عشرين', 'للميلاد.'] },
    { 'en': ['Drive', 'carefully.'], 'ar': ['قُد', 'بحذر.'] },
    { 'en': ['Read', 'through', 'the', 'article.'], 'ar': ['إقرأ', 'المقالة.'] },
    { 'en': ['Choose', 'your', 'favorite', 'racket.'], 'ar': ['اختر', 'مضربك', 'المفضل.'] },
    { 'en': ['Do', 'you', 'have', 'an', 'appointment', 'with', 'him?'], 'ar': ['هل', 'لديك', 'موعد', 'معه؟'] },
    { 'en': ['She', 'trembled', 'with', 'fear.'], 'ar': ['ارتجفت', 'خوفاً.'] },
    { 'en': ["I've", 'never', 'met', 'him.'], 'ar': ['لم', 'أقابله', 'من', 'قبل.'] },
    { 'en': ['I', 'just', "don't", 'feel', 'like', 'going', 'out', 'tonight.'], 'ar': ['ليس', 'لدي', 'رغبة', 'في', 'الخروج', 'الليلة', 'فحسب.'] },
    { 'en': ['I', "don't", 'want', 'to', 'be', 'cremated.'], 'ar': ['لا', 'أريد', 'أن', 'تحرق', 'جثتي.'] },
    { 'en': ['Thanks', 'for', 'coming', 'over', 'tonight.'], 'ar': ['شكراً', 'لقدومك', 'لزيارتنا.'] },
    { 'en': ['He', 'has', 'no', 'girlfriend.'], 'ar': ['ليس', 'عنده', 'حبيبة.'] },
    { 'en': ['You', 'have', 'to', 'go.'], 'ar': ['عليك', 'أن', 'ترحل.'] },
    { 'en': ["Don't", 'you', 'feel', 'cold?'], 'ar': ['ألا', 'تشعر', 'بالبرد؟'] },
    { 'en': ["Let's", 'get', 'some', 'sleep.'], 'ar': ['دعونا', 'نأخذ', 'قسطاً', 'من', 'الراحة.'] },
    { 'en': ['When', 'was', 'the', 'last', 'time', 'you', 'rode', 'a', 'bike?'], 'ar': ['متى', 'كانت', 'آخر', 'مرة', 'ركبت', 'بها', 'دراجة', 'هوائية', '؟'] },
    { 'en': ['Tom', "isn't", 'qualified', 'to', 'teach', 'high', 'school.'], 'ar': ['توم', 'ليس', 'مؤهلاً', 'لتدريس', 'المدرسة', 'الثانوية.'] },
    { 'en': ['Tom', "didn't", 'want', 'to', 'go', 'to', 'the', 'movie.'], 'ar': ['لم', 'يرد', 'توم', 'الذهاب', 'إلى', 'السينما.'] },
    { 'en': ['You', "can't", 'live', 'without', 'oxygen.'], 'ar': ['لا', 'يمكنك', 'العيش', 'من', 'دون', 'أكسجين.'] },
    { 'en': ['Try', 'some.'], 'ar': ['هاك،', 'جرب.'] },
    { 'en': ['Most', 'of', 'the', 'leaves', 'have', 'fallen.'], 'ar': ['سقطت', 'معظم', 'الأوراق.'] },
    { 'en': ['Tom', "didn't", 'tell', 'us', 'the', 'truth.'], 'ar': ['توم', 'لم', 'يخبرنا', 'الحقيقة.'] },
    { 'en': ['The', 'girl', 'is', 'lonely.'], 'ar': ['الفتاة', 'تشعر', 'بالوحدة.'] },
    { 'en': ["I'm", 'waiting', 'for', 'your', 'reply.'], 'ar': ['أنا', 'أنتظر', 'ردك.'] },
    { 'en': ['They', 'kept', 'it', 'secret', 'that', 'they', 'were', 'in', 'love.'], 'ar': ['أبقَوْ', 'حبهما', 'سراً.'] },
    { 'en': ['Sorry', 'I', "didn't", 'reply', 'sooner.'], 'ar': ['عذرا', 'لعدم', 'الرد', 'أبكر.'] },
    { 'en': ['Were', 'you', 'shot?'], 'ar': ['هل', 'أصبت', 'بعيار', 'ناري؟'] },
    { 'en': ['There', 'was', 'a', 'lot', 'of', 'snow', 'last', 'year.'], 'ar': ['هطل', 'الكثير', 'من', 'الثلج', 'السنة', 'الماضية.'] },
    { 'en': ['Tom', 'is', 'waiting', 'at', 'the', 'airport.'], 'ar': ['توم', 'ينتظر', 'في', 'المطار.'] },
    { 'en': ['Did', 'I', 'win?'], 'ar': ['هل', 'أنا', 'فُزت؟'] },
    { 'en': ['I', 'got', 'it.'], 'ar': ['فَهمتُ', 'ذلك.'] },
    { 'en': ['That', 'noise', 'was', 'deafing.'], 'ar': ['كان', 'ذلك', 'الضّجيج', 'صاخبا.'] },
    { 'en': ['I', 'took', 'that', 'photo', 'a', 'week', 'ago.'], 'ar': ['أنا', 'أخذت', 'تلك', 'الصورة', 'قبل', 'إسبوع.'] },
    { 'en': ['She', 'can', 'speak', 'three', 'languages.'], 'ar': ['بإمكانها', 'تحدث', 'ثلاثة', 'لغات.'] },
    { 'en': ["Don't", 'forget', 'to', 'put', 'a', 'stamp', 'on', 'your', 'letter.'], 'ar': ['لا', 'تنسَ', 'أن', 'تضع', 'طابعاً', 'على', 'رسالتك.'] },
    { 'en': ['I', 'had', 'no', 'difficulty', 'finding', 'your', 'house.'], 'ar': ['لم', 'أجد', 'صعوبة', 'في', 'العثور', 'على', 'مكان', 'بيتك.'] },
    { 'en': ['I', 'had', 'better', 'ask', 'her.'], 'ar': ['من', 'الأفضل', 'أن', 'أسئلها.'] },
    { 'en': ['Could', 'you', 'show', 'me', 'that?'], 'ar': ['أيمكنك', 'أن', 'تُرِيَني', 'ذلك؟'] },
    { 'en': ['Can', 'your', 'mother', 'drive', 'a', 'car?'], 'ar': ['هل', 'تستطيع', 'أمك', 'أن', 'تقود', 'سيارة؟'] },
    { 'en': ['Who', 'drew', 'it?'], 'ar': ['من', 'رسمها؟'] },
    { 'en': ['Here', 'is', 'my', 'bicycle.'], 'ar': ['ها', 'هي', 'دراجتي.'] },
    { 'en': ['You', 'are', 'selling', 'him', 'short.'], 'ar': ['أنت', 'تستهين', 'به.'] },
    { 'en': ['Hi.'], 'ar': ['مرحبًا.'] },
    { 'en': ['Be', 'specific.'], 'ar': ['كُن', 'محدداً.'] },
    { 'en': ['Tom', 'offered', 'to', 'lend', 'me', 'money.'], 'ar': ['عرض', 'توم', 'أن', 'يقرضني', 'بعض', 'المال'] },
    { 'en': ['What', 'happened?'], 'ar': ['ماذا', 'حدث؟'] },
    { 'en': ['Let', 'me', 'help', 'you.', 'Your', 'bags', 'look', 'very', 'heavy.'], 'ar': ['دَعني', 'أُساعدك.', 'حقائبك', 'تبدو', 'ثقيلة.'] },
    { 'en': ['Tom', 'would', 'like', 'it.'], 'ar': ['توم', 'سيرغب', 'بها.'] },
    { 'en': ['She', 'is', 'very', 'intelligent.'], 'ar': ['إنها', 'حادة', 'الذكاء.'] },
    { 'en': ['We', 'were', 'all', 'tired.'], 'ar': ['كنّا', 'كلّنا', 'متعبون.'] },
    { 'en': ['Just', 'follow', 'your', 'heart.'], 'ar': ['إتبع', 'قلبك', 'فحسب.'] },
    { 'en': ['She', 'came', 'here', 'once', 'again.'], 'ar': ['هىَ', 'أتت', 'إلى', 'هُنا', 'مرةً', 'أخرى.'] },
    { 'en': ['The', 'dragon', 'is', 'an', 'imaginary', 'creature.'], 'ar': ['التنين', 'حيوان', 'خرافي.'] },
    { 'en': ['I', "haven't", 'seen', 'you', 'in', 'years.'], 'ar': ['لم', 'أرك', 'منذ', 'سنوات.'] },
    { 'en': ['I', 'prefer', 'mineral', 'water.'], 'ar': ['أنا', 'أفضل', 'المياه', 'المعدنية.'] },
    { 'en': ['She', 'was', 'about', 'to', 'go', 'out.'], 'ar': ['كانت', 'على', 'وشك', 'الخروج.'] },
    { 'en': ['It', 'seems', 'like', 'years', 'since', 'Sunday', 'morning.'], 'ar': ['وكأن', 'أعواما', 'مضت', 'منذ', 'صباح', 'الأحد.'] },
    { 'en': ["Don't", 'look', 'back.'], 'ar': ['لا', 'تنظر', 'إلى', 'الخلف.'] },
    { 'en': ['Did', 'you', 'do', 'your', 'homework', 'by', 'yourself?'], 'ar': ['هل', 'حللتَ', 'واجِبك', 'بنفسك؟'] },
    { 'en': ['Tom', 'frowned.'], 'ar': ['قطب', 'توم', 'حاجبيه.'] },
    { 'en': ['This', 'desk', 'is', 'mine.'], 'ar': ['هذا', 'مكتبي.'] },
    { 'en': ['That', 'house', 'belongs', 'to', 'me.'], 'ar': ['ذاك', 'المنزل', 'ملكي.'] },
    { 'en': ['Picasso', 'was', 'a', 'famous', 'painter.'], 'ar': ['بيكاسو', 'رسام', 'مشهور.'] },
    { 'en': ['Tom', 'is', 'very', 'talkative.'], 'ar': ['توم', 'كثير', 'الكلام.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'lot', 'to', 'do', 'today?'], 'ar': ['هل', 'لديك', 'الكثير', 'لتفعله', 'اليوم؟'] },
    { 'en': ['You', 'are', 'guilty', 'of', 'murder.'], 'ar': ['إنك', 'مذنب', 'بتهمة', 'القتل.'] },
    { 'en': ['Nobody', 'encouraged', 'her.'], 'ar': ['لم', 'يشجّعها', 'أحد.'] },
    { 'en': ['Stop', 'it', 'right', 'now!'], 'ar': ['أوقف', 'هذا', 'حالاً!'] },
    { 'en': ['My', 'life', 'is', 'over.'], 'ar': ['لقد', 'انتهت', 'حياتي.'] },
    { 'en': ['What', 'a', 'wonderful', 'surprise!'], 'ar': ['يا', 'لها', 'من', 'مفاجأةٍ', 'سارةٍ!'] },
    { 'en': ['May', 'I', 'use', 'your', 'phone?'], 'ar': ['أيمكنني', 'استعمال', 'هاتفك؟'] },
    { 'en': ['Someone', 'stole', 'my', 'wallet.'], 'ar': ['أحدهم', 'سرق', 'محفظتي.'] },
    { 'en': ['I', 'need', 'to', 'concentrate.'], 'ar': ['أحتاج', 'إلى', 'التركيز.'] },
    { 'en': ["It'll", 'stop', 'raining', 'before', 'long.'], 'ar': ['سوف', 'يتوقف', 'المطر', 'قريبا'] },
    { 'en': ['My', 'sister', 'went', 'to', 'Kobe', 'yesterday.'], 'ar': ['ذهبت', 'أختي', 'إلى', 'كوبي', 'بالأمس.'] },
    { 'en': ['Take', 'advantage', 'of', 'every', 'opportunity.'], 'ar': ['استغلّ', 'كل', 'فرصة.'] },
    { 'en': ["I'm", 'too', 'scared', 'to', 'go', 'there', 'by', 'myself.'], 'ar': ['أنا', 'خائفه', 'جداً', 'من', 'الذهاب', 'إلى', 'هناك', 'بمفردي.'] },
    { 'en': ["It's", 'useless', 'to', 'try', 'and', 'resist.'], 'ar': ['لن', 'تستفيد', 'من', 'المقاومة', 'فلا', 'تحاول.'] },
    { 'en': ["It's", 'very', 'big.'], 'ar': ['إنها', 'كبيرة', 'جداً.'] },
    { 'en': ['You', 'made', 'the', 'same', 'mistake.'], 'ar': ['لقد', 'ارتكبت', 'نفس', 'الخطأ.'] },
    { 'en': ["I'm", 'old', 'enough.'], 'ar': ['أنا', 'كبير', 'بما', 'فيه', 'الكفاية.'] },
    { 'en': ['His', 'leg', 'was', 'bitten', 'by', 'a', 'crocodile.'], 'ar': ['عضّ', 'تمساحٌ', 'ساقه.'] },
    { 'en': ['He', 'kissed', 'his', 'daughter', 'on', 'the', 'forehead.'], 'ar': ['قبّل', 'ابنته', 'على', 'الجبين.'] },
    { 'en': ['I', 'am', 'going', 'to', 'be', 'a', 'doctor.'], 'ar': ['سأصبح', 'طبيبًا.'] },
    { 'en': ['Tom', 'is', 'a', 'nice', 'man.'], 'ar': ['توم', 'رجل', 'لطيف', '.'] },
    { 'en': ["You're", 'running', 'late.'], 'ar': ['أنت', 'متأخر.'] },
    { 'en': ['May', 'I', 'look', 'at', 'your', 'passport?'], 'ar': ['هل', 'لي', 'أن', 'أُلقي', 'نظرة', 'على', 'جواز', 'سفرك؟'] },
    { 'en': ['He', 'was', 'hit', 'by', 'a', 'car', 'and', 'died', 'instantly.'], 'ar': ['لقد', 'صدم', 'من', 'قبل', 'سيارة', 'وتوفي', 'على', 'الفور.'] },
    { 'en': ['Where', 'is', 'the', 'book?'], 'ar': ['أين', 'الكتاب؟'] },
    { 'en': ['We', 'need', 'help.'], 'ar': ['نحن', 'نحتاج', 'إلى', 'مساعدة'] },
    { 'en': ['Who', 'cares?'], 'ar': ['من', 'يهمّه؟'] },
    { 'en': ['Could', 'you', 'give', 'me', 'a', 'ride', 'home?'], 'ar': ['هل', 'بإمكانك', 'أن', 'توصلني', 'لمنزلي؟'] },
    { 'en': ['Tell', 'me', 'where', 'to', 'put', 'these', 'books.'], 'ar': ['أخبرني', 'أين', 'أضع', 'هذه', 'الكتب.'] },
    { 'en': ['Listen', 'to', 'me.'], 'ar': ['استمع', 'الي'] },
    { 'en': ['We', "can't", 'sleep', 'because', 'of', 'the', 'noise.'], 'ar': ['لا', 'نستطيع', 'النوم', 'بسبب', 'الضجيج.'] },
    { 'en': ['Do', 'what', 'Tom', 'tells', 'you', 'to', 'do.'], 'ar': ['إفعلى', 'كل', 'ما', 'أخبركِ', 'توم', 'أن', 'تفعليه.'] },
    { 'en': ['She', 'will', 'get', 'married', 'to', 'a', 'rich', 'man.'], 'ar': ['ستتزوّج', 'برجل', 'ثريّ.'] },
    { 'en': ['I', 'started', 'learning', 'Chinese', 'last', 'week.'], 'ar': ['بدأت', 'تعلّم', 'الصينية', 'الأسبوع', 'الماضي.'] },
    { 'en': ['I', 'was', 'happy', 'yesterday.'], 'ar': ['كنت', 'فرِحةً', 'بالأمس.'] },
    { 'en': ['When', 'will', 'you', 'get', 'married?'], 'ar': ['متى', 'ستتزوج؟'] },
    { 'en': ['Do', 'you', 'think', 'Tom', 'bought', 'it?'], 'ar': ['أتظن', 'أن', 'توم', 'اشتراها؟'] },
    { 'en': ['Is', 'the', "university's", 'library', 'open', 'now?'], 'ar': ['هل', 'مكتبة', 'الجامعة', 'مفتوحة', 'الآن؟'] },
    { 'en': ['How', 'many', 'people', 'did', 'you', 'invite?'], 'ar': ['كم', 'شخصًا', 'دعوت؟'] },
    { 'en': ['She', 'turned', 'down', 'my', 'request.'], 'ar': ['لم', 'تقبل', 'طلبي.'] },
    { 'en': ['She', 'had', 'a', 'happy', 'childhood.'], 'ar': ['أمضت', 'طفولة', 'سعيدة.'] },
    { 'en': ["Where's", 'my', 'father?'], 'ar': ['أين', 'أبي؟'] },
    { 'en': ['How', 'can', 'you', 'say', 'that?'], 'ar': ['كيف', 'لك', 'أن', 'تقول', 'ذلك؟'] },
    { 'en': ["It's", 'only', 'a', 'theory.'], 'ar': ['إنها', 'مجرد', 'نظرية.'] },
    { 'en': ['Are', 'these', 'my', 'socks', 'or', 'your', 'socks?'], 'ar': ['هل', 'هؤلاء', 'جواربي', 'أم', 'جواربك؟'] },
    { 'en': ["I'm", 'not', 'happy.'], 'ar': ['أنا', 'لستُ', 'سعيدة.'] },
    { 'en': ['She', "didn't", 'even', 'try', 'to', 'do', 'the', 'right', 'thing.'], 'ar': ['لم', 'تحاول', 'حتى', 'ان', 'تفعل', 'الشيء', 'الصحيح'] },
    { 'en': ['Tell', 'Tom', 'to', 'pick', 'up', 'the', 'phone.'], 'ar': ['قل', 'لتوم', 'أن', 'يرفع', 'سماعة', 'الهاتف.'] },
    { 'en': ['Are', 'you', 'going', 'to', 'sing?'], 'ar': ['هل', 'ستغني؟'] },
    { 'en': ['Write', 'your', 'address', 'here.'], 'ar': ['اكتب', 'عنوانك', 'هنا.'] },
    { 'en': ['Your', 'answer', 'differs', 'from', 'mine.'], 'ar': ['جوابك', 'مختلف', 'عن', 'جوابي.'] },
    { 'en': ['Please', 'say', 'something.'], 'ar': ['قُل', 'شيئاً,', 'رجاءً.'] },
    { 'en': ['Contact', 'Tom.'], 'ar': ['إتصل', 'بتوم.'] },
    { 'en': ['She', 'looks', 'at', 'least', 'sixty.'], 'ar': ['تبدو', 'في', 'الستينات', 'من', 'عمرها', 'على', 'الأقل.'] },
    { 'en': ["You'd", 'better', 'not', 'go', 'there.'], 'ar': ['من', 'الأفضل', 'ألا', 'تذهب', 'إلى', 'هناك.'] },
    { 'en': ['Pass', 'me', 'the', 'pen.'], 'ar': ['ناولني', 'القلم.'] },
    { 'en': ['That', 'house', 'belongs', 'to', 'him.'], 'ar': ['هذا', 'بيته.'] },
    { 'en': ["We'd", 'better', 'wait.'], 'ar': ['الأفضل', 'أن', 'ننتظر.'] },
    { 'en': ['What', 'made', 'you', 'change', 'your', 'mind?'], 'ar': ['ما', 'الذي', 'جعلك', 'تغير', 'رأيك؟'] },
    { 'en': ['The', 'bicycle', 'by', 'the', 'door', 'is', 'mine.'], 'ar': ['الدراجة', 'التي', 'بجانب', 'الباب', 'هي', 'دراجتي.'] },
    { 'en': ['I', 'agree', 'with', 'you', 'entirely.'], 'ar': ['أوافقك', 'الرأي', 'تماما.'] },
    { 'en': ['I', "can't", 'remember', 'the', 'tune', 'of', 'that', 'song.'], 'ar': ['لا', 'أذكر', 'لحن', 'تلك', 'الأغنية.'] },
    { 'en': ['I', 'wrote', 'a', 'song', 'about', 'you.'], 'ar': ['كتبت', 'أغنية', 'عنك.'] },
    { 'en': ['I', 'must', 'go', 'now.'], 'ar': ['يتوجب', 'علي', 'الذهاب', 'الآن.'] },
    { 'en': ['I', 'know', "you're", 'afraid.'], 'ar': ['أعلم', 'بأنك', 'خائف'] },
    { 'en': ['People', 'love', 'freedom.'], 'ar': ['الناس', 'يحبون', 'الحرية.'] },
    { 'en': ['I', "don't", 'worry', 'so', 'much', 'about', 'my', 'resume.'], 'ar': ['أنا', 'لستُ', 'قلقاً', 'كثيراً', 'بشأن', 'سيرتي', 'الذاتية.'] },
    { 'en': ['Bring', 'me', 'something', 'to', 'eat.'], 'ar': ['أحضر', 'لي', 'شيئاً', 'لآكله.'] },
    { 'en': ['You', "can't", 'speak', 'English,', 'can', 'you?'], 'ar': ['أنتَ', 'لا', 'تستطيع', 'تحدث', 'الإنجليزية,', 'هل', 'تستطيع؟'] },
    { 'en': ['I', 'thought', 'you', 'two', 'knew', 'each', 'other.'], 'ar': ['أظنّكما', 'تعرفان', 'بعضكما.'] },
    { 'en': ['Place', 'it', 'wherever', 'you', 'like.'], 'ar': ['ضعها', 'أينما', 'تريد.'] },
    { 'en': ['She', 'turned', 'down', 'my', 'proposal.'], 'ar': ['رفضت', 'عرضي.'] },
    { 'en': ['What', 'happened', 'last', 'night?'], 'ar': ['ماذا', 'حدث', 'بالأمس؟'] },
    { 'en': ['My', 'father', 'gave', 'me', 'a', 'new', 'fountain', 'pen.'], 'ar': ['أعطاني', 'أبي', 'قلمًا', 'سائلًا', 'جديدًا.'] },
    { 'en': ['You', 'sound', 'like', 'my', 'wife.'], 'ar': ['كلامك', 'يذكرني', 'بزوجتي'] },
    { 'en': ['This', 'is', 'smaller', 'than', 'that.'], 'ar': ['هذا', 'أصغر', 'من', 'ذاك.'] },
    { 'en': ['Why', 'is', 'she', 'angry?'], 'ar': ['لماذا', 'هي', 'غاضبة؟'] },
    { 'en': ['Please', 'drive', 'carefully.'], 'ar': ['قد', 'بحذر', 'من', 'فضلك.'] },
    { 'en': ['I', 'received', 'her', 'letter', 'yesterday.'], 'ar': ['استلمت', 'رسالتها', 'البارحة.'] },
    { 'en': ['She', 'speaks', 'relatively', 'fast.'], 'ar': ['إنها', 'تتحدث', 'بسرعة', 'نسبياً.'] },
    { 'en': ["I'll", 'take', 'you', 'home.'], 'ar': ['سأوصلك', 'إلى', 'البيت'] },
    { 'en': ['Somebody', 'is', 'eating.'], 'ar': ['شخص', 'ما', 'يأكل.'] },
    { 'en': ['I', 'spent', 'all', 'afternoon', 'in', 'the', 'library.'], 'ar': ['لقد', 'قضيت', 'كل', 'نهاري', 'في', 'المكتبة'] },
    { 'en': ['You', 'have', 'three', 'pens.'], 'ar': ['لديك', 'ثلاثة', 'أقلام.'] },
    { 'en': ['Knowledge', 'is', 'power.'], 'ar': ['العلم', 'قوة.'] },
    { 'en': ['We', 'came', 'back', 'by', 'way', 'of', 'Hong', 'Kong.'], 'ar': ['عدنا', 'بالمرور', 'من', 'خلال', 'هونج', 'كونج.'] },
    { 'en': ['Just', 'do', 'the', 'essential', 'repairs,', 'please.'], 'ar': ['فقط', 'قم', 'بالإصلاحات', 'الضرورية,', 'لو', 'سمحت..'] },
    { 'en': ['They', 'all', 'drank.'], 'ar': ['كلهم', 'سكارى.'] },
    { 'en': ['It', 'is', 'unfortunately', 'true.'], 'ar': ['مع', 'الأسف', 'إن', 'ذلك', 'صحيح.'] },
    { 'en': ['Living', 'with', 'Tom', "isn't", 'easy.'], 'ar': ['ليس', 'من', 'السهل', 'العيش', 'مع', 'توم.'] },
    { 'en': ['I', 'knew', 'that', 'Tom', 'would', 'come.'], 'ar': ['عرفت', 'أن', 'توم', 'سيأتي.'] },
    { 'en': ['You', 'should', 'quit', 'smoking.'], 'ar': ['عليك', 'أن', 'تقلع', 'عن', 'التدخين.'] },
    { 'en': ['Eat', 'your', 'veggies.'], 'ar': ['كُل', 'الخضار', 'الخاص', 'بك.'] },
    { 'en': ['My', 'brother', 'is', 'small', 'but', 'strong.'], 'ar': ['أخي', 'صغير', 'البنية', 'إلا', 'أنه', 'قوي.'] },
    { 'en': ['She', 'has', 'gone', 'through', 'many', 'difficulties.'], 'ar': ['لقد', 'مرَّت', 'بالكثير', 'من', 'الصعوبات.'] },
    { 'en': ['The', 'workers', 'are', 'exhausted.'], 'ar': ['العمال', 'منهكين'] },
    { 'en': ['Everybody', 'loves', 'her.'], 'ar': ['الكل', 'يحبها.'] },
    { 'en': ['Do', 'you', 'like', 'eating', 'fish?'], 'ar': ['هل', 'تحب', 'أكل', 'السمك؟'] },
    { 'en': ['Tom', "can't", 'decide', 'whether', 'he', 'should', 'go.'], 'ar': ['لا', 'يستطيع', 'توم', 'أن', 'يقرر', 'فيما', 'إذا', 'كان', 'عليه', 'الذهاب.'] },
    { 'en': ["I'll", 'eat', 'here.'], 'ar': ['سآكل', 'هنا.'] },
    { 'en': ['That', "won't", 'change', 'anything.'], 'ar': ['لن', 'يُغَيِّرَ', 'ذلك', 'شيئًا.'] },
    { 'en': ['Today,', 'I', 'have', 'a', 'lot', 'of', 'homework.'], 'ar': ['لدي', 'الكثير', 'من', 'الواجبات', 'هذا', 'اليوم.'] },
    { 'en': ['Let', 'me', 'help', 'you.'], 'ar': ['دعني', 'أساعدك.'] },
    { 'en': ['I', 'think', 'I', 'understand.'], 'ar': ['أظنني', 'أنني', 'فهمت'] },
    { 'en': ['He', "can't", 'wait', 'to', 'see', 'you.'], 'ar': ['لا', 'يطيق', 'صبرا', 'ليراك.'] },
    { 'en': ['Please', 'think', 'about', 'it.'], 'ar': ['من', 'فضلك', 'فكر', 'في', 'الموضوع.'] },
    { 'en': ['I', 'do', 'not', 'understand.'], 'ar': ['أنا', 'لم', 'أفهم.'] },
    { 'en': ['My', 'father', 'contracted', 'pneumonia', 'last', 'month.'], 'ar': ['أصيب', 'أبي', 'بذات', 'الرئة', 'الشهر', 'الماضي.'] },
    { 'en': ['I', 'really', 'enjoy', 'coming', 'here.'], 'ar': ['كم', 'أستمتع', 'بالمجيء', 'هنا!'] },
    { 'en': ['He', 'is', 'always', 'late', 'for', 'school.'], 'ar': ['دائماً', 'يتأخر', 'عن', 'المدرسة.'] },
    { 'en': ['I', "don't", 'love', 'anybody', 'more', 'than', 'I', 'love', 'you.'], 'ar': ['لا', 'أحب', 'أحد', 'بقدر', 'ما', 'أحبك'] },
    { 'en': ["I'm", 'going', 'to', 'stay', 'just', 'a', 'little', 'bit', 'longer.'], 'ar': ['سأبقى', 'أكثر', 'قليلا.'] },
    { 'en': ['I', 'can', 'peel', 'an', 'apple.'], 'ar': ['يمكنني', 'أن', 'أقشر', 'تفاحة.'] },
    { 'en': ['We', 'returned', 'to', 'Boston', 'on', 'October', '20th.'], 'ar': ['عدنا', 'إلى', 'بوستن', 'في', 'العشرين', 'من', 'أكتوبر.'] },
    { 'en': ["Didn't", 'I', 'say', 'that?'], 'ar': ['ألم', 'أقل', 'ذلك؟'] },
    { 'en': ['Will', 'you', 'go', 'to', 'America', 'next', 'month?'], 'ar': ['هل', 'ستذهب', 'إلى', 'أمريكا', 'الشهر', 'القادم؟'] },
    { 'en': ['Tom', 'wants', 'to', 'see', 'you', 'upstairs.'], 'ar': ['توم', 'يريد', 'أن', 'يراك', 'بالطابِق', 'العُلوي.'] },
    { 'en': ['They', 'say', 'that', 'he', 'will', 'never', 'return.'], 'ar': ['يقولون', 'بأنه', 'لن', 'يرجع.'] },
    { 'en': ['I', 'have', 'to', 'come', 'on', 'Monday.'], 'ar': ['يجب', 'علي', 'أن', 'آتي', 'يوم', 'الإثنين'] },
    { 'en': ["It's", 'definitely', 'possible.'], 'ar': ['من', 'المؤكد', 'أنه', 'ممكن.'] },
    { 'en': ['I', "don't", 'think', 'anybody', 'could', 'do', 'that.'], 'ar': ['أشك', 'في', 'أن', 'يستطيع', 'أحد', 'فعل', 'ذلك.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'pen?'], 'ar': ['أيمكنني', 'اِستعارة', 'قلمك؟'] },
    { 'en': ['He', 'lost', 'his', 'eyesight.'], 'ar': ['فقد', 'بصره.'] },
    { 'en': ['She', 'became', 'a', 'nurse.'], 'ar': ['أصبحت', 'ممرضة.'] },
    { 'en': ['What', 'good', 'will', 'that', 'do?'], 'ar': ['ما', 'الفائدة', 'من', 'ذلك؟'] },
    { 'en': ['Tom', 'asked', 'me', 'to', 'forgive', 'him.'], 'ar': ['طلب', 'مني', 'توم', 'مسامحته.'] },
    { 'en': ['Please', 'have', 'someone', 'else', 'do', 'it.'], 'ar': ['اجعل', 'أحداً', 'آخر', 'يقوم', 'بذلك', 'إذا', 'سمحت.'] },
    { 'en': ['I', 'excused', 'myself', 'for', 'a', 'minute.'], 'ar': ['استأذنت', 'لدقيقة.'] },
    { 'en': ['I', "can't", 'forgive', 'him', 'for', 'what', 'he', 'did.'], 'ar': ['لا', 'أقدر', 'أن', 'أسامحه', 'على', 'ما', 'فعل.'] },
    { 'en': ['Bring', 'your', 'children', 'along.'], 'ar': ['أحضر', 'أولادك', 'معك.'] },
    { 'en': ['How', 'do', 'you', 'know', 'Tom?'], 'ar': ['كيف', 'تعرفت', 'على', 'توم؟'] },
    { 'en': ['Tom', 'gave', 'me', 'an', 'apple.'], 'ar': ['أعطاني', 'توم', 'تفاحة.'] },
    { 'en': ['Drop', 'the', 'knife!'], 'ar': ['ألق', 'السكين.'] },
    { 'en': ["Don't", 'you', 'get', 'bored', 'when', "you're", 'alone?'], 'ar': ['ألا', 'تحس', 'بالملل', 'عندما', 'تكون', 'وحيداً؟'] },
    { 'en': ['Tom', 'was', 'the', 'last', 'one', 'to', 'see', 'Mary', 'alive.'], 'ar': ['توم', 'كان', 'آخر', 'من', 'رأى', 'ماري', 'حيّة.'] },
    { 'en': ['I', 'got', 'here', 'Monday', 'night.'], 'ar': ['وصلت', 'هنا', 'مساء', 'الإثنين.'] },
    { 'en': ['Listen', 'to', 'me.'], 'ar': ['استمع', 'إلي'] },
    { 'en': ['He', 'sells', 'cars.'], 'ar': ['إنه', 'يبيع', 'السيارات.'] },
    { 'en': ['My', 'uncle', 'lives', 'in', 'an', 'apartment.'], 'ar': ['يعيش', 'عمي', 'في', 'شقة', 'سكنية.'] },
    { 'en': ['This', 'apple', 'is', 'very', 'red.'], 'ar': ['هذه', 'التفاحة', 'حمراء', 'جداً.'] },
    { 'en': ["I'll", 'see', 'you', 'later.'], 'ar': ['أراك', 'لاحقاً.'] },
    { 'en': ['I', 'will', 'be', 'back', 'soon.'], 'ar': ['سأعود', 'بعد', 'قليل.'] },
    { 'en': ['I', 'slept', 'late.'], 'ar': ['نمت', 'متأخّرا.'] },
    { 'en': ['Can', 'you', 'see', 'the', 'difference?'], 'ar': ['أيمكنك', 'رؤية', 'الفرق؟'] },
    { 'en': ['May', 'I', 'run', 'with', 'you?'], 'ar': ['أيمكنني', 'أن', 'أركض', 'معك؟'] },
    { 'en': ['Tom', 'showed', 'it', 'to', 'me.'], 'ar': ['توم', 'أراني', 'اياه.'] },
    { 'en': ['You', 'should', 'go.'], 'ar': ['من', 'الأفضل', 'أن', 'تذهب.'] },
    { 'en': ['Can', 'you', 'find', 'it?'], 'ar': ['أيمكنك', 'أن', 'تبحث', 'عنها؟'] },
    { 'en': ['How', 'can', 'I', 'help', 'you?'], 'ar': ['كيف', 'بإمكاني', 'مساعدتك؟'] },
    { 'en': ['Everett', 'spoke', 'for', 'almost', 'two', 'hours.'], 'ar': ['تحدث', 'ايفرت', 'لقرابة', 'الساعتين.'] },
    { 'en': ['She', 'asked', 'me', 'how', 'old', 'I', 'was.'], 'ar': ['سألتني', 'كم', 'عمري.'] },
    { 'en': ["It's", 'well', 'worth', 'the', 'trouble.'], 'ar': ['إنها', 'تستحق', 'العناء.'] },
    { 'en': ['You', "won't", 'believe', 'what', 'Tom', 'told', 'me!'], 'ar': ['لن', 'تصدّقي', 'ما', 'قاله', 'لي', 'توم!'] },
    { 'en': ['Where', 'did', 'you', 'buy', 'that', 'dress?'], 'ar': ['من', 'أين', 'أشتريتَ', 'هذا', 'الثوب؟'] },
    { 'en': ['Do', 'you', 'know', 'what', 'happened?'], 'ar': ['هل', 'تعرف', 'ما', 'الذي', 'حصل؟'] },
    { 'en': ['Do', 'you', 'want', 'to', 'do', 'it', 'right', 'now?'], 'ar': ['هل', 'تريد', 'أن', 'تفعلها', 'الآن؟'] },
    { 'en': ["I'm", 'very', 'sorry.'], 'ar': ['أنا', 'آسف', 'جداً'] },
    { 'en': ['I', 'will', 'transfer', 'the', 'money.'], 'ar': ['سأُحوّل', 'المبلغ.'] },
    { 'en': ['We', 'have', 'to', 'help.'], 'ar': ['علينا', 'أن', 'نساعد'] },
    { 'en': ["It's", 'Sunday', 'today.'], 'ar': ['اليوم', 'هو', 'الأحد'] },
    { 'en': ['I', "don't", 'have', 'money.'], 'ar': ['ليس', 'لدي', 'نقود'] },
    { 'en': ['I', 'guess', 'I', 'could', 'wait', 'a', 'little', 'bit', 'longer.'], 'ar': ['أظن', 'أنني', 'يمكنني', 'الانتظار', 'أكثر', 'قليلا.'] },
    { 'en': ['Birds', 'of', 'a', 'feather', 'flock', 'together.'], 'ar': ['الطيور', 'على', 'أشكالها', 'تقع.'] },
    { 'en': ['I', "don't", 'know', 'what', 'to', 'do.'], 'ar': ['لا', 'أعرف', 'ماذا', 'أفعل.'] },
    { 'en': ['I', "don't", 'know', 'how', 'to', 'thank', 'you.'], 'ar': ['لا', 'أعرف', 'كيف', 'أشكرك.'] },
    { 'en': ['I', 'have', 'another', 'friend', 'in', 'China.'], 'ar': ['لي', 'صديق', 'آخر', 'في', 'الصين.'] },
    { 'en': ['Practice', 'makes', 'perfect.'], 'ar': ['الممارسة', 'هي', 'الطريق', 'إلى', 'الإتقان.'] },
    { 'en': ['Please', 'visit', 'me', 'tomorrow.'], 'ar': ['ارجوك', ',', 'قوم', 'بزيارتي', 'غدا.'] },
    { 'en': ['Because', 'he', 'lied,', 'he', 'was', 'punished.'], 'ar': ['عوقب', 'على', 'كذبه.'] },
    { 'en': ['Have', 'faith.'], 'ar': ['كن', 'مؤمنا.'] },
    { 'en': ['What', 'is', 'happening?'], 'ar': ['ما', 'الذي', 'يحدث؟'] },
    { 'en': ['I', 'won!'], 'ar': ['أنا', 'فُزت!'] },
    { 'en': ["Don't", 'give', 'up.', 'You', 'still', 'have', 'a', 'chance.'], 'ar': ['لا', 'تستسلم', 'فما', 'زالت', 'لديك', 'فرصة.'] },
    { 'en': ['Be', 'merciful.'], 'ar': ['كُن', 'رحيماً.'] },
    { 'en': ['How', 'was', 'school', 'today?'], 'ar': ['كيفَ', 'كانت', 'المدرسة', 'اليوم؟'] },
    { 'en': ['Do', 'you', 'know', 'the', 'reason?'], 'ar': ['هل', 'تعرف', 'السبب؟'] },
    { 'en': ['This', 'is', 'an', 'extremely', 'important', 'point.'], 'ar': ['هذه', 'ملاحظة', 'في', 'غاية', 'الأهمية.'] },
    { 'en': ['Can', 'you', 'count', 'in', 'Italian?'], 'ar': ['هل', 'تستطيع', 'أن', 'تعُدّ', 'بالإيطالية؟'] },
    { 'en': ['Tom', 'thanked', 'me', 'for', 'the', 'gift.'], 'ar': ['توم', 'شكرني', 'على', 'الهدية.'] },
    { 'en': ['My', 'uncle', 'gave', 'me', 'this', 'watch.'], 'ar': ['عَمّي', 'أعطاني', 'هذه', 'السّاعة.'] },
    { 'en': ['Get', 'out!'], 'ar': ['اخرج', 'من', 'هنا!'] },
    { 'en': ['Her', 'kindness', 'touched', 'me.'], 'ar': ['أثّر', 'فيّ', 'لطفها.'] },
    { 'en': ['Tom', 'has', 'been', 'at', "Mary's", 'house', 'all', 'afternoon.'], 'ar': ['كانت', 'توم', 'في', 'منزل', 'ماري', 'طوال', 'الظهيرة.'] },
    { 'en': ['Come', 'with', 'me.'], 'ar': ['تعال', 'معي.'] },
    { 'en': ['I', 'have', 'no', 'money', 'with', 'me.'], 'ar': ['لا', 'أملك', 'المال.'] },
    { 'en': ['Tom', 'said', 'that', "he'd", 'be', 'here.'], 'ar': ['توم', 'قال', 'أنه', 'قد', 'يكون', 'هنا'] },
    { 'en': ['Which', 'do', 'you', 'like', 'better,', 'meat', 'or', 'fish?'], 'ar': ['أيهما', 'تفضل', 'اللحم', 'أم', 'السمك؟'] },
    { 'en': ['I', "don't", 'agree', 'with', 'you.'], 'ar': ['لا', 'أوافقك', 'الرأي.'] },
    { 'en': ['No', 'one', 'knows', 'where', 'that', 'dog', 'came', 'from.'], 'ar': ['لا', 'أحد', 'يعلم', 'من', 'أين', 'أتى', 'ذلك', 'الكلب.'] },
    { 'en': ['Walk', 'faster,', 'or', "you'll", 'miss', 'the', 'train.'], 'ar': ['سر', 'بسرعة،', 'أو', 'إنّك', 'ستفوّت', 'القطار.'] },
    { 'en': ['I', 'am', 'an', 'only', 'child.'], 'ar': ['أنا', 'طفل', 'وحيد', 'أبي', 'و', 'أمي.'] },
    { 'en': ['I', 'can', 'do', 'it', 'for', 'you.'], 'ar': ['يمكنني', 'ان', 'افعلها', 'من', 'اجلك'] },
    { 'en': ['Who', 'do', 'you', 'want', 'to', 'speak', 'to?'], 'ar': ['مع', 'من', 'تريد', 'التحدث؟'] },
    { 'en': ['If', 'you', 'eat', 'too', 'much,', "you'll", 'become', 'fat.'], 'ar': ['إذا', 'أكلت', 'كثيرًا', 'فإنك', 'تصير', 'سمينًا.'] },
    { 'en': ['Most', 'Americans', 'like', 'hamburgers.'], 'ar': ['يحب', 'معظم', 'الأمريكيين', 'الهمبرجر.'] },
    { 'en': ['What', 'are', 'you', 'looking', 'at?'], 'ar': ['إلامَ', 'تنظر؟'] },
    { 'en': ['Tom', 'likes', 'painting', 'pictures.'], 'ar': ['توم', 'يحب', 'تلوين', 'الصور.'] },
    { 'en': ['I', "don't", 'want', 'to', 'stay', 'here.'], 'ar': ['لا', 'أريد', 'البقاء', 'هنا.'] },
    { 'en': ["It's", 'not', 'at', 'all', 'impossible.'], 'ar': ['إنها', 'ليست', 'مستحيلة', 'على', 'الإطلاق.'] },
    { 'en': ["Don't", 'worry.', 'I', 'have', 'insurance.'], 'ar': ['لا', 'تقلق.', 'أنا', 'لدي', 'تأمين.'] },
    { 'en': ['Did', 'I', 'miss', 'anything?'], 'ar': ['هل', 'هناك', 'شيء', 'لم', 'أفهمه؟'] },
    { 'en': ['Astronomy', 'is', 'by', 'no', 'means', 'a', 'new', 'science.'], 'ar': ['علم', 'الفلك', 'ليس', 'جديدا', 'على', 'الإطلاق.'] },
    { 'en': ['The', 'war', 'ended', 'in', '1954.'], 'ar': ['انتهت', 'الحرب', 'عام', '١٩٥٤م.'] },
    { 'en': ['This', 'is', "Mary's", 'dog.'], 'ar': ['ذلك', 'كلب', 'ماري.'] },
    { 'en': ['She', 'was', 'busy', 'doing', 'her', 'homework.'], 'ar': ['كانت', 'مشغولة', 'بإنهاء', 'واجباتها.'] },
    { 'en': ['Tom', 'deserved', 'the', 'punishment', 'he', 'got.'], 'ar': ['استحق', 'توم', 'العقاب', 'الذي', 'حصل', 'عليه.'] },
    { 'en': ['Do', 'whatever', 'he', 'tells', 'you.'], 'ar': ['افعل', 'كلّ', 'ما', 'يقولهُ', 'لك.'] },
    { 'en': ['This', 'is', 'my', 'home.'], 'ar': ['هذا', 'بيتي'] },
    { 'en': ['I', 'found', 'out', 'where', 'Tom', 'used', 'to', 'live.'], 'ar': ['عرفت', 'المكان', 'الذي', 'كان', 'يسكن', 'فيه', 'توم.'] },
    { 'en': ['Today', "I'm", 'not', 'feeling', 'well.'], 'ar': ['اليوم', 'لا', 'اشعر', 'أنني', 'بحالة', 'جيدة'] },
    { 'en': ['There', 'is', 'no', 'sign', 'of', 'life', 'on', 'Mars.'], 'ar': ['لا', 'يوجد', 'آثار', 'حياة', 'فوق', 'سطح', 'كوكب', 'المريخ.'] },
    { 'en': ['This', 'bicycle', 'is', 'mine.'], 'ar': ['هذه', 'دراجتي.'] },
    { 'en': ['My', 'aunt', 'has', 'been', 'dead', 'for', 'two', 'years.'], 'ar': ['ماتت', 'عمتي', 'منذ', 'سنتين.'] },
    { 'en': ['You', 'are', 'to', 'blame', 'for', 'the', 'accident.'], 'ar': ['أنت', 'المخطأ', 'في', 'الحادث.'] },
    { 'en': ['I', "don't", 'mind', 'staying.'], 'ar': ['لا', 'أمانع', 'في', 'البقاء.'] },
    { 'en': ['We', "can't", 'protect', 'Tom', 'indefinitely.'], 'ar': ['لن', 'نحمي', 'توم', 'للأبد.'] },
    { 'en': ["I'll", 'have', 'to', 'work', 'hard.'], 'ar': ['عليك', 'العمل', 'بجدّ.'] },
    { 'en': ['Look', 'at', 'the', 'picture.'], 'ar': ['انظر', 'إلى', 'الصورة.'] },
    { 'en': ['Look', 'at', 'me.'], 'ar': ['انظر', 'إليّ.'] },
    { 'en': ['I', 'see', 'Tom.'], 'ar': ['أنا', 'أرى', 'توم'] },
    { 'en': ['The', 'boy', 'is', 'nice.'], 'ar': ['الولد', 'لطيف.'] },
    { 'en': ['Stay', 'away', 'from', 'me.'], 'ar': ['اِبقَ', 'بعيداً', 'عني.'] },
    { 'en': ['His', 'mother', 'was', 'ashamed', 'of', 'him.'], 'ar': ['والدتهُ', 'كانت', 'تخجل', 'منهُ.'] },
    { 'en': ["We'll", 'find', 'one.'], 'ar': ['سنجد', 'واحدا.'] },
    { 'en': ['A', 'bird', 'in', 'the', 'hand', 'is', 'worth', 'two', 'in', 'the', 'bush.'], 'ar': ['عصفور', 'في', 'اليد', 'خير', 'من', 'عشرة', 'على', 'الشجرة.'] },
    { 'en': ['Can', 'we', 'stay', 'here?'], 'ar': ['هل', 'يمكننا', 'البقاء', 'هنا؟'] },
    { 'en': ['Tom', 'will', 'need', 'a', 'lot', 'of', 'help.'], 'ar': ['سوف', 'يحتاج', 'توم', 'الكثير', 'من', 'المساعدة.'] },
    { 'en': ['Do', 'you', 'have', 'any', 'Japanese', 'magazines?'], 'ar': ['هل', 'لديك', 'أي', 'مجلات', 'يابانية؟'] },
    { 'en': ['Mary', 'likes', 'Japan,', "doesn't", 'she?'], 'ar': ['ماري', 'تحب', 'اليابان،', 'أليس', 'كذلك؟'] },
    { 'en': ['The', 'old', 'man', 'spoke', 'to', 'me', 'in', 'French.'], 'ar': ['تحدث', 'إليّ', 'الرجل', 'العجوز', 'بالفرنسية.'] },
    { 'en': ["I'm", 'trying', 'to', 'save', 'room', 'for', 'dessert.'], 'ar': ['أُحَاوِلُ', 'تَرْكَ', 'مَكَانٍ', 'لِلتَّحْلِيَة.'] },
    { 'en': ['Tom', 'knows', 'us', 'well.'], 'ar': ['يعرفنا', 'توم', 'جيدا'] },
    { 'en': ['Tom', 'glanced', 'at', 'the', 'rear-view', 'mirror.'], 'ar': ['توم', 'القى', 'نظرة', 'سريعة', 'في', 'مرآة', 'الرؤية', 'الخلفية', '.'] },
    { 'en': ['Tom', "isn't", 'home.'], 'ar': ['توم', 'ليس', 'في', 'البيت'] },
    { 'en': ['Take', 'what', 'you', 'want.'], 'ar': ['خذ', 'ما', 'تريد'] },
    { 'en': ['Birds', 'fly.'], 'ar': ['العصافير', 'تطير.'] },
    { 'en': ['Finally,', "it's", 'Friday.'], 'ar': ['و', 'أخيراً', 'أتى', 'الجمعة.'] },
    { 'en': ['This', 'play', 'has', 'three', 'acts.'], 'ar': ['لهذه', 'المسرحية', 'ثلاثة', 'فصول.'] },
    { 'en': ['I', 'thought', 'this', 'was', 'common', 'knowledge.'], 'ar': ['ظننت', 'هذا', 'معلوما.'] },
    { 'en': ['I', 'found', 'the', 'key', 'that', 'I', 'was', 'looking', 'for.'], 'ar': ['وجت', 'المفتاح', 'الذي', 'كنت', 'أبحث', 'عنه.'] },
    { 'en': ['The', 'man', 'aimed', 'a', 'gun', 'at', 'the', 'detectives.'], 'ar': ['صوب', 'الرجل', 'مسدساً', 'نحو', 'المحققين.'] },
    { 'en': ["I'm", 'going', 'to', 'play', 'chess', 'with', 'Tom', 'today.'], 'ar': ['سألعب', 'الشطرنج', 'مع', 'توم', 'اليوم.'] },
    { 'en': ['Does', 'she', 'have', 'a', 'piano?'], 'ar': ['هل', 'لديها', 'بيانو؟'] },
    { 'en': ['The', 'cat', 'which', 'you', 'found', 'is', 'mine.'], 'ar': ['القطة', 'التي', 'عثرت', 'عليها', 'تكون', 'لي.'] },
    { 'en': ['Take', 'a', 'look', 'at', 'these.'], 'ar': ['ألق', 'نظرة', 'على', 'هذه'] },
    { 'en': ['I', 'really', "can't", 'help', 'you.'], 'ar': ['أنا', 'حقا', 'لا', 'أستطيع', 'مساعدتك.'] },
    { 'en': ['Why', 'did', 'you', 'lie', 'to', 'me?'], 'ar': ['لماذا', 'كذبت', 'علي؟'] },
    { 'en': ['Tom', 'is', 'in', 'bed', 'with', 'a', 'fever.'], 'ar': ['توم', 'يرقد', 'في', 'الفراش', 'محموماً.'] },
    { 'en': ['I', 'think', "it's", 'OK.'], 'ar': ['أظن', 'أنها', 'لا', 'بأس', 'بها.'] },
    { 'en': ['The', 'list', 'is', 'endless.'], 'ar': ['القائمة', 'لانهائيّة.'] },
    { 'en': ['I', 'came', 'to', 'Japan', 'last', 'year.'], 'ar': ['جئت', 'إلى', 'اليابان', 'العام', 'الماضي.'] },
    { 'en': ['I', 'am', 'not', 'from', 'India.'], 'ar': ['لست', 'من', 'الهند.'] },
    { 'en': ['You', 'are', 'not', 'Japanese.'], 'ar': ['أنت', 'لست', 'ياباني'] },
    { 'en': ['The', 'train', 'has', 'arrived.'], 'ar': ['وصل', 'القطار.'] },
    { 'en': ['I', 'was', 'late', 'because', 'of', 'heavy', 'traffic.'], 'ar': ['تأخرت', 'بسبب', 'زحمة', 'الطريق', 'الشديدة.'] },
    { 'en': ['Let', 'me', 'help', 'you', 'with', 'your', 'work.'], 'ar': ['دعني', 'أساعدك', 'في', 'عملك.'] },
    { 'en': ['Hi,', 'come', 'on', 'in.'], 'ar': ['مرحبا,', 'ادخل'] },
    { 'en': ['The', 'driver', 'accelerated', 'his', 'car.'], 'ar': ['تسارع', 'السائق', 'بسيارته.'] },
    { 'en': ['I', "can't", 'concentrate', 'while', "you're", 'here.'], 'ar': ['لا', 'يمكنني', 'التركيز', 'عندما', 'تكون', 'هنا.'] },
    { 'en': ['They', 'are', 'struggling', 'for', 'freedom.'], 'ar': ['إنهم', 'يكافحون', 'من', 'أجل', 'الحرية.'] },
    { 'en': ['Have', 'you', 'ever', 'traveled', 'alone?'], 'ar': ['هل', 'سافرت', 'لوحدك', 'مرة؟'] },
    { 'en': ['Should', 'we', 'go', 'by', 'car', 'or', 'by', 'taxi?'], 'ar': ['ما', 'رأيك', '،', 'أنذهب', 'بالسيارة', 'أم', 'بالتاكسي؟'] },
    { 'en': ['Please', 'forgive', 'me.'], 'ar': ['سامحني', 'أرجوك.'] },
    { 'en': ['Tom', "doesn't", 'even', 'know', 'I', 'exist.'], 'ar': ['توم', 'لا', 'يعرف', 'بأني', 'موجود.'] },
    { 'en': ['They', "didn't", 'know', 'me.'], 'ar': ['لم', 'يعرفوني'] },
    { 'en': ["It's", 'none', 'of', 'your', 'business.'], 'ar': ['ليس', 'ذلك', 'من', 'شأنك.'] },
    { 'en': ["I've", 'done', 'my', 'duty.'], 'ar': ['قمت', 'بما', 'عليّ.'] },
    { 'en': ['Are', 'you', 'sure', 'you', 'know', 'how', 'to', 'do', 'this?'], 'ar': ['أمتأك', 'من', 'أنك', 'تعرف', 'كيف', 'تفعل', 'هذا؟'] },
    { 'en': ["I'm", 'about', 'to', 'leave.'], 'ar': ['إنني', 'على', 'وشك', 'أن', 'أغادر.'] },
    { 'en': ['You', 'must', 'fulfill', 'your', 'duty.'], 'ar': ['عليك', 'أن', 'تقوم', 'بواجبك.'] },
    { 'en': ['I', "can't", 'think', 'of', 'his', 'name', 'just', 'now.'], 'ar': ['لا', 'أستطيع', 'تذكر', 'اسمه', 'الآن.'] },
    { 'en': ["I'm", 'gonna', 'have', 'to', 'call', 'you', 'back.'], 'ar': ['سأعاود', 'الإتصال', 'بك.'] },
    { 'en': ['I', 'must', 'buy', 'one.'], 'ar': ['لابد', 'أن', 'اشتري', 'واحدة.'] },
    { 'en': ['A', 'widow', 'is', 'a', 'woman', 'whose', 'husband', 'has', 'died.'], 'ar': ['الأرملة', 'هي', 'امرأة', 'مات', 'زوجها.'] },
    { 'en': ["He's", 'very', 'honest,', 'so', 'we', 'can', 'rely', 'on', 'him.'], 'ar': ['إنهُ', 'أمين', 'جداً,', 'لذلك', 'يمكننا', 'الإعتماد', 'عليه.'] },
    { 'en': ['I', 'want', 'to', 'learn', 'to', 'swim.'], 'ar': ['أريد', 'أن', 'أتعلم', 'السباحة.'] },
    { 'en': ["Don't", 'judge', 'by', 'appearances.'], 'ar': ['لا', 'تحكم', 'بظواهر', 'الأمور.'] },
    { 'en': ['Tom', 'announced', 'he', 'was', 'retiring.'], 'ar': ['أعلن', 'توم', 'عن', 'تقاعده.'] },
    { 'en': ['You', 'can', 'come', 'with', 'me.'], 'ar': ['يمكنك', 'مرافقتي.'] },
    { 'en': ['He', 'is', 'a', 'daredevil.'], 'ar': ['هو', 'متهور.'] },
    { 'en': ['Tom', 'looks', 'amazing.'], 'ar': ['يبدو', '"توم"', 'مذهلا'] },
    { 'en': ['Take', 'it', 'easy.'], 'ar': ['على', 'رِسْلِك.'] },
    { 'en': ['We', 'were', 'in', 'a', 'hurry.'], 'ar': ['كنا', 'علی', 'عجلة', 'من', 'أمرنا'] },
    { 'en': ['He', 'knows', 'us', 'very', 'well.'], 'ar': ['إنه', 'يعرفنا', 'جيداً.'] },
    { 'en': ['What', 'happened', 'to', 'him?'], 'ar': ['ما', 'الذي', 'حصل', 'له؟'] },
    { 'en': ["They've", 'come', 'to', 'make', 'trouble.'], 'ar': ['هُم', 'جاءو', 'ليصنعوا', 'مشكلة.'] },
    { 'en': ['Is', 'this', 'place', 'far', 'from', 'the', 'bank?'], 'ar': ['هل', 'ذاك', 'المكان', 'بعيد', 'عن', 'المصرف؟'] },
    { 'en': ['Trees', 'are', 'planted', 'along', 'the', 'street.'], 'ar': ['زُرِعَت', 'الأشجار', 'بجانب', 'الطريق.'] },
    { 'en': ['I', 'thought', 'you', 'might', 'feel', 'sorry', 'for', 'Tom.'], 'ar': ['ظننت', 'أنك', 'قد', 'تشعر', 'بالأسف', 'على', 'توم.'] },
    { 'en': ["I'm", 'not', 'your', 'enemy.', "I'm", 'your', 'friend.'], 'ar': ['انا', 'لست', 'عدوك.', 'انا', 'صديقك.'] },
    { 'en': ['The', 'bridge', 'is', 'under', 'construction.'], 'ar': ['الجسر', 'في', 'طور', 'البناء.'] },
    { 'en': ['I', "can't", 'stay', 'long', 'today.'], 'ar': ['لا', 'يمكنني', 'المكوث', 'طويلا', 'اليوم.'] },
    { 'en': ["I'd", 'like', 'to', 'stay', 'here', 'with', 'you.'], 'ar': ['أريد', 'البقاء', 'هنا', 'معك.'] },
    { 'en': ['He', 'blocked', 'my', 'way.'], 'ar': ['سدّ', 'عليّ', 'الطريق.'] },
    { 'en': ['I', 'have', 'two', 'sons.'], 'ar': ['عندي', 'ابنان.'] },
    { 'en': ['Speech', 'is', 'silver,', 'but', 'silence', 'is', 'golden.'], 'ar': ['الكلام', 'فضة،', 'لكن', 'الصمت', 'ذهب.'] },
    { 'en': ['Tom', 'would', 'like', 'to', 'speak', 'to', 'you', 'in', 'private.'], 'ar': ['توم', 'يريد', 'محادثتك', 'على', 'انفراد.'] },
    { 'en': ['I', 'like', 'to', 'study', 'languages.'], 'ar': ['أحبّ', 'دراسة', 'اللّغات.'] },
    { 'en': ["I've", 'looked', 'everywhere.'], 'ar': ['أنا', 'بحثت', 'في', 'كل', 'مكان.'] },
    { 'en': ['Are', 'you', 'doing', 'that', 'on', 'purpose?'], 'ar': ['هل', 'تقوم', 'بذلك', 'عن', 'قصد؟'] },
    { 'en': ['This', 'dog', 'is', 'white.'], 'ar': ['هذا', 'الكلب', 'أبيض.'] },
    { 'en': ['This', 'will', 'cost', '€30.'], 'ar': ['الحساب', 'ثلاثون', 'يورو.'] },
    { 'en': ['What', 'do', 'people', 'do', 'for', 'fun', 'in', 'Boston?'], 'ar': ['ماذا', 'يفعلون', 'الناس', 'من', 'اجل', 'المتعة', 'في', 'بوسطن؟'] },
    { 'en': ['It', 'is', 'going', 'to', 'rain.'], 'ar': ['ستمطر.'] },
    { 'en': ['Can', 'I', 'use', 'your', 'pen?'], 'ar': ['أيمكنني', 'أن', 'أستخدم', 'قلمك؟'] },
    { 'en': ['She', 'is', 'not', 'tall.'], 'ar': ['ليست', 'طويلة.'] },
    { 'en': ['I', 'keep', 'sneezing.'], 'ar': ['أعطس', 'كثيراً.'] },
    { 'en': ['Look', 'at', 'that', 'red', 'building.'], 'ar': ['أنظر', 'إلى', 'ذلك', 'المبنى', 'الأحمر.'] },
    { 'en': ['I', 'was', 'watching', 'TV', 'then.'], 'ar': ['كنت', 'أشاهد', 'التلفاز', 'حينها.'] },
    { 'en': ["I'm", 'really', 'hungry.'], 'ar': ['أنا', 'في', 'غاية', 'الجوع.'] },
    { 'en': ['This', 'is', 'always', 'the', 'way', 'it', 'has', 'been.'], 'ar': ['هكذا', 'كانت', 'الأمور', 'دائمًا.'] },
    { 'en': ['Wood', 'burns.'], 'ar': ['الخشب', 'يحترق.'] },
    { 'en': ['Can', 'you', 'deal', 'with', 'it?'], 'ar': ['أيمكنك', 'تولّي', 'أمره؟'] },
    { 'en': ['I', 'was', 'expecting', 'it!'], 'ar': ['كنت', 'أتوقعه!'] },
    { 'en': ['Watermelons', 'are', 'in', 'season', 'now.'], 'ar': ['هذا', 'موسم', 'البطيخ.'] },
    { 'en': ['Take', 'good', 'care', 'of', 'yourself.'], 'ar': ['اعتنِ', 'بنفسك', 'جيدًا.'] },
    { 'en': ['Go', 'away.'], 'ar': ['ارحل.'] },
    { 'en': ['Tom', "doesn't", 'think', 'that', 'Mary', 'is', 'at', 'home.'], 'ar': ['لا', 'يظن', 'توم', 'أن', 'ماري', 'في', 'البيت.'] },
    { 'en': ['We', 'knew', 'Tom', "wouldn't", 'be', 'late.'], 'ar': ['علمنا', 'أن', 'توم', 'لن', 'يتأخر.'] },
    { 'en': ['You', 'must', 'be', 'the', 'temporary', 'we', 'asked', 'for.'], 'ar': ['لا', 'بد', 'أنك', 'البديل', 'المؤقت', 'الذي', 'طلبناه.'] },
    { 'en': ["Let's", 'discuss', 'that', 'problem', 'later.'], 'ar': ['لنناقش', 'هذه', 'المشكلة', 'لاحقاً.'] },
    { 'en': ['We', 'found', 'her', 'alive.'], 'ar': ['وجدناها', 'على', 'قيد', 'الحياة.'] },
    { 'en': ['Tom', 'is', 'from', 'Boston.'], 'ar': ['توم', 'هو', 'من', 'بوستون.'] },
    { 'en': ['The', 'conference', 'ended', 'two', 'hours', 'ago.'], 'ar': ['أُختتم', 'المؤتمر', 'قبل', 'ساعتين.'] },
    { 'en': ["I'm", 'an', 'honest', 'person.'], 'ar': ['أنا', 'صادق.'] },
    { 'en': ['Where', 'are', 'we', 'going?'], 'ar': ['إلى', 'أين', 'نحن', 'ذاهبون؟'] },
    { 'en': ['You', 'probably', 'want', 'to', 'be', 'alone.'], 'ar': ['أنتَ', 'ربما', 'ترغب', 'في', 'أن', 'تكون', 'وحيداً.'] },
    { 'en': ['She', 'told', 'me', 'she', 'knew', 'my', 'brother.'], 'ar': ['أخبرتني', 'بأنها', 'تعرف', 'أخي.'] },
    { 'en': ["There's", 'nobody', 'inside.'], 'ar': ['لا', 'أحد', 'بالداخل.'] },
    { 'en': ['This', 'girl', 'changed', 'her', 'look.'], 'ar': ['غيّرت', 'الفتاة', 'شكلها.'] },
    { 'en': ['Is', 'there', 'an', 'elevator?'], 'ar': ['هل', 'يوجد', 'مصعد؟'] },
    { 'en': ['My', 'brother', 'has', 'become', 'a', 'priest.'], 'ar': ['أصبح', 'أخي', 'راهبا'] },
    { 'en': ['Tom', 'left', 'the', 'gate', 'open.'], 'ar': ['ترك', 'توم', 'البوابة', 'مفتوحة.'] },
    { 'en': ['Look', 'it', 'up', 'in', 'the', 'dictionary.'], 'ar': ['ابحث', 'عنها', 'في', 'قاموسك.'] },
    { 'en': ['Can', 'I', 'buy', 'you', 'another', 'drink?'], 'ar': ['أأشتري', 'لك', 'شرابا', 'آخر؟'] },
    { 'en': ["I'm", 'feeling', 'fit.'], 'ar': ['أحس', 'بأن', 'لياقتي', 'عالية.'] },
    { 'en': ['Do', 'you', 'know', 'if', "he's", 'coming', 'to', 'the', 'party?'], 'ar': ['هل', 'تعرف', 'إن', 'كان', 'سيحضر', 'الحفل؟'] },
    { 'en': ['Bring', 'wine.'], 'ar': ['أحضر', 'الخمر.'] },
    { 'en': ['Can', 'you', 'really', 'swim?'], 'ar': ['هل', 'يمكنك', 'أن', 'تسبح', 'حقاً؟'] },
    { 'en': ['By', 'the', 'way,', 'do', 'you', 'play', 'the', 'violin?'], 'ar': ['بالمناسبة', 'هل', 'يمكنك', 'العزف', 'على', 'الكمان؟'] },
    { 'en': ['May', 'I', 'introduce', 'my', 'friend', 'to', 'you?'], 'ar': ['أعرّفك', 'بصديقي.'] },
    { 'en': ['I', "can't", 'use', 'a', 'fork', 'well.'], 'ar': ['لا', 'اعرف', 'كيف', 'أستخدم', 'الشوكة', 'جيداً.'] },
    { 'en': ['We', 'grow', 'wheat', 'here.'], 'ar': ['نزرع', 'القمح', 'هنا.'] },
    { 'en': ['A', 'drowning', 'man', 'will', 'clutch', 'at', 'a', 'straw.'], 'ar': ['الغريق', 'يتعلق', 'بقشة.'] },
    { 'en': ['He', 'will', 'play', 'tennis', 'with', 'his', 'friends.'], 'ar': ['سوف', 'يلعب', 'كرة', 'المضرب', 'مع', 'أصدقائه.'] },
    { 'en': ['He', 'heard', 'a', 'shout.'], 'ar': ['سمع', 'صرخة.'] },
    { 'en': ['Tom', 'wanted', 'to', 'know', 'where', 'Mary', 'had', 'been.'], 'ar': ['أراد', 'توم', 'معرفة', 'أين', 'كانت', 'ماري.'] },
    { 'en': ['Tom', 'and', 'Mary', "don't", 'live', 'in', 'the', 'same', 'state.'], 'ar': ['توم', 'وماري', 'لا', 'يعيشون', 'فى', 'نفس', 'الولاية.'] },
    { 'en': ['I', 'am', 'curious.'], 'ar': ['أود', 'أن', 'أعرف.'] },
    { 'en': ["I'm", 'not', 'worried.'], 'ar': ['أنا', 'لست', 'قلقاً.'] },
    { 'en': ['That', "doesn't", 'seem', 'right', 'at', 'all.'], 'ar': ['لا', 'يبدو', 'هذا', 'عادلا', 'إطلاقا.'] },
    { 'en': ['The', 'house', 'standing', 'on', 'the', 'hill', 'is', 'very', 'old.'], 'ar': ['المنزل', 'الواقف', 'على', 'التلة', 'قديم', 'جداً.'] },
    { 'en': ['Tom', 'has', 'kids.'], 'ar': ['توم', 'لديه', 'أطفال.'] },
    { 'en': ['Tom', 'barged', 'into', 'the', 'room.'], 'ar': ['اقتحم', 'توم', 'الغرفة.'] },
    { 'en': ["Aren't", 'you', 'thirsty?'], 'ar': ['ألست', 'عطشاناً؟'] },
    { 'en': ['I', "didn't", 'know', 'where', 'it', 'came', 'from.'], 'ar': ['لا', 'أعلم', 'من', 'أين', 'أتت.'] },
    { 'en': ["I'm", 'very', 'grateful', 'for', 'your', 'help.'], 'ar': ['أنا', 'ممتن', 'للغاية', 'لمساعدتك.'] },
    { 'en': ['The', 'sky', 'is', 'clear.'], 'ar': ['السماء', 'صافية.'] },
    { 'en': ['Translate', 'this', 'book', 'into', 'English.'], 'ar': ['تَرجمْ', 'هذا', 'الكتابَ', 'إلى', 'الأنجليزيّة.'] },
    { 'en': ['"Did', 'she', 'buy', 'a', 'watch?"', '"Yes,', 'she', 'did."'], 'ar': ['"هل', 'اشترت', 'ساعةً؟"', '"أجل".'] },
    { 'en': ['Hey,', 'what', 'are', 'you', 'talking', 'about?'], 'ar': ['عن', 'ماذا', 'تتحدث؟'] },
    { 'en': ['May', 'I', 'use', 'this?'], 'ar': ['هل', 'يمكنني', 'استخدام', 'هذا؟'] },
    { 'en': ['Who', 'made', 'this', 'plan?'], 'ar': ['من', 'وضع', 'هذه', 'الخطة؟'] },
    { 'en': ['I', 'lost', 'my', 'shoe', 'in', 'the', 'fire.'], 'ar': ['لقد', 'فقدت', 'حذائي', 'في', 'الحريق.'] },
    { 'en': ['Tom', 'likes', 'money.'], 'ar': ['توم', 'يحب', 'النقود'] },
    { 'en': ['What', 'did', 'you', 'major', 'in', 'at', 'college?'], 'ar': ['ما', 'المجال', 'الذي', 'قمت', 'بدراسته', 'في', 'الجامعة؟'] },
    { 'en': ['In', 'order', 'to', 'do', 'that,', 'you', 'have', 'to', 'take', 'risks.'], 'ar': ['لفعل', 'ذلك،', 'يجِبُ', 'أن', 'تُخاطر.'] },
    { 'en': ['Drive', 'safely.'], 'ar': ['قد', 'بحذر.'] },
    { 'en': ['Those', 'books', 'are', 'theirs.'], 'ar': ['تلك', 'كتبهم.'] },
    { 'en': ['Someone', 'is', 'calling', 'you.'], 'ar': ['أحدهم', 'يتصل', 'بك.'] },
    { 'en': ['This', 'car', 'is', 'bigger', 'than', 'that', 'one.'], 'ar': ['هذه', 'السيارة', 'أكبر', 'من', 'تلك.'] },
    { 'en': ['All', 'right,', "I'll", 'do', 'it.'], 'ar': ['لا', 'بأس،', 'سأتولى', 'أنا', 'الأمر.'] },
    { 'en': ['You', 'broke', 'the', 'rule.'], 'ar': ['لقد', 'خالفت', 'القانون.'] },
    { 'en': ['That', 'boy', 'has', 'black', 'hair.'], 'ar': ['شعر', 'ذاك', 'الولد', 'أسود.'] },
    { 'en': ['We', 'have', 'to', 'do', 'this', 'again', 'tomorrow.'], 'ar': ['علينا', 'تكرار', 'هذا', 'غدا.'] },
    { 'en': ['He', 'came', 'to', 'ask', 'us', 'for', 'our', 'help.'], 'ar': ['جاء', 'ليطلب', 'مساعدتنا.'] },
    { 'en': ['Tom', 'certainly', 'deserves', 'praise.'], 'ar': ['توم', 'مؤكدا', 'يستحق', 'المدح'] },
    { 'en': ['Who', 'do', 'you', 'want', 'to', 'talk', 'to?'], 'ar': ['مع', 'من', 'تريد', 'أن', 'تتحدث؟'] },
    { 'en': ['Hurry', 'up,', 'or', "we'll", 'miss', 'the', 'train.'], 'ar': ['أسرع', 'و', 'إلا', 'فاتنا', 'القطار.'] },
    { 'en': ['Please', 'accept', 'my', 'apologies.'], 'ar': ['تقبل', 'إعتذاري', 'من', 'فضلك.'] },
    { 'en': ['I', 'think', 'you', 'are', 'a', 'really', 'nice', 'person.'], 'ar': ['أعتقد', 'أنك', 'شخص', 'لطيف', 'حقاً'] },
    { 'en': ['Frankly', 'speaking,', 'he', 'is', 'wrong.'], 'ar': ['في', 'الحقيقة،', 'إنه', 'مخطئ.'] },
    { 'en': ['Tom', 'got', 'into', 'the', 'wheelchair.'], 'ar': ['جلس', 'توم', 'على', 'الكرسي', 'المتحرك.'] },
    { 'en': ['Please', 'make', 'sure.'], 'ar': ['تأكّد', 'لو', 'سمحت.'] },
    { 'en': ['He', 'went', 'to', 'the', 'store.'], 'ar': ['ذهب', 'إلى', 'الدكان.'] },
    { 'en': ['This', 'word', 'comes', 'from', 'Greek.'], 'ar': ['أصل', 'هذه', 'الكلمة', 'يوناني.'] },
    { 'en': ["How's", 'Tom?'], 'ar': ['كيف', 'حال', 'توم؟'] },
    { 'en': ['His', 'handwriting', 'is', 'poor.'], 'ar': ['خَطهُ', 'رديء.'] },
    { 'en': ['She', 'had', 'suffered', 'a', 'heart', 'attack.'], 'ar': ['هي', 'تعرضت', 'إلي', 'نوبة', 'قلبية.'] },
    { 'en': ['There', 'are', 'lots', 'of', 'animals', 'in', 'the', 'park.'], 'ar': ['يوجد', 'الكثير', 'من', 'الحيوانات', 'في', 'المنتزه.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'come?'], 'ar': ['هل', 'تود', 'القدوم؟'] },
    { 'en': ['Who', 'is', 'this', 'person?'], 'ar': ['من', 'ذلك', 'الشخص؟'] },
    { 'en': ['The', 'man', 'caught', 'the', 'girl', 'by', 'the', 'wrist.'], 'ar': ['أمسك', 'الرجلُ', 'الفتاةَ', 'من', 'معصمها.'] },
    { 'en': ['She', 'hates', 'green', 'peppers.'], 'ar': ['إنها', 'لا', 'تحب', 'الفلفل', 'الأخضر.'] },
    { 'en': ["I'll", 'tell', 'you', 'what', 'I', 'want', 'you', 'to', 'do.'], 'ar': ['سأخبرك', 'بما', 'أريد', 'فعله.'] },
    { 'en': ["I'll", 'be', 'back', 'in', 'an', 'hour.'], 'ar': ['سأعود', 'بعد', 'ساعة.'] },
    { 'en': ['It', 'may', 'rain.'], 'ar': ['ربما', 'ستمطر.'] },
    { 'en': ["It's", 'time', 'for', 'me', 'to', 'go.'], 'ar': ['حان', 'وقت', 'ذهابي.'] },
    { 'en': ["Let's", 'meet', 'here', 'again', 'tomorrow.'], 'ar': ['لنتقابل', 'هنا', 'مرة', 'أخرى', 'في', 'الغد.'] },
    { 'en': ['What', 'are', 'you', 'thinking', 'about?'], 'ar': ['ما', 'الذي', 'تفكر', 'فيه؟'] },
    { 'en': ["Don't", 'get', 'run', 'over.'], 'ar': ['لا', 'تهربي'] },
    { 'en': ['Tom', 'drives', 'an', 'imported', 'car.'], 'ar': ['يقود', 'توم', 'سيارة', 'مستوردة.'] },
    { 'en': ['"Will', 'you', 'help', 'me?"', '"I\'ll', 'be', 'glad', 'to."'], 'ar': ['هلا', 'ساعدتني؟', 'سأكون', 'مسرورا', 'بذلك.'] },
    { 'en': ['You', 'should', 'not', 'sleep.'], 'ar': ['يجب', 'أن', 'لا', 'تنام.'] },
    { 'en': ['Tom', "isn't", 'good', 'at', 'talking', 'to', 'children.'], 'ar': ['توم', 'ليس', 'جيد', 'للحديث', 'مع', 'الاطفال'] },
    { 'en': ['He', 'left', 'the', 'door', 'open.'], 'ar': ['ترك', 'الباب', 'مفتوحاً.'] },
    { 'en': ['We', 'need', 'rain.'], 'ar': ['نحن', 'نحتاج', 'إلي', 'المطر.'] },
    { 'en': ['I', 'want', 'to', 'answer', 'the', 'last', 'question', 'first.'], 'ar': ['أريد', 'الإجابةَ', 'على', 'السؤال', 'الأخير', 'أولاً.'] },
    { 'en': ["Let's", 'check', 'with', 'an', 'expert.'], 'ar': ['دعنا', 'نتأكد', 'من', 'خبير.'] },
    { 'en': ['My', 'father', 'loves', 'pizza.'], 'ar': ['أبي', 'يحب', 'البيتزا.'] },
    { 'en': ["Let's", 'go', 'to', 'my', 'office.'], 'ar': ['هيا', 'بنا', 'نذهب', 'إلى', 'مكتبي.'] },
    { 'en': ['They', 'might', 'hear', 'us.'], 'ar': ['قد', 'يسمعونا.'] },
    { 'en': ["I'm", 'reading.'], 'ar': ['أنا', 'أقرأ'] },
    { 'en': ['You', "can't", 'be', 'serious.'], 'ar': ['لا', 'يمكن', 'أن', 'تكون', 'جاداً.'] },
    { 'en': ['Please', 'take', 'one.'], 'ar': ['تفضّل', 'خذ', 'واحدة.'] },
    { 'en': ['We', 'learn', 'by', 'experience.'], 'ar': ['نحن', 'نتعلم', 'بالتجربة.'] },
    { 'en': ['We', 'had', 'no', 'choice', 'but', 'to', 'go', 'there.'], 'ar': ['لم', 'يكن', 'لدينا', 'خيار', 'إلا', 'الذهاب', 'إلى', 'هناك.'] },
    { 'en': ['My', 'mother', "can't", 'ride', 'a', 'bicycle.'], 'ar': ['أمي', 'لا', 'يمكنها', 'ركوب', 'الدراجة.'] },
    { 'en': ['You', "aren't", 'a', 'spy,', 'are', 'you?'], 'ar': ['لست', 'جاسوساً', '،', 'أليس', 'كذلك؟'] },
    { 'en': ["I'll", 'make', 'you', 'happy.'], 'ar': ['سأجعلك', 'سعيدًا.'] },
    { 'en': ['When', 'will', 'she', 'return', 'home?'], 'ar': ['متى', 'ستعود', 'إلى', 'المنزل؟'] },
    { 'en': ['I', 'sometimes', 'watch', 'TV.'], 'ar': ['أشاهد', 'التلفاز', 'في', 'بعض', 'الأحيان.'] },
    { 'en': ['Tom', 'likes', 'to', 'read.'], 'ar': ['توم', 'يحب', 'أن', 'يقرأ'] },
    { 'en': ['He', 'likes', 'fishing.'], 'ar': ['إنه', 'يحب', 'الصيد.'] },
    { 'en': ['What', 'are', 'you', 'frightened', 'of?'], 'ar': ['من', 'ماذا', 'تخاف؟'] },
    { 'en': ['Were', 'you', 'sober', 'at', 'that', 'time?'], 'ar': ['هل', 'كنت', 'صاحٍ', 'في', 'ذلك', 'الوقت؟'] },
    { 'en': ['What', 'a', 'big', 'dog!'], 'ar': ['يا', 'له', 'من', 'كلب', 'كبير!'] },
    { 'en': ['Are', 'there', 'movies', 'on', 'the', 'plane?'], 'ar': ['هل', 'تُبث', 'أفلام', 'على', 'متن', 'هذه', 'الطّائرة؟'] },
    { 'en': ['Please', 'shuffle', 'the', 'cards', 'carefully.'], 'ar': ['من', 'فضلك', 'اخلط', 'الورق', 'جيداً.'] },
    { 'en': ['She', 'was', 'arrested', 'by', 'the', 'police.'], 'ar': ['قامت', 'الشرطة', 'باعتقالها.'] },
    { 'en': ["You'd", 'better', 'take', 'an', 'umbrella', 'with', 'you.'], 'ar': ['من', 'الأفضل', 'أن', 'تأخذ', 'مظلّة', 'معك.'] },
    { 'en': ["That's", 'certainly', 'one', 'possibility.'], 'ar': ['هذا', 'أحد', 'الاحتمالات', 'بالتأكيد'] },
    { 'en': ['Maybe', 'Tom', 'saw', 'something.'], 'ar': ['ربما', 'توم', 'رأى', 'شيئاً.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'pen', 'on', 'you?'], 'ar': ['هل', 'معك', 'قلم؟'] },
    { 'en': ["It's", 'my', "brother's."], 'ar': ['إنه', 'أخي.'] },
    { 'en': ['I', 'knew', 'it', 'was', 'you.'], 'ar': ['كنت', 'أعلم', 'أنه', 'أنت.'] },
    { 'en': ['You', "don't", 'have', 'to', 'make', 'an', 'apology.'], 'ar': ['ليس', 'عليك', 'الاعتذار.'] },
    { 'en': ['I', 'give', 'my', 'pledge', 'that', 'I', 'will', 'quit', 'smoking.'], 'ar': ['أقطع', 'وعدا', 'بأني', 'سأترك', 'التدخين.'] },
    { 'en': ['He', 'put', 'some', 'coins', 'in', 'the', 'box.'], 'ar': ['وضع', 'بعض', 'النقود', 'المعدنية', 'في', 'الصندوق.'] },
    { 'en': ['It', 'seems', 'right', 'to', 'me.'], 'ar': ['تبدو', 'صحيحة', 'بالنسبة', 'لي.'] },
    { 'en': ['He', 'gave', 'each', 'of', 'them', 'a', 'pencil.'], 'ar': ['أعطى', 'كل', 'واحد', 'منهم', 'قلم', 'رصاص.'] },
    { 'en': ['Who', 'invented', 'the', 'telephone?'], 'ar': ['من', 'اخترع', 'الهاتف؟'] },
    { 'en': ['Tomorrow', 'is', "Mother's", 'Day.'], 'ar': ['غداً', 'عيد', 'الأم.'] },
    { 'en': ["I'll", 'do', 'it', 'myself.'], 'ar': ['سأفعلها', 'بنفسي.'] },
    { 'en': ["I'm", 'fine,', 'thank', 'you.'], 'ar': ['أنا', 'بخير,', 'شكرا', 'لك'] },
    { 'en': ['He', 'always', 'tells', 'the', 'truth.'], 'ar': ['إنه', 'صادق', 'دائماً.'] },
    { 'en': ["I'm", 'so', 'happy.'], 'ar': ['أنا', 'سعيد', 'جداً.'] },
    { 'en': ["We're", 'out', 'of', 'sugar.'], 'ar': ['لم', 'يعد', 'عندنا', 'أي', 'سكر.'] },
    { 'en': ["We'll", 'protect', 'Tom.'], 'ar': ['سنحمي', 'توم.'] },
    { 'en': ["I'm", 'wasting', 'my', 'time.'], 'ar': ['أنا', 'أضيع', 'وقتي.'] },
    { 'en': ['I', "don't", 'feel', 'safe', 'here', 'anymore.'], 'ar': ['لم', 'أعد', 'أشعر', 'بالأمان', 'هنا.'] },
    { 'en': ['How', 'many', 'are', 'there', 'in', 'your', 'class?'], 'ar': ['كم', 'طالبًا', 'في', 'فصلك؟'] },
    { 'en': ['Did', 'you', 'call?'], 'ar': ['هل', 'اتصلت؟'] },
    { 'en': ['I', 'am', 'only', 'joking.'], 'ar': ['كنت', 'أمزح', 'فقط.'] },
    { 'en': ['This', 'bird', 'cannot', 'fly.'], 'ar': ['هذا', 'الطائر', 'لا', 'يمكنه', 'التحليق.'] },
    { 'en': ['Can', 'we', 'trust', 'you?'], 'ar': ['أيمكننا', 'الوثوق', 'بك؟'] },
    { 'en': ['I', 'am', 'happy.'], 'ar': ['أنا', 'سعيد'] },
    { 'en': ['He', 'measured', 'the', 'length', 'of', 'the', 'bed.'], 'ar': ['قاس', 'طول', 'السرير.'] },
    { 'en': ["What're", 'you', 'saying?'], 'ar': ['ماذا', 'تقول؟'] },
    { 'en': ['You', "shouldn't", 'leave', 'the', 'baby', 'alone.'], 'ar': ['عليك', 'أن', 'لا', 'تترك', 'الرضيع', 'وحده.'] },
    { 'en': ["Don't", 'worry', 'about', 'it.'], 'ar': ['لا', 'تقلق', 'بشأن', 'ذلك.'] },
    { 'en': ['They', 'are', 'about', 'the', 'same', 'age.'], 'ar': ['إنهم', 'في', 'نفس', 'العمر', 'تقريباً.'] },
    { 'en': ['A', 'rabbit', 'has', 'long', 'ears.'], 'ar': ['للأرنب', 'أذنان', 'طويلتان.'] },
    { 'en': ['What', 'a', 'team!'], 'ar': ['يا', 'له', 'من', 'فريق!'] },
    { 'en': ['You', 'broke', 'the', 'rules.'], 'ar': ['أنتِ', 'كسرتِ', 'القواعد.'] },
    { 'en': ['He', 'asked', 'a', 'few', 'questions', 'of', 'me.'], 'ar': ['سألني', 'بضعة', 'أسئلة.'] },
    { 'en': ['The', 'city', 'was', 'destroyed', 'by', 'fire.'], 'ar': ['دُمرت', 'المدينة', 'بسبب', 'الحريق.'] },
    { 'en': ['I', 'was', 'caught', 'in', 'a', 'traffic', 'jam.'], 'ar': ['لقد', 'علقت', 'في', 'ازدحام', 'مروري.'] },
    { 'en': ['How', 'could', 'you', 'not', 'notice?'], 'ar': ['كيف', 'أمكنك', 'تجاهل', 'ذلك؟'] },
    { 'en': ['This', 'is', 'a', 'very', 'important', 'meeting.'], 'ar': ['هذا', 'اجتماع', 'مهم.'] },
    { 'en': ['Shall', 'we', 'go', 'to', 'my', 'place', 'or', 'yours?'], 'ar': ['هل', 'نذهب', 'عندى', 'أم', 'عندك', '؟'] },
    { 'en': ['I', 'had', 'a', 'nap', 'for', 'about', 'an', 'hour.'], 'ar': ['أخذت', 'قيلولة', 'لمدة', 'ساعة.'] },
    { 'en': ['I', "don't", 'know', 'anything', 'yet.'], 'ar': ['أنا', 'لا', 'أعرف', 'أي', 'شئ', 'بعد.'] },
    { 'en': ['You', 'tried.'], 'ar': ['لقد', 'حاولت', 'وسعك.'] },
    { 'en': ['How', 'fascinating!'], 'ar': ['يا', 'للروعة!'] },
    { 'en': ['He', 'kicked', 'the', 'ball.'], 'ar': ['ركل', 'الكرة.'] },
    { 'en': ['You', 'may', 'come', 'if', 'you', 'like.'], 'ar': ['إن', 'أحببت،', 'فتعال!'] },
    { 'en': ['Tom', 'fell', 'down', 'on', 'the', 'floor.'], 'ar': ['سقط', 'توم', 'على', 'الأرض.'] },
    { 'en': ['What', 'does', 'your', 'aunt', 'do?'], 'ar': ['ما', 'مجال', 'عمل', 'خالتك؟'] },
    { 'en': ['But', 'I', "don't", 'want', 'to.'], 'ar': ['لكن', 'لا', 'أريد'] },
    { 'en': ['She', 'has', 'been', 'dead', 'for', 'ten', 'years.'], 'ar': ['مر', 'على', 'موتها', 'عشر', 'سنين.'] },
    { 'en': ['This', 'is', 'a', 'pun.'], 'ar': ['هذه', 'نكتة.'] },
    { 'en': ["I'm", 'behind', 'him.'], 'ar': ['إنني', 'وراءه.'] },
    { 'en': ["I'll", 'be', 'right', 'with', 'you.'], 'ar': ['سأكون', 'معك', 'حالًا.'] },
    { 'en': ['I', 'will', 'go', 'there', 'even', 'if', 'it', 'rains.'], 'ar': ['سأذهب', 'إلى', 'هناك', 'حتى', 'و', 'لو', 'أمطرت.'] },
    { 'en': ['I', 'am', 'from', 'Egypt.'], 'ar': ['أنا', 'من', 'مصر.'] },
    { 'en': ["I'm", 'not', 'sure', 'if', 'I', 'like', 'this', 'one', 'or', 'that', 'one.'], 'ar': ['لست', 'متأكدًا', 'مما', 'إذا', 'كنت', 'أفضل', 'هذا', 'أم', 'ذاك.'] },
    { 'en': ['Tell', 'me', 'the', 'truth.'], 'ar': ['أخبرني', 'الحقيقة.'] },
    { 'en': ["What's", 'your', 'occupation?'], 'ar': ['ما', 'مهنتك؟'] },
    { 'en': ['Maybe', 'Tom', 'has', 'something', 'to', 'hide.'], 'ar': ['ربما', 'توم', 'لديهُ', 'شيئاً', 'لإخفائه.'] },
    { 'en': ['It', 'can', 'be', 'used', 'as', 'a', 'knife.'], 'ar': ['يمكن', 'استخدامها', 'كسكين.'] },
    { 'en': ['You', "can't", 'do', 'two', 'things', 'at', 'once.'], 'ar': ['لا', 'يمكنك', 'أن', 'تفعل', 'شيئين', 'في', 'وقت', 'واحد.'] },
    { 'en': ['Did', 'you', 'see', 'the', "shooter's", 'face?'], 'ar': ['هل', 'رأيت', 'وجه', 'الرامي؟'] },
    { 'en': ['She', 'found', 'a', 'ball', 'in', 'the', 'garden.'], 'ar': ['وجدت', 'طابة', 'في', 'الحديقة.'] },
    { 'en': ["That's", 'something', 'you', 'need', 'to', 'remember.'], 'ar': ['هذا', 'أمر', 'عليك', 'تذكره.'] },
    { 'en': ['I', 'love', 'you.'], 'ar': ['أحبك.'] },
    { 'en': ["You're", 'not', 'dressed.'], 'ar': ['أنت', 'عار.'] },
    { 'en': ['The', 'sky', 'is', 'blue.'], 'ar': ['السماء', 'زرقاء.'] },
    { 'en': ['This', 'is', 'my', 'office.'], 'ar': ['هذا', 'مكتبي.'] },
    { 'en': ['You', 'were', 'busy.'], 'ar': ['كنتَ', 'مشغولاً.'] },
    { 'en': ['My', 'mother', 'hates', 'writing', 'letters.'], 'ar': ['تكره', 'أمي', 'كتابة', 'الرسائل.'] },
    { 'en': ['They', 'shouted', 'for', 'help.'], 'ar': ['صرخوا', 'طالبين', 'النجدة.'] },
    { 'en': ["I'm", 'alone.'], 'ar': ['أنا', 'لوحدي.'] },
    { 'en': ['First', 'things', 'first.'], 'ar': ['الأهم', 'فالمهم.'] },
    { 'en': ['He', 'is', 'very', 'handsome.'], 'ar': ['إنه', 'وسيم', 'جدا.'] },
    { 'en': ['Whose', 'paper', 'is', 'this?'], 'ar': ['ورقة', 'مَن', 'هذه؟'] },
    { 'en': ['Do', 'you', 'think', 'fish', 'can', 'hear?'], 'ar': ['هل', 'تظن', 'أن', 'السمك', 'يسمع؟'] },
    { 'en': ['The', 'conference', 'ended', 'at', 'five.'], 'ar': ['انتهى', 'المؤتمر', 'عند', 'الخامسة.'] },
    { 'en': ['When', 'can', 'I', 'see', 'you', 'again?'], 'ar': ['متى', 'يمكنني', 'أن', 'أراك', 'مجددا؟'] },
    { 'en': ['Can', 'you', 'gift-wrap', 'this,', 'please?'], 'ar': ['أيمكنك', 'أن', 'تغلّف', 'هذا', 'كالهدايا،', 'من', 'فضلك؟'] },
    { 'en': ["What's", 'that', 'bird', 'called?'], 'ar': ['ما', 'اسم', 'هذا', 'الطائر؟'] },
    { 'en': ['Better', 'late', 'than', 'never.'], 'ar': ['أن', 'تأتي', 'متأخرًا', 'خيرٌ', 'من', 'ألا', 'تأتي.'] },
    { 'en': ["I'm", 'the', 'killer.'], 'ar': ['أنا', 'القاتل.'] },
    { 'en': ['Well,', 'see', 'you', 'later.'], 'ar': ['أراك', 'لاحقاً.'] },
    { 'en': ['Her', 'disappointment', 'was', 'apparent', 'to', 'everyone.'], 'ar': ['خيبَةُ', 'أملها', 'كانت', 'جليّة', 'للجميع.'] },
    { 'en': ["Don't", 'worry', 'about', 'me.'], 'ar': ['لا', 'تقلق', 'علي'] },
    { 'en': ['This', 'tattoo', 'is', 'stupid.'], 'ar': ['هذا', 'الوشم', 'سخيف.'] },
    { 'en': ["Don't", 'cheat.'], 'ar': ['لا', 'تَغِش.'] },
    { 'en': ['Where', 'do', 'you', 'come', 'from?'], 'ar': ['من', 'أين', 'أنت؟'] },
    { 'en': ['I', 'helped', 'him', 'yesterday.'], 'ar': ['ساعدته', 'بالأمس.'] },
    { 'en': ['How', 'many', 'hours', 'of', 'sleep', 'do', 'you', 'need?'], 'ar': ['إلى', 'كم', 'ساعة', 'من', 'النوم', 'تحتاج؟'] },
    { 'en': ['Are', 'you', 'all', 'right', 'now?'], 'ar': ['هل', 'أنتَ', 'بخير', 'الآن؟'] },
    { 'en': ['If', 'I', 'were', 'you,', 'I', 'would', 'also', 'do', 'the', 'same.'], 'ar': ['لو', 'كنتُ', 'مكانك,', 'كنتُ', 'سأفعل', 'نفس', 'الشئ؟'] },
    { 'en': ['You', "mustn't", 'depend', 'on', 'others', 'for', 'help.'], 'ar': ['يجب', 'ألا', 'تعتمد', 'على', 'الآخرين', 'للمساعدة.'] },
    { 'en': ['There', 'are', 'many', 'factors.'], 'ar': ['هناك', 'عوامل', 'كثيرة.'] },
    { 'en': ['Do', 'you', 'have', 'one?'], 'ar': ['هل', 'لديك', 'واحد؟'] },
    { 'en': ["That's", 'a', 'great', 'idea.'], 'ar': ['إنها', 'فكرة', 'جيدة.'] },
    { 'en': ["Let's", 'speak', 'in', 'English.'], 'ar': ['لنتحدث', 'بالإنجليزية.'] },
    { 'en': ['You', 'look', 'tired.'], 'ar': ['تبدو', 'متعباً.'] },
    { 'en': ["I'll", 'stay', 'home', 'tomorrow.'], 'ar': ['سأبقى', 'بالبيت', 'غداً.'] },
    { 'en': ["I'm", 'busy.'], 'ar': ['أنا', 'مشغول.'] },
    { 'en': ["She's", 'my', 'sister.'], 'ar': ['إنها', 'أختي'] },
    { 'en': ['It', 'is', 'too', 'expensive.'], 'ar': ['إنها', 'غالية', 'جداً.'] },
    { 'en': ['My', 'uncle', 'lives', 'near', 'the', 'school.'], 'ar': ['يعيش', 'عمي', 'قرب', 'المدرسة.'] },
    { 'en': ["I'm", 'not', 'sure', 'if', 'this', 'is', 'correct.'], 'ar': ['أنا', 'لست', 'متأكداً', 'إن', 'كان', 'هذا', 'صحيحاً.'] },
    { 'en': ['Just', 'stand', 'there,', 'please.'], 'ar': ['قف', 'هناك', 'من', 'فضلك.'] },
    { 'en': ['Tom', "doesn't", 'like', 'cheese.'], 'ar': ['توم', 'لا', 'يحب', 'الجبنة.'] },
    { 'en': ["I'd", 'like', 'to', 'go.'], 'ar': ['أود', 'الذهاب.'] },
    { 'en': ['When', 'did', 'you', 'change', 'your', 'address?'], 'ar': ['متى', 'غيرت', 'عنوانك؟'] },
    { 'en': ["I'm", 'at', 'home.'], 'ar': ['أنا', 'في', 'البيت.'] },
    { 'en': ['They', 'sat', 'in', 'the', 'shade', 'of', 'a', 'tree.'], 'ar': ['جلسوا', 'تحت', 'ظل', 'شجرة.'] },
    { 'en': ['Please', 'come', 'as', 'soon', 'as', 'possible.'], 'ar': ['من', 'فضلك', 'تعال', 'بأسرع', 'ما', 'يمكن.'] },
    { 'en': ['She', 'was', 'unconscious', 'of', 'her', 'mistake.'], 'ar': ['لم', 'تدرك', 'خطأها.'] },
    { 'en': ['What', 'is', 'your', 'size?'], 'ar': ['ما', 'هو', 'قياسك؟'] },
    { 'en': ['There', 'is', 'little', 'milk', 'left', 'in', 'the', 'bottle.'], 'ar': ['بقي', 'القليل', 'من', 'الحليب', 'في', 'الزجاجة.'] },
    { 'en': ['Here', 'we', 'are.'], 'ar': ['ها', 'نحن', 'هنا'] },
    { 'en': ["I'm", 'going', 'to', 'go.'], 'ar': ['سأذهب', 'الآن.'] },
    { 'en': ["I'm", 'really', 'glad', 'to', 'hear', 'that.'], 'ar': ['أنا', 'مسرور', 'للغاية', 'لسماع', 'ذلك.'] },
    { 'en': ['Did', 'you', 'hear', 'the', 'news', 'on', 'the', 'radio?'], 'ar': ['هل', 'سمعت', 'الأخبار', 'على', 'المذياع؟'] },
    { 'en': ["I'm", 'ready', 'for', 'next', 'week.'], 'ar': ['أنا', 'مستعد', 'للأسبوع', 'المقبل.'] },
    { 'en': ['I', 'want', 'that', 'bag.'], 'ar': ['أريد', 'تلك', 'الحقيبة.'] },
    { 'en': ['I', 'know', 'where', 'Tom', 'works.'], 'ar': ['أنا', 'أعرف', 'أين', 'يعمل', 'توم.'] },
    { 'en': ['Stop', 'bothering', 'me!'], 'ar': ['كُفّ', 'عن', 'إزعاجي!'] },
    { 'en': ["I'm", 'on', 'the', 'list.'], 'ar': ['أنا', 'على', 'القائمة.'] },
    { 'en': ['He', 'is', 'lacking', 'in', 'experience.'], 'ar': ['هو', 'يفتقر', 'إلى', 'الخبرة'] },
    { 'en': ['I', 'like', 'you', 'very', 'much.'], 'ar': ['أنا', 'معجب', 'بك', 'كثيرًا.'] },
    { 'en': ['The', 'rain', 'changed', 'into', 'snow.'], 'ar': ['أصبح', 'المطر', 'ثلجاً.'] },
    { 'en': ['Cars', 'arrived', 'there', 'one', 'after', 'another.'], 'ar': ['السيارات', 'وصلت', 'واحدة', 'تلو', 'الأُخرى.'] },
    { 'en': ['I', 'think', 'so.'], 'ar': ['أعتقد', 'ذلك.'] },
    { 'en': ["I'm", 'counting', 'on', 'you.'], 'ar': ['أعتمد', 'عليك.'] },
    { 'en': ['Tom', 'read', 'a', 'book.'], 'ar': ['توم', 'يقرأ', 'كتاباً'] },
    { 'en': ['You', "won't", 'finish', 'this', 'project.'], 'ar': ['لن', 'تنهي', 'هذا', 'المشروع.'] },
    { 'en': ["I'll", 'take', 'the', 'yellow', 'one.'], 'ar': ['سآخذ', 'الأصفر.'] },
    { 'en': ['He', 'looks', 'strong.'], 'ar': ['يبدو', 'قوياً.'] },
    { 'en': ['The', 'peace', 'talks', 'failed', 'once', 'again.'], 'ar': ['فشلت', 'محادثات', 'السلام', 'مرة', 'أخرى.'] },
    { 'en': ['I', 'was', 'about', 'to', 'go', 'out', 'when', 'the', 'phone', 'rang.'], 'ar': ['كنت', 'على', 'وشك', 'الذهاب', 'عندما', 'رَنّ', 'الهاتف.'] },
    { 'en': ['Go', 'inside.'], 'ar': ['أُدخُلْ.'] },
    { 'en': ['All', 'of', 'my', 'friends', 'like', 'soccer.'], 'ar': ['جميع', 'أصحابي', 'يحبون', 'كرة', 'القدم.'] },
    { 'en': ['This', 'is', 'an', 'extremely', 'important', 'point.'], 'ar': ['هذه', 'نقطة', 'مهمة', 'جداً.'] },
    { 'en': ['The', 'bus', 'was', 'filled', 'to', 'capacity.'], 'ar': ['مُلئت', 'الحافلة.'] },
    { 'en': ['He', 'was', 'punished', 'for', 'his', 'crimes.'], 'ar': ['لقد', 'عوقب', 'على', 'جرائمه.'] },
    { 'en': ['I', 'will', 'always', 'remember', 'you.'], 'ar': ['سأتذكرك', 'دائما', '.'] },
    { 'en': ['Darwin', 'changed', 'the', 'world.'], 'ar': ['داروين', 'غيرَ', 'العالم.'] },
    { 'en': ['I', "didn't", 'have', 'to', 'do', 'anything.'], 'ar': ['لم', 'يكن', 'عليّ', 'فعل', 'شيء.'] },
    { 'en': ["I'll", 'try', 'to', 'shape', 'my', 'ideas', 'into', 'a', 'book.'], 'ar': ['سأحاول', 'وضع', 'أفكاري', 'في', 'كتاب.'] },
    { 'en': ['They', 'just', 'want', 'to', 'have', 'fun.'], 'ar': ['إنهم', 'يريدون', 'فقط', 'الحصول', 'على', 'المتعة.'] },
    { 'en': ['I', 'arrived', 'in', 'Tokyo', 'yesterday.'], 'ar': ['وصلت', 'إلى', 'طوكيو', 'البارحة.'] },
    { 'en': ['We', 'all', 'make', 'mistakes.'], 'ar': ['كلنا', 'نخطئ.'] },
    { 'en': ['Keep', 'children', 'away', 'from', 'medicine.'], 'ar': ['أبق', 'الدواء', 'بعيدا', 'عن', 'متناول', 'الأطفال.'] },
    { 'en': ['The', 'rain', 'lasted', 'four', 'days.'], 'ar': ['استمر', 'المطر', 'بالهطول', 'لمدة', 'أربعة', 'أيام.'] },
    { 'en': ['May', 'I', 'ask', 'your', 'name?'], 'ar': ['هل', 'أستطيع', 'أن', 'أسألك', 'عن', 'اسمك؟'] },
    { 'en': ['She', 'refused', 'to', 'accept', 'the', 'money.'], 'ar': ['رفضت', 'المال.'] },
    { 'en': ['Actions', 'speak', 'louder', 'than', 'words.'], 'ar': ['العبرةُ', 'بالأعمالِ', 'وليست', 'بالأقوال.'] },
    { 'en': ["I'd", 'prefer', 'meat', 'to', 'fish.'], 'ar': ['أفضّل', 'اللحم', 'على', 'السمك.'] },
    { 'en': ['What', 'is', 'the', 'meaning', 'of', 'this', 'word?'], 'ar': ['ما', 'معنى', 'هذه', 'الكلمة؟'] },
    { 'en': ['Go', 'back', 'to', 'your', 'seats.'], 'ar': ['عودوا', 'إلى', 'أماكنكم.'] },
    { 'en': ['I', 'know', 'what', 'I', 'like.'], 'ar': ['أنا', 'أعلم', 'ما', 'أحب'] },
    { 'en': ['I', 'wrote', 'a', 'letter', 'to', 'my', 'mother.'], 'ar': ['كتبت', 'إلى', 'أمي.'] },
    { 'en': ['Stop', 'playing', 'with', 'your', 'hair.'], 'ar': ['كُفّ', 'عن', 'اللعب', 'بشعرك.'] },
    { 'en': ['He', 'seems', 'tired.'], 'ar': ['يبدو', 'متعباً.'] },
    { 'en': ['She', 'looks', 'pretty', 'in', 'that', 'dress.'], 'ar': ['شكلها', 'فاتن', 'في', 'ذلك', 'الفستان.'] },
    { 'en': ['He', 'speaks', 'Portuguese.'], 'ar': ['هو', 'يتكلّم', 'البرتغاليّة.'] },
    { 'en': ['Do', 'as', 'you', 'want.'], 'ar': ['افعل', 'ما', 'تريد.'] },
    { 'en': ['Whatever', 'you', 'say.'], 'ar': ['الرأي', 'رأيك.'] },
    { 'en': ['He', "couldn't", 'understand', 'the', 'sentence.'], 'ar': ['لم', 'يستطع', 'فهم', 'الجملة.'] },
    { 'en': ["It's", 'not', 'my', 'fault.'], 'ar': ['ليس', 'ذنبي.'] },
    { 'en': ['I', 'usually', "don't", 'drink', 'coffee.'], 'ar': ['بالعادة', 'لا', 'أشرب', 'القهوة.'] },
    { 'en': ['Where', 'is', 'his', 'house?'], 'ar': ['أين', 'بيته؟'] },
    { 'en': ['Kindly', 'mind', 'your', 'own', 'business.'], 'ar': ['رجاء', 'اهتم', 'بأمورك.'] },
    { 'en': ['I', 'am', 'able', 'to', 'drive', 'a', 'car.'], 'ar': ['أستطيع', 'قيادة', 'السيارة.'] },
    { 'en': ['I', 'talked', 'to', 'her', 'for', 'an', 'hour.'], 'ar': ['تكلمت', 'معها', 'لمدة', 'ساعة.'] },
    { 'en': ['Did', 'you', 'think', 'about', 'it?'], 'ar': ['أفكّرت', 'في', 'الأمر؟'] },
    { 'en': ['I', 'have', 'been', 'here', 'for', 'a', 'week.'], 'ar': ['أنا', 'هنا', 'منذ', 'أسبوع.'] },
    { 'en': ["That's", 'the', 'stupidest', 'thing', "I've", 'ever', 'said.'], 'ar': ['هذا', 'أغبى', 'ما', 'قُلتُه', 'على', 'الإطلاق.'] },
    { 'en': ['We', 'were', 'all', 'present', 'at', 'her', 'wedding.'], 'ar': ['كلنا', 'حضرنا', 'حفل', 'زفافها.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Tokyo.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'طوكيو.'] },
    { 'en': ['Who', 'is', 'that', 'man?'], 'ar': ['من', 'ذاك', 'الرجل؟'] },
    { 'en': ['You', 'must', 'go.'], 'ar': ['عليك', 'أن', 'تذهب.'] },
    { 'en': ['The', 'new', 'method', 'was', 'anything', 'but', 'ideal.'], 'ar': ['الطريقة', 'الجديدة', 'لم', 'تكن', 'مثاليةً', 'البتة.'] },
    { 'en': ['Does', 'the', 'story', 'have', 'a', 'happy', 'ending?'], 'ar': ['أللقصّة', 'نهاية', 'سعيدة؟'] },
    { 'en': ['I', "don't", 'feel', 'like', 'eating', 'anything', 'today.'], 'ar': ['لا', 'أشعر', 'بتناول', 'أي', 'طعامٍ', 'اليوم.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['كيف', 'تعرفين', 'هذا؟'] },
    { 'en': ['I', 'dislike', 'being', 'alone.'], 'ar': ['لا', 'أحب', 'أن', 'أكون', 'وحدي.'] },
    { 'en': ['I', "don't", 'believe', 'you.', "You're", 'always', 'lying.'], 'ar': ['أنا', 'لا', 'أصدقك,', 'أنت', 'تكذب', 'دائماً.'] },
    { 'en': ['How', 'high', 'is', 'it?'], 'ar': ['ما', 'مدي', 'ارتفاعها', '؟'] },
    { 'en': ['You', 'are', 'rich.'], 'ar': ['أنت', 'غنيّ.'] },
    { 'en': ['I', 'think', 'Tom', 'likes', 'me.'], 'ar': ['أظن', 'بأن', 'توم', 'معجب', 'بي.'] },
    { 'en': ['You', 'must', 'be', 'kidding!'], 'ar': ['لا', 'بد', 'أنك', 'تمزح!'] },
    { 'en': ['Tom', 'knows', 'that', 'Mary', 'loves', 'him.'], 'ar': ['يعلم', 'توم', 'بحبّ', 'ماري', 'له.'] },
    { 'en': ["It's", 'possible', 'that', 'she', "won't", 'come.'], 'ar': ['من', 'الممكن', 'أن', 'لا', 'تأتي.'] },
    { 'en': ['I', 'need', 'to', 'charge', 'my', 'cell', 'phone.'], 'ar': ['علي', 'شحن', 'هاتفي', 'الجوال.'] },
    { 'en': ['She', 'bent', 'down.'], 'ar': ['إنحنت.'] },
    { 'en': ['She', 'loves', 'Tom.'], 'ar': ['إنها', 'تعشق', 'توم.'] },
    { 'en': ['What', 'does', 'Tom', 'think', 'about', 'this?'], 'ar': ['ما', 'رأي', 'توم', 'حيال', 'هذا؟'] },
    { 'en': ['I', 'received', 'your', 'letter', 'yesterday.'], 'ar': ['وصلتني', 'رسالتك', 'بالأمس.'] },
    { 'en': ['He', 'was', 'accused', 'of', 'stealing', 'dinosaur', 'bones.'], 'ar': ['لقد', 'أُتُهم', 'بسرقة', 'عظام', 'الديناصورات.'] },
    { 'en': ['You', 'are', 'doing', 'very', 'well.'], 'ar': ['إنك', 'تبلي', 'حسناً.'] },
    { 'en': ["I'm", 'sorry,', 'but', "it's", 'impossible.'], 'ar': ['أنا', 'آسف', 'لكن', 'ذلك', 'مستحيل.'] },
    { 'en': ['The', 'boy', 'remained', 'silent.'], 'ar': ['ظل', 'الولد', 'ساكناً.'] },
    { 'en': ['Could', 'you', 'tackle', 'this?'], 'ar': ['هل', 'يمكنك', 'معالجة', 'هذا', 'الامر؟'] },
    { 'en': ['Do', 'you', 'want', 'me', 'to', 'help', 'you', 'do', 'that?'], 'ar': ['أتريد', 'مني', 'مساعدتك', 'في', 'ذلك؟'] },
    { 'en': ['Did', 'he', 'come', 'by', 'bus', 'or', 'by', 'train?'], 'ar': ['هل', 'أتى', 'بالباص', 'أم', 'بالقطار؟'] },
    { 'en': ['The', 'door', 'is', 'closed.'], 'ar': ['الباب', 'مغلق.'] },
    { 'en': ["It's", 'very', 'cold.'], 'ar': ['الجو', 'بارد', 'جداً.'] },
    { 'en': ["Tom's", 'horse', 'reared', 'suddenly', 'and', 'threw', 'him.'], 'ar': ['شبَ', 'حصان', 'توم', 'فجأة', 'و', 'القاه', 'ارضاً.'] },
    { 'en': ['My', 'legs', 'still', 'hurt.'], 'ar': ['ما', 'زالت', 'قدماي', 'تؤلمانني.'] },
    { 'en': ["You're", 'not', 'ready', 'yet.'], 'ar': ['أنتِ', 'لستِ', 'جاهزةً', 'بعد.'] },
    { 'en': ['Could', 'I', 'have', 'a', 'glass', 'of', 'white', 'wine?'], 'ar': ['هل', 'أستطيع', 'الحصول', 'على', 'كأس', 'من', 'النبيذ', 'الأبيض؟'] },
    { 'en': ['What', 'do', 'you', 'want?'], 'ar': ['ما', 'الذي', 'تريده؟'] },
    { 'en': ['There', 'is', 'nothing', 'to', 'apologize', 'for.'], 'ar': ['لا', 'يوجد', 'ما', 'يدعو', 'للإعتذار.'] },
    { 'en': ['I', 'want', 'to', 'tell', 'you', 'what', 'happened.'], 'ar': ['أريد', 'أن', 'أخبرك', 'ما', 'حدث.'] },
    { 'en': ['Continue', 'working.'], 'ar': ['واصِل', 'العمل.'] },
    { 'en': ['He', 'witnessed', 'the', 'murder.'], 'ar': ['شهد', 'حصول', 'الجريمة.'] },
    { 'en': ['Japanese', 'tourists', 'abroad', 'are', 'big', 'spenders.'], 'ar': ['السياح', 'اليابانيون', 'في', 'الخارج', 'هم', 'اكبر', 'منفقي', 'النقود', '.'] },
    { 'en': ['I', 'have', 'one', 'brother.'], 'ar': ['لي', 'أخ', 'وحيد.'] },
    { 'en': ['Bees', 'fly', 'from', 'flower', 'to', 'flower.'], 'ar': ['تطير', 'النحلات', 'من', 'زهرة', 'إلى', 'زهرة.'] },
    { 'en': ['I', 'eagerly', 'await', 'your', 'decision.'], 'ar': ['أنا', 'أنتظر', 'قرارك', 'على', 'أحر', 'من', 'الجمر.'] },
    { 'en': ['You', 'knew', 'them,', "didn't", 'you?'], 'ar': ['أنت', 'عرفتهم.', 'أليس', 'كذلك؟'] },
    { 'en': ['Look', 'again.'], 'ar': ['انظر', 'مرة', 'أخرى'] },
    { 'en': ['Without', 'you,', "I'm", 'nothing.'], 'ar': ['بدونك', 'أنا', 'لا', 'شيء.'] },
    { 'en': ["I'm", 'serious.'], 'ar': ['أنا', 'لا', 'أمزح.'] },
    { 'en': ['Thanks', 'for', 'the', 'drink.'], 'ar': ['شكرا', 'على', 'الشراب'] },
    { 'en': ['This', 'is', 'one', 'of', "Tom's", 'favorite', 'books.'], 'ar': ['هذا', 'أحد', 'كتب', 'توم', 'المفضلة.'] },
    { 'en': ['She', 'finally', 'found', 'her', 'missing', 'keys.'], 'ar': ['وأخيرًا', 'وجدت', 'مفاتيحها', 'المفقودة.'] },
    { 'en': ['Do', 'you', 'play', 'tennis?'], 'ar': ['أتلعب', 'التنس؟'] },
    { 'en': ['The', 'dog', 'was', 'dead.'], 'ar': ['كان', 'الكلب', 'ميتاً.'] },
    { 'en': ['You', 'are', 'the', 'only', 'one.'], 'ar': ['أنت', 'الشخص', 'الوحيد.'] },
    { 'en': ['For', 'me,', "it's", 'important.'], 'ar': ['بالنسبة', 'لي،', 'إنه', 'مهم.'] },
    { 'en': ['That', 'child', 'was', 'left', 'in', 'the', 'sun', 'too', 'long.'], 'ar': ['تُرك', 'ذاك', 'الطفل', 'في', 'الشمس', 'مدة', 'طويلة', 'للغاية.'] },
    { 'en': ['Tom', 'is', 'angry', 'at', 'Mary.'], 'ar': ['توم', 'غاضب', 'على', 'مارى.'] },
    { 'en': ['Children', "don't", 'need', 'their', 'own', 'rooms.'], 'ar': ['لا', 'يحتاج', 'الأطفال', 'غرفًا', 'خاصة', 'بهم.'] },
    { 'en': ['The', 'boy', 'captured', 'the', 'bird', 'with', 'a', 'net.'], 'ar': ['اصطاد', 'الولد', 'الطائر', 'بالشبكة.'] },
    { 'en': ['It', 'is', 'no', 'use', 'trying', 'to', 'solve', 'this', 'problem.'], 'ar': ['لا', 'فائدة', 'من', 'محاولة', 'حل', 'هذه', 'المسألة.'] },
    { 'en': ['Why', 'are', 'you', 'in', 'such', 'a', 'hurry?'], 'ar': ['لماذا', 'أنت', 'في', 'عجلة', 'من', 'أمرك', 'هكذا؟'] },
    { 'en': ['Was', 'he', 'in', 'Hokkaido', 'last', 'year?'], 'ar': ['هل', 'كان', 'في', 'هوكّايدو', 'العام', 'الماضي؟'] },
    { 'en': ['Who', 'coaches', 'the', 'team?'], 'ar': ['من', 'يدرب', 'الفريق؟'] },
    { 'en': ['Absence', 'makes', 'the', 'heart', 'grow', 'fonder.'], 'ar': ['البُعد', 'يزيد', 'القلب', 'ولوعا.'] },
    { 'en': ["I'll", 'decide.'], 'ar': ['سأقرر.'] },
    { 'en': ['You', "don't", 'need', 'to', 'work', 'today.'], 'ar': ['لا', 'يتوجب', 'عليك', 'العمل', 'اليوم.'] },
    { 'en': ['I', "wasn't", 'there.'], 'ar': ['لم', 'أَكُنْ', 'هُناكْ.'] },
    { 'en': ['I', 'heard', 'everything', 'clearly.'], 'ar': ['سمعت', 'كل', 'شيء', 'بوضوح.'] },
    { 'en': ['I', "didn't", 'run', 'away.'], 'ar': ['لم', 'أهرب.'] },
    { 'en': ['She', 'has', 'a', 'son', 'and', 'a', 'daughter.'], 'ar': ['لديها', 'ابن', 'وابنة.'] },
    { 'en': ['I', 'gave', 'my', 'father', 'a', 'silk', 'tie.'], 'ar': ['أعطيتُ', 'أبي', 'ربطة', 'عنق', 'حريرية.'] },
    { 'en': ['The', 'book', 'is', 'easy.'], 'ar': ['الكتابُ', 'سهلٌ.'] },
    { 'en': ['That', 'was', 'the', 'best', 'day', 'of', 'my', 'life.'], 'ar': ['كان', 'ذلك', 'أجمل', 'يوم', 'في', 'حياتي.'] },
    { 'en': ["I'm", 'just', 'like', 'you.'], 'ar': ['أنا', 'مثلك', 'تماما.'] },
    { 'en': ['She', 'painted', 'the', 'wall', 'red.'], 'ar': ['طلت', 'الحائط', 'باللون', 'الأحمر.'] },
    { 'en': ['She', 'has', 'hundreds', 'of', 'books.'], 'ar': ['لديها', 'المئات', 'من', 'الكتب.'] },
    { 'en': ["I'll", 'stay', 'home.'], 'ar': ['سأبقى', 'في', 'البيت.'] },
    { 'en': ['I', 'still', "haven't", 'learned', 'to', 'drive', 'a', 'car.'], 'ar': ['لم', 'أتعلم', 'كيف', 'أقود', 'السيارة', 'بعد.'] },
    { 'en': ['I', "can't", 'stay', 'long', 'today.'], 'ar': ['لا', 'يمكنني', 'البقاء', 'طويلا', 'اليوم.'] },
    { 'en': ['The', 'girl', 'looked', 'around.'], 'ar': ['نظرت', 'الفتاة', 'حولها.'] },
    { 'en': ['It', 'was', 'a', 'stupid', 'mistake.'], 'ar': ['كان', 'خطأً', 'غبياً.'] },
    { 'en': ['He', 'is', 'my', "brother's", 'friend.'], 'ar': ['إنهُ', 'صديق', 'أخي.'] },
    { 'en': ['Welcome.'], 'ar': ['أهلاً', 'و', 'سهلاً!'] },
    { 'en': ['DNA', 'tests', 'showed', 'he', 'was', 'innocent.'], 'ar': ['إختبارات', 'الحمض', 'النووي', 'أظهرت', 'أنه', 'برئ.'] },
    { 'en': ["Nobody's", 'perfect.'], 'ar': ['لا', 'يوجد', 'شخص', 'معصوم', 'من', 'الخطأ.'] },
    { 'en': ['Tom,', "what's", 'wrong', 'with', 'you', 'tonight?'], 'ar': ['ما', 'خطبك', 'الليلة', 'يا', 'توم', '؟'] },
    { 'en': ['My', 'son', 'is', 'a', 'rebellious', 'teenager.'], 'ar': ['ابني', 'مراهق', 'متمرد.'] },
    { 'en': ['It', 'is', 'impossible', 'to', 'live', 'without', 'water.'], 'ar': ['من', 'المستحيل', 'العيش', 'بدون', 'ماء.'] },
    { 'en': ['Tom', "doesn't", 'look', 'convinced.'], 'ar': ['لا', 'يبدو', 'على', 'توم', 'أنه', 'مقتنعٌ.'] },
    { 'en': ['They', 'hated', 'each', 'other.'], 'ar': ['يكرهان', 'بعضهما', 'البعض.'] },
    { 'en': ['My', 'older', 'sister', 'often', 'drinks', 'tea', 'with', 'milk.'], 'ar': ['عادةً', 'ما', 'تشرب', 'أختي', 'الكبيرة', 'الشاي', 'مع', 'الحليب.'] },
    { 'en': ['Tom', 'likes', 'tea', 'better', 'than', 'coffee.'], 'ar': ['توم', 'يحب', 'الشاي', 'أكثر', 'من', 'القهوة.'] },
    { 'en': ["I'll", 'get', 'off', 'at', 'the', 'next', 'station.'], 'ar': ['سأنزل', 'في', 'المحطة', 'القادمة.'] },
    { 'en': ['She', 'answered', 'in', 'tears.'], 'ar': ['أجابت', 'باكيةً.'] },
    { 'en': ["I'll", 'wait', 'outside.'], 'ar': ['سأنتظر', 'في', 'الخارج.'] },
    { 'en': ['At', 'last,', 'he', 'came.'], 'ar': ['و', 'أخيراً', 'أتى.'] },
    { 'en': ['There', 'is', 'a', 'fence', 'around', 'the', 'house.'], 'ar': ['هناك', 'سياج', 'حول', 'المنزل.'] },
    { 'en': ['Three', 'bodies', 'were', 'found', 'in', 'the', 'cave.'], 'ar': ['عُثرت', 'على', 'ثلاث', 'حثث', 'في', 'الكهف.'] },
    { 'en': ['We', "don't", 'know', 'where', "we'll", 'live', 'next', 'year.'], 'ar': ['نحن', 'لا', 'نعرف', 'اين', 'سنعيش', 'في', 'العام', 'المقبل'] },
    { 'en': ['Shake', 'the', 'bottle', 'before', 'using.'], 'ar': ['رج', 'العبوة', 'قبل', 'استخدامها.'] },
    { 'en': ['I', "didn't", 'know', 'that', 'she', 'was', 'ill.'], 'ar': ['لم', 'أكن', 'على', 'علم', 'بمرضها.'] },
    { 'en': ['He', 'died', 'fighting', 'in', 'the', 'Vietnam', 'War.'], 'ar': ['لقد', 'توفي', 'وهو', 'يقاتل', 'في', 'حرب', 'فيتنام.'] },
    { 'en': ['The', 'store', 'was', 'not', 'a', 'big', 'one,', 'was', 'it?'], 'ar': ['لم', 'يكن', 'المخزن', 'كبيراً،', 'أليس', 'كذلك؟'] },
    { 'en': ['Tom', "doesn't", 'have', 'either', 'a', 'cat', 'or', 'a', 'dog.'], 'ar': ['ليس', 'لتوم', 'هرّ', 'ولا', 'كلب.'] },
    { 'en': ['You', 'should', 'fix', 'your', 'tie.'], 'ar': ['عليك', 'إصلاح', 'ربطة', 'عنقك.'] },
    { 'en': ['Can', 'you', 'write', 'a', 'letter', 'in', 'English?'], 'ar': ['أيمكنك', 'كتابة', 'الرسالة', 'بالإنجليزية؟'] },
    { 'en': ["That's", 'what', 'women', 'want.'], 'ar': ['هذا', 'ما', 'تريده', 'النّساء.'] },
    { 'en': ['I', 'would', 'like', 'to', 'go', 'to', 'Japan.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'اليابان.'] },
    { 'en': ['Who', 'is', 'playing', 'the', 'guitar?'], 'ar': ['من', 'يعزف', 'على', 'الجيتار؟'] },
    { 'en': ["I'm", 'giving', 'up', 'smoking.'], 'ar': ['تركت', 'التدخين.'] },
    { 'en': ['This', 'knife', 'is', 'very', 'sharp.'], 'ar': ['هذه', 'السكين', 'حادة', 'جداً.'] },
    { 'en': ["There's", 'an', 'emergency', 'exit', 'in', 'the', 'rear.'], 'ar': ['هنالك', 'مخرج', 'طوارئ', 'في', 'الخلف', '.'] },
    { 'en': ['My', 'father', 'goes', 'to', 'work', 'by', 'bike.'], 'ar': ['يذهب', 'أبي', 'إلى', 'العمل', 'بالدراجة.'] },
    { 'en': ['She', 'refused', 'his', 'offer.'], 'ar': ['رفضت', 'عرضه.'] },
    { 'en': ['Do', 'you', 'think', 'this', 'book', 'is', 'worth', 'reading?'], 'ar': ['هل', 'تعتقد', 'أن', 'هذا', 'الكتاب', 'يستحق', 'القراءة؟'] },
    { 'en': ['He', 'agrees', 'with', 'my', 'opinion.'], 'ar': ['إنه', 'يوافقني', 'الرأي.'] },
    { 'en': ['Speak', 'up!'], 'ar': ['تكلم!'] },
    { 'en': ['I', 'love', 'her.'], 'ar': ['أحبها.'] },
    { 'en': ["I'm", 'going', 'to', 'stay', 'home', 'tonight.'], 'ar': ['سأبقی', 'في', 'المنزل', 'الليلة.'] },
    { 'en': ['Tom', 'and', 'Mary', 'went', 'to', 'the', 'beach.'], 'ar': ['ذهب', 'توم', 'وماري', 'إلى', 'الشاطئ.'] },
    { 'en': ['When', 'does', 'the', 'bank', 'open?'], 'ar': ['متى', 'يفتح', 'المصرف؟'] },
    { 'en': ['The', 'hotel', 'was', 'burned', 'down.'], 'ar': ['احترق', 'الفندق.'] },
    { 'en': ['Everyone', 'is', 'still', 'smiling.'], 'ar': ['لا', 'يزال', 'الجميع', 'يبتسم.'] },
    { 'en': ['Will', 'we', 'be', 'in', 'time', 'for', 'the', 'train?'], 'ar': ['هل', 'سنلحق', 'بالقطار؟'] },
    { 'en': ["It's", 'your', 'turn.'], 'ar': ['إنه', 'دورك.'] },
    { 'en': ['They', 'are', 'happy.'], 'ar': ['هم', 'سعداء'] },
    { 'en': ['Father', 'is', 'coming', 'home', 'tomorrow.'], 'ar': ['الأب', 'عائد', 'إلى', 'البيت', 'غداَ.'] },
    { 'en': ['That', 'is', 'his', 'car.'], 'ar': ['تلك', 'سيارته.'] },
    { 'en': ['Generally', 'speaking,', 'savings', 'are', 'increasing.'], 'ar': ['عموماً', '،', 'مدّخرات', 'الأفراد', 'في', 'اِزدياد.'] },
    { 'en': ['My', 'favorite', 'music', 'is', 'pop', 'music.'], 'ar': ['موسيقايَ', 'المضلة', 'هي', 'موسيقى', 'البوب.'] },
    { 'en': ['Tom', 'can', 'stay', 'if', 'he', 'wants', 'to.'], 'ar': ['يمكن', 'لتوم', 'البقاء', 'إن', 'رغب.'] },
    { 'en': ['We', 'enjoyed', 'playing', 'baseball.'], 'ar': ['أمضينا', 'وقتاً', 'ممتعاً', 'و', 'نحن', 'نلعب', 'كرة', 'القاعدة.'] },
    { 'en': ['They', 'might', 'hear', 'us.'], 'ar': ['قد', 'يسمعانا.'] },
    { 'en': ['My', 'sister', 'is', 'a', 'very', 'good', 'typist.'], 'ar': ['أُختي', 'طابِعة', 'جيدة', 'جداً.'] },
    { 'en': ['I', 'have', 'a', 'cold.'], 'ar': ['لدي', 'زكام.'] },
    { 'en': ['Where', 'are', 'we?'], 'ar': ['أين', 'نحن؟'] },
    { 'en': ["Don't", 'trust', 'anybody', 'but', 'yourself.'], 'ar': ['لا', 'تثق', 'بأحد', 'عدى', 'نفسك.'] },
    { 'en': ["It's", 'lunch', 'time.'], 'ar': ['إنه', 'وقت', 'الغداء.'] },
    { 'en': ["Don't", 'forget', 'rule', 'number', 'seventy-two.'], 'ar': ['لا', 'تنسى', 'القاعدة', 'رقم', 'إثنان', 'وسبعون.'] },
    { 'en': ["Tom's", 'the', 'best.'], 'ar': ['توم', 'هو', 'الأفضل.'] },
    { 'en': ['Tom', 'just', 'loves', 'pineapple', 'upside-down', 'cake.'], 'ar': ['يحب', 'توم', 'كعكة', 'الأناناس', 'المقلوبة', 'كثيرا.'] },
    { 'en': ['This', 'book', 'was', 'easy.'], 'ar': ['كان', 'ذاك', 'الكتاب', 'سهلاً.'] },
    { 'en': ['Mary', 'likes', 'watching', 'TV.'], 'ar': ['تحب', 'ماري', 'مشاهدة', 'التلفاز.'] },
    { 'en': ['I', "don't", 'doubt', 'his', 'ability', 'to', 'do', 'it.'], 'ar': ['لا', 'أشك', 'في', 'قدرته', 'على', 'فعلها.'] },
    { 'en': ['Why', "don't", 'you', 'come', 'visit', 'us?'], 'ar': ['لم', 'لا', 'تأتِ', 'لزيارتنا؟'] },
    { 'en': ['My', 'aunt', 'was', 'pleased', 'with', 'my', 'success.'], 'ar': ['أُعجبت', 'عمتي', 'بنجاحي.'] },
    { 'en': ['It', 'is', 'cold.'], 'ar': ['الجو', 'بارد.'] },
    { 'en': ['Mary', 'slapped', 'Tom.'], 'ar': ['ميري', 'صفعت', 'توم.'] },
    { 'en': ['I', 'studied', 'for', 'one', 'hour.'], 'ar': ['ذاكرت', 'ساعة.'] },
    { 'en': ["I'll", 'think', 'about', 'it.'], 'ar': ['سأفكّر', 'في', 'الأمر.'] },
    { 'en': ['Excuse', 'me,', "I'm", 'lost.'], 'ar': ['لو', 'سمحت،', 'أضعت', 'طريقي.\u200f\u200f'] },
    { 'en': ['I', 'swim', 'once', 'a', 'week.'], 'ar': ['أسبح', 'مرةً', 'في', 'الأسبوع.'] },
    { 'en': ['Thanks', 'for', 'your', 'explanation.'], 'ar': ['أشكرك', 'لأنك', 'وضّحت', 'لي', 'ذلك.'] },
    { 'en': ['Where', 'do', 'you', 'live?'], 'ar': ['أين', 'تسكن؟'] },
    { 'en': ['The', 'station', 'is', 'not', 'far', 'from', 'here.'], 'ar': ['لا', 'تبعد', 'المحطة', 'كثيراً', 'عن', 'هنا.'] },
    { 'en': ['Tom', 'always', 'did', 'his', 'best', 'to', 'help', 'Mary.'], 'ar': ['على', 'الدوام', 'قدم', 'توم', 'أفضل', 'ما', 'يستطيع', 'لمساعدة', 'ماري.'] },
    { 'en': ['You', 'must', 'return', 'the', 'book', 'to', 'him.'], 'ar': ['يجب', 'عليك', 'أن', 'تعيد', 'الكتاب', 'إليه.'] },
    { 'en': ['The', 'car', 'is', 'ready.'], 'ar': ['السيارة', 'جاهزة.'] },
    { 'en': ['The', 'grass', 'needs', 'cutting.'], 'ar': ['يحتاج', 'العشب', 'إلى', 'التشذيب.'] },
    { 'en': ["They've", 'already', 'eaten.'], 'ar': ['قد', 'سبق', 'وأكلوا.'] },
    { 'en': ['When', 'did', 'you', 'come?'], 'ar': ['متىَ', 'أتيتَ؟'] },
    { 'en': ["I'm", 'really', 'hungry.'], 'ar': ['أنا', 'جائعٌ', 'للغاية.'] },
    { 'en': ['Everybody', 'loves', 'music.'], 'ar': ['الجميع', 'يحبون', 'الموسيقى.'] },
    { 'en': ['Please', "don't", 'talk', 'about', 'me', 'when', "I'm", 'gone.'], 'ar': ['من', 'فضلك', 'لا', 'تتحدث', 'عني', 'أثناء', 'غيابي.'] },
    { 'en': ["Let's", 'not', 'discuss', 'it.'], 'ar': ['لنتوقف', 'عن', 'النقاش', 'في', 'ذلك.'] },
    { 'en': ['My', 'grandfather', 'was', 'born', 'in', '1920.'], 'ar': ['ولد', 'جدي', 'عام', '١٩٢٠م.'] },
    { 'en': ['Get', 'me', 'the', 'scissors,', 'please.'], 'ar': ['أحضر', 'لي', 'المقص', 'من', 'فضلك.'] },
    { 'en': ['You', "can't", 'do', 'two', 'things', 'at', 'once.'], 'ar': ['لا', 'يمكنك', 'أن', 'تفعل', 'شيئين', 'في', 'الوقت', 'ذاته.'] },
    { 'en': ['Tom', 'hurt', 'himself.'], 'ar': ['آذى', 'توم', 'نفسه.'] },
    { 'en': ['He', 'gave', 'me', 'a', 'wink.'], 'ar': ['غمز', 'لي.'] },
    { 'en': ['Why', 'did', 'you', 'keep', 'that', 'a', 'secret?'], 'ar': ['لمَ', 'أبقيت', 'هذا', 'سرًّا؟'] },
    { 'en': ['Tom', 'is', 'losing', 'blood.'], 'ar': ['توم', 'يفقد', 'كثيرا', 'من', 'الدم.'] },
    { 'en': ['I', 'understand', 'what', 'you', 'mean.'], 'ar': ['أفهم', 'قصدك.'] },
    { 'en': ['This', 'is', 'her', 'pen.'], 'ar': ['هذا', 'قلمها.'] },
    { 'en': ['I', "don't", 'remember', 'where', 'I', 'bought', 'it.'], 'ar': ['لا', 'أذكر', 'أين', 'اشتريتها.'] },
    { 'en': ['This', 'book', 'is', 'new.'], 'ar': ['هذا', 'الكتاب', 'جديد'] },
    { 'en': ['Tell', 'me', 'what', 'happened.'], 'ar': ['أخبرني', '،', 'ما', 'الذي', 'حدث؟'] },
    { 'en': ['I', 'forgot', 'my', 'email', 'address.'], 'ar': ['أنا', 'نسيت', 'عنوان', 'بريدي', 'الإلكتروني.'] },
    { 'en': ['I', 'can', 'see', 'what', 'you', 'mean.'], 'ar': ['أفهم', 'قصدك.'] },
    { 'en': ['These', 'fireworks', 'are', 'spectacular!'], 'ar': ['هذه', 'الألعاب', 'النارية', 'رائعة!'] },
    { 'en': ['Pollution', 'is', 'damaging', 'our', 'earth.'], 'ar': ['التلوث', 'يضر', 'كوكبنا.'] },
    { 'en': ['You', 'have', 'better', 'sight', 'than', 'me.'], 'ar': ['أنتِ', 'لديكِ', 'رؤية', 'أفضل', 'منى؟'] },
    { 'en': ['Will', 'it', 'rain', 'tomorrow?'], 'ar': ['هل', 'ستمطر', 'غداً؟'] },
    { 'en': ["Let's", 'hurry', 'up.'], 'ar': ['لنسرع.'] },
    { 'en': ['Pick', 'up', 'your', 'things', 'and', 'go', 'away.'], 'ar': ['اجمع', 'أغراضك', 'وغادر.'] },
    { 'en': ['Take', 'me', 'with', 'you.'], 'ar': ['خذني', 'معك.'] },
    { 'en': ['I', 'liked', 'the', 'room', 'overlooking', 'the', 'valley.'], 'ar': ['أعجبتني', 'الغرفة', 'المطلة', 'على', 'الوادي.'] },
    { 'en': ['Here', 'is', 'your', 'bag.'], 'ar': ['ها', 'هي', 'حقيبتك.'] },
    { 'en': ['Almost', 'everybody', 'here', 'speaks', 'French.'], 'ar': ['يتحدث', 'أغلب', 'الناس', 'هنا', 'الفرنسية.'] },
    { 'en': ['She', 'advised', 'him', 'not', 'to', 'use', 'too', 'much', 'sugar.'], 'ar': ['نصحته', 'ألا', 'يضع', 'الكثير', 'من', 'السكر.'] },
    { 'en': ['What', 'do', 'you', 'think', "you're", 'doing?'], 'ar': ['ماذا', 'تظن', 'نفسك', 'فاعلاً؟'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'bed.'], 'ar': ['عليّ', 'الذهاب', 'إلى', 'النوم.'] },
    { 'en': ['He', 'comes', 'every', 'day', 'to', 'visit', 'his', 'sick', 'friend.'], 'ar': ['يأتي', 'كل', 'يوم', 'لزيارة', 'صديقه', 'المريض.'] },
    { 'en': ['Who', 'is', 'the', 'boss', 'of', 'this', 'company?'], 'ar': ['من', 'يرأس', 'هذه', 'الشركة؟'] },
    { 'en': ['Did', 'you', 'taste', 'the', 'rice?'], 'ar': ['هل', 'تذوقتَ', 'الأرز؟'] },
    { 'en': ['Do', 'you', 'really', 'want', 'me', 'to', 'do', 'that?'], 'ar': ['هل', 'تريديني', 'حقاً', 'أن', 'أفعل', 'هذا؟'] },
    { 'en': ['A', 'tea', 'with', 'lemon,', 'please.'], 'ar': ['شاي', 'بالليمون،', 'لو', 'سمحت.'] },
    { 'en': ['She', 'was', 'wearing', 'dark', 'brown', 'shoes.'], 'ar': ['كانت', 'ترتدي', 'حذاءً', 'ذا', 'لون', 'بني', 'غامق.'] },
    { 'en': ['Tom', 'looked', 'like', 'he', 'was', 'rich.'], 'ar': ['بدا', 'توم', 'وكأنه', 'ثري.'] },
    { 'en': ['Tom', 'is', 'happy.'], 'ar': ['توم', 'سعيد'] },
    { 'en': ['How', 'embarrassing!'], 'ar': ['كم', 'هذا', 'محرج!'] },
    { 'en': ['My', 'room', 'is', 'very', 'small.'], 'ar': ['غرفتي', 'صغيرةٌ', 'جداً.'] },
    { 'en': ['He', 'likes', 'oranges.'], 'ar': ['يحب', 'البرتقال.'] },
    { 'en': ['Spanish', 'is', 'spoken', 'in', 'twenty', 'countries.'], 'ar': ['يتحدثون', 'الإسبانية', 'في', 'عشرين', 'دولة.'] },
    { 'en': ['Mary', 'died', 'of', 'breast', 'cancer.'], 'ar': ['توفّيت', 'ماري', 'بسبب', 'سرطان', 'الثدي.'] },
    { 'en': ['The', "judge's", 'decision', 'is', 'final.'], 'ar': ['حكم', 'القاضي', 'نهائي.'] },
    { 'en': ["Don't", 'talk', 'like', 'that.'], 'ar': ['لا', 'تقل', 'ذلك.'] },
    { 'en': ['His', 'explanation', 'was', 'not', 'satisfactory.'], 'ar': ['شرحه', 'لم', 'يكن', 'كافياً.'] },
    { 'en': ['You', 'are', 'my', 'friend.'], 'ar': ['أنت', 'صديقي.'] },
    { 'en': ['How', 'tall', 'you', 'are!'], 'ar': ['ما', 'أطولك!'] },
    { 'en': ['Take', 'care.'], 'ar': ['مع', 'السلامة.'] },
    { 'en': ['If', 'you', 'were', 'in', 'my', 'place,', 'what', 'would', 'you', 'do?'], 'ar': ['ماذا', 'كنتَ', 'ستفعَل', 'لو', 'كنت', 'في', 'مكاني؟'] },
    { 'en': ['I', "can't", 'bear', 'the', 'noise', 'any', 'longer.'], 'ar': ['لا', 'أستطيع', 'أن', 'أحتمل', 'ذلك', 'الصوت', 'المزعج', 'أكثر', 'من', 'ذلك.'] },
    { 'en': ['Everyone', 'came', 'except', 'for', 'Tom.'], 'ar': ['أتى', 'الجميع', 'باستثناء', 'توم.'] },
    { 'en': ['Tom', 'has', 'a', 'scar', 'on', 'his', 'face.'], 'ar': ['لتوم', 'ندب', 'على', 'وجهه.'] },
    { 'en': ["I'm", 'at', 'home.'], 'ar': ['إني', 'في', 'المنزل.'] },
    { 'en': ['Where', 'can', 'we', 'make', 'a', 'phone', 'call?'], 'ar': ['أين', 'يمكنني', 'إجراء', 'مكالمة', 'هاتفية؟'] },
    { 'en': ['I', 'caught', 'a', 'glimpse', 'of', 'her.'], 'ar': ['لمحتها.'] },
    { 'en': ['Here', 'or', 'to', 'go?'], 'ar': ['هنا', 'أم', 'تأخذها', 'معك؟'] },
    { 'en': ["I'm", 'counting', 'on', 'you', 'guys.'], 'ar': ['أعتمد', 'عليكم', 'يا', 'رفاق.'] },
    { 'en': ['Mary', 'looked', 'at', 'herself', 'in', 'the', 'mirror.'], 'ar': ['نظرت', 'ماري', 'إلى', 'نفسها', 'في', 'المرآة.'] },
    { 'en': ['She', 'was', 'afraid', 'to', 'cross', 'the', 'road.'], 'ar': ['خافت', 'من', 'عبور', 'الشارع.'] },
    { 'en': ["I'm", 'telling', 'the', 'truth.'], 'ar': ['أنا', 'أقول', 'الحقيقة.'] },
    { 'en': ['I', 'work', 'in', 'a', 'language', 'school.'], 'ar': ['أعمل', 'في', 'مدرسة', 'للغات.'] },
    { 'en': ['Can', 'you', 'teach', 'me', 'English?'], 'ar': ['أيمكنكَ', 'تعليمي', 'الإنجليزية؟'] },
    { 'en': ['Tom', 'is', 'in', 'the', 'house.'], 'ar': ['توم', 'موجود', 'في', 'المنزل.'] },
    { 'en': ["I'm", 'needy.'], 'ar': ['أنا', 'مسكين.'] },
    { 'en': ['She', 'was', 'accused', 'of', 'telling', 'a', 'lie.'], 'ar': ['اتُّهمت', 'بالكذب.'] },
    { 'en': ['The', 'sky', 'is', 'full', 'of', 'stars.'], 'ar': ['السماء', 'مليئة', 'بالنجوم.'] },
    { 'en': ["He's", 'as', 'timid', 'as', 'a', 'rabbit.'], 'ar': ['إنه', 'جبان', 'كالأرنب.'] },
    { 'en': ['Any', 'student', 'can', 'answer', 'that', 'question.'], 'ar': ['أي', 'طالب', 'يمكنه', 'الرد', 'على', 'هذا', 'السؤال.'] },
    { 'en': ['Your', 'plan', 'sounds', 'great.'], 'ar': ['تبدو', 'خطتك', 'جيدة.'] },
    { 'en': ['My', 'plane', 'leaves', 'at', 'six', "o'clock."], 'ar': ['ستغادر', 'الطائرة', 'الساعة', 'السادسة.'] },
    { 'en': ['I', 'went', 'to', 'drink', 'a', 'beer', 'with', 'friends.'], 'ar': ['ذهبتُ', 'لشرب', 'البيرة', 'مع', 'الأصدقاء.'] },
    { 'en': ["I've", 'already', 'given', 'Tom', 'his', 'birthday', 'present.'], 'ar': ['سبق', 'أن', 'أعطيت', 'توم', 'هدية', 'عيد', 'ميلاده.'] },
    { 'en': ['The', 'pizza', 'is', 'cold.'], 'ar': ['البيتزا', 'باردة.'] },
    { 'en': ['Our', 'friendship', 'did', 'not', 'last.'], 'ar': ['لم', 'تدم', 'صداقتنا.'] },
    { 'en': ['Go', 'back', 'to', 'your', 'seats.'], 'ar': ['عودوا', 'إلى', 'مقاعدكم.'] },
    { 'en': ['Tom', "isn't", 'himself', 'today.'], 'ar': ['توم', 'ليس', 'نَفسَهُ', 'اليوم.'] },
    { 'en': ['I', 'am', 'already', 'late.'], 'ar': ['أنا', 'تأخرت.'] },
    { 'en': ['No', 'words', 'can', 'express', 'her', 'deep', 'sorrow.'], 'ar': ['حزنها', 'العميق', 'لا', 'يسعه', 'الكلمات.'] },
    { 'en': ['War', 'broke', 'out', 'in', '1939.'], 'ar': ['اِندلعت', 'الحرب', 'عام', '١٩٣٩م.'] },
    { 'en': ['My', 'eyes', 'hurt.'], 'ar': ['عيناي', 'تؤلمانني.'] },
    { 'en': ['This', 'is', 'not', 'important.'], 'ar': ['ليس', 'ذلك', 'بالشيء', 'المهم.'] },
    { 'en': ["I'll", 'give', 'you', 'a', 'good', 'piece', 'of', 'advice.'], 'ar': ['سأنصحك', 'نصيحة', 'مفيدة.'] },
    { 'en': ['I', "didn't", 'pay', 'attention', 'to', 'him.'], 'ar': ['لم', 'أعره', 'انتباها'] },
    { 'en': ['Do', 'you', 'speak', 'French?'], 'ar': ['هل', 'تتحدث', 'الفرنسية؟'] },
    { 'en': ['When', 'I', 'grow', 'up,', 'I', 'want', 'to', 'be', 'a', 'king.'], 'ar': ['حَيْنَ', 'أكبر،', 'أُريد', 'أن', 'أصبِحَ', 'ملِكًا.'] },
    { 'en': ['The', 'stock', 'market', 'has', 'dropped', 'today.'], 'ar': ['انخفضت', 'الأسعار', 'في', 'سوق', 'الأسهم', 'اليوم.'] },
    { 'en': ['Those', 'dogs', 'are', 'big.'], 'ar': ['تلك', 'الكلاب', 'ضخمة.'] },
    { 'en': ['We', 'wonder', 'why.'], 'ar': ['نتساءل', 'لِمَ.'] },
    { 'en': ["You're", 'not', 'listening!'], 'ar': ['أنت', 'لا', 'تُصغي.'] },
    { 'en': ['Mt.', 'Fuji', 'was', 'covered', 'with', 'snow.'], 'ar': ['كان', 'جبل', 'فوجي', 'مغطى', 'بالثلج.'] },
    { 'en': ['There', "isn't", 'any', 'solution.'], 'ar': ['ليس', 'هناك', 'حل.'] },
    { 'en': ['What', 'browser', 'are', 'you', 'using?'], 'ar': ['ما', 'البرنامج', 'الذي', 'تتصفح', 'به', 'الإنترنت؟'] },
    { 'en': ['She', 'has', 'five', 'older', 'brothers.'], 'ar': ['لديها', 'خمس', 'إخوة', 'أكبر', 'منها.'] },
    { 'en': ['Barking', 'dogs', 'seldom', 'bite.'], 'ar': ['الكلاب', 'النابحة', 'نادراً', 'ما', 'تعض.'] },
    { 'en': ['I', 'love', 'trying', 'new', 'things.'], 'ar': ['أُحب', 'تجربة', 'أشياء', 'جديدة.'] },
    { 'en': ["Don't", 'worry.', "I'll", 'take', 'care', 'of', 'you.'], 'ar': ['لا', 'تقلق.', 'أنا', 'سأعتني', 'بك.'] },
    { 'en': ['She', 'is', 'very', 'clever.'], 'ar': ['إنها', 'في', 'غاية', 'المهارة.'] },
    { 'en': ['I', 'had', 'my', 'money', 'stolen.'], 'ar': ['سُرقت', 'نقودي.'] },
    { 'en': ['She', 'will', 'be', 'back', 'within', 'a', 'week.'], 'ar': ['ستعود', 'في', 'غضون', 'أسبوع.'] },
    { 'en': ['Tom', 'is', 'able', 'to', 'speak', 'three', 'languages', 'well.'], 'ar': ['توم', 'يستطيع', 'ان', 'يتحدث', 'ثلاث', 'لغات', 'جيدا'] },
    { 'en': ['Have', 'you', 'been', 'to', 'France', 'before?'], 'ar': ['هل', 'زرت', 'فرنسا', 'من', 'قبل؟'] },
    { 'en': ['What', 'else', 'do', 'you', 'want?'], 'ar': ['ماذا', 'تريد', 'الآن؟'] },
    { 'en': ['Tom', 'bought', 'a', 'very', 'cheap', 'camera.'], 'ar': ['اشترى', 'توم', 'كاميرا', 'رخيصةً', 'جدا.'] },
    { 'en': ['Where', 'is', 'the', 'entrance', 'to', 'the', 'museum?'], 'ar': ['أين', 'مدخل', 'المتحف؟'] },
    { 'en': ["I'm", 'sorry', 'that', 'I', "can't", 'meet', 'you', 'tonight.'], 'ar': ['آسف', 'لكني', 'لا', 'أستطيع', 'أن', 'أقابلك', 'الليلة.'] },
    { 'en': ['Some', 'of', 'my', 'friends', 'can', 'speak', 'English', 'well.'], 'ar': ['بعض', 'أصدقائي', 'يمكنهم', 'تحدث', 'الإنجليزية', 'جيداً.'] },
    { 'en': ['Do', 'you', 'have', 'the', 'time', 'to', 'drink', 'something?'], 'ar': ['هل', 'عندك', 'الوقت', 'لتشرب', 'شيئا', 'ما؟'] },
    { 'en': ['I', 'take', 'my', 'camera', 'with', 'me', 'wherever', 'I', 'go.'], 'ar': ['آخذ', 'آلة', 'التصوير', 'معي', 'أينما', 'ذهبت.'] },
    { 'en': ['This', 'sentence', "doesn't", 'make', 'sense.'], 'ar': ['هذه', 'الجملة', 'ليس', 'لها', 'أي', 'معنى.'] },
    { 'en': ['I', 'know', 'what', 'I', 'want.'], 'ar': ['أنا', 'أعلم', 'ما', 'أريد'] },
    { 'en': ['Please', 'write', 'down', 'what', 'I', 'am', 'going', 'to', 'say.'], 'ar': ['من', 'فضلك', 'اكتب', 'ما', 'سأقوله.'] },
    { 'en': ['I', 'warned', 'you', 'not', 'to', 'come', 'here.'], 'ar': ['حذّرتك', 'من', 'المجيء', 'إلى', 'هنا.'] },
    { 'en': ['Give', 'me', 'something', 'to', 'eat.'], 'ar': ['أعطني', 'ما', 'يمكنني', 'أكله.'] },
    { 'en': ['In', 'the', 'end,', 'he', 'landed', 'in', 'jail.'], 'ar': ['في', 'النهاية', '،', 'دخل', 'السجن.'] },
    { 'en': ['I', 'am', 'pleased', 'to', 'see', 'you.'], 'ar': ['سررتُ', 'لرؤيتكِ.'] },
    { 'en': ['I', "don't", 'know.'], 'ar': ['لا', 'أعرف\u200f.\u200f\u200f'] },
    { 'en': ['You', 'should', 'come', 'to', 'Australia.'], 'ar': ['يجب', 'ان', 'تأتي', 'إلى', 'استراليا.'] },
    { 'en': ["I'm", 'free.'], 'ar': ['أنا', 'حُرّ.'] },
    { 'en': ['We', 'always', 'looked', 'down', 'on', 'him.'], 'ar': ['كنا', 'دائما', 'نحتقره.'] },
    { 'en': ['You', 'were', 'busy', 'last', 'week.'], 'ar': ['كنت', 'مشغولاً', 'الأسبوع', 'الماضي.'] },
    { 'en': ['Does', 'Tom', 'know', 'about', 'it?'], 'ar': ['هل', 'يعلم', 'توم', 'عنها؟'] },
    { 'en': ['My', 'father', 'made', 'a', 'shelf', 'for', 'me.'], 'ar': ['صنع', 'لي', 'أبي', 'رفًّا.'] },
    { 'en': ['You', 'know', 'what', 'I', 'mean.'], 'ar': ['تعرف', 'ما', 'أعنيه.'] },
    { 'en': ['Japanese', 'tourists', 'can', 'be', 'found', 'everywhere.'], 'ar': ['يمكن', 'ايجاد', 'السياح', 'اليابانيين', 'في', 'كل', 'مكان', '.'] },
    { 'en': ["There's", 'a', 'body', 'in', 'the', 'trunk', 'of', 'the', 'car.'], 'ar': ['توجد', 'جثّة', 'في', 'صندوق', 'السيّارة.'] },
    { 'en': ['No,', 'thank', 'you.'], 'ar': ['لا,', 'شكراً', 'لك'] },
    { 'en': ['Me,', 'too.'], 'ar': ['أنا', 'أيضاً.'] },
    { 'en': ['The', 'sisters', 'are', 'both', 'blondes.'], 'ar': ['الأختان', 'شقراوتان.'] },
    { 'en': ['What', 'are', 'you', 'afraid', 'of?'], 'ar': ['ما', 'الذي', 'تخشاه', '؟'] },
    { 'en': ['I', "haven't", 'yet', 'graduated.'], 'ar': ['لم', 'أتخرج', 'بعد.'] },
    { 'en': ['I', 'wish', 'you', 'would', 'do', 'as', "you're", 'told.'], 'ar': ['أتمنى', 'أن', 'تفعل', 'ما', 'أُمِرت', 'به.'] },
    { 'en': ['I', 'know', 'that', 'you', 'still', 'love', 'me.'], 'ar': ['أنا', 'أعرف', 'أنكِ', 'ما', 'زلتِ', 'تحبيني.'] },
    { 'en': ['Turn', 'down', 'the', 'volume,', 'please.'], 'ar': ['أخفض', 'الصوت', 'من', 'فضلك.'] },
    { 'en': ["He's", 'a', 'little', 'taller', 'than', 'you', 'are.'], 'ar': ['إنه', 'أطول', 'منك', 'بقليل.'] },
    { 'en': ["I'll", 'be', 'waiting', 'inside.'], 'ar': ['سأنتظر', 'في', 'الدّاخل.'] },
    { 'en': ['Give', 'me', 'another', 'chance.'], 'ar': ['أعطني', 'فرصةً', 'أخرى.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'room', 'of', 'your', 'own?'], 'ar': ['أعندك', 'غرفة', 'تخصك؟'] },
    { 'en': ["He's", 'my', 'father.'], 'ar': ['إنه', 'والدي.'] },
    { 'en': ['Did', 'they', 'understand?'], 'ar': ['هل', 'فهموا؟'] },
    { 'en': ['I', 'tried', 'to', 'cheer', 'him', 'up.'], 'ar': ['حاولت', 'رفع', 'معنوياته.'] },
    { 'en': ['I', 'lost', 'my', 'keys.'], 'ar': ['أضعت', 'مفاتيحي.'] },
    { 'en': ["It's", 'as', 'cold', 'as', 'ice.'], 'ar': ['إنها', 'باردة', 'كالثلج.'] },
    { 'en': ['Please', 'turn', 'over.'], 'ar': ['استدر', 'من', 'فضلك.'] },
    { 'en': ['Turn', 'on', 'the', 'radio.'], 'ar': ['افتح', 'الراديو.'] },
    { 'en': ['Tom', 'made', 'a', 'big', 'mistake.'], 'ar': ['ارتكب', 'توم', 'خطأ', 'شنيعا.'] },
    { 'en': ['He', 'needs', 'that', 'money.'], 'ar': ['هو', 'بحاجة', 'لذلك', 'المال.'] },
    { 'en': ['We', "don't", 'know', 'her.'], 'ar': ['نحن', 'لا', 'نعلم', 'من', 'هي.'] },
    { 'en': ['My', 'uncle', 'gave', 'me', 'his', 'car.'], 'ar': ['أعطاني', 'عمي', 'سيارته.'] },
    { 'en': ['He', 'is', 'alone.'], 'ar': ['إنه', 'وحده.'] },
    { 'en': ['The', 'dog', 'barked', 'at', 'the', 'stranger.'], 'ar': ['نبح', 'الكلب', 'في', 'وجه', 'الغريب.'] },
    { 'en': ['I', 'have', 'been', 'to', 'London', 'twice.'], 'ar': ['زرت', 'لندن', 'مرتين.'] },
    { 'en': ['The', 'rain', 'lasted', 'a', 'week.'], 'ar': ['استمر', 'المطر', 'أسبوعاً.'] },
    { 'en': ['Is', 'this', 'a', 'picture', 'that', 'you', 'yourself', 'drew?'], 'ar': ['هل', 'هذه', 'صورة', 'أنتَ', 'رسمتَها', 'بنفسك؟'] },
    { 'en': ['I', 'have', 'a', 'pen.'], 'ar': ['لدي', 'قلم.'] },
    { 'en': ["I'm", 'very', 'glad', 'to', 'meet', 'you.'], 'ar': ['إنني', 'سعيد', 'بلقائك.'] },
    { 'en': ['You', 'certainly', 'have', 'a', 'lot', 'of', 'questions.'], 'ar': ['أكيد', 'لديك', 'كثير', 'من', 'الأسئلة.'] },
    { 'en': ['Will', 'you', 'be', 'here', 'tomorrow?'], 'ar': ['أنتَ', 'ستكون', 'هنا', 'غداً؟'] },
    { 'en': ['I', 'lost', 'my', 'trust', 'in', 'him.'], 'ar': ['فقدت', 'ثقتي', 'فيه.'] },
    { 'en': ['How', 'did', 'you', 'sleep', 'last', 'night?'], 'ar': ['كيف', 'نِمتِ', 'الليلة', 'الماضية؟'] },
    { 'en': ['When', 'did', 'you', 'get', 'to', 'London?'], 'ar': ['متى', 'وصلت', 'إلى', 'لندن؟'] },
    { 'en': ['I', 'see', 'the', 'girl.'], 'ar': ['أنا', 'أري', 'الفتاة.'] },
    { 'en': ['Tom', 'and', 'Mary', 'exchanged', 'smiles.'], 'ar': ['ابتسم', 'توم', 'و', 'ماري', 'لبعضهم', 'البعض.'] },
    { 'en': ['Be', 'respectful.'], 'ar': ['كُن', 'مُحترماً.'] },
    { 'en': ['This', 'is', 'a', 'small', 'book.'], 'ar': ['هذا', 'كتاب', 'صغير'] },
    { 'en': ['He', 'told', 'his', 'son', 'to', 'get', 'down', 'from', 'the', 'ladder.'], 'ar': ['قال', 'لابنه', 'أن', 'ينزل', 'عن', 'السلم.'] },
    { 'en': ['Let', 'me', 'explain', 'why', 'I', 'arrived', 'late.'], 'ar': ['دعني', 'أشرح', 'سبب', 'وصولي', 'متأخراً.'] },
    { 'en': ["We're", 'happy', 'to', 'see', 'you.'], 'ar': ['سعيدون', 'لرؤيتك.'] },
    { 'en': ['I', 'woke', 'up', 'at', 'five', 'this', 'morning.'], 'ar': ['استيقظت', 'في', 'الخامسة', 'هذا', 'الصباح.'] },
    { 'en': ['This', 'story', 'is', 'about', 'Tom.'], 'ar': ['هذه', 'قصّة', 'عن', 'توم.'] },
    { 'en': ['My', 'father', 'bought', 'a', 'new', 'car.'], 'ar': ['اشترى', 'أبي', 'سيارة', 'جديدة.'] },
    { 'en': ["I'm", 'from', 'Tokyo,', 'Japan.'], 'ar': ['أنا', 'من', 'طوكيو', 'عاصمة', 'اليابان.'] },
    { 'en': ['Mary', 'is', "Tom's", 'sister.'], 'ar': ['ماري', 'هي', 'أخت', 'توم'] },
    { 'en': ['You', "shouldn't", 'have', 'done', 'that.'], 'ar': ['لم', 'يكن', 'عليك', 'فعل', 'هذا.'] },
    { 'en': ['I', 'have', 'a', 'pain', 'in', 'my', 'chest.'], 'ar': ['لدي', 'ألم', 'في', 'صدري.'] },
    { 'en': ['Is', 'this', 'your', 'first', 'visit', 'to', 'Japan?'], 'ar': ['هل', 'هذه', 'أول', 'زيارة', 'لك', 'إلى', 'اليابان؟'] },
    { 'en': ['If', 'you', 'do', 'it,', 'I', 'might', 'be', 'able', 'to', 'help', 'you.'], 'ar': ['إذا', 'فعلت', 'ذلك,', 'قد', 'أكون', 'قادر', 'على', 'مساعدتك.'] },
    { 'en': ["Don't", 'give', 'me', 'any', 'ideas.'], 'ar': ['لا', 'ُثعطني', 'أي', 'فكرة.'] },
    { 'en': ['My', 'father', 'has', 'a', 'restaurant.'], 'ar': ['يملك', 'أبي', 'مطعماً.'] },
    { 'en': ['This', 'bus', 'will', 'take', 'you', 'to', 'the', 'airport.'], 'ar': ['سيأخذك', 'هذا', 'الباص', 'إلى', 'المطار.'] },
    { 'en': ['Cows', 'give', 'us', 'milk.'], 'ar': ['البقر', 'يعطينا', 'الحليب.'] },
    { 'en': ['I', 'need', 'to', 'concentrate.'], 'ar': ['أحتاج', 'إلى', 'أن', 'أركز.'] },
    { 'en': ["I'm", 'from', 'Bulgaria.'], 'ar': ['أنا', 'من', 'بلغاريا.'] },
    { 'en': ['Are', 'you', 'going', 'to', "Tom's", 'party', 'tonight?'], 'ar': ['أستذهب', 'إلى', 'حفلة', 'توم', 'الليلة؟'] },
    { 'en': ['I', 'was', 'very', 'careful,', 'but', 'I', 'caught', 'a', 'cold.'], 'ar': ['كنتُ', 'حذراً', 'جداً،', 'لكنني', 'أصبتُ', 'بنزلةِ', 'برد.'] },
    { 'en': ['I', "don't", 'have', 'a', 'girlfriend.'], 'ar': ['ليس', 'لديّ', 'صديقة.'] },
    { 'en': ['I', 'will', 'sue', 'you.'], 'ar': ['سأقاضيك.'] },
    { 'en': ['I', 'have', 'never', 'seen', 'a', 'red', 'refrigerator.'], 'ar': ['لم', 'يسبق', 'لي', 'أن', 'رأيت', 'ثلاجةً', 'حمراء.'] },
    { 'en': ['You', 'were', 'supposed', 'to', 'tell', 'Tom', 'that.'], 'ar': ['كان', 'عليك', 'إخبار', 'توم', 'بذلك.'] },
    { 'en': ['Take', 'a', 'look.'], 'ar': ['ألقي', 'نظرة'] },
    { 'en': ['Did', 'you', 'pay', 'for', 'the', 'book?'], 'ar': ['هل', 'دفعت', 'ثمن', 'الكتاب؟'] },
    { 'en': ['She', 'appears', 'to', 'have', 'a', 'headache.'], 'ar': ['و', 'كأنها', 'لديها', 'صداع.'] },
    { 'en': ["I'll", 'lend', 'you', 'my', 'notebook.'], 'ar': ['سأعيرك', 'مذكرتي.'] },
    { 'en': ['Take', 'it.'], 'ar': ['خذه'] },
    { 'en': ['He', 'got', 'angry.'], 'ar': ['غضب.'] },
    { 'en': ['In', 'my', 'opinion,', "he's", 'right.'], 'ar': ['رأيي', 'أنه', 'محق.'] },
    { 'en': ['Such', 'a', 'thing', 'occurs', 'frequently.'], 'ar': ['يحدث', 'ذلك', 'كثيراً.'] },
    { 'en': ['Why', "don't", 'you', 'eat', 'vegetables?'], 'ar': ['لم', 'لا', 'تأكل', 'خضاراً؟'] },
    { 'en': ['Is', 'there', 'something', 'you', 'want', 'to', 'buy?'], 'ar': ['أهناك', 'شيء', 'تريد', 'شراءه؟'] },
    { 'en': ['My', 'brother', 'likes', 'horror', 'movies.'], 'ar': ['يحب', 'أخي', 'مشاهدة', 'أفلام', 'الرعب.'] },
    { 'en': ['There', 'was', 'a', 'post', 'office', 'on', 'this', 'corner', 'once.'], 'ar': ['في', 'يوم', 'من', 'الأيام', 'كان', 'هناك', 'مكتب', 'بريدي', 'على', 'هذه', 'الزاوية', 'من', 'الشارع.'] },
    { 'en': ['I', "don't", 'know', 'what', 'happens', 'here.'], 'ar': ['أنا', 'لا', 'اعرف', 'ما', 'يحصل', 'هنا.'] },
    { 'en': ['Pus', 'has', 'formed', 'in', 'the', 'wound.'], 'ar': ['تَكَوّنَ', 'الصديد', 'في', 'الجرح.'] },
    { 'en': ['Tom', 'turned', 'on', 'the', 'kitchen', 'faucet.'], 'ar': ['توم', 'أفتح', 'صنبور', 'المطبخ'] },
    { 'en': ['That', "doesn't", 'change', 'anything.'], 'ar': ['هذا', 'لا', 'يغير', 'شيئاً.'] },
    { 'en': ['Stay', 'here', 'with', 'us.'], 'ar': ['ابق', 'هنا', 'معنا.'] },
    { 'en': ['I', 'think', "that's", 'what', 'happened.'], 'ar': ['أظنّ', 'أن', 'هذا', 'ما', 'حدث.'] },
    { 'en': ['I', 'guess', "I'll", 'stay', 'a', 'while.'], 'ar': ['أظن', 'أنني', 'سأبقى', 'لفترة.'] },
    { 'en': ['Come', 'on.', "Let's", 'get', 'out', 'of', 'here.'], 'ar': ['هيا.', 'دعينا', 'نخرج', 'من', 'هنا.'] },
    { 'en': ['Mary', 'spoke', 'Japanese', 'slowly.'], 'ar': ['تحدثت', 'ماري', 'باليابانية', 'ببطئ.'] },
    { 'en': ['This', 'water', 'is', 'delicious.'], 'ar': ['هذا', 'الماء', 'لذيذ.'] },
    { 'en': ['Has', 'something', 'happened?'], 'ar': ['هل', 'حدث', 'أي', 'شيء؟'] },
    { 'en': ['I', 'have', 'to', 'go', 'home.'], 'ar': ['يجب', 'أن', 'أذهب', 'إلى', 'البيت'] },
    { 'en': ['It', 'was', 'a', 'mouse.'], 'ar': ['كان', 'ذلك', 'فأراً.'] },
    { 'en': ["I'm", 'very', 'glad', 'I', "wasn't", 'there.'], 'ar': ['أنا', 'سَعيدٌ', 'جداً', 'لأنّي', 'لم', 'أكُنْ', 'هناك.'] },
    { 'en': ['I', 'knew', 'that', 'something', 'bad', 'might', 'happen.'], 'ar': ['علمت', 'أن', 'شيئا', 'سيئا', 'قد', 'يحدث.'] },
    { 'en': ['It', 'is', 'raining.'], 'ar': ['إنها', 'تمطر.'] },
    { 'en': ['Today', 'is', 'Monday.'], 'ar': ['اليوم', 'هو', 'الإثنين'] },
    { 'en': ["Don't", 'rely', 'too', 'much', 'on', 'others.'], 'ar': ['لا', 'تعتمد', 'على', 'الآخرين', 'كثيراً.'] },
    { 'en': ['Who', 'owns', 'this', 'house?'], 'ar': ['من', 'مالك', 'هذا', 'البيت؟'] },
    { 'en': ['Which', 'is', 'your', 'book?'], 'ar': ['أيهما', 'كتابك؟'] },
    { 'en': ['I', 'miss', 'him.'], 'ar': ['أشتاق', 'إليه.'] },
    { 'en': ['If', 'you', "don't", 'work,', 'you', "don't", 'eat.'], 'ar': ['إن', 'لم', 'تعمل', 'لن', 'تأكل.'] },
    { 'en': ['I', 'have', 'a', 'tourist', 'visa.'], 'ar': ['انا', 'املك', 'تأشيرة', 'سياحية', '.'] },
    { 'en': ["Let's", 'go', 'early,', 'shall', 'we?'], 'ar': ['لنذهب', 'باكراً.'] },
    { 'en': ['She', 'made', 'friends', 'with', 'Tom', 'at', 'the', 'party.'], 'ar': ['تعرّفت', 'على', 'توم', 'في', 'الحفلة', 'و', 'أصبحا', 'صديقَيْن.'] },
    { 'en': ['Are', 'you', 'hungry?'], 'ar': ['هل', 'أنت', 'جائع؟'] },
    { 'en': ['Could', 'I', 'get', 'a', 'little', 'piece', 'of', 'that', 'cake?'], 'ar': ['أيمكنني', 'الحصول', 'على', 'قطعة', 'من', 'تلك', 'الكعكة؟'] },
    { 'en': ['I', 'went', 'to', 'the', 'zoo', 'yesterday.'], 'ar': ['زرت', 'حديقة', 'الحيوان', 'بالأمس.'] },
    { 'en': ['He', 'does', 'not', 'know', 'how', 'to', 'drive', 'a', 'car.'], 'ar': ['إنه', 'لا', 'يعرف', 'كيف', 'يقود', 'سيارة.'] },
    { 'en': ['Will', 'you', 'go', 'with', 'Tom?'], 'ar': ['هل', 'ستذهب', 'مع', 'توم؟'] },
    { 'en': ['She', 'cleaned', 'her', 'room', 'in', 'a', 'hurry.'], 'ar': ['رتبت', 'غرفتها', 'بسرعة.'] },
    { 'en': ['All', 'you', 'need', 'is', 'love.'], 'ar': ['كل', 'ما', 'تحتاجه', 'هو', 'الحب.'] },
    { 'en': ['The', 'strike', 'affected', 'the', "nation's", 'economy.'], 'ar': ['أثًّر', 'الإضراب', 'على', 'اقتصاد', 'البلاد.'] },
    { 'en': ['I', 'live', 'here.'], 'ar': ['أنا', 'أسكن', 'هنا'] },
    { 'en': ['You', 'can', 'always', 'ask', 'me', 'for', 'help.'], 'ar': ['يمكنكِ', 'دائماً', 'أن', 'تسأليني', 'عن', 'المساعدة.'] },
    { 'en': ['He', 'took', 'part', 'in', 'the', 'race.'], 'ar': ['لقد', 'شارك', 'في', 'السباق.'] },
    { 'en': ['He', 'is', 'not', 'stupid.'], 'ar': ['ليس', 'غبياً.'] },
    { 'en': ['I', "don't", 'pretend', 'to', 'understand', 'women.'], 'ar': ['لا', 'أدعي', 'أنني', 'أفهم', 'النساء.'] },
    { 'en': ['He', 'is', 'French.'], 'ar': ['هو', 'فرنسي'] },
    { 'en': ['She', 'has', 'a', 'daughter', 'who', 'is', 'a', 'pianist.'], 'ar': ['لديها', 'ابنة', 'تعزف', 'على', 'البيانو.'] },
    { 'en': ['I', 'think', 'that', 'Tom', 'is', 'hiding', 'something.'], 'ar': ['أظنّ', 'توم', 'يخفي', 'شيئًا.'] },
    { 'en': ['We', 'really', 'enjoyed', 'ourselves.'], 'ar': ['أمضينا', 'وقتاً', 'ممتعاً', 'جداً.'] },
    { 'en': ['Do', 'I', 'have', 'to', 'do', 'it', 'right', 'away?'], 'ar': ['أيجب', 'أن', 'أقوم', 'بذلك', 'على', 'الفور؟'] },
    { 'en': ['What', 'are', 'you', 'looking', 'for?'], 'ar': ['عمّا', 'تبحث؟'] },
    { 'en': ['Tom', 'is', 'still', 'in', 'the', 'hospital.'], 'ar': ['ما', 'زال', 'توم', 'في', 'المستشفى.'] },
    { 'en': ['Iron', 'is', 'hard.'], 'ar': ['الحديد', 'صلب.'] },
    { 'en': ['We', 'are', 'going', 'downtown', 'to', 'eat', 'pizza.'], 'ar': ['نحن', 'ذاهبون', 'إلى', 'وسط', 'المدينة', 'لنأكل', 'البيتزا.'] },
    { 'en': ['How', 'can', 'I', 'change', 'that?'], 'ar': ['كيف', 'يمكنني', 'أن', 'أغيّر', 'هذا؟'] },
    { 'en': ['Do', 'you', 'work', 'alone?'], 'ar': ['هل', 'تعمل', 'لوحدك؟'] },
    { 'en': ['I', 'have', 'greasy', 'skin.'], 'ar': ['لدي', 'جلد', 'دهني.'] },
    { 'en': ['What', 'do', 'you', 'wish', 'for?'], 'ar': ['ماذا', 'تتمنى؟'] },
    { 'en': ['She', 'should', 'listen', 'more', 'to', 'other', 'people.'], 'ar': ['ينبغي', 'لها', 'أن', 'تستمع', 'إلى', 'آراء', 'الآخرين', 'أكثر.'] },
    { 'en': ['My', 'plane', 'leaves', 'at', 'six', "o'clock."], 'ar': ['ستنطلق', 'طائرتي', 'عند', 'الساعة', 'السادسة.'] },
    { 'en': ["I'm", 'sure', "I've", 'seen', 'him', 'somewhere', 'before.'], 'ar': ['متأكّد', 'أني', 'رأيته', 'قبلا', 'في', 'مكان', 'ما.'] },
    { 'en': ["I'm", 'tired', 'of', 'studying.'], 'ar': ['تعبتُ', 'من', 'الدراسة.'] },
    { 'en': ['The', 'sun', 'gives', 'us', 'light', 'and', 'heat.'], 'ar': ['تعطينا', 'الشمسُ', 'الضوءَ', 'والحرارةَ.'] },
    { 'en': ["I've", 'just', 'finished', 'eating', 'breakfast.'], 'ar': ['لقد', 'إنتهيت', 'للتو', 'من', 'تناول', 'الإفطار.'] },
    { 'en': ['Tom', 'is', 'a', 'friend', 'of', 'mine.'], 'ar': ['توم', 'صديق', 'لي.'] },
    { 'en': ['Hi!', 'How', 'are', 'you?'], 'ar': ['أهلاً', 'كيف', 'حالك؟'] },
    { 'en': ['He', 'knows', 'the', 'city', 'well.'], 'ar': ['هو', 'يعرف', 'المدينة', 'جيداً.'] },
    { 'en': ['My', 'father', 'is', 'rich.'], 'ar': ['أبي', 'غني'] },
    { 'en': ['I', 'know', 'your', 'father.'], 'ar': ['أنا', 'أعرف', 'أباك'] },
    { 'en': ['I', 'bought', 'a', 'watch.'], 'ar': ['اشتريت', 'ساعة.'] },
    { 'en': ["You're", 'an', 'angel!'], 'ar': ['أنت', 'ملاك!'] },
    { 'en': ['They', "won't", 'intervene.'], 'ar': ['هم', 'لن', 'يتدخلوا.'] },
    { 'en': ['Is', 'this', 'your', 'book?'], 'ar': ['هل', 'هذا', 'كتابك؟'] },
    { 'en': ['I', 'hope', 'so.'], 'ar': ['آمل', 'ذلك.'] },
    { 'en': ['What', 'do', 'you', 'say', 'to', 'this', 'plan?'], 'ar': ['مارأيك', 'بهذه', 'الخطة؟'] },
    { 'en': ["I've", 'been', 'a', 'teacher', 'for', 'two', 'years.'], 'ar': ['لقد', 'كنتُ', 'معلمة', 'لعامين.'] },
    { 'en': ['Bring', 'wine.'], 'ar': ['أحضر', 'النبيذ.'] },
    { 'en': ['Go', 'to', 'bed.'], 'ar': ['اذهب', 'إلى', 'النوم'] },
    { 'en': ["Let's", 'take', 'a', 'vote.'], 'ar': ['دعونا', 'نأخذ', 'تصويت.'] },
    { 'en': ['He', 'taught', 'himself', 'French.'], 'ar': ['تعلم', 'الفرنسية', 'بنفسه.'] },
    { 'en': ['It', "doesn't", 'surprise', 'me.'], 'ar': ['لم', 'يفاجئني', 'ذلك.'] },
    { 'en': ['I', 'gave', 'my', 'sister', 'a', 'dictionary.'], 'ar': ['أعطيت', 'أختي', 'قاموساً.'] },
    { 'en': ["I'm", 'old', 'enough', 'to', 'vote.'], 'ar': ['أنا', 'كبير', 'بما', 'يكفي', 'للتصويت.'] },
    { 'en': ['What', 'he', 'needs', 'most', 'is', 'a', 'good', 'job.'], 'ar': ['ما', 'يحتاجه', 'حقاً', 'هو', 'وظيفة', 'جيدة.'] },
    { 'en': ['His', 'birthday', 'is', 'May', '5th.'], 'ar': ['الخامس', 'من', 'مايو', 'هو', 'يوم', 'ميلادها.'] },
    { 'en': ['She', 'divorced', 'her', 'husband.'], 'ar': ['طلّقت', 'زوجها.'] },
    { 'en': ['Your', 'condition', "isn't", 'serious.'], 'ar': ['حالتُُك', 'ليست', 'سيئة.'] },
    { 'en': ['I', 'am', 'very', 'sorry.'], 'ar': ['أنا', 'آسف', 'جداً'] },
    { 'en': ['This', 'book', 'is', 'adapted', 'for', 'children.'], 'ar': ['تم', 'تكييف', 'هذا', 'الكتاب', 'للأطفال.'] },
    { 'en': ['Hurry,', 'and', 'you', 'will', 'catch', 'the', 'train.'], 'ar': ['أسرع', 'و', 'ستلحق', 'بالقطار.'] },
    { 'en': ['I', 'thought', 'Tom', 'was', 'dead.'], 'ar': ['ظننت', 'توم', 'ميّتا.'] },
    { 'en': ['Tom', 'drove', 'the', 'car.'], 'ar': ['قاد', 'توم', 'السيارة.'] },
    { 'en': ['My', 'name', 'is', 'known', 'to', 'everybody', 'in', 'my', 'school.'], 'ar': ['اسمي', 'معروف', 'لدى', 'الجميع', 'في', 'مدرستي.'] },
    { 'en': ['The', 'first', 'step', 'is', 'always', 'the', 'hardest.'], 'ar': ['الخطوة', 'الأولى', 'دائماً', 'ما', 'تكون', 'الأصعب.'] },
    { 'en': ['Please', 'send', 'it', 'to', 'me', 'by', 'fax.'], 'ar': ['من', 'فضلك', 'أرسلها', 'عن', 'طريق', 'الفاكس.'] },
    { 'en': ["I'm", 'pleased', 'to', 'meet', 'you.'], 'ar': ['سررتُ', 'بمعرفتكَ.'] },
    { 'en': ['Tom', 'likes', 'to', 'knit.'], 'ar': ['يحب', 'توم', 'الحياكة.'] },
    { 'en': ['You', 'have', 'to', 'take', 'this.'], 'ar': ['عليك', 'أن', 'تأخذ', 'هذا.'] },
    { 'en': ['Tom', 'and', 'Mary', 'are', 'playing', 'chess.'], 'ar': ['يلعب', 'توم', 'وماري', 'الشطرنج.'] },
    { 'en': ['She', 'warned', 'him', 'not', 'to', 'go', 'alone.'], 'ar': ['حذرته', 'من', 'الذهاب', 'وحده.'] },
    { 'en': ['I', "don't", 'like', 'her.'], 'ar': ['لا', 'أحبها.'] },
    { 'en': ["How's", 'your', 'dad?'], 'ar': ['كيف', 'حال', 'أبيك؟'] },
    { 'en': ['My', 'mom', "doesn't", 'speak', 'English', 'very', 'well.'], 'ar': ['والدتي', 'لا', 'تتحدث', 'الإنجليزية', 'بشكل', 'جيد', 'جداً.'] },
    { 'en': ['He', 'will', 'go', 'to', 'Tokyo', 'tomorrow,', "won't", 'he?'], 'ar': ['سيذهب', 'إلى', 'طوكيو', 'غداً،', 'أليس', 'ذلك', 'صحيحاً؟'] },
    { 'en': ['Goodbye!'], 'ar': ['مع', 'السلامة.'] },
    { 'en': ['I', "don't", 'know', 'if', 'I', 'still', 'have', 'it.'], 'ar': ['لا', 'أعرف', 'إن', 'كانت', 'لا', 'تزال', 'معي.'] },
    { 'en': ['The', 'girl', 'looked', 'around.'], 'ar': ['نظرت', 'البنت', 'حولها.'] },
    { 'en': ['I', 'have', 'lost', 'my', 'wallet.'], 'ar': ['أضعت', 'محفظة', 'نقودي.'] },
    { 'en': ['Look', 'over', 'the', 'papers,', 'please.'], 'ar': ['من', 'فضلك', 'ألقٍ', 'نظرةً', 'على', 'الأوراق.'] },
    { 'en': ['I', 'will', 'do', 'anything', 'for', 'you.'], 'ar': ['سأفعل', 'أي', 'شيء', 'من', 'أجلك.'] },
    { 'en': ['When', 'can', 'we', 'eat?'], 'ar': ['متى', 'يمكننا', 'الأكل؟'] },
    { 'en': ['Pickpockets', 'en', 'tourists.'], 'ar': ['النشالون', 'يستهدفون', 'السياح', '.'] },
    { 'en': ['She', 'asked', 'us', 'to', 'leave', 'her', 'alone.'], 'ar': ['طلبت', 'منا', 'أن', 'نتركها', 'و', 'شأنها.'] },
    { 'en': ['I', 'went', 'to', 'church', 'this', 'morning.'], 'ar': ['ذهبت', 'هذا', 'الصباح', 'إلى', 'الكنسية.'] },
    { 'en': ["I'm", 'your', 'father.'], 'ar': ['أنا', 'والدك.'] },
    { 'en': ['Translate', 'this', 'sentence', 'into', 'English.'], 'ar': ['ترجم', 'هذه', 'الجملة', 'إلى', 'الإنجليزية.'] },
    { 'en': ['I', "can't", 'help', 'you', 'because', "I'm", 'busy.'], 'ar': ['لا', 'يمكنني', 'مساعدتك', 'لأني', 'مشغول.'] },
    { 'en': ['Shut', 'up!'], 'ar': ['اصمت!'] },
    { 'en': ['You', 'must', 'study', 'more.'], 'ar': ['عليك', 'أن', 'تدرس', 'أكثر.'] },
    { 'en': ['Tom', 'became', 'a', 'policeman.'], 'ar': ['أصبح', 'توم', 'شرطيا.'] },
    { 'en': ['This', 'is', 'my', 'younger', 'sister.'], 'ar': ['هذه', 'أختي', 'الصغرى.'] },
    { 'en': ["I'm", 'not', 'scared.'], 'ar': ['أنا', 'لستُ', 'خائفةً'] },
    { 'en': ['I', 'use', 'it.'], 'ar': ['أستخدمه.'] },
    { 'en': ['Do', 'you', 'love', 'music?'], 'ar': ['أتحب', 'الموسيقى؟'] },
    { 'en': ['My', 'hobby', 'is', 'visiting', 'old', 'temples.'], 'ar': ['هوايتي', 'زيارة', 'المعابد', 'القديمة.'] },
    { 'en': ["How's", 'the', 'project', 'going?'], 'ar': ['كيف', 'تسير', 'أمور', 'المشروع؟'] },
    { 'en': ['We', 'are', 'at', 'home.'], 'ar': ['نحن', 'في', 'المنزل'] },
    { 'en': ['We', 'may', 'have', 'missed', 'the', 'bus.'], 'ar': ['لربما', 'فاتنا', 'الباص.'] },
    { 'en': ['Everything', 'is', 'better', 'without', 'you.'], 'ar': ['كل', 'شيء', 'أفضل', 'من', 'دونك.'] },
    { 'en': ['I', 'do', 'not', 'like', 'science.'], 'ar': ['لا', 'أحب', 'مادة', 'العلوم.'] },
    { 'en': ['Tom', 'will', 'help', 'me', 'clean', 'my', 'room.'], 'ar': ['سيساعدني', 'توم', 'في', 'تنظيف', 'غرفتي'] },
    { 'en': ['I', 'have', 'something', 'to', 'tell', 'you.'], 'ar': ['أريد', 'أن', 'أقول', 'لك', 'شيئاً.'] },
    { 'en': ['He', 'likes', 'anything', 'sweet.'], 'ar': ['إنه', 'يحب', 'أي', 'شيء', 'حلو', 'المذاق.'] },
    { 'en': ["Don't", 'get', 'so', 'angry.'], 'ar': ['لا', 'تغضب', 'كثيرًا.'] },
    { 'en': ['I', 'think', 'you', 'should', 'read', 'it', 'yourself.'], 'ar': ['أعتقد', 'أنه', 'ينبغي', 'عليك', 'أن', 'تقرأه', 'بنفسك.'] },
    { 'en': ['Let', 'Tom', 'go.'], 'ar': ['دَع', 'توم', 'يذهب.'] },
    { 'en': ['The', 'driver', 'accelerated', 'his', 'car.'], 'ar': ['زاد', 'السائق', 'من', 'سرعة', 'سيارته.'] },
    { 'en': ['She', 'was', 'busy', 'with', 'the', 'housework.'], 'ar': ['كانت', 'مشغولة', 'بأعمال', 'منزلية.'] },
    { 'en': ['Tell', 'Tom', 'hi', 'for', 'me.'], 'ar': ['أوصل', 'سلامي', 'لتوم.'] },
    { 'en': ['She', 'was', 'busy', 'with', 'household', 'work.'], 'ar': ['كانت', 'مشغولة', 'بأعمال', 'البيت.'] },
    { 'en': ['What', 'did', 'you', 'talk', 'about?'], 'ar': ['ما', 'الذي', 'تحدّثت', 'عنه؟'] },
    { 'en': ['What', 'did', 'he', 'say?'], 'ar': ['ماذا', 'قال؟'] },
    { 'en': ["That's", 'an', 'order.'], 'ar': ['هذا', 'أمر'] },
    { 'en': ['She', 'was', 'advised', 'by', 'him', 'to', 'give', 'up', 'smoking.'], 'ar': ['تم', 'نصحهها', 'من', 'قبله', 'للتخلي', 'عن', 'التدخين.'] },
    { 'en': ['All', 'my', 'siblings', 'are', 'taller', 'than', 'me.'], 'ar': ['جميع', 'اخوتي', 'أطول', 'قامة', 'مني.'] },
    { 'en': ['I', 'would', 'rather', 'sing', 'than', 'dance.'], 'ar': ['أفضل', 'الغناء', 'على', 'الرقص.'] },
    { 'en': ['Do', 'you', 'still', 'need', 'tea?'], 'ar': ['هل', 'مازلتِ', 'تريدين', 'شاياً؟'] },
    { 'en': ['There', "isn't", 'any', 'soap.'], 'ar': ['ليس', 'هناك', 'أي', 'صابون.'] },
    { 'en': ['I', 'asked', 'him', 'where', 'he', 'lived.'], 'ar': ['سألته', 'أين', 'عاش.'] },
    { 'en': ['I', 'was', 'late', 'for', 'the', 'appointment.'], 'ar': ['تأخرتُ', 'على', 'الموعد.'] },
    { 'en': ['Are', 'you', 'Japanese?'], 'ar': ['هل', 'أنت', 'ياباني؟'] },
    { 'en': ['How', 'do', 'you', 'feel', 'now?'], 'ar': ['كيف', 'تشعر', 'الآن؟'] },
    { 'en': ["We've", 'made', 'arrangements', 'for', 'you.'], 'ar': ['عملنا', 'بعض', 'التحضيرات', 'لأجلك.'] },
    { 'en': ['You', 'were', 'talking', 'in', 'your', 'sleep', 'last', 'night.'], 'ar': ['كنت', 'تتحدث', 'في', 'نومك', 'البارحة.'] },
    { 'en': ['Schools', 'were', 'built.'], 'ar': ['أُنْشِئَتْ', 'مدارس.'] },
    { 'en': ['The', 'front', 'door', 'was', 'open.'], 'ar': ['كان', 'الباب', 'مفتوحاً.'] },
    { 'en': ["Don't", 'speak', 'in', 'Japanese.'], 'ar': ['لا', 'تتكلم', 'باليابانية.'] },
    { 'en': ['He', 'accomplished', 'the', 'work', 'as', 'planned.'], 'ar': ['قام', 'بالعمل', 'كما', 'طُلب.'] },
    { 'en': ['Please', 'speak', 'more', 'loudly.'], 'ar': ['من', 'فضلك', 'تكلم', 'بصوت', 'أعلى.'] },
    { 'en': ["I've", 'come', 'to', 'save', 'you.'], 'ar': ['أتيتُ', 'لإنقاذك.'] },
    { 'en': ["Shouldn't", 'you', 'be', 'getting', 'ready', 'for', 'school?'], 'ar': ['ألا', 'يفترضُ', 'بك', 'أن', 'تستعد', 'للمدرسة؟'] },
    { 'en': ["Don't", 'give', 'up', 'if', 'you', 'fail.'], 'ar': ['لا', 'تستسلم', 'إن', 'فشلت.'] },
    { 'en': ['I', 'do', 'not', 'work.'], 'ar': ['أنا', 'عاطل', 'عن', 'العمل.'] },
    { 'en': ['He', 'was', 'foolish', 'enough', 'to', 'believe', 'her.'], 'ar': ['كان', 'أحمق', 'بما', 'يكفي', 'ليصدقها.'] },
    { 'en': ['Is', 'that', 'milk', 'still', 'good?'], 'ar': ['هل', 'ما', 'زال', 'ذلك', 'الحليب', 'صالحاً', 'للشرب؟'] },
    { 'en': ['I', 'have', 'ten', 'pens.'], 'ar': ['لديّ', 'عشرة', 'أقلام.'] },
    { 'en': ['Where', 'is', 'the', 'toothpaste?'], 'ar': ['أين', 'معجون', 'الأسنان؟'] },
    { 'en': ["I've", 'done', 'business', 'with', 'Tom', 'before.'], 'ar': ['لقد', 'تعاملت', 'مع', 'توم', 'سابقا.'] },
    { 'en': ['You', 'may', 'go', 'anywhere.'], 'ar': ['يمكنك', 'أن', 'تذهب', 'إلى', 'أي', 'مكان', 'تريد.'] },
    { 'en': ['You', 'must', 'join', 'me.'], 'ar': ['يجب', 'عليك', 'الانضمام', 'لي.'] },
    { 'en': ['She', 'taught', 'me', 'how', 'to', 'make', 'a', 'web', 'site.'], 'ar': ['علّمتْني', 'كيف', 'أصنع', 'موقعَ', 'إنترنت.'] },
    { 'en': ['Please', 'correct', 'my', 'pronunciation.'], 'ar': ['صحح', 'نطقي', 'من', 'فضلك.'] },
    { 'en': ['The', 'tourist', 'asked', 'for', 'lodging', 'for', 'the', 'night.'], 'ar': ['سال', 'السائح', 'بخصوص', 'المكوث', 'لهذه', 'الليلة', '.'] },
    { 'en': ['Soccer', 'is', 'the', "world's", 'most', 'popular', 'sport.'], 'ar': ['كرة', 'القدم', 'هي', 'رياضة', 'العالم', 'الأكثر', 'شعبيّة.'] },
    { 'en': ['I', 'can', 'carry', 'those', 'suitcases', 'for', 'you.'], 'ar': ['أستطيع', 'أن', 'أحمل', 'تلك', 'الحقائب', 'عنك.'] },
    { 'en': ['Her', 'answer', "couldn't", 'be', 'understood.'], 'ar': ['جوابها', 'لم', 'يكن', 'مفهوماً.'] },
    { 'en': ['I', 'miss', 'you.'], 'ar': ['اشتقت', 'إليك.'] },
    { 'en': ['I', 'saw', 'five', 'men.'], 'ar': ['رأيت', 'خمسة', 'رجال.'] },
    { 'en': ['What', 'do', 'you', 'like', 'about', 'her?'], 'ar': ['ما', 'الذي', 'يعجبك', 'بشأنها؟'] },
    { 'en': ['My', 'name', "isn't", 'on', 'the', 'list.'], 'ar': ['اسمي', 'ليس', 'في', 'القائمة.'] },
    { 'en': ['I', 'am', 'not', 'the', 'captain', 'of', 'the', 'new', 'team.'], 'ar': ['لست', 'قبطان', 'الفريق', 'الجديد.'] },
    { 'en': ['I', 'went', 'to', 'Osaka', 'station.'], 'ar': ['ذهبت', 'إلى', 'محطة', 'أوساكا.'] },
    { 'en': ['Thank', 'you', 'for', 'the', 'pleasant', 'evening.'], 'ar': ['شكراً', 'لكَ', 'على', 'الأمسية', 'الرائعة.'] },
    { 'en': ['I', 'love', 'you.'], 'ar': ['انا', 'احبك'] },
    { 'en': ["Don't", 'look', 'back.'], 'ar': ['لا', 'تنظر', 'إلى', 'الوراء.'] },
    { 'en': ['She', 'sat', 'next', 'to', 'me.'], 'ar': ['جلست', 'بجانبي.'] },
    { 'en': ['Is', 'the', 'cat', 'on', 'the', 'chair', 'or', 'under', 'the', 'chair?'], 'ar': ['هل', 'القطة', 'فوق', 'الكرسي', 'أم', 'أسفله', '؟'] },
    { 'en': ['You', 'should', 'study', 'harder.'], 'ar': ['عليك', 'أن', 'تدرس', 'بجهد', 'أكثر', 'من', 'ذلك.'] },
    { 'en': ['She', 'smiled', 'and', 'accepted', 'my', 'little', 'present.'], 'ar': ['ابتسمت', 'وقبلت', 'هديتي', 'الصغيرة.'] },
    { 'en': ['We', 'have', 'been', 'to', 'Tokyo', 'many', 'times.'], 'ar': ['زرنا', 'طوكيو', 'مرات', 'عديدة.'] },
    { 'en': ['Is', 'that', 'your', 'car?'], 'ar': ['هل', 'تلك', 'سيارتك؟'] },
    { 'en': ["You're", 'forgiven.'], 'ar': ['أنت', 'مُسامح.'] },
    { 'en': ['I', 'want', 'you', 'to', 'tell', 'me', 'the', 'truth.'], 'ar': ['أريدك', 'أن', 'تخبرني', 'الحقيقة.'] },
    { 'en': ['He', 'is', 'too', 'tired', 'to', 'study.'], 'ar': ['لن', 'يستطيع', 'أن', 'يدرس', 'من', 'شدة', 'التعب.'] },
    { 'en': ["It's", 'difficult', 'to', 'learn', 'a', 'foreign', 'language.'], 'ar': ['تعلم', 'لغة', 'أجنبية', 'صعب.'] },
    { 'en': ['She', 'put', 'down', 'her', 'thoughts', 'on', 'paper.'], 'ar': ['دونت', 'ما', 'تفكر', 'به', 'على', 'الورق.'] },
    { 'en': ['Do', 'you', 'play', 'soccer?'], 'ar': ['هل', 'تلعب', 'الكرة؟'] },
    { 'en': ['We', 'had', 'a', 'good', 'time', 'playing', 'cards.'], 'ar': ['أمضينا', 'وقتاً', 'ممتعاً', 'و', 'نحن', 'نلعب', 'الورق.'] },
    { 'en': ['Come', 'down', 'for', 'a', 'minute.'], 'ar': ['إنزل', 'تحت', 'لدقيقة.'] },
    { 'en': ['Have', 'you', 'finished?'], 'ar': ['هل', 'إنتهيت؟'] },
    { 'en': ["I'll", 'give', 'you', 'a', 'call.'], 'ar': ['سأتصل', 'بك.'] },
    { 'en': ["I'm", 'sorry.'], 'ar': ['أنا', 'متأسف.'] },
    { 'en': ['Love', 'is', 'blind.'], 'ar': ['الحب', 'أعمى.'] },
    { 'en': ['It', 'will', 'rain', 'tonight.'], 'ar': ['ستمطر', 'الليلة.'] },
    { 'en': ["Don't", 'move', 'till', 'I', 'get', 'back.'], 'ar': ['إياك', 'والتحرك', 'حتى', 'أرجع.'] },
    { 'en': ["Let's", 'discuss', 'that', 'problem', 'later.'], 'ar': ['لندع', 'الحديث', 'عن', 'هذه', 'المسألة', 'لوقت', 'آخر.'] },
    { 'en': ['Can', 'I', 'see', 'your', 'passport?'], 'ar': ['هل', 'أستطيع', 'أن', 'أرى', 'جواز', 'سفرك؟'] },
    { 'en': ['She', 'was', 'born', 'lucky.'], 'ar': ['هي', 'ولدت', 'محظوظة.'] },
    { 'en': ['I', "didn't", 'mean', 'to', 'do', 'that.'], 'ar': ['لم', 'أقصد', 'فعل', 'ذلك.'] },
    { 'en': ['I', "don't", 'care', 'what', 'your', 'mother', 'said.'], 'ar': ['لا', 'يهمني', 'ما', 'قالتهُ', 'والدتك.'] },
    { 'en': ["I'm", 'just', 'looking,', 'thank', 'you.'], 'ar': ['أنا', 'أبحث', 'فقط,', 'شكراً', 'لكَ.'] },
    { 'en': ['I', 'lost', 'my', 'key.'], 'ar': ['أضعت', 'مفتاحي.'] },
    { 'en': ['I', 'love', 'her', 'and', 'she', 'loves', 'me.'], 'ar': ['أحبها', 'و', 'تحبني.'] },
    { 'en': ['Come', 'forward.'], 'ar': ['تَقَدَم.'] },
    { 'en': ['You', 'will', 'see', 'the', 'difference.'], 'ar': ['سترى', 'الفرق.'] },
    { 'en': ['Wait', 'till', 'the', 'rain', 'stops.'], 'ar': ['انتظر', 'حتى', 'يتوقف', 'المطر', 'عن', 'الهطول.'] },
    { 'en': ["I've", 'tried', 'to', 'warn', 'you.'], 'ar': ['حاولت', 'أن', 'أحذرك.'] },
    { 'en': ["I'd", 'like', 'to', 'study', 'in', 'Paris.'], 'ar': ['أود', 'الدراسة', 'في', 'باريس.'] },
    { 'en': ['She', 'has', 'something', 'in', 'her', 'hand.'], 'ar': ['إنها', 'تمسك', 'شيئاً.'] },
    { 'en': ["You'd", 'better', 'hurry', 'up.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تسرع.'] },
    { 'en': ['He', 'has', 'a', 'car', 'that', 'has', 'four', 'doors.'], 'ar': ['لديه', 'سيارة', 'بأربعة', 'أبواب.'] },
    { 'en': ['My', 'mother', "can't", 'come.'], 'ar': ['أمي', 'لا', 'يمكنها', 'المجيء.'] },
    { 'en': ['Tom', "doesn't", 'like', 'cheese.'], 'ar': ['توم', 'لا', 'يحب', 'أكل', 'الجبن.'] },
    { 'en': ['Did', 'you', 'find', 'a', 'lot', 'of', 'Algerians', 'in', 'Germany?'], 'ar': ['هل', 'وجدت', 'كثيرا', 'من', 'الجزائريين', 'في', 'ألمانيا؟'] },
    { 'en': ['I', 'suddenly', 'became', 'very', 'sad.'], 'ar': ['فجأةً', 'أصبحت', 'حزيناً', 'جداً.'] },
    { 'en': ['I', 'aimed', 'my', 'gun', 'at', 'the', 'en.'], 'ar': ['صوبت', 'مسدسي', 'نحو', 'الهدف.'] },
    { 'en': ['We', "don't", 'have', 'any', 'food.'], 'ar': ['ليس', 'لدينا', 'طعام.'] },
    { 'en': ['Every', 'word', 'in', 'this', 'dictionary', 'is', 'important.'], 'ar': ['كل', 'كلمة', 'في', 'هذا', 'القاموس', 'مهمة.'] },
    { 'en': ['Will', 'it', 'rain', 'today?'], 'ar': ['هل', 'ستمطر', 'اليوم؟'] },
    { 'en': ['How', 'old', 'are', 'you?'], 'ar': ['كم', 'عمرك؟'] },
    { 'en': ['I', 'saw', 'a', 'bird', 'flying', 'over', 'a', 'tree.'], 'ar': ['رأيتُ', 'طائراً', 'يُحلّقُ', 'فوقَ', 'شجرةٍ.'] },
    { 'en': ['I', "don't", 'know', 'where', 'my', 'ski', 'gloves', 'are.'], 'ar': ['لا', 'أعرف', 'أين', 'توجد', 'قفازات', 'التزلج', 'الخاصة', 'بي.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'America', 'someday.'], 'ar': ['أريد', 'أن', 'أذهب', 'إلى', 'أمريكا', 'في', 'يوم', 'من', 'الأيام.'] },
    { 'en': ['Do', 'you', 'own', 'a', 'computer?'], 'ar': ['هل', 'لديك', 'حاسب', 'آلي؟'] },
    { 'en': ['What', 'do', 'you', 'see', 'in', 'the', 'picture?'], 'ar': ['ماذا', 'ترين', 'في', 'الصورة؟'] },
    { 'en': ['I', "can't", 'remember', 'his', 'name.'], 'ar': ['لا', 'أستطيع', 'تذكر', 'اسمِهِ.'] },
    { 'en': ['I', 'have', 'to', 'study', 'Japanese.'], 'ar': ['عليّ', 'أن', 'أدرس', 'اليابانية.'] },
    { 'en': ['Do', 'you', 'want', 'a', 'drink?'], 'ar': ['هل', 'تريد', 'أن', 'تشرب؟'] },
    { 'en': ["We've", 'already', 'done', 'our', 'work.'], 'ar': ['سبق', 'أن', 'أنهينا', 'عملنا.'] },
    { 'en': ['When', 'was', 'this', 'temple', 'built?'], 'ar': ['متى', 'بُني', 'هذا', 'المعبد؟'] },
    { 'en': ['Tom', 'wanted', 'you', 'to', 'know.'], 'ar': ['أراد', 'توم', 'أن', 'تعرف.'] },
    { 'en': ['We', 'are', 'happy.'], 'ar': ['إنا', 'سعداء.'] },
    { 'en': ['Stop', 'hitting', 'your', 'brother.'], 'ar': ['توقف', 'عن', 'ضرب', 'أخيك.'] },
    { 'en': ['You', 'and', 'I', 'make', 'a', 'good', 'team.'], 'ar': ['أنا', 'وأنت', 'نشكل', 'فريقا', 'جيدا.'] },
    { 'en': ['How', 'far', 'is', 'the', 'garden', 'from', 'your', 'house?'], 'ar': ['كم', 'تبعد', 'الحديقة', 'عن', 'منزلك؟'] },
    { 'en': ["It's", 'too', 'dangerous', 'for', 'you', 'to', 'stay', 'here.'], 'ar': ['خطير', 'جداً', 'بالنسبة', 'لكَ', 'أن', 'تكون', 'هنا.'] },
    { 'en': ['I', "don't", 'get', 'what', 'you', 'mean.'], 'ar': ['لا', 'أفهم', 'ما', 'تقصده.'] },
    { 'en': ['I', "don't", 'regret', 'coming', 'here.'], 'ar': ['أنا', 'لست', 'نادماً', 'على', 'مجيئي', 'هنا.'] },
    { 'en': ['There', 'were', 'three', 'men.'], 'ar': ['كان', 'هنالك', 'ثلاثة', 'رجال.'] },
    { 'en': ['Please', 'help', 'me', 'clean', 'the', 'house.'], 'ar': ['ساعدني', 'في', 'تنظيف', 'البيت', 'من', 'فضلك.'] },
    { 'en': ['Do', 'you', 'see', 'the', 'raccoon?'], 'ar': ['هل', 'ترى', 'الراكون؟'] },
    { 'en': ['Check', 'again.'], 'ar': ['تحقق', 'مجدداً.'] },
    { 'en': ['Is', 'this', 'your', 'car?'], 'ar': ['أهذه', 'سيارتك؟'] },
    { 'en': ['My', 'father', 'works', 'for', 'a', 'factory.'], 'ar': ['يعمل', 'أبي', 'في', 'المصنع.'] },
    { 'en': ['Tom', 'thanked', 'me', 'for', 'the', 'gift.'], 'ar': ['توم', 'قام', 'بشكري', 'على', 'الهدية.'] },
    { 'en': ['You', 'deserve', 'to', 'succeed.'], 'ar': ['تستحق', 'أن', 'تنجح.'] },
    { 'en': ['Have', 'you', 'done', 'all', 'your', 'homework?'], 'ar': ['هل', 'أنهيت', 'كل', 'واجباتك؟'] },
    { 'en': ['Were', 'you', 'there', 'before?'], 'ar': ['هل', 'سبق', 'وكنت', 'هناك', 'من', 'قبل', '؟'] },
    { 'en': ['This', 'book', 'is', 'hard', 'for', 'me', 'to', 'read.'], 'ar': ['يصعب', 'علي', 'قراءة', 'هذا', 'الكتاب.'] },
    { 'en': ['I', 'went', 'home', 'and', 'cried.'], 'ar': ['ذهبت', 'للمنزل', 'وبكيت.'] },
    { 'en': ['The', 'conference', 'will', 'end', 'tomorrow.'], 'ar': ['سينتهي', 'المؤتمر', 'غداً.'] },
    { 'en': ['School', 'begins', 'the', 'day', 'after', 'tomorrow.'], 'ar': ['ستبدأ', 'الدراسة', 'بعد', 'غد.'] },
    { 'en': ['I', 'thought', 'you', 'would', 'never', 'come', 'here.'], 'ar': ['اعتقدت', 'انك', 'لن', 'تأتي', 'الى', 'هنا'] },
    { 'en': ['Lack', 'of', 'sleep', 'is', 'bad', 'for', 'your', 'health.'], 'ar': ['قلة', 'النوم', 'مضر', 'بصحتك.'] },
    { 'en': ['Please', 'close', 'the', 'door.'], 'ar': ['من', 'فضلك', 'أغلق', 'الباب.'] },
    { 'en': ['Everyone', 'went', 'there,', "didn't", 'they?'], 'ar': ['ذهب', 'الجميع', 'إلى', 'هناك،', 'أليس', 'كذلك؟'] },
    { 'en': ['How', 'will', 'I', 'pay', 'my', 'debts', 'now?'], 'ar': ['كيفَ', 'سأسدد', 'ديونى', 'الآن؟'] },
    { 'en': ['We', 'have', 'a', 'lot', 'of', 'earthquakes', 'in', 'Japan.'], 'ar': ['لدينا', 'الكثير', 'من', 'الزلازل', 'في', 'اليابان.'] },
    { 'en': ['We', "didn't", 'see', 'her', 'anywhere.'], 'ar': ['لم', 'نرها', 'مطلقاً.'] },
    { 'en': ['The', 'area', 'was', 'quiet.'], 'ar': ['كان', 'المكان', 'هادئاً.'] },
    { 'en': ['I', "don't", 'care', 'what', 'anyone', 'says.'], 'ar': ['لا', 'اهتم', 'بما', 'يقوله', 'الآخرون.'] },
    { 'en': ['This', 'will', 'be', 'a', 'very', 'dangerous', 'mission.'], 'ar': ['هذه', 'ستكون', 'مهمة', 'خطيرة', 'جداً.'] },
    { 'en': ['What', 'time', 'did', 'he', 'say', "he'd", 'come?'], 'ar': ['ماذا', 'قال', 'عن', 'ميعاد', 'وصوله؟'] },
    { 'en': ["I'd", 'be', 'unhappy,', 'but', 'I', "wouldn't", 'kill', 'myself.'], 'ar': ['لن', 'أكون', 'سعيدًا،', 'لكن', 'لن', 'أقتل', 'نفسي.'] },
    { 'en': ["Don't", 'read', 'in', 'this', 'room.'], 'ar': ['لا', 'تقرأ', 'في', 'تلك', 'الغرفة.'] },
    { 'en': ['The', 'details', "aren't", 'necessary.'], 'ar': ['التفاصيل', 'ليست', 'مهمة.'] },
    { 'en': ['Do', 'you', 'want', 'to', 'be', 'rich?'], 'ar': ['هل', 'تريد', 'أن', 'تصبح', 'غنياً؟'] },
    { 'en': ["Let's", 'eat', 'sushi.'], 'ar': ['لنأكل', 'السوشي.'] },
    { 'en': ['Prices', 'have', 'dropped', 'recently.'], 'ar': ['انخفضت', 'الأسعار', 'مؤخراً.'] },
    { 'en': ['I', 'make', '€100', 'a', 'day.'], 'ar': ['أَجْنِيْ', 'مِئْةَ', 'يُوْرَو', 'فِيْ', 'اليَوْم.'] },
    { 'en': ["That's", 'my', 'cat.'], 'ar': ['تلك', 'قطتي.'] },
    { 'en': ['I', 'know', 'that', 'you', 'like', 'coffee.'], 'ar': ['أعلم', 'أنّك', 'تحبّ', 'القهوة.'] },
    { 'en': ['I', "wasn't", 'aware', 'Tom', 'should', 'do', 'that.'], 'ar': ['لم', 'أكن', 'أعلم', 'بأنه', 'على', 'توم', 'فعل', 'ذلك.'] },
    { 'en': ['We', 'are', 'not', 'Americans.'], 'ar': ['نحن', 'لسنا', 'أمريكيين.'] },
    { 'en': ['What', 'time', 'are', 'you', 'coming', 'back?'], 'ar': ['متى', 'سترجع؟'] },
    { 'en': ["It's", 'useless', 'to', 'talk', 'to', 'Tom.'], 'ar': ['الكلام', 'مع', 'توم', 'بلا', 'فائدة.'] },
    { 'en': ["I'm", 'so', 'proud', 'of', 'you.'], 'ar': ['أنا', 'فخورة', 'جداً', 'بكِ.'] },
    { 'en': ['How', 'did', 'you', 'cook', 'this?'], 'ar': ['كيف', 'طهيت', 'هذا؟'] },
    { 'en': ["It's", 'a', 'popular', 'tourist', 'destination.'], 'ar': ['انهُ', 'مقصد', 'سياحي', 'مشهور.'] },
    { 'en': ['Hurry', 'up,', 'or', "you'll", 'miss', 'the', 'bus.'], 'ar': ['أسرع', 'و', 'إلا', 'فاتك', 'الباص.'] },
    { 'en': ['This', 'chair', 'is', 'ugly.'], 'ar': ['هذا', 'الكرسي', 'قبيح.'] },
    { 'en': ['Please', 'deal', 'the', 'card.'], 'ar': ['من', 'فضلك', 'وزّع', 'الورق.'] },
    { 'en': ['I', 'asked', 'Tom', 'to', 'stop', 'following', 'us.'], 'ar': ['طلبت', 'من', 'توم', 'أن', 'يتوقف', 'عن', 'ملاحقتنا.'] },
    { 'en': ['It', 'was', 'a', 'perfect', 'morning.'], 'ar': ['لقد', 'كان', 'صباحاً', 'مثالياً.'] },
    { 'en': ['Without', 'your', 'advice,', 'I', 'would', 'have', 'failed.'], 'ar': ['من', 'دون', 'نصائحك', 'لكنت', 'فشلت.'] },
    { 'en': ['I', 'really', 'love', 'cats.'], 'ar': ['أنا', 'حقا', 'احب', 'القطط.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that', 'belongs', 'to', 'Tom?'], 'ar': ['كيف', 'تعلم', 'بملكية', 'توم', 'لهذا؟'] },
    { 'en': ['I', 'saw', 'him', 'running.'], 'ar': ['رأيته', 'يركض.'] },
    { 'en': ['Today', 'is', 'Wednesday.'], 'ar': ['اليوم', 'هو', 'الأربعاء'] },
    { 'en': ['We', 'want', 'complete', 'sentences.'], 'ar': ['نريد', 'جملا', 'مكتملة.'] },
    { 'en': ['I', 'need', 'you', 'to', 'trust', 'me.'], 'ar': ['أريد', 'منك', 'أن', 'تثق', 'بي', '.'] },
    { 'en': ['Terrific!'], 'ar': ['رائع!'] },
    { 'en': ['You', "shouldn't", 'go.'], 'ar': ['عليك', 'ألا', 'تذهب.'] },
    { 'en': ['I', "can't", 'understand', 'his', 'feelings.'], 'ar': ['لا', 'يمكنني', 'فهم', 'مشاعره.'] },
    { 'en': ['How', 'did', 'you', 'come', 'here?'], 'ar': ['كيف', 'جئت', 'إلى', 'هنا؟'] },
    { 'en': ['Tom', 'is', 'now', 'studying', 'in', 'the', 'library.'], 'ar': ['يدرس', 'توم', 'في', 'المكتبة', 'الآن.'] },
    { 'en': ['I', 'will', 'wait', 'for', 'you', 'in', 'front', 'of', 'the', 'school.'], 'ar': ['سأنتظرك', 'أمام', 'المدرسة.'] },
    { 'en': ['He', 'will', 'come', 'soon.'], 'ar': ['سيأتي', 'قريباً.'] },
    { 'en': ['They', 'showed', 'it', 'to', 'our', 'company.'], 'ar': ['إنهم', 'أظهروها', 'لشركتنا.'] },
    { 'en': ['I', 'want', 'to', 'say', 'something', 'to', 'you.'], 'ar': ['أريد', 'أن', 'أقول', 'لك', 'شيئاً.'] },
    { 'en': ['Tom', 'has', 'a', 'good', 'head', 'for', 'numbers.'], 'ar': ['لدى', 'توم', 'درجات', 'جيدة'] },
    { 'en': ['My', 'tooth', 'hurts.'], 'ar': ['سني', 'يؤلمني.'] },
    { 'en': ['Never', 'say', 'never.'], 'ar': ['لا', 'اقو', 'ابدا'] },
    { 'en': ['Is', 'that', 'black', 'bag', 'yours?'], 'ar': ['هل', 'الحقيبة', 'السوداء', 'لك؟'] },
    { 'en': ['She', 'is', 'loved', 'by', 'everyone.'], 'ar': ['الجميع', 'يحبها.'] },
    { 'en': ['Sorry', 'for', 'the', 'interruption.'], 'ar': ['المعذره', 'على', 'الإزعاج.'] },
    { 'en': ['I', "don't", 'want', 'to', 'lose', 'you.'], 'ar': ['لا', 'أريد', 'خسارتَكِ.'] },
    { 'en': ['Tom', 'loves', 'Mary,', 'but', 'Mary', 'loves', 'John.'], 'ar': ['توم', 'يحب', 'ماري،', 'ولكنها', 'تحب', 'جون.'] },
    { 'en': ['Tom', 'is', 'a', 'kind', 'man.'], 'ar': ['توم', 'رجل', 'لطيف', '.'] },
    { 'en': ['Tom', "isn't", 'happy.'], 'ar': ['توم', 'ليس', 'سعيدا.'] },
    { 'en': ['It', 'is', 'their', 'only', 'choice.'], 'ar': ['إنه', 'الخيار', 'الوحيد', 'لديهم.'] },
    { 'en': ['The', 'smell', 'of', 'food', 'made', 'me', 'hungry.'], 'ar': ['جعلتني', 'رائحة', 'الطعام', 'جائعاً.'] },
    { 'en': ['Let', 'me', 'go.'], 'ar': ['دَعني', 'أذهب.'] },
    { 'en': ['The', 'answer', 'is', 'right.'], 'ar': ['الجواب', 'صحيح.'] },
    { 'en': ["I'm", 'old', 'enough.'], 'ar': ['أنا', 'كبير', 'بما', 'يكفي.'] },
    { 'en': ["Where's", 'the', 'dining', 'room?'], 'ar': ['أين', 'غرفة', 'الطعام؟'] },
    { 'en': ['You', 'can', 'study', 'here.'], 'ar': ['بإمكانك', 'المذاكرة', 'هنا.'] },
    { 'en': ['My', 'friend', 'should', 'be', 'home', 'now.'], 'ar': ['صديقي', 'يجب', 'أن', 'يكون', 'بالمنزل', 'الآن.'] },
    { 'en': ["That's", 'a', 'good', 'question.'], 'ar': ['هذا', 'سؤال', 'جيد.'] },
    { 'en': ['He', 'narrowly', 'escaped', 'death.'], 'ar': ['نجا', 'من', 'الموت', 'بأعجوبة.'] },
    { 'en': ['Tom', 'has', 'a', 'right', 'to', 'know', 'the', 'truth.'], 'ar': ['توم', 'له', 'الحق', 'في', 'معرفة', 'الحقيقة.'] },
    { 'en': ['I', 'make', 'an', 'honest', 'living.'], 'ar': ['أنا', 'أعيش', 'عيشة', 'شريفة.'] },
    { 'en': ['She', 'divided', 'the', 'cake', 'into', 'six', 'pieces.'], 'ar': ['قسمت', 'الكعكة', 'إلى', 'ستة', 'قطع.'] },
    { 'en': ['School', 'starts', 'in', 'September', 'in', 'Europe.'], 'ar': ['تبدأ', 'الدراسة', 'في', 'أوروبا', 'شهر', 'سبتمبر.'] },
    { 'en': ['Tom', "isn't", 'going', 'to', 'do', 'that.'], 'ar': ['لن', 'يفعل', 'توم', 'ذلك.'] },
    { 'en': ['It', 'snowed', 'hard', 'yesterday.'], 'ar': ['أثلجت', 'بشدة', 'الأمس.'] },
    { 'en': ['He', 'jumped', 'out', 'the', 'window.'], 'ar': ['قفز', 'من', 'النافذة.'] },
    { 'en': ["What's", 'your', 'favorite', 'music?'], 'ar': ['ما', 'هي', 'موسيقاكَ', 'المفضّلة؟'] },
    { 'en': ['This', 'bread', 'is', 'hard', 'as', 'a', 'rock.'], 'ar': ['هذه', 'الخبزة', 'صلبة', 'كالصخر.'] },
    { 'en': ['Japan', 'imports', 'oranges', 'from', 'California.'], 'ar': ['تستورد', 'اليابانُ', 'الفاكهةَ', 'من', 'كاليفورنيا.'] },
    { 'en': ['The', 'audience', 'applauded', 'the', 'actress.'], 'ar': ['صفق', 'الجمهور', 'للممثلة.'] },
    { 'en': ["We're", 'not', 'afraid.'], 'ar': ['لسنا', 'خائفين'] },
    { 'en': ["It's", 'great', 'to', 'see', 'you.'], 'ar': ['إنه', 'من', 'الرائع', 'رؤيتك'] },
    { 'en': ['He', 'placed', 'the', 'book', 'on', 'the', 'shelf.'], 'ar': ['وضع', 'الكتاب', 'على', 'الرف.'] },
    { 'en': ['What', 'are', 'we', 'having', 'for', 'dinner', 'tonight?'], 'ar': ['ما', 'عشاؤنا', 'الليلة؟'] },
    { 'en': ['Tom', 'would', 'want', 'me', 'to', 'say', 'that.'], 'ar': ['توم', 'يريد', 'مِني', 'أن', 'أقول', 'ذلك.'] },
    { 'en': ['Do', 'you', 'have', 'any', 'pencils?'], 'ar': ['هل', 'لديك', 'أي', 'أقلام', 'رصاص؟'] },
    { 'en': ['He', 'exploded', 'with', 'anger.'], 'ar': ['انفجر', 'غاضباً.'] },
    { 'en': ['Clearly', 'you', 'are', 'mistaken.'], 'ar': ['من', 'الواضح', 'أنك', 'مخطئ.'] },
    { 'en': ['I', 'will', 'give', 'you', 'this', 'book.'], 'ar': ['سأعطيك', 'هذا', 'الكتاب.'] },
    { 'en': ['There', 'is', 'a', 'fork', 'missing.'], 'ar': ['ينقص', 'شوكة.'] },
    { 'en': ['That', 'bus', 'will', 'take', 'you', 'to', 'the', 'zoo.'], 'ar': ['ستُوصلك', 'تلك', 'الحافلة', 'إلى', 'حديقة', 'الحيوان.'] },
    { 'en': ['Hey,', 'listen', 'to', 'this.'], 'ar': ['استمع', 'لهذا'] },
    { 'en': ['Would', 'you', 'play', 'with', 'me?'], 'ar': ['هل', 'ستلعب', 'معي؟'] },
    { 'en': ['I', 'do', 'not', 'understand.'], 'ar': ['لا', 'أفهم.'] },
    { 'en': ['He', 'only', 'paid', 'ten', 'dollars', 'for', 'that', 'shirt.'], 'ar': ['دفع', 'عشرة', 'دولارات', 'فقط', 'لذلك', 'القميص.'] },
    { 'en': ['He', 'is', 'getting', 'better', 'day', 'by', 'day.'], 'ar': ['إنه', 'يتحسّن', 'يوماً', 'بعد', 'يوم.'] },
    { 'en': ['I', 'have', 'many', 'friends.'], 'ar': ['لدي', 'الكثير', 'من', 'الأصدقاء.'] },
    { 'en': ['Have', 'you', 'been', 'to', 'France', 'before?'], 'ar': ['هل', 'سبق', 'لك', 'أن', 'ذهبت', 'إلى', 'فرنسا؟'] },
    { 'en': ['They', 'had', 'to', 'stay', 'at', 'home', 'all', 'day.'], 'ar': ['كان', 'عليهم', 'البقاء', 'في', 'المنزل', 'طوال', 'اليوم.'] },
    { 'en': ['Tom', 'is', 'losing', 'blood.'], 'ar': ['توم', 'ينزف.'] },
    { 'en': ['My', 'mother', 'is', 'making', 'my', 'father', 'a', 'cake.'], 'ar': ['أمي', 'تعد', 'كعكة', 'لأبي.'] },
    { 'en': ['I', 'quickly', 'ate', 'lunch.'], 'ar': ['أكلت', 'طعام', 'الغداء', 'بسرعة.'] },
    { 'en': ['Yoga', 'comes', 'from', 'India.'], 'ar': ['جاءت', 'اليوغا', 'من', 'الهند.'] },
    { 'en': ['He', 'whistled', 'as', 'he', 'walked.'], 'ar': ['صفّر', 'و', 'هو', 'يمشي.'] },
    { 'en': ['She', 'prefers', 'staying', 'home', 'to', 'going', 'out.'], 'ar': ['إنها', 'تفضل', 'البقاء', 'في', 'المنزل', 'على', 'الخروج.'] },
    { 'en': ['This', 'pen', 'has', 'run', 'dry.'], 'ar': ['جفّ', 'القلم.'] },
    { 'en': ['I', 'will', 'go', 'to', 'America', 'tomorrow.'], 'ar': ['سأغادر', 'إلى', 'أمريكا', 'في', 'الغد.'] },
    { 'en': ['He', 'is', 'an', 'ace', 'pitcher.'], 'ar': ['هو', 'رامي', 'اساسي', 'في', 'رياضة', 'البيسبول.'] },
    { 'en': ['I', 'called', 'you', 'in', 'here', 'to', 'ask', 'you', 'a', 'question.'], 'ar': ['لقد', 'دعوتك', 'إلی', 'هنا', 'لأسألك', 'سؤالا'] },
    { 'en': ['You', 'should', 'have', 'done', 'so.'], 'ar': ['كان', 'عليك', 'فعل', 'ذلك.'] },
    { 'en': ['When', 'will', 'they', 'arrive?'], 'ar': ['متى', 'سيصلوا؟'] },
    { 'en': ['I', 'studied', 'for', 'one', 'hour.'], 'ar': ['درست', 'لمدة', 'ساعة.'] },
    { 'en': ['The', 'war', 'lasted', 'two', 'years.'], 'ar': ['دامت', 'الحرب', 'سنتين.'] },
    { 'en': ['Were', 'you', 'at', 'school', 'at', 'that', 'time?'], 'ar': ['أكنت', 'في', 'المدرسة', 'في', 'ذلك', 'الوقت؟'] },
    { 'en': ['Could', 'we', 'have', 'a', 'fork?'], 'ar': ['ألنا', 'بشوكة؟'] },
    { 'en': ["He's", 'behaving', 'oddly.'], 'ar': ['إنه', 'يتصرف', 'بغرابة.'] },
    { 'en': ["It's", 'as', 'lovely', 'as', 'a', 'rose.'], 'ar': ['جميلة', 'كالوردة.'] },
    { 'en': ['Tom', 'was', 'working', 'for', 'me.'], 'ar': ['كان', 'توم', 'يعمل', 'عندي.'] },
    { 'en': ['In', 'which', 'folder', 'did', 'you', 'save', 'the', 'file?'], 'ar': ['في', 'أي', 'مجلدٍ', 'حفظت', 'الملف؟'] },
    { 'en': ['He', 'is', 'handsome', 'and', 'clever.'], 'ar': ['إنه', 'وسيم', 'و', 'ذكي.'] },
    { 'en': ['I', 'want', 'to', 'come,', 'too.'], 'ar': ['أريد', 'أن', 'آتي', 'أيضاً'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['كيف', 'تعلم', 'ذلك؟'] },
    { 'en': ['Tom', 'gave', 'Mary', 'the', 'wrong', 'key.'], 'ar': ['أعطى', 'توم', 'ماري', 'المفتاح', 'الخطأ.'] },
    { 'en': ["I'm", 'sure', 'Tom', 'is', 'bored.'], 'ar': ['أنا', 'متأكد', 'أن', 'توم', 'ممل.'] },
    { 'en': ['I', "don't", 'like', 'movies.'], 'ar': ['لا', 'أحب', 'مشاهدة', 'الأفلام.'] },
    { 'en': ["I'm", 'married.'], 'ar': ['أنا', 'متزوج.'] },
    { 'en': ['This', 'is', 'my', 'brother.'], 'ar': ['هذا', 'أخي'] },
    { 'en': ['Why', 'did', 'you', 'tell', 'Tom', 'it', 'was', 'my', 'fault?'], 'ar': ['لماذا', 'أخبرت', 'توم', 'بأنه', 'كان', 'خطئي؟'] },
    { 'en': ["I'm", 'sure', 'Tom', 'will', 'be', 'here', 'soon.'], 'ar': ['أنا', 'متأكدة', 'أن', 'توم', 'سيكون', 'هنا', 'قريباً.'] },
    { 'en': ['I', 'have', 'two', 'daughters.'], 'ar': ['لي', 'بنتان.'] },
    { 'en': ['Welcome.'], 'ar': ['مرحباً', 'بك!'] },
    { 'en': ['You', 'are', 'exaggerating', 'the', 'problem.'], 'ar': ['إنك', 'تعطي', 'المسألة', 'أكثر', 'من', 'حقها.'] },
    { 'en': ['Cattle', 'feed', 'on', 'grass.'], 'ar': ['يتغذى', 'القطيع', 'على', 'العشب.'] },
    { 'en': ['She', 'likes', 'her', 'school', 'a', 'lot.'], 'ar': ['إنها', 'تحب', 'مدرستها', 'كثيراً.'] },
    { 'en': ['Mary', 'can', 'dance', 'well.'], 'ar': ['تستطيع', 'ماري', 'الرقص', 'جيدا.'] },
    { 'en': ["I'm", 'rarely', 'invited', 'to', 'parties.'], 'ar': ['نادرًا', 'ما', 'أُدعى', 'إلى', 'الحفلات.'] },
    { 'en': ['That', 'was', 'not', 'my', 'intention.'], 'ar': ['كان', 'ذاك', 'ما', 'نويته.'] },
    { 'en': ['I', 'got', 'it.'], 'ar': ['فهمتُهُ.'] },
    { 'en': ['Tom', 'hid', 'the', 'book', 'under', 'his', 'pillow.'], 'ar': ['أخفى', 'توم', 'الكتاب', 'تحت', 'الوسادة.'] },
    { 'en': ['Mary', 'bought', 'a', 'new', 'dress', 'for', 'the', 'party.'], 'ar': ['ابتاعت', 'ماري', 'فستانًا', 'جديدًا', 'للحفلة.'] },
    { 'en': ["I'd", 'rather', 'not', 'go', 'out', 'today.'], 'ar': ['أفضل', 'ألا', 'أخرج', 'اليوم.'] },
    { 'en': ['My', 'boss', 'is', 'twice', 'as', 'old', 'as', 'I', 'am.'], 'ar': ['رئيسي', 'في', 'العمل', 'يكبرني', 'عمراً', 'بمرتين.'] },
    { 'en': ['Why', 'were', 'you', 'there?'], 'ar': ['لماذا', 'كنت', 'هناك؟'] },
    { 'en': ['You', 'must', 'study', 'hard.'], 'ar': ['عليك', 'أن', 'تدرس', 'جاهداً.'] },
    { 'en': ['That', 'man', 'has', 'a', 'foul', 'mouth.'], 'ar': ['ذلك', 'الرجل', 'لديه', 'فم', 'كريه.'] },
    { 'en': ['What', 'they', 'told', 'you', 'is', 'not', 'true.'], 'ar': ['ما', 'أخبروك', 'به', 'ليس', 'صحيحاً.'] },
    { 'en': ['Police', "shouldn't", 'take', 'bribes.'], 'ar': ['يجب', 'على', 'الشرطة', 'ألا', 'تقبل', 'الرشوة.'] },
    { 'en': ['You', 'should', 'swear', 'on', 'the', 'Bible.'], 'ar': ['ينبغي', 'أن', 'تقسم', 'ويدك', 'على', 'الإنجيل.'] },
    { 'en': ['I', 'was', 'made', 'to', 'go', 'there.'], 'ar': ['أُجبرتُ', 'على', 'الذهاب', 'هناك.'] },
    { 'en': ['My', 'sister', 'lives', 'near', 'Yokohama.'], 'ar': ['أختي', 'تسكن', 'بالقرب', 'من', 'يوكوهاما.'] },
    { 'en': ['She', 'can', 'drive', 'a', 'car.'], 'ar': ['يمكنها', 'أن', 'تقود', 'السيارة.'] },
    { 'en': ['Where', 'is', 'the', 'mailbox?'], 'ar': ['أين', 'صندوق', 'البريد؟'] },
    { 'en': ['Tom', "isn't", 'at', 'all', 'stupid.'], 'ar': ['توم', 'ليس', 'غبيا', 'على', 'الاطلاق'] },
    { 'en': ['I', 'have', 'no', 'money', 'on', 'me.'], 'ar': ['ليس', 'معي', 'نقود'] },
    { 'en': ['I', 'asked', 'him', 'to', 'drive', 'me', 'home.'], 'ar': ['طلبت', 'منه', 'أن', 'يوصلني', 'إلى', 'المنزل.'] },
    { 'en': ['Hello!'], 'ar': ['مرحباً.'] },
    { 'en': ['Stop', 'gawking.'], 'ar': ['كف', 'عن', 'التحديق.'] },
    { 'en': ["He'll", 'be', 'here', 'any', 'second.'], 'ar': ['سيكون', 'هنا', 'في', 'أي', 'ثانية'] },
    { 'en': ["It's", 'too', 'big.'], 'ar': ['هل', 'توم', 'كبير؟'] },
    { 'en': ['Not', 'all', 'Muslims', 'observe', 'Ramadan.'], 'ar': ['لا', 'يلتزم', 'جميع', 'المسلمين', 'بشرائع', 'رمضان.'] },
    { 'en': ['He', 'is', 'a', 'genius.'], 'ar': ['إنه', 'ذكي', 'جداً.'] },
    { 'en': ['Did', 'you', 'find', 'the', 'book', 'you', 'were', 'looking', 'for?'], 'ar': ['أوجدت', 'الكتاب', 'الذي', 'كنت', 'تبحث', 'عنه؟'] },
    { 'en': ['You', 'should', 'start', 'as', 'early', 'as', 'you', 'can.'], 'ar': ['ينبغي', 'أن', 'تبدأَ', 'أبكر', 'ما', 'يمكنك.'] },
    { 'en': ['He', 'likes', 'his', 'school', 'a', 'lot.'], 'ar': ['إنه', 'يحب', 'مدرسته', 'كثيراً.'] },
    { 'en': ["It's", 'hot.'], 'ar': ['الجو', 'حار'] },
    { 'en': ['Are', 'these', 'books', 'yours?'], 'ar': ['هل', 'هذه', 'الكتب', 'لك؟'] },
    { 'en': ['No', 'one', 'cares', 'about', 'that', 'anymore.'], 'ar': ['لا', 'أحد', 'يهتم', 'حيال', 'ذلك', 'بعد', 'الآن.'] },
    { 'en': ['I', 'wrote', 'a', 'letter', 'to', 'my', 'mother.'], 'ar': ['كتبت', 'رسالة', 'إلى', 'أمي.'] },
    { 'en': ['The', 'stock', 'market', 'is', 'very', 'active.'], 'ar': ['سوق', 'الأسهم', 'يشهد', 'نشاطاً', 'زائداً.'] },
    { 'en': ['Come', 'on.', "Let's", 'go', 'home.'], 'ar': ['هيا.', 'دعونا', 'نذهب', 'إلى', 'البيت.'] },
    { 'en': ['A', 'lot', 'of', 'tourists', 'invaded', 'the', 'island.'], 'ar': ['اجتاح', 'العديد', 'من', 'السياح', 'الجزيرة', '.'] },
    { 'en': ["I'm", 'only', 'joking.'], 'ar': ['أنا', 'فقط', 'أمزح.'] },
    { 'en': ["I've", 'been', 'waiting', 'for', 'you', 'for', '5', 'hours.'], 'ar': ['إني', 'أنتظرك', 'منذ', 'خمس', 'ساعات.'] },
    { 'en': ['Why', 'not', 'see', 'the', 'doctor?'], 'ar': ['لم', 'تر', 'طبيباً؟'] },
    { 'en': ['Have', 'you', 'answered', 'that', 'letter', 'yet?'], 'ar': ['هل', 'رددت', 'على', 'تلك', 'الرسالة', 'بعد؟'] },
    { 'en': ["It's", 'fun', 'to', 'speak', 'in', 'English.'], 'ar': ['تحدث', 'الإنجليزية', 'ممتع.'] },
    { 'en': ["Don't", 'ask', 'me', 'for', 'money.'], 'ar': ['لا', 'تطلب', 'مني', 'مالا.'] },
    { 'en': ['I', 'thought', 'you', 'might', 'want', 'to', 'come', 'along.'], 'ar': ['ظننت', 'أنك', 'قد', 'تود', 'الذهاب', 'معي.'] },
    { 'en': ["He's", 'a', 'devout', 'Catholic.'], 'ar': ['إنهُ', 'متدين', 'كاثوليكي.'] },
    { 'en': ['Holland', 'is', 'a', 'small', 'country.'], 'ar': ['هولندا', 'بلد', 'صغير.'] },
    { 'en': ["Didn't", 'it', 'occur', 'to', 'you', 'to', 'shut', 'the', 'windows?'], 'ar': ['ألم', 'يخطر', 'لك', 'أن', 'تغلق', 'النوافذ؟'] },
    { 'en': ['Look', 'at', 'the', 'boy', 'jump!'], 'ar': ['أنظر', 'إلى', 'الولد', 'و', 'هو', 'يقفز!'] },
    { 'en': ['I', 'prefer', 'biking.'], 'ar': ['أفضل', 'ركوب', 'الدراجة.'] },
    { 'en': ['Shut', 'up!'], 'ar': ['اخرس!'] },
    { 'en': ['I', "didn't", 'mean', 'to', 'do', 'that.'], 'ar': ['لم', 'أقصد', 'أن', 'أفعل', 'ذلك.'] },
    { 'en': ['Are', 'you', 'in', 'a', 'relationship?'], 'ar': ['هل', 'أنت', 'في', 'علاقة؟'] },
    { 'en': ['Go', 'on.'], 'ar': ['استمر.'] },
    { 'en': ['This', 'pen', 'has', 'run', 'dry.'], 'ar': ['جفّ', 'هذا', 'القلم.'] },
    { 'en': ['Did', 'you', 'go', 'to', 'school', 'today?'], 'ar': ['أذهبت', 'إلى', 'المدرسة', 'اليوم؟'] },
    { 'en': ['I', 'love', 'French', 'films.'], 'ar': ['أحب', 'الأفلام', 'الفرنسية.'] },
    { 'en': ['All', 'Tom', 'really', 'needed', 'was', 'about', 'ten', 'dollars.'], 'ar': ['كل', 'ما', 'كان', 'يحتاجه', 'توم', 'كان', 'حقاً', 'بخصوص', 'عشرة', 'دولارات.'] },
    { 'en': ['These', 'are', 'my', 'books.'], 'ar': ['هذه', 'كتبي.'] },
    { 'en': ['Did', 'you', 'order', 'the', 'book?'], 'ar': ['هل', 'طلبت', 'الكتاب؟'] },
    { 'en': ['I', 'disagree', 'with', 'you.'], 'ar': ['أنا', 'اختلف', 'معك', 'في', 'الرأي.'] },
    { 'en': ['I', 'like', 'jazz.'], 'ar': ['أحب', 'موسيقى', 'الجاز.'] },
    { 'en': ['Tom', 'is', 'my', 'friend.'], 'ar': ['توم', 'صديقي.'] },
    { 'en': ['You', 'must', 'make', 'up', 'for', 'lost', 'time.'], 'ar': ['يجب', 'عليك', 'أن', 'تعوض', 'عن', 'الوقت', 'الضائع.'] },
    { 'en': ["Let's", 'play', 'soccer.'], 'ar': ['لنلعب', 'كرة', 'القدم.'] },
    { 'en': ["I'd", 'like', 'to', 'sing', 'you', 'a', 'song.'], 'ar': ['اود', 'أن', 'اغني', 'لك.'] },
    { 'en': ['I', "don't", 'know', 'because', 'I', "wasn't", 'there.'], 'ar': ['لا', 'أعرف', 'لأني', 'لم', 'أكن', 'هناك.'] },
    { 'en': ['What', 'shall', 'I', 'do', 'with', 'the', 'knife?'], 'ar': ['ماذا', 'أفعل', 'بالسكين؟'] },
    { 'en': ['I', 'had', 'my', 'brother', 'repair', 'my', 'bicycle.'], 'ar': ['جعلت', 'أخي', 'يصلح', 'دراجتي.'] },
    { 'en': ['Where', 'are', 'the', 'others?'], 'ar': ['أين', 'الآخرين؟'] },
    { 'en': ['I', 'hope', 'to', 'see', 'you', 'again.'], 'ar': ['أتمنى', 'أن', 'أراك', 'مرة', 'أخرى.'] },
    { 'en': ["It's", 'good', 'seeing', 'you.'], 'ar': ['من', 'الجيد', 'رؤيتك'] },
    { 'en': ['There', 'are', 'many', 'red', 'flowers', 'in', 'the', 'garden.'], 'ar': ['هناك', 'الكثير', 'من', 'الورود', 'الحمراء', 'في', 'الحديقة.'] },
    { 'en': ['I', 'have', 'a', 'friend', 'who', 'lives', 'in', 'Sapporo.'], 'ar': ['لدي', 'صديق', 'يعيش', 'في', 'سابورو.'] },
    { 'en': ['Did', 'you', 'go', 'to', 'Cairo', 'or', 'Alexandria?'], 'ar': ['أإلى', 'القاهرة', 'ذهبت', 'أم', 'إلى', 'الاسكندرية؟'] },
    { 'en': ['I', 'got', 'on', 'the', 'wrong', 'bus.'], 'ar': ['صعدت', 'إلى', 'الحافلة', 'الخطأ.'] },
    { 'en': ['Tom', 'went', 'quickly', 'up', 'the', 'stairs.'], 'ar': ['صعد', 'توم', 'السلالم', 'بسرعة.'] },
    { 'en': ['I', 'like', 'candlelight.'], 'ar': ['أحب', 'ضوء', 'الشموع.'] },
    { 'en': ['He', 'lived', 'a', 'long', 'life.'], 'ar': ['عاش', 'حياة', 'طويلة.'] },
    { 'en': ["It's", 'Monday.'], 'ar': ['اليوم', 'الإثنين.'] },
    { 'en': ['Come', 'home', 'before', 'six.'], 'ar': ['اِرجع', 'إلى', 'البيت', 'قبل', 'السادسة.'] },
    { 'en': ['Is', 'there', 'a', 'cash', 'machine', 'near', 'here?'], 'ar': ['أيوجد', 'صرّاف', 'آلي', 'بالقرب', 'من', 'هنا؟'] },
    { 'en': ['I', 'knew', 'he', 'would', 'accept.'], 'ar': ['علمت', 'أنه', 'سيقبل.'] },
    { 'en': ["I'm", 'sure', "you're", 'right.'], 'ar': ['أنا', 'متأكد', 'أنكِ', 'محقة.'] },
    { 'en': ['He', 'hesitated', 'before', 'answering.'], 'ar': ['لقد', 'تردد', 'قبل', 'الإجابة.'] },
    { 'en': ["I'm", 'over', 'eighteen.'], 'ar': ['أنا', 'فوق', 'ثمانية', 'عشر', 'عاماً.'] },
    { 'en': ['My', 'father', 'likes', 'pizza', 'very', 'much.'], 'ar': ['يحب', 'أبي', 'البيتزا', 'كثيراً.'] },
    { 'en': ['I', 'had', 'a', 'bad', 'dream', 'last', 'night.'], 'ar': ['حلمت', 'بكابوس', 'ليلة', 'البارحة.'] },
    { 'en': ['I', 'appreciate', 'your', 'cooperation.'], 'ar': ['أقدر', 'تعاونك', 'معنا.'] },
    { 'en': ['It', 'is', 'sunny', 'today.'], 'ar': ['الجو', 'مشمس', 'اليوم.'] },
    { 'en': ['How', 'unlucky', 'I', 'am!'], 'ar': ['يا', 'لي', 'من', 'منحوس!'] },
    { 'en': ["What's", 'that', 'bird', 'called?'], 'ar': ['ماذا', 'يسمى', 'هذا', 'الطائر؟'] },
    { 'en': ['I', 'dropped', 'my', 'keys.'], 'ar': ['أوقعت', 'مفاتيحي.'] },
    { 'en': ['The', 'park', 'is', 'filled', 'with', 'children.'], 'ar': ['الحديقة', 'العامة', 'مليئة', 'بالأطفال.'] },
    { 'en': ['This', 'has', 'nothing', 'to', 'do', 'with', 'me.'], 'ar': ['هذا', 'لا', 'يخصني.'] },
    { 'en': ['A', 'good', 'idea', 'crossed', 'his', 'mind.'], 'ar': ['خطر', 'بباله', 'فكرة', 'جيدة.'] },
    { 'en': ['I', 'think', "I'm", 'in', 'love.'], 'ar': ['أظن', 'أنني', 'وقعت', 'في', 'الحب'] },
    { 'en': ['Let', 'him', 'go.'], 'ar': ['دَعَهُ', 'يذهب.'] },
    { 'en': ['You', 'are', 'very', 'brave.'], 'ar': ['أنت', 'شجاع', 'جداً.'] },
    { 'en': ["I'll", 'phone', 'you.'], 'ar': ['سأتصل', 'بك.'] },
    { 'en': ['What', 'is', 'his', 'nationality?'], 'ar': ['ما', 'جنسيته؟'] },
    { 'en': ['We', 'know', 'our', 'rights.'], 'ar': ['نعرف', 'حقوقنا.'] },
    { 'en': ["I'm", 'going', 'out', 'in', 'an', 'hour.'], 'ar': ['سأخرج', 'بعد', 'ساعة.'] },
    { 'en': ['Count', 'from', 'one', 'to', 'ten.'], 'ar': ['عد', 'من', 'واحد', 'إلى', 'عشرة.'] },
    { 'en': ['Once', 'again.'], 'ar': ['مرة', 'أخرى.'] },
    { 'en': ['Come', 'with', 'me,', 'will', 'you?'], 'ar': ['تعال', 'معي', 'إذا', 'سمحت.'] },
    { 'en': ['This', 'happened', 'for', 'a', 'reason.'], 'ar': ['حدث', 'هذا', 'لسبب.'] },
    { 'en': ['Check', 'again.'], 'ar': ['إفحص', 'مُجدداً.'] },
    { 'en': ['What', 'made', 'him', 'change', 'his', 'mind?'], 'ar': ['ما', 'الذي', 'جعله', 'يغير', 'رأيه؟'] },
    { 'en': ['Rats', 'carry', 'the', 'plague.'], 'ar': ['تحمل', 'الفئران', 'الطاعون.'] },
    { 'en': ["Let's", 'play', 'soccer.'], 'ar': ['لنلعب', 'الكرة.'] },
    { 'en': ['Please', 'hold', 'on.'], 'ar': ['انتظر', 'قليلاً.'] },
    { 'en': ['He', 'was', 'killed', 'with', 'a', 'sword.'], 'ar': ['قُتِلَ', 'بالسيف.'] },
    { 'en': ['This', 'is', 'too', 'difficult', 'for', 'me.'], 'ar': ['إن', 'ذلك', 'صعب', 'جداً', 'عليّ.'] },
    { 'en': ['I', 'made', 'him', 'open', 'the', 'door.'], 'ar': ['دفعته', 'إلى', 'فتح', 'الباب.'] },
    { 'en': ['I', 'write', 'poems', 'in', 'my', 'free', 'time.'], 'ar': ['أكتب', 'القصائد', 'في', 'وقت', 'فراغي.'] },
    { 'en': ['My', 'mother', 'died', 'during', 'my', 'absence.'], 'ar': ['ماتت', 'أمي', 'في', 'غيابي.'] },
    { 'en': ['My', 'mother', 'bought', 'me', 'a', 'new', 'dress.'], 'ar': ['اشترت', 'لي', 'أمي', 'فستاناً', 'جديداً.'] },
    { 'en': ['Nothing', 'is', 'missing.'], 'ar': ['ليس', 'هناك', 'ما', 'ينقص.'] },
    { 'en': ['The', 'bottle', 'is', 'filled', 'with', 'water.'], 'ar': ['الزجاجة', 'ملئى', 'بالماء.'] },
    { 'en': ['My', 'favorite', 'dance', 'is', 'the', 'tango.'], 'ar': ['رقصتي', 'المفضلة', 'هي', 'التانجو.'] },
    { 'en': ['He', 'has', 'left', 'us.'], 'ar': ['هو', 'غادرنا.'] },
    { 'en': ['This', 'is', 'not', 'my', 'car.'], 'ar': ['هذه', 'السيارة', 'ليست', 'ملكي'] },
    { 'en': ['You', 'have', 'to', 'get', 'this', 'work', 'finished', 'by', 'noon.'], 'ar': ['يجب', 'عليك', 'أن', 'تنهي', 'هذا', 'العمل', 'قبل', 'الظهر.'] },
    { 'en': ["We've", 'prepared', 'well.'], 'ar': ['نحن', 'مستعدون', 'بشكل', 'جيد'] },
    { 'en': ['I', 'think', "you're", 'wasting', 'your', 'time.'], 'ar': ['أظن', 'أنك', 'تضيع', 'وقتك.'] },
    { 'en': ['I', 'have', 'questions.'], 'ar': ['عندي', 'أسئلة.'] },
    { 'en': ['When', 'do', 'you', 'play', 'tennis?'], 'ar': ['متى', 'تلعب', 'التنس؟'] },
    { 'en': ['These', 'are', 'my', 'books,', 'and', 'those', 'are', 'his', 'books.'], 'ar': ['هذه', 'كتبي،', 'وتلك', 'كتبه.'] },
    { 'en': ['Have', 'a', 'seat,', 'please.'], 'ar': ['تفضل', 'بالجلوس', 'من', 'فضلك!'] },
    { 'en': ['A', 'good', 'coach', 'trains', 'this', 'team.'], 'ar': ['يدرّب', 'الفريق', 'مدرّب', 'جيّد.'] },
    { 'en': ['I', "haven't", 'been', 'fishing', 'in', 'a', 'long', 'time.'], 'ar': ['لم', 'أقم', 'بصيد', 'السمك', 'منذ', 'وقت', 'طويل'] },
    { 'en': ['Please', 'help', 'yourself.'], 'ar': ['تفضّل', 'المكان', 'مكانك.'] },
    { 'en': ['Make', 'it', 'smaller.'], 'ar': ['اجعلها', 'أصغر.'] },
    { 'en': ['There', 'are', 'numerous', 'universities', 'in', 'Kyoto.'], 'ar': ['هناك', 'العديد', 'من', 'الجامعات', 'في', 'كيوتو.'] },
    { 'en': ['Everybody', 'knows', 'that', 'two', 'and', 'two', 'make', 'four.'], 'ar': ['يعلم', 'الجميع', 'أن', 'اثنين', 'زائد', 'اثنين', 'تساوي', 'أربعة.'] },
    { 'en': ['She', 'talks', 'a', 'lot.'], 'ar': ['إنها', 'تكثر', 'من', 'الكلام.'] },
    { 'en': ['I', 'like', 'movies.'], 'ar': ['أحب', 'مشاهدة', 'الأفلام.'] },
    { 'en': ['I', "don't", 'think', 'it', 'will', 'rain', 'this', 'afternoon.'], 'ar': ['لا', 'أظن', 'أنها', 'ستمطر', 'بعد', 'الظهر.'] },
    { 'en': ['She', 'asked', 'me', 'if', 'I', 'was', 'all', 'right.'], 'ar': ['سألتني', 'إن', 'كنت', 'بخير.'] },
    { 'en': ['I', 'want', 'to', 'be', 'able', 'to', 'read', 'French.'], 'ar': ['أريد', 'أن', 'أتمكن', 'من', 'قراءة', 'الفرنسية.'] },
    { 'en': ['How', 'will', 'we', 'pay', 'our', 'debts', 'now?'], 'ar': ['كيف', 'سندفع', 'ديوننا', 'الآن؟'] },
    { 'en': ['The', 'sun', 'is', 'rising', 'now.'], 'ar': ['تشرق', 'الشمس', 'الآن.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'come', 'inside?'], 'ar': ['هل', 'ترغب', 'بالدخول؟'] },
    { 'en': ["I've", 'just', 'arrived', 'at', 'the', 'airport.'], 'ar': ['وصلت', 'إلى', 'المطار', 'للتو.'] },
    { 'en': ['Can', 'you', 'help', 'me', 'with', 'my', 'work?'], 'ar': ['هلّا', 'ساعدتني', 'في', 'عملي؟'] },
    { 'en': ["It's", 'not', 'going', 'to', 'be', 'that', 'easy.'], 'ar': ['لن', 'تكون', 'بتلك', 'السهولة.'] },
    { 'en': ["Don't", 'leave', 'me', 'alone,', 'please.'], 'ar': ['لا', 'تتركني', 'لوحدي', 'أرجوك.'] },
    { 'en': ['I', 'took', 'your', 'advice.'], 'ar': ['عملت', 'بنصيحتك.'] },
    { 'en': ["Here's", 'what', 'I', 'think.'], 'ar': ['هذا', 'ما', 'ظننته'] },
    { 'en': ["I'm", 'free', 'now.'], 'ar': ['أنا', 'متفرغ', 'الآن'] },
    { 'en': ['My', 'father', 'has', 'gone', 'to', 'China.'], 'ar': ['سافر', 'أبي', 'إلى', 'الصين.'] },
    { 'en': ["Let's", 'go', 'to', 'the', 'beach.'], 'ar': ['لنذهب', 'إلى', 'الشاطئ.'] },
    { 'en': ['I', "don't", 'want', 'to', 'talk', 'about', 'this', 'right', 'now.'], 'ar': ['لا', 'أريد', 'أن', 'أتحدث', 'بشأن', 'هذا', 'الآن.'] },
    { 'en': ["I'm", 'your', 'friend.'], 'ar': ['انا', 'صديقك.'] },
    { 'en': ['Do', 'you', 'really', 'love', 'me?'], 'ar': ['أتحبني', 'بحق؟'] },
    { 'en': ["Tom's", 'clothes', 'are', 'out', 'of', 'fashion.'], 'ar': ['ملابس', 'توم', 'موضة', 'قديمة.'] },
    { 'en': ['He', 'asked', 'me', 'for', 'help.'], 'ar': ['طلب', 'مني', 'المساعدة.'] },
    { 'en': ['I', 'asked', 'him', 'to', 'send', 'us', 'the', 'book.'], 'ar': ['طلبت', 'منه', 'أن', 'يبعث', 'الكتاب', 'إلينا.'] },
    { 'en': ["It's", 'now', 'my', 'turn.'], 'ar': ['إنه', 'دوري', 'الآن.'] },
    { 'en': ["Don't", 'worry', 'about', 'it!'], 'ar': ['لا', 'تقلق', 'بشأن', 'ذلك!'] },
    { 'en': ['I', 'should', 'have', 'left', 'earlier.'], 'ar': ['كان', 'علي', 'أن', 'أغادر', 'أبكر.'] },
    { 'en': ['The', 'baby', 'cried', 'all', 'night.'], 'ar': ['بكى', 'الطفل', 'طوال', 'اليل.'] },
    { 'en': ['That', 'was', 'the', 'intent.'], 'ar': ['تلك', 'كانت', 'نيتي.'] },
    { 'en': ['You', 'really', "don't", 'want', 'to', 'know.'], 'ar': ['أنتَ', 'حقاً', 'لا', 'تريد', 'أن', 'تعرف.'] },
    { 'en': ['I', 'have', 'lived', 'here', 'a', 'little', 'over', '60', 'years.'], 'ar': ['عشت', 'هنا', 'قرابة', 'الستين', 'عاماً.'] },
    { 'en': ['Kyiv', 'is', 'the', 'capital', 'of', 'Ukraine.'], 'ar': ['كييف', 'هي', 'عاصمة', 'اوكرانيا.'] },
    { 'en': ['One', 'is', 'Japanese', 'and', 'the', 'other', 'is', 'Italian.'], 'ar': ['أحدهما', 'ياباني', 'و', 'الآخر', 'إيطالي.'] },
    { 'en': ['Thank', 'you', 'for', 'the', 'pleasant', 'evening.'], 'ar': ['شكرأ', 'لكِ', 'على', 'الأمسية', 'الممتعة.'] },
    { 'en': ["I'll", 'join', 'you', 'later.'], 'ar': ['سأنضم', 'إليك', 'لاحقاً.'] },
    { 'en': ['He', 'exploded', 'with', 'anger.'], 'ar': ['انفجر', 'غضباً.'] },
    { 'en': ['I', 'wanted', 'to', 'buy', 'a', 'painting', 'from', 'Tom.'], 'ar': ['أردت', 'شراء', 'لوحة', 'من', 'توم.'] },
    { 'en': ['Cheers!'], 'ar': ['في', 'صحتك.'] },
    { 'en': ["I'm", 'not', 'your', 'girlfriend.'], 'ar': ['أنا', 'لستُ', 'صدبقتك.'] },
    { 'en': ['When', 'does', 'it', 'end?'], 'ar': ['متى', 'تنتهي؟'] },
    { 'en': ['She', 'and', 'her', 'friends', 'love', 'music.'], 'ar': ['هي', 'و', 'أصدقاءها', 'يحبون', 'الموسيقى.'] },
    { 'en': ['Tom', 'knows', 'I', 'like', 'him.'], 'ar': ['توم', 'يعرف', 'أنني', 'أوده.'] },
    { 'en': ['They', 'all', 'left.'], 'ar': ['غادروا', 'جميعا.'] },
    { 'en': ['Lemons', 'are', 'sour.'], 'ar': ['الليمون', 'حامض.'] },
    { 'en': ["Don't", 'look', 'at', 'me.'], 'ar': ['لا', 'تنظر', 'إلي!'] },
    { 'en': ["I'm", 'in', 'a', 'hurry', 'today.'], 'ar': ['أنا', 'في', 'عجلة', 'من', 'أمري.'] },
    { 'en': ['She', 'hated', 'him.'], 'ar': ['كرهته.'] },
    { 'en': ['I', 'hope', 'this', "won't", 'happen', 'again.'], 'ar': ['آمل', 'ألا', 'يحدث', 'هذا', 'ثانيةً.'] },
    { 'en': ['Let', 'me', 'take', 'your', 'blood', 'pressure.'], 'ar': ['اسمح', 'لي', 'أن', 'أقيس', 'ضغط', 'دمك.'] },
    { 'en': ['That', 'was', 'years', 'ago.'], 'ar': ['حصل', 'ذلك', 'منذ', 'سنوات', 'عدة.'] },
    { 'en': ["Let's", 'get', 'out', 'of', 'here', 'before', 'Tom', 'sees', 'us.'], 'ar': ['دعونا', 'نخرج', 'من', 'هنا', 'قبل', 'أن', 'يرانا', 'توم.'] },
    { 'en': ['Can', 'we', 'go?'], 'ar': ['هل', 'نستطيع', 'أن', 'نذهب؟'] },
    { 'en': ['Let', 'me', 'know', 'if', 'you', 'hear', 'anything.'], 'ar': ['أعلمني', 'إن', 'سمعتَ', 'شيئًا.'] },
    { 'en': ['Mathematics', 'is', 'an', 'interesting', 'subject.'], 'ar': ['الرياضيات', 'مادة', 'مثيرة', 'للاهتمام.'] },
    { 'en': ['I', 'like', 'playing', 'tennis', 'and', 'golf.'], 'ar': ['أحب', 'لعب', 'التنس', 'والغولف.'] },
    { 'en': ['Do', 'it', 'yourself', 'and', 'do', 'it', 'right', 'away.'], 'ar': ['قم', 'به', 'بنفسك', 'وبالطريقة', 'الصحيحة.'] },
    { 'en': ['They', 'are', 'about', 'the', 'same', 'age.'], 'ar': ['هم', 'في', 'نفس', 'العمر', 'تقريباً.'] },
    { 'en': ["I'll", 'visit', 'him', 'tomorrow.'], 'ar': ['سأزوره', 'غداً.'] },
    { 'en': ['They', 'asked', 'for', 'help.'], 'ar': ['لقد', 'طلبوا', 'المساعدة'] },
    { 'en': ['The', 'letter', 'returned.'], 'ar': ['عادت', 'الرّسالة.'] },
    { 'en': ['He', 'began', 'to', 'cry.'], 'ar': ['أخذت', 'تبكي.'] },
    { 'en': ['I', 'figured', "you'd", 'be', 'impressed.'], 'ar': ['توقعت', 'أنك', 'ستنبهر.'] },
    { 'en': ["I'm", 'undressing.'], 'ar': ['أنا', 'أخلع', 'ملابسي.'] },
    { 'en': ['What', 'she', 'said', 'did', 'not', 'make', 'sense.'], 'ar': ['ما', 'قلته', 'ليس', 'له', 'أي', 'معنى.'] },
    { 'en': ['Do', 'you', 'prefer', 'tea', 'or', 'coffee?'], 'ar': ['هل', 'تفضل', 'الشاي', 'أم', 'القهوة؟'] },
    { 'en': ['Is', 'your', 'wife', 'a', 'good', 'cook?'], 'ar': ['هل', 'زوجتك', 'طباخة', 'ماهرة؟'] },
    { 'en': ['That', 'black', 'one', 'is', 'mine.'], 'ar': ['الأسود', 'لي.'] },
    { 'en': ['I', "won't", 'do', 'it', 'again.'], 'ar': ['لن', 'أفعلها', 'ثانيةً.'] },
    { 'en': ['This', 'book', 'sold', 'well', 'in', 'Japan.'], 'ar': ['هذا', 'الكتاب', 'بيع', 'جيدا', 'في', 'اليابان'] },
    { 'en': ['Do', 'you', 'have', 'a', 'receipt?'], 'ar': ['هل', 'عندك', 'الفاتورة؟'] },
    { 'en': ['It', 'was', 'probably', 'not', 'true.'], 'ar': ['كان', 'على', 'الأرجح', 'غير', 'صحيح.'] },
    { 'en': ["It's", 'a', 'small', 'world.'], 'ar': ['العالم', 'قرية', 'صغيرة.'] },
    { 'en': ['I', 'would', 'like', 'to', 'see', 'your', 'products.'], 'ar': ['أريد', 'أن', 'أرى', 'منتجاتكم.'] },
    { 'en': ["I'm", 'not', 'going', 'to', 'tell', 'Tom.'], 'ar': ['أنا', 'لن', 'أخبر', 'توم.'] },
    { 'en': ['I', 'feel', 'for', 'you.'], 'ar': ['أشعر', 'بما', 'تمر', 'به.'] },
    { 'en': ['How', 'interesting!'], 'ar': ['كم', 'هذا', 'مثير', 'للإهتمام!'] },
    { 'en': ['Got', 'it?'], 'ar': ['هل', 'فهمت؟'] },
    { 'en': ["Let's", 'get', 'out', 'of', 'here', 'before', 'Tom', 'sees', 'us.'], 'ar': ['دعنا', 'نخرج', 'من', 'هنا', 'قبل', 'أن', 'يرانا', 'توم.'] },
    { 'en': ['I', 'made', 'a', 'mistake.'], 'ar': ['اقترفت', 'خطأً.'] },
    { 'en': ['Are', 'you', 'going', 'anywhere?'], 'ar': ['هل', 'أنت', 'ذاهب', 'إلى', 'مكان', 'ما؟'] },
    { 'en': ['Come', 'at', 'ten', "o'clock", 'sharp.'], 'ar': ['تعال', 'عند', 'العاشرة', 'تماماً.'] },
    { 'en': ['Go', 'to', 'work.'], 'ar': ['اذهب', 'إلى', 'العمل'] },
    { 'en': ['Our', 'school', 'was', 'founded', 'in', '1990.'], 'ar': ['تأسست', 'مدرستنا', 'عام', '۱۹۹۰م.'] },
    { 'en': ['When', 'does', 'the', 'movie', 'start?'], 'ar': ['متى', 'سيبدأ', 'الفيلم؟'] },
    { 'en': ['The', 'boy', 'is', 'kind.'], 'ar': ['الولد', 'لطيف.'] },
    { 'en': ['I', 'need', 'to', 'get', 'a', 'haircut.'], 'ar': ['أنا', 'بحاجة', 'لأحصل', 'على', 'قَصِة', 'شَعر.'] },
    { 'en': ['Make', 'way,', 'please.'], 'ar': ['أفسح', 'الطريق', 'من', 'فضلك.'] },
    { 'en': ['It', 'was', 'love', 'at', 'first', 'sight.'], 'ar': ['كان', 'حبًّا', 'من', 'أوّل', 'نظرة.'] },
    { 'en': ['You', 'have', 'a', 'big', 'nose.'], 'ar': ['أنتَ', 'لديكَ', 'أنف', 'كبير.'] },
    { 'en': ['She', 'helped', 'me', 'pack', 'my', 'suitcase.'], 'ar': ['ساعدتني', 'في', 'حزم', 'حقيبتي.'] },
    { 'en': ['Did', 'you', 'do', 'that', 'intentionally?'], 'ar': ['هل', 'قمت', 'بذلك', 'عن', 'قصد؟'] },
    { 'en': ['They', 'became', 'professional', 'soccer', 'players.'], 'ar': ['صاروا', 'لاعبِي', 'كرة', 'قدمٍ', 'محترفين.'] },
    { 'en': ['Be', 'cool.'], 'ar': ['خذ', 'راحتك.'] },
    { 'en': ["I'm", 'going', 'to', 'London', 'this', 'summer.'], 'ar': ['سأذهب', 'إلى', 'لندن', 'في', 'الصيف.'] },
    { 'en': ["Don't", 'fight', 'with', 'me.'], 'ar': ['لا', 'تقاتلني.'] },
    { 'en': ['Tom', 'got', 'three', 'presents', 'for', 'his', 'birthday.'], 'ar': ['تلقى', 'توم', 'ثلاثة', 'هدايا', 'بمناسبة', 'عيد', 'مولده.'] },
    { 'en': ['Where', 'are', 'you', 'going?'], 'ar': ['إلى', 'أين', 'ستذهب؟'] },
    { 'en': ['Did', 'you', 'receive', 'the', 'letter?'], 'ar': ['هل', 'استلمت', 'الرسالة؟'] },
    { 'en': ['He', 'is', 'young', 'and', 'immature.'], 'ar': ['إنهُ', 'صغير', 'وغير', 'ناضِج.'] },
    { 'en': ["Let's", 'play', 'cards.'], 'ar': ['لنلعب', 'الورق.'] },
    { 'en': ['Please', 'park', 'in', 'the', 'back.'], 'ar': ['رجاء', 'اركن', 'في', 'الخلف.'] },
    { 'en': ['I', 'know', 'only', 'this.'], 'ar': ['هذا', 'كل', 'ما', 'أعرفه.'] },
    { 'en': ['The', 'hunter', 'followed', 'the', "bear's", 'tracks.'], 'ar': ['لحِق', 'الصياد', 'آثار', 'الدب.'] },
    { 'en': ['Can', 'you', 'play', 'the', 'piano?'], 'ar': ['أيمكنك', 'العزف', 'على', 'البيانو؟'] },
    { 'en': ['A', 'computer', 'is', 'a', 'complex', 'machine.'], 'ar': ['الحاسوب', 'آلة', 'معقدة.'] },
    { 'en': ['No', 'fewer', 'than', '50', 'passengers', 'were', 'killed.'], 'ar': ['قُتل', 'ما', 'لا', 'يقل', 'عن', '50', 'راكبًا.'] },
    { 'en': ['I', 'bought', 'a', 'new', 'computer', 'last', 'month.'], 'ar': ['اشتريت', 'حاسوباً', 'جديداً', 'الشهر', 'الماضي.'] },
    { 'en': ['What', 'a', 'beautiful', 'rainbow!'], 'ar': ['يا', 'له', 'من', 'قوس', 'قزح', 'جميل!'] },
    { 'en': ["They're", 'washing', 'their', 'hands.'], 'ar': ['إنهم', 'يغسلون', 'أيديهم.'] },
    { 'en': ['Tom', 'wants', 'me', 'to', 'quit', 'this', 'job.'], 'ar': ['توم', 'يريد', 'مني', 'أن', 'أستقيل', 'من', 'هذه', 'الوظيفة.'] },
    { 'en': ['He', 'is', 'tremendously', 'handsome.'], 'ar': ['إنه', 'وسيم', 'جدا.'] },
    { 'en': ['I', 'found', 'Tom', 'smoking', 'a', 'hookah.'], 'ar': ['عثرت', 'عل', 'توم', 'يدخن', 'النرجيلة.'] },
    { 'en': ['China', 'is', 'a', 'huge', 'country.'], 'ar': ['الصين', 'بلد', 'كبير'] },
    { 'en': ['That', 'was', 'hard', 'to', 'believe.'], 'ar': ['كان', 'ذلك', 'صعب', 'التصديق.'] },
    { 'en': ['I', 'saw', 'a', 'ghost.'], 'ar': ['رأيت', 'شبحا.'] },
    { 'en': ['I', 'went', 'for', 'a', 'walk', 'in', 'the', 'park.'], 'ar': ['ذهبت', 'لأتمشى', 'في', 'المتنزه.'] },
    { 'en': ['Can', 'you', 'speak', 'English?'], 'ar': ['هل', 'تستطيع', 'التحدث', 'باللغة', 'الانجليزية؟'] },
    { 'en': ['He', 'is', 'getting', 'better', 'day', 'by', 'day.'], 'ar': ['إنه', 'يتحسّن', 'كل', 'يوم.'] },
    { 'en': ['We', 'have', 'lived', 'in', 'Osaka', 'six', 'years.'], 'ar': ['عشنا', 'في', 'أوساكا', 'ست', 'سنوات.'] },
    { 'en': ['Where', 'are', 'your', 'keys?'], 'ar': ['أين', 'هي', 'مفاتيحك؟'] },
    { 'en': ['I', 'know', 'the', 'boy.'], 'ar': ['أعرف', 'ذلك', 'الولد.'] },
    { 'en': ['She', 'will', 'be', 'seventeen', 'next', 'year.'], 'ar': ['سيصبح', 'عمرها', 'سبعة', 'عشر', 'عاماً', 'السنة', 'المقبلة.'] },
    { 'en': ['How', 'can', 'I', 'get', 'there?'], 'ar': ['كيف', 'بإمكاني', 'الوصول', 'إلى', 'هناك؟'] },
    { 'en': ['Pick', 'up', 'the', 'pencil', 'from', 'the', 'floor.'], 'ar': ['ارفع', 'القلم', 'من', 'على', 'الأرض.'] },
    { 'en': ['Have', 'you', 'ever', 'been', 'to', 'the', 'United', 'States?'], 'ar': ['هل', 'سبق', 'لك', 'أن', 'ذهبت', 'إلى', 'الولايات', 'المتحدة؟'] },
    { 'en': ['We', 'still', 'have', 'a', 'lot', 'of', 'food', 'left.'], 'ar': ['ما', 'زال', 'لدينا', 'الكثير', 'من', 'الطعام.'] },
    { 'en': ['We', 'are', 'teachers.'], 'ar': ['نحن', 'معلمون.'] },
    { 'en': ['I', 'do', 'love', 'you.'], 'ar': ['أحبك.'] },
    { 'en': ['I', 'gave', 'her', 'all', 'the', 'money', 'I', 'had.'], 'ar': ['أعطيتها', 'كل', 'المال', 'الذي', 'أملكه.'] },
    { 'en': ["It's", 'cold', 'this', 'morning.'], 'ar': ['الجو', 'بارد', 'هذا', 'الصباح.'] },
    { 'en': ['You', 'have', 'nothing', 'to', 'fear.'], 'ar': ['ليس', 'لديك', 'ما', 'تخشاه.'] },
    { 'en': ['I', "shouldn't", 'have', 'come', 'here.'], 'ar': ['لا', 'يجب', 'علي', 'أن', 'آتي', 'هنا.'] },
    { 'en': ['Smile.'], 'ar': ['ابتسم.'] },
    { 'en': ['I', "don't", 'know', 'what', 'to', 'do', 'anymore.'], 'ar': ['لا', 'أعرف', 'ماذا', 'سأفعل', 'بعد', 'الآن.'] },
    { 'en': ['Who', 'stole', 'the', 'apple?'], 'ar': ['من', 'سرق', 'التفاحة؟'] },
    { 'en': ['What', 'do', 'you', 'do', 'in', 'your', 'free', 'time?'], 'ar': ['ما', 'الذي', 'تفعله', 'بوقت', 'فراغك؟'] },
    { 'en': ['She', 'cooked', 'the', 'dinner', 'herself.'], 'ar': ['أعدت', 'العشاء', 'بنفسها.'] },
    { 'en': ['You', 'should', 'give', 'up', 'drinking.'], 'ar': ['عليك', 'أن', 'تقلع', 'عن', 'شرب', 'الكحول.'] },
    { 'en': ['I', "don't", 'know', 'her.'], 'ar': ['لا', 'أعرفها.'] },
    { 'en': ['My', 'sister', 'lives', 'near', 'Yokohama.'], 'ar': ['تعيش', 'أختي', 'قرب', 'يوكوهاما.'] },
    { 'en': ['Why', 'not', 'go', 'to', 'the', 'movies?'], 'ar': ['لمَ', 'لا', 'تذهب', 'إلى', 'السينما؟'] },
    { 'en': ['Tom', 'is', 'absent.'], 'ar': ['توم', 'غائب.'] },
    { 'en': ["Don't", 'you', 'just', 'hate', 'this', 'kind', 'of', 'movie?'], 'ar': ['ألا', 'تكره', 'هذا', 'النوع', 'من', 'الأفلام؟'] },
    { 'en': ['How', 'can', 'you', 'know', 'that?'], 'ar': ['كيف', 'بإمكانك', 'معرفة', 'ذلك؟'] },
    { 'en': ["Haven't", 'we', 'met', 'before?'], 'ar': ['ألم', 'نلتقي', 'مسبقاً؟'] },
    { 'en': ['It', 'may,', 'indeed,', 'be', 'a', 'mistake.'], 'ar': ['من', 'الممكن', 'فعلاً', 'أن', 'يكون', 'ذلك', 'خطأً.'] },
    { 'en': ['I', 'intend', 'to', 'stay', 'at', 'a', 'five-star', 'hotel.'], 'ar': ['أنوي', 'الإقامة', 'في', 'فندقٍ', 'من', 'فئة', 'الخمسة', 'نجوم.'] },
    { 'en': ['She', 'accepted', 'his', 'hand', 'in', 'marriage.'], 'ar': ['قبلت', 'أن', 'تتزوج', 'به.'] },
    { 'en': ["Let's", 'get', 'started.'], 'ar': ['هيا', 'لنبدأ.'] },
    { 'en': ['Tom', 'is', 'going', 'to', 'come', 'live', 'with', 'us.'], 'ar': ['توم', 'سيأتي', 'ليعيش', 'معنا.'] },
    { 'en': ['You', 'understand', 'me.'], 'ar': ['أنت', 'تفهمني.'] },
    { 'en': ['Why', 'is', 'Tom', 'even', 'here?'], 'ar': ['لماذا', 'توم', 'هنا', 'أصلا؟'] },
    { 'en': ["It'll", 'turn', 'up.'], 'ar': ['سيتم', 'الامر'] },
    { 'en': ['Why', 'is', 'snow', 'white?'], 'ar': ['لماذا', 'الثلج', 'أبيض؟'] },
    { 'en': ['I', 'went', 'to', 'a', 'commercial', 'high', 'school.'], 'ar': ['أنا', 'ذهبت', 'إلى', 'مدرسة', 'ثانوية', 'تجارية.'] },
    { 'en': ["Where's", 'the', 'boss?'], 'ar': ['أين', 'الرئيس؟'] },
    { 'en': ["I'll", 'take', 'this', 'umbrella.'], 'ar': ['سآخذ', 'هذه', 'المظلة.'] },
    { 'en': ['I', 'went', 'shopping', 'with', 'a', 'friend.'], 'ar': ['ذهبت', 'للتسوق', 'مع', 'صديق', 'لي.'] },
    { 'en': ['We', "don't", 'have', 'to', 'do', 'this', 'every', 'day.'], 'ar': ['ليس', 'واجب', 'علينا', 'فعل', 'ذلك', 'يوميا.'] },
    { 'en': ['This', 'book', 'is', 'difficult', 'to', 'understand.'], 'ar': ['هذا', 'الكتاب', 'صعب', 'الفهم'] },
    { 'en': ['Who', 'knows', 'what', 'might', 'happen', 'in', 'the', 'future?'], 'ar': ['من', 'يعلم', 'ما', 'قد', 'يحصل', 'مستقبلًا؟'] },
    { 'en': ["You're", 'a', 'remarkable', 'person.'], 'ar': ['أنت', 'رجل', 'متميز.'] },
    { 'en': ['I', 'asked', 'him', 'to', 'open', 'the', 'window.'], 'ar': ['طلبت', 'منه', 'فتح', 'النافذة.'] },
    { 'en': ['The', 'washbasin', 'is', 'made', 'of', 'granite.'], 'ar': ['المغسلة', 'صنعت', 'من', 'الجرانيت'] },
    { 'en': ['Could', 'you', 'get', 'me', 'a', 'glass', 'of', 'water,', 'please?'], 'ar': ['هلا', 'جلبت', 'لي', 'كأسا', 'من', 'الماء', 'من', 'فضلك؟'] },
    { 'en': ['Prices', 'are', 'high', 'these', 'days.'], 'ar': ['الأسعار', 'غالية', 'هذه', 'الأيام.'] },
    { 'en': ['Shut', 'the', 'door.'], 'ar': ['أغلق', 'الباب.'] },
    { 'en': ['Answer', 'my', 'questions.'], 'ar': ['أجب', 'على', 'أسئلتي.'] },
    { 'en': ['This', "isn't", 'fair.'], 'ar': ['هذا', 'ليس', 'عدلاً.'] },
    { 'en': ['Swimming', 'at', 'night', 'is', 'dangerous.'], 'ar': ['السباحة', 'ليلاً', 'خطرة.'] },
    { 'en': ['Tom', 'is', 'going', 'to', 'love', 'it', 'here.'], 'ar': ['توم', 'سوف', 'يحب', 'هذا', 'المكان.'] },
    { 'en': ['He', 'is', 'old', 'enough', 'to', 'drive.'], 'ar': ['إنه', 'كبير', 'بما', 'فيه', 'الكفاية', 'ليقود', 'سيارة.'] },
    { 'en': ['Frankly', 'speaking,', 'I', "don't", 'agree', 'with', 'you.'], 'ar': ['بصراحة', 'لا', 'أوافقك', 'الرأي.'] },
    { 'en': ["I'll", 'make', 'you', 'happy.'], 'ar': ['سأجعلك', 'سعيدةً.'] },
    { 'en': ['Who', 'are', 'these', 'two', 'boys?'], 'ar': ['من', 'هذان', 'الولدان؟'] },
    { 'en': ['She', 'may', 'not', 'come.'], 'ar': ['من', 'الممكن', 'أن', 'لا', 'تأتي.'] },
    { 'en': ['Is', 'there', 'any', 'life', 'on', 'Mars?'], 'ar': ['هل', 'هناك', 'حياة', 'على', 'كوكب', 'المريخ؟'] },
    { 'en': ['He', 'failed,', 'due', 'to', 'lack', 'of', 'money.'], 'ar': ['فشِل', 'بسبب', 'قلة', 'المال.'] },
    { 'en': ['I', 'ordered', 'a', 'book', 'from', 'London.'], 'ar': ['طلبت', 'كتاباً', 'من', 'لندن.'] },
    { 'en': ['Stop', 'hitting', 'me!'], 'ar': ['توقف', 'عن', 'ضربي.'] },
    { 'en': ['My', 'father', 'died', 'of', 'cancer.'], 'ar': ['مات', 'أبي', 'بسبب', 'السرطان.'] },
    { 'en': ['He', 'is', 'not', 'Japanese.'], 'ar': ['هو', 'ليس', 'يابانياً.'] },
    { 'en': ['The', 'horse', 'stopped', 'and', 'refused', 'to', 'move.'], 'ar': ['توقف', 'الحصان', 'و', 'رفض', 'أن', 'يتحرك.'] },
    { 'en': ['I', 'am', 'going', 'to', 'play', 'tennis', 'tomorrow.'], 'ar': ['سألعب', 'كرة', 'المضرب', 'غداً.'] },
    { 'en': ['I', "don't", 'like', 'watching', 'subtitled', 'movies.'], 'ar': ['لا', 'أحب', 'مشاهدة', 'الافلام', 'المترجمة'] },
    { 'en': ['Can', 'I', 'wait', 'for', 'Tom', 'here?'], 'ar': ['هل', 'يمكنني', 'إنتظار', 'توم', 'هنا؟'] },
    { 'en': ['I', 'want', 'to', 'marry', 'you.'], 'ar': ['أُريد', 'أن', 'أتزوجكِ.'] },
    { 'en': ["He's", 'come', 'to', 'make', 'trouble.'], 'ar': ['إنهُ', 'يأتي', 'ليصنع', 'مشكلة.'] },
    { 'en': ['All', 'aboard!'], 'ar': ['ليركب', 'الكل.'] },
    { 'en': ['I', 'think', 'otherwise.'], 'ar': ['اعتقد', 'خلاف', 'ذلك.'] },
    { 'en': ["That's", 'what', 'happened', 'here', 'on', 'Monday.'], 'ar': ['هذا', 'ما', 'حدث', 'هنا', 'يوم', 'الإثنين.'] },
    { 'en': ['They', 'checked', 'how', 'pure', 'the', 'water', 'was.'], 'ar': ['فحصوا', 'مدى', 'نقاوة', 'الماء.'] },
    { 'en': ["Who's", 'that', 'boy?'], 'ar': ['من', 'هذا', 'الصبي؟'] },
    { 'en': ["You're", 'not', 'fast', 'enough.'], 'ar': ['لست', 'سريعاً', 'بما', 'يكفي.'] },
    { 'en': ['He', 'can', 'do', 'it', 'better', 'than', 'me.'], 'ar': ['إنهُ', 'يستطيع', 'أن', 'يفعل', 'هذا', 'أفضل', 'مني.'] },
    { 'en': ['I', "didn't", 'hear', 'my', 'alarm', 'clock.'], 'ar': ['أنا', 'لم', 'أسمع', 'المنبه.'] },
    { 'en': ["Don't", 'worry', 'about', 'what', "you've", 'done.'], 'ar': ['لا', 'تقلق', 'بشأن', 'ما', 'فعلته.'] },
    { 'en': ['Control', 'yourself.'], 'ar': ['تَحَكَم', 'بنفسك.'] },
    { 'en': ['She', 'is', 'unconscious.'], 'ar': ['إنّها', 'فاقدة', 'الوعي.'] },
    { 'en': ['I', 'had', 'my', 'only', 'son', 'die', 'of', 'cancer.'], 'ar': ['توفي', 'ابني', 'الوحيد', 'بالسرطان.'] },
    { 'en': ['You', 'had', 'a', 'choice.'], 'ar': ['كان', 'لديك', 'خيار.'] },
    { 'en': ['I', "shouldn't", 'have', 'eaten', 'that.'], 'ar': ['لم', 'يكن', 'عليّ', 'أكل', 'هذا.'] },
    { 'en': ['Please', 'move', 'to', 'the', 'rear', 'of', 'the', 'bus.'], 'ar': ['انتقل', 'الى', 'الجزء', 'الخلفي', 'من', 'الباص', 'رجاءً', '.'] },
    { 'en': ['Maybe', 'you', 'should', 'turn', 'off', 'the', 'TV.'], 'ar': ['لربّما', 'عليك', 'إطفاء', 'التلفاز.'] },
    { 'en': ['You', "don't", 'need', 'to', 'worry', 'about', 'me.'], 'ar': ['لا', 'داعي', 'لأن', 'تقلق', 'علي.'] },
    { 'en': ['I', 'slept', 'only', 'two', 'hours.'], 'ar': ['نمت', 'لساعتين', 'فقط.'] },
    { 'en': ['Please', 'give', 'me', 'a', 'piece', 'of', 'bread.'], 'ar': ['أعطني', 'كسرة', 'خبز', 'من', 'فضلك.'] },
    { 'en': ['I', "don't", 'know.'], 'ar': ['لا', 'أعلم'] },
    { 'en': ['Do', 'you', 'like', 'French?'], 'ar': ['هل', 'تحب', 'الفرنسية؟'] },
    { 'en': ['He', 'told', 'me', 'not', 'to', 'drive', 'too', 'fast.'], 'ar': ['نبهني', 'ألا', 'أقود', 'بسرعة.'] },
    { 'en': ['Our', 'school', 'burned', 'down.'], 'ar': ['اِحترقت', 'مدرستنا.'] },
    { 'en': ["Didn't", 'I', 'ask', 'you', 'not', 'to', 'do', 'that?'], 'ar': ['ألم', 'أطلب', 'منك', 'عدم', 'فعل', 'ذلك؟'] },
    { 'en': ['Where', 'do', 'you', 'think', 'we', 'should', 'stay?'], 'ar': ['أين', 'تظن', 'أن', 'علينا', 'أن', 'نبقى؟'] },
    { 'en': ['I', 'just', 'arrived', 'now.'], 'ar': ['وصلت', 'منذ', 'لحظات.'] },
    { 'en': ['I', 'know', 'your', 'mother.'], 'ar': ['أنا', 'أعرف', 'أمك'] },
    { 'en': ['His', 'wife', 'goes', 'with', 'him', 'wherever', 'he', 'goes.'], 'ar': ['تذهب', 'زوجته', 'معه', 'أينما', 'ذهب.'] },
    { 'en': ['Her', 'mother', 'started', 'shouting.'], 'ar': ['بدأت', 'أمها', 'الصراخ.'] },
    { 'en': ['Are', 'you', 'new', 'here?'], 'ar': ['هل', 'أنت', 'جديد', 'هنا؟'] },
    { 'en': ['He', 'tried', 'to', 'pull', 'a', 'fast', 'one', 'on', 'me.'], 'ar': ['لقد', 'حاول', 'أن', 'يخدعني.'] },
    { 'en': ['Beijing', 'is', 'the', 'capital', 'of', 'China.'], 'ar': ['بكين', 'هي', 'عاصمة', 'الصين.'] },
    { 'en': ['What', 'you', 'say', 'does', 'not', 'make', 'any', 'sense', 'to', 'me.'], 'ar': ['ما', 'تقوله', 'ليس', 'له', 'أيُ', 'معنىً', 'لي.'] },
    { 'en': ['I', "don't", 'think', 'so.'], 'ar': ['لا', 'أظن', 'ذلك.'] },
    { 'en': ['This', "isn't", 'drinking', 'water.'], 'ar': ['هذه', 'المياه', 'ليست', 'للشرب.'] },
    { 'en': ['Her', 'face', 'turned', 'red.'], 'ar': ['إحمرّ', 'وجهها.'] },
    { 'en': ['I', 'have', 'a', 'question', 'I', 'need', 'to', 'ask', 'you.'], 'ar': ['عندي', 'سؤال', 'علي', 'سؤالك', 'عنه.'] },
    { 'en': ["I'm", 'going', 'to', 'find', 'a', 'place', 'of', 'my', 'own.'], 'ar': ['ساعثر', 'علي', 'مكان', 'خاص', 'بي'] },
    { 'en': ['How', 'rude', 'of', 'you!'], 'ar': ['يا', 'لك', 'من', 'وقح!'] },
    { 'en': ["Let's", 'not', 'go', 'home.'], 'ar': ['دعنا', 'لا', 'نعود', 'للمنزل.'] },
    { 'en': ['The', 'waves', 'are', 'high.'], 'ar': ['الأمواج', 'عالية.'] },
    { 'en': ['She', 'slapped', 'his', 'face.'], 'ar': ['صفعته.'] },
    { 'en': ['Walk', 'faster,', 'or', "you'll", 'miss', 'the', 'train.'], 'ar': ['إمش', 'بسرعة،', 'وإلا', 'فاتك', 'القطار.'] },
    { 'en': ["That's", 'a', 'good', 'idea!'], 'ar': ['هذه', 'فكرة', 'جيدة!'] },
    { 'en': ['He', 'told', 'me', 'not', 'to', 'drive', 'too', 'fast.'], 'ar': ['قال', 'لي', 'ألا', 'أقود', 'بسرعة.'] },
    { 'en': ['Gay', 'marriage', 'is', 'legal', 'here.'], 'ar': ['الزواج', 'المثلي', 'قانوني', 'هنا'] },
    { 'en': ['Tom', 'looks', 'happy.'], 'ar': ['توم', 'يبدو', 'سعيداً'] },
    { 'en': ['I', 'admire', 'your', 'courage.'], 'ar': ['تعجبني', 'شجاعتك.'] },
    { 'en': ['I', "don't", 'know', 'what', 'you', 'heard', 'about', 'me.'], 'ar': ['لا', 'أعلم', 'ما', 'الذي', 'سمعته', 'عني.'] },
    { 'en': ['Tom', "doesn't", 'know', 'me.'], 'ar': ['توم', 'لا', 'يعرفني'] },
    { 'en': ["I'd", 'rather', 'go', 'swimming.'], 'ar': ['أفضّل', 'لو', 'ذهبت', 'للسباحة.'] },
    { 'en': ['Look', 'back!'], 'ar': ['انظر', 'خلفك!'] },
    { 'en': ['Cherries', 'are', 'red.'], 'ar': ['الكرز', 'أحمر.'] },
    { 'en': ['My', 'father', 'drives', 'very', 'well.'], 'ar': ['أبي', 'ماهر', 'في', 'قيادة', 'السيارة.'] },
    { 'en': ['This', 'word', 'is', 'derived', 'from', 'Latin.'], 'ar': ['هذه', 'الكلمة', 'مشتقة', 'من', 'اللاتينية.'] },
    { 'en': ['Hurry', 'up,', 'or', "you'll", 'be', 'late', 'for', 'school.'], 'ar': ['أسرع', 'و', 'إلا', 'ستتأخر', 'عن', 'المدرسة.'] },
    { 'en': ['Why', 'are', 'you', 'doing', 'this?'], 'ar': ['لماذا', 'تفعلين', 'هذا؟'] },
    { 'en': ['You', 'must', 'get', 'ready', 'quickly.'], 'ar': ['يجب', 'أن', 'تجهز', 'نفسك', 'بسرعة.'] },
    { 'en': ['Where', 'is', 'his', 'family?'], 'ar': ['أين', 'عائلته؟'] },
    { 'en': ['Where', 'are', 'the', 'eggs,', 'please?'], 'ar': ['لو', 'سمحت', '،', 'أين', 'البيض؟'] },
    { 'en': ['I', "don't", 'believe', 'it!'], 'ar': ['لا', 'يمكنني', 'أن', 'أصدق!'] },
    { 'en': ['It', 'is', 'a', 'difficult', 'problem.'], 'ar': ['هذه', 'مشكلة', 'صعبة.'] },
    { 'en': ['Local', 'shops', 'do', 'good', 'business', 'with', 'tourists.'], 'ar': ['المتاجر', 'المحلية', 'تقوم', 'بمتاجرة', 'جيدة', 'مع', 'السياح', '.'] },
    { 'en': ['We', "don't", 'know', 'her.'], 'ar': ['نحن', 'لا', 'نعرفها'] },
    { 'en': ['It', 'was', 'well', 'worth', 'the', 'trouble.'], 'ar': ['لقد', 'كان', 'يستحق', 'العناء.'] },
    { 'en': ["You've", 'got', 'to', 'go.'], 'ar': ['يجب', 'أن', 'تذهب'] },
    { 'en': ['He', 'is', 'angry', 'with', 'me.'], 'ar': ['إنهُ', 'غاضب', 'معي.'] },
    { 'en': ['I', 'would', 'like', 'you', 'to', 'meet', 'my', 'parents.'], 'ar': ['أود', 'أن', 'تقابل', 'والداي.'] },
    { 'en': ["I'm", 'not', 'the', 'problem.'], 'ar': ['أنا', 'لست', 'المشكلة.'] },
    { 'en': ['My', 'internet', 'connection', 'was', 'cut', 'off.'], 'ar': ['انقطع', 'اتصالي', 'بالإنترنت.'] },
    { 'en': ["That's", 'life.'], 'ar': ['هكذا', 'هي', 'الحياة.'] },
    { 'en': ["What's", 'that?'], 'ar': ['ما', 'ذلك؟'] },
    { 'en': ['May', 'I', 'interrupt?'], 'ar': ['هل', 'لي', 'أن', 'أقاطع؟'] },
    { 'en': ['Tom', 'never', 'thinks', 'about', 'others.'], 'ar': ['أبدًا', 'لا', 'يفكّر', 'توم', 'بالآخرين.'] },
    { 'en': ['This', 'wall', 'is', 'painted', 'green.'], 'ar': ['الحائط', 'مطلي', 'بالأخضر.'] },
    { 'en': ['No', 'one', 'I', 'know', 'talks', 'to', 'Tom', 'anymore.'], 'ar': ['لا', 'أحد', 'أعرفهُ', 'يتحدث', 'إلى', 'توم', 'بعد', 'الآن.'] },
    { 'en': ['You', 'should', 'give', 'up', 'drinking', 'and', 'smoking.'], 'ar': ['ينبغي', 'أن', 'تقلع', 'عن', 'الشرب', 'والتدخين.'] },
    { 'en': ['I', 'love', 'you', 'more', 'than', 'you', 'love', 'me.'], 'ar': ['أحبك', 'أكثر', 'مما', 'تحبني.'] },
    { 'en': ['I', "don't", 'have', 'time.'], 'ar': ['ليس', 'عندي', 'وقت.'] },
    { 'en': ['What', 'do', 'you', 'do', 'in', 'the', 'afternoon?'], 'ar': ['ماذا', 'تفعل', 'ظهرا؟'] },
    { 'en': ['Do', 'you', 'have', 'anything', 'to', 'say', 'regarding', 'this?'], 'ar': ['هل', 'لديك', 'ما', 'تقوله', 'بخصوص', 'هذا', 'الأمر؟'] },
    { 'en': ["Tom's", 'a', 'bit', 'freaked', 'out.'], 'ar': ['توم', 'مذعور', 'قليلا.'] },
    { 'en': ['Even', 'Homer', 'sometimes', 'nods.'], 'ar': ['لكلّ', 'عالِم', 'هفوة.'] },
    { 'en': ['Show', 'me', "what's", 'in', 'your', 'pocket.'], 'ar': ['أرني', 'ما', 'في', 'جيبك.'] },
    { 'en': ['Leave', 'your', 'desk', 'as', 'it', 'is.'], 'ar': ['اترك', 'مكتبك', 'كما', 'هو.'] },
    { 'en': ['My', 'son', 'came', 'to', 'my', 'room.'], 'ar': ['أتى', 'ولدي', 'إلى', 'غرفتي.'] },
    { 'en': ['Tom', 'is', 'getting', 'married', 'on', 'Monday.'], 'ar': ['سيتزوج', 'توم', 'يوم', 'الاثنين.'] },
    { 'en': ['See', 'you', 'tomorrow', 'at', 'the', 'library.'], 'ar': ['أراك', 'غداً', 'في', 'المكتبة.'] },
    { 'en': ['Everybody', 'wants', 'to', 'sit', 'beside', 'her.'], 'ar': ['الكل', 'يريد', 'أن', 'يجلس', 'بجانبها.'] },
    { 'en': ["It's", 'the', 'right', 'decision.'], 'ar': ['إنه', 'القرار', 'الصائب.'] },
    { 'en': ['I', 'smell', 'something', 'rotten.'], 'ar': ['أشم', 'رائحة', 'شيء', 'عفن.'] },
    { 'en': ['You', 'ask', 'me', 'to', 'do', 'the', 'impossible.'], 'ar': ['أنت', 'تطلب', 'مني', 'أن', 'أفعل', 'المستحيل.'] },
    { 'en': ['I', 'think', 'I', 'mistakenly', 'deleted', 'that', 'file.'], 'ar': ['أنا', 'أعتقد', 'أني', 'بطريق', 'الخطأ', 'حذفت', 'ذلك', 'الملف.'] },
    { 'en': ['She', 'knows', 'nothing', 'about', 'your', 'family.'], 'ar': ['إنها', 'لا', 'تعرف', 'شيئاً', 'عن', 'عائلتك.'] },
    { 'en': ['I', 'was', 'interviewed', 'for', 'a', 'job.'], 'ar': ['أجريتْ', 'لي', 'مقابلة', 'عمل.'] },
    { 'en': ['My', 'favorite', 'music', 'instrument', 'is', 'the', 'flute.'], 'ar': ['آلتي', 'الموسيقية', 'المضلة', 'هي', 'الناي.'] },
    { 'en': ['He', 'came', 'to', 'ask', 'us', 'for', 'our', 'help.'], 'ar': ['أتى', 'ليطلب', 'منا', 'المساعدة.'] },
    { 'en': ['Will', 'you', 'go', 'to', 'the', 'party', 'tonight?'], 'ar': ['هل', 'ستذهب', 'إلى', 'الحفلة', 'الليلة؟'] },
    { 'en': ["I'm", 'proud', 'to', 'have', 'him', 'as', 'a', 'friend.'], 'ar': ['أنا', 'فخور', 'بكونه', 'صديقي.'] },
    { 'en': ['You', 'look', 'like', 'a', 'tourist.'], 'ar': ['انت', 'تبدو', 'سائحاً.'] },
    { 'en': ["I've", 'been', 'learning', 'to', 'drive.'], 'ar': ['إني', 'أتعلم', 'القيادة.'] },
    { 'en': ['That', 'looks', 'good.'], 'ar': ['ذلك', 'يبدو', 'جيدا'] },
    { 'en': ['She', 'used', 'to', 'visit', 'me', 'regularly.'], 'ar': ['كانت', 'تزورني', 'باستمرار.'] },
    { 'en': ['We', 'respect', 'each', 'other.'], 'ar': ['نحن', 'نحترم', 'بعضنا', 'البعض.'] },
    { 'en': ['I', "don't", 'understand', 'music.'], 'ar': ['لا', 'أفهم', 'الموسيقى.'] },
    { 'en': ['Tom', 'wanted', 'to', 'change', 'his', 'life.'], 'ar': ['توم', 'أراد', 'أن', 'يغير', 'حياتهُ.'] },
    { 'en': ['She', 'will', 'be', 'late', 'for', 'dinner.'], 'ar': ['ستتأخّر', 'على', 'العشاء.'] },
    { 'en': ["Don't", 'mention', 'our', 'plan', 'to', 'anybody.'], 'ar': ['لا', 'تذكر', 'خطتنا', 'لأي', 'أحد.'] },
    { 'en': ["You're", 'in', 'better', 'shape', 'than', 'I', 'am.'], 'ar': ['مظهرك', 'أفضل', 'من', 'مظهري', '.'] },
    { 'en': ['We', 'stayed', 'there', 'for', 'three', 'months.'], 'ar': ['بقينا', 'هناك', 'لمدة', 'ثلاثة', 'شهور.'] },
    { 'en': ['My', 'son', 'entered', 'high', 'school.'], 'ar': ['ابني', 'الآن', 'في', 'المرحلة', 'الثانوية.'] },
    { 'en': ['He', 'retired', 'at', 'the', 'age', 'of', '65.'], 'ar': ['هو', 'تقاعد', 'عند', '٦٥', 'من', 'عمره.'] },
    { 'en': ['Do', 'you', 'remember', 'your', 'passport', 'number?'], 'ar': ['هل', 'تذكر', 'رقم', 'جواز', 'سفرك؟'] },
    { 'en': ['I', "didn't", 'tell', 'Tom', 'that', 'I', 'knew', 'you.'], 'ar': ['لم', 'أخبر', 'توم', 'أني', 'أعرفك.'] },
    { 'en': ['The', 'station', 'is', 'to', 'the', 'west', 'of', 'the', 'hotel.'], 'ar': ['تقع', 'المحطة', 'غرب', 'الفندق.'] },
    { 'en': ["Let's", 'go!'], 'ar': ['لنذهب.'] },
    { 'en': ['I', 'love', 'American', 'movies.'], 'ar': ['أحب', 'الأفلام', 'الأمريكية.'] },
    { 'en': ["I'm", 'tired.'], 'ar': ['أنا', 'متعب.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'pen?'], 'ar': ['ألديك', 'قلم؟'] },
    { 'en': ['Tom', "wasn't", 'really', 'hungry.'], 'ar': ['لم', 'يكن', 'توم', 'جائعا', 'حقا.'] },
    { 'en': ['He', 'wrote', 'one', 'letter.'], 'ar': ['كتب', 'رسالة', 'واحدة.'] },
    { 'en': ['I', 'want', 'to', 'sleep.'], 'ar': ['أريد', 'أن', 'أنام.'] },
    { 'en': ["Where's", 'the', 'whipped', 'cream?'], 'ar': ['أين', 'القِشدة؟'] },
    { 'en': ['Tom', 'is', 'here.'], 'ar': ['توم', 'هنا'] },
    { 'en': ['I', 'think', 'it', 'is', 'best', 'not', 'to', 'be', 'impolite.'], 'ar': ['أظن', 'أن', 'من', 'الأفضل', 'ألا', 'تكون', 'وقِحًا.'] },
    { 'en': ["That's", 'why', 'I', 'like', 'you.'], 'ar': ['لذلك', 'أحبك'] },
    { 'en': ["He's", 'outraged.'], 'ar': ['إنه', 'غاضب.'] },
    { 'en': ['Health', 'is', 'more', 'important', 'than', 'wealth.'], 'ar': ['الصحة', 'أهم', 'من', 'المال.'] },
    { 'en': ['This', 'reminds', 'me', 'of', 'you.'], 'ar': ['يفكّرني', 'هذا', 'بكِ.'] },
    { 'en': ['We', 'can', 'deliver', 'it', 'this', 'evening.'], 'ar': ['يمكننا', 'أن', 'نوصله', 'الليلة.'] },
    { 'en': ['Tom', 'carefully', 'lifted', 'the', 'box.'], 'ar': ['رفع', 'توم', 'الصندوق', 'بحذر.'] },
    { 'en': ['What', 'should', 'I', 'do?'], 'ar': ['ما', 'الذي', 'عليّ', 'فعله؟'] },
    { 'en': ['Squirrels', 'move', 'quickly.'], 'ar': ['السناجب', 'سريعة', 'الحركة.'] },
    { 'en': ['Tom', "doesn't", 'have', 'many', 'books.'], 'ar': ['ليس', 'لتوم', 'الكثير', 'من', 'الكتب.'] },
    { 'en': ['I', 'skip', 'ads', 'on', 'videos', 'whenever', 'I', 'can.'], 'ar': ['أتخطّى', 'الإعلانات', 'على', 'الفيديوهات', 'كلما', 'أمكن.'] },
    { 'en': ['I', 'took', 'that', 'picture', 'a', 'week', 'ago.'], 'ar': ['أنا', 'أخذت', 'تلك', 'الصورة', 'قبل', 'إسبوع.'] },
    { 'en': ['Tom', 'is', 'going', 'to', 'boarding', 'school', 'next', 'year.'], 'ar': ['سيذهب', 'توم', 'إلى', 'مدرسة', 'داخلية', 'في', 'العام', 'القادم.'] },
    { 'en': ["I'm", 'just', 'a', 'tourist.'], 'ar': ['انا', 'فقط', 'سائح.'] },
    { 'en': ['Any', 'child', 'can', 'do', 'that.'], 'ar': ['أي', 'طفل', 'يمكنه', 'فعل', 'ذلك.'] },
    { 'en': ["You'll", 'get', 'lost.'], 'ar': ['ستضل', 'طريقك.'] },
    { 'en': ["Don't", 'forget', 'our', 'date', 'tomorrow.'], 'ar': ['لا', 'تنسى', 'ميعادنا', 'غدا.'] },
    { 'en': ['Do', 'those', 'insects', 'sting?'], 'ar': ['هل', 'تلسع', 'هذه', 'الحشرات؟'] },
    { 'en': ['What', 'time', 'will', 'you', 'leave?'], 'ar': ['متى', 'ستغادر؟'] },
    { 'en': ["I'll", 'call', 'you.'], 'ar': ['سأتصل', 'بك.'] },
    { 'en': ['Tom', 'was', "Mary's", 'first', 'love.'], 'ar': ['توم', 'كان', 'حُبُّ', 'ماري', 'الأوّل.'] },
    { 'en': ['Do', 'you', 'understand?'], 'ar': ['هل', 'تفهم؟'] },
    { 'en': ['Bring', 'backup.'], 'ar': ['أحضر', 'النُسخة', 'الإحتياطية.'] },
    { 'en': ['There', 'are', 'twelve', 'months', 'in', 'a', 'year.'], 'ar': ['هناك', 'اثنا', 'عشر', 'شهرًا', 'في', 'السنة.'] },
    { 'en': ["That's", 'none', 'of', 'your', 'business.'], 'ar': ['ليس', 'ذلك', 'من', 'شأنك.'] },
    { 'en': ['The', 'children', 'love', 'listening', 'to', 'fairy', 'tales.'], 'ar': ['الأطفال', 'يحبون', 'الاستماع', 'إلى', 'القصص', 'الخيالية.'] },
    { 'en': ['You', 'broke', 'my', 'heart.'], 'ar': ['أنتَ', 'كسرتَ', 'قلبي.'] },
    { 'en': ['He', 'gives', 'her', 'everything', 'she', 'asks', 'for.'], 'ar': ['أعطاها', 'كل', 'ما', 'ابتغته', '.'] },
    { 'en': ['It', 'was', 'a', 'nerve-wracking', 'experience.'], 'ar': ['كانت', 'تجربة', 'محطمة', 'للأعصاب.'] },
    { 'en': ['The', 'king', 'crushed', 'his', 'enemies.'], 'ar': ['قضى', 'الملك', 'على', 'أعدائه.'] },
    { 'en': ['I', 'wish', 'I', 'knew', 'where', 'he', 'was!'], 'ar': ['ليتني', 'كنت', 'أعرف', 'أين', 'هو!'] },
    { 'en': ['She', 'took', 'a', 'pen', 'out', 'of', 'her', 'pocket.'], 'ar': ['أخرجت', 'قلماً', 'من', 'جيبها.'] },
    { 'en': ["Let's", 'split.'], 'ar': ['دعونا', 'ننقسم.'] },
    { 'en': ['I', 'want', 'you.'], 'ar': ['أريدك.'] },
    { 'en': ['The', 'man', 'was', 'dying.'], 'ar': ['كان', 'الرجل', 'يحتضر.'] },
    { 'en': ['I', "don't", 'know', 'what', 'to', 'do.'], 'ar': ['لا', 'أعرف', 'ما', 'الذي', 'عليّ', 'فعله.'] },
    { 'en': ['She', 'is', 'used', 'to', 'staying', 'up', 'late.'], 'ar': ['إنها', 'معتادة', 'على', 'السهر.'] },
    { 'en': ['Tom', 'asked', 'me', 'what', 'had', 'become', 'of', 'Mary.'], 'ar': ['سألني', 'توم', 'عما', 'أصبحت', 'ماري.'] },
    { 'en': ['They', 'are', 'talking', 'about', 'this', 'and', 'that.'], 'ar': ['إنهم', 'يتحدثون', 'عن', 'هذا', 'و', 'ذاك.'] },
    { 'en': ["I'm", 'sorry,', 'but', 'I', "can't", 'hear', 'you', 'well.'], 'ar': ['آسف', 'لا', 'يمكنني', 'سماعك', 'جيداً.'] },
    { 'en': ['I', 'remember', 'locking', 'the', 'door.'], 'ar': ['أذكر', 'أني', 'أغلقت', 'الباب.'] },
    { 'en': ['He', 'believes', 'whatever', 'I', 'say.'], 'ar': ['هو', 'يصدق', 'أى', 'شئ', 'أقوله.'] },
    { 'en': ['It', 'was', 'your', 'mistake,', 'not', 'mine.'], 'ar': ['انة', 'خطأك', 'وليس', 'خطئى'] },
    { 'en': ['I', 'know', "you're", 'going', 'to', 'say', 'no.'], 'ar': ['كنت', 'أعلم', 'أنك', 'ستقول', 'لا'] },
    { 'en': ['You', 'speak', 'fluent', 'English.'], 'ar': ['إنك', 'تتحدث', 'الإنجليزية', 'بطلاقة.'] },
    { 'en': ['There', 'is', 'no', 'reason', 'why', 'I', "shouldn't", 'do', 'it.'], 'ar': ['لا', 'يوجد', 'سبب', 'لئلا', 'أفعله.'] },
    { 'en': ['This', 'is', 'for', 'you.'], 'ar': ['هذا', 'لك.'] },
    { 'en': ['Hi!', 'Do', 'you', 'work', 'here?'], 'ar': ['أهلاً', '،', 'هل', 'تعمل', 'هنا؟'] },
    { 'en': ['You', "won't", 'believe', 'what', 'Tom', 'told', 'me!'], 'ar': ['لن', 'تصدّق', 'ما', 'قاله', 'لي', 'توم!'] },
    { 'en': ['Even', 'children', 'can', 'read', 'this', 'book.'], 'ar': ['حتى', 'الأطفال', 'بإمكانهم', 'قراءة', 'هذا', 'الكتاب.'] },
    { 'en': ['I', 'hope', 'Tom', "isn't", 'looking', 'for', 'us.'], 'ar': ['آمل', 'ألّا', 'يكون', 'توم', 'في', 'إثرنا.'] },
    { 'en': ['Take', 'as', 'much', 'as', 'you', 'want.'], 'ar': ['خذ', 'قدر', 'ما', 'تريد.'] },
    { 'en': ['I', 'heard', 'my', 'mother', 'singing', 'in', 'the', 'shower.'], 'ar': ['سمعت', 'والدتي', 'تغني', 'في', 'الحمام.'] },
    { 'en': ['Here', 'is', 'the', 'bill.'], 'ar': ['تفضل', 'الحساب'] },
    { 'en': ['My', 'legs', 'still', 'hurt.'], 'ar': ['ما', 'زالت', 'قدمَيّ', 'تؤلمانني.'] },
    { 'en': ["I'm", 'a', 'student.'], 'ar': ['أنا', 'تلميذ.'] },
    { 'en': ['We', 'must', 'not', 'speak', 'in', 'the', 'library.'], 'ar': ['علينا', 'ألّا', 'نتكلم', 'في', 'المكتبة.'] },
    { 'en': ['The', 'pain', 'was', 'terrible.'], 'ar': ['كان', 'الألم', 'فظيعاً.'] },
    { 'en': ['My', 'uncle', 'died', 'a', 'year', 'ago.'], 'ar': ['مات', 'عمي', 'منذ', 'سنة.'] },
    { 'en': ['He', 'attempted', 'to', 'escape.'], 'ar': ['حاول', 'الهروب.'] },
    { 'en': ["You're", "Tom's", 'friend,', "aren't", 'you?'], 'ar': ['أنت', 'صديق', 'توم،', 'أليس', 'ذلك', 'صحيحاً؟'] },
    { 'en': ['I', 'can', 'read', 'Spanish', 'easily.'], 'ar': ['يمكنني', 'قراءة', 'الأسبانية', 'بسهولة.'] },
    { 'en': ['Do', 'your', 'best', 'in', 'everything.'], 'ar': ['ابذل', 'وسعك', 'في', 'كل', 'شيء', '.'] },
    { 'en': ['Their', 'wedding', 'will', 'be', 'tomorrow.'], 'ar': ['سيقام', 'حفل', 'زفافهم', 'غداً.'] },
    { 'en': ['How', 'can', 'I', 'help?'], 'ar': ['كيف', 'بإمكاني', 'مساعدتك', '؟'] },
    { 'en': ['She', 'advised', 'him', 'not', 'to', 'use', 'too', 'much', 'sugar.'], 'ar': ['نصحته', 'بألا', 'يكثر', 'من', 'السكر.'] },
    { 'en': ['Do', 'you', 'want', 'to', 'go', 'out', 'for', 'a', 'drink?'], 'ar': ['أتريد', 'الخروج', 'لاحتساء', 'شيء؟'] },
    { 'en': ['I', 'have', 'the', 'ace', 'of', 'diamonds.'], 'ar': ['لدي', 'الاس', 'الديناري.'] },
    { 'en': ['What', 'did', 'you', 'come', 'here', 'for?'], 'ar': ['من', 'أجل', 'ماذا', 'أتيتَ', 'إلى', 'هنا؟'] },
    { 'en': ['I', 'went', 'to', 'Europe', 'once.'], 'ar': ['ذهبت', 'إلى', 'أوروبا', 'مرة.'] },
    { 'en': ['I', 'am', 'ashamed', 'of', 'my', "son's", 'conduct.'], 'ar': ['أخجلُ', 'من', 'تصرّفِ', 'وَلدي.'] },
    { 'en': ["I've", 'never', 'seen', 'anything', 'like', 'that.'], 'ar': ['أنا', 'لم', 'أرَ', 'شيئاً', 'مثل', 'هذا.'] },
    { 'en': ['He', 'paid', 'no', 'attention', 'to', 'my', 'warning.'], 'ar': ['لم', 'يعر', 'أي', 'اهتمام', 'لتحذيري.'] },
    { 'en': ["It's", 'too', 'small.'], 'ar': ['إنها', 'صغيرة', 'جداً'] },
    { 'en': ['Leave', 'the', 'rest', 'to', 'me.'], 'ar': ['اترك', 'الباقي', 'لي.'] },
    { 'en': ['He', 'has', 'started', 'to', 'write', 'a', 'novel.'], 'ar': ['لقد', 'بدأ', 'بكتابة', 'رواية.'] },
    { 'en': ['The', 'end', 'justifies', 'the', 'means.'], 'ar': ['الغاية', 'تبرر', 'الوسيلة.'] },
    { 'en': ['When', 'did', 'you', 'come', 'to', 'Japan?'], 'ar': ['متى', 'أتيت', 'إلى', 'اليابان؟'] },
    { 'en': ['Do', 'you', 'live', 'with', 'your', 'parents?'], 'ar': ['هل', 'تعيش', 'مع', 'والدَيك؟'] },
    { 'en': ['She', 'likes', 'oranges.'], 'ar': ['تحب', 'البرتقال.'] },
    { 'en': ['We', 'are', 'Arabs.'], 'ar': ['نحن', 'عرب.'] },
    { 'en': ['I', "don't", 'have', 'any', 'evidence.'], 'ar': ['لا', 'أملك', 'أي', 'دليل.'] },
    { 'en': ['You', "don't", 'have', 'a', 'fever.'], 'ar': ['ليس', 'لديك', 'حمى.'] },
    { 'en': ['Please', 'throw', 'the', 'ball.'], 'ar': ['من', 'فضلك', 'اِرمِ', 'الكرة.'] },
    { 'en': ['None', 'of', 'my', 'friends', 'drink', 'coffee.'], 'ar': ['لا', 'يشرب', 'أي', 'من', 'أصدقائي', 'القهوة.'] },
    { 'en': ['Who', 'else', 'can', 'answer', 'my', 'question?'], 'ar': ['هل', 'هناك', 'شخص', 'آخر', 'بإمكانه', 'الإجابة', 'على', 'سؤالي؟'] },
    { 'en': ['I', 'am', 'from', 'Norway.'], 'ar': ['أنا', 'من', 'النرويج.'] },
    { 'en': ['Do', 'you', 'think', 'Tom', 'will', 'come?'], 'ar': ['هل', 'تعتقدين', 'أن', 'توم', 'سيأتي؟'] },
    { 'en': ['There', 'are', 'sixty', 'seconds', 'in', 'a', 'minute.'], 'ar': ['في', 'الدقيقة', 'ستون', 'ثانية.'] },
    { 'en': ['My', 'life', 'was', 'in', 'danger.'], 'ar': ['حياتي', 'كانت', 'في', 'خطر.'] },
    { 'en': ['Guns', "don't", 'kill', 'people.', 'People', 'kill', 'people.'], 'ar': ['الأسلحة', 'النارية', 'ليست', 'من', 'يقتل', 'البشر.', 'البشر', 'هم', 'من', 'يقتل', 'البشر.'] },
    { 'en': ['He', 'must', 'be', 'from', 'the', 'South.'], 'ar': ['لا', 'بد', 'أنه', 'من', 'الجنوب.'] },
    { 'en': ['Laughter', 'is', 'the', 'best', 'medicine.'], 'ar': ['الضحك', 'أفضل', 'دواء.'] },
    { 'en': ['He', 'loves', 'music.'], 'ar': ['يحب', 'الموسيقي.'] },
    { 'en': ['Can', 'you', 'help', 'me?'], 'ar': ['هل', 'يمكنك', 'أن', 'تساعدني؟'] },
    { 'en': ['Stop', 'it.'], 'ar': ['أوقفه'] },
    { 'en': ["It's", 'time', 'to', 'do', 'something.'], 'ar': ['إنهُ', 'الوقت', 'لِفعل', 'شيئاً', 'ما.'] },
    { 'en': ['The', 'plan', 'backfired.'], 'ar': ['أنقلبَ', 'السحرُ', 'على', 'الساحرِ.'] },
    { 'en': ['Plastic', 'does', 'not', 'break', 'easily.'], 'ar': ['لا', 'ينكسر', 'البلاستيك', 'بسهولة.'] },
    { 'en': ['Everyone', 'wants', 'to', 'meet', 'you.', "You're", 'famous!'], 'ar': ['الكل', 'يريد', 'أن', 'يتعرف', 'عليك.', 'إنك', 'مشهور!'] },
    { 'en': ['He', 'bought', 'a', 'dress', 'for', 'her.'], 'ar': ['اشترى', 'لها', 'فستاناً.'] },
    { 'en': ['There', "isn't", 'any', 'hope.'], 'ar': ['لا', 'يوجد', 'أي', 'أمل.'] },
    { 'en': ['No', 'one', 'knew', 'who', 'did', 'it.'], 'ar': ['لم', 'يعرف', 'أحد', 'من', 'فعلها.'] },
    { 'en': ['Your', 'daughters', 'have', 'turned', 'into', 'women.'], 'ar': ['لقد', 'أصبحت', 'بناتك', 'نساء.'] },
    { 'en': ['When', 'did', 'you', 'begin', 'learning', 'German?'], 'ar': ['متى', 'بدأت', 'تتعلم', 'اللّغة', 'الألمانية؟'] },
    { 'en': ['We', 'found', 'the', 'front', 'door', 'locked.'], 'ar': ['وجدنا', 'الباب', 'الرئيسي', 'مقفل.'] },
    { 'en': ["It's", 'cold.'], 'ar': ['الجو', 'بارد.'] },
    { 'en': ['Who', 'owns', 'this', 'land?'], 'ar': ['من', 'يملك', 'قطعة', 'الأرض', 'هذه؟'] },
    { 'en': ['Tom', 'is', 'waiting', 'to', 'talk', 'to', 'you.'], 'ar': ['ينتظرك', 'توم', 'للتحدث', 'معك.'] },
    { 'en': ['Is', 'there', 'an', 'elevator?'], 'ar': ['هل', 'هناك', 'مصعد؟'] },
    { 'en': ['Here', 'it', 'is.'], 'ar': ['ها', 'هي.'] },
    { 'en': ['She', 'writes', 'to', 'me', 'once', 'a', 'month.'], 'ar': ['تكتب', 'لي', 'مرةً', 'كل', 'شهر.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'of', 'dreams.'], 'ar': ['أحلم', 'كثيراً.'] },
    { 'en': ['Do', 'you', 'think', 'Tom', 'will', 'come?'], 'ar': ['هل', 'تعتقد', 'أن', 'توم', 'سيأتي؟'] },
    { 'en': ['The', 'house', 'is', 'haunted.'], 'ar': ['البيت', 'مسكون', 'بالأشباح.'] },
    { 'en': ['Stop', 'them.'], 'ar': ['أوقفهم.'] },
    { 'en': ['Get', 'me', 'the', 'scissors,', 'please.'], 'ar': ['من', 'فضلك', 'ناولني', 'المقصّ.'] },
    { 'en': ['How', 'did', 'you', 'come', 'to', 'know', 'each', 'other?'], 'ar': ['كيف', 'تعرفتما', 'إلى', 'بعضكما؟'] },
    { 'en': ['What', 'turned', 'her', 'against', 'you?'], 'ar': ['ما', 'الذي', 'قلبها', 'عليك؟'] },
    { 'en': ['Do', 'you', 'have', 'any', 'plans', 'for', 'today?'], 'ar': ['هل', 'عندك', 'خطط', 'لليوم؟'] },
    { 'en': ["I'll", 'take', 'that', 'one,', 'too.'], 'ar': ['سآخذ', 'تلك', 'أيضا.'] },
    { 'en': ['This', 'dictionary', 'is', 'superior', 'to', 'that', 'one.'], 'ar': ['هذا', 'القاموس', 'أفضل', 'من', 'ذاك.'] },
    { 'en': ['I', 'came', 'here', 'yesterday.'], 'ar': ['كنت', 'هنا', 'البارحة.'] },
    { 'en': ["I'll", 'get', 'you', 'something', 'to', 'drink.'], 'ar': ['ساُحضِر', 'لكَ', 'شيئاُ', 'لتشربهُ.'] },
    { 'en': ['He', 'mocked', 'me.'], 'ar': ['لقد', 'سخر', 'منّي.'] },
    { 'en': ['Please', 'sit', 'down.'], 'ar': ['تفضل', 'بالجلوس', 'من', 'فضلك.'] },
    { 'en': ['I', 'rushed', 'out', 'to', 'see', 'what', 'was', 'going', 'on.'], 'ar': ['انا', 'خرجت', 'مسرعا', 'لارى', 'ماذا', 'حدث', 'بالخارج'] },
    { 'en': ['You', 'may', 'come', 'if', 'you', 'like.'], 'ar': ['بإمكانك', 'المجيء', 'إن', 'أحببت.'] },
    { 'en': ['Did', 'Tom', 'die?'], 'ar': ['هل', 'مات', 'توم؟'] },
    { 'en': ["You're", 'a', 'philosopher,', "aren't", 'you?'], 'ar': ['إنك', 'فيلسوف', '،', 'أليس', 'ذلك', 'صحيحاً؟'] },
    { 'en': ['I', 'really', 'trust', 'his', 'ability.'], 'ar': ['أنا', 'حقاً', 'أثق', 'بقدرته.'] },
    { 'en': ['The', 'birds', 'flew', 'away', 'in', 'all', 'directions.'], 'ar': ['حلق', 'الطير', 'في', 'جميع', 'الإتجهات.'] },
    { 'en': ['When', 'will', 'you', 'come', 'home?'], 'ar': ['متى', 'ستعود', 'إلى', 'البيت؟'] },
    { 'en': ['No', 'one', 'loves', 'war.'], 'ar': ['لا', 'يحب', 'أحداً', 'الحرب.'] },
    { 'en': ['Check', 'this.'], 'ar': ['إفحص', 'هذا.'] },
    { 'en': ['Come', 'here', 'at', 'once.'], 'ar': ['تعال', 'إلى', 'هنا', 'حالاً.'] },
    { 'en': ['May', 'I', 'help', 'you?'], 'ar': ['هل', 'يمكنني', 'أن', 'اساعدك؟'] },
    { 'en': ['What', 'are', 'you', 'laughing', 'at?'], 'ar': ['علامَ', 'تضحك؟'] },
    { 'en': ['No,', 'thank', 'you.'], 'ar': ['لا،', 'شكراً', 'لك.'] },
    { 'en': ['I', 'changed', 'my', 'address', 'last', 'month.'], 'ar': ['غيرت', 'عنواني', 'الشهر', 'الماضي.'] },
    { 'en': ['The', 'tourist', 'bus', 'crossed', 'the', 'border.'], 'ar': ['عبرت', 'الحافلة', 'السياحية', 'الحدود.'] },
    { 'en': ['I', 'congratulated', 'him', 'on', 'his', 'success.'], 'ar': ['باركت', 'له', 'على', 'نجاحه.'] },
    { 'en': ["I'm", 'from', 'Tokyo.'], 'ar': ['أنا', 'من', 'طوكيو.'] },
    { 'en': ["Don't", 'worry.', 'This', "won't", 'happen', 'again.'], 'ar': ['لا', 'تقلق.', 'لن', 'يحدث', 'هذا', 'ثانية.'] },
    { 'en': ['Those', 'are', 'my', 'pants.'], 'ar': ['ذلك', 'بنطالي.'] },
    { 'en': ['I', 'expect', 'your', 'help.'], 'ar': ['أتوقع', 'منك', 'أن', 'تساعدني.'] },
    { 'en': ['I', 'have', 'been', 'working', 'since', 'six', 'this', 'morning.'], 'ar': ['أعمل', 'منذ', 'السادسة', 'صباحاً.'] },
    { 'en': ["How's", 'your', 'brother?'], 'ar': ['كيف', 'حال', 'أخيك؟'] },
    { 'en': ['You', 'speak', 'like', 'your', 'mother.'], 'ar': ['تتحدث', 'مثل', 'أمك.'] },
    { 'en': ['The', 'house', 'went', 'cheap.'], 'ar': ['بِيع', 'المنزل', 'بثمن', 'بخس.'] },
    { 'en': ['Could', 'you', 'please', 'repeat', 'that?'], 'ar': ['هلّا', 'كررت', 'هذا؟'] },
    { 'en': ['You', 'made', 'the', 'same', 'mistake', 'as', 'last', 'time.'], 'ar': ['أخطأت', 'نفس', 'خطإكَ', 'المرة', 'السابقة.'] },
    { 'en': ['He', 'was', 'wounded', 'in', 'the', 'shoulder.'], 'ar': ['جُرح', 'في', 'كتفه.'] },
    { 'en': ['Please', 'help', 'yourself.'], 'ar': ['تفضّل', 'البيت', 'بيتك.'] },
    { 'en': ['She', 'is', 'absent', 'because', 'of', 'sickness.'], 'ar': ['غابت', 'لأنها', 'مريضة.'] },
    { 'en': ["I'm", 'reading', 'this', 'book.'], 'ar': ['أنا', 'أقرأ', 'هذا', 'الكتاب'] },
    { 'en': ['I', "don't", 'like', 'talking', 'with', 'strangers.'], 'ar': ['لا', 'افضل', 'ان', 'اتكلم', 'مع', 'الغرباء.'] },
    { 'en': ['He', 'seems', 'to', 'have', 'been', 'rich.'], 'ar': ['يبدو', 'أنه', 'كان', 'غنياً.'] },
    { 'en': ['Tom', 'turned', 'the', 'lights', 'off.'], 'ar': ['أطفئ', 'توم', 'المصابيح.'] },
    { 'en': ['Why', 'is', 'he', 'here?'], 'ar': ['لماذا', 'هو', 'هنا؟'] },
    { 'en': ['I', 'am', 'determined', 'to', 'be', 'a', 'scientist.'], 'ar': ['عقدتُ', 'العزم', 'على', 'أن', 'أكون', 'عالمًا.'] },
    { 'en': ['Thank', 'you', 'for', 'the', 'information.'], 'ar': ['شكراً', 'لكَ', 'على', 'المعلومة.'] },
    { 'en': ['Where', 'are', 'you?'], 'ar': ['أين', 'أنت؟'] },
    { 'en': ['The', 'ground', 'seems', 'wet.'], 'ar': ['تبدو', 'الأرض', 'مبلولة.'] },
    { 'en': ["I'm", 'going', 'to', 'run.'], 'ar': ['أنا', 'ذاهب', 'للجري'] },
    { 'en': ['We', 'watched', 'TV', 'after', 'lunch.'], 'ar': ['شاهدنا', 'التلفاز', 'بعد', 'الغداء.'] },
    { 'en': ['I', 'resigned.'], 'ar': ['استقلت.'] },
    { 'en': ['We', 'still', 'have', 'ten', 'miles', 'to', 'go.'], 'ar': ['لا', 'يزال', 'أمامنا', 'عشرة', 'أميال.'] },
    { 'en': ['Our', 'plane', 'was', 'flying', 'above', 'the', 'clouds.'], 'ar': ['كانت', 'طائرتنا', 'تحلق', 'فوق', 'السحب.'] },
    { 'en': ['This', 'tie', "doesn't", 'go', 'with', 'my', 'suit.'], 'ar': ['ربطة', 'العنق', 'هذه', 'لا', 'تليق', 'ببزتي.'] },
    { 'en': ['Tom', 'took', 'the', 'wrong', 'bus.'], 'ar': ['ركب', 'توم', 'الحافلة', 'الخطأ'] },
    { 'en': ['She', 'can', 'speak', 'French', 'fluently.'], 'ar': ['بإمكانها', 'تحدث', 'الفرنسية', 'بطلاقة.'] },
    { 'en': ['I', 'met', 'her', 'in', 'the', 'winter', 'several', 'years', 'ago.'], 'ar': ['التقيت', 'بها', 'ذات', 'شتاء', 'قبل', 'عدة', 'سنوات.'] },
    { 'en': ['I', 'remember', 'mailing', 'the', 'letter.'], 'ar': ['أذكر', 'أني', 'بعثت', 'بالرسالة.'] },
    { 'en': ['Follow', 'the', 'car.'], 'ar': ['إتبع', 'تلك', 'السيارة.'] },
    { 'en': ['It', 'is', 'raining', 'hard.'], 'ar': ['إنها', 'تمطر', 'بشدة.'] },
    { 'en': ['We', 'have', 'no', 'options', 'but', 'to', 'continue.'], 'ar': ['لا', 'خيار', 'لنا', 'سوى', 'المتابعة.'] },
    { 'en': ['The', 'bus', 'leaves', 'in', 'five', 'minutes.'], 'ar': ['سيغادر', 'الباص', 'في', 'غضون', 'خمس', 'دقائق.'] },
    { 'en': ["Won't", 'you', 'go?'], 'ar': ['ألن', 'تذهب؟'] },
    { 'en': ['My', 'father', 'is', 'a', 'teacher.'], 'ar': ['أبي', 'مدرّس.'] },
    { 'en': ['They', 'believed', 'he', 'was', 'honest.'], 'ar': ['صدقوا', 'أنه', 'صادق.'] },
    { 'en': ['His', 'memory', 'amazes', 'me.'], 'ar': ['ذاكرتهُ', 'تُدهِشُني.'] },
    { 'en': ['Have', 'you', 'ever', 'traveled', 'alone?'], 'ar': ['هل', 'سافرت', 'لوحدك', 'قط؟'] },
    { 'en': ['It', 'looks', 'like', 'this', 'house', 'is', 'a', 'total', 'mess.'], 'ar': ['يبدو', 'هذا', 'المنزل', 'مخرّبا', 'بالكامل.'] },
    { 'en': ['This', 'bird', 'cannot', 'fly.'], 'ar': ['هذا', 'الطائر', 'لا', 'يمكنه', 'الطيران.'] },
    { 'en': ['Yesterday', 'was', 'Thursday.'], 'ar': ['البارحة', 'كان', 'يوم', 'الخميس.'] },
    { 'en': ['We', 'are', 'teachers.'], 'ar': ['نحن', 'مدرسون.'] },
    { 'en': ['A', 'widower', 'is', 'a', 'man', 'whose', 'wife', 'has', 'died.'], 'ar': ['الأرمل', 'هو', 'رجل', 'ماتت', 'زوجه.'] },
    { 'en': ['Come', 'along', 'with', 'me.'], 'ar': ['تعال', 'معي.'] },
    { 'en': ['No', 'one', 'encouraged', 'her.'], 'ar': ['لم', 'يشجّعها', 'أحد.'] },
    { 'en': ['This', 'is', 'a', 'government', 'project.'], 'ar': ['هذا', 'مشروع', 'حكومي.'] },
    { 'en': ['I', 'asked', 'him', 'where', 'he', 'was', 'going.'], 'ar': ['سألته', 'إلى', 'أين', 'سيذهب.'] },
    { 'en': ['I', 'always', 'get', 'nervous', 'before', 'I', 'go', 'on', 'stage.'], 'ar': ['أتوتر', 'دائما', 'قبل', 'أن', 'أخرج', 'إلى', 'المسرح.'] },
    { 'en': ['He', 'showed', 'it', 'to', 'me.'], 'ar': ['إنهُ', 'بينها', 'لي.'] },
    { 'en': ['I', 'love', 'this', 'store.'], 'ar': ['أحِبُّ', 'هذا', 'المتجر.'] },
    { 'en': ['I', 'was', 'at', 'a', 'movie', 'theater.'], 'ar': ['كنت', 'في', 'دور', 'عرض', 'للسينما.'] },
    { 'en': ['You', 'are', 'mistaken', 'about', 'that.'], 'ar': ['إنك', 'مخطئ', 'بشأن', 'ذلك.'] },
    { 'en': ['Calm', 'down.'], 'ar': ['اِهدأ.'] },
    { 'en': ['My', 'mother', 'died', 'when', 'I', 'was', 'a', 'kid.'], 'ar': ['ماتت', 'أمي', 'عندما', 'كنت', 'صغيراً.'] },
    { 'en': ['I', 'like', 'your', 'car.'], 'ar': ['تعجبني', 'سيارتك.'] },
    { 'en': ["There's", 'a', 'lot', 'we', 'can', 'agree', 'on.'], 'ar': ['هناك', 'الكثير', 'ما', 'يمكننا', 'الإتفاق', 'عليه.'] },
    { 'en': ['It', 'was', 'your', 'own', 'choice.'], 'ar': ['كان', 'ذلك', 'خيارك', 'أنت.'] },
    { 'en': ['Each', 'of', 'them', 'has', 'his', 'own', 'car.'], 'ar': ['كلٌّ', 'منهم', 'لديه', 'سيارته', 'الخاصة.'] },
    { 'en': ['It', 'is', 'wrong', 'to', 'steal.'], 'ar': ['السرقة', 'خطأ.'] },
    { 'en': ['Whose', 'shoes', 'are', 'these?'], 'ar': ['لمن', 'هذين', 'الحذائين؟'] },
    { 'en': ["I'm", 'sure', "I'll", 'be', 'fine.'], 'ar': ['أنا', 'متأكد', 'أني', 'سأكون', 'بخير.'] },
    { 'en': ['The', 'dogs', 'kept', 'barking.'], 'ar': ['واصلت', 'الكلاب', 'النّباح.'] },
    { 'en': ["There's", 'not', 'enough', 'coffee.'], 'ar': ['ليس', 'هناك', 'قهوة', 'كافية.'] },
    { 'en': ['That', 'car', 'is', 'very', 'new.'], 'ar': ['تلك', 'السيارة', 'حقاً', 'جديدة'] },
    { 'en': ['How', 'much', 'longer', 'is', 'it', 'going', 'to', 'take?'], 'ar': ['كم', 'من', 'الوقت', 'سوف', 'تستغرق', 'بعد؟'] },
    { 'en': ['She', 'continued', 'her', 'talk.'], 'ar': ['أكملت', 'حديثها.'] },
    { 'en': ['Above', 'all,', 'children', 'need', 'love.'], 'ar': ['و', 'فوق', 'كل', 'شيء،', 'يحتاج', 'الأطفال', 'إلى', 'الحب.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'car?'], 'ar': ['أتمتلك', 'سيارة؟'] },
    { 'en': ['The', 'house', 'is', 'said', 'to', 'be', 'haunted.'], 'ar': ['يُقال', 'أن', 'ذاك', 'المنزل', 'مسكون.'] },
    { 'en': ['I', 'like', 'the', 'dog.'], 'ar': ['أحب', 'ذلك', 'الكلب.'] },
    { 'en': ["There's", 'so', 'much', 'I', 'want', 'to', 'say', 'to', 'you.'], 'ar': ['هناك', 'الكثير', 'مما', 'أود', 'قوله', 'لك.'] },
    { 'en': ['Why', "doesn't", 'he', 'send', 'me', 'letters', 'anymore?'], 'ar': ['لِمَ', 'لَمْ', 'يعد', 'يرسل', 'لي', 'رسائلا؟'] },
    { 'en': ['My', 'dog', 'is', 'afraid', 'of', 'water.'], 'ar': ['كلبي', 'خائف', 'من', 'الماء'] },
    { 'en': ['Your', 'dreams', 'have', 'come', 'true.'], 'ar': ['تحققت', 'أحلامك.'] },
    { 'en': ['You', 'look', 'very', 'tired.'], 'ar': ['تبدو', 'مجهداً.'] },
    { 'en': ['May', 'I', 'smoke', 'here?'], 'ar': ['أيمكنني', 'أن', 'أدخّن', 'هنا؟'] },
    { 'en': ['What', 'made', 'you', 'come', 'here?'], 'ar': ['ما', 'الذي', 'جعلك', 'تأتي', 'إلى', 'هنا؟'] },
    { 'en': ["We're", 'not', 'used', 'to', 'doing', 'that.'], 'ar': ['ليس', 'من', 'عادتنا', 'فعل', 'ذلك.'] },
    { 'en': ['Obviously,', 'he', 'is', 'to', 'blame.'], 'ar': ['الواضح،', 'هو', 'من', 'يقع', 'عليه', 'اللوم.'] },
    { 'en': ["Don't", 'say', 'such', 'things.'], 'ar': ['لا', 'تَقُل', 'مِثل', 'هذهِ', 'الأشياء.'] },
    { 'en': ['He', 'is', 'a', 'war', 'orphan.'], 'ar': ['إنه', 'يتيم', 'حرب.'] },
    { 'en': ['I', 'got', 'thirsty.'], 'ar': ['أنا', 'عطشان.'] },
    { 'en': ['I', 'want', 'a', 'book', 'to', 'read.'], 'ar': ['أريد', 'كتاباً', 'لأقرأ'] },
    { 'en': ['My', 'father', 'bought', 'me', 'a', 'bicycle.'], 'ar': ['اشترى', 'لي', 'أبي', 'دراجةً.'] },
    { 'en': ["Let's", 'forget', 'about', 'this.'], 'ar': ['دعنا', 'ننسى', 'هذا.'] },
    { 'en': ['He', 'speaks', 'Japanese', 'well.'], 'ar': ['إنه', 'يتكلم', 'اليابانية', 'جيداً.'] },
    { 'en': ['I', 'was', 'nearly', 'hit', 'by', 'a', 'car.'], 'ar': ['كادت', 'أن', 'تصدمني', 'سيارة.'] },
    { 'en': ['My', 'aim', 'is', 'to', 'be', 'a', 'doctor.'], 'ar': ['هدفي', 'أن', 'أصبح', 'طبيباً.'] },
    { 'en': ['I', 'hope', 'this', 'is', 'accurate', 'information.'], 'ar': ['آمل', 'أن', 'هذه', 'معلومات', 'ذات', 'مصداقية.'] },
    { 'en': ['I', 'went', 'to', 'the', 'hospital', 'to', 'visit', 'him.'], 'ar': ['ذهبت', 'إلى', 'المستشفى', 'لزيارته.'] },
    { 'en': ['He', 'employs', 'a', 'maid.'], 'ar': ['إنه', 'يوظف', 'خادمة.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Tokyo.'], 'ar': ['أريد', 'أن', 'أذهب', 'إلى', 'طوكيو.'] },
    { 'en': ["I've", 'studied', 'English', 'for', 'five', 'years.'], 'ar': ['درست', 'الإنجليزية', 'لمدة', 'خمس', 'سنوات.'] },
    { 'en': ['This', 'is', 'an', 'important', 'letter.'], 'ar': ['هذه', 'رسالة', 'مهمة.'] },
    { 'en': ['Cheer', 'him', 'up', 'when', 'you', 'see', 'him.'], 'ar': ['أدخل', 'البهجة', 'عليه', 'عندما', 'تراه.'] },
    { 'en': ['One', 'million', 'people', 'died', 'in', 'the', 'war.'], 'ar': ['مات', 'مليون', 'شخص', 'في', 'الحرب.'] },
    { 'en': ['He', 'never', 'drinks', 'alcohol.'], 'ar': ['إنه', 'لا', 'يشرب', 'الكحول', 'على', 'الإطلاق.'] },
    { 'en': ['Tom', 'will', 'never', 'do', 'that.'], 'ar': ['لن', 'يقوم', 'توم', 'بفعل', 'ذلك', 'أبدا.'] },
    { 'en': ['When', 'did', 'you', 'get', 'back', 'from', 'your', 'trip?'], 'ar': ['متى', 'عدت', 'من', 'رحلتك؟'] },
    { 'en': ['I', 'lost', 'my', 'job.'], 'ar': ['خسرت', 'وظيفتي.'] },
    { 'en': ['The', 'knife', 'is', 'not', 'sharp.'], 'ar': ['السكين', 'ليست', 'حادة.'] },
    { 'en': ["I'm", 'sorry.'], 'ar': ['أتأسف.'] },
    { 'en': ['I', "didn't", 'have', 'a', 'choice.'], 'ar': ['لم', 'يكن', 'لديَّ', 'خيار.'] },
    { 'en': ['I', 'think', "it's", 'time', 'for', 'a', 'drink.'], 'ar': ['أظن', 'أنه', 'الوقت', 'لشرب', 'شيء', 'ما.'] },
    { 'en': ['I', 'will', 'show', 'you', 'some', 'pictures.'], 'ar': ['سأريك', 'بعض', 'الصور.'] },
    { 'en': ['Can', 'you', 'come?'], 'ar': ['هل', 'يمكنك', 'القدوم؟'] },
    { 'en': ['Tom', 'laid', 'the', 'gun', 'down', 'on', 'the', 'floor.'], 'ar': ['وضع', 'توم', 'السلاح', 'على', 'الأرض.'] },
    { 'en': ['Please', 'take', 'me', 'to', 'the', 'hospital.'], 'ar': ['من', 'فضلك', 'خذني', 'إلى', 'المستشفى.'] },
    { 'en': ['Answer', 'the', 'telephone,', 'will', 'you?'], 'ar': ['هلّا', 'رددت', 'على', 'الهاتف؟'] },
    { 'en': ['The', 'teacher', 'is', 'with', 'the', 'school', 'principal.'], 'ar': ['المدرس', 'عند', 'المدير.'] },
    { 'en': ['They', 'built', 'a', 'fence', 'around', 'the', 'farm.'], 'ar': ['بنوا', 'سياجاً', 'حول', 'المزرعة.'] },
    { 'en': ['Japan', 'and', 'South', 'Korea', 'are', 'neighbors.'], 'ar': ['اليابان', 'وكوريا', 'الجنوبية', 'جارتان.'] },
    { 'en': ['I', 'have', 'a', 'pain', 'in', 'my', 'little', 'toe.'], 'ar': ['لدي', 'ألم', 'في', 'اصبع', 'قدمي', 'الصغير.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'fix', 'this', 'TV?'], 'ar': ['هل', 'تعرف', 'كيف', 'تصلح', 'التلفاز؟'] },
    { 'en': ['Bring', 'him', 'in.'], 'ar': ['أدخله.'] },
    { 'en': ['She', 'looks', 'pretty', 'in', 'that', 'dress.'], 'ar': ['تبدو', 'جميلة', 'في', 'هذا', 'الرداء.'] },
    { 'en': ['At', 'the', 'time,', 'we', 'were', 'just', 'fifteen', 'years', 'old.'], 'ar': ['كان', 'عمرنا', 'حينها', 'خمس', 'عشرة', 'سنة', 'فقط.'] },
    { 'en': ['This', 'desk', 'is', 'mine.'], 'ar': ['هذا', 'المكتب', 'لي.'] },
    { 'en': ['I', 'do', 'not', 'have', 'any', 'money.'], 'ar': ['ليس', 'لدي', 'نقود'] },
    { 'en': ['Did', 'you', 'behave', 'today?'], 'ar': ['هل', 'أحسنت', 'التصرف', 'اليوم؟'] },
    { 'en': ['I', 'got', 'scolded', 'severely', 'by', 'that', 'teacher.'], 'ar': ['وبخني', 'ذلك', 'المعلم', 'كثيرًا.'] },
    { 'en': ['Doing', 'that', 'would', 'be', 'dangerous.'], 'ar': ['سيكون', 'فعل', 'ذلك', 'خطرًا.'] },
    { 'en': ['You', 'like', 'rain,', "don't", 'you?'], 'ar': ['تحب', 'المطر', '،', 'صحيح؟'] },
    { 'en': ['I', 'need', 'first', 'aid.'], 'ar': ['أحتاج', 'إلى', 'إسعافات', 'أولية.'] },
    { 'en': ['She', 'used', 'to', 'be', 'a', 'very', 'shy', 'girl.'], 'ar': ['كان', 'من', 'طبيعتها', 'أنها', 'فتاة', 'خجولة', 'جداً.'] },
    { 'en': ['She', 'is', 'happy.'], 'ar': ['هي', 'سعيدة'] },
    { 'en': ['He', 'reads', 'a', 'good', 'deal.'], 'ar': ['هو', 'قارئ', 'نهم.'] },
    { 'en': ['She', 'was', 'mad', 'at', 'her', 'son.'], 'ar': ['هىَ', 'كانت', 'غاضبة', 'علىَ', 'إبنها.'] },
    { 'en': ['I', 'would', 'like', 'to', 'get', 'a', 'camera', 'like', 'this.'], 'ar': ['لكم', 'أريد', 'الحصول', 'على', 'كاميرا', 'مثل', 'تلك!'] },
    { 'en': ['What', 'time', 'does', 'your', 'class', 'end?'], 'ar': ['في', 'أي', 'وقت', 'ينتهي', 'صفك؟'] },
    { 'en': ['I', 'can', 'play', 'the', 'piano', 'after', 'a', 'fashion.'], 'ar': ['بالكاد', 'أستطيع', 'العزف', 'على', 'البيانو.'] },
    { 'en': ['Do', 'I', 'look', 'like', "I'm", 'kidding?'], 'ar': ['هل', 'يبدو', 'لك', 'بأني', 'أمزح؟'] },
    { 'en': ["I'll", 'buy', 'him', 'a', 'pencil.'], 'ar': ['سأشتري', 'له', 'قلماً.'] },
    { 'en': ['Do', 'you', 'love', 'each', 'other', 'very', 'much?'], 'ar': ['هل', 'تحبان', 'بعضكما', 'كثيرًا؟'] },
    { 'en': ["You're", 'joking!'], 'ar': ['أنت', 'تمزح!'] },
    { 'en': ['This', 'shop', 'has', 'more', 'candy', 'than', 'that', 'shop.'], 'ar': ['في', 'هذا', 'المتجر', 'حلوى', 'أكثر', 'من', 'ذاك.'] },
    { 'en': ["I'll", 'reconsider', 'the', 'matter.'], 'ar': ['سأفكر', 'في', 'الأمر', 'مجدداً.'] },
    { 'en': ['Can', 'you', 'drive?'], 'ar': ['هل', 'تستطيع', 'أن', 'تقود', 'سيارة؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'make', 'a', 'request.'], 'ar': ['أود', 'أن', 'أقدم', 'على', 'طلب.'] },
    { 'en': ['Come', 'home.'], 'ar': ['تعال', 'إلى', 'منزلي.'] },
    { 'en': ['The', 'lion', 'is', 'the', 'king', 'of', 'the', 'jungle.'], 'ar': ['الأسد', 'ملك', 'الغابة.'] },
    { 'en': ['No', 'one', 'suspected', 'a', 'thing.'], 'ar': ['لا', 'أحد', 'شكّ', 'أنّه', 'كان', 'ثمّة', 'شيء.'] },
    { 'en': ['Only', 'one', 'person', 'survived', 'the', 'accident.'], 'ar': ['نجا', 'من', 'الحادثة', 'شخص', 'واحد.'] },
    { 'en': ['She', 'accused', 'me', 'of', 'telling', 'a', 'lie.'], 'ar': ['اتهمتني', 'بأني', 'كذبت.'] },
    { 'en': ['I', 'like', 'red', 'roses.'], 'ar': ['أحب', 'الورود', 'الحمراء.'] },
    { 'en': ['Keep', 'away', 'from', 'me.'], 'ar': ['اِبقَ', 'بعيداً', 'عني.'] },
    { 'en': ['She', 'went', 'to', 'the', 'back', 'of', 'the', 'line.'], 'ar': ['وقفت', 'في', 'آخر', 'الصف.'] },
    { 'en': ['I', 'think', "you'd", 'better', 'go', 'on', 'a', 'diet.'], 'ar': ['أظن', 'أنه', 'من', 'الأفضل', 'لك', 'أن', 'تبدأ', 'حمية', 'غذائية.'] },
    { 'en': ['They', 'lost', 'the', 'battle.'], 'ar': ['خسروا', 'الحرب.'] },
    { 'en': ['Tom', 'is', 'drinking', 'directly', 'from', 'the', 'faucet.'], 'ar': ['توم', 'يشرب', 'من', 'الصنبور', 'مباشرة'] },
    { 'en': ['When', 'did', 'you', 'find', 'out', 'the', 'truth?'], 'ar': ['متى', 'عرفت', 'الحقيقة؟'] },
    { 'en': ['Look', 'at', 'that!'], 'ar': ['أُنظر', 'إلى', 'هذا!'] },
    { 'en': ['We', 'all', 'desire', 'success.'], 'ar': ['الكل', 'يريد', 'النجاح.'] },
    { 'en': ['Tom', 'wants', 'to', 'come.'], 'ar': ['توم', 'يريد', 'ان', 'يأتي.'] },
    { 'en': ['Many', 'new', 'factories', 'have', 'opened.'], 'ar': ['فُتحت', 'الكثير', 'من', 'المصانع', 'الجديدة.'] },
    { 'en': ['Please', 'go', 'to', 'the', 'next', 'page.'], 'ar': ['من', 'فضلك', 'انتقل', 'إلى', 'الصفحة', 'التالية'] },
    { 'en': ['I', 'have', 'a', 'lot', 'of', 'passwords', 'to', 'remember.'], 'ar': ['لديَ', 'الكثير', 'من', 'كلمات', 'السر', 'لأتذكرها.'] },
    { 'en': ['I', 'hope', 'I', "don't", 'have', 'to', 'use', 'this', 'pistol.'], 'ar': ['آمُل', 'ألا', 'أضطر', 'إلى', 'استخدام', 'هذا', 'المسدس.'] },
    { 'en': ['I', "don't", 'know', 'when', "he'll", 'be', 'here.'], 'ar': ['لا', 'أعرف', 'متى', 'سيكون', 'هنا.'] },
    { 'en': ['May', 'I', 'have', 'a', 'glass', 'of', 'water?'], 'ar': ['هل', 'لي', 'بكأس', 'ماء؟'] },
    { 'en': ["That's", 'why', "we're", 'here.'], 'ar': ['لذلك', 'نحن', 'هنا'] },
    { 'en': ['He', "couldn't", 'understand', 'the', 'sentence.'], 'ar': ['لم', 'يفهم', 'معنى', 'الجملة.'] },
    { 'en': ['Do', 'you', 'love', 'each', 'other', 'that', 'much?'], 'ar': ['هل', 'تحبان', 'بعضكما', 'بعضاً', 'إلى', 'ذلك', 'الحد؟'] },
    { 'en': ['Ask', 'her', 'when', 'he', 'will', 'come', 'back.'], 'ar': ['اسألها', 'متى', 'سيعود.'] },
    { 'en': ["I've", 'made', 'my', 'decision.'], 'ar': ['أنا', 'صنعت', 'قراري.'] },
    { 'en': ['You', 'look', 'happy', 'today.'], 'ar': ['تبدو', 'سعيداً', 'هذا', 'اليوم.'] },
    { 'en': ['She', 'thought', 'that', 'I', 'was', 'a', 'doctor.'], 'ar': ['اعتقدت', 'أنني', 'طبيب.'] },
    { 'en': ['The', 'climate', 'here', 'is', 'mild.'], 'ar': ['الجو', 'معتدل', 'هنا.'] },
    { 'en': ['Can', 'you', 'suggest', 'a', 'better', 'idea?'], 'ar': ['أيمكنك', 'أن', 'تقترح', 'فكرة', 'أفضل؟'] },
    { 'en': ['Did', 'you', 'play', 'tennis', 'yesterday?'], 'ar': ['هل', 'لعبت', 'التنس', 'البارحة؟'] },
    { 'en': ['I', 'make', '€100', 'a', 'day.'], 'ar': ['أجني', 'مئة', 'يورو', 'في', 'اليوم.'] },
    { 'en': ['Which', 'bed', 'do', 'you', 'want', 'to', 'use?'], 'ar': ['على', 'أي', 'سرير', 'تريد', 'أن', 'تنام؟'] },
    { 'en': ['Thank', 'you', 'for', 'the', 'information.'], 'ar': ['شكراً', 'لكِ', 'على', 'المعلومة.'] },
    { 'en': ["I'll", 'make', 'you', 'happy.'], 'ar': ['سأجعلك', 'سعيدة/سأسعدك'] },
    { 'en': ["Where're", 'you', 'going?'], 'ar': ['إلى', 'أين', 'تذهب'] },
    { 'en': ['Good', 'evening.'], 'ar': ['مساء', 'الخير.'] },
    { 'en': ['Cobras', 'are', 'always', 'very', 'alert.'], 'ar': ['أفاعي', 'الكوبرا', 'تكون', 'دائمًا', 'متيقظة', 'إلى', 'أبعد', 'الحدود.'] },
    { 'en': ['Her', 'dream', 'is', 'to', 'become', 'a', 'nurse.'], 'ar': ['حلمها', 'هو', 'أن', 'تصبح', 'ممرضة.'] },
    { 'en': ['Do', 'you', 'really', 'want', 'to', 'talk', 'about', 'that', 'now?'], 'ar': ['هل', 'أنتِ', 'تريد', 'حقاً', 'التحدث', 'بشأن', 'هذا', 'الأن؟'] },
    { 'en': ['She', 'traveled', 'all', 'over', 'the', 'world.'], 'ar': ['سافرت', 'حول', 'العالم.'] },
    { 'en': ['Do', 'you', 'remember', 'his', 'telephone', 'number?'], 'ar': ['هل', 'تذكر', 'رقم', 'هاتفه؟'] },
    { 'en': ['The', 'rain', 'lasted', 'five', 'days.'], 'ar': ['استمر', 'المطر', 'خمسة', 'أيام.'] },
    { 'en': ['I', 'want', 'to', 'ask', 'you', 'one', 'last', 'favor.'], 'ar': ['أريد', 'أن', 'أطلب', 'منك', 'خدمة', 'أخيرة'] },
    { 'en': ['Give', 'this', 'book', 'to', 'Tom.'], 'ar': ['أعط', 'هذا', 'الكتاب', 'لتوم'] },
    { 'en': ['Stay', 'where', 'you', 'are.'], 'ar': ['ابق', 'مكانك.'] },
    { 'en': ["I've", 'waited', 'for', 'three', 'days.'], 'ar': ['انتظرت', 'لثلاثة', 'أيام.'] },
    { 'en': ['It', 'was', 'such', 'a', 'shock.'], 'ar': ['كانت', 'صدمة', 'قوية.'] },
    { 'en': ["I'm", 'sure', 'Tom', 'will', 'be', 'here', 'soon.'], 'ar': ['أنا', 'متأكد', 'أن', 'توم', 'سيكون', 'هنا', 'قريباً.'] },
    { 'en': ['I', 'believe', 'he', 'is', 'going', 'to', 'be', 'rich', 'one', 'day.'], 'ar': ['أعتقد', 'أنه', 'سيصبح', 'غنياً', 'يوماً', 'ما.'] },
    { 'en': ["You'll", 'be', 'busy', 'tomorrow,', "won't", 'you?'], 'ar': ['ستكون', 'مشغولاً', 'غداً،', 'أليس', 'ذلك', 'صحيحاً؟'] },
    { 'en': ['How', 'are', 'you?'], 'ar': ['كيفَ', 'حالِك؟'] },
    { 'en': ['My', 'father', 'has', 'gone', 'to', 'China.'], 'ar': ['لقد', 'ذهب', 'أبي', 'إلى', 'الصين.'] },
    { 'en': ['He', 'is', 'busy', 'doing', 'something.'], 'ar': ['إنه', 'مشغول', 'بشيء', 'ما.'] },
    { 'en': ['This', 'is', 'the', 'only', 'alternative.'], 'ar': ['هذا', 'هو', 'الخيار', 'الوحيد.'] },
    { 'en': ["I'm", 'really', 'hungry.'], 'ar': ['أنا', 'جائع', 'جداً.'] },
    { 'en': ['The', 'accused', 'is', 'innocent', 'until', 'proven', 'guilty.'], 'ar': ['المتهم', 'بريء', 'حتى', 'تثبت', 'إدانته.'] },
    { 'en': ['Can', 'I', 'see', 'this', 'one?'], 'ar': ['أيمكنك', 'أن', 'تُرِيَني', 'ذلك؟'] },
    { 'en': ['I', 'must', 'help', 'him.'], 'ar': ['عليّ', 'أن', 'أساعده.'] },
    { 'en': ['The', "judge's", 'decision', 'is', 'final.'], 'ar': ['قرار', 'القاضي', 'قرارٌ', 'فاصل.'] },
    { 'en': ['The', 'noise', 'was', 'quite', 'loud.'], 'ar': ['كانت', 'الضّجّة', 'قويّة', 'جدّا.'] },
    { 'en': ['Lincoln', 'died', 'in', '1865.'], 'ar': ['مات', 'لنكولن', 'في', 'عام', '١٨٦٥', 'للميلاد.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'doing', 'this', 'again.'], 'ar': ['أنا', 'أتتطلع', 'لأفعل', 'هذا', 'مجدداً.'] },
    { 'en': ['I', "don't", 'use', 'Facebook.'], 'ar': ['أنا', 'لا', 'أستخدم', 'الفيسبوك'] },
    { 'en': ['You', 'should', 'have', 'told', 'it', 'to', 'me', 'sooner.'], 'ar': ['كان', 'عليك', 'أن', 'تخبرني', 'أبكر.'] },
    { 'en': ['Where', 'can', 'I', 'find', 'a', 'taxi?'], 'ar': ['أين', 'يمكنني', 'أن', 'أجد', 'سيارة', 'أجرة؟'] },
    { 'en': ["Don't", 'put', 'it', 'on', 'my', 'desk.'], 'ar': ['ليس', 'على', 'مكتبي.'] },
    { 'en': ['You', 'should', 'begin', 'right', 'away.'], 'ar': ['عليك', 'البدء', 'في', 'الحال.'] },
    { 'en': ['These', 'dogs', 'are', 'big.'], 'ar': ['هذه', 'الكلاب', 'كبيرة.'] },
    { 'en': ['Am', 'I', 'wrong?'], 'ar': ['هل', 'أنا', 'مخطئ؟'] },
    { 'en': ['I', "can't", 'live', 'without', 'you.'], 'ar': ['لا', 'أستطيع', 'العيش', 'من', 'دونك.'] },
    { 'en': ["I'll", 'be', 'here', 'all', 'week.'], 'ar': ['سأكون', 'هنا', 'طوال', 'الأسبوع.'] },
    { 'en': ['I', 'like', 'the', 'way', 'you', 'treat', 'me.'], 'ar': ['أحب', 'الطريقة', 'التي', 'تعامليني', 'بها.'] },
    { 'en': ['Above', 'all,', 'be', 'patient.'], 'ar': ['أهم', 'شيء', 'أن', 'تكن', 'صبوراً.'] },
    { 'en': ['Who', 'is', 'the', 'woman', 'dressed', 'in', 'pink?'], 'ar': ['من', 'تلك', 'المرأة', 'التي', 'ترتدي', 'فستاناً', 'وردياً؟'] },
    { 'en': ['I', "didn't", 'steal', 'the', 'money.'], 'ar': ['لم', 'أسرق', 'المال.'] },
    { 'en': ["I'd", 'rather', 'die', 'than', 'do', 'that.'], 'ar': ['أفضّل', 'الموت', 'على', 'ذلك.'] },
    { 'en': ['I', 'thought', 'you', 'had', 'higher', 'standards.'], 'ar': ['ظننتك', 'تملك', 'معايير', 'أسمى.'] },
    { 'en': ['Tom', 'is', 'waiting', 'for', 'a', 'reply.'], 'ar': ['ينتظر', 'توم', 'ردًّا.'] },
    { 'en': ['I', "didn't", 'know', 'where', 'it', 'came', 'from.'], 'ar': ['ليس', 'لدي', 'علم', 'من', 'أين', 'جاءت.'] },
    { 'en': ['I', 'think', 'he', "won't", 'come.'], 'ar': ['لا', 'أظن', 'أنه', 'قادم.'] },
    { 'en': ['Who', 'wrote', 'this', 'poem?'], 'ar': ['من', 'كتب', 'هذه', 'القصيدة؟'] },
    { 'en': ['I', 'came', 'here', 'hoping', 'to', 'see', 'Tom.'], 'ar': ['أتيت', 'هنا', 'آملا', 'رؤية', 'توم.'] },
    { 'en': ['Do', 'you', 'speak', 'English?'], 'ar': ['هل', 'تتكلم', 'اللغة', 'الإنجليزية؟'] },
    { 'en': ['How', 'did', 'you', 'know', 'that?'], 'ar': ['أنتِ', 'كيف', 'عرفتِ', 'ذلك؟'] },
    { 'en': ['Where', 'is', 'my', 'book?'], 'ar': ['أين', 'كتابي؟'] },
    { 'en': ["They're", 'ignoring', 'me.'], 'ar': ['إنهم', 'يتجاهلونني.'] },
    { 'en': ["We've", 'arrived.'], 'ar': ['لقد', 'وصلنا.'] },
    { 'en': ['You', 'must', 'help', 'her.'], 'ar': ['عليك', 'أن', 'تساعدها.'] },
    { 'en': ['She', 'was', 'on', 'the', 'verge', 'of', 'crying.'], 'ar': ['أوشكت', 'على', 'البكاء.'] },
    { 'en': ['Take', 'this', 'or', 'that.'], 'ar': ['خذ', 'هذا', 'أو', 'ذاك.'] },
    { 'en': ['I', 'have', 'no', 'words', 'to', 'express', 'my', 'gratitude.'], 'ar': ['الكلمات', 'لا', 'يمكنها', 'أن', 'تعبر', 'عن', 'شكري', 'لك.'] },
    { 'en': ['I', 'suppose', "you're", 'hungry.'], 'ar': ['أعتقد', 'أنك', 'جوعان.'] },
    { 'en': ["It's", 'a', 'system', 'that', 'works.'], 'ar': ['إنه', 'نظامٌ', 'يعمل.'] },
    { 'en': ['I', 'am', 'coming.'], 'ar': ['أنا', 'آتٍ.'] },
    { 'en': ['I', 'built', 'an', 'amplifier', 'yesterday.'], 'ar': ['صنعتُ', 'مُضَخِّمًا', 'أمس.'] },
    { 'en': ['I', 'was', 'named', 'after', 'my', 'uncle.'], 'ar': ['سُميّتُ', 'على', 'خالي.'] },
    { 'en': ['Are', 'you', 'all', 'ready?'], 'ar': ['هل', 'كلكم', 'جاهزون؟'] },
    { 'en': ['I', 'think', 'I', 'can', 'run', 'as', 'fast', 'as', 'Tom.'], 'ar': ['أظن', 'أنه', 'يمكنني', 'الركض', 'بمثل', 'سرعة', 'توم.'] },
    { 'en': ['Not', 'all', 'Muslims', 'are', 'Arabs.'], 'ar': ['ليس', 'كلّ', 'المسلمين', 'عرب.'] },
    { 'en': ['Birds', 'fly', 'long', 'distances.'], 'ar': ['تقطع', 'الطيور', 'مسافات', 'طويلة.'] },
    { 'en': ['You', 'disappointed', 'me.'], 'ar': ['خيبت', 'أملي', 'فيك.'] },
    { 'en': ['That', 'old', 'woman', 'lives', 'by', 'herself.'], 'ar': ['تلك', 'المرأة', 'العجوز', 'تعيش', 'لوحدها.'] },
    { 'en': ['What', 'are', 'you', 'going', 'to', 'do?'], 'ar': ['ما', 'الذي', 'ستفعله؟'] },
    { 'en': ['Go', 'and', 'speak', 'to', 'my', 'colleague.'], 'ar': ['اذهب', 'وتحدّث', 'إلى', 'زملائي.'] },
    { 'en': ['She', 'bought', 'eggs', 'by', 'the', 'dozen.'], 'ar': ['اشترت', 'البيض', 'بالدرزن.'] },
    { 'en': ['I', 'have', 'a', 'question.'], 'ar': ['لدي', 'سؤال.'] },
    { 'en': ['You', 'can', 'drive', 'a', 'car,', "can't", 'you?'], 'ar': ['تستطيع', 'قيادة', 'سيارة،', 'صحيح؟'] },
    { 'en': ['The', 'door', 'is', 'open.'], 'ar': ['الباب', 'مفتوح.'] },
    { 'en': ["I'm", 'not', 'even', 'sure', 'if', 'this', 'is', 'my', 'key.'], 'ar': ['أنا', 'حتىَ', 'لستُ', 'متأكداً', 'إن', 'كان', 'هذا', 'مفتاحي.'] },
    { 'en': ['I', 'read', 'your', 'book.'], 'ar': ['قرأت', 'كتابك.'] },
    { 'en': ["I'll", 'be', 'watching', 'you.'], 'ar': ['سأراقبك.'] },
    { 'en': ['Could', 'you', 'tell', 'me', 'how', 'to', 'get', 'to', 'the', 'station?'], 'ar': ['هل', 'يمكنك', 'أن', 'تخبرني', 'كيف', 'أصل', 'إلى', 'المحطة؟'] },
    { 'en': ['What', 'did', 'you', 'do', 'yesterday', 'evening?'], 'ar': ['ماذا', 'فعلت', 'ليلة', 'الأمس؟'] },
    { 'en': ['Tom', 'is', 'very', 'interested', 'in', 'jazz.'], 'ar': ['توم', 'مهتم', 'جداً', 'في', 'موسيقى', 'الجاز.'] },
    { 'en': ['A', 'child', 'needs', 'love.'], 'ar': ['الطفل', 'يحتاج', 'الى', 'الحنان.'] },
    { 'en': ['That', 'meat', 'is', 'chicken.'], 'ar': ['ذلك', 'اللحم', 'لحم', 'دجاج.'] },
    { 'en': ['Above', 'all,', "don't", 'forget', 'to', 'write', 'me.'], 'ar': ['والأهم،', 'لا', 'تنسى', 'أن', 'تكتب', 'لي.'] },
    { 'en': ['Did', 'you', 'break', 'something?'], 'ar': ['هل', 'خرّبت', 'شيئًا؟'] },
    { 'en': ['If', 'I', 'get', 'rich,', 'I', 'will', 'buy', 'it.'], 'ar': ['أنا', 'لو', 'أصبحت', 'غنياً,', 'سوف', 'أشتريه.'] },
    { 'en': ['I', 'want', 'a', 'beer.'], 'ar': ['أنا', 'أُريد', 'بيرة.'] },
    { 'en': ['He', 'came.'], 'ar': ['لقد', 'أتى.'] },
    { 'en': ['Who', 'are', 'you', 'talking', 'about?'], 'ar': ['عمّن', 'تتكلم؟'] },
    { 'en': ['These', 'books', 'are', 'new.'], 'ar': ['هذه', 'الكتب', 'جديدة'] },
    { 'en': ['You', 'should', 'live', 'up', 'to', 'your', 'principles.'], 'ar': ['ينبغي', 'أن', 'تتقيد', 'بمبادئك.'] },
    { 'en': ['Do', 'you', 'love', 'me?'], 'ar': ['أتحبني؟'] },
    { 'en': ['Did', 'you', 'find', 'your', 'passport?'], 'ar': ['أوجدت', 'جوازك؟'] },
    { 'en': ['My', 'parents', 'live', 'in', 'the', 'country.'], 'ar': ['يعيش', 'أبويّ', 'في', 'القرية.'] },
    { 'en': ['This', 'is', 'not', 'a', 'sentence.'], 'ar': ['هذه', 'ليست', 'جملةً.'] },
    { 'en': ['Do', 'you', 'speak', 'Spanish?'], 'ar': ['هل', 'تتحدث', 'الإسبانية؟'] },
    { 'en': ["I'm", 'in', 'love', 'with', 'you.'], 'ar': ['أنا', 'واقع', 'في', 'حبك'] },
    { 'en': ["I've", 'never', 'climbed', 'Mt.', 'Fuji.'], 'ar': ['لم', 'أتسلق', 'جبل', 'فوجي', 'أبداً.'] },
    { 'en': ['Life', 'is', 'sweet.'], 'ar': ['الحياة', 'حلوة.'] },
    { 'en': ['Tom', 'entered', 'the', 'building.'], 'ar': ['دخل', 'توم', 'المبنى.'] },
    { 'en': ['She', 'came', 'to', 'Tokyo', 'when', 'she', 'was', '18.'], 'ar': ['أتت', 'إلى', 'طوكيو', 'عندما', 'كان', 'عمرها', 'ثمانية', 'عشر', 'عاماً.'] },
    { 'en': ['Did', 'Spot', 'go,', 'too?'], 'ar': ['هل', 'ذهب', 'سبوت', 'أيضاً؟'] },
    { 'en': ['What', 'browser', 'are', 'you', 'using?'], 'ar': ['أي', 'متصفح', 'إنترنت', 'تستخدم؟'] },
    { 'en': ["Don't", 'worry.'], 'ar': ['لا', 'عليك.'] },
    { 'en': ["He's", 'a', 'cat', 'lover.'], 'ar': ['إنه', 'من', 'محبي', 'القطط.'] },
    { 'en': ['My', 'room', 'key,', 'please.'], 'ar': ['أعطني', 'مفتاح', 'غرفتي', 'من', 'فضلك.'] },
    { 'en': ['Tom', "doesn't", 'know.'], 'ar': ['توم', 'لا', 'يعلم'] },
    { 'en': ['War', 'broke', 'out', 'in', '1939.'], 'ar': ['اِندلعت', 'الحرب', 'عام', '١٩٣٩', 'للميلاد.'] },
    { 'en': ["I'm", 'thinking', 'about', 'you.'], 'ar': ['أفكر', 'فيك.'] },
    { 'en': ['He', 'always', 'tells', 'the', 'truth.'], 'ar': ['يصدقنا', 'القول', 'دوماً.'] },
    { 'en': ['A', 'dog', 'is', 'a', "man's", 'best', 'friend.'], 'ar': ['الكلب', 'صديق', 'الإنسان', 'الوفي.'] },
    { 'en': ["Let's", 'make', 'a', 'trade.'], 'ar': ['دعينا', 'نصنع', 'تجارة.'] },
    { 'en': ['He', 'did', 'what', 'he', 'promised', 'to', 'do.'], 'ar': ['لقد', 'فعل', 'ما', 'وعد', 'ان', 'يقوم', 'به'] },
    { 'en': ["I'm", 'very', 'proud', 'of', 'my', 'father.'], 'ar': ['أنا', 'شديدة', 'الفخر', 'بأبي.'] },
    { 'en': ['Are', 'you', 'a', 'senior', 'high', 'school', 'student?'], 'ar': ['هل', 'أنت', 'طالب', 'في', 'الثانوية؟'] },
    { 'en': ["It's", 'a', 'piece', 'of', 'cake.'], 'ar': ['إنه', 'أمر', 'سهل.'] },
    { 'en': ['He', 'must', 'be', 'over', 'sixty.'], 'ar': ['لا', 'شكّ', 'أن', 'عمره', 'يتجاوز', 'الستين.'] },
    { 'en': ['He', 'is', 'anxious', 'to', 'go', 'with', 'you.'], 'ar': ['هو', 'متشوق', 'للذهاب', 'معك.'] },
    { 'en': ['Is', 'this', 'love?'], 'ar': ['هل', 'هذا', 'هو', 'الحب؟'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'the', 'mosque.'], 'ar': ['عليّ', 'أن', 'أذهب', 'إلى', 'المسجد.'] },
    { 'en': ['Tom', "doesn't", 'like', 'talking', 'about', 'himself.'], 'ar': ['لا', 'يحب', 'توم', 'التحدث', 'عن', 'نفسه.'] },
    { 'en': ['When', 'he', 'was', 'young,', 'he', 'was', 'a', 'hard', 'worker.'], 'ar': ['عندما', 'كان', 'شاباً،', 'كان', 'عاملاً', 'مثابراً.'] },
    { 'en': ['These', 'pearls', 'look', 'real.'], 'ar': ['تبدو', 'تلك', 'اللآلئ', 'حقيقية.'] },
    { 'en': ['I', 'want', 'to', 'be', 'a', 'teacher.'], 'ar': ['أريد', 'أن', 'أصبح', 'معلما.'] },
    { 'en': ['I', 'owe', 'my', 'success', 'to', 'their', 'help.'], 'ar': ['أنا', 'مدينٌ', 'بنجاحي', 'لهم', 'لمساعدتهم', 'لي.'] },
    { 'en': ['What', 'do', 'you', 'advise', 'me', 'to', 'do?'], 'ar': ['ماذا', 'تنصحني', 'أن', 'أفعل؟'] },
    { 'en': ['When', 'is', 'the', 'party?'], 'ar': ['متى', 'الحفلة؟'] },
    { 'en': ["I've", 'never', 'eaten', 'Chinese', 'food.'], 'ar': ['لم', 'آكل', 'طعاما', 'صينيا', 'قط.'] },
    { 'en': ['The', 'guard', 'was', 'on', 'duty', 'all', 'night.'], 'ar': ['كان', 'الحارس', 'على', 'رأس', 'مهمته', 'طوال', 'الليل.'] },
    { 'en': ['Are', 'you', 'asking', 'me', 'to', 'stay?'], 'ar': ['هل', 'تطلب', 'مني', 'البقاء؟'] },
    { 'en': ['These', 'three', 'countries', 'were', 'united', 'into', 'one.'], 'ar': ['إتحدت', 'الدول', 'الثلاث', 'ليكوّنوا', 'دولة', 'واحدة.'] },
    { 'en': ["I'll", 'scold', 'him.'], 'ar': ['سأوبخه.'] },
    { 'en': ['She', 'has', 'made', 'the', 'same', 'mistake', 'as', 'last', 'time.'], 'ar': ['ارتكبت', 'نفس', 'الخطأ', 'مرّة', 'أخرى.'] },
    { 'en': ['What', 'is', 'the', 'matter', 'with', 'your', 'car?'], 'ar': ['ما', 'مشكلة', 'سيارتك؟'] },
    { 'en': ['I', 'knew', "you'd", 'come.'], 'ar': ['عرفت', 'أنك', 'ستأتي.'] },
    { 'en': ["It's", 'very', 'cold', 'this', 'winter.'], 'ar': ['الجوّ', 'بارد', 'جداً', 'هذا', 'الشتاء.'] },
    { 'en': ['Everybody', 'is', 'immune', 'to', 'smallpox', 'nowadays.'], 'ar': ['عند', 'كل', 'الناس', 'مناعةٌ', 'من', 'الجدري', 'اليوم.'] },
    { 'en': ["It's", 'my', 'job.'], 'ar': ['إن', 'ذلك', 'عملي.'] },
    { 'en': ['He', 'fell', 'in', 'love', 'with', 'her', 'at', 'first', 'sight.'], 'ar': ['وقع', 'في', 'حبها', 'من', 'النظرة', 'الأولى.'] },
    { 'en': ['I', 'have', 'to', 'stay', 'in', 'bed', 'all', 'day.'], 'ar': ['علي', 'أن', 'أبقى', 'في', 'السرير', 'طوال', 'اليوم.'] },
    { 'en': ['Japanese', 'cars', 'sell', 'well', 'overseas.'], 'ar': ['مبيعات', 'السيارات', 'اليابانية', 'في', 'الخارج', 'جيدة.'] },
    { 'en': ['My', 'house', 'is', 'big.'], 'ar': ['منزلي', 'كبير.'] },
    { 'en': ["I've", 'heard', 'nothing.'], 'ar': ['لم', 'أسمع', 'شيئاً.'] },
    { 'en': ['Quite', 'a', 'few', 'people', 'have', 'two', 'cars.'], 'ar': ['عدد', 'لا', 'يستهان', 'به', 'من', 'الناس', 'يملكون', 'سيارتين.'] },
    { 'en': ['Was', 'Tom', 'a', 'sailor?'], 'ar': ['هل', 'كان', 'توم', 'بحاراً؟'] },
    { 'en': ['Please', 'phone', 'me', 'before', 'you', 'come.'], 'ar': ['من', 'فضلك', 'اتصل', 'بي', 'قبل', 'أن', 'تأتي.'] },
    { 'en': ['I', 'cannot', 'give', 'you', 'a', 'definite', 'answer', 'today.'], 'ar': ['ليس', 'بإمكاني', 'إعطائك', 'جواباً', 'قاطعاً', 'اليوم.'] },
    { 'en': ['Few', 'people', 'know', 'about', 'the', 'plan.'], 'ar': ['القليل', 'يعرف', 'أي', 'شيء', 'عن', 'الخطة.'] },
    { 'en': ['My', 'hands', 'and', 'legs', 'are', 'swollen.'], 'ar': ['تورمت', 'قدميّ', 'و', 'يديّ.'] },
    { 'en': ['He', 'lent', 'me', 'two', 'books.'], 'ar': ['أعارني', 'كتابَيْن.'] },
    { 'en': ["I'll", 'get', 'the', 'food.'], 'ar': ['سوف', 'أُحضرُ', 'الطعام.'] },
    { 'en': ['Talk', 'to', 'me!'], 'ar': ['كلمني!'] },
    { 'en': ['It', 'is', 'difficult', 'to', 'give', 'up', 'smoking.'], 'ar': ['الإقلاع', 'عن', 'التدخين', 'صعب.'] },
    { 'en': ["Let's", 'flip', 'a', 'coin.'], 'ar': ['دعونا', 'نعكس', 'العملة.'] },
    { 'en': ['Most', 'people', 'think', "I'm", 'crazy.'], 'ar': ['معظم', 'الناس', 'يظنون', 'أني', 'مجنون.'] },
    { 'en': ['She', 'translated', 'it', 'word', 'for', 'word.'], 'ar': ['ترجمت', 'النص', 'حرفياً.'] },
    { 'en': ['My', 'wife', 'is', 'a', 'doctor.'], 'ar': ['زوجتي', 'طبيبة.'] },
    { 'en': ["What's", 'the', 'secret?'], 'ar': ['ما', 'السر؟'] },
    { 'en': ['This', 'is', 'what', 'we', 'want', 'to', 'know.'], 'ar': ['هذا', 'ما', 'نريد', 'أن', 'نعرفه.'] },
    { 'en': ['The', 'servant', 'swept', 'the', 'floor.'], 'ar': ['كنس', 'الخادم', 'الأرض.'] },
    { 'en': ['Tom', "didn't", 'show', 'up', 'at', 'the', 'party', 'last', 'night.'], 'ar': ['لم', 'يأتي', 'توم', 'إلى', 'الحفلة', 'الليلة', 'الماضية.'] },
    { 'en': ['That', 'was', 'the', 'best', 'day', 'of', 'my', 'life.'], 'ar': ['كان', 'ذلك', 'أفضل', 'يوم', 'من', 'أيام', 'عمري.'] },
    { 'en': ['The', 'boy', 'remained', 'silent.'], 'ar': ['بقِيَ', 'الولد', 'هادئاً.'] },
    { 'en': ['You', "don't", 'have', 'to', 'come', 'tomorrow.'], 'ar': ['ليس', 'عليك', 'أن', 'تأتي', 'غدًا.'] },
    { 'en': ['My', 'dream', 'is', 'to', 'become', 'a', 'teacher.'], 'ar': ['حلمي', 'أن', 'أصبح', 'معلّماً.'] },
    { 'en': ['He', 'worked', 'all', 'day', 'yesterday.'], 'ar': ['عمل', 'طوال', 'الأمس.'] },
    { 'en': ['I', "can't", 'come', 'up', 'with', 'a', 'plan', 'right', 'now.'], 'ar': ['لا', 'أستطيع', 'ابتكار', 'خطة', 'الآن.'] },
    { 'en': ['It', 'turned', 'out', 'that', 'I', 'was', 'right.'], 'ar': ['تبيّن', 'في', 'النهاية', 'أنّني', 'كنت', 'على', 'حق.'] },
    { 'en': ['Take', 'off', 'your', 'shoes.'], 'ar': ['اخلع', 'حذاءك.'] },
    { 'en': ['I', 'like', 'tennis', 'very', 'much.'], 'ar': ['أحب', 'النتس', 'جدا.'] },
    { 'en': ['You', 'should', 'exercise.'], 'ar': ['يجب', 'عليك', 'ممارسة', 'الرياضة.'] },
    { 'en': ['He', 'disappeared', 'without', 'a', 'trace.'], 'ar': ['اختفى', 'بلا', 'أثر.'] },
    { 'en': ['When', 'did', 'you', 'see', 'her?'], 'ar': ['متى', 'قابلتها؟'] },
    { 'en': ['I', 'began', 'to', 'cry.'], 'ar': ['أخذت', 'تبكي.'] },
    { 'en': ['Is', 'Tom', 'happy?'], 'ar': ['هل', 'توم', 'سعيد؟'] },
    { 'en': ['Your', 'problem', 'is', 'similar', 'to', 'mine.'], 'ar': ['مشكلتك', 'شبيهة', 'بمشكلتي.'] },
    { 'en': ['I', 'left', 'you', 'a', 'note.'], 'ar': ['تركت', 'لك', 'ملاحظة.'] },
    { 'en': ['There', 'are', 'a', 'lot', 'of', 'books', 'in', 'his', 'room.'], 'ar': ['ثمة', 'كتب', 'كثيرة', 'في', 'غرفته.'] },
    { 'en': ['I', 'know', "I'm", 'wrong.'], 'ar': ['أعلم', 'بأني', 'مخطئ.'] },
    { 'en': ['Both', 'of', 'them', 'are', 'in', 'the', 'room.'], 'ar': ['كلاهما', 'في', 'الغرفة.'] },
    { 'en': ['Tom', 'watched', 'TV', 'yesterday.'], 'ar': ['شاهد', 'توم', 'التلفاز', 'بالأمس.'] },
    { 'en': ['Were', 'you', 'tired', 'last', 'night?'], 'ar': ['هل', 'كنت', 'متعباً', 'ليلة', 'البارحة؟'] },
    { 'en': ['Birds', 'lay', 'eggs.'], 'ar': ['تضع', 'الطيور', 'البيض.'] },
    { 'en': ['Do', 'you', 'have', 'the', 'key', 'to', 'this', 'door?'], 'ar': ['ألديك', 'مفتاح', 'هذا', 'الباب؟'] },
    { 'en': ['May', 'I', 'share', 'this', 'table', 'with', 'you?'], 'ar': ['أتسمح', 'لي', 'أن', 'أشاركك', 'الجلوس', 'على', 'الطاولة؟'] },
    { 'en': ['Who', 'wrote', 'this', 'book?'], 'ar': ['من', 'كتب', 'هذا', 'الكتاب؟'] },
    { 'en': ['We', 'must', 'go', 'there', 'whether', 'we', 'like', 'it', 'or', 'not.'], 'ar': ['يجب', 'أن', 'نذهب', 'إلى', 'هناك', 'سواء', 'أردنا', 'ذلك', 'أم', 'لا.'] },
    { 'en': ["Don't", 'mention', 'this', 'to', 'anyone.'], 'ar': ['لا', 'تذكر', 'هذا', 'لأحد.'] },
    { 'en': ['Why', 'are', 'you', 'acting', 'this', 'way?'], 'ar': ['لما', 'تتصرف', 'هكذا؟'] },
    { 'en': ['The', 'faucet', 'is', 'leaking.'], 'ar': ['الصنبور', 'يسرب', '.'] },
    { 'en': ['My', 'grandfather', 'gets', 'up', 'early.'], 'ar': ['يستيقظ', 'جدي', 'باكراً.'] },
    { 'en': ['He', 'is', 'always', 'laughing.'], 'ar': ['إنه', 'دائم', 'الضحك.'] },
    { 'en': ['You', 'look', 'very', 'pale.'], 'ar': ['تبدو', 'شاحباً', 'للغاية.'] },
    { 'en': ['You', 'work', 'too', 'hard.'], 'ar': ['أنت', 'تجهد', 'نفسك', 'في', 'عملك.'] },
    { 'en': ['Where', 'does', 'Tom', 'live?'], 'ar': ['أين', 'يسكن', 'توم؟'] },
    { 'en': ['Show', 'me', 'another', 'example.'], 'ar': ['أرني', 'مثالاً', 'آخر.'] },
    { 'en': ['You', 'should', 'get', 'your', 'car', 'fixed.'], 'ar': ['ينبغي', 'أن', 'تصلح', 'سيارتك.'] },
    { 'en': ['I', 'need', 'your', 'help.'], 'ar': ['أحتاج', 'مساعدتك.'] },
    { 'en': ['I', 'want', 'your', 'opinion.'], 'ar': ['أريد', 'رأيك.'] },
    { 'en': ['He', 'has', 'a', 'lot', 'of', 'original', 'ideas.'], 'ar': ['عنده', 'كثيرٌ', 'من', 'الأفكار', 'الإبداعية.'] },
    { 'en': ['I', 'have', 'a', 'friend', 'in', 'England.'], 'ar': ['أنا', 'لدي', 'صديقة', 'في', 'إنجلترا.'] },
    { 'en': ['His', 'victory', 'made', 'him', 'a', 'hero.'], 'ar': ['فوزه', 'جعله', 'بطلاً.'] },
    { 'en': ['My', 'friend', "doesn't", 'play', 'tennis.'], 'ar': ['صديقي', 'لا', 'يلعب', 'التنس.'] },
    { 'en': ["I'm", 'writing', 'a', 'letter.'], 'ar': ['أكتب', 'رسالة.'] },
    { 'en': ['How', 'much', 'is', 'that', 'mountain', 'bike?'], 'ar': ['بكم', 'هذه', 'الدراجة', 'الجبلية؟'] },
    { 'en': ["You'd", 'better', 'start', 'now.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تبدأ', 'الآن.'] },
    { 'en': ['She', 'put', 'the', 'magazine', 'on', 'the', 'table.'], 'ar': ['هىَ', 'وضعت', 'المجلة', 'علىَ', 'الطاولة.'] },
    { 'en': ['She', 'turned', 'down', 'his', 'proposal.'], 'ar': ['رفضت', 'عرضه.'] },
    { 'en': ["I'm", 'coming.'], 'ar': ['سآتي.'] },
    { 'en': ["Aren't", 'you', 'happy?'], 'ar': ['ألست', 'سعيداً؟'] },
    { 'en': ['Do', 'you', 'have', 'money?'], 'ar': ['هل', 'لديك', 'نقود؟'] },
    { 'en': ['Do', 'you', 'really', 'want', 'me', 'to', 'go', 'there', 'right', 'now?'], 'ar': ['هل', 'تريديني', 'حقاً', 'أن', 'أذهب', 'إلى', 'هناك', 'الأن؟'] },
    { 'en': ['The', 'park', 'was', 'full', 'of', 'people.'], 'ar': ['كان', 'المنتزه', 'مليئاً', 'بالناس.'] },
    { 'en': ['I', 'love', 'her.'], 'ar': ['أنا', 'أحبها'] },
    { 'en': ["I've", 'been', 'a', 'teacher', 'for', 'two', 'years.'], 'ar': ['لقد', 'كنتُ', 'معلماً', 'لعامين.'] },
    { 'en': ['Whenever', 'I', 'see', 'you,', 'I', 'feel', 'happy.'], 'ar': ['أشعر', 'بالسعادة', 'كلما', 'أراك.'] },
    { 'en': ['I', 'thought', "you'd", 'found', 'someone', 'else.'], 'ar': ['ظننتك', 'عثرت', 'على', 'شخص', 'آخر.'] },
    { 'en': ['It', 'was', 'really', 'quite', 'fast.'], 'ar': ['لقد', 'كان', 'حقاً', 'سريعاً', 'جداً.'] },
    { 'en': ['I', 'have', 'an', 'appointment.'], 'ar': ['لدي', 'موعد.'] },
    { 'en': ['You', 'can', 'always', 'sleep', 'on', 'my', 'couch.'], 'ar': ['أنتِ', 'يمكنكِ', 'دائماً', 'النوم', 'على', 'أريكتي.'] },
    { 'en': ['I', 'have', 'a', 'good', 'job', 'now.'], 'ar': ['لدي', 'وظيفة', 'جيدة', 'الان.'] },
    { 'en': ['We', 'just', 'need', 'a', 'little', 'time.'], 'ar': ['نحتاج', 'فقط', 'قليلا', 'من', 'الوقت.'] },
    { 'en': ['There', 'is', 'one', 'apple', 'on', 'the', 'desk.'], 'ar': ['هناك', 'تفاحة', 'واحدة', 'على', 'المكتب.'] },
    { 'en': ['Tom', 'often', 'goes', 'to', 'Boston.'], 'ar': ['يذهب', 'توم', 'إلى', 'بوسطن', 'كثيرًا.'] },
    { 'en': ['We', 'sat', 'in', 'the', 'center', 'of', 'the', 'room.'], 'ar': ['قعدنا', 'في', 'وسط', 'الحجرة.'] },
    { 'en': ["Isn't", 'she', 'a', 'doctor?'], 'ar': ['أليست', 'طبيبة؟'] },
    { 'en': ["I'm", 'not', 'in', 'the', 'best', 'of', 'shape.'], 'ar': ['لست', 'في', 'أفضل', 'حال.'] },
    { 'en': ['I', "wouldn't", 'do', 'it', 'if', 'I', 'were', 'you.'], 'ar': ['ما', 'كنتُ', 'لأفعله', 'لو', 'كنت', 'مكانك.'] },
    { 'en': ['Did', 'it', 'snow', 'yesterday?'], 'ar': ['هل', 'أثلجت', 'البارحة؟'] },
    { 'en': ['I', "shouldn't", 'have', 'trusted', 'Tom.'], 'ar': ['ما', 'كان', 'يجب', 'علي', 'أن', 'أثق', 'بتوم'] },
    { 'en': ['Pardon', 'me?'], 'ar': ['المعذرة؟'] },
    { 'en': ['I', 'have', 'visited', 'America.'], 'ar': ['زرت', 'أمريكا.'] },
    { 'en': ["I'm", 'not', 'thirsty.'], 'ar': ['لا', 'أشعر', 'بالعطش.'] },
    { 'en': ["I'm", 'too', 'scared', 'to', 'go', 'there', 'by', 'myself.'], 'ar': ['أنا', 'خائف', 'جداً', 'من', 'الذهاب', 'إلى', 'هناك', 'بنفسي.'] },
    { 'en': ['I', 'have', 'a', 'book.'], 'ar': ['عندي', 'كتاب.'] },
    { 'en': ['How', 'strange', 'life', 'is!'], 'ar': ['كم', 'غريبة', 'الحياة!'] },
    { 'en': ['He', 'is', 'ill.'], 'ar': ['إنه', 'مريض.'] },
    { 'en': ["We're", 'old', 'friends.'], 'ar': ['نحن', 'أصدقاء', 'قدامى.'] },
    { 'en': ["You're", 'such', 'a', 'cute', 'boy.'], 'ar': ['يا', 'لك', 'من', 'ولد', 'جميل!'] },
    { 'en': ['Someone', 'is', 'at', 'the', 'front', 'door.'], 'ar': ['أحدهم', 'على', 'الباب.'] },
    { 'en': ['The', 'boy', 'ran', 'away.'], 'ar': ['هرب', 'الولد.'] },
    { 'en': ['Why', 'me?'], 'ar': ['لماذا', 'أنا؟'] },
    { 'en': ['I', 'worked', 'hard', 'last', 'month.'], 'ar': ['عملت', 'بجد', 'الشهر', 'الماضي.'] },
    { 'en': ['This', 'book', 'is', 'yours.'], 'ar': ['هذا', 'الكتاب', 'لك.'] },
    { 'en': ['Tom', 'knows', 'a', 'lot', 'about', 'American', 'history.'], 'ar': ['توم', 'يعرف', 'الكثير', 'عن', 'التاريخ', 'الأميركي.'] },
    { 'en': ['I', 'expect', 'your', 'help.'], 'ar': ['أتوقع', 'مساعدتك.'] },
    { 'en': ['Now', 'I', 'remember.'], 'ar': ['تذكرت', 'الآن.'] },
    { 'en': ["There's", 'no', 'shortage', 'of', 'work.'], 'ar': ['لا', 'يوجد', 'نقص', 'في', 'العمل'] },
    { 'en': ['They', 'must', 'be', 'waiting', 'for', 'you.'], 'ar': ['لا', 'بد', 'أنهم', 'ينتظرونك.'] },
    { 'en': ['I', 'love', 'to', 'go', 'to', 'the', 'movies.'], 'ar': ['أحب', 'الذهاب', 'إلى', 'السينما.'] },
    { 'en': ['Just', 'follow', 'your', 'heart.'], 'ar': ['اتبع', 'قلبك', 'فحسب.'] },
    { 'en': ['What', 'time', 'will', 'the', 'game', 'start?'], 'ar': ['متى', 'ستبدأ', 'المباراة؟'] },
    { 'en': ['He', 'kept', 'all', 'the', 'windows', 'open.'], 'ar': ['أبقى', 'جميع', 'النّوافذ', 'مفتوحة.'] },
    { 'en': ['Tom', 'said', 'he', "wasn't", 'allowed', 'to', 'do', 'that.'], 'ar': ['قال', 'توم', 'بأنه', 'لم', 'يكن', 'مسموحا', 'له', 'فعل', 'ذلك.'] },
    { 'en': ['He', 'drank', 'a', 'little.'], 'ar': ['شرب', 'القليل.'] },
    { 'en': ['Only', 'a', 'few', 'people', 'understood', 'me.'], 'ar': ['فهمني', 'القليل.'] },
    { 'en': ["Here's", 'a', 'basket', 'full', 'of', 'fruit.'], 'ar': ['هذه', 'السلة', 'مليئة', 'بالفواكه'] },
    { 'en': ['I', 'want', 'my', 'dinner', 'brought', 'to', 'my', 'room.'], 'ar': ['أرغب', 'بأن', 'يُحضر', 'عشائي', 'لغرفتي'] },
    { 'en': ['No', 'one', 'believes', 'what', 'Tom', 'says', 'anymore.'], 'ar': ['لا', 'أحد', 'يصدق', 'ما', 'يقولهُ', 'توم', 'بعد', 'الآن.'] },
    { 'en': ['Will', 'you', 'drive', 'me', 'to', 'the', 'hospital?'], 'ar': ['هلّا', 'أوصلتني', 'إلى', 'المستشفى؟'] },
    { 'en': ["I'll", 'take', 'a', 'shower.'], 'ar': ['سأستحِمّ.'] },
    { 'en': ['Do', "whatever's", 'required.'], 'ar': ['إفعل', 'كل', 'ما', 'هو', 'مطلوب.'] },
    { 'en': ['He', 'suddenly', 'came', 'out', 'with', 'the', 'truth.'], 'ar': ['اعترَف', 'بالحقيقة', 'فجأةً.'] },
    { 'en': ['What', 'time', 'does', 'your', 'plane', 'leave?'], 'ar': ['ما', 'ميعاد', 'إقلاع', 'طائرتك؟'] },
    { 'en': ['We', "haven't", 'finished', 'what', 'we', 'have', 'to', 'do', 'yet.'], 'ar': ['لم', 'نكمل', 'ما', 'علينا', 'إنجازه', 'بعد.'] },
    { 'en': ['I', 'tried,', 'but', 'I', 'failed.'], 'ar': ['حاولتُ', 'ولكني', 'فشلت.'] },
    { 'en': ['The', 'old', 'man', 'is', 'very', 'kind.'], 'ar': ['الرجل', 'العجوز', 'لطيف', 'للغاية.'] },
    { 'en': ['Tom', 'never', 'gives', 'up.'], 'ar': ['توم', 'لا', 'يستسلم', 'أبدا.'] },
    { 'en': ['We', 'may', 'need', 'some.'], 'ar': ['قد', 'نحتاج', 'لبعضه.'] },
    { 'en': ['One', 'plus', 'two', 'equals', 'three.'], 'ar': ['واحد', 'زائد', 'اثنان', 'يساوي', 'ثلاثة.'] },
    { 'en': ['How', 'did', 'it', 'go?'], 'ar': ['كيف', 'جرى', 'الأمر؟'] },
    { 'en': ['I', 'have', 'no', 'memory', 'of', 'what', 'happened.'], 'ar': ['لا', 'أتذكر', 'ما', 'حصل.'] },
    { 'en': ['Who', 'cares', 'about', 'facts?'], 'ar': ['من', 'يهتم', 'بالحقائق؟'] },
    { 'en': ['Stay', 'here', 'with', 'us.'], 'ar': ['ابق', 'معنا.'] },
    { 'en': ['Everyone', 'likes', 'her.'], 'ar': ['الكل', 'يحبونها.'] },
    { 'en': ['She', 'went', 'down', 'to', 'the', 'fifth', 'floor.'], 'ar': ['نزلت', 'إلى', 'الطابق', 'الخامس.'] },
    { 'en': ["I'm", 'not', 'sure', 'what', 'Tom', 'was', 'talking', 'about.'], 'ar': ['لست', 'متأكدا', 'حيال', 'ما', 'كان', 'يتحدث', 'توم', 'عنه.'] },
    { 'en': ["Don't", 'look', 'for', 'me.'], 'ar': ['لا', 'تبحث', 'عني'] },
    { 'en': ['This', 'bread', 'is', 'delicious.'], 'ar': ['هذا', 'الخبز', 'لذيذ.'] },
    { 'en': ['I', 'trust', 'you.'], 'ar': ['أنا', 'أثق', 'فيك.'] },
    { 'en': ["I'm", 'here', 'for', 'you.'], 'ar': ['إنني', 'هنا', 'لأجلك.'] },
    { 'en': ['How', 'long', 'is', 'the', 'bridge?'], 'ar': ['ما', 'طول', 'الجسر؟'] },
    { 'en': ['I', 'want', 'to', 'ride', 'a', 'horse.'], 'ar': ['أريد', 'أن', 'أركب', 'حصاناً.'] },
    { 'en': ['I', 'miss', 'you', 'badly.'], 'ar': ['أشتاق', 'إليك', 'كثيراً.'] },
    { 'en': ['Return', 'this', 'book', 'as', 'soon', 'as', 'you', 'can.'], 'ar': ['أعد', 'الكتاب', 'في', 'أقرب', 'فرصة', 'ممكنة.'] },
    { 'en': ['Tom', 'is', 'trying', 'to', 'get', 'a', 'green', 'card.'], 'ar': ['يحاول', 'توم', 'الحصول', 'على', 'الكرت', 'الأخضر.'] },
    { 'en': ['I', 'love', 'you.'], 'ar': ['احبك.'] },
    { 'en': ['I', 'have', 'many', 'photos.'], 'ar': ['لدي', 'العديد', 'من', 'الصور.'] },
    { 'en': ['China', 'is', 'rich', 'in', 'natural', 'resources.'], 'ar': ['الصين', 'غنية', 'بالموارد', 'الطبيعية.'] },
    { 'en': ['She', 'became', 'happy.'], 'ar': ['أصبحت', 'سعيدة.'] },
    { 'en': ['I', 'have', 'a', 'pain', 'in', 'my', 'back.'], 'ar': ['لدي', 'ألم', 'في', 'ظهري.'] },
    { 'en': ['Come', 'closer.'], 'ar': ['إقتَرِب.'] },
    { 'en': ["What's", 'this?'], 'ar': ['ما', 'هذا؟'] },
    { 'en': ['He', 'is', 'angry', 'with', 'you.'], 'ar': ['إنهُ', 'غاضب', 'معكِ.'] },
    { 'en': ["You'd", 'better', 'go', 'to', 'bed.'], 'ar': ['حريّ', 'لك', 'الذهاب', 'إلى', 'الفراش.'] },
    { 'en': ['You', 'should', 'go', 'see', 'a', 'doctor.'], 'ar': ['عليك', 'أن', 'تذهب', 'إلى', 'الطّبيب.'] },
    { 'en': ["I'll", 'just', 'leave', 'this', 'here.'], 'ar': ['أنا', 'فقط', 'سأترك', 'هذه', 'هنا.'] },
    { 'en': ['How', 'do', 'you', 'say', '"good', 'bye"', 'in', 'German?'], 'ar': ['كيف', 'تقول', '"وداعًا"', 'باللغة', 'الألمانية؟'] },
    { 'en': ['I', 'ate', 'the', 'cheese.'], 'ar': ['أكلت', 'الجبن.'] },
    { 'en': ['Who', 'does', 'this', 'suitcase', 'belong', 'to?'], 'ar': ['من', 'مالك', 'تلك', 'الحقيبة؟'] },
    { 'en': ['Will', 'you', 'please', 'let', 'me', 'go', 'now?'], 'ar': ['هل', 'تسمح', 'لي', 'بالذهاب', 'الآن؟'] },
    { 'en': ['He', 'got', 'the', 'ball.'], 'ar': ['حصل', 'على', 'الكرة.'] },
    { 'en': ['You', 'have', 'to', 'stop.'], 'ar': ['يجب', 'أن', 'تتوقف'] },
    { 'en': ['Are', 'you', 'able', 'to', 'swim?'], 'ar': ['هل', 'تستطيع', 'السباحة؟'] },
    { 'en': ['You', 'are', 'not', 'a', 'doctor.'], 'ar': ['إنك', 'لست', 'طبيباً.'] },
    { 'en': ['My', 'TV', 'is', 'on', 'the', 'fritz', 'again.'], 'ar': ['تعطل', 'تلفزيوني', 'مجددا.'] },
    { 'en': ["I'm", 'really', 'tired.'], 'ar': ['أنا', 'متعب', 'جداً.'] },
    { 'en': ['May', 'I', 'ask', 'where', 'are', 'you', 'going?'], 'ar': ['هل', 'يمكنني', 'أن', 'أسأل,', 'إلى', 'أين', 'أنتَ', 'ذاهب؟'] },
    { 'en': ['He', 'was', 'dressed', 'in', 'blue.'], 'ar': ['كان', 'مرتدياً', 'اللون', 'الأزرق.'] },
    { 'en': ['The', 'boy', 'began', 'to', 'cry.'], 'ar': ['أخذ', 'الولد', 'يبكي.'] },
    { 'en': ['Who', 'broke', 'the', 'vase?'], 'ar': ['من', 'كسر', 'المزهرية؟'] },
    { 'en': ['Do', 'not', 'disturb', 'her.'], 'ar': ['لا', 'تزعجها.'] },
    { 'en': ['She', 'might', 'come.'], 'ar': ['من', 'الممكن', 'أنها', 'ستأتي.'] },
    { 'en': ['You', 'should', 'sleep.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تنام.'] },
    { 'en': ['Which', 'way', 'is', 'the', 'beach?'], 'ar': ['أيّ', 'طريق', 'يؤدّي', 'إلى', 'الشاطئ؟'] },
    { 'en': ['The', 'car', 'broke', 'down.'], 'ar': ['تعطلت', 'السيارة.'] },
    { 'en': ['Knowledge', 'is', 'power.'], 'ar': ['المعرفة', 'قوة.'] },
    { 'en': ["I've", 'finally', 'finished', 'writing', 'the', 'report.'], 'ar': ['أخيرا', 'أنهيتُ', 'كتابة', 'التقرير.'] },
    { 'en': ['We', 'have', 'run', 'out', 'of', 'gas.'], 'ar': ['لقد', 'نفذ', 'ما', 'لدينا', 'من', 'الغاز', '.'] },
    { 'en': ['Boys', 'will', 'be', 'boys.'], 'ar': ['سيبقى', 'الأولاد', 'أولاداً.'] },
    { 'en': ['Leave', 'it', 'to', 'me.'], 'ar': ['دع', 'الأمر', 'لي.'] },
    { 'en': ['China', 'is', 'a', 'huge', 'country.'], 'ar': ['الصين', 'دولة', 'كبيرة', 'جداً'] },
    { 'en': ['Tom', 'was', 'indicted', 'last', 'month.'], 'ar': ['تم', 'اتهام', 'توم', 'الشهر', 'الماضي.'] },
    { 'en': ['My', 'bag', 'was', 'stolen.'], 'ar': ['سُرقت', 'حقيبتي.'] },
    { 'en': ["Let's", 'not', 'talk.'], 'ar': ['دعونا', 'لا', 'نتحدث.'] },
    { 'en': ['This', 'will', 'take', 'time.'], 'ar': ['سيأخذ', 'هذا', 'وقتا.'] },
    { 'en': ['I', 'live', 'on', 'the', 'bottom', 'floor.'], 'ar': ['أعيش', 'في', 'الطابق', 'الأرضي.'] },
    { 'en': ["I've", 'had', 'a', 'pleasant', 'evening.'], 'ar': ['أمضيت', 'ليلة', 'ممتعة.'] },
    { 'en': ['Is', 'that', 'all', 'you', 'wanted', 'to', 'tell', 'me?'], 'ar': ['أهذا', 'كل', 'ما', 'أردت', 'قوله؟'] },
    { 'en': ['You', 'surprised', 'everybody.'], 'ar': ['لقد', 'فاجأت', 'الجميع.'] },
    { 'en': ['I', 'am', 'happy', 'to', 'hear', 'your', 'voice.'], 'ar': ['أنا', 'سعيد', 'بسماع', 'صوتك.'] },
    { 'en': ['My', 'mother', 'is', 'reading', 'a', 'magazine.'], 'ar': ['أمي', 'تقرأ', 'مجلة.'] },
    { 'en': ['I', 'wonder', 'where', 'I', 'put', 'my', 'glasses.'], 'ar': ['أتساءل', 'أين', 'وضعت', 'نظاراتي.'] },
    { 'en': ['No', 'one', 'ate', 'the', 'cake.'], 'ar': ['لم', 'يأكل', 'أحد', 'الكعكة.'] },
    { 'en': ['When', 'is', 'your', 'birthday?'], 'ar': ['متى', 'عيد', 'ميلادك؟'] },
    { 'en': ['I', 'looked', 'for', 'the', 'key.'], 'ar': ['أنا', 'بحثت', 'عن', 'المفتاح.'] },
    { 'en': ['This', 'book', 'is', 'yours.'], 'ar': ['هذا', 'كتابك.'] },
    { 'en': ['I', 'want', 'a', 'guitar.'], 'ar': ['أريد', 'قيثارة.'] },
    { 'en': ['I', 'like', 'tennis.'], 'ar': ['أحب', 'كرة', 'المضرب.'] },
    { 'en': ['It', 'may', 'freeze', 'next', 'week.'], 'ar': ['من', 'الممكن', 'أن', 'تتجمد', 'الأسبوع', 'القادم.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'come', 'inside?'], 'ar': ['هل', 'تود', 'أن', 'تأتي', 'بالداخِل؟'] },
    { 'en': ['The', 'police', 'arrested', 'the', 'suspect.'], 'ar': ['اعتقلت', 'الشرطة', 'المشتبه', 'به.'] },
    { 'en': ['Was', 'that', 'too', 'much?'], 'ar': ['كان', 'ذلك', 'كثيرا'] },
    { 'en': ['My', 'sister', 'made', 'me', 'a', 'beautiful', 'doll.'], 'ar': ['صنعت', 'لي', 'أختي', 'دميةً', 'جميلة.'] },
    { 'en': ["I'm", 'going', 'to', 'go', 'shopping', 'after', 'lunch.'], 'ar': ['سوف', 'اذهب', 'للتسوق', 'بعد', 'الغداء.'] },
    { 'en': ['Do', 'you', 'know', 'them?'], 'ar': ['هل', 'تعرفهم؟'] },
    { 'en': ['We', 'need', 'to', 'communicate', 'with', 'each', 'other.'], 'ar': ['نحتاج', 'ان', 'نتواصل', 'مع', 'بعضنا', 'البعض.'] },
    { 'en': ['We', 'went', 'to', 'the', 'same', 'church.'], 'ar': ['ذهبنا', 'إلى', 'ذات', 'الكنيسة.'] },
    { 'en': ['We', 'can', 'do', 'nothing', 'about', 'it.'], 'ar': ['لا', 'يمكننا', 'فعل', 'أي', 'شيء', 'حيال', 'ذلك.'] },
    { 'en': ['I', 'have', 'a', 'car.'], 'ar': ['أملك', 'سيارةً.'] },
    { 'en': ['I', "don't", 'remember', 'what', 'happened', 'anymore.'], 'ar': ['لم', 'أعد', 'أتذكر', 'ما', 'حصل.'] },
    { 'en': ['He', 'did', 'what', 'he', 'had', 'been', 'told.'], 'ar': ['فعل', 'ما', 'أُمِرَ', 'به.'] },
    { 'en': ['I', 'know', 'you', "don't", 'like', 'me.'], 'ar': ['أنا', 'أعلم', 'أنكَ', 'ليس', 'معجباً', 'بي.'] },
    { 'en': ['Take', 'off', 'your', 'shoes.'], 'ar': ['اخلع', 'نعلَيْك.'] },
    { 'en': ['You', 'have', 'a', 'bright', 'future.'], 'ar': ['لديك', 'مستقبل', 'مشرق.'] },
    { 'en': ['Do', 'you', 'have', 'one?'], 'ar': ['ألديك', 'واحد؟'] },
    { 'en': ['Where', 'is', 'my', 'watch?'], 'ar': ['أين', 'ساعتي؟'] },
    { 'en': ['Will', 'you', 'go', 'to', 'the', 'party', 'tonight?'], 'ar': ['هل', 'ستحضر', 'حفلة', 'الليلة؟'] },
    { 'en': ['I', 'appreciate', 'this.'], 'ar': ['أنا', 'أقدر', 'ذلك.'] },
    { 'en': ["I'll", 'stay', 'until', 'Tom', 'leaves.'], 'ar': ['سأظل', 'حتى', 'يرحل', 'توم.'] },
    { 'en': ['I', 'waited', 'for', 'Tom', 'for', 'a', 'long', 'time.'], 'ar': ['انتظرت', 'توم', 'لوقت', 'طويل.'] },
    { 'en': ['Tom', 'complained', 'that', 'he', "couldn't", 'find', 'a', 'job.'], 'ar': ['اشتكي', 'توم', 'من', 'أنه', 'لم', 'يستطع', 'إيجاد', 'وظيفة'] },
    { 'en': ['Tom', 'wants', 'to', 'buy', 'a', 'house.'], 'ar': ['يريد', 'توم', 'شراء', 'منزلًا.'] },
    { 'en': ['Cheese', 'is', 'made', 'from', 'milk.'], 'ar': ['تُصنع', 'الجبنة', 'من', 'الحليب.'] },
    { 'en': ['He', 'taught', 'his', 'son', 'how', 'to', 'swim.'], 'ar': ['علم', 'ابنه', 'كيفية', 'السباحة.'] },
    { 'en': ['Eat', 'your', 'vegetables.'], 'ar': ['كُل', 'خضراواتك.'] },
    { 'en': ['You', 'let', 'me', 'down.'], 'ar': ['خذلتني.'] },
    { 'en': ['I', 'have', 'a', 'cold.'], 'ar': ['أصبت', 'بالبرد.'] },
    { 'en': ['Come', 'to', 'my', 'house.'], 'ar': ['تعال', 'إلى', 'منزلي.'] },
    { 'en': ['He', 'caught', 'a', 'large', 'trout.'], 'ar': ['التقط', 'سمكة', 'سلمون', 'مرقط', 'كبيرة.'] },
    { 'en': ["Let's", 'go', 'someplace', 'else.'], 'ar': ['هيا', 'بنا', 'نذهب', 'إلى', 'مكان', 'آخر.'] },
    { 'en': ['I', 'also', 'went', 'there.'], 'ar': ['ذهبت', 'إلى', 'هناك', 'أيضاً.'] },
    { 'en': ['Where', 'have', 'you', 'been?'], 'ar': ['أين', 'كنت؟'] },
    { 'en': ['He', "doesn't", 'live', 'in', 'my', 'neighborhood.'], 'ar': ['إنه', 'لا', 'يسكن', 'في', 'نفس', 'الحي', 'الذي', 'أعيش', 'فيه.'] },
    { 'en': ["Don't", 'resort', 'to', 'violence.'], 'ar': ['لا', 'تلجأ', 'إلى', 'العنف.'] },
    { 'en': ['What', 'are', 'your', 'conditions?'], 'ar': ['ما', 'هي', 'شروطكم؟'] },
    { 'en': ['Who', 'won?'], 'ar': ['من', 'فاز؟'] },
    { 'en': ['You', 'must', 'learn', 'from', 'your', 'mistakes.'], 'ar': ['يجب', 'أن', 'تتعلم', 'من', 'أخطائك.'] },
    { 'en': ["You'll", 'get', 'lost.'], 'ar': ['ستضيع.'] },
    { 'en': ['You', 'must', 'do', 'it', 'yourself.'], 'ar': ['يجب', 'عليك', 'فعل', 'ذلك', 'بنفسك.'] },
    { 'en': ['School', 'begins', 'at', 'eight-thirty.'], 'ar': ['تبدأ', 'المدرسة', 'الساعة', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ["I'm", 'trying', 'to', 'help.'], 'ar': ['أحاول', 'المساعدة', 'فحسب.'] },
    { 'en': ['Would', 'you', 'mind', 'shutting', 'the', 'door?'], 'ar': ['هل', 'لك', 'أن', 'تغلق', 'الباب؟'] },
    { 'en': ['No', 'one', 'helped', 'me.'], 'ar': ['لم', 'يساعدني', 'أحد.'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['إن', 'الطقس', 'حار', 'للغاية.'] },
    { 'en': ['When', 'did', 'you', 'first', 'meet', 'him?'], 'ar': ['متى', 'قابلته', 'اول', 'مره؟'] },
    { 'en': ['The', 'students', 'learned', 'this', 'poem', 'by', 'heart.'], 'ar': ['حفظ', 'الطلبة', 'هذه', 'القصيدة.'] },
    { 'en': ["I'm", 'really', 'glad', 'to', 'hear', 'that.'], 'ar': ['يسعدني', 'جداً', 'سماع', 'ذلك.'] },
    { 'en': ['She', 'has', 'pretty', 'eyes.'], 'ar': ['عيونها', 'جميلة.'] },
    { 'en': ['The', 'cat', 'is', 'sleeping', 'on', 'the', 'sofa.'], 'ar': ['القطة', 'نائمة', 'على', 'الأريكة.'] },
    { 'en': ['He', 'had', 'no', 'difficulty', 'solving', 'the', 'problem.'], 'ar': ['لم', 'يواجه', 'صعوبةً', 'تذكر', 'في', 'حلّ', 'المشكلة.'] },
    { 'en': ["I'm", 'glad', 'to', 'see', 'you', 'again.'], 'ar': ['أنا', 'سعيد', 'لرؤيتك', 'مجدداً.'] },
    { 'en': ['Have', 'you', 'washed', 'the', 'car', 'yet?'], 'ar': ['هل', 'غسلت', 'السيارة؟'] },
    { 'en': ['I', 'like', 'him', 'because', 'he', 'is', 'honest.'], 'ar': ['أحب', 'فيه', 'صدقه.'] },
    { 'en': ['The', 'price', 'is', 'high.'], 'ar': ['الثمن', 'مرتفع.'] },
    { 'en': ['You', 'still', 'have', 'a', 'chance.'], 'ar': ['ما', 'زالت', 'لديك', 'فرصة.'] },
    { 'en': ['Do', 'you', 'swim', 'very', 'fast,', 'too?'], 'ar': ['هل', 'تسبح', 'بسرعة', 'أيضاً؟'] },
    { 'en': ["I've", 'lost', 'my', 'bag.'], 'ar': ['فقدت', 'حقيبتي.'] },
    { 'en': ['Should', 'we', 'go', 'by', 'car', 'or', 'by', 'taxi?'], 'ar': ['هل', 'نذهب', 'بالسيارة', 'أم', 'بالتكسي؟'] },
    { 'en': ['She', 'decided', 'to', 'resign.'], 'ar': ['قررت', 'أن', 'تستقيل.'] },
    { 'en': ['The', 'rain', 'has', 'let', 'up,', 'so', 'we', 'can', 'begin.'], 'ar': ['لقد', 'توقف', 'المطر,', 'لذا', 'يمكننا', 'البدء.'] },
    { 'en': ['I', 'thought', 'you', 'might', 'want', 'to', 'come', 'along.'], 'ar': ['ظننت', 'أنك', 'قد', 'تود', 'مرافقتي.'] },
    { 'en': ['Bottoms', 'up!'], 'ar': ['الى', 'القاع', '.'] },
    { 'en': ['You', 'should', 'stay', 'in', 'bed.'], 'ar': ['عليك', 'أن', 'تلزم', 'السرير.'] },
    { 'en': ['It', 'was', 'a', 'mistake', 'to', 'refuse', 'his', 'assistance.'], 'ar': ['كان', 'من', 'الخطأ', 'رفض', 'مساعدته.'] },
    { 'en': ['Tom', 'wants', 'me', 'to', 'help', 'him', 'with', 'his', 'homework.'], 'ar': ['يريد', 'توم', 'منّي', 'مساعدته', 'في', 'حلّ', 'واجبه', 'المنزليّ.'] },
    { 'en': ['If', 'I', 'need', 'money,', "I'll", 'ask', 'my', 'father.'], 'ar': ['إن', 'احتجت', 'المال', 'سأطلبه', 'من', 'والدي.'] },
    { 'en': ['You', 'only', 'live', 'once.'], 'ar': ['إنك', 'تعيش', 'مرة', 'واحدة', 'لا', 'غير.'] },
    { 'en': ['Please', 'add', 'my', 'name', 'to', 'the', 'list.'], 'ar': ['من', 'فضلك', 'أضف', 'اسمي', 'إلى', 'اللائحة.'] },
    { 'en': ['How', 'old', 'is', 'your', 'father?'], 'ar': ['كم', 'عمر', 'والدك؟'] },
    { 'en': ['My', 'hair', 'is', 'light', 'brown.'], 'ar': ['شعري', 'لونه', 'بني', 'فاتح.'] },
    { 'en': ["I'm", 'sure', "you're", 'mistaken.'], 'ar': ['أنا', 'متأكد', 'أنكَ', 'مخطئ.'] },
    { 'en': ['I', 'appreciate', 'what', "you've", 'done', 'for', 'me.'], 'ar': ['أقدر', 'ما', 'عمِلته', 'لأجلي.'] },
    { 'en': ['Tom', 'is', 'your', 'brother.'], 'ar': ['توم', 'هو', 'أخوك'] },
    { 'en': ['All', 'you', 'need', 'to', 'do', 'is', 'listen', 'carefully.'], 'ar': ['كل', 'ما', 'عليك', 'فعله', 'هو', 'أن', 'تسمع', 'بعناية.'] },
    { 'en': ['Everybody', 'had', 'a', 'good', 'year.'], 'ar': ['أمضى', 'الجميع', 'سنة', 'طيبة.'] },
    { 'en': ['Never', 'play', 'here.'], 'ar': ['لا', 'تلعب', 'هنا', 'أبداً.'] },
    { 'en': ['Does', 'he', 'have', 'many', 'books?'], 'ar': ['هل', 'لديه', 'كتب', 'كثيرة؟'] },
    { 'en': ['How', 'about', 'going', 'to', 'a', 'movie?'], 'ar': ['ما', 'رأيك', 'بالذهاب', 'لمشاهدة', 'فلم؟'] },
    { 'en': ['I', 'was', 'vaccinated', 'against', 'the', 'flu.'], 'ar': ['طُعِّمتُ', 'من', 'الإنفلونزا.'] },
    { 'en': ["I'll", 'stay', 'here', 'until', 'you', 'return.'], 'ar': ['سأبقىَ', 'هنا', 'إلى', 'أن', 'ترجع.'] },
    { 'en': ["That's", 'my', 'dictionary.'], 'ar': ['ذاك', 'قاموسي.'] },
    { 'en': ['You', 'made', 'the', 'same', 'mistake.'], 'ar': ['لقد', 'وقعت', 'في', 'الخطأ', 'ذاته.'] },
    { 'en': ['Were', 'they', 'busy', 'last', 'week?'], 'ar': ['هل', 'كانوا', 'مشغولين', 'في', 'الإسبوع', 'الماضي؟'] },
    { 'en': ['Tom', 'was', 'eating', 'dinner', 'when', 'Mary', 'arrived.'], 'ar': ['كان', 'توم', 'يأكل', 'العشاء', 'عندما', 'وصلت', 'ماري.'] },
    { 'en': ['They', 'slept', 'in', 'the', 'same', 'bed.'], 'ar': ['لقد', 'ناما', 'بنفس', 'السرير'] },
    { 'en': ['You', 'left', 'your', 'lights', 'on.'], 'ar': ['لقد', 'تركت', 'أنوارك', 'مفتوحة.'] },
    { 'en': ['Your', 'privacy', 'is', 'important', 'to', 'us.'], 'ar': ['خصوصيتك', 'مهمة', 'لنا.'] },
    { 'en': ['This', 'movie', 'is', 'just', 'great.'], 'ar': ['هذا', 'الفلم', '-ببساطة-', 'رائع.'] },
    { 'en': ['We', 'must', 'cancel', 'our', 'trip', 'to', 'Japan.'], 'ar': ['علينا', 'أن', 'نلغي', 'رحلتنا', 'إلى', 'اليابان.'] },
    { 'en': ['She', 'works', 'in', 'marketing.'], 'ar': ['إنها', 'تعمل', 'في', 'مجال', 'التسويق.'] },
    { 'en': ['We', 'went', 'to', 'the', 'museum', 'last', 'week.'], 'ar': ['ذهبنا', 'إلى', 'المتحف', 'الأسبوع', 'الماضي.'] },
    { 'en': ['He', 'kissed', 'her.'], 'ar': ['قبّلها.'] },
    { 'en': ["I'm", 'the', 'one', 'who', 'loves', 'you.'], 'ar': ['أنا', 'هو', 'الشخص', 'الذي', 'يحبك.'] },
    { 'en': ['We', 'all', 'desire', 'success.'], 'ar': ['كلنا', 'نرغب', 'بالنجاح.'] },
    { 'en': ["She's", 'busy', 'now,', 'so', 'she', "can't", 'talk', 'with', 'you.'], 'ar': ['إنها', 'مشغولة', 'الآن,', 'لذا', 'لا', 'تستطيع', 'التحدث', 'معك.'] },
    { 'en': ["Let's", 'play', 'something.'], 'ar': ['هيا', 'لنلعب.'] },
    { 'en': ['Tom', 'seems', 'OK', 'now.'], 'ar': ['يبدو', 'توم', 'بحال', 'جيدة.'] },
    { 'en': ['I', 'was', 'reading', 'a', 'novel', 'then.'], 'ar': ['كنت', 'أقرأ', 'رواية', 'حينها.'] },
    { 'en': ['The', 'train', 'leaves', 'in', 'ten', 'minutes.'], 'ar': ['سينطلق', 'القطار', 'خلال', 'عشر', 'دقائق.'] },
    { 'en': ['Can', 'you', 'prove', 'it?'], 'ar': ['أيمكنك', 'أن', 'تثبت', 'ذلك؟'] },
    { 'en': ['Can', 'you', 'tell', 'wheat', 'from', 'barley?'], 'ar': ['هل', 'تستطيع', 'التمييز', 'بين', 'القمح', 'والشعير؟'] },
    { 'en': ["It's", 'very', 'hot', 'here.'], 'ar': ['الحرارة', 'مرتفعة', 'جدا', 'هنا'] },
    { 'en': ['I', "don't", 'watch', 'TV', 'very', 'much.'], 'ar': ['لا', 'أشاهد', 'التلفاز', 'كثيرا.'] },
    { 'en': ['I', 'am', '19', 'years', 'old.'], 'ar': ['عمري', 'تسعة', 'عشر', 'عاماً.'] },
    { 'en': ['I', 'fell', 'asleep', 'before', 'my', 'father', 'came', 'home.'], 'ar': ['غفوت', 'قبل', 'أن', 'يأتي', 'والدي', 'للمنزل.'] },
    { 'en': ['You', 'must', 'keep', 'your', 'room', 'clean.'], 'ar': ['عليك', 'أن', 'تبقي', 'غرفتك', 'نظيفة.'] },
    { 'en': ['One', 'stayed', 'and', 'the', 'other', 'went', 'away.'], 'ar': ['بقيَ', 'واحد', 'وذهب', 'الآخر.'] },
    { 'en': ['I', 'want', 'to', 'share', 'this', 'with', 'you.'], 'ar': ['أريد', 'أن', 'أشاركك', 'هذا.'] },
    { 'en': ['You', 'ought', 'to', 'be', 'ashamed.'], 'ar': ['يجب', 'أن', 'تكون', 'خجِلًا.'] },
    { 'en': ["What's", 'your', 'job?'], 'ar': ['ما', 'مهنتك؟'] },
    { 'en': ['Thanks', 'for', 'coming', 'over', 'tonight.'], 'ar': ['نشكرك', 'لمجيئك', 'عندنا', 'الليلة.'] },
    { 'en': ['Are', 'you', 'new,', 'too?'], 'ar': ['هل', 'أنت', 'جديد', 'أيضاً؟'] },
    { 'en': ['We', 'must', 'prevent', 'war', 'at', 'any', 'cost.'], 'ar': ['علينا', 'أن', 'نتجنب', 'الحرب', 'بأي', 'ثمن.'] },
    { 'en': ['There', 'is', 'a', 'page', 'missing.'], 'ar': ['هناك', 'صفحة', 'ناقصة.'] },
    { 'en': ['Speech', 'is', 'silver,', 'silence', 'is', 'gold.'], 'ar': ['إذا', 'كان', 'الكلام', 'من', 'فضة', 'فالسكوت', 'من', 'ذهب.'] },
    { 'en': ['I', 'like', 'sports.'], 'ar': ['أحب', 'الرياضة.'] },
    { 'en': ['That', 'house', 'is', 'big.'], 'ar': ['ذاك', 'البيت', 'كبير.'] },
    { 'en': ['Do', 'you', 'believe', 'ghosts', 'exist?'], 'ar': ['هل', 'تصدق', 'أن', 'الأشباح', 'موجودة؟'] },
    { 'en': ['We', 'understand', 'that.'], 'ar': ['نفهم', 'ذلك.'] },
    { 'en': ['My', 'friend', "doesn't", 'play', 'tennis.'], 'ar': ['صديقي', 'لا', 'يلعب', 'كرة', 'المضرب.'] },
    { 'en': ['What', 'gives?'], 'ar': ['ما', 'الأمر؟'] },
    { 'en': ['Did', 'you', 'grade', 'the', 'tests?'], 'ar': ['هل', 'صححت', 'الإمتحانات؟'] },
    { 'en': ['The', 'taste', 'is', 'delicious.'], 'ar': ['إنها', 'لذيذة', 'المذاق.'] },
    { 'en': ['We', 'need', 'action,', 'not', 'words.'], 'ar': ['نحتاج', 'إلى', 'العمل', 'وليس', 'الكلام.'] },
    { 'en': ['She', 'promised', 'not', 'to', 'be', 'late', 'again.'], 'ar': ['هي', 'وعدت', 'بأن', 'لاتتأخر', 'مرة', 'اخرى.'] },
    { 'en': ['Did', 'you', 'practice', 'the', 'piano', 'this', 'morning?'], 'ar': ['هل', 'تدربت', 'على', 'عزف', 'البيانو', 'هذا', 'الصباح؟'] },
    { 'en': ['Where', 'is', 'the', 'bathroom?'], 'ar': ['أين', 'الحمّام؟'] },
    { 'en': ['I', 'should', 'have', 'done', 'this', 'years', 'ago.'], 'ar': ['كان', 'عليّ', 'فعل', 'هذا', 'منذ', 'زمن.'] },
    { 'en': ["I'll", 'miss', 'you.'], 'ar': ['سأشتاق', 'إليك.'] },
    { 'en': ["Let's", 'talk', 'about', 'somebody', 'else.'], 'ar': ['دعونا', 'نتكلم', 'عن', 'شخص', 'ٱخر', '.', 'أ'] },
    { 'en': ['Everyone', 'noticed.'], 'ar': ['تم', 'إشعار', 'الجميع.'] },
    { 'en': ['I', 'have', 'trouble', 'talking', 'about', 'my', 'feelings.'], 'ar': ['أواجه', 'صعوبةً', 'في', 'التحدث', 'عن', 'مشاعري.'] },
    { 'en': ['May', 'I', 'come', 'again?'], 'ar': ['هل', 'أستطيع', 'أن', 'آتي', 'مرة', 'أخرى؟'] },
    { 'en': ["I'm", 'very', 'hungry', 'now.'], 'ar': ['أنا', 'جائع', 'للغاية', 'الآن.'] },
    { 'en': ["I've", 'already', 'spoken', 'with', 'Tom.'], 'ar': ['سبق', 'أن', 'كلمت', 'توم.'] },
    { 'en': ['I', 'went', 'shopping.'], 'ar': ['أنا', 'ذهبت', 'للتسوق.'] },
    { 'en': ["I'll", 'ask', 'him.'], 'ar': ['سوف', 'أسأله'] },
    { 'en': ["Don't", 'try', 'to', 'trick', 'us.'], 'ar': ['لا', 'تحاول', 'خداعنا.'] },
    { 'en': ['Tom', 'was', 'killed', 'in', 'an', 'accident.'], 'ar': ['توم', 'قتل', 'في', 'حادث.'] },
    { 'en': ["She's", 'your', 'typical', 'workaholic.'], 'ar': ['هي', 'من', 'النمط', 'المهووس', 'بمهنته.'] },
    { 'en': ['Everyone', 'laughed', 'at', 'him.'], 'ar': ['ضحك', 'الجميع', 'عليه.'] },
    { 'en': ['Come', 'here', 'and', 'help', 'me.'], 'ar': ['تعال', 'و', 'ساعدني.'] },
    { 'en': ['Stop', 'that!'], 'ar': ['أوقف', 'ذلك'] },
    { 'en': ["I'm", 'on', 'a', 'diet.'], 'ar': ['إنني', 'أتبع', 'حمية.'] },
    { 'en': ['I', 'know', 'her', 'address.'], 'ar': ['أنا', 'أعرف', 'عنوانها.'] },
    { 'en': ['We', 'have', 'seen', 'three', 'wars.'], 'ar': ['شهدنا', 'ثلاثة', 'حروب.'] },
    { 'en': ['Great,', "isn't", 'it?'], 'ar': ['عظيم,', 'أليس', 'كذلك؟'] },
    { 'en': ['You', 'have', 'to', 'forgive', 'me.'], 'ar': ['عليكِ', 'أن', 'تسامحيني.'] },
    { 'en': ['Hurry', 'up,', 'or', "you'll", 'miss', 'your', 'plane.'], 'ar': ['أسرع', 'و', 'إلا', 'فاتتك', 'الطائرة.'] },
    { 'en': ['Thanks', 'in', 'advance.'], 'ar': ['شكرًا', 'مقدمًا.'] },
    { 'en': ['My', 'mother', 'made', 'me', 'a', 'cute', 'skirt.'], 'ar': ['صنعت', 'لي', 'أمي', 'تنورة', 'جميلة.'] },
    { 'en': ['Listen', 'to', 'your', 'father.'], 'ar': ['إستمع', 'إلى', 'أبيك'] },
    { 'en': ['Give', 'it', 'to', 'me,', 'please.'], 'ar': ['أعطني', 'إياها', 'من', 'فضلك.'] },
    { 'en': ['Books', 'are', 'my', 'best', 'friends.'], 'ar': ['الكتب', 'هي', 'أعز', 'أصدقائي.'] },
    { 'en': ['I', "don't", 'even', 'know', 'anybody', 'there.'], 'ar': ['لا', 'أعرف', 'أي', 'شخص', 'هناك.'] },
    { 'en': ['He', 'broke', 'his', 'word.'], 'ar': ['لقد', 'أخلف', 'وعده'] },
    { 'en': ['This', 'question', "isn't", 'easy.'], 'ar': ['هذا', 'السؤال', 'ليس', 'سهلاً.'] },
    { 'en': ['Tom', 'is', 'angry', 'with', 'Mary.'], 'ar': ['توم', 'غاضب', 'معَ', 'مارى.'] },
    { 'en': ['How', 'long', 'did', 'you', 'stay?'], 'ar': ['كَم', 'لبثت؟'] },
    { 'en': ['Only', 'adults', 'may', 'watch', 'that', 'film.'], 'ar': ['الكبار', 'فقط', 'بإمكانهم', 'مشاهدة', 'ذاك', 'الفلم.'] },
    { 'en': ['I', 'felt', 'my', 'phone', 'vibrating', 'in', 'my', 'pocket.'], 'ar': ['شعرت', 'بأن', 'جوالي', 'يهتز', 'في', 'جيبي.'] },
    { 'en': ["It's", 'not', 'something', 'anyone', 'can', 'do.'], 'ar': ['ليس', 'أمرًا', 'هيّنًا', 'على', 'أيّ', 'شخص.'] },
    { 'en': ['He', 'drives', 'very', 'fast.'], 'ar': ['إنه', 'يقود', 'بسرعة', 'عالية.'] },
    { 'en': ['Do', 'you', 'think', 'he', 'is', 'dead?'], 'ar': ['هل', 'تعتقد', 'أنه', 'مات؟'] },
    { 'en': ["Let's", 'wait', 'and', 'see', 'if', 'that', 'happens.'], 'ar': ['فلننتظر', 'لنرى', 'إن', 'كان', 'ذلك', 'سيحدث.'] },
    { 'en': ['All', 'of', 'my', 'friends', 'like', 'soccer.'], 'ar': ['كل', 'أصدقائي', 'يحبون', 'لعب', 'كرة', 'القدم.'] },
    { 'en': ['I', 'have', 'to', 'take', 'medicine.'], 'ar': ['عليّ', 'أن', 'آخذ', 'الدواء.'] },
    { 'en': ['The', 'apartment', 'caught', 'on', 'fire.'], 'ar': ['الشقة', 'تحترق'] },
    { 'en': ["Let's", 'sing', 'the', 'song', 'in', 'English.'], 'ar': ['لنغني', 'بالإنجليزية.'] },
    { 'en': ['Put', 'the', 'book', 'where', 'you', 'found', 'it.'], 'ar': ['أعد', 'الكتاب', 'إلى', 'مكانه', 'الذي', 'وجدته', 'فيه.'] },
    { 'en': ['I', "don't", 'speak', 'German.'], 'ar': ['لا', 'أتكلم', 'الألمانية.'] },
    { 'en': ['I', 'want', 'you', 'to', 'read', 'it.'], 'ar': ['أريدك', 'أن', 'تقرأه'] },
    { 'en': ['Ten', 'teams', 'competed', 'for', 'the', 'prize.'], 'ar': ['تنافس', 'عشر', 'فرق', 'على', 'الجائزة.'] },
    { 'en': ['He', 'arrived', 'with', 'a', 'pretty', 'girl', 'on', 'his', 'arm.'], 'ar': ['إنهُ', 'وصل', 'مع', 'فتاة', 'حسناء', 'علي', 'ذراعهُ.'] },
    { 'en': ['I', 'called', 'my', 'dog.'], 'ar': ['ناديت', 'كلبي.'] },
    { 'en': ['Open', 'the', 'window.'], 'ar': ['افتح', 'النافذة.'] },
    { 'en': ['What', 'do', 'you', 'want', 'to', 'eat?'], 'ar': ['ماذا', 'تريد', 'أن', 'تأكل؟'] },
    { 'en': ['He', 'did', 'well', 'in', 'the', 'examination.'], 'ar': ['أبلى', 'في', 'الامتحان', 'بلاءً', 'حسنا.'] },
    { 'en': ['When', 'do', 'we', 'arrive?'], 'ar': ['متى', 'نصل؟'] },
    { 'en': ['It', 'is', 'necessary', 'for', 'you', 'to', 'start', 'at', 'once.'], 'ar': ['عليك', 'أن', 'تبدأ', 'حالاً.'] },
    { 'en': ['I', 'write', 'poems', 'in', 'my', 'free', 'time.'], 'ar': ['أؤلف', 'أشعاراً', 'في', 'أوقات', 'فراغي.'] },
    { 'en': ['They', 'accused', 'me', 'of', 'being', 'a', 'liar.'], 'ar': ['هم', 'اتهموني', 'بالكذب.'] },
    { 'en': ['Have', 'fun.'], 'ar': ['استمتع', 'بوقتك.'] },
    { 'en': ['There', 'has', 'to', 'be', 'a', 'way.'], 'ar': ['لا', 'بد', 'من', 'وجود', 'طريقة', 'ما.'] },
    { 'en': ['Summer', 'has', 'ended.'], 'ar': ['انتهى', 'الصيف.'] },
    { 'en': ['Is', 'she', 'a', 'doctor?'], 'ar': ['هل', 'هي', 'طبيبة؟'] },
    { 'en': ['A', 'wood', 'floor', 'is', 'beautiful.'], 'ar': ['إن', 'الأرضية', 'الخشبية', 'جميلة.'] },
    { 'en': ['I', 'love', 'it.'], 'ar': ['أنا', 'أحبه.'] },
    { 'en': ['Give', 'me', 'a', 'definite', 'answer.'], 'ar': ['أعطني', 'إجابةً', 'أكيدةً.'] },
    { 'en': ['He', 'often', 'drives', 'his', "father's", 'car.'], 'ar': ['عادةً', 'ما', 'يقود', 'سيارة', 'أبيه.'] },
    { 'en': ['I', 'need', 'a', 'few', 'minutes.'], 'ar': ['أحتاج', 'بضع', 'دقائق.'] },
    { 'en': ['Forget', 'it!'], 'ar': ['انسَ', 'الأمر.'] },
    { 'en': ['Tom', 'teaches', 'at', 'a', 'university.'], 'ar': ['توم', 'يدرس', 'في', 'الجامعة'] },
    { 'en': ['I', 'want', 'this', 'camera.'], 'ar': ['أريد', 'هذه', 'الكاميرا.'] },
    { 'en': ["I'm", 'going', 'to', 'help', 'you', 'save', 'some', 'time.'], 'ar': ['سأساعدك', 'في', 'توفير', 'بعض', 'الوقت.'] },
    { 'en': ['You', 'are', 'rich.'], 'ar': ['أنت', 'غني'] },
    { 'en': ['Instead', 'of', 'going', 'myself,', 'I', 'sent', 'a', 'gift.'], 'ar': ['بعثت', 'بهدية', 'عوضاً', 'عن', 'الذهاب', 'بنفسي.'] },
    { 'en': ['Do', 'you', 'still', 'need', 'tea?'], 'ar': ['هل', 'مازلتَ', 'تريد', 'شاياً؟'] },
    { 'en': ['Why', 'do', 'you', 'always', 'do', 'this?'], 'ar': ['لم', 'تفعل', 'هذا', 'دائما؟'] },
    { 'en': ['I', "don't", 'care', 'if', 'it', 'snows.'], 'ar': ['لا', 'يهمني', 'إذا', 'هطل', 'الثلج.'] },
    { 'en': ["I'm", 'glad', 'you', 'were', 'able', 'to', 'come.'], 'ar': ['أنا', 'سعيدٌ', 'لأنك', 'استطعت', 'المجيء.'] },
    { 'en': ['He', 'talks', 'too', 'fast.'], 'ar': ['يتحدث', 'بسرعة', 'كبيرة.'] },
    { 'en': ['I', "won't", 'let', 'you', 'slip', 'away', 'so', 'easily.'], 'ar': ['لن', 'أتركك', 'تفلت', 'بهذه', 'السهولة.'] },
    { 'en': ['The', 'truth', 'is', "I've", 'never', 'even', 'met', 'Tom.'], 'ar': ['الصراحة', 'أني', 'لم', 'أقابل', 'توم', 'أبدا.'] },
    { 'en': ['This', 'is', 'an', 'automatic', 'door.'], 'ar': ['هذا', 'الباب', 'أوتوماتيكي.'] },
    { 'en': ["What's", 'your', "friend's", 'name?'], 'ar': ['ما', 'اسم', 'صديقك؟'] },
    { 'en': ['We', 'kept', 'them', 'quiet.'], 'ar': ['أسكتناهم.'] },
    { 'en': ['Tom', 'said', 'he', 'saw', 'Mary', 'yesterday.'], 'ar': ['قال', 'توم', 'بأنه', 'رأى', 'ماري', 'البارحة.'] },
    { 'en': ['Is', 'this', 'your', 'car?'], 'ar': ['هل', 'هذه', 'سيارتك؟'] },
    { 'en': ['You', 'can', 'read', 'between', 'the', 'lines.'], 'ar': ['يمكنك', 'قراءة', 'ما', 'بين', 'السطور.'] },
    { 'en': ['Clean', 'your', 'room.'], 'ar': ['نظفي', 'غرفتكِ.'] },
    { 'en': ['The', 'victim', 'declined', 'to', 'press', 'charges.'], 'ar': ['رفضت', 'الضحية', 'إقامة', 'دعوى', 'قضائية.'] },
    { 'en': ['What', 'else', 'do', 'you', 'want?'], 'ar': ['ماذا', 'تريد', 'بعد؟'] },
    { 'en': ['If', 'I', 'were', 'rich,', 'I', 'would', 'buy', 'it.'], 'ar': ['لو', 'كنت', 'غنياً,', 'كنت', 'أود', 'أن', 'أشتريه.'] },
    { 'en': ["Let's", 'go.'], 'ar': ['هيا', 'بنا.'] },
    { 'en': ['Do', 'you', 'play', 'soccer?'], 'ar': ['هل', 'تعرف', 'كيف', 'تلعب', 'كرة', 'القدم؟'] },
    { 'en': ['I', 'like', 'the', 'Japanese', 'language', 'very', 'much.'], 'ar': ['تعجبني', 'اللغة', 'اليابانية', 'كثيرا.'] },
    { 'en': ['What', 'can', 'I', 'do?'], 'ar': ['ما', 'عساني', 'أن', 'أفعل؟'] },
    { 'en': ['It', 'was', 'well', 'worth', 'the', 'cost.'], 'ar': ['لقد', 'كانت', 'تستحق', 'التكلفة.'] },
    { 'en': ['Do', 'you', 'come', 'here', 'every', 'day?'], 'ar': ['هل', 'تأتي', 'إلى', 'هنا', 'كل', 'يوم؟'] },
    { 'en': ['They', 'agreed', 'to', 'elect', 'him', 'as', 'president.'], 'ar': ['إتفقوا', 'على', 'انتخابه', 'رئيساً.'] },
    { 'en': ['I', 'suggested', 'that', 'he', 'go', 'there', 'at', 'once.'], 'ar': ['أنا', 'أقترح', 'أن', 'يذهب', 'إلى', 'هناك', 'في', 'الحال.'] },
    { 'en': ['Lincoln', 'is', 'a', 'great', 'person.'], 'ar': ['لنكولن', 'رجل', 'عظيم.'] },
    { 'en': ['I', 'saw', 'you', 'driving', 'your', 'new', 'car.'], 'ar': ['رأيتك', 'راكباً', 'سيارتك', 'الجديدة.'] },
    { 'en': ["I'm", 'from', 'America.'], 'ar': ['أنا', 'من', 'أمريكا.'] },
    { 'en': ['Cool', 'it', 'down.'], 'ar': ['هدّء', 'من', 'روعك.'] },
    { 'en': ['We', 'have', 'a', 'chance.'], 'ar': ['لدينا', 'فرصة.'] },
    { 'en': ['Who', 'knows?'], 'ar': ['من', 'يعلم؟'] },
    { 'en': ["I'm", 'ready.'], 'ar': ['أنا', 'مستعدة.'] },
    { 'en': ['Many', 'businesses', 'closed', 'down.'], 'ar': ['أُغلقت', 'الكثير', 'من', 'المحالّ.'] },
    { 'en': ['We', 'live', 'in', 'a', 'three-bedroom', 'house.'], 'ar': ['نعيش', 'في', 'بيت', 'فيه', 'ثلاثة', 'غرف', 'نوم.'] },
    { 'en': ['Tom', 'and', 'Mary', 'both', 'laughed.'], 'ar': ['ضحك', 'كلُّ', 'من', 'توم', 'وماري.'] },
    { 'en': ['Is', 'this', 'your', 'umbrella?'], 'ar': ['هل', 'هذه', 'مظلتك؟'] },
    { 'en': ["I'm", 'serious.'], 'ar': ['أنا', 'جاد.'] },
    { 'en': ["Don't", 'you', 'want', 'to', 'watch', 'TV?'], 'ar': ['ألا', 'تريد', 'مشاهدة', 'التلفاز؟'] },
    { 'en': ['The', 'first', 'lesson', 'is', 'easy.'], 'ar': ['الدرس', 'الأول', 'سهل.'] },
    { 'en': ["What's", 'your', 'name?'], 'ar': ['ما', 'اسمُك؟'] },
    { 'en': ['No', 'one', 'speaks', 'with', 'me.'], 'ar': ['لا', 'أحد', 'يتكلم', 'معي.'] },
    { 'en': ['Even', 'children', 'can', 'understand', 'it.'], 'ar': ['حتى', 'الأطفال', 'يمكنهم', 'فهم', 'ذلك.'] },
    { 'en': ['We', "can't", 'sleep', 'because', 'of', 'the', 'noise.'], 'ar': ['لا', 'يمكننا', 'النوم', 'بسبب', 'الإزعاج.'] },
    { 'en': ['Please', 'help', 'me', 'cook.'], 'ar': ['ساعدني', 'في', 'الطبخ.'] },
    { 'en': ['I', 'was', 'fortunate', 'to', 'make', 'his', 'acquaintance.'], 'ar': ['من', 'حسن', 'حظي', 'أن', 'صرتُ', 'صديقه.'] },
    { 'en': ['I', 'admire', 'you', 'for', 'your', 'courage.'], 'ar': ['يعجبني', 'فيك', 'شجاعتك.'] },
    { 'en': ["Let's", 'wait', 'a', 'while.'], 'ar': ['لننتظر', 'قليلا.'] },
    { 'en': ["I'm", 'the', 'one', 'responsible', 'for', 'that.'], 'ar': ['انا', 'الوحيد', 'المسؤال', 'عن', 'هذا'] },
    { 'en': ['Many', 'students', 'took', 'part', 'in', 'the', 'contest.'], 'ar': ['شارك', 'كثير', 'من', 'الطلاب', 'في', 'المسابقة.'] },
    { 'en': ['Please', 'shut', 'the', 'door.'], 'ar': ['أغلق', 'الباب', 'من', 'فضلك.'] },
    { 'en': ['I', 'dialed', '911.'], 'ar': ['اتّصلت', 'ب\u200d', '911.'] },
    { 'en': ['Twelve', 'is', 'an', 'even', 'number.'], 'ar': ['الرقم', 'إثنا', 'عشر', 'عدد', 'زوجيّ.'] },
    { 'en': ['Tom', 'wanted', 'to', 'go', 'wherever', 'Mary', 'was', 'going.'], 'ar': ['توم', 'أراد', 'أن', 'يذهب', 'أينما', 'كانت', 'ماري', 'تذهب', '.'] },
    { 'en': ['You', 'can', 'put', 'it', 'anywhere.'], 'ar': ['بإمكانك', 'وضعها', 'في', 'أي', 'مكان.'] },
    { 'en': ['This', 'beach', 'is', 'a', 'popular', 'tourist', 'spot.'], 'ar': ['هذا', 'الشاطئ', 'مكان', 'سياحي', 'مشهور', '.'] },
    { 'en': ["You're", 'a', 'friend', 'of', "Tom's,", "aren't", 'you?'], 'ar': ['أنت', 'أحد', 'أصدقاء', 'توم،', 'صحيح؟'] },
    { 'en': ['What', 'time', 'do', 'you', 'usually', 'go', 'to', 'bed?'], 'ar': ['متى', 'تنام؟'] },
    { 'en': ["Don't", 'say', 'anything', 'without', 'thinking.'], 'ar': ['لا', 'تقولي', 'شيئًا', 'قبل', 'التفكير', 'فيه.'] },
    { 'en': ['She', 'screamed', 'for', 'help.'], 'ar': ['صرخت', 'طالبة', 'المساعدة.'] },
    { 'en': ["Tom's", 'behavior', 'is', 'unacceptable.'], 'ar': ['تصرف', 'توم', 'غير', 'مقبول.'] },
    { 'en': ['I', 'know', 'you', "don't", 'believe', 'me.'], 'ar': ['أعلم', 'بأنك', 'لا', 'تصدقني.'] },
    { 'en': ['He', 'took', 'his', 'sister', 'along.'], 'ar': ['أخذ', 'أخته', 'معه.'] },
    { 'en': ['You', 'are', 'always', 'late.'], 'ar': ['أنتَ', 'متأخر', 'دائماً.'] },
    { 'en': ['Hey,', 'get', 'off', 'my', 'bike.'], 'ar': ['أنتِ،', 'انزلي', 'عن', 'درّاجتي.'] },
    { 'en': ['We', "don't", 'need', 'to', 'do', 'this', 'every', 'day.'], 'ar': ['ليس', 'علينا', 'فعل', 'ذلك', 'يوميا.'] },
    { 'en': ['Can', 'you', 'give', 'me', 'a', 'lift', 'to', 'the', 'station?'], 'ar': ['أيمكننك', 'أن', 'توصلني', 'إلى', 'المحطة؟'] },
    { 'en': ['This', 'river', 'is', 'one', 'mile', 'across.'], 'ar': ['عرض', 'هذا', 'النهر', 'ميل.'] },
    { 'en': ['An', 'investigation', 'is', 'going', 'on.'], 'ar': ['هناك', 'تحر', 'جار.'] },
    { 'en': ['Tom', 'has', 'been', 'close.'], 'ar': ['كان', 'توم', 'قريبا.'] },
    { 'en': ["I'll", 'be', 'with', 'you', 'forever.'], 'ar': ['سأبقى', 'معك', 'إلى', 'الأبد.'] },
    { 'en': ['Now', "you're", 'going', 'too', 'far.'], 'ar': ['إنك', 'تذهب', 'بعيدا', 'الآن.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'use', 'a', 'dictionary?'], 'ar': ['هل', 'تعرف', 'كيف', 'تستخدم', 'قاموسًا؟'] },
    { 'en': ['Tom', 'is', 'waiting.'], 'ar': ['توم', 'ينتظر.'] },
    { 'en': ['He', 'sang', 'a', 'song.'], 'ar': ['غنى', 'أغنية.'] },
    { 'en': ["Let's", 'consider', 'the', 'worst', 'that', 'could', 'happen.'], 'ar': ['لِنُفَكّر', 'بأسوأ', 'ما', 'يمكن', 'أن', 'يكون', 'قد', 'حصل.'] },
    { 'en': ["It's", 'raining', 'cats', 'and', 'dogs', 'here.'], 'ar': ['إنها', 'تُمطِرُ', 'بغزارةٍ', 'هُنا.'] },
    { 'en': ['I', 'have', 'nothing', 'to', 'do', 'with', 'the', 'matter.'], 'ar': ['ليس', 'لدي', 'أيُ', 'شأنٍ', 'في', 'هذا', 'الأمر.'] },
    { 'en': ['I', 'think', "that'll", 'happen.'], 'ar': ['أظن', 'أن', 'ذلك', 'سيحصل.'] },
    { 'en': ['Can', 'you', 'pause', 'the', 'video', 'for', 'a', 'moment?'], 'ar': ['هلا', 'أوقفت', 'الفيديو', 'للحظة؟'] },
    { 'en': ['My', 'grandfather', 'loves', 'to', 'read.'], 'ar': ['جدي', 'يحب', 'القراءة.'] },
    { 'en': ['I', "can't", 'believe', 'it!'], 'ar': ['لا', 'أصدق!'] },
    { 'en': ["What's", 'my', "doctor's", 'name?'], 'ar': ['ما', 'اسم', 'طبيبي؟'] },
    { 'en': ['What', 'has', 'become', 'of', 'your', 'sister?'], 'ar': ['ما', 'حال', 'أختك', 'الآن؟'] },
    { 'en': ['Are', 'you', 'sure', 'you', "haven't", 'forgotten', 'anything?'], 'ar': ['أمتأكد', 'أنك', 'لم', 'تنسَ', 'شيئاً', 'ما؟'] },
    { 'en': ['You', 'know', "I'm", 'not', 'coming', 'back,', "don't", 'you?'], 'ar': ['أنت', 'تعرف', 'أنني', 'لن', 'أعود،', 'أليس', 'كذلك؟'] },
    { 'en': ['A', 'long', 'time', 'ago,', 'there', 'was', 'a', 'bridge', 'here.'], 'ar': ['منذ', 'زمن', 'بعيد،', 'كان', 'هناك', 'جسر', 'هنا.'] },
    { 'en': ['What', 'do', 'you', 'think', 'of', 'my', 'new', 'coat?'], 'ar': ['ما', 'رأيك', 'بمعطفي', 'الجديد؟'] },
    { 'en': ["I'm", 'a', 'new', 'student.'], 'ar': ['أنا', 'طالب', 'جديد'] },
    { 'en': ['The', 'police', 'have', 'Tom', 'under', 'surveillance.'], 'ar': ['وضعت', 'الشرطة', 'توم', 'تحت', 'المراقبة.'] },
    { 'en': ['I', 'like', 'studying', 'history.'], 'ar': ['أحب', 'دراسة', 'التاريخ.'] },
    { 'en': ['What', 'are', 'you', 'thinking', 'about', 'now?'], 'ar': ['فيمَ', 'تفكّر', 'الآن؟'] },
    { 'en': ['January', 'is', 'the', 'first', 'month', 'of', 'the', 'year.'], 'ar': ['يناير', 'هو', 'أول', 'شهر', 'في', 'السنة.'] },
    { 'en': ['Any', 'student', 'can', 'solve', 'this', 'problem.'], 'ar': ['أي', 'طالب', 'بإمكانه', 'حل', 'هذه', 'المسألة.'] },
    { 'en': ['Why', "couldn't", 'you', 'come', 'yesterday?'], 'ar': ['لماذا', 'لم', 'تستطع', 'القدوم', 'بالأمس؟'] },
    { 'en': ['Why', 'do', 'you', 'blame', 'me', 'for', 'what', 'happened?'], 'ar': ['لماذا', 'تلومني', 'على', 'ما', 'حصل؟'] },
    { 'en': ['Please', 'do', 'something.'], 'ar': ['إفعل', 'شيئاً,', 'رجاءً.'] },
    { 'en': ['I', 'was', 'given', 'a', 'new', 'jacket.'], 'ar': ['أُعطيت', 'معطفاً', 'جديداً.'] },
    { 'en': ['Has', 'anybody', 'seen', 'Tom?'], 'ar': ['هل', 'شاهدتم', 'توم؟'] },
    { 'en': ['Are', 'you', 'planning', 'to', 'help', 'them?'], 'ar': ['هل', 'تنوي', 'أن', 'تساعدهم؟'] },
    { 'en': ["Won't", 'you', 'join', 'our', 'conversation?'], 'ar': ['هلّا', 'شاركتنا', 'الحديث؟'] },
    { 'en': ['I', "don't", 'want', 'to', 'do', 'his', 'work', 'for', 'him.'], 'ar': ['لا', 'أريد', 'القيام', 'بعمله', 'نيابة', 'عنه.'] },
    { 'en': ['I', "don't", 'mind', 'staying.'], 'ar': ['لا', 'مانع', 'عندي', 'من', 'البقاء.'] },
    { 'en': ['I', 'met', 'her', 'on', 'the', 'street.'], 'ar': ['قابلتها', 'في', 'الشارع.'] },
    { 'en': ['Do', 'you', 'like', 'playing', 'volleyball?'], 'ar': ['هل', 'تحب', 'لعب', 'الكرة', 'الطائرة؟'] },
    { 'en': ['My', 'car', 'is', 'German.'], 'ar': ['سيارتي', 'ألمانية', 'الصنع.'] },
    { 'en': ['We', "can't", 'forget', 'that.'], 'ar': ['لا', 'نستطيع', 'نسيان', 'ذلك'] },
    { 'en': ["Don't", 'use', 'this', 'faucet.'], 'ar': ['لا', 'تستخدم', 'هذا', 'الصنبور'] },
    { 'en': ['Do', 'you', 'object', 'to', 'smoking?'], 'ar': ['هل', 'تعارض', 'التدخين؟'] },
    { 'en': ['Show', 'me', 'a', 'cheaper', 'one,', 'please.'], 'ar': ['أرني', 'شيئاً', 'أرخص', 'لو', 'سمحت.'] },
    { 'en': ['Correct', 'me', 'if', 'I', 'am', 'wrong.'], 'ar': ['صححني', 'إذا', 'كنت', 'مخطئاً.'] },
    { 'en': ['I', 'am', 'from', 'Shikoku.'], 'ar': ['أنا', 'من', 'شيكوكو.'] },
    { 'en': ['I', 'remember', 'meeting', 'that', 'man', 'before.'], 'ar': ['أذكر', 'أني', 'قابلت', 'ذلك', 'الرجل', 'من', 'قبل.'] },
    { 'en': ['I', 'must', 'hide.'], 'ar': ['عليّ', 'الاختباء.'] },
    { 'en': ['I', 'need', 'a', 'knife.'], 'ar': ['أحتاج', 'الى', 'سكين.'] },
    { 'en': ["I'll", 'be', 'back', 'within', 'an', 'hour.'], 'ar': ['سأعود', 'في', 'خلال', 'ساعة.'] },
    { 'en': ['I', 'am', 'at', 'home.'], 'ar': ['أنا', 'في', 'البيت'] },
    { 'en': ['The', 'landing', 'was', 'perfect.'], 'ar': ['كان', 'الهبوط', 'متقنا.'] },
    { 'en': ['I', 'retired', 'last', 'year.'], 'ar': ['تقاعدت', 'السنة', 'الماضية.'] },
    { 'en': ['My', 'cousins', 'are', 'coming', 'in', 'a', 'few', 'days.'], 'ar': ['سيأتي', 'أولاد', 'عمي', 'بعد', 'بضعة', 'أيام.'] },
    { 'en': ['Tom', "isn't", "Mary's", 'friend.'], 'ar': ['توم', 'ليس', 'صديق', 'ماريا'] },
    { 'en': ["He's", 'stupid', 'and', 'dishonest.'], 'ar': ['هو', 'غبي', 'وغير', 'صادق.'] },
    { 'en': ['Cats', 'are', 'very', 'clean', 'animals.'], 'ar': ['القطط', 'حيوانات', 'نظيفة', 'جداً.'] },
    { 'en': ['I', 'wanted', 'to', 'read', 'them.'], 'ar': ['أردت', 'قراءتهم.'] },
    { 'en': ['There', 'is', 'nothing', 'to', 'be', 'proud', 'of.'], 'ar': ['لا', 'يوجد', 'أي', 'شيء', 'مدعاة', 'للفخر.'] },
    { 'en': ['Some', 'children', 'play', 'video', 'games', 'all', 'the', 'time.'], 'ar': ['بعض', 'الأطفال', 'يلعبون', 'ألعاب', 'الفيديو', 'في', 'كل', 'الأوقات.'] },
    { 'en': ['How', 'much', 'is', 'this', 'watch?'], 'ar': ['بكم', 'الساعة؟'] },
    { 'en': ['Come', 'here.'], 'ar': ['تعال', 'إلى', 'هنا.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'he', 'is?'], 'ar': ['هل', 'تعرف', 'من', 'يكون؟'] },
    { 'en': ['I', 'come', 'from', 'Brazil.'], 'ar': ['أنا', 'من', 'البرازيل'] },
    { 'en': ['He', 'speaks', 'four', 'languages.'], 'ar': ['إنةُ', 'يتحدث', 'أربع', 'لغات.'] },
    { 'en': ["Can't", 'you', 'do', 'anything', 'to', 'help', 'me?'], 'ar': ['ألا', 'تستطيع', 'مساعدتي', 'بأي', 'شيء؟'] },
    { 'en': ['Seriously?'], 'ar': ['أأنت', 'جاد؟'] },
    { 'en': ['Congratulations!'], 'ar': ['تهانيّ!'] },
    { 'en': ["That's", 'your', 'funeral.'], 'ar': ['هذه', 'جنازتك.'] },
    { 'en': ['She', 'refused', 'my', 'offer', 'to', 'help', 'her.'], 'ar': ['رفضت', 'مساعدتي.'] },
    { 'en': ['Do', 'you', 'want', 'to', 'play', 'golf', 'or', 'not?'], 'ar': ['اتريد', 'لعب', 'الغولف', 'أم', 'لا؟'] },
    { 'en': ['I', "can't", 'find', 'my', 'pen.'], 'ar': ['لا', 'يمكنني', 'إيجاد', 'قلمي.'] },
    { 'en': ['How', 'many', 'apples', 'are', 'there?'], 'ar': ['كم', 'تفاحة؟'] },
    { 'en': ['I', "don't", 'have', 'time.'], 'ar': ['ليس', 'لدي', 'وقت.'] },
    { 'en': ['I', 'hope', 'that', 'it', 'rains', 'tomorrow.'], 'ar': ['آمل', 'أن', 'تمطر', 'غداً.'] },
    { 'en': ['Yes,', 'of', 'course.'], 'ar': ['نعم،', 'بالطبع.'] },
    { 'en': ['Tom', 'says', 'that', "he's", 'very', 'busy.'], 'ar': ['يقول', 'توم', 'بأنه', 'مشغول', 'للغاية.'] },
    { 'en': ['The', 'front', 'door', 'remained', 'locked.'], 'ar': ['ظل', 'الباب', 'مغلقاً.'] },
    { 'en': ['Tom', 'is', 'out', 'there.'], 'ar': ['توم', 'هناك.'] },
    { 'en': ['Is', 'there', 'anything', 'that', 'you', 'want', 'to', 'tell', 'me?'], 'ar': ['أثمّة', 'ما', 'تريد', 'إخباري', 'عنه؟'] },
    { 'en': ['I', "can't", 'wait', 'till', 'tomorrow.'], 'ar': ['لا', 'أستطيع', 'الانتظار', 'إلى', 'الغد.'] },
    { 'en': ["Today's", 'paper', 'says', 'that', 'a', 'typhoon', 'is', 'coming.'], 'ar': ['جريدة', 'اليوم', 'تحذّر', 'من', 'قدوم', 'عاصفة.'] },
    { 'en': ['Go', 'on.'], 'ar': ['داوم.'] },
    { 'en': ['What', 'time', 'does', 'this', 'train', 'reach', 'Yokohama?'], 'ar': ['متى', 'يصل', 'هذا', 'القطار', 'إلى', 'يوكوهاما؟'] },
    { 'en': ['Reservations', 'are', 'required.'], 'ar': ['الحجز', 'مطلوب.'] },
    { 'en': ['Tom', 'loves', 'reading.'], 'ar': ['توم', 'يحب', 'القراءة'] },
    { 'en': ['Tom', "isn't", 'a', 'French', 'teacher', 'anymore.'], 'ar': ['لم', 'يعد', 'توم', 'مدرسا', 'للفرنسية', 'بعد', 'الآن.'] },
    { 'en': ['We', 'have', 'little', 'chance', 'of', 'winning.'], 'ar': ['فرصتنا', 'في', 'الفوز', 'ضئيلة.'] },
    { 'en': ['This', 'is', 'his', 'house.'], 'ar': ['هذا', 'بيته.'] },
    { 'en': ['I', 'have', 'no', 'idea.'], 'ar': ['لا', 'أعرف\u200f.\u200f\u200f'] },
    { 'en': ['Is', 'there', 'any', 'danger?'], 'ar': ['أهناك', 'أي', 'خطر؟'] },
    { 'en': ['Were', 'you', 'with', 'anyone?'], 'ar': ['أكنت', 'مع', 'أحدهم؟'] },
    { 'en': ["Don't", 'touch', 'these.'], 'ar': ['لا', 'تلمس', 'هذه', 'الأشياء.'] },
    { 'en': ['Are', 'you', 'at', 'home?'], 'ar': ['هل', 'أنت', 'في', 'البيت؟'] },
    { 'en': ['I', 'have', 'something', 'that', 'I', 'want', 'to', 'say', 'to', 'you.'], 'ar': ['هناك', 'ما', 'أريد', 'قوله', 'لك.'] },
    { 'en': ['Tom', "isn't", 'old', 'enough', 'to', 'understand', 'this.'], 'ar': ['توم', 'ليس', 'كبيراً', 'بما', 'يكفي', 'ليفهم', 'هذا.'] },
    { 'en': ['I', "don't", 'want', 'to', 'wait', 'that', 'long.'], 'ar': ['لا', 'أريد', 'أن', 'أنتظر', 'هذا', 'أطول.'] },
    { 'en': ['Have', 'you', 'ever', 'prayed', 'in', 'a', 'mosque?'], 'ar': ['هل', 'سبق', 'لك', 'و', 'أن', 'صلّيت', 'في', 'مسجد؟'] },
    { 'en': ['I', 'chose', 'to', 'look', 'the', 'other', 'way.'], 'ar': ['فضلت', 'أن', 'أنظر', 'إلى', 'الجهة', 'الأخرى.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'your', 'Halloween', 'party.'], 'ar': ['أنا', 'أتتطلع', 'لحفلة', 'عيد', 'القديسيين', 'خاصتك.'] },
    { 'en': ['Bring', 'me', 'the', 'newspaper,', 'please.'], 'ar': ['أحضر', 'لي', 'الجريدة', 'من', 'فضلك.'] },
    { 'en': ['Whether', 'he', 'comes', 'or', 'not,', "I'll", 'go.'], 'ar': ['سأذهب', 'بغض', 'النظر', 'عن', 'مجيئه.'] },
    { 'en': ['He', 'asked', 'me', 'whether', 'I', 'was', 'busy.'], 'ar': ['سألني', 'إذا', 'كنت', 'مشغولاً.'] },
    { 'en': ["It's", 'well', 'worth', 'the', 'cost.'], 'ar': ['إنها', 'تستحق', 'التكلفة.'] },
    { 'en': ['Can', 'you', 'order', 'one', 'for', 'me?'], 'ar': ['هل', 'يمكنك', 'طلب', 'واحد', 'لي؟'] },
    { 'en': ['Is', 'that', 'your', 'room?'], 'ar': ['أهذه', 'غرفتك؟'] },
    { 'en': ['Could', 'I', 'have', 'one', 'more', 'coffee,', 'please?'], 'ar': ['فضلا،', 'أيمكنني', 'الحصول', 'على', 'كوب', 'قهوة', 'آخر؟'] },
    { 'en': ['Tom', 'is', 'trying', 'to', 'break', 'the', 'lock.'], 'ar': ['يحاول', 'توم', 'كسر', 'القفل.'] },
    { 'en': ['You', 'look', 'very', 'good.'], 'ar': ['أنتَ', 'تبدو', 'بحالة', 'جيداً', 'جداً.'] },
    { 'en': ['I', 'go', 'to', 'school', 'by', 'bus.'], 'ar': ['أذهب', 'إلى', 'المدرسة', 'بالباص.'] },
    { 'en': ['I', 'think', 'Tom', 'is', 'likely', 'to', 'like', 'this', 'movie.'], 'ar': ['اعتقد', 'من', 'المحتمل', 'بان', 'توم', 'يفضل', 'هذا', 'الفلم.'] },
    { 'en': ['You', "can't", 'speak', 'English,', 'can', 'you?'], 'ar': ['أنتِ', 'لا', 'يمكنكِ', 'تحدث', 'الإنجليزية,', 'هل', 'يمكنكِ؟'] },
    { 'en': ['I', 'have', 'books', 'that', "I've", 'reread', 'several', 'times.'], 'ar': ['لدي', 'كُتب', 'قرأتها', 'مرارا', 'وتكرارا.'] },
    { 'en': ['I', 'think', 'Tom', 'is', 'kind.'], 'ar': ['أظن', 'أن', 'توم', 'لطيف.'] },
    { 'en': ["I'm", 'going', 'to', 'leave', 'school.'], 'ar': ['سأترك', 'المدرسة.'] },
    { 'en': ['You', 'should', 'have', 'seen', 'that', 'movie.'], 'ar': ['كان', 'عليك', 'مشاهدة', 'ذلك', 'الفلم.'] },
    { 'en': ['We', 'immediately', 'became', 'friends.'], 'ar': ['أصبحنا', 'أصدقاء', 'على', 'الفور.'] },
    { 'en': ['The', 'game', "isn't", 'over', 'yet.'], 'ar': ['لم', 'تنته', 'اللعبة', 'بعد.'] },
    { 'en': ["I'm", 'late', 'for', 'class.'], 'ar': ['تأخّرتُ', 'عن', 'الحِصّة.'] },
    { 'en': ["I'm", 'ready.'], 'ar': ['أنا', 'مستعد.'] },
    { 'en': ['He', 'gave', 'his', 'life', 'for', 'his', 'country.'], 'ar': ['أعطى', 'بلده', 'حياته.'] },
    { 'en': ['Tom,', 'what', 'are', 'you', 'doing?'], 'ar': ['توم،', 'ماذا', 'تفعل؟'] },
    { 'en': ["I've", 'done', 'that', 'many', 'times.'], 'ar': ['لقد', 'فعلت', 'ذلك', 'مرات', 'عديدة.'] },
    { 'en': ['That', "won't", 'happen.'], 'ar': ['لن', 'يحصُلَ', 'ذلِك.'] },
    { 'en': ['My', 'father', 'made', 'me', 'wash', 'the', 'car.'], 'ar': ['جعلني', 'أبي', 'أغسل', 'السيارة.'] },
    { 'en': ['Tom', 'suggested', 'it.'], 'ar': ['توم', 'أقترحَ', 'هذا.'] },
    { 'en': ['I', 'always', 'walk', 'to', 'school.'], 'ar': ['أمشي', 'إلى', 'المدرسة', 'دائماً.'] },
    { 'en': ['But', 'Dad', "won't", 'like', 'it.'], 'ar': ['و', 'لكن', 'أبي', 'لن', 'يعجبه', 'ذلك.'] },
    { 'en': ['The', "door's", 'locked.'], 'ar': ['الباب', 'مقفل.'] },
    { 'en': ['It', 'tastes', 'really', 'good.'], 'ar': ['إنها', 'لذيذة.'] },
    { 'en': ['Tom', 'felt', 'something', 'crawling', 'on', 'his', 'arm.'], 'ar': ['أحس', 'توم', 'بشيء', 'يزحف', 'على', 'ذراعه.'] },
    { 'en': ['I', "didn't", 'mean', 'to', 'hurt', 'you.'], 'ar': ['لم', 'أقصد', 'إيذاءك.'] },
    { 'en': ["He's", 'happy.'], 'ar': ['هو', 'سعيد'] },
    { 'en': ['He', 'just', 'wants', 'to', 'have', 'fun.'], 'ar': ['إنه', 'يريد', 'فقط', 'الحصول', 'على', 'المتعة.'] },
    { 'en': ['Perhaps', "that's", 'true.'], 'ar': ['ربما', 'يكون', 'ذلك', 'صحيحا.'] },
    { 'en': ['I', 'went', 'there', 'yesterday.'], 'ar': ['ذهبت', 'هناك', 'البارحة.'] },
    { 'en': ['The', 'dog', 'attacked', 'the', 'little', 'boy.'], 'ar': ['تهجم', 'الكلب', 'على', 'الصغير.'] },
    { 'en': ['What', 'else', 'do', 'you', 'have', 'to', 'do?'], 'ar': ['ما', 'الأمر', 'الآخر', 'الذي', 'يجب', 'عليك', 'ان', 'تفعله؟'] },
    { 'en': ['Will', 'you', 'sell', 'your', 'house', 'to', 'him?'], 'ar': ['هل', 'ستبيعه', 'بيتك؟'] },
    { 'en': ['Her', 'feeling', 'for', 'him', 'was', 'reciprocated.'], 'ar': ['شعورها', 'له', 'كان', 'متبادلاً.'] },
    { 'en': ['Only', 'a', 'few', 'people', 'understood', 'me.'], 'ar': ['كل', 'من', 'فهمني', 'هم', 'حفنة', 'من', 'الناس.'] },
    { 'en': ['One', 'of', 'my', 'suitcases', 'is', 'missing.'], 'ar': ['ضاعت', 'إحدى', 'حقائبي.'] },
    { 'en': ['I', 'have', 'some', 'time', 'left.'], 'ar': ['بقي', 'عندي', 'بعض', 'الوقت.'] },
    { 'en': ['This', 'bridge', 'was', 'built', 'two', 'years', 'ago.'], 'ar': ['بُني', 'هذا', 'الجسر', 'منذ', 'عامين.'] },
    { 'en': ["I'm", 'an', 'eighth', 'grader.'], 'ar': ['أنا', 'في', 'الصف', 'الثامن.'] },
    { 'en': ['I', 'think', 'I', "could've", 'helped', 'Tom.'], 'ar': ['أظن', 'أنه', 'قد', 'كان', 'بمقدروي', 'أن', 'أساعد', 'توم.'] },
    { 'en': ["I'm", 'impressed,', 'Tom.'], 'ar': ['انا', 'مندهش', ',', 'توم'] },
    { 'en': ['Take', 'care!'], 'ar': ['انتبه!'] },
    { 'en': ['He', 'got', 'the', 'ball.'], 'ar': ['أصبحت', 'الكرة', 'معه.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'seeing', 'Tom.'], 'ar': ['أنا', 'أتتطلع', 'لرؤية', 'توم.'] },
    { 'en': ["I'm", 'sorry.'], 'ar': ['أنا', 'آسف'] },
    { 'en': ['Will', 'you', 'be', 'going', 'with', 'us?'], 'ar': ['أذاهب', 'أنت', 'معنا؟'] },
    { 'en': ['Stop', 'talking', 'and', 'listen', 'to', 'me.'], 'ar': ['اسكت', 'و', 'اسمعني.'] },
    { 'en': ['We', 'usually', 'have', 'breakfast', 'at', '7:30.'], 'ar': ['عادةً', 'ما', 'نفطر', 'السابعة', 'و', 'النصف', 'صباحاً.'] },
    { 'en': ['This', 'is', 'very', 'good.'], 'ar': ['هذا', 'جيد', 'جدا'] },
    { 'en': ['It', 'is', 'getting', 'colder', 'day', 'by', 'day.'], 'ar': ['يصبح', 'الجو', 'أبرد', 'يوماً', 'بعد', 'يوم.'] },
    { 'en': ["Don't", 'exaggerate.'], 'ar': ['لا', 'تُبالِغ.'] },
    { 'en': ['I', 'walk', 'every', 'day.'], 'ar': ['أتمشّى', 'كل', 'يوم.'] },
    { 'en': ["I'm", 'a', 'night', 'owl.'], 'ar': ['أنا', 'رجل', 'سهر.'] },
    { 'en': ["Aren't", 'you', 'in', 'love', 'with', 'me', 'anymore?'], 'ar': ['ألم', 'تعد', 'تحبّني؟'] },
    { 'en': ["It's", 'time', 'for', 'you', 'to', 'buy', 'a', 'new', 'car.'], 'ar': ['حان', 'الوقت', 'لأن', 'تشتري', 'سيارة', 'جديدة.'] },
    { 'en': ['The', 'train', "hasn't", 'arrived', 'yet.'], 'ar': ['لم', 'يصل', 'القطار', 'بعد.'] },
    { 'en': ['When', 'is', 'school', 'over?'], 'ar': ['متى', 'ينتهي', 'الدوام', 'المدرسيّ؟'] },
    { 'en': ['I', "don't", 'know', 'whether', 'she', 'will', 'come.'], 'ar': ['لا', 'أدري', 'إن', 'كانت', 'ستأتي.'] },
    { 'en': ["He's", 'not', 'stupid.'], 'ar': ['ليس', 'غبياً.'] },
    { 'en': ['Tom', 'left', 'me', 'a', 'message.'], 'ar': ['ترك', 'لي', 'توم', 'رسالة.'] },
    { 'en': ['How', 'long', 'do', 'you', 'play', 'tennis', 'every', 'day?'], 'ar': ['كم', 'ساعة', 'في', 'اليوم', 'تلعب', 'التنس؟'] },
    { 'en': ['I', 'called', 'for', 'help.'], 'ar': ['طلبت', 'النجدة.'] },
    { 'en': ['A', 'rabbit', 'has', 'long', 'ears.'], 'ar': ['الأرنب', 'لديه', 'أذنان', 'طويلتان.'] },
    { 'en': ['Why', 'is', 'autumn', 'called', '"fall"', 'in', 'America?'], 'ar': ['لماذا', 'يسمى', 'الخريف', '"fall"', 'في', 'أمريكا؟'] },
    { 'en': ['Welcome', 'to', 'Japan.'], 'ar': ['أهلاً', 'و', 'سهلاً', 'بك', 'في', 'اليايان.'] },
    { 'en': ['Tom', 'liked', "Mary's", 'new', 'hairstyle.'], 'ar': ['أحب', 'توم', 'تسريحة', 'شعر', 'ماري', 'الجديدة.'] },
    { 'en': ["It's", 'a', 'good', 'question.'], 'ar': ['سؤال', 'جيد.'] },
    { 'en': ['I', 'just', "don't", 'feel', 'like', 'going', 'out', 'tonight.'], 'ar': ['كل', 'ما', 'في', 'الأمر', 'أني', 'لا', 'أريد', 'الخروج', 'الليلة.'] },
    { 'en': ['I', "don't", 'have', 'any', 'money.'], 'ar': ['ليس', 'لدي', 'مال.'] },
    { 'en': ['Tom', 'looks', 'horrible.'], 'ar': ['مظهر', 'توم', 'فظيع.'] },
    { 'en': ['She', 'is', 'about', 'to', 'leave.'], 'ar': ['هي', 'على', 'وشك', 'المغادرة.'] },
    { 'en': ['She', 'caught', 'my', 'eye.'], 'ar': ['لقد', 'لفتت', 'انتباهي.'] },
    { 'en': ['You', 'must', 'have', 'the', 'report', 'done', 'by', 'Monday.'], 'ar': ['يجب', 'أن', 'تتم', 'هذا', 'التقرير', 'قبل', 'يوم', 'الأثنين.'] },
    { 'en': ["It's", 'almost', 'six.'], 'ar': ['قاربت', 'على', 'السادسة.'] },
    { 'en': ['You', 'have', 'to', 'come', 'with', 'me.'], 'ar': ['عليك', 'أن', 'تأتي', 'معي.'] },
    { 'en': ["I'm", 'sorry', 'I', "don't", 'have', 'my', 'watch', 'with', 'me.'], 'ar': ['آسف', 'ساعتي', 'ليست', 'معي.'] },
    { 'en': ['What', 'time', 'did', 'you', 'go', 'to', 'bed', 'last', 'night?'], 'ar': ['متى', 'خلدت', 'إلى', 'النوم', 'ليلة', 'الأمس؟'] },
    { 'en': ['She', 'asked', 'me', 'if', 'I', 'was', 'all', 'right.'], 'ar': ['سألتني', 'إذا', 'كنت', 'على', 'ما', 'يرام.'] },
    { 'en': ['She', "didn't", 'want', 'to', 'get', 'involved.'], 'ar': ['لم', 'ترد', 'أن', 'تتدخل.'] },
    { 'en': ["You're", 'a', 'coward!'], 'ar': ['أنتَ', 'جبان!'] },
    { 'en': ['Is', 'the', 'bath', 'ready?'], 'ar': ['هل', 'الحمّام', 'جاهز؟'] },
    { 'en': ['I', 'forgot', 'to', 'buy', 'sugar.'], 'ar': ['نسيتُ', 'شراء', 'السكّر.'] },
    { 'en': ['She', 'gave', 'me', 'a', 'present.'], 'ar': ['أعطتني', 'هدية.'] },
    { 'en': ['Tom', 'thinks', 'the', 'same', 'way.'], 'ar': ['يفكّر', 'توم', 'بنفس', 'الطريقة.'] },
    { 'en': ['She', 'dived', 'into', 'the', 'swimming', 'pool.'], 'ar': ['غطست', 'في', 'المسبح.'] },
    { 'en': ['There', 'was', 'no', 'furniture', 'in', 'that', 'room.'], 'ar': ['كانت', 'الغرفة', 'خالية', 'من', 'الأثاث.'] },
    { 'en': ['I', 'like', 'rice.'], 'ar': ['أحب', 'الرز'] },
    { 'en': ["He's", 'over', 'thirty.'], 'ar': ['هو', 'تجاوز', 'الثلاثين.'] },
    { 'en': ["That's", 'wet.'], 'ar': ['ذاك', 'مبلل.'] },
    { 'en': ['These', 'are', 'our', 'books.'], 'ar': ['هذه', 'كتبنا.'] },
    { 'en': ['She', 'began', 'to', 'talk', 'to', 'the', 'dog.'], 'ar': ['أخذت', 'تكلم', 'الكلب.'] },
    { 'en': ['Where', 'is', 'your', 'school?'], 'ar': ['أين', 'مدرستك؟'] },
    { 'en': ['Tom', 'and', 'I', 'are', 'nonsmokers.'], 'ar': ['لا', 'ندخن', 'أنا', 'وتوم', 'السجائر.'] },
    { 'en': ['All', 'right.', "It's", 'a', 'deal.'], 'ar': ['حسناً', 'إتّفقنا.'] },
    { 'en': ['He', 'told', 'us', 'an', 'interesting', 'story.'], 'ar': ['حكى', 'لنا', 'قصة', 'شيقة.'] },
    { 'en': ['Who', 'are', 'you', 'talking', 'about?'], 'ar': ['عن', 'من', 'تتحدث؟'] },
    { 'en': ['It', 'is', 'an', 'endangered', 'species.'], 'ar': ['إنه', 'نوع', 'مهدد', 'بالانقراض.'] },
    { 'en': ['Are', 'you', 'sure', "you're", 'feeling', 'OK?'], 'ar': ['أمتأكد', 'من', 'أنك', 'على', 'ما', 'يرام؟'] },
    { 'en': ['Why', "don't", 'I', 'do', 'that?'], 'ar': ['لماذا', 'لا', 'أفعل', 'ذلك؟'] },
    { 'en': ['Do', 'you', 'study', 'English?'], 'ar': ['أتدرس', 'الإنجليزية؟'] },
    { 'en': ["Where's", 'the', 'money?'], 'ar': ['أين', 'المال؟'] },
    { 'en': ['He', "won't", 'come,', 'will', 'he?'], 'ar': ['لن', 'يأتي', 'أليس', 'كذلك؟'] },
    { 'en': ['I', 'am', 'pressed', 'for', 'time.'], 'ar': ['ليس', 'لديّ', 'وقت.'] },
    { 'en': ['Can', 'I', 'help', 'you?'], 'ar': ['كيف', 'لي', 'أن', 'أساعدك؟'] },
    { 'en': ["That's", 'not', 'what', 'happened', 'here.'], 'ar': ['ليس', 'هذا', 'ما', 'حدث', 'هنا.'] },
    { 'en': ['What', 'did', 'you', 'want', 'to', 'do?'], 'ar': ['ما', 'الذي', 'أردت', 'فعله؟'] },
    { 'en': ['You', 'broke', 'my', 'heart.'], 'ar': ['أنتِ', 'كسرتِ', 'قلبي.'] },
    { 'en': ['Give', 'me', 'a', 'break.'], 'ar': ['اعطني', 'مهلة.'] },
    { 'en': ['Listen', 'carefully.'], 'ar': ['إسمع', 'جيداً.'] },
    { 'en': ['I', 'was', 'just', 'here', 'yesterday.'], 'ar': ['كنت', 'هنا', 'البارحة.'] },
    { 'en': ['He', 'lives', 'in', 'a', 'world', 'of', 'fantasy.'], 'ar': ['يعيش', 'في', 'عالم', 'من', 'الخيال.'] },
    { 'en': ['Tom', 'said', 'that', 'he', 'knew', 'Mary.'], 'ar': ['توم', 'قال', 'أنه', 'يعرف', 'ماري'] },
    { 'en': ["I'll", 'be', 'absent', 'tomorrow.'], 'ar': ['سأغيب', 'غداً.'] },
    { 'en': ['There', 'is', 'a', 'basket', 'under', 'the', 'table.'], 'ar': ['توجد', 'سلة', 'تحت', 'الطاولة.'] },
    { 'en': ['You', 'should', 'sleep.'], 'ar': ['يَنْبَغِيْ', 'أَنْ', 'تَنَام.'] },
    { 'en': ['Tom', 'does', 'love', 'you.'], 'ar': ['توم', 'يحبك'] },
    { 'en': ['This', 'box', 'is', 'not', 'as', 'big', 'as', 'that', 'one.'], 'ar': ['هذا', 'الصندوق', 'ليس', 'كبيرا', 'كذاك.'] },
    { 'en': ["We've", 'arrived.'], 'ar': ['ها', 'قد', 'وصلنا.'] },
    { 'en': ['This', 'lesson', 'should', 'be', 'kept', 'in', 'mind.'], 'ar': ['يجب', 'إبقاء', 'هذا', 'الدرس', 'في', 'الحسبان.'] },
    { 'en': ['It', 'is', 'clear', 'that', 'he', 'has', 'made', 'a', 'big', 'mistake.'], 'ar': ['من', 'الواضحِ', 'أنه', 'أرتكب', 'خطأ', 'كبيراً.'] },
    { 'en': ['He', 'lives', 'in', 'Tokyo.'], 'ar': ['إنه', 'يسكن', 'في', 'طوكيو.'] },
    { 'en': ['I', "don't", 'know', 'where', 'my', 'sunglasses', 'are.'], 'ar': ['لا', 'أعرف', 'أين', 'توجد', 'نظاراتي', 'الشمسية'] },
    { 'en': ['Did', 'you', 'break', 'something?'], 'ar': ['هل', 'كسرت', 'شيئًا؟'] },
    { 'en': ['Do', 'you', 'accept', 'Visa', 'card?'], 'ar': ['أتقبلون', 'الدفع', 'ببطاقة', 'الإئتمان؟'] },
    { 'en': ['No', "one's", 'stopping', 'you.'], 'ar': ['لا', 'أحد', 'يوقفك'] },
    { 'en': ['You', 'want', 'a', 'divorce,', "don't", 'you?'], 'ar': ['أنتَ', 'تريد', 'الطلاق,', 'أليس', 'كذلك؟'] },
    { 'en': ['I', 'think', 'it', 'was', 'a', 'stupid', 'thing', 'to', 'do.'], 'ar': ['كان', 'القيام', 'بذلك', 'شيئا', 'سخيفا', 'على', 'ما', 'أعتقد.'] },
    { 'en': ["It's", 'okay', 'to', 'go.'], 'ar': ['لا', 'بأس', 'أن', 'تذهب.'] },
    { 'en': ["I'm", 'working', 'in', 'a', 'town', 'near', 'Rome.'], 'ar': ['أنا', 'أعمل', 'في', 'بلدة', 'بالقرب', 'من', 'روما.'] },
    { 'en': ['We', 'have', 'a', 'problem.'], 'ar': ['لدينا', 'مشكلة.'] },
    { 'en': ['Is', 'this', 'your', 'bike?'], 'ar': ['أتلك', 'درّاجتك؟'] },
    { 'en': ["It's", 'too', 'good', 'to', 'be', 'true.'], 'ar': ['إنه', 'أفضل', 'من', 'أن', 'يكون', 'صحيحًا.'] },
    { 'en': ["Let's", 'split', 'the', 'bill', 'four', 'ways.'], 'ar': ['دعنا', 'نقسم', 'الفاتورة', 'بأربعة', 'طرق.'] },
    { 'en': ['She', 'is', 'very', 'beautiful.'], 'ar': ['إنها', 'جميلة', 'جداً.'] },
    { 'en': ["That's", 'too', 'much.'], 'ar': ['هذا', 'كثير.'] },
    { 'en': ['My', 'cholesterol', 'levels', 'are', 'high.'], 'ar': ['معدلات', 'الكولسترول', 'في', 'دمي', 'عالية.'] },
    { 'en': ['What', 'did', 'I', 'forget', 'to', 'say?'], 'ar': ['ما', 'الذي', 'نسيتُ', 'قوله؟'] },
    { 'en': ['Give', 'me', 'the', 'book.'], 'ar': ['أعطني', 'الكتاب'] },
    { 'en': ['Cats', 'dislike', 'being', 'wet.'], 'ar': ['القطط', 'تكره', 'البلل.'] },
    { 'en': ["I'll", 'get', 'some', 'glasses.'], 'ar': ['سأحضر', 'بعض', 'النظارات', '.'] },
    { 'en': ['She', 'is', 'having', 'dinner.'], 'ar': ['إنها', 'تتناول', 'العشاء.'] },
    { 'en': ['Today', 'is', 'Saturday.'], 'ar': ['اليوم', 'هو', 'السبت'] },
    { 'en': ['I', 'am', 'going', 'to', 'study.'], 'ar': ['أنا', 'ذاهب', 'لأدرس'] },
    { 'en': ['You', 'should', 'acknowledge', 'your', 'failure.'], 'ar': ['عليك', 'أن', 'تعترف', 'بفشلك.'] },
    { 'en': ['I', 'was', 'late', 'because', 'of', 'the', 'traffic.'], 'ar': ['تأخّرتُ', 'بسبب', 'إزدحام', 'الطريق.'] },
    { 'en': ['If', 'it', 'rains', 'tomorrow,', 'we', "won't", 'go.'], 'ar': ['لن', 'نذهب', 'إن', 'أمطرت', 'غدًا.'] },
    { 'en': ['It', 'looks', 'like', "it'll", 'be', 'clearing', 'up.'], 'ar': ['يظهر', 'أن', 'الجو', 'سيصير', 'صحوًا.'] },
    { 'en': ['Did', 'you', 'leave', 'a', 'tip?'], 'ar': ['هل', 'تركت', 'إكرامية؟'] },
    { 'en': ['The', 'committee', 'consists', 'of', 'four', 'members.'], 'ar': ['تتكون', 'الهيئة', 'من', 'أربعة', 'أعضاء.'] },
    { 'en': ['Earthquakes', 'may', 'occur', 'at', 'any', 'moment.'], 'ar': ['يمكن', 'أن', 'تحدث', 'الزلازل', 'في', 'أي', 'لحظة.'] },
    { 'en': ['I', 'do', 'not', 'think', 'that', 'he', 'will', 'come.'], 'ar': ['لا', 'أعتقد', 'أنه', 'سيأتي.'] },
    { 'en': ['Do', 'you', 'remember', 'his', 'name?'], 'ar': ['هل', 'تذكر', 'اسمه؟'] },
    { 'en': ['Thanks', 'for', 'your', 'help,', 'Tom.'], 'ar': ['شكرا', 'لمساعدتك،', 'توم.'] },
    { 'en': ["I've", 'been', 'waiting', 'for', 'hours.'], 'ar': ['كنت', 'أنتظر', 'لساعات.'] },
    { 'en': ["That's", 'a', 'hard', 'question', 'to', 'answer.'], 'ar': ['ذلك', 'سؤال', 'صعبٌ', 'الإجابة', 'عليه.'] },
    { 'en': ['You', 'are', 'exaggerating', 'the', 'problem.'], 'ar': ['إنك', 'تكبر', 'المشكلة.'] },
    { 'en': ['Both', 'of', 'those', 'students', "didn't", 'pass', 'the', 'test.'], 'ar': ['كلا', 'الطالبين', 'لم', 'ينجحا', 'الإختبار.'] },
    { 'en': ["I'm", 'too', 'scared', 'to', 'go', 'there', 'by', 'myself.'], 'ar': ['أنا', 'خائف', 'جداً', 'من', 'الذهاب', 'إلى', 'هناك', 'بمفردي.'] },
    { 'en': ['Take', 'your', 'time.', "There's", 'no', 'hurry.'], 'ar': ['خذ', 'وقتك', '،', 'لست', 'في', 'عجلة', 'من', 'أمري.'] },
    { 'en': ['This', 'table', 'is', 'reserved.'], 'ar': ['هذه', 'الطاولة', 'محجوزة.'] },
    { 'en': ['He', 'can', 'drive', 'a', 'car.'], 'ar': ['بإمكانه', 'قيادة', 'السيارة.'] },
    { 'en': ['Luckily,', 'Tom', 'had', 'an', 'extra', 'umbrella.'], 'ar': ['لحسن', 'الحظ', 'وجود', 'مظلة', 'إضافية', 'مع', 'توم.'] },
    { 'en': ['She', 'grew', 'roses.'], 'ar': ['زرعت', 'ورداً.'] },
    { 'en': ['She', 'took', 'her', 'own', 'life.'], 'ar': ['قتلت', 'نفسها.'] },
    { 'en': ['We', "can't", 'leave', 'Tom', 'like', 'this.'], 'ar': ['لا', 'يمكننا', 'ترك', 'توم', 'هكذا'] },
    { 'en': ['You', 'are', 'tall,', 'but', 'he', 'is', 'still', 'taller.'], 'ar': ['أنت', 'طويل', 'و', 'لكنه', 'أطول.'] },
    { 'en': ['Is', 'that', 'your', 'mom?'], 'ar': ['هل', 'تلك', 'أمك؟'] },
    { 'en': ['You', 'look', 'happy', 'today.'], 'ar': ['تبدو', 'مسروراً', 'اليوم.'] },
    { 'en': ['You', 'can', 'take', 'either', 'book.'], 'ar': ['خذ', 'أي', 'الكتابين', 'تريد.'] },
    { 'en': ['See', 'you', 'tomorrow.'], 'ar': ['أراك', 'غداً.'] },
    { 'en': ['Take', 'his', 'car', 'keys.'], 'ar': ['خذ', 'مفاتيح', 'سيّارته.'] },
    { 'en': ["I'm", 'going', 'to', 'go.'], 'ar': ['أنا', 'ذاهب.'] },
    { 'en': ["There's", 'been', 'a', 'lot', 'of', 'rain', 'this', 'year.'], 'ar': ['أمطرت', 'بكثرة', 'هذا', 'العام.'] },
    { 'en': ['Stop', 'teasing', 'your', 'sister!'], 'ar': ['توقفي', 'عن', 'غيظ', 'أُختِك!'] },
    { 'en': ['Did', 'you', 'see', 'the', "shooter's", 'face?'], 'ar': ['هل', 'رأيت', 'وجه', 'مطلق', 'النار؟'] },
    { 'en': ["That's", 'good', 'to', 'know.'], 'ar': ['من', 'الجيد', 'معرفة', 'ذلك'] },
    { 'en': ['She', 'looked', 'as', 'if', 'she', "hadn't", 'eaten', 'in', 'days.'], 'ar': ['بدت', 'و', 'كأنها', 'لم', 'تأكل', 'منذ', 'أيام.'] },
    { 'en': ['You', 'cannot', 'be', 'serious.'], 'ar': ['لا', 'يمكن', 'أن', 'تكون', 'جاداً.'] },
    { 'en': ['I', 'have', 'no', 'time', 'to', 'do', 'my', 'homework.'], 'ar': ['ليس', 'لدي', 'وقت', 'لأنهي', 'فروضي.'] },
    { 'en': ['Tom', 'has', 'called', 'me', 'many', 'times', 'this', 'past', 'week.'], 'ar': ['اتصل', 'بي', 'توم', 'مرات', 'كثيرة', 'الأسبوع', 'الماضي.'] },
    { 'en': ['Tomorrow', 'is', 'my', 'first', 'day', 'of', 'work.'], 'ar': ['غدا', 'هو', 'أول', 'يوم', 'عمل', 'لي.'] },
    { 'en': ['It', 'was', 'an', 'unexpected', 'opportunity.'], 'ar': ['كانت', 'فرصة', 'غير', 'متوقعة.'] },
    { 'en': ["I've", 'been', 'here', 'before.'], 'ar': ['كنت', 'هنا', 'من', 'قبل.'] },
    { 'en': ["He's", 'a', 'good', 'person.'], 'ar': ['إنه', 'شخص', 'جيد'] },
    { 'en': ['One', 'failure', 'followed', 'another.'], 'ar': ['فشل', 'تلو', 'الآخر.'] },
    { 'en': ['Tell', 'me', 'how', 'to', 'solve', 'the', 'problem.'], 'ar': ['علمني', 'كيف', 'أحل', 'هذه', 'المشكلة.'] },
    { 'en': ['I', 'made', 'a', 'mistake.'], 'ar': ['ارتكبت', 'خطأً.'] },
    { 'en': ['Why', 'is', 'he', 'here?'], 'ar': ['لماذا', 'هو', 'موجود', 'هنا؟'] },
    { 'en': ["Where's", 'your', 'money?'], 'ar': ['أين', 'مالكم', '؟'] },
    { 'en': ['He', 'painted', 'the', 'ceiling', 'blue.'], 'ar': ['طلى', 'السقف', 'باللون', 'الأزرق.'] },
    { 'en': ["It's", 'for', 'tourists.'], 'ar': ['انها', 'للسياح.'] },
    { 'en': ['I', 'saw', 'it', 'with', 'my', 'own', 'eyes.'], 'ar': ['رأيت', 'ذلك', 'بأم', 'عيني.'] },
    { 'en': ['I', "don't", 'understand', 'it.'], 'ar': ['أنا', 'لا', 'أفهمه'] },
    { 'en': ['He', 'went', 'out', 'in', 'a', 'hurry.'], 'ar': ['خرج', 'مسرعا.'] },
    { 'en': ['I', 'thought', 'we', "weren't", 'going', 'to', 'go', 'there.'], 'ar': ['ظننت', 'أننا', 'لن', 'نذهب', 'إلى', 'هناك.'] },
    { 'en': ['Read', 'it', 'once', 'more,', 'please.'], 'ar': ['إقرأها', 'مرة', 'أخرى', 'من', 'فضلك.'] },
    { 'en': ['Hurry!'], 'ar': ['تعجّل!'] },
    { 'en': ['He', 'threw', 'the', 'ball.'], 'ar': ['رمى', 'الكرة.'] },
    { 'en': ["It's", 'very', 'good.'], 'ar': ['هذا', 'جيد', 'جدا'] },
    { 'en': ['I', 'am', 'from', 'Columbia.'], 'ar': ['أنا', 'من', 'كولومبيا.'] },
    { 'en': ['Whose', 'pen', 'is', 'this?'], 'ar': ['لمن', 'هذا', 'القلم؟'] },
    { 'en': ['What', 'did', 'you', 'do', 'with', 'that', 'camera?'], 'ar': ['ما', 'الذي', 'فعلته', 'بآلة', 'التصوير', 'تلك؟'] },
    { 'en': ["Don't", 'worry.', "I'll", 'stay', 'with', 'you.'], 'ar': ['لا', 'تقلق.', 'أنا', 'سأبقى', 'معك..'] },
    { 'en': ['I', 'can', 'jump.'], 'ar': ['يمكنني', 'القفز.'] },
    { 'en': ['I', 'gave', 'Tom', 'everything.'], 'ar': ['أعطيت', 'توم', 'كل', 'شيء.'] },
    { 'en': ['Tom', 'has', 'decided', 'to', 'sell', 'his', 'house.'], 'ar': ['قرر', 'توم', 'أن', 'يبيع', 'منزله.'] },
    { 'en': ["We're", 'no', 'longer', 'living', 'in', 'the', 'stone', 'age.'], 'ar': ['لم', 'نعد', 'نعيش', 'في', 'العصر', 'الحجري.'] },
    { 'en': ['I', 'need', 'you.'], 'ar': ['أحتاج', 'إليك.'] },
    { 'en': ['The', 'boy', 'came', 'running.'], 'ar': ['أتى', 'الولد', 'راكضاً.'] },
    { 'en': ['Tom', 'must', 'be', 'having', 'fun.'], 'ar': ['لا', 'شك', 'أن', 'توم', 'يستمتع.'] },
    { 'en': ['I', 'have', 'a', 'weird', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جار', 'غريب.'] },
    { 'en': ['He', 'told', 'me', 'not', 'to', 'drive', 'too', 'fast.'], 'ar': ['أخبرني', 'ألّا', 'أقود', 'بسرعة', 'عالية.'] },
    { 'en': ['Do', 'not', 'interfere!'], 'ar': ['لا', 'تتدخل!'] },
    { 'en': ['He', 'forgot', 'to', 'turn', 'off', 'the', 'light.'], 'ar': ['نسي', 'أن', 'يطفئ', 'النور.'] },
    { 'en': ['How', 'is', 'it', 'going', 'in', 'the', 'fish', 'market?'], 'ar': ['كيف', 'الأحوال', 'في', 'سوق', 'السمك؟'] },
    { 'en': ['Now', "let's", 'begin', 'the', 'game.'], 'ar': ['هيا', 'لنبدأ', 'اللعب', 'الآن.'] },
    { 'en': ["Let's", 'get', 'a', 'cab.'], 'ar': ['دعنا', 'نحصل', 'على', 'سيارة', 'أجرة.'] },
    { 'en': ['Now,', "I'll", 'take', 'your', 'temperature.'], 'ar': ['سأقيس', 'حرارتك', 'الآن.'] },
    { 'en': ['My', 'father', 'bought', 'this', 'hat', 'for', 'me.'], 'ar': ['اشترى', 'لي', 'أبي', 'هذه', 'القبعة.'] },
    { 'en': ['Tom', 'is', 'better', 'than', 'us.'], 'ar': ['توم', 'أفضل', 'منا.'] },
    { 'en': ['He', 'just', 'missed', 'the', 'bus.'], 'ar': ['فاتته', 'الحافلة', 'بثوان', 'قليلة.'] },
    { 'en': ['Can', 'you', 'read', 'that', 'sign', 'ahead', 'of', 'us?'], 'ar': ['أيمكنك', 'قراءة', 'اللافتة', 'التي', 'أمامنا؟'] },
    { 'en': ['Where', 'are', 'they', 'going?'], 'ar': ['أين', 'هم', 'ذاهبون؟'] },
    { 'en': ['Stop', 'pulling', 'my', 'hair!'], 'ar': ['توقّف', 'عن', 'شدّ', 'شعري'] },
    { 'en': ['I', 'know', 'what', 'Tom', 'did', 'to', 'Mary.'], 'ar': ['أعلم', 'ماذا', 'فعل', 'توم', 'بماري.'] },
    { 'en': ['I', "won't", 'harm', 'you.'], 'ar': ['لن', 'أؤذيك.'] },
    { 'en': ['Here', 'comes', 'the', 'bus.'], 'ar': ['ها', 'قد', 'وصل', 'الباص.'] },
    { 'en': ["I'm", 'not', 'surprised.'], 'ar': ['أنا', 'غير', 'متفاجئة.'] },
    { 'en': ['He', 'is', 'a', 'beginner.'], 'ar': ['إنهُ', 'مبتدىء.'] },
    { 'en': ['That', 'was', 'fun.'], 'ar': ['كان', 'ذلك', 'ممتعًا.'] },
    { 'en': ["Let's", 'be', 'honest', 'with', 'each', 'other.'], 'ar': ['دعونا', 'نكون', 'صادقين', 'معَ', 'بعض.'] },
    { 'en': ['Here', 'is', 'a', 'book.'], 'ar': ['هنا', 'كتاب.'] },
    { 'en': ['You', 'have', 'a', 'big', 'nose.'], 'ar': ['أنتِ', 'لديكِ', 'أنف', 'كبير.'] },
    { 'en': ['Nothing', 'is', 'written', 'on', 'the', 'helicopter.'], 'ar': ['لم', 'يكتب', 'شيء', 'على', 'المروحية.'] },
    { 'en': ['We', 'are', 'facing', 'a', 'violent', 'crisis.'], 'ar': ['نحن', 'نواجه', 'كارثة', 'كبيرة.'] },
    { 'en': ['Turn', 'on', 'the', 'light,', 'please.'], 'ar': ['أشعل', 'الضوء', 'من', 'فضلك.'] },
    { 'en': ['Stand', 'at', 'ease!'], 'ar': ['استرح.'] },
    { 'en': ['OK.', 'I', 'agree.'], 'ar': ['حسناً.', 'أنا', 'موافق.'] },
    { 'en': ["I'm", 'nothing', 'without', 'you.'], 'ar': ['أنا', 'لا', 'شئ', 'بدونك.'] },
    { 'en': ['Why', 'is', 'Tom', 'even', 'here?'], 'ar': ['مالداعي', 'لوجود', 'توم', 'هنا', 'أصلا؟'] },
    { 'en': ["That's", 'the', 'fastest', 'train', 'in', 'the', 'world.'], 'ar': ['هذا', 'أسرع', 'قطار', 'في', 'العالم.'] },
    { 'en': ['I', "don't", 'eat', 'fruit.'], 'ar': ['لا', 'آكل', 'الفواكه.'] },
    { 'en': ['Whose', 'books', 'are', 'those?'], 'ar': ['لمن', 'هذه', 'الكتب؟'] },
    { 'en': ['He', 'is', 'past', 'forty.'], 'ar': ['هو', 'تجاوز', 'الأربعين.'] },
    { 'en': ['Italy', 'is', 'a', 'very', 'beautiful', 'country.'], 'ar': ['إيطاليا', 'بلد', 'جميل', 'جدا.'] },
    { 'en': ["She's", 'my', 'older', 'sister.'], 'ar': ['إنها', 'أختي', 'الكبرى.'] },
    { 'en': ['Everything', 'was', 'better', 'in', 'the', 'past.'], 'ar': ['كل', 'شيء', 'كان', 'أفضل', 'في', 'الماضي.'] },
    { 'en': ['Tom', 'refused', 'to', 'talk', 'about', 'it.'], 'ar': ['رفض', 'توم', 'التحدث', 'عن', 'ذلك'] },
    { 'en': ['New', 'York', 'is', 'a', 'big', 'city.'], 'ar': ['نيويورك', 'مدينة', 'كبيرة.'] },
    { 'en': ['We', 'lost', 'the', 'game.'], 'ar': ['خسرنا', 'تلك', 'المباراة.'] },
    { 'en': ['I', 'like', 'your', 'plan.'], 'ar': ['تعجبني', 'خطتك.'] },
    { 'en': ['He', 'catches', 'colds', 'easily.'], 'ar': ['إنه', 'يصاب', 'بالزكام', 'بسهولة.'] },
    { 'en': ['Tom', 'ordered', 'something', 'to', 'eat.'], 'ar': ['طلب', 'توم', 'شيء', 'للأكل'] },
    { 'en': ['Did', 'you', 'meet', 'her?'], 'ar': ['هل', 'قابلتها؟'] },
    { 'en': ['She’s', 'a', 'doctor', 'now.'], 'ar': ['هي', 'طبيبة', 'الآن.'] },
    { 'en': ['One', 'day', 'he', 'killed', 'himself.'], 'ar': ['وفي', 'يوم', 'من', 'الأيام', '،', 'انتحر.'] },
    { 'en': ['Do', 'you', 'think', "it's", 'going', 'to', 'snow?'], 'ar': ['أتظنها', 'ستُثلج؟'] },
    { 'en': ['Tom', 'is', 'tall', 'and', 'strong.'], 'ar': ['توم', 'طويلٌ', 'وقويّ.'] },
    { 'en': ['Above', 'all', 'things,', 'we', 'must', 'not', 'be', 'selfish.'], 'ar': ['قبل', 'كل', 'شيء،', 'يجب', 'ألا', 'نكون', 'أنانيين.'] },
    { 'en': ['Excuse', 'me.'], 'ar': ['عفواً.'] },
    { 'en': ["I'll", 'need', 'some', 'tools.'], 'ar': ['سأحتاج', 'بعض', 'الأدوات.'] },
    { 'en': ['Most', 'Englishmen', 'are', 'conservative.'], 'ar': ['الإنجليز', 'أكثرهم', 'محافظين.'] },
    { 'en': ['Why', "don't", 'we', 'go', 'home?'], 'ar': ['لماذا', 'لا', 'نذهب', 'إلى', 'البيت؟'] },
    { 'en': ['I', 'thought', 'you', "didn't", 'believe', 'in', 'ghosts.'], 'ar': ['ظننت', 'أنك', 'لا', 'تؤمن', 'بوجود', 'الأشباح.'] },
    { 'en': ['My', 'father', 'gives', 'my', 'mother', 'all', 'of', 'his', 'salary.'], 'ar': ['أبي', 'يعطي', 'أمي', 'كل', 'راتبه', 'الشهري.'] },
    { 'en': ["I'm", 'three', 'years', 'younger', 'than', 'you.'], 'ar': ['أنا', 'أصغر', 'منك', 'بثلاث', 'سنين.'] },
    { 'en': ['I', 'want', 'a', 'cup', 'of', 'tea.'], 'ar': ['أريد', 'فنجاناً', 'من', 'الشاي.'] },
    { 'en': ["I'm", 'sure', 'that', 'Tom', 'will', 'do', 'that.'], 'ar': ['متأكد', 'بأن', 'توم', 'سيفعل', 'ذلك.'] },
    { 'en': ['My', 'sister', 'and', 'I', 'went', 'to', 'the', 'castle.'], 'ar': ['ذهبت', 'مع', 'أختي', 'إلى', 'القلعة.'] },
    { 'en': ['Perhaps', 'it', 'will', 'snow', 'tomorrow.'], 'ar': ['من', 'الممكن', 'أن', 'تثلج', 'غداً.'] },
    { 'en': ["I'm", 'not', 'used', 'to', 'this', 'kind', 'of', 'work.'], 'ar': ['لست', 'معتادا', 'على', 'هكذا', 'عمل.'] },
    { 'en': ["You'd", 'better', 'start', 'as', 'soon', 'as', 'possible.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تبدأ', 'في', 'أقرب', 'فرصة', 'ممكنة.'] },
    { 'en': ['I', "don't", 'have', 'the', 'time', 'to', 'read', 'that', 'book.'], 'ar': ['لا', 'أملك', 'الوقت', 'الكافي', 'لقراءة', 'هذا', 'الكتاب'] },
    { 'en': ["Where's", 'your', 'sister?'], 'ar': ['أين', 'أختك؟'] },
    { 'en': ['Give', 'it', 'to', 'him.'], 'ar': ['أعطه', 'إياه.'] },
    { 'en': ['You', 'make', 'me', 'happy.'], 'ar': ['أنت', 'تجعلني', 'سعيداً.'] },
    { 'en': ['What', 'do', 'you', 'do', 'for', 'a', 'living?'], 'ar': ['ما', 'مهنتك؟'] },
    { 'en': ['What', 'color', 'is', 'it?'], 'ar': ['ما', 'لونه؟'] },
    { 'en': ['The', 'book', 'is', 'red.'], 'ar': ['الكتاب', 'أحمر.'] },
    { 'en': ['Mary', 'came', 'in.'], 'ar': ['أتت', 'ماري'] },
    { 'en': ['Could', 'we', 'have', 'a', 'fork?'], 'ar': ['أيمكننا', 'الحصول', 'على', 'شوكة؟'] },
    { 'en': ['Look', 'at', 'my', 'new', 'car.'], 'ar': ['انظر', 'إلى', 'سيارتي', 'الجديدة'] },
    { 'en': ['Many', 'thanks.'], 'ar': ['شكراً', 'جزيلاً.'] },
    { 'en': ['Tom', "isn't", 'yet', 'able', 'to', 'write', 'his', 'own', 'name.'], 'ar': ['ليس', 'بمقدور', 'توم', 'كتابة', 'اسمه', 'بعد.'] },
    { 'en': ['The', 'castle', 'is', 'beautiful.'], 'ar': ['القلعة', 'جميلة.'] },
    { 'en': ["I'm", 'only', 'a', 'customer.'], 'ar': ['لست', 'إلا', 'زبون.'] },
    { 'en': ['The', 'dispute', 'was', 'finally', 'settled.'], 'ar': ['في', 'النهاية', 'حل', 'الخلاف.'] },
    { 'en': ['Nobody', 'answered', 'the', 'telephone.'], 'ar': ['لم', 'يرد', 'أحد', 'على', 'الهاتف.'] },
    { 'en': ['I', 'got', 'on', 'the', 'wrong', 'train.'], 'ar': ['ركبت', 'القطار', 'الخطأ.'] },
    { 'en': ['Above', 'all,', 'watch', 'your', 'diet.'], 'ar': ['أهم', 'شيء', '،', 'إهتم', 'بنظامك', 'الغذائي.'] },
    { 'en': ['Always', 'obey', 'your', 'father.'], 'ar': ['أطع', 'أباك', 'دوماً.'] },
    { 'en': ['What', 'were', 'you', 'doing', 'at', 'that', 'time?'], 'ar': ['ما', 'الذي', 'كنت', 'تفعله', 'آن', 'ذاك؟'] },
    { 'en': ['I', 'would', 'like', 'a', 'tourist', 'visa.'], 'ar': ['اود', 'الحصول', 'على', 'تأشيرة', 'سياحية', '.'] },
    { 'en': ['May', 'I', 'come', 'and', 'see', 'you', 'tomorrow?'], 'ar': ['هل', 'لي', 'أن', 'آتي', 'و', 'أقابلك', 'غداً؟'] },
    { 'en': ['I', 'am', 'memorizing', 'the', 'national', 'anthem.'], 'ar': ['انا', 'احفظ', 'النشيد', 'الوطني.'] },
    { 'en': ['Wake', 'up!'], 'ar': ['استيقظ!'] },
    { 'en': ["I'm", 'not', 'interested', 'in', 'doing', 'that.'], 'ar': ['لست', 'مهتمًّا', 'بفعل', 'ذلك.'] },
    { 'en': ["That's", 'good', 'news.'], 'ar': ['هذه', 'أخبار', 'طيبة.'] },
    { 'en': ['I', 'never', 'could', 'say', 'no', 'to', 'you.'], 'ar': ['انني', 'لن', 'اقول', 'لك', 'لا', 'مطلقا'] },
    { 'en': ['I', 'left', 'my', 'card', 'at', 'home.'], 'ar': ['تركت', 'بطاقتي', 'في', 'المنزل.'] },
    { 'en': ['This', 'idea', 'is', 'not', 'rational.'], 'ar': ['هذه', 'الفكرة', 'ليست', 'منطقية.'] },
    { 'en': ["Let's", 'agree', 'to', 'disagree.'], 'ar': ['فالنتفق', 'على', 'أن', 'نختلف.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Kyoto.'], 'ar': ['أريد', 'أن', 'أذهب', 'إلى', 'كيوتو.'] },
    { 'en': ['Thank', 'you', 'very', 'much,', 'doctor.'], 'ar': ['شكراً', 'جزيلاً', 'لك', 'يا', 'دكتور.'] },
    { 'en': ['These', 'are', 'birds.'], 'ar': ['هذه', 'عصافير.'] },
    { 'en': ['Get', 'out.'], 'ar': ['اخرج!'] },
    { 'en': ['Why', 'are', 'you', 'alone?'], 'ar': ['لم', 'أنت', 'وحدك؟'] },
    { 'en': ['Your', 'glasses', 'fell', 'on', 'the', 'floor.'], 'ar': ['وقعت', 'نظارتك', 'على', 'الأرض.'] },
    { 'en': ['Is', 'this', 'your', 'first', 'visit?'], 'ar': ['هل', 'هذه', 'أول', 'زيارة', 'لك؟'] },
    { 'en': ['He', 'is', 'a', 'man', 'of', 'wit.'], 'ar': ['هو', 'رجل', 'حكمة.'] },
    { 'en': ['Tom', "doesn't", 'trust', 'Mary', 'anymore.'], 'ar': ['توم', 'لا', 'يثق', 'بماري', 'بعد', 'الآن.'] },
    { 'en': ['Do', 'you', 'understand', 'me?'], 'ar': ['هل', 'تفهمني؟'] },
    { 'en': ["I'll", 'go', 'if', 'Tom', 'goes.'], 'ar': ['سأذهب', 'إن', 'ذهب', 'توم.'] },
    { 'en': ['Tom', 'smiled.'], 'ar': ['توم', 'إبتسم.'] },
    { 'en': ['Which', 'car', 'is', "Tom's?"], 'ar': ['أيّها', 'سيارة', 'توم؟'] },
    { 'en': ['He', 'was', 'able', 'to', 'build', 'a', 'small', 'house.'], 'ar': ['استطاع', 'أن', 'يبني', 'بيتاً', 'صغيراً.'] },
    { 'en': ['I', 'want', 'a', 'new', 'computer.'], 'ar': ['أريد', 'حاسوبا', 'جديدا.'] },
    { 'en': ['I', "don't", 'know', 'if', 'I', 'have', 'the', 'time.'], 'ar': ['لا', 'أعلم', 'إن', 'كان', 'لدي', 'ما', 'يكفي', 'من', 'الوقت.'] },
    { 'en': ["I've", 'always', 'trusted', 'Tom.'], 'ar': ['انا', 'دائما', 'اثق', 'في', 'توم'] },
    { 'en': ["Don't", 'touch', 'me!'], 'ar': ['لا', 'تلمسني.'] },
    { 'en': ['Can', 'I', 'go', 'home', 'now?'], 'ar': ['أيمكنني', 'الذهاب', 'إلى', 'البيت', 'الآن؟'] },
    { 'en': ['Do', 'you', 'have', 'your', "driver's", 'license?'], 'ar': ['هل', 'رخصتك', 'معك؟'] },
    { 'en': ['I', 'remember', 'mailing', 'the', 'letter.'], 'ar': ['أذكر', 'أني', 'بعثت', 'الرسالة', 'بالبريد.'] },
    { 'en': ["I'll", 'bring', 'it', 'to', 'you', 'tomorrow.'], 'ar': ['سأجلبها', 'لك', 'غدا.'] },
    { 'en': ['It', 'was', 'the', 'right', 'decision.'], 'ar': ['لقد', 'كان', 'قرارا', 'صحيحا.'] },
    { 'en': ["It's", 'fun', 'to', 'speak', 'in', 'English.'], 'ar': ['إن', 'التحدث', 'بالإنجليزية', 'أمر', 'ممتع.'] },
    { 'en': ['She', 'took', 'off', 'the', 'lid', 'of', 'the', 'box.'], 'ar': ['أزاحت', 'غطاء', 'الصندوق.'] },
    { 'en': ['He', 'is', 'poor,', 'but', 'happy.'], 'ar': ['إنه', 'سعيد', 'رغم', 'فقره.'] },
    { 'en': ['I', 'first', 'used', 'a', 'computer', 'about', '40', 'years', 'ago.'], 'ar': ['أول', 'استخدام', 'لي', 'للحاسوب', 'كان', 'منذ', '40', 'سنة', 'خلت.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'get', 'to', "Tom's", 'house?'], 'ar': ['هل', 'تعرف', 'كيف', 'أصل', 'إلى', 'منزل', 'توم؟'] },
    { 'en': ['I', 'thought', 'she', 'was', 'cute.'], 'ar': ['رأيي', 'أنها', 'جميلة.'] },
    { 'en': ["He's", 'so', 'young.'], 'ar': ['إنهُ', 'شاب', 'جداً.'] },
    { 'en': ['Is', 'your', 'watch', 'correct?'], 'ar': ['هل', 'ساعتك', 'دقيقة', 'الوقت؟'] },
    { 'en': ['I', 'was', 'born', 'in', 'Kyoto', 'in', '1980.'], 'ar': ['وُلدت', 'في', 'كيوتو', 'سنة', '١٩٨٠', 'للميلاد.'] },
    { 'en': ["We're", 'going', 'to', 'have', 'a', 'test', 'today.'], 'ar': ['سيكون', 'لدينا', 'اختبار', 'اليوم.'] },
    { 'en': ["That's", 'common', 'sense.'], 'ar': ['ذاك', 'من', 'البديهيات.'] },
    { 'en': ['I', "can't", 'believe', "Tom's", 'dead.'], 'ar': ['لا', 'أصدق', 'أن', 'توم', 'مات.'] },
    { 'en': ['I', 'will', 'be', 'glad', 'to', 'help', 'you', 'anytime.'], 'ar': ['سيسعدني', 'مساعدتك', 'أي', 'وقت', 'تحب.'] },
    { 'en': ['Your', 'room', 'is', 'cleaner', 'than', 'mine.'], 'ar': ['غرفتك', 'انظف', 'من', 'غرفتي.'] },
    { 'en': ['My', 'father', "isn't", 'reading', 'a', 'book', 'now.'], 'ar': ['أبي', 'لا', 'يقرأ', 'كتاباً', 'الآن.'] },
    { 'en': ['Tom', "doesn't", 'like', 'watching', 'baseball.'], 'ar': ['توم', 'لا', 'يحب', 'مشاهدة', 'البيسبول'] },
    { 'en': ['It', 'is', 'too', 'hot.'], 'ar': ['الجو', 'حار', 'جداً'] },
    { 'en': ['What', 'time', 'is', 'it,', 'anyway?'], 'ar': ['كم', 'الساعة', 'على', 'أي', 'حال؟'] },
    { 'en': ['We', 'elected', 'him', 'chairman.'], 'ar': ['لقد', 'انتخبناه', 'رئيساً.'] },
    { 'en': ['This', 'is', 'what', 'I', 'found', 'in', 'the', 'cave.'], 'ar': ['هذا', 'ما', 'وجدته', 'في', 'الكهف.'] },
    { 'en': ['It', "won't", 'hurt.'], 'ar': ['لن', 'يؤلم.'] },
    { 'en': ["I'm", 'married', 'and', 'I', 'have', 'children.'], 'ar': ['أنا', 'متزوج', 'و', 'لديّ', 'أطفال.'] },
    { 'en': ['This', 'is', 'a', 'pen.'], 'ar': ['هذا', 'قلم.'] },
    { 'en': ['What', 'do', 'you', 'think?'], 'ar': ['ما', 'رأيك؟'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'summer', 'vacation.'], 'ar': ['أنا', 'أتتطلع', 'لإجازة', 'الصيف.'] },
    { 'en': ['That', 'hotel', 'was', 'very', 'near', 'the', 'lake.'], 'ar': ['ذاك', 'الفندق', 'قريب', 'جداً', 'من', 'البحيرة.'] },
    { 'en': ['He', 'speaks', 'English', 'fluently.'], 'ar': ['يتحدث', 'الإنجليزية', 'بطلاقة.'] },
    { 'en': ['Tom', 'told', 'me', 'he', "wasn't", 'sick', 'anymore.'], 'ar': ['أخبرني', 'توم', 'أنه', 'لم', 'يعد', 'مريضا.'] },
    { 'en': ['Tom', 'tried', 'to', 'stop', 'it.'], 'ar': ['حاول', 'توم', 'إيقافها.'] },
    { 'en': ['This', 'is', 'your', 'key.'], 'ar': ['هذا', 'مفتاحك.'] },
    { 'en': ["He's", 'here', 'to', 'protect', 'you.'], 'ar': ['إنه', 'هنا', 'ليحميك.'] },
    { 'en': ['I', "can't", 'leave', 'you.'], 'ar': ['لا', 'أستطيع', 'أن', 'أتركك.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Italy.'], 'ar': ['أود', 'السفر', 'إلى', 'إيطاليا.'] },
    { 'en': ['Close', 'the', 'door,', 'please.'], 'ar': ['أغلق', 'الباب', 'من', 'فضلك.'] },
    { 'en': ['How', 'far', 'is', 'it', 'from', 'here?'], 'ar': ['كم', 'تبعُد', 'عن', 'هنا؟'] },
    { 'en': ['I', 'am', 'okay.'], 'ar': ['أنا', 'بخير.'] },
    { 'en': ['I', 'prefer', 'biking.'], 'ar': ['أفضّل', 'ركوب', 'الدرّاجة', 'الهوائيّة.'] },
    { 'en': ['I', 'was', 'in', 'London', 'most', 'of', 'the', 'summer.'], 'ar': ['كنت', 'في', 'لندن', 'معظم', 'الصيف.'] },
    { 'en': ['I', "can't", 'take', 'the', 'heat.'], 'ar': ['لا', 'أستطيع', 'تحمل', 'الحرارة.'] },
    { 'en': ['At', 'last,', 'we', 'arrived', 'at', 'the', 'village.'], 'ar': ['و', 'أخيراً', 'وصلنا', 'إلى', 'القرية.'] },
    { 'en': ["I'll", 'miss', 'all', 'of', 'you.'], 'ar': ['سأفتقدكم', 'جميعا.'] },
    { 'en': ['This', 'book', 'is', 'old.'], 'ar': ['هذا', 'كتاب', 'قديم.'] },
    { 'en': ["What's", 'up?'], 'ar': ['ما', 'الجديد؟'] },
    { 'en': ['I', 'studied', 'in', 'Boston', 'from', '2003', 'to', '2007.'], 'ar': ['درست', 'في', 'بوسطون', 'من', 'عام', 'ألفين', 'و', 'ثلاثة', 'إلى', 'عام', 'ألفين', 'و', 'سبعة', 'للميلاد.'] },
    { 'en': ['Mother', 'went', 'to', 'town', 'to', 'get', 'some', 'bread.'], 'ar': ['ذهبت', 'الأم', 'إلى', 'المدينة', 'لجلب', 'بعض', 'الخبز.'] },
    { 'en': ['Keep', 'children', 'away', 'from', 'medicine.'], 'ar': ['أبق', 'الأطفال', 'بعيدا', 'عن', 'الأدوية.'] },
    { 'en': ['I', 'saw', 'a', 'man', 'coming', 'toward', 'me.'], 'ar': ['رأيت', 'رجلاً', 'يتّجه', 'نحوي.'] },
    { 'en': ['Tom', 'is', 'hardworking', 'and', 'dependable.'], 'ar': ['توم', 'مجتهد', 'ويمكن', 'الإعتماد', 'عليه.'] },
    { 'en': ['We', 'are', 'all', 'suspicious', 'about', 'him.'], 'ar': ['كنا', 'جميعًا', 'نشك', 'فيه.'] },
    { 'en': ['We', 'may', 'not', 'be', 'able', 'to', 'afford', 'it.'], 'ar': ['قد', 'لا', 'نتحمل', 'سعره.'] },
    { 'en': ['This', 'is', 'her', 'house.'], 'ar': ['هذا', 'بيتها.'] },
    { 'en': ['I', 'have', 'a', 'fever.'], 'ar': ['لدي', 'حمى.'] },
    { 'en': ['Yes.', "That's", 'right.'], 'ar': ['نعم،', 'ذلك', 'صحيح.'] },
    { 'en': ["I'm", 'just', 'a', 'plain', 'old', 'office', 'worker.'], 'ar': ['أنا', 'مجرد', 'موظف', 'عادي.'] },
    { 'en': ['Tom', 'saw', 'Mary', 'do', 'that.'], 'ar': ['توم', 'رأى', 'ماري', 'تقوم', 'بذلك'] },
    { 'en': ['That', "wasn't", 'nice.'], 'ar': ['لم', 'يكن', 'هذا', 'مهذبا.'] },
    { 'en': ['I', 'have', 'a', 'dog', 'and', 'a', 'cat.'], 'ar': ['لدي', 'كلب', 'و', 'قطة.'] },
    { 'en': ['I', 'believe', 'Tom', 'is', 'right.'], 'ar': ['أنا', 'موقن', 'بأن', 'توم', 'محق.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['أنتَ', 'كيف', 'تعرف', 'هذا؟'] },
    { 'en': ['I', 'know', "you've", 'been', 'in', 'Boston.'], 'ar': ['أعرف', 'أنك', 'كنت', 'في', 'بوسطن.'] },
    { 'en': ['He', "can't", 'run', 'very', 'fast.'], 'ar': ['لا', 'يمكنه', 'الركض', 'بسرعة.'] },
    { 'en': ['We', 'wear', 'uniforms', 'at', 'our', 'school.'], 'ar': ['نحن', 'ترتدي', 'الزي', 'الرسمي', 'في', 'مدرستنا.'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'bed.'], 'ar': ['عليّ', 'أن', 'أخلد', 'إلى', 'النوم.'] },
    { 'en': ['Tom', 'looks', 'baffled.'], 'ar': ['يبدو', 'توم', 'في', 'حيرة.'] },
    { 'en': ['I', "don't", 'like', 'school.'], 'ar': ['لا', 'أحب', 'المدرسة.'] },
    { 'en': ['Your', 'shoes', 'are', 'here.'], 'ar': ['حذاءك', 'هنا.'] },
    { 'en': ['I', 'disagree', 'with', 'you.'], 'ar': ['لا', 'أوافقك', 'الرأي.'] },
    { 'en': ["What's", 'in', 'the', 'file?'], 'ar': ['ما', 'الذي', 'في', 'الملف؟'] },
    { 'en': ['What', 'do', 'you', 'see', 'in', 'the', 'picture?'], 'ar': ['ماذا', 'ترى', 'في', 'الصورة؟'] },
    { 'en': ['I', 'study', 'Korean.'], 'ar': ['أنا', 'أدرس', 'اللغة', 'الكورية.'] },
    { 'en': ['He', 'has', 'three', 'sons.'], 'ar': ['لديه', 'ثلاثة', 'أبناء.'] },
    { 'en': ['I', 'want', 'to', 'look', 'rich.'], 'ar': ['أريد', 'أن', 'أبدو', 'غنياً'] },
    { 'en': ['I', "don't", 'know', 'if', 'we', 'needed', 'that.'], 'ar': ['لا', 'أعرف', 'إن', 'كنا', 'نحتاج', 'ذلك.'] },
    { 'en': ['Are', 'you', 'angry', 'at', 'what', 'I', 'said?'], 'ar': ['هل', 'أنت', 'غاضب', 'مما', 'قلت؟'] },
    { 'en': ['The', 'cat', 'slowly', 'approached', 'the', 'mouse.'], 'ar': ['إقتربت', 'القطة', 'من', 'الفأر', 'بحذر.'] },
    { 'en': ['Why', "don't", 'you', 'listen', 'to', 'me?'], 'ar': ['لم', 'لا', 'تصغي', 'إلي؟'] },
    { 'en': ['What', 'were', 'you', 'doing?'], 'ar': ['ماذا', 'كنت', 'تفعل؟'] },
    { 'en': ['She', 'kept', 'on', 'working.'], 'ar': ['استمرت', 'في', 'العمل.'] },
    { 'en': ['I', "don't", 'know', 'him.'], 'ar': ['أنا', 'لا', 'أعرفه'] },
    { 'en': ['You', 'can', 'go', 'to', 'the', 'station', 'by', 'bus.'], 'ar': ['بإمكانك', 'الوصول', 'إلى', 'المحطة', 'عن', 'طريق', 'الحافلة.'] },
    { 'en': ['I', 'have', 'a', 'French', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جار', 'فرنسي.'] },
    { 'en': ['You', 'must', 'keep', 'quiet.'], 'ar': ['يجب', 'ان', 'تلتزم', 'الصمت'] },
    { 'en': ['You', 'can', 'always', 'count', 'on', 'Tom.'], 'ar': ['يمكنك', 'دوماً', 'الإعتماد', 'على', 'توم.'] },
    { 'en': ['This', 'shop', 'has', 'more', 'candy', 'than', 'that', 'shop.'], 'ar': ['يوجد', 'في', 'هذا', 'المتجر', 'حلوى', 'أكثر', 'من', 'المتجر', 'الآخر.'] },
    { 'en': ['The', 'road', 'is', 'icy,', 'so', 'take', 'care.'], 'ar': ['الطريق', 'متجمد،', 'لذا', 'انتبه.'] },
    { 'en': ['Mary', 'is', "Tom's", 'secretary.'], 'ar': ['ماري', 'هي', 'سكرتيرة', 'توم.'] },
    { 'en': ['I', 'was', 'just', 'mugged.'], 'ar': ['سُرقت', 'للتو.'] },
    { 'en': ["It's", 'part', 'of', 'the', 'system.'], 'ar': ['إنه', 'جزء', 'من', 'النظام.'] },
    { 'en': ['Tell', 'Tom', 'that', "I'm", 'busy.'], 'ar': ['أخبر', 'توم', 'بأني', 'مشغول.'] },
    { 'en': ['We', 'can', 'rest', 'after', 'lunch.'], 'ar': ['نستطيع', 'ان', 'نرتاح', 'بعد', 'الغداء.'] },
    { 'en': ['Is', 'that', 'a', 'picture', 'of', 'me?'], 'ar': ['هل', 'تلك', 'صورةٌ', 'لي؟'] },
    { 'en': ['Choose', 'one', 'person.'], 'ar': ['اختر', 'شخصاً', 'واحداً.'] },
    { 'en': ['It', 'is', 'up', 'to', 'you', 'to', 'decide', 'what', 'to', 'do.'], 'ar': ['القرار', 'بيدك', 'الآن.'] },
    { 'en': ['English', 'is', 'studied', 'in', 'China,', 'too.'], 'ar': ['تُدرْسُ', 'الإنجليزية', 'في', 'الصين', 'أيضًا.'] },
    { 'en': ['Where', 'are', 'you', 'from?'], 'ar': ['من', 'أين', 'أنت؟'] },
    { 'en': ['The', 'flood', 'caused', 'a', 'lot', 'of', 'damage.'], 'ar': ['تسببت', 'الفيضانات', 'بخسائر', 'فادحة.'] },
    { 'en': ['Do', 'your', 'own', 'work.'], 'ar': ['قم', 'بعملك', 'بنفسك.'] },
    { 'en': ['Someone', 'is', 'watching', 'you.'], 'ar': ['أحدهم', 'يراقبك.'] },
    { 'en': ["That's", 'the', 'place', 'where', 'I', 'used', 'to', 'work.'], 'ar': ['ذلك', 'هو', 'المكان', 'الذي', 'كنت', 'أعمل', 'فيه.'] },
    { 'en': ['I', 'just', 'want', 'to', 'have', 'fun.'], 'ar': ['أنا', 'أريد', 'فقط', 'الحصول', 'على', 'المتعة.'] },
    { 'en': ['I', "don't", 'think', "he's", 'coming.'], 'ar': ['لا', 'أظن', 'أنه', 'آتٍ.'] },
    { 'en': ['The', 'house', 'was', 'deserted.'], 'ar': ['هُجر', 'المنزل.'] },
    { 'en': ["We're", 'going', 'to', 'wait', 'until', 'Tom', 'gets', 'here.'], 'ar': ['سننتظر', 'حتى', 'يأتي', 'توم', 'هنا.'] },
    { 'en': ['They', 'live', 'there.'], 'ar': ['هم', 'يعيشون', 'هناك'] },
    { 'en': ['I', 'can', 'come', 'tomorrow.'], 'ar': ['بإمكاني', 'القدوم', 'غداً.'] },
    { 'en': ['I', 'like', 'your', 'offer.'], 'ar': ['يعجبني', 'عرضك.'] },
    { 'en': ['I', 'was', 'born', 'in', '1972.'], 'ar': ['أن', 'ولدت', 'في', 'عام', '1972.'] },
    { 'en': ['I', 'had', 'no', 'choice', 'but', 'to', 'stay', 'in', 'bed', 'all', 'day.'], 'ar': ['لم', 'يكن', 'لدي', 'خيار', 'سوی', 'الاستلقاء', 'علی', 'السرير', 'طوال', 'اليوم.'] },
    { 'en': ["Aren't", 'you', 'going', 'to', 'tell', 'Tom?'], 'ar': ['ألن', 'تخبر', 'توم؟'] },
    { 'en': ['Do', 'you', 'remember', 'what', 'she', 'was', 'like?'], 'ar': ['هل', 'تتذكر', 'كيف', 'كانت', 'تبدو؟'] },
    { 'en': ['Tom', 'is', 'always', 'out', 'of', 'money.'], 'ar': ['توم', 'دائما', 'مفلس.'] },
    { 'en': ['He', 'is', 'a', 'doctor.'], 'ar': ['إنه', 'طبيب.'] },
    { 'en': ['Do', 'you', 'remember', 'the', 'night', 'when', 'we', 'first', 'met?'], 'ar': ['هل', 'تذكر', 'الليلة', 'التي', 'تقابلنا', 'فيها', 'أول', 'مرة؟'] },
    { 'en': ['His', 'mother', 'was', 'a', 'school', 'teacher.'], 'ar': ['كانت', 'أمها', 'مدرّسة.'] },
    { 'en': ['He', 'is', 'suffering', 'from', 'a', 'cold.'], 'ar': ['يعاني', 'من', 'البرد'] },
    { 'en': ["Tom's", 'watching.'], 'ar': ['توم', 'يشاهد'] },
    { 'en': ['What', 'are', 'you', 'doing,', 'Dad?'], 'ar': ['ماذا', 'تفعل', 'يا', 'أبي؟'] },
    { 'en': ['I', 'saw', 'your', 'father.'], 'ar': ['رأيت', 'أباك'] },
    { 'en': ['Where', 'is', 'Paris?'], 'ar': ['أين', 'تقع', 'باريس؟'] },
    { 'en': ['This', 'rose', 'is', 'beautiful.'], 'ar': ['هذه', 'الوردة', 'جميلة.'] },
    { 'en': ['What', 'is', 'new?'], 'ar': ['ما', 'الجديد؟'] },
    { 'en': ["You'd", 'better', 'not', 'swim', 'if', "you've", 'just', 'eaten.'], 'ar': ['من', 'الأفضل', 'لك', 'ألا', 'تسبح', 'إذا', 'أكلت', 'للتو.'] },
    { 'en': ['Tom', 'likes', 'brunettes.'], 'ar': ['توم', 'تعجبه', 'السمراوات.'] },
    { 'en': ['You', 'are', 'very', 'brave.'], 'ar': ['إنك', 'شجاع', 'للغاية.'] },
    { 'en': ['You', 'like', 'English,', "don't", 'you?'], 'ar': ['أنت', 'تحب', 'اللغة', 'الإنجليزية', '،', 'أليس', 'كذلك؟'] },
    { 'en': ["You're", 'always', 'criticizing', 'me!'], 'ar': ['إنك', 'تذكر', 'عيوبي', 'دائماً!'] },
    { 'en': ['He', 'translated', 'the', 'verse', 'into', 'English.'], 'ar': ['هو', 'ترجم', 'الاية', 'الى', 'اللغة', 'الانكليزية', '.'] },
    { 'en': ['I', 'have', 'sisters.'], 'ar': ['لدي', 'أخوات'] },
    { 'en': ['Tom', 'is', 'a', 'good', 'listener.'], 'ar': ['إن', 'توم', 'مستمع', 'جيد'] },
    { 'en': ['I', 'know', 'his', 'name.'], 'ar': ['أعرف', 'اسمه'] },
    { 'en': ['He', 'can', 'do', 'it.'], 'ar': ['بإمكانه', 'القيام', 'بذلك.'] },
    { 'en': ['Who', 'wrote', 'the', 'letter?'], 'ar': ['من', 'كتب', 'الرسالة؟'] },
    { 'en': ['I', 'met', 'an', 'old', 'woman.'], 'ar': ['صادفت', 'سيدة', 'كبيرة', 'السن.'] },
    { 'en': ["You're", 'just', 'the', 'man', 'I', 'want', 'to', 'see.'], 'ar': ['أنت', 'فقط', 'الرجل', 'الذي', 'أريد', 'ان', 'أراه'] },
    { 'en': ['He', 'had', 'only', 'one', 'hundred', 'yen', 'with', 'him.'], 'ar': ['كان', 'معه', 'مئة', 'ين', 'فقط.'] },
    { 'en': ["You're", 'right.'], 'ar': ['أنت', 'محقّ.'] },
    { 'en': ['I', 'added', 'this', 'website', 'to', 'my', 'favorites.'], 'ar': ['لقد', 'وضعت', 'هذا', 'الموقع', 'في', 'مفضلاتي.'] },
    { 'en': ["I've", 'lost', 'my', 'ticket.'], 'ar': ['فقدت', 'تذكرتي.'] },
    { 'en': ['Tom', 'wants', 'to', 'eat', 'here', 'tonight.'], 'ar': ['توم', 'يريد', 'أن', 'يأكل', 'هنا', 'الليلة.'] },
    { 'en': ['One', 'billion', 'people', 'speak', 'English.'], 'ar': ['يبلغ', 'متحدّثي', 'اللغة', 'الإنجليزية', 'مليار', 'نسمة.'] },
    { 'en': ['Tom', 'knows', 'those', 'rules.'], 'ar': ['توم', 'يَعرِفُ', 'القوانينَ.'] },
    { 'en': ["I'll", 'be', 'here', 'all', 'day.'], 'ar': ['سأكون', 'هنا', 'طوال', 'اليوم'] },
    { 'en': ['You', 'will', 'soon', 'get', 'well.'], 'ar': ['ستتحسن', 'قريبا.'] },
    { 'en': ['Please', 'sit', 'down.'], 'ar': ['تفضل', 'بالجلوس.'] },
    { 'en': ['She', 'was', 'busy', 'with', 'the', 'housework.'], 'ar': ['كانت', 'مشغولة', 'بأعمال', 'المنزل.'] },
    { 'en': ['The', 'experiment', 'was', 'successful.'], 'ar': ['نجحت', 'التجربة.'] },
    { 'en': ['He', 'kept', 'his', 'eyes', 'fixed', 'on', 'her', 'face.'], 'ar': ['أبقى', 'عينيه', 'مسلّطةً', 'على', 'وجهها.'] },
    { 'en': ["You're", 'looking', 'down.'], 'ar': ['أنت', 'تبدو', 'مكتئباً'] },
    { 'en': ['This', 'bus', 'will', 'take', 'you', 'to', 'the', 'airport.'], 'ar': ['ستأخذك', 'هذه', 'الحافلة', 'إلى', 'المطار.'] },
    { 'en': ['She', 'asked', 'him', 'why', 'he', 'was', 'crying.'], 'ar': ['سَأَلَتهُ', 'لما', 'كان', 'يبكي.'] },
    { 'en': ['Tom', 'drove', 'the', 'car.'], 'ar': ['ركب', 'توم', 'السيارة.'] },
    { 'en': ['This', 'story', 'is', 'true.'], 'ar': ['هذه', 'قصة', 'حقيقية.'] },
    { 'en': ['You', 'are', 'a', 'good', 'student.'], 'ar': ['أنت', 'طالب', 'جيد.'] },
    { 'en': ['We', 'are', 'students.'], 'ar': ['نحن', 'طلاب'] },
    { 'en': ['Tom', 'paid', 'for', 'the', 'tickets.'], 'ar': ['دفع', 'توم', 'ثمن', 'التذاكر.'] },
    { 'en': ['That', 'kind', 'of', 'person', 'will', 'fail', 'for', 'sure.'], 'ar': ['إنسان', 'من', 'ذلك', 'النوع', 'سيفشل', 'بالتأكيد.'] },
    { 'en': ['Be', 'careful.'], 'ar': ['كن', 'حذرا.'] },
    { 'en': ['You', "haven't", 'eaten', 'anything.'], 'ar': ['لم', 'تأكل', 'شيئا.'] },
    { 'en': ['It', 'was', 'nice', 'of', 'you', 'to', 'say', 'that.'], 'ar': ['إن', 'هذا', 'للطفٌ', 'منك.'] },
    { 'en': ["Here's", 'the', 'address.'], 'ar': ['ها', 'هو', 'العنوان.'] },
    { 'en': ['The', 'earthquake', 'damaged', 'the', 'clinic.'], 'ar': ['ألحق', 'الزّلزال', 'ضررا', 'بالعيادة.'] },
    { 'en': ['Tom', 'invited', 'Mary', 'to', 'supper.'], 'ar': ['توم', 'دعا', 'ميري', 'إلى', 'العشاء.'] },
    { 'en': ['I', "don't", 'know', 'if', "I'll", 'have', 'time', 'to', 'do', 'it.'], 'ar': ['لا', 'أعلم', 'إن', 'كنت', 'سأملك', 'الوقت', 'لفعله.'] },
    { 'en': ['He', 'went', 'to', 'the', 'store.'], 'ar': ['ذهب', 'إلى', 'المتجر.'] },
    { 'en': ['I', 'just', 'wanted', 'you', 'to', 'help', 'me.'], 'ar': ['أريد', 'فقط', 'أن', 'تساعدني.'] },
    { 'en': ['Where', 'is', 'the', 'pain?'], 'ar': ['أين', 'الألم؟'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'scissors?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'مقصك؟'] },
    { 'en': ['Please', 'come', 'in.'], 'ar': ['تفضل', 'بالدخول.'] },
    { 'en': ["Don't", 'worry', 'about', 'the', 'results', 'of', 'your', 'test.'], 'ar': ['لا', 'تقلق', 'بشأن', 'نتائج', 'إختبارك.'] },
    { 'en': ["Let's", 'hear', "Tom's", 'explanation', 'first.'], 'ar': ['لنسمع', 'تفسير', 'توم', 'بدايةً.'] },
    { 'en': ['He', 'wanted', 'to', 'be', 'rich.'], 'ar': ['هو', 'اراد', 'ان', 'يكون', 'غنيا.'] },
    { 'en': ['"Trust', 'me,"', 'he', 'said.'], 'ar': ['قال:', 'ثق', 'بي.'] },
    { 'en': ["It's", 'fine', 'today.'], 'ar': ['طقس', 'اليوم', 'جميل.'] },
    { 'en': ['What', 'woke', 'you', 'up?'], 'ar': ['ما', 'الذي', 'أيقظك؟'] },
    { 'en': ['The', 'Texans', 'began', 'to', 'organize', 'their', 'own', 'army.'], 'ar': ['بدأ', 'التكساسيون', 'بتنظيم', 'جيوشهم.'] },
    { 'en': ['He', 'told', 'me', 'not', 'to', 'drive', 'too', 'fast.'], 'ar': ['نهاني', 'عن', 'القيادة', 'بسرعة.'] },
    { 'en': ['You', 'ran', 'a', 'red', 'light.'], 'ar': ['لقد', 'قطعت', 'إشارة', 'حمراء.'] },
    { 'en': ['Do', 'you', 'have', 'anything', 'to', 'say', 'regarding', 'this?'], 'ar': ['أعندك', 'أي', 'شيء', 'تريد', 'قوله', 'بخصوص', 'هذا', 'الموضوع؟'] },
    { 'en': ['I', "can't", 'trust', 'people', 'like', 'him.'], 'ar': ['لا', 'أثق', 'بأمثاله.'] },
    { 'en': ['Switzerland', 'is', 'a', 'beautiful', 'country.'], 'ar': ['سويسرا', 'بلد', 'جميل.'] },
    { 'en': ['I', "don't", 'know', 'what', 'to', 'say.'], 'ar': ['لا', 'أعرف', 'ماذا', 'أقول.'] },
    { 'en': ["It's", 'hot', 'today.'], 'ar': ['اليوم', 'حار'] },
    { 'en': ['I', 'thought', 'it', 'was', 'a', 'great', 'idea.'], 'ar': ['ظننت', 'أنّها', 'فكرة', 'رائعة.'] },
    { 'en': ['He', 'wanted', 'to', 'succeed.'], 'ar': ['أراد', 'أن', 'ينجح.'] },
    { 'en': ['Your', 'friendship', 'means', 'a', 'lot', 'to', 'me.'], 'ar': ['صداقتك', 'تعني', 'لي', 'الكثير.'] },
    { 'en': ['Why', 'did', 'you', 'quit?'], 'ar': ['لماذا', 'استقلت؟'] },
    { 'en': ['Tom', 'wished', 'he', "hadn't", 'looked.'], 'ar': ['تمنى', 'توم', 'لو', 'أنه', 'لم', 'يرى.'] },
    { 'en': ['Come', 'on!'], 'ar': ['هيا'] },
    { 'en': ['Do', 'I', 'have', 'to', 'do', 'it', 'over', 'again?'], 'ar': ['أيجب', 'علي', 'أن', 'أقوم', 'بذلك', 'مرة', 'أخرى؟'] },
    { 'en': ["It's", 'hard', 'being', 'a', 'parent.'], 'ar': ['من', 'الصعب', 'أن', 'تكون', 'والداً.'] },
    { 'en': ['Earthquakes', 'frequently', 'hit', 'Japan.'], 'ar': ['تضرب', 'الزلازل', 'اليابان', 'بكثرة.'] },
    { 'en': ['This', 'is', 'not', 'the', 'entrance.'], 'ar': ['المدخَلُ', 'ليس', 'من', 'هنا.'] },
    { 'en': ['I', 'think', 'I', 'mistakenly', 'sent', 'that', 'email', 'to', 'Tom.'], 'ar': ['أنا', 'أعتقد', 'أني', 'بطريق', 'الخطأ', 'أرسلت', 'البريد', 'الإلكتروني', 'إلى', 'توم.'] },
    { 'en': ['He', 'combined', 'two', 'ideas', 'into', 'one.'], 'ar': ['دمج', 'فكرتين', 'في', 'فكرة.'] },
    { 'en': ['He', 'promised', 'to', 'marry', 'her.'], 'ar': ['وعد', 'بأن', 'يتزوجها.'] },
    { 'en': ['I', 'remember', 'seeing', 'her', 'once', 'on', 'the', 'street.'], 'ar': ['أذكر', 'أني', 'لمحتها', 'مرة', 'على', 'الطريق.'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'America', 'someday.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'أمريكا', 'يوماً', 'من', 'الأيام.'] },
    { 'en': ['Thank', 'you', 'for', 'inviting', 'me', 'to', 'the', 'party.'], 'ar': ['شكراً', 'لدعوتكِ', 'لي', 'إلى', 'الحفلة.'] },
    { 'en': ["I'll", 'be', 'ready', 'in', 'ten', 'minutes.'], 'ar': ['سأكون', 'جاهزة', 'خلال', 'عشر', 'دقائق.'] },
    { 'en': ["There's", 'no', 'one', 'home.'], 'ar': ['لا', 'يوجد', 'أحد', 'في', 'البيت'] },
    { 'en': ['He', 'insulted', 'me', 'without', 'reason.'], 'ar': ['شتمني', 'من', 'دون', 'سبب.'] },
    { 'en': ['You', 'are', 'doing', 'very', 'well.'], 'ar': ['أنت', 'تقوم', 'بعمل', 'جيد.'] },
    { 'en': ['Who', 'died?'], 'ar': ['من', 'مات؟'] },
    { 'en': ['Which', 'is', 'heavier,', 'lead', 'or', 'gold?'], 'ar': ['أيهما', 'أثقل،', 'الذهب', 'أم', 'الرصاص؟'] },
    { 'en': ['She', 'disliked', 'her', 'husband.'], 'ar': ['لم', 'تحب', 'زوجها.'] },
    { 'en': ["That's", 'an', 'old', 'trick.'], 'ar': ['هذه', 'خدعة', 'قديمة.'] },
    { 'en': ['Please', 'keep', 'this', 'secret.'], 'ar': ['من', 'فضلك', 'أبقِ', 'ذلك', 'سراً.'] },
    { 'en': ['Let', 'me', 'in,', 'please.'], 'ar': ['اتركني', 'أدخل', 'لو', 'سمحت.'] },
    { 'en': ['Put', 'the', 'book', 'where', 'you', 'found', 'it.'], 'ar': ['أعد', 'الكتاب', 'حيث', 'وجدته.'] },
    { 'en': ["Where's", 'the', 'exit?'], 'ar': ['أين', 'المخرج؟'] },
    { 'en': ["That's", 'very', 'sweet', 'of', 'you.'], 'ar': ['هذا', 'لطف', 'منك.'] },
    { 'en': ['I', 'asked', 'him', 'not', 'to', 'drive', 'so', 'fast.'], 'ar': ['طلبت', 'منه', 'ألا', 'يقود', 'بسرعة.'] },
    { 'en': ['What', 'a', 'strong', 'wind!'], 'ar': ['يا', 'لها', 'من', 'زوبعة', 'عاصفة!'] },
    { 'en': ['See', 'you', 'then.'], 'ar': ['إذاً', 'أراك', 'لاحقاً.'] },
    { 'en': ['It', 'is', 'certainly', 'possible', 'if', 'you', 'want', 'it.'], 'ar': ['الأمر', 'ممكن', 'بالتأكيد', 'إن', 'أردت.'] },
    { 'en': ['The', 'clock', 'ticked.'], 'ar': ['تحرك', 'عقرب', 'الثواني', 'الخاص', 'بالساعة', '.'] },
    { 'en': ['She', 'has', 'a', 'soft', 'and', 'clear', 'voice.'], 'ar': ['صوتها', 'ناعم', 'و', 'واضح.'] },
    { 'en': ['Japan', 'is', 'close', 'to', 'China.'], 'ar': ['اليابان', 'قريبة', 'إلى', 'الصين.'] },
    { 'en': ['It', 'really', 'gets', 'on', 'my', 'nerves.'], 'ar': ['إن', 'ذلك', 'يغيظني', 'حقاً.'] },
    { 'en': ['She', 'became', 'a', 'doctor.'], 'ar': ['أصبحت', 'طبيبة.'] },
    { 'en': ['Show', 'me', 'another', 'watch.'], 'ar': ['أرني', 'ساعة', 'يد', 'أخرى.'] },
    { 'en': ['They', 'followed', 'you.'], 'ar': ['لقد', 'اتبعوك.'] },
    { 'en': ['I', 'may', 'have', 'to', 'call', 'you', 'later.'], 'ar': ['ربما', 'علي', 'أن', 'أتصل', 'بك', 'لاحقا.'] },
    { 'en': ['I', 'want', 'one,', 'too.'], 'ar': ['أنا', 'أريد', 'واحدة،', 'أيضا.'] },
    { 'en': ['We', 'feel', 'good', 'about', 'it.'], 'ar': ['نتفائل', 'به.'] },
    { 'en': ['She', 'killed', 'herself', 'at', 'the', 'age', 'of', 'thirty.'], 'ar': ['انتحرت', 'عن', 'عمر', 'ينازه', 'الثلاثين', 'عاماً.'] },
    { 'en': ['Tom', 'is', 'the', 'only', 'one', 'who', 'can', 'do', 'this.'], 'ar': ['توم', 'هو', 'فقط', 'من', 'يمكنه', 'فعل', 'هذا.'] },
    { 'en': ["That's", 'the', 'ugliest', 'snowman', "I've", 'ever', 'seen.'], 'ar': ['هذا', 'أبشع', 'رجل', 'ثلجي', 'رأيته', 'أبدا.'] },
    { 'en': ['Please', 'leave', 'right', 'away.'], 'ar': ['من', 'فضلك', 'غادر', 'حالاً.'] },
    { 'en': ['Are', 'you', 'sad?'], 'ar': ['هل', 'أنتِ', 'حزينة؟'] },
    { 'en': ["What's", 'the', 'airmail', 'rate?'], 'ar': ['ما', 'معدل', 'البريد', 'الجوي؟'] },
    { 'en': ['I', "can't", 'walk', 'another', 'step.'], 'ar': ['لا', 'يمكنني', 'المشي', 'خطوة', 'أخرى.'] },
    { 'en': ['I', 'have', 'to', 'study.'], 'ar': ['عليّ', 'أن', 'أدرس.'] },
    { 'en': ["They're", 'eating', 'apples.'], 'ar': ['إنهم', 'يأكلون', 'التفاح.'] },
    { 'en': ['You', 'are', 'not', 'our', 'friend.'], 'ar': ['أنت', 'لست', 'صديقنا.'] },
    { 'en': ['Would', 'you', 'prefer', 'to', 'speak', 'in', 'French?'], 'ar': ['هل', 'تُفضّل', 'التحدّث', 'بالفرنسيّةِ؟'] },
    { 'en': ['Look', 'at', 'those', 'black', 'clouds.'], 'ar': ['انظر', 'إلى', 'تلك', 'الغيوم', 'السوداء.'] },
    { 'en': ['The', 'guard', 'was', 'on', 'duty', 'all', 'night.'], 'ar': ['حرس', 'الحارس', 'الليل', 'بطوله.'] },
    { 'en': ['Has', 'something', 'happened?'], 'ar': ['هل', 'حصل', 'شيء', 'ما؟'] },
    { 'en': ['I', 'can', 'swim.'], 'ar': ['بإمكاني', 'أن', 'أسبح.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'our', 'ski', 'trip.'], 'ar': ['أنا', 'أتتطلع', 'لرحلة', 'التزلج', 'خاصتنا.'] },
    { 'en': ['Tom', "didn't", 'have', 'to', 'leave.'], 'ar': ['لم', 'يكن', 'على', 'توم', 'الذهاب.'] },
    { 'en': ["I'm", 'not', 'going', 'back.'], 'ar': ['لن', 'أعود.'] },
    { 'en': ['Is', 'this', 'book', 'yours?'], 'ar': ['أهذا', 'كتابك؟'] },
    { 'en': ['I', 'strongly', 'agree.'], 'ar': ['أوافق', 'وبشدة.'] },
    { 'en': ['What', 'is', 'the', 'price', 'of', 'this', 'cap?'], 'ar': ['ما', 'ثمن', 'هذه', 'القبّعة؟'] },
    { 'en': ["Let's", 'make', 'a', 'trade.'], 'ar': ['دعنا', 'نصنع', 'تجارة.'] },
    { 'en': ['Thanks', 'for', 'the', 'effort.'], 'ar': ['شكرا', 'على', 'جهودك.'] },
    { 'en': ["It's", 'too', 'dangerous!'], 'ar': ['إنه', 'خطير', 'للغاية.'] },
    { 'en': ['Tell', 'Tom', 'he', 'has', 'to', 'wash', 'the', 'dishes.'], 'ar': ['أخبر', 'توم', 'بأن', 'عليه', 'غسل', 'الصحون.'] },
    { 'en': ['What', 'were', 'we', 'talking', 'about?'], 'ar': ['عمّا', 'كنا', 'نتكلّم؟'] },
    { 'en': ['Not', 'every', 'question', 'deserves', 'an', 'answer.'], 'ar': ['ليس', 'كل', 'سؤال', 'يستحق', 'الاجابة.'] },
    { 'en': ['There', 'comes', 'the', 'bus.'], 'ar': ['ها', 'قد', 'أتى', 'الباص.'] },
    { 'en': ['Our', 'house', 'was', 'robbed', 'while', 'we', 'were', 'away.'], 'ar': ['سُرق', 'منزلنا', 'عندما', 'كنا', 'مسافرين.'] },
    { 'en': ["I'm", 'still', 'too', 'young', 'to', 'do', 'that.'], 'ar': ['مازلت', 'صغيرا', 'جدا', 'لافعل', 'ذلك.'] },
    { 'en': ['Perhaps', 'you', 'misunderstood.'], 'ar': ['لعلك', 'أسأت', 'الفهم.'] },
    { 'en': ["I'm", 'very', 'hungry', 'now.'], 'ar': ['إنني', 'في', 'غاية', 'الجوع', 'الآن.'] },
    { 'en': ['I', 'brush', 'my', 'teeth', 'twice', 'a', 'day.'], 'ar': ['أفرش', 'أسناني', 'مرتين', 'في', 'اليوم.'] },
    { 'en': ['Sit', 'wherever', 'you', 'like.'], 'ar': ['اجلس', 'في', 'أي', 'مكان', 'تحب.'] },
    { 'en': ['The', 'horse', 'stopped', 'and', "wouldn't", 'move.'], 'ar': ['توقف', 'الحصان', 'و', 'رفض', 'أن', 'يتحرك.'] },
    { 'en': ['His', 'condition', 'could', 'have', 'been', 'worse.'], 'ar': ['كان', 'يمكن', 'أن', 'تكون', 'حالته', 'أكثر', 'سوءً.'] },
    { 'en': ['Please', 'show', 'me', 'something', 'cheaper.'], 'ar': ['أرني', 'شيئاً', 'أرخص', 'لو', 'سمحت.'] },
    { 'en': ['This', "doesn't", 'concern', 'me.'], 'ar': ['هذا', 'الأمر', 'لا', 'يعنيني.'] },
    { 'en': ["It's", 'not', 'rocket', 'science.'], 'ar': ['ليس', 'الأمر', 'صعباً', 'إلى', 'هذه', 'الدرجة.'] },
    { 'en': ['Look', 'at', 'the', 'blackboard,', 'everyone.'], 'ar': ['انظروا', 'إلى', 'السبورة', '،', 'جميعكم.'] },
    { 'en': ['He', 'is', 'a', 'famous', 'artist.'], 'ar': ['إنه', 'رسّام', 'مشهور.'] },
    { 'en': ['At', 'times,', 'I', "can't", 'trust', 'him.'], 'ar': ['أحيانًا،', 'لا', 'أستطيع', 'الثقة', 'به.'] },
    { 'en': ['I', 'see.'], 'ar': ['انا', 'اري'] },
    { 'en': ['I', "don't", 'want', 'to', 'hear', 'another', 'word', 'about', 'it!'], 'ar': ['لا', 'أُريد', 'أن', 'أسمع', 'كلمة', 'أُخرىَ', 'عن', 'هذا!'] },
    { 'en': ['Did', 'he', 'learn', 'Japanese', 'as', 'well?'], 'ar': ['هل', 'تَعَلَمَ', 'اليابانية', 'أيضاً؟'] },
    { 'en': ["Don't", 'be', 'ridiculous.'], 'ar': ['لا', 'تكن', 'سخيفاً!'] },
    { 'en': ['I', "don't", 'think', "you'll", 'be', 'able', 'to', 'do', 'that.'], 'ar': ['لا', 'أظنك', 'قادرا', 'على', 'فعل', 'ذلك.'] },
    { 'en': ['Will', 'you', 'make', 'a', 'list', 'of', 'issues', 'to', 'discuss?'], 'ar': ['أيمكنك', 'عمل', 'قائمة', 'بالمسائل', 'التي', 'ستناقش؟'] },
    { 'en': ['My', 'sister', 'sometimes', 'makes', 'our', 'dinner.'], 'ar': ['أحياناً', 'تعد', 'أختي', 'العشاء.'] },
    { 'en': ['Come', 'on!'], 'ar': ['بالله', 'عليك!'] },
    { 'en': ['I', 'saw', 'her', 'clean', 'the', 'room.'], 'ar': ['رأيتها', 'تنظف', 'الغرفة.'] },
    { 'en': ['Smile', 'at', 'the', 'camera,', 'please!'], 'ar': ['إبتسم', 'للكاميرا', 'إذا', 'سمحت!'] },
    { 'en': ['Everybody', 'laughed.'], 'ar': ['ضحك', 'الجميع.'] },
    { 'en': ["I'm", 'trying', 'to', 'save', 'room', 'for', 'dessert.'], 'ar': ['أُحَاوِلُ', 'تَرْكَ', 'مَكَانٍ', 'لِلحَلْوَى.'] },
    { 'en': ['The', 'train', 'leaves', 'in', '5', 'minutes.'], 'ar': ['سينطلق', 'القطار', 'في', 'غضون', 'خمس', 'دقائق.'] },
    { 'en': ["Who's", 'that', 'girl', 'sitting', 'over', 'there?'], 'ar': ['من', 'تكون', 'تلك', 'الفتاة', 'الجالسة', 'هناك؟'] },
    { 'en': ["It's", 'never', 'too', 'late', 'to', 'start.'], 'ar': ['ليس', 'متأخراً', 'جداً', 'للبدء.'] },
    { 'en': ['Birds', 'sing.'], 'ar': ['الطيور', 'تغرد.'] },
    { 'en': ['I', 'have', 'a', 'TV', 'in', 'my', 'bedroom.'], 'ar': ['لدي', 'تلفاز', 'في', 'غرفة', 'نومي.'] },
    { 'en': ['I', 'think', 'we', 'can', 'help', 'each', 'other.'], 'ar': ['اعتقد', 'بأنه', 'يمكننا', 'مساعدة', 'بعضنا', 'البعض.'] },
    { 'en': ['They', 'agreed', 'on', 'a', 'price.'], 'ar': ['إتفقوا', 'على', 'سعرٍ', 'ما.'] },
    { 'en': ['His', 'son', 'was', 'expelled', 'from', 'school.'], 'ar': ['فُصِل', 'ابنه', 'من', 'المدرسة.'] },
    { 'en': ['Tom', 'and', 'Mary', 'are', 'dead.'], 'ar': ['ماتا', 'توم', 'وماري.'] },
    { 'en': ['Would', 'you', 'mind', 'standing', 'aside?'], 'ar': ['هل', 'تمانع', 'في', 'الوقوف', 'جانبًا؟'] },
    { 'en': ['Send', 'it', 'by', 'mail.'], 'ar': ['أرسله', 'بالبريد.'] },
    { 'en': ['I', 'really', "didn't", 'mean', 'to', 'hurt', 'you.'], 'ar': ['لم', 'أقصد', 'أن', 'أوذيك.'] },
    { 'en': ['He', 'decided', 'to', 'go', 'abroad.'], 'ar': ['قرر', 'أن', 'يسافر', 'إلى', 'الخارج.'] },
    { 'en': ['You', 'must', 'perform', 'your', 'duty.'], 'ar': ['عليك', 'أن', 'تقوم', 'بالواجب.'] },
    { 'en': ['He', 'has', 'a', 'car.'], 'ar': ['لديه', 'سيارة.'] },
    { 'en': ['Tom', 'could', 'explain', 'that.'], 'ar': ['يمكن', 'لتوم', 'توضيح', 'ذلك.'] },
    { 'en': ['I', 'wrote', 'three', 'letters', 'last', 'night.'], 'ar': ['كتبت', 'ثلاث', 'رسائل', 'ليلة', 'البارحة.'] },
    { 'en': ['Tom', "doesn't", 'like', 'me.'], 'ar': ['توم', 'لا', 'يحبني.'] },
    { 'en': ['I', 'just', 'want', 'a', 'straight', 'answer.', 'Nothing', 'more.'], 'ar': ['أريد', 'فقط', 'إجابة', 'صريحة،', 'لا', 'أكثر.'] },
    { 'en': ['Never', 'mind!'], 'ar': ['لا', 'عليك!'] },
    { 'en': ['He', 'ran', 'like', 'a', 'scared', 'rabbit.'], 'ar': ['هرب', 'كأرنب', 'خائف.'] },
    { 'en': ['I', 'appreciate', 'all', 'you', 'did.'], 'ar': ['أقدر', 'كل', 'ما', 'فعلته.'] },
    { 'en': ["I'm", 'getting', 'off', 'at', 'the', 'next', 'station.'], 'ar': ['سأنزل', 'عند', 'المحطة', 'القادمة.'] },
    { 'en': ['Whose', 'car', 'is', 'this?'], 'ar': ['لمن', 'هذه', 'السيارة؟'] },
    { 'en': ['It', "doesn't", 'matter.'], 'ar': ['هذا', 'لا', 'يهم.'] },
    { 'en': ['My', 'sister', 'resembles', 'my', 'grandmother.'], 'ar': ['أختي', 'تشبه', 'جدتي.'] },
    { 'en': ['We', "don't", 'know', 'the', 'truth', 'yet.'], 'ar': ['نحن', 'لا', 'نعرف', 'الحقيقة', 'بعد.'] },
    { 'en': ['This', 'dog', 'was', 'born', 'two', 'months', 'ago.'], 'ar': ['وُلد', 'الكلب', 'منذ', 'شهرين.'] },
    { 'en': ['You', 'cannot', 'swim', 'here.'], 'ar': ['لا', 'تستطيع', 'السباحة', 'هنا.'] },
    { 'en': ['Tom', "wouldn't", 'allow', 'Mary', 'to', 'do', 'that.'], 'ar': ['لم', 'يكن', 'توم', 'ليسمح', 'لماري', 'بفعل', 'ذلك.'] },
    { 'en': ['All', 'you', 'have', 'to', 'do', 'is', 'tell', 'Tom', 'to', 'leave.'], 'ar': ['كل', 'ما', 'عليك', 'فعله', 'هو', 'إخبار', 'توم', 'بأن', 'يغادر.'] },
    { 'en': ['It', 'looks', 'like', 'we', 'fell', 'into', 'a', 'trap.'], 'ar': ['يبدو', 'أننا', 'وقعنا', 'في', 'الفخ.'] },
    { 'en': ['You', 'reap', 'what', 'you', 'sow.'], 'ar': ['سوف', 'تحصد', 'ما', 'زرعت.'] },
    { 'en': ['Have', 'any', 'letters', 'arrived', 'for', 'me?'], 'ar': ['هل', 'وصلتني', 'أي', 'رسائل؟'] },
    { 'en': ["They're", 'happy.'], 'ar': ['إنهم', 'سعداء'] },
    { 'en': ['What', 'about', 'Tom?'], 'ar': ['ماذا', 'عن', '"توم"؟'] },
    { 'en': ['I', 'liked', 'my', 'teachers.'], 'ar': ['كنت', 'أحبّ', 'مدرّسّيّ.'] },
    { 'en': ['I', 'was', 'delayed', 'by', 'a', 'traffic', 'jam.'], 'ar': ['أخّرني', 'زحام', 'السير.'] },
    { 'en': ['Have', 'a', 'drink', 'with', 'me.'], 'ar': ['اشرب', 'معي', 'شيئا'] },
    { 'en': ['She', 'turned', 'down', 'his', 'invitation.'], 'ar': ['رفضت', 'دعوته.'] },
    { 'en': ['My', 'mother', 'made', 'me', 'a', 'bag.'], 'ar': ['صنعت', 'لي', 'أمي', 'حقيبة.'] },
    { 'en': ['Is', 'that', 'a', 'yes', 'or', 'no?'], 'ar': ['هل', 'ذلك', 'نعم', 'أم', 'لا؟'] },
    { 'en': ['I', 'was', 'named', 'after', 'my', 'uncle.'], 'ar': ['سُميّتُ', 'على', 'عمي.'] },
    { 'en': ['The', 'man', 'who', 'is', 'standing', 'there', 'is', 'my', 'father.'], 'ar': ['الرجل', 'الذي', 'يقف', 'هناك', 'هو', 'أبي.'] },
    { 'en': ['I', 'have', 'a', 'dictionary.'], 'ar': ['لدي', 'قاموس.'] },
    { 'en': ['Who', 'do', 'you', 'think', 'you', 'are?'], 'ar': ['من', 'تحسب', 'نفسك؟'] },
    { 'en': ['I', 'want', 'to', 'go', 'to', 'Italy.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'إيطاليا.'] },
    { 'en': ["It's", 'Monday,', 'you', 'know.'], 'ar': ['إنه', 'يوم', 'الإثنين,', 'كما', 'تعلم'] },
    { 'en': ['I', "can't", 'tolerate', 'this', 'noise', 'any', 'longer.'], 'ar': ['لا', 'أستطيع', 'تحمل', 'هذا', 'الضجيج', 'أكثر', 'من', 'ذلك.'] },
    { 'en': ['Stop', 'teasing', 'your', 'brother!'], 'ar': ['توقفي', 'عن', 'غيظ', 'أخيكِ!'] },
    { 'en': ["It's", 'up', 'to', 'you.'], 'ar': ['الأمر', 'يعود', 'إليك.'] },
    { 'en': ['Has', 'anything', 'changed?'], 'ar': ['هل', 'تغير', 'أي', 'شيء؟'] },
    { 'en': ['Your', 'gums', 'are', 'in', 'bad', 'shape.'], 'ar': ['حالة', 'لثتك', 'سيئة.'] },
    { 'en': ['You', 'must', 'study', 'much', 'harder.'], 'ar': ['يجب', 'عليك', 'أن', 'تدرس', 'بجهد', 'أكبر.'] },
    { 'en': ['I', 'congratulate', 'you', 'on', 'your', 'success.'], 'ar': ['أهنّئك', 'بمناسبة', 'نجاحك.'] },
    { 'en': ["He's", 'my', 'older', 'brother.'], 'ar': ['إنه', 'أخي', 'الأكبر.'] },
    { 'en': ['Shake', 'hands', 'with', 'him.'], 'ar': ['سلم', 'عليه.'] },
    { 'en': ['May', 'I', 'eat', 'this', 'cake?'], 'ar': ['هل', 'لي', 'أن', 'آكل', 'هذه', 'الكعكة؟'] },
    { 'en': ['Tom', 'waved.'], 'ar': ['لَوَحَ', 'توم.'] },
    { 'en': ['No', 'one', 'was', 'present.'], 'ar': ['لم', 'يكن', 'هناك', 'أحد.'] },
    { 'en': ['I', "don't", 'blame', 'them.'], 'ar': ['أنا', 'لا', 'ألومهم.'] },
    { 'en': ['That', "would've", 'been', 'fun.'], 'ar': ['لكان', 'أمرا', 'مسليا.'] },
    { 'en': ["Don't", 'forget', 'about', 'us!'], 'ar': ['لا', 'تنسنا.'] },
    { 'en': ['He', 'agreed', 'with', 'me.'], 'ar': ['لقد', 'وافقني.'] },
    { 'en': ['How', "I've", 'missed', 'you!'], 'ar': ['افتقدتك', 'كثيراً!'] },
    { 'en': ['We', 'use', 'a', 'lot', 'of', 'water', 'every', 'day.'], 'ar': ['إننا', 'نستهلك', 'الكثير', 'من', 'الماء', 'يومياً.'] },
    { 'en': ['I', 'got', 'on', 'the', 'wrong', 'bus.'], 'ar': ['ركبت', 'الباص', 'الخطأ.'] },
    { 'en': ["I've", 'finished', 'reading', 'that', 'book.'], 'ar': ['أنهيت', 'قراءة', 'ذلك', 'الكتاب.'] },
    { 'en': ["Let's", 'split.'], 'ar': ['دعنا', 'ننقسم.'] },
    { 'en': ['I', 'will', 'read', 'a', 'book.'], 'ar': ['سأقرأ', 'كتاباً.'] },
    { 'en': ["Don't", 'call', 'him', 'names.'], 'ar': ['لا', 'تَسُبّهُ.'] },
    { 'en': ['That', 'castle', 'is', 'beautiful.'], 'ar': ['هذه', 'القلعة', 'جميلة.'] },
    { 'en': ['I', 'wanted', 'to', 'show', 'them', 'my', 'appreciation.'], 'ar': ['أردت', 'أن', 'أريهم', 'تقديري'] },
    { 'en': ['I', 'like', 'to', 'eat', 'watermelon.'], 'ar': ['أحب', 'أكل', 'البطيخ.'] },
    { 'en': ['We', 'stood', 'face', 'to', 'face.'], 'ar': ['وقفنا', 'وجهاً', 'لوجه.'] },
    { 'en': ['Those', 'who', 'know', 'him', 'like', 'him.'], 'ar': ['من', 'عرفه،', 'أحبه.'] },
    { 'en': ['I', 'love', 'trying', 'to', 'do', 'new', 'things.'], 'ar': ['أنا', 'أُحب', 'أن', 'أُجرب', 'لأفعل', 'أشياء', 'جديدة.'] },
    { 'en': ['Are', 'you', 'good', 'at', 'tennis?'], 'ar': ['هل', 'أنت', 'ماهر', 'في', 'لعب', 'التنس؟'] },
    { 'en': ['I', "don't", 'know', 'his', 'name.'], 'ar': ['أنا', 'لا', 'أعرف', 'اسمه'] },
    { 'en': ['Which', 'car', 'is', 'ours?'], 'ar': ['أي', 'سيارة', 'هي', 'سيارتنا؟'] },
    { 'en': ['Is', 'anyone', 'looking?'], 'ar': ['هل', 'أى', 'ينظر', 'أحد؟'] },
    { 'en': ['I', 'am', 'familiar', 'with', 'this', 'neighborhood.'], 'ar': ['عندي', 'معرفة', 'بهذا', 'الجوار.'] },
    { 'en': ['The', 'others', 'will', 'arrive', 'in', 'a', 'few', 'minutes.'], 'ar': ['سيصل', 'الاخرون', 'في', 'غضون', 'دقائق', 'قليلة.'] },
    { 'en': ['My', 'mother', 'cut', 'the', 'cake.'], 'ar': ['قطّعت', 'أمي', 'الكعك.'] },
    { 'en': ['Pass', 'me', 'the', 'salt,', 'please.'], 'ar': ['مرّر', 'إليّ', 'الملح', 'إذا', 'سمحت.'] },
    { 'en': ['Tom', 'is', 'in', 'the', 'house.'], 'ar': ['توم', 'في', 'البيت.'] },
    { 'en': ['I', 'know', 'nothing', 'yet.'], 'ar': ['لا', 'أعرف', 'شيئاً', 'بعد.'] },
    { 'en': ['Is', 'your', 'father', 'a', 'doctor?'], 'ar': ['هل', 'أبوك', 'طبيب؟'] },
    { 'en': ['How', 'many', 'kids', 'do', 'you', 'have?'], 'ar': ['كم', 'ابناً', 'عندك؟'] },
    { 'en': ['I', "don't", 'recommend', 'that.'], 'ar': ['لا', 'أنصح', 'بذلك.'] },
    { 'en': ['Police', "shouldn't", 'take', 'bribes.'], 'ar': ['على', 'الشرطة', 'ألا', 'تقبل', 'الرشاوي.'] },
    { 'en': ['You', 'are', 'my', 'mother.'], 'ar': ['أنتِ', 'أُمّي.'] },
    { 'en': ["You'll", 'feel', 'better', 'if', 'you', 'take', 'these', 'pills.'], 'ar': ['ستشعر', 'بتحسن', 'إذا', 'تناولت', 'هذه', 'الحبوب.'] },
    { 'en': ['Tom', 'is', 'an', 'only', 'child.'], 'ar': ['توم', 'وحيد', 'لأهله.'] },
    { 'en': ['How', 'could', 'you', 'not', 'say', 'something?'], 'ar': ['كيف', 'يمكن', 'أن', 'لا', 'تقول', 'شيئاً؟'] },
    { 'en': ['Mother', 'bought', 'my', 'brother', 'a', 'yellow', 'umbrella.'], 'ar': ['اِشترت', 'أمي', 'مظلة', 'صفراء', 'لأخي.'] },
    { 'en': ['We', 'feel', 'good', 'about', 'it.'], 'ar': ['نحمل', 'شعورا', 'جيدا', 'تجاهه.'] },
    { 'en': ['His', 'performance', 'was', 'worthy', 'of', 'praise.'], 'ar': ['كان', 'أداؤه', 'جديراً', 'بالثناء.'] },
    { 'en': ['He', 'likes', 'to', 'watch', 'TV.'], 'ar': ['يحب', 'مشاهدة', 'التلفاز.'] },
    { 'en': ['My', 'scarf', 'is', 'blue.'], 'ar': ['وشاحي', 'أزرق.'] },
    { 'en': ['She', 'loves', 'Tom,', 'not', 'me.'], 'ar': ['هي', 'تحب', 'توم,', 'ليس', 'أنا'] },
    { 'en': ['Maryam', 'is', 'well', 'behaved.'], 'ar': ['مريم', 'ذات', 'خلق.'] },
    { 'en': ['Please', 'be', 'serious.'], 'ar': ['كوني', 'جادًّة', 'أرجوكِ.'] },
    { 'en': ["I'm", 'a', 'mother.'], 'ar': ['أنا', 'أمّ.'] },
    { 'en': ["I've", 'got', 'two', 'brothers.'], 'ar': ['لدي', 'شقيقين.'] },
    { 'en': ['Tom', 'did', 'it', 'in', 'his', 'own', 'way.'], 'ar': ['توم', 'فعل', 'ذلك', 'بطريقته', 'الخاصة.'] },
    { 'en': ['He', 'shot', 'at', 'me.'], 'ar': ['أطلق', 'عليّ', 'الرّصاص.'] },
    { 'en': ['You', 'should', 'not', 'go', 'there.'], 'ar': ['عليك', 'ألا', 'تذهب', 'إلى', 'هناك.'] },
    { 'en': ['When', 'are', 'you', 'going', 'to', 'do', 'that?'], 'ar': ['متى', 'ستقوم', 'بذلك؟'] },
    { 'en': ['Tom', 'hates', 'shopping.'], 'ar': ['يكره', 'توم', 'التسوق.'] },
    { 'en': ['Answer', 'the', 'telephone,', 'will', 'you?'], 'ar': ['هل', 'لك', 'أن', 'ترد', 'على', 'الهاتف؟'] },
    { 'en': ["It's", 'a', 'funny', 'story.'], 'ar': ['إنها', 'قصةٌ', 'مضحكة.'] },
    { 'en': ["I'm", 'happy', 'with', 'that.'], 'ar': ['أنا', 'سعيد', 'بذلك'] },
    { 'en': ['We', 'felt', 'the', 'house', 'shake', 'a', 'little.'], 'ar': ['شعرنا', 'بالبيت', 'يهتز', 'قليلاً.'] },
    { 'en': ['You', 'remind', 'me', 'of', 'your', 'brother.'], 'ar': ['أنت', 'تذكرني', 'بأخيك.'] },
    { 'en': ['If', 'I', 'were', 'rich,', "I'd", 'give', 'you', 'money.'], 'ar': ['أنا', 'لو', 'كنت', 'غنية,', 'كنت', 'أود', 'أن', 'أعطيك', 'المال.'] },
    { 'en': ["I'm", 'not', 'feeling', 'well.'], 'ar': ['أشعر', 'بوعكة.'] },
    { 'en': ['I', 'work', 'with', 'him.'], 'ar': ['أعمل', 'معه.'] },
    { 'en': ["It'll", 'only', 'take', 'a', 'minute', 'of', 'your', 'time.'], 'ar': ['سيأخذ', 'دقيقة', 'من', 'وقتك', 'فقط.'] },
    { 'en': ['Tom', 'is', 'willing', 'to', 'stand', 'by', 'you.'], 'ar': ['توم', 'مستعد', 'لمساعدتك.'] },
    { 'en': ['Tom', "doesn't", 'sing', 'anymore.'], 'ar': ['لم', 'يعد', 'توم', 'يغنّي', 'الآن.'] },
    { 'en': ['He', 'improved', 'the', 'device.'], 'ar': ['لقد', 'حسّن', 'الجهاز.'] },
    { 'en': ['You', 'have', 'better', 'sight', 'than', 'me.'], 'ar': ['أنتَ', 'لديكَ', 'رؤية', 'أفضل', 'منى؟'] },
    { 'en': ['You', 'have', 'a', 'way', 'with', 'women.'], 'ar': ['إنك', 'تعرف', 'كيف', 'تتعامل', 'مع', 'النساء.'] },
    { 'en': ['Measles', 'can', 'be', 'quite', 'dangerous.'], 'ar': ['يمكن', 'للحصبة', 'أن', 'تكون', 'خطيرةً.'] },
    { 'en': ['Tell', 'me', 'how', 'to', 'solve', 'the', 'problem.'], 'ar': ['أخبرني', 'كيف', 'أحل', 'هذه', 'المسألة.'] },
    { 'en': ['Then', 'what?'], 'ar': ['ماذا', 'بعد', 'ذلك؟'] },
    { 'en': ['If', 'you', "don't", 'like', 'it', 'then', 'you', 'can', 'quit.'], 'ar': ['إن', 'لم', 'يعجبك', 'فبإمكانك', 'أن', 'تتركه.'] },
    { 'en': ["I've", 'seen', 'Tom', 'here', 'before.'], 'ar': ['رأيت', 'توم', 'هنا', 'سابقا.'] },
    { 'en': ["I'm", 'from', 'Australia.'], 'ar': ['أنا', 'من', 'أُستراليا.'] },
    { 'en': ['When', 'do', 'you', 'study?'], 'ar': ['متى', 'تدرس؟'] },
    { 'en': ['All', 'roads', 'lead', 'to', 'Rome.'], 'ar': ['كل', 'الطرق', 'تؤدي', 'إلى', 'روما.'] },
    { 'en': ['You', 'should', 'have', 'warned', 'him', 'before.'], 'ar': ['كان', 'عليك', 'تحذيره', 'مسبقا.'] },
    { 'en': ["Let's", 'get', 'started', 'right', 'away.'], 'ar': ['لنبدأ', 'على', 'الفور.'] },
    { 'en': ['If', "you're", 'looking', 'for', 'a', 'job,', 'call', 'me.'], 'ar': ['إن', 'كنت', 'تبحث', 'عن', 'وظيفة،', 'اتصل', 'بي.'] },
    { 'en': ['You', 'look', 'busy.'], 'ar': ['تبدو', 'مشغولاً.'] },
    { 'en': ['He', 'showed', 'it', 'to', 'me.'], 'ar': ['إنهُ', 'اظهرها', 'علي.'] },
    { 'en': ['She', 'likes', 'to', 'run.'], 'ar': ['إنها', 'تحب', 'الركض.'] },
    { 'en': ['He', 'went', 'out', 'in', 'a', 'hurry.'], 'ar': ['خرج', 'علی', 'عجلة', 'من', 'أمره.'] },
    { 'en': ["Isn't", 'it', 'black?'], 'ar': ['أليست', 'سوداء؟'] },
    { 'en': ['My', 'wallet', 'was', 'stolen', 'yesterday.'], 'ar': ['سُرقت', 'محفظتي', 'بالأمس.'] },
    { 'en': ['We', 'have', 'to', 'do', 'this', 'by', 'ourselves.'], 'ar': ['علينا', 'ان', 'نفعل', 'هذا', 'بأنفسنا'] },
    { 'en': ["It's", 'very', 'important', 'to', 'get', 'enough', 'rest.'], 'ar': ['انه', 'لمن', 'المهم', 'جداً', 'الحصول', 'على', 'كفاية', 'من', 'الراحة.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'of', 'work', 'to', 'do.'], 'ar': ['لدي', 'الكثير', 'من', 'العمل', 'لأنجزهُ.'] },
    { 'en': ['Where', 'is', 'the', 'school?'], 'ar': ['أين', 'مدرسته؟'] },
    { 'en': ["Where's", 'the', 'boss?'], 'ar': ['أين', 'الزعيم؟'] },
    { 'en': ['I', 'know', 'those', 'girls.'], 'ar': ['أعرف', 'أولئك', 'الفتيات.'] },
    { 'en': ['Mind', 'your', 'own', 'business!'], 'ar': ['اهتم', 'بشؤونك', 'الخاصة.'] },
    { 'en': ["I'll", 'call', 'you', 'back', 'later.'], 'ar': ['سأعاود', 'الإتصال', 'بك', 'فيما', 'بعد.'] },
    { 'en': ['Now', 'that', 'I', 'am', 'here,', 'you', "don't", 'have', 'to', 'worry.'], 'ar': ['لا', 'تقلق', '،', 'أنا', 'هنا', 'الآن.'] },
    { 'en': ['This', 'is', 'an', 'apple,', 'too.'], 'ar': ['هذه', 'تفاحة', 'أيضاً.'] },
    { 'en': ['I', "can't", 'be', 'seen', 'with', 'you.'], 'ar': ['لا', 'يمكن', 'ان', 'اُرى', 'معك', '.'] },
    { 'en': ["She's", 'in', 'the', 'restroom.'], 'ar': ['إنها', 'في', 'الحمام.'] },
    { 'en': ['How', 'far', 'is', 'it', 'from', 'your', 'house', 'to', 'the', 'park?'], 'ar': ['كم', 'تبعد', 'الحديقة', 'عن', 'منزلك؟'] },
    { 'en': ['Do', 'you', 'smoke?'], 'ar': ['هل', 'تدخن؟'] },
    { 'en': ["Here's", 'your', 'order.'], 'ar': ['تفضّل', 'طلبك.'] },
    { 'en': ['I', 'will', 'lend', 'you', 'money', 'just', 'this', 'once.'], 'ar': ['سأقرضك', 'مالً', 'هذه', 'المرة', 'فقط.'] },
    { 'en': ['My', 'mother', "can't", 'ride', 'a', 'bicycle.'], 'ar': ['لا', 'تستطيع', 'أمي', 'أن', 'تركب', 'الدراجة.'] },
    { 'en': ["It's", 'a', 'good', 'book.'], 'ar': ['إنه', 'كتاب', 'جيد'] },
    { 'en': ['Stop,', 'or', "I'll", 'shoot.'], 'ar': ['توقف', 'و', 'إلا', 'أطلقت', 'النار.'] },
    { 'en': ['The', 'boy', 'started', 'crying.'], 'ar': ['أخذ', 'الولد', 'يبكي.'] },
    { 'en': ['Her', 'son', 'called', 'from', 'New', 'York.'], 'ar': ['اتصل', 'ابنها', 'من', 'نيويورك.'] },
    { 'en': ['Will', 'you', 'take', 'your', 'hand', 'off', 'me?'], 'ar': ['ألا', 'تبعد', 'يدك', 'عني؟'] },
    { 'en': ['Tom', "isn't", 'here.'], 'ar': ['توم', 'ليس', 'هنا'] },
    { 'en': ['The', 'baby', 'cried', 'all', 'night.'], 'ar': ['أخذ', 'الصغير', 'يبكي', 'طيلة', 'الليل.'] },
    { 'en': ['Why', 'are', 'you', 'crying?'], 'ar': ['لماذا', 'تبكي؟'] },
    { 'en': ["I'm", 'happy', 'to', 'see', 'you.'], 'ar': ['سعيد', 'برؤيتك'] },
    { 'en': ['Do', 'you', 'remember', 'us?'], 'ar': ['هل', 'تتذكرنا؟'] },
    { 'en': ["Let's", 'watch', 'TV.'], 'ar': ['لنشاهد', 'الرائي.'] },
    { 'en': ['I', 'like', 'animals,', 'for', 'example,', 'cats', 'and', 'dogs.'], 'ar': ['أنا', 'أُحب', 'الحيوانات,', 'على', 'سبيل', 'المثال,', 'القِطط', 'والكلاب.'] },
    { 'en': ['It', 'was', 'a', 'lovely', 'autumn', 'evening.'], 'ar': ['كان', 'ذلك', 'مساء', 'خريفي', 'جميل.'] },
    { 'en': ['I', 'wanted', 'red', 'shoes.'], 'ar': ['أريد', 'حذاءً', 'أحمراً.'] },
    { 'en': ['He', 'is', 'playing', 'music.'], 'ar': ['يعزف', 'موسيقى.'] },
    { 'en': ['I', 'feel', 'hungry.'], 'ar': ['أشعر', 'بالجوع.'] },
    { 'en': ['Everything', 'was', 'lost.'], 'ar': ['كل', 'شيء', 'فُقِد.'] },
    { 'en': ['I', 'am', 'reading', 'a', 'book', 'about', 'animals.'], 'ar': ['إني', 'أقرأ', 'كتاباً', 'عن', 'الحيوانات.'] },
    { 'en': ['Defend', 'yourselves.'], 'ar': ['دافِعوا', 'عن', 'انفُسَكُم.'] },
    { 'en': ['I', 'have', 'to', 'study', 'for', 'the', 'test.'], 'ar': ['عليّ', 'أن', 'أدرس', 'للإمتحان.'] },
    { 'en': ['Why', 'are', 'you', 'going?'], 'ar': ['لما', 'أنت', 'ذاهب؟'] },
    { 'en': ['My', 'dad', 'is', 'very', 'strict.'], 'ar': ['أبي', 'صارم', 'للغاية.'] },
    { 'en': ['He', 'looks', 'like', 'his', 'father.'], 'ar': ['يشبه', 'أباه.'] },
    { 'en': ['Who', 'died?'], 'ar': ['من', 'توفي؟'] },
    { 'en': ['Unbelievable!'], 'ar': ['شيء', 'لا', 'يُصدق!'] },
    { 'en': ['The', 'house', 'is', 'burning.'], 'ar': ['المنزل', 'يحترق.'] },
    { 'en': ['Is', 'Tom', 'here?'], 'ar': ['هل', 'توم', 'هنا؟'] },
    { 'en': ['For', 'that', 'reason,', 'he', 'lost', 'his', 'job.'], 'ar': ['خسر', 'وظيفته', 'لذاك', 'السبب.'] },
    { 'en': ['Life', 'is', 'very', 'short.'], 'ar': ['الحياة', 'قصيرة', 'جداً.'] },
    { 'en': ['Maybe', 'you', 'can', 'tell', 'me', 'where', 'I', 'can', 'find', 'Tom.'], 'ar': ['ربّما', 'تخبرني', 'عن', 'مكانٍ', 'أجد', 'فيه', 'توم.'] },
    { 'en': ["I'm", 'a', 'bit', 'shy.'], 'ar': ['أنا', 'خجول', 'قليلاً.'] },
    { 'en': ['We', 'are', 'playing', 'tennis', 'this', 'weekend.'], 'ar': ['سنلعب', 'التنس', 'في', 'عطلة', 'نهاية', 'الأسبوع.'] },
    { 'en': ['You', "can't", 'finish', 'this', 'project.'], 'ar': ['لا', 'يمكنك', 'إنهاء', 'هذا', 'المشروع.'] },
    { 'en': ['I', 'want', 'more.'], 'ar': ['أريد', 'المزيد'] },
    { 'en': ["It's", 'fall', 'now.'], 'ar': ['دخل', 'الخريف.'] },
    { 'en': ['My', 'mother', 'plays', 'the', 'piano', 'well.'], 'ar': ['تعزف', 'أمي', 'على', 'البيانو', 'بمهارة.'] },
    { 'en': ['She', 'has', 'a', 'son', 'everybody', 'loves.'], 'ar': ['لديها', 'ابن', 'يحبه', 'الجميع.'] },
    { 'en': ['Where', 'did', 'you', 'get', 'this?'], 'ar': ['أنی', 'لك', 'هذا', '؟'] },
    { 'en': ['Maybe', 'we', 'should', 'take', 'Tom', 'with', 'us.'], 'ar': ['ربما', 'علينا', 'جلب', 'توم', 'معنا.'] },
    { 'en': ['I', 'just', "don't", 'know', 'what', 'to', 'say.'], 'ar': ['انا', 'فقط', 'لا', 'اعلم', 'ماذا', 'اقول'] },
    { 'en': ['The', 'attraction', 'was', 'mutual.'], 'ar': ['كان', 'الانجذاب', 'متبادلا.'] },
    { 'en': ['He', 'became', 'a', 'pianist.'], 'ar': ['أصبح', 'عازف', 'بيانو.'] },
    { 'en': ['I', 'would', 'often', 'play', 'tennis', 'with', 'him.'], 'ar': ['كثيراً', 'ما', 'ألعب', 'معه', 'التنس.'] },
    { 'en': ['Who', "doesn't", 'care', 'about', 'money?'], 'ar': ['من', 'لا', 'يَهمّه', 'المال؟'] },
    { 'en': ['Spring', 'will', 'come', 'soon.'], 'ar': ['سيأتي', 'الربيع', 'قريباً.'] },
    { 'en': ['You', 'just', 'have', 'to', 'promise', 'me', 'one', 'thing.'], 'ar': ['أنتِ', 'عليكِ', 'فقط', 'أن', 'تعِديني', 'بشيء', 'واحد.'] },
    { 'en': ['There', 'are', 'a', 'lot', 'of', 'children', 'in', 'the', 'park.'], 'ar': ['هنالك', 'الكثير', 'من', 'الأطفال', 'في', 'الحديقة.'] },
    { 'en': ['One', 'of', 'my', 'bags', 'is', 'missing.'], 'ar': ['إحدى', 'حقائبي', 'مفقودة.'] },
    { 'en': ['I', 'do', 'hope', "you'll", 'come', 'again.'], 'ar': ['أتمنّى', 'أن', 'تأتي', 'مرّة', 'أخرى.'] },
    { 'en': ['Tom', 'took', 'what', "wasn't", 'his.'], 'ar': ['أخذ', 'توم', 'شيئا', 'ليس', 'ملكه.'] },
    { 'en': ['You', 'look', 'pale', 'today.'], 'ar': ['تبدو', 'شاحباً', 'اليوم.'] },
    { 'en': ['No', 'one', 'trusts', 'him', 'any', 'more.'], 'ar': ['لم', 'يعد', 'يثق', 'به', 'أحد', 'بعد', 'الآن.'] },
    { 'en': ["I'm", 'a', 'little', 'tired.'], 'ar': ['إنني', 'متعب', 'قليلاً.'] },
    { 'en': ['I', 'have', 'no', 'books', 'to', 'read.'], 'ar': ['ليس', 'لدي', 'كتب', 'لأقرأها.'] },
    { 'en': ['My', 'dog', 'goes', 'everywhere', 'with', 'me.'], 'ar': ['يرافقني', 'كلبي', 'إلى', 'أي', 'مكان.'] },
    { 'en': ['Watch', 'how', 'I', 'do', 'it.'], 'ar': ['انظر', 'كيف', 'أقوم', 'بذلك'] },
    { 'en': ['I', 'know.'], 'ar': ['أعرف.'] },
    { 'en': ["What's", 'the', 'next', 'step', 'to', 'be', 'done?'], 'ar': ['ما', 'هي', 'الخطوة', 'التالية', 'ليصبح', 'جاهز؟'] },
    { 'en': ['This', 'flower', 'is', 'more', 'beautiful', 'than', 'that', 'one.'], 'ar': ['هذه', 'الوردة', 'أجمل', 'من', 'تلك.'] },
    { 'en': ["I'm", 'going', 'to', 'move', 'next', 'month.'], 'ar': ['سأنتقل', 'الشهر', 'التالي.'] },
    { 'en': ['I', 'had', 'a', 'strange', 'dream', 'last', 'night.'], 'ar': ['حلمت', 'بحلم', 'غريب', 'ليلة', 'الأمس.'] },
    { 'en': ['Can', 'you', 'come?'], 'ar': ['أيمكنك', 'المجيء؟'] },
    { 'en': ['We', 'succeeded!'], 'ar': ['نجحنا!'] },
    { 'en': ['A', 'dog', 'is', 'barking.'], 'ar': ['هناك', 'كلب', 'ينبح.'] },
    { 'en': ['What', 'time', 'do', 'you', 'get', 'up', 'every', 'day?'], 'ar': ['متى', 'تستيقظ', 'كل', 'يوم؟'] },
    { 'en': ['I', 'know', 'who', 'your', 'father', 'is.'], 'ar': ['أنا', 'أعرف', 'من', 'هو', 'والدك.'] },
    { 'en': ['Why', "don't", 'you', 'sit', 'down', 'and', 'relax?'], 'ar': ['لمَ', 'لا', 'تجلس', 'و', 'تستريح؟'] },
    { 'en': ['The', 'speech', 'lasted', 'thirty', 'minutes.'], 'ar': ['دامت', 'الخطبة', 'ثلاثين', 'دقيقة.'] },
    { 'en': ['All', 'I', 'know', 'is', 'that', 'he', 'came', 'from', 'China.'], 'ar': ['كل', 'ما', 'أعرفه', 'هو', 'أنه', 'من', 'الصين.'] },
    { 'en': ['I', 'gave', 'up', 'smoking', 'a', 'year', 'ago.'], 'ar': ['أقلعت', 'عن', 'التدخين', 'منذ', 'سنة.'] },
    { 'en': ['I', 'will', 'never', 'hurt', 'you.'], 'ar': ['لن', 'أجرح', 'مشاعرك', 'أبدا'] },
    { 'en': ["She's", 'way', 'taller', 'than', 'me.'], 'ar': ['الفرق', 'بيني', 'و', 'بينها', 'في', 'الطول', 'كبير.'] },
    { 'en': ['Tom', "didn't", 'believe', 'anything', 'Mary', 'said.'], 'ar': ['لم', 'يصدّق', 'توم', 'أيا', 'من', 'كلام', 'ماري.'] },
    { 'en': ['The', 'police', 'shot', "Tom's", 'tires', 'out.'], 'ar': ['أطلقت', 'الشرطة', 'النار', 'على', 'إطارات', 'مركبة', 'توم.'] },
    { 'en': ['Tom', 'has', 'always', 'had', 'that', 'problem.'], 'ar': ['لطالما', 'عانى', 'توم', 'من', 'تلك', 'المشكلة.'] },
    { 'en': ['Why', "didn't", 'they', 'say', 'something?'], 'ar': ['لِمَ', 'لًم', 'يقولوا', 'شيئا؟'] },
    { 'en': ['She', 'eats', 'nothing', 'but', 'vegetables.'], 'ar': ['إنها', 'لا', 'تأكل', 'إلا', 'الخضار.'] },
    { 'en': ['Be', 'friendly.'], 'ar': ['كُن', 'ودوداً.'] },
    { 'en': ['Did', 'you', 'buy', 'this', 'book?'], 'ar': ['هل', 'اشتريت', 'الكتاب؟'] },
    { 'en': ['She', 'came', 'to', 'see', 'me.'], 'ar': ['أتت', 'لتراني.'] },
    { 'en': ['Kenya', 'became', 'independent', 'in', '1963.'], 'ar': ['استقلت', 'كينيا', 'عام', '1963.'] },
    { 'en': ['Are', 'you', 'older', 'than', 'Tom?'], 'ar': ['أأنت', 'أكبر', 'من', 'توم؟'] },
    { 'en': ["He's", 'smart.'], 'ar': ['إنه', 'ذكي.'] },
    { 'en': ['Is', 'anyone', 'absent', 'today?'], 'ar': ['هل', 'غاب', 'أحد', 'اليوم؟'] },
    { 'en': ['I', 'continued', 'working.'], 'ar': ['تابعت', 'العمل.'] },
    { 'en': ['Even', 'Homer', 'sometimes', 'nods.'], 'ar': ['لكلّ', 'صارم', 'نبوة.'] },
    { 'en': ['I', "don't", 'want', 'to', 'lose', 'you.'], 'ar': ['لا', 'أريد', 'أن', 'أخسرَكِ.'] },
    { 'en': ["I'm", 'sure', 'that', 'she', 'will', 'come', 'back', 'soon.'], 'ar': ['أنا', 'متأكد', 'من', 'أنها', 'ستعود', 'قريباً.'] },
    { 'en': ['We', 'have', 'a', 'guest.'], 'ar': ['لدينا', 'ضيف.'] },
    { 'en': ['They', 'hated', 'Tom.'], 'ar': ['كانوا', 'يكرهون', 'توم.'] },
    { 'en': ['All', 'aboard!'], 'ar': ['ليركب', 'الجميع.'] },
    { 'en': ['You', 'must', 'fulfill', 'your', 'duty.'], 'ar': ['يجب', 'عليك', 'تأدية', 'واجباتك.'] },
    { 'en': ['Are', 'you', 'kidding?'], 'ar': ['هل', 'تَمْزَح؟'] },
    { 'en': ['Anything', 'new?'], 'ar': ['هل', 'من', 'جديد؟'] },
    { 'en': ['Keep', 'a', 'record', 'of', 'how', 'much', 'you', 'spend.'], 'ar': ['سجّل', 'ما', 'تنفق.'] },
    { 'en': ['The', 'stock', 'reached', 'its', 'high', 'point', 'last', 'July.'], 'ar': ['وصلت', 'أسعار', 'الأسهم', 'ذروتها', 'في', 'يوليو', 'الماضي.'] },
    { 'en': ['My', 'mother', 'is', 'not', 'always', 'at', 'home.'], 'ar': ['أمي', 'ليست', 'موجودة', 'في', 'المنزل', 'طيلة', 'الوقت.'] },
    { 'en': ['He', 'believes', 'in', 'an', 'afterlife.'], 'ar': ['إنهُ', 'يؤمن', 'بالحياة', 'الآخرة.'] },
    { 'en': ["I'm", 'sure', 'she', 'will', 'return', 'soon.'], 'ar': ['أنا', 'متأكد', 'من', 'أنها', 'ستعود', 'قريباً.'] },
    { 'en': ['She', 'teaches', 'reading', 'and', 'writing.'], 'ar': ['إنها', 'تدرّس', 'القراءة', 'و', 'الكتابة.'] },
    { 'en': ['Here', 'he', 'is!'], 'ar': ['ها', 'هو', 'هنا'] },
    { 'en': ['I', "wasn't", 'busy', 'yesterday.'], 'ar': ['لم', 'أكن', 'مشغولاً', 'بالأمس.'] },
    { 'en': ['Does', 'he', 'have', 'a', 'girlfriend?'], 'ar': ['هل', 'لديه', 'حبيبة؟'] },
    { 'en': ['I', "can't", 'think', 'of', 'anything', 'to', 'write', 'about.'], 'ar': ['لا', 'شيء', 'في', 'بالي', 'للكتابة', 'عنه.'] },
    { 'en': ['What', 'you', 'said', 'is', 'complete', 'nonsense.'], 'ar': ['ما', 'قلته', 'هو', 'محض', 'هراء.'] },
    { 'en': ['I', 'give', 'you', 'my', 'word.'], 'ar': ['أعدك', 'بذلك'] },
    { 'en': ['Cats', 'usually', 'hate', 'dogs.'], 'ar': ['عادة', 'ما', 'تكره', 'القطط', 'الكلاب.'] },
    { 'en': ['Tom', 'says', 'that', 'he', 'enjoyed', 'the', 'concert.'], 'ar': ['يقول', 'توم', 'بأنه', 'استمتع', 'بالحفلة', 'الموسيقية.'] },
    { 'en': ['This', 'movie', 'is', 'worth', 'seeing', 'again.'], 'ar': ['هذا', 'الفلم', 'يستحق', 'المشاهدة', 'لمرة', 'ثانية.'] },
    { 'en': ['He', 'asked', 'me', 'for', 'money.'], 'ar': ['لقد', 'طلب', 'مني', 'مال.'] },
    { 'en': ['I', 'went', 'to', 'many', 'shops', 'to', 'look', 'for', 'the', 'book.'], 'ar': ['ذهبت', 'إلى', 'العديد', 'من', 'المتاجر', 'للبحث', 'عن', 'الكتاب.'] },
    { 'en': ['I', 'gave', 'up', 'smoking', 'six', 'months', 'ago.'], 'ar': ['أقلعت', 'عن', 'التدخين', 'منذ', 'ستة', 'أشهر.'] },
    { 'en': ['I', 'love', 'you.'], 'ar': ['أنا', 'أحبك'] },
    { 'en': ['Dr.', 'Hideo', 'Noguchi', 'died', 'in', 'Africa', 'in', '1928.'], 'ar': ['تُوفِّي', 'د.هيديو', 'نوغوتشي', 'في', 'أفريقيا', 'عام', '1928.'] },
    { 'en': ['This', 'is', 'ours.'], 'ar': ['هذه', 'لنا'] },
    { 'en': ['He', 'is', 'washing', 'your', 'car.'], 'ar': ['إنه', 'يغسل', 'سيارتك.'] },
    { 'en': ['If', 'I', 'knew', 'the', 'truth,', 'I', 'would', 'tell', 'you.'], 'ar': ['إذا', 'عرفت', 'الحقيقة', 'سأخبرك.'] },
    { 'en': ['The', 'world', "doesn't", 'revolve', 'around', 'you.'], 'ar': ['العالم', 'لا', 'يتمحور', 'حولك.'] },
    { 'en': ['This', 'is', 'where', 'the', 'accident', 'happened.'], 'ar': ['هنا', 'موقع', 'الحادث.'] },
    { 'en': ['Do', 'you', 'study', 'English?'], 'ar': ['أتدرس', 'اللغة', 'الإنجليزية؟'] },
    { 'en': ["Don't", 'talk', 'in', 'a', 'loud', 'voice', 'here.'], 'ar': ['لا', 'تتكلم', 'بصوت', 'عالٍ', 'هنا.'] },
    { 'en': ['You', 'are', 'always', 'complaining.'], 'ar': ['أنت', 'دائماً', 'ما', 'تشتكي.'] },
    { 'en': ['Have', 'you', 'been', 'shot?'], 'ar': ['هل', 'أصبت', 'بطلق', 'ناري؟'] },
    { 'en': ['One', 'year', 'has', 'twelve', 'months.'], 'ar': ['هناك', 'اثنا', 'عشر', 'شهرًا', 'في', 'السنة.'] },
    { 'en': ['He', 'built', 'a', 'fence', 'around', 'his', 'house.'], 'ar': ['بنى', 'سِياجاً', 'حول', 'بيته.'] },
    { 'en': ['He', "doesn't", 'smoke.'], 'ar': ['هو', 'لا', 'يدخن.'] },
    { 'en': ['I', 'went', 'to', 'bed', 'later', 'than', 'usual.'], 'ar': ['ذهبت', 'للنوم', 'متأخراً', 'أكثر', 'من', 'العادة.'] },
    { 'en': ['Go', 'find', 'Tom.'], 'ar': ['إذهب', 'وابحث', 'عن', 'طوم.'] },
    { 'en': ['Could', 'you', 'help', 'me?'], 'ar': ['أيمكنك', 'مساعدتي؟'] },
    { 'en': ['This', 'park', 'reminds', 'me', 'of', 'my', 'childhood.'], 'ar': ['هذا', 'المنتزه', 'يذكّرني', 'بطفولتي.'] },
    { 'en': ['They', 'called', 'off', 'their', 'visit', 'to', 'London.'], 'ar': ['ألغوا', 'زيارتهم', 'إلى', 'لندن.'] },
    { 'en': ['Tom', "couldn't", 'hold', 'back', 'his', 'tears.'], 'ar': ['لم', 'يستطع', 'توم', 'أن', 'يمنع', 'دموعه.'] },
    { 'en': ["I've", 'seen', 'you', 'somewhere', 'before.'], 'ar': ['لقد', 'رأيتك', 'بمكان', 'ما', 'من', 'قبل'] },
    { 'en': ["I'm", 'doing', 'great.'], 'ar': ['أنا', 'بخير'] },
    { 'en': ["I'm", 'very', 'popular.'], 'ar': ['أنا', 'شعبي', 'جداً.'] },
    { 'en': ['What', 'did', 'he', 'say?'], 'ar': ['ما', 'الذي', 'قاله؟'] },
    { 'en': ["I'm", 'studying', 'several', 'languages.'], 'ar': ['أنا', 'أدرس', 'عدّة', 'لغات.'] },
    { 'en': ["Don't", 'hang', 'up', 'yet,', 'please.'], 'ar': ['لا', 'تقفل', 'الخط', 'من', 'فضلك.'] },
    { 'en': ['You', 'betrayed', 'me.'], 'ar': ['لقد', 'خنتيني.'] },
    { 'en': ["I'm", 'not', 'convinced', 'at', 'all.'], 'ar': ['أنا', 'لستُ', 'مقتنعًا', 'على', 'الإطلاق.'] },
    { 'en': ['"Tatoeba"', 'means', '"for', 'example"', 'in', 'Japanese.'], 'ar': ['"Tatoeba"', 'معناها', '"على', 'سبيل', 'المثال"', 'باللغة', 'اليابانية.'] },
    { 'en': ['He', "couldn't", 'bring', 'himself', 'to', 'shoot', 'the', 'deer.'], 'ar': ['لم', 'يستطع', 'أن', 'يجعل', 'نفسه', 'تطلق', 'على', 'الأيِّل.'] },
    { 'en': ['I', 'turned', 'right.'], 'ar': ['انعطفتُ', 'يمينًا.'] },
    { 'en': ["I'll", 'admit', "I'm", 'wrong.'], 'ar': ['أعترف', 'أني', 'مخطئ.'] },
    { 'en': ['One', 'billion', 'people', 'speak', 'English.'], 'ar': ['مليار', 'شخص', 'يتكلمون', 'الإنجليزية.'] },
    { 'en': ['It', 'is', 'believed', 'that', 'he', 'was', 'a', 'brave', 'soldier.'], 'ar': ['يُعتقد', 'أنه', 'كان', 'جنديا', 'شجاعا.'] },
    { 'en': ['He', 'remained', 'single', 'all', 'his', 'life.'], 'ar': ['بقي', 'عازباً', 'طوال', 'حياته.'] },
    { 'en': ['I', 'have', 'to', 'find', 'it.'], 'ar': ['عليّ', 'أن', 'أجدها.'] },
    { 'en': ["There's", 'a', 'possibility', 'of', 'war.'], 'ar': ['هناك', 'إحتمال', 'أن', 'تندلع', 'الحرب.'] },
    { 'en': ["That's", 'not', 'what', 'I', 'wanted', 'to', 'show', 'you.'], 'ar': ['ليس', 'هذا', 'ما', 'أردت', 'منك', 'رؤيته.'] },
    { 'en': ['May', 'I', 'use', 'the', 'toilet?'], 'ar': ['أيمكنني', 'الذهاب', 'إلى', 'الحمام؟'] },
    { 'en': ['He', 'overslept', 'this', 'morning.'], 'ar': ['لقد', 'نام', 'أكثر', 'من', 'المعتاد', 'هذا', 'الصباح.'] },
    { 'en': ['It', 'depends', 'on', 'the', 'context.'], 'ar': ['يعتمد', 'ذلك', 'على', 'السياق.'] },
    { 'en': ["I'm", 'charging', 'my', 'phone.'], 'ar': ['أقوم', 'بشحن', 'هاتفي'] },
    { 'en': ["I'm", 'sitting', 'alone', 'in', 'my', 'house.'], 'ar': ['أجلس', 'وحيداً', 'في', 'بيتي.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'of', 'homework.'], 'ar': ['لدي', 'الكثير', 'من', 'الواجبات.'] },
    { 'en': ['There', 'are', 'a', 'few', 'shops', 'next', 'to', 'my', 'house.'], 'ar': ['توجد', 'بعض', 'المحلات', 'قرب', 'منزلي.'] },
    { 'en': ['No', 'one', 'I', 'know', 'likes', 'Tom', 'anymore.'], 'ar': ['لا', 'أحد', 'أعرفهُ', 'يحب', 'توم', 'بعد', 'الآن.'] },
    { 'en': ['What', 'do', 'you', 'suggest', 'that', 'we', 'do', 'now?'], 'ar': ['ماذا', 'تقترحْ', 'أن', 'نفعل', 'الآن؟'] },
    { 'en': ['The', 'child', 'is', 'afraid', 'of', 'the', 'dark.'], 'ar': ['الطفل', 'خائف', 'من', 'الظلام.'] },
    { 'en': ['At', 'what', 'time', 'does', 'your', 'class', 'end?'], 'ar': ['في', 'أي', 'وقت', 'ينتهي', 'صفك؟'] },
    { 'en': ['He', 'paid', 'no', 'attention', 'to', 'my', 'advice.'], 'ar': ['لم', 'يعر', 'أي', 'اهتمام', 'لنصيحتي.'] },
    { 'en': ['Are', 'you', 'looking', 'for', 'something', 'specific?'], 'ar': ['هل', 'تبحث', 'عن', 'شيء', 'معين'] },
    { 'en': ['I', 'decided', 'to', 'be', 'a', 'doctor.'], 'ar': ['قررت', 'أن', 'أصبح', 'طبيباً.'] },
    { 'en': ['He', 'asked', 'me', 'what', 'I', 'had', 'been', 'doing.'], 'ar': ['سالني', 'عما', 'كنت', 'افعله?'] },
    { 'en': ['Is', 'this', 'your', 'pen?'], 'ar': ['أهذا', 'قلمك؟'] },
    { 'en': ['I', 'am', 'going', 'to', 'watch', 'TV', 'this', 'evening.'], 'ar': ['سأشاهد', 'التلفاز', 'هذا', 'المساء.'] },
    { 'en': ['Sit', 'at', 'the', 'table.'], 'ar': ['اجلس', 'على', 'الطاولة.'] },
    { 'en': ['What', 'are', 'you', 'cooking?'], 'ar': ['ماذا', 'تطبخ؟'] },
    { 'en': ['If', 'possible,', "I'd", 'like', 'to', 'receive', 'an', 'answer.'], 'ar': ['إذا', 'ممكن,', 'أود', 'أن', 'أستلم', 'جواب.'] },
    { 'en': ["We'll", 'have', 'to', 'postpone', 'the', 'meeting.'], 'ar': ['علينا', 'تأجيل', 'الاجتماع.'] },
    { 'en': ["That's", 'enough.'], 'ar': ['كفى.'] },
    { 'en': ['She', 'might', 'come.'], 'ar': ['ربما', 'ستأتي.'] },
    { 'en': ['She', 'loves', 'children.'], 'ar': ['إنها', 'تحب', 'الأطفال.'] },
    { 'en': ['I', 'am', 'four', 'months', 'pregnant.'], 'ar': ['أنا', 'في', 'الشهر', 'الرابع', 'من', 'حملي.'] },
    { 'en': ["I'm", 'not', 'kidding.'], 'ar': ['لستُ', 'أمزح!'] },
    { 'en': ["They're", 'too', 'big.'], 'ar': ['هم', 'كبار', 'جدا'] },
    { 'en': ["I'm", 'going', 'to', 'need', 'some', 'more', 'money.'], 'ar': ['سأحتاج', 'إلى', 'المزيد', 'من', 'المال.'] },
    { 'en': ['I', 'find', 'it', 'difficult', 'to', 'believe.'], 'ar': ['يصعب', 'عليّ', 'تصديق', 'ذلك.'] },
    { 'en': ['We', 'could', 'see', 'the', 'sunset', 'from', 'the', 'window.'], 'ar': ['أمكننا', 'أن', 'نرى', 'غروب', 'الشمس', 'من', 'النافذة.'] },
    { 'en': ['You', 'work', 'hard.'], 'ar': ['إنك', 'تعمل', 'بجد.'] },
    { 'en': ['If', 'only', 'I', 'knew!'], 'ar': ['لو', 'أنّني', 'كنت', 'أعرف!'] },
    { 'en': ['How', 'did', 'you', 'know', 'what', 'she', 'wanted?'], 'ar': ['كيفَ', 'علمتيِ', 'ما', 'كانت', 'تريدهُ؟'] },
    { 'en': ['Do', 'you', 'need', 'money?'], 'ar': ['هل', 'أنت', 'بحاجة', 'إلى', 'المال؟'] },
    { 'en': ["I'll", 'take', 'some', 'X-rays', 'of', 'your', 'teeth.'], 'ar': ['سأخذ', 'أشعة', 'سينية', 'لأسنانك.'] },
    { 'en': ['It', 'is', 'Saturday.'], 'ar': ['هل', 'اليوم', 'هو', 'السبت؟'] },
    { 'en': ['She', 'went', 'to', 'Italy', 'to', 'study', 'music.'], 'ar': ['ذهبت', 'إلى', 'إيطاليا', 'لتدرس', 'الموسيقى.'] },
    { 'en': ['She', 'asked', 'him', 'why', 'he', 'was', 'crying.'], 'ar': ['سَأَلَتهُ', 'لماذا', 'كان', 'يبكي.'] },
    { 'en': ['Bless', 'you.'], 'ar': ['يُباركك.'] },
    { 'en': ['Tom', 'felt', 'strong.'], 'ar': ['توم', 'شعر', 'بالقوة.'] },
    { 'en': ['I', "can't", 'understand', 'what', "you're", 'saying.'], 'ar': ['لا', 'أستطيع', 'فهم', 'ما', 'تقول.'] },
    { 'en': ['Do', 'I', 'have', 'to', 'do', 'it', 'right', 'away?'], 'ar': ['هل', 'يجب', 'أن', 'أفعل', 'ذلك', 'الآن؟'] },
    { 'en': ['What', 'a', 'strong', 'wind!'], 'ar': ['يا', 'لها', 'من', 'ريح', 'قوية!'] },
    { 'en': ['He', 'can', 'also', 'speak', 'French.'], 'ar': ['يتكلم', 'الفرنسية', 'أيضاً.'] },
    { 'en': ["I'm", 'sure', "I'll", 'find', 'a', 'good', 'gift', 'for', 'Tom.'], 'ar': ['أنا', 'متأكد', 'أني', 'سأجد', 'هدية', 'جيدة', 'لتوم.'] },
    { 'en': ['There', 'are', 'some', 'books', 'on', 'the', 'desk.'], 'ar': ['هناك', 'بعض', 'الكتب', 'على', 'المكتب.'] },
    { 'en': ['Save', 'me', 'some', 'ice', 'cream.'], 'ar': ['اترك', 'لي', 'بعض', 'المثلجات.'] },
    { 'en': ['He', 'introduced', 'his', 'sister', 'to', 'me.'], 'ar': ['عرّفني', 'بأخته.'] },
    { 'en': ["I'm", 'a', 'free', 'man.'], 'ar': ['أنا', 'رجل', 'حر.'] },
    { 'en': ["It's", 'a', 'surprise.'], 'ar': ['إنّها', 'مفاجأة.'] },
    { 'en': ['I', 'saw', 'Tom', 'going', 'into', 'the', 'bank.'], 'ar': ['رأيت', 'توم', 'داخلا', 'المصرف.'] },
    { 'en': ['The', 'bus', 'leaves', 'in', 'five', 'minutes.'], 'ar': ['ستنطلق', 'الحافلة', 'بعد', 'خمس', 'دقائق.'] },
    { 'en': ['She', 'plays', 'tennis', 'every', 'day.'], 'ar': ['تلعب', 'التنس', 'كل', 'يوم.'] },
    { 'en': ["It's", 'an', 'old', 'piano.'], 'ar': ['إنه', 'بيانو', 'قديم.'] },
    { 'en': ['What', 'do', 'you', 'think', "I've", 'been', 'doing?'], 'ar': ['ماذا', 'تعتقد', 'أني', 'كنت', 'أفعل؟'] },
    { 'en': ['Why', 'are', 'you', 'so', 'tired', 'today?'], 'ar': ['لمَ', 'أنت', 'متعبٌ', 'اليوم؟'] },
    { 'en': ['Her', 'mother', 'is', 'writing', 'a', 'letter.'], 'ar': ['تكتب', 'أمها', 'رسالةً.'] },
    { 'en': ["I'll", 'do', 'anything', 'I', 'can', 'for', 'Tom.'], 'ar': ['سأعمل', 'ما', 'بوسعي', 'لأجل', 'توم.'] },
    { 'en': ['I', 'think', 'Tom', 'likes', 'me.'], 'ar': ['أظن', 'بأن', 'توم', 'يحبني.'] },
    { 'en': ['Where', 'is', 'the', 'closest', 'train', 'station?'], 'ar': ['أين', 'أقرب', 'محطة', 'قطار؟'] },
    { 'en': ['We', 'are', 'grateful', 'to', 'you', 'for', 'your', 'help.'], 'ar': ['نحن', 'ممتنون', 'لك', 'لمساعدتك.'] },
    { 'en': ['Your', 'letter', 'made', 'me', 'happy.'], 'ar': ['أسعدتني', 'رسالتك.'] },
    { 'en': ['Are', 'you', 'kidding?'], 'ar': ['أأنت', 'تمزح؟'] },
    { 'en': ['I', 'will', 'make', 'her', 'happy.'], 'ar': ['سأجعلها', 'سعيدة', '-', 'سأشعرها', 'بالسعادة'] },
    { 'en': ['How', 'will', 'he', 'pay', 'his', 'debts?'], 'ar': ['كيفَ', 'سيدفع', 'ديونهُ؟'] },
    { 'en': ["I'll", 'personally', 'visit', 'you.'], 'ar': ['سأزورك', 'بنفسي.'] },
    { 'en': ['I', 'work', 'every', 'day', 'except', 'Sunday.'], 'ar': ['أعمل', 'كل', 'يوم', 'عدا', 'يوم', 'الأحد.'] },
    { 'en': ['I', "don't", 'think', 'Tom', 'knows', 'how', 'to', 'do', 'that.'], 'ar': ['لا', 'أظنّن', 'توم', 'يعرف', 'كيفيّة', 'فعل', 'ذلك.'] },
    { 'en': ['Do', 'you', 'really', 'want', 'me', 'to', 'do', 'that?'], 'ar': ['هل', 'تريدني', 'حقاً', 'أن', 'أفعل', 'هذا؟'] },
    { 'en': ['She', 'has', 'beautiful', 'handwriting.'], 'ar': ['خطّها', 'جميل.'] },
    { 'en': ['He', 'drew', 'a', 'straight', 'line', 'on', 'the', 'wall.'], 'ar': ['رسم', 'خطاً', 'مستقيماً', 'على', 'الحائط.'] },
    { 'en': ['Atlantis', 'was', 'destroyed.'], 'ar': ['دُمِّرَتْ', 'اطلانتس.'] },
    { 'en': ['I', 'prefer', 'to', 'work', 'on', 'my', 'own.'], 'ar': ['أُفضل', 'العمل', 'وحدي.'] },
    { 'en': ['The', 'speech', 'lasted', 'thirty', 'minutes.'], 'ar': ['استمرت', 'الخطبة', 'ثلاثين', 'دقيقة.'] },
    { 'en': ["Aren't", 'we', 'forgetting', 'something?'], 'ar': ['ألم', 'ننس', 'شيئاً؟'] },
    { 'en': ['We', 'are', 'going', 'to', 'travel', 'abroad', 'this', 'summer.'], 'ar': ['سنسافر', 'إلى', 'الخارج', 'هذا', 'الصيف.'] },
    { 'en': ['You', 'drink', 'too', 'much', 'coffee.'], 'ar': ['إنك', 'تشرب', 'الكثير', 'من', 'القهوة.'] },
    { 'en': ['There', 'were', 'a', 'lot', 'of', 'people', 'in', 'the', 'room.'], 'ar': ['كان', 'هناك', 'الكثير', 'من', 'الناس', 'في', 'الغرفة.'] },
    { 'en': ['I', 'carried', 'three', 'books.'], 'ar': ['حملت', 'ثلاثة', 'كتب.'] },
    { 'en': ['Have', 'you', 'ever', 'seen', 'a', 'kangaroo?'], 'ar': ['هل', 'سبق', 'لك', 'أن', 'رأيت', 'كنغراً؟'] },
    { 'en': ['I', 'love', 'music,', 'too.'], 'ar': ['أنا', 'أيضاً', 'أحب', 'الموسيقى.'] },
    { 'en': ['I', "didn't", 'know', 'that', 'she', 'was', 'ill.'], 'ar': ['لم', 'أعلم', 'أنّها', 'كانت', 'مريضة.'] },
    { 'en': ['Tom', 'has', 'decided', 'to', 'start', 'studying', 'French.'], 'ar': ['قرر', '"توم"', 'أن', 'يبدأ', 'بتعلم', 'اللغة', 'الفرنسية'] },
    { 'en': ['Have', 'you', 'forgotten', 'about', 'me?'], 'ar': ['هل', 'نسيتَني؟'] },
    { 'en': ['A', 'truck', 'hit', 'the', 'dog.'], 'ar': ['صدم', 'الكلب', 'شاحنة.'] },
    { 'en': ['Which', 'is', 'your', 'pen?'], 'ar': ['أيٌّ', 'قلمك؟'] },
    { 'en': ['Happy', 'birthday!'], 'ar': ['عيد', 'ميلاد', 'سعيد.'] },
    { 'en': ["Don't", 'stop', 'him.'], 'ar': ['لا', 'توقفه'] },
    { 'en': ['Do', 'you', 'remember', 'what', 'you', 'did', 'last', 'Friday?'], 'ar': ['أتذكري', 'ما', 'فعلتِ', 'الجمعة', 'الماضية؟'] },
    { 'en': ['What', 'did', 'you', 'make?'], 'ar': ['ماذا', 'صنعت؟'] },
    { 'en': ['You', 'are', 'a', 'good', 'boy.'], 'ar': ['إنك', 'ولد', 'مهذّب.'] },
    { 'en': ['Why', "don't", 'you', 'come', 'visit', 'us?'], 'ar': ['لم', 'لا', 'تزُرنا؟'] },
    { 'en': ["It's", 'as', 'cold', 'as', 'ice.'], 'ar': ['بارد', 'كالثلج.'] },
    { 'en': ['Is', 'she', 'your', 'sister?'], 'ar': ['هل', 'هي', 'أختك؟'] },
    { 'en': ['Would', 'you', 'like', 'to', 'watch', 'TV?'], 'ar': ['أتود', 'مشاهدة', 'التلفاز؟'] },
    { 'en': ['What', 'did', 'you', 'do', 'last', 'vacation?'], 'ar': ['ماذا', 'فعلت', 'في', 'الإجازة', 'الماضية؟'] },
    { 'en': ['This', 'book', 'is', 'unique', 'in', 'many', 'ways.'], 'ar': ['هذا', 'الكتاب', 'مميز', 'في', 'نواحي', 'عديدة'] },
    { 'en': ['Do', 'you', 'remember?'], 'ar': ['أما', 'زلت', 'تذكر؟'] },
    { 'en': ['He', 'killed', 'that', 'man.'], 'ar': ['لقد', 'قام', 'بقتل', 'ذاك', 'الرجل.'] },
    { 'en': ['She', 'brought', 'a', 'cup', 'of', 'tea', 'to', 'me.'], 'ar': ['أحضرت', 'لي', 'كأساً', 'من', 'الشاي.'] },
    { 'en': ['For', 'that', 'reason,', 'he', 'lost', 'his', 'job.'], 'ar': ['من', 'أجل', 'ذلك', 'فقد', 'عمله.'] },
    { 'en': ['She', 'put', 'the', 'magazine', 'on', 'the', 'table.'], 'ar': ['هىَ', 'وضعت', 'المجلة', 'علىَ', 'المنضدة.'] },
    { 'en': ['You', 'are', 'always', 'complaining.'], 'ar': ['إنك', 'تشتكي', 'دائماً.'] },
    { 'en': ["That's", 'great!'], 'ar': ['هذا', 'عظيم!'] },
    { 'en': ['Tom', 'traveled', 'a', 'lot', 'last', 'year.'], 'ar': ['سافر', 'توم', 'كثيرا', 'في', 'العام', 'الماضي'] },
    { 'en': ['The', 'exact', 'same', 'thing', 'happened', 'to', 'Tom.'], 'ar': ['الأمر', 'عينه', 'حصل', 'لتوم.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'listen?'], 'ar': ['هل', 'تريد', 'أن', 'تستمع؟'] },
    { 'en': ['Who', 'is', 'in', 'the', 'house?'], 'ar': ['من', 'في', 'البيت؟'] },
    { 'en': ['This', 'book', 'will', 'do.'], 'ar': ['هذا', 'الكتاب', 'سيفي', 'بالغرض.'] },
    { 'en': ["I'm", 'not', 'in', 'a', 'hurry.'], 'ar': ['أنا', 'لست', 'مستعجلاً.'] },
    { 'en': ['You', 'keep', 'out', 'of', 'this.'], 'ar': ['لا', 'تتدخل.'] },
    { 'en': ['It', "wasn't", 'a', 'pleasant', 'job.'], 'ar': ['لم', 'يكن', 'عملاً', 'مرضٍ.'] },
    { 'en': ['She', 'asked', 'us', 'to', 'leave', 'her', 'alone.'], 'ar': ['طلبت', 'منا', 'أن', 'نتركها', 'وحدها.'] },
    { 'en': ["Don't", 'worry.', 'He', 'knows', 'what', "he's", 'doing.'], 'ar': ['لا', 'تقلق.', 'إنهُ', 'يعرف', 'ما', 'يفعلهُ.'] },
    { 'en': ["There's", 'an', 'orange', 'on', 'the', 'table.'], 'ar': ['هناك', 'برتقالة', 'على', 'الطاولة.'] },
    { 'en': ['I', 'had', 'nothing', 'else', 'to', 'do.'], 'ar': ['لم', 'يكن', 'لديّ', 'أيّ', 'شيء', 'آخر', 'أقوم', 'به.'] },
    { 'en': ["I'm", 'tired.', "It's", 'been', 'a', 'long', 'day.'], 'ar': ['أنا', 'متعبة.', 'لقد', 'كان', 'يوماً', 'طويلاً.'] },
    { 'en': ['Who', 'ate', 'the', 'bread?'], 'ar': ['مَن', 'أكل', 'الخبز؟'] },
    { 'en': ['Forget', 'it.'], 'ar': ['انسَ', 'الأمر.'] },
    { 'en': ['This', 'is', 'the', 'key', 'that', 'opens', 'that', 'door.'], 'ar': ['هذا', 'هو', 'المفتاح', 'الذي', 'يفتح', 'الباب.'] },
    { 'en': ['Speaking', 'English', "isn't", 'easy.'], 'ar': ['التحدث', 'بالإنجليزية', 'ليس', 'أمراً', 'سهلاً.'] },
    { 'en': ["He's", 'eating', 'an', 'apple.'], 'ar': ['إنه', 'يأكل', 'تفاحة.'] },
    { 'en': ['Add', 'up', 'these', 'figures.'], 'ar': ['اجمع', 'هذه', 'الأعداد.'] },
    { 'en': ['I', 'am', 'bored.'], 'ar': ['أنا', 'أشعر', 'بالملل.'] },
    { 'en': ['We', 'have', 'a', 'dog,', 'a', 'cat', 'and', 'three', 'canaries.'], 'ar': ['عندنا', 'كلبٌ،', 'وقط،', 'وثلاثة', 'من', 'الكناري.'] },
    { 'en': ['She', 'used', 'to', 'work', 'for', 'our', 'company.'], 'ar': ['كانت', 'تعمل', 'لدى', 'شركتنا.'] },
    { 'en': ['Tom', 'pretended', 'to', 'be', 'angry.'], 'ar': ['تظاهرَ', 'توم', 'بأنّه', 'غاضب.'] },
    { 'en': ['The', 'girl', 'was', 'afraid', 'of', 'her', 'own', 'shadow.'], 'ar': ['كانت', 'الفتاة', 'خائفة', 'من', 'ظلها.'] },
    { 'en': ['I', 'asked', 'him', 'if', 'he', 'knew', 'my', 'name.'], 'ar': ['سألته', 'إن', 'كان', 'يعرف', 'إسمي.'] },
    { 'en': ['It', "isn't", 'here', 'anymore.'], 'ar': ['لم', 'تعد', 'هنا', 'بعد', 'الآن.'] },
    { 'en': ["That's", 'easy', 'to', 'say.'], 'ar': ['قول', 'هذا', 'سهل.'] },
    { 'en': ['He', 'made', 'up', 'his', 'mind', 'to', 'keep', 'his', 'plan', 'secret.'], 'ar': ['قرر', 'أن', 'يبقي', 'خطته', 'سراً.'] },
    { 'en': ['Choose', 'any', 'flowers', 'you', 'like.'], 'ar': ['اختر', 'الورود', 'التي', 'تحبها.'] },
    { 'en': ['How', 'do', 'you', 'say', '"thank', 'you"', 'in', 'Japanese?'], 'ar': ['كيف', 'تقول', '"', 'شكراً', 'لك', '"', 'باليابانية؟'] },
    { 'en': ['Children', 'begin', 'school', 'at', 'the', 'age', 'of', 'six.'], 'ar': ['يبدأ', 'الأطفال', 'بالذهاب', 'إلى', 'المدرسة', 'في', 'سن', 'السادسة.'] },
    { 'en': ['She', 'walks.'], 'ar': ['هي', 'تمشي'] },
    { 'en': ['Can', 'Tom', 'really', 'swim', 'faster', 'than', 'you?'], 'ar': ['أيمكنه', 'توم', 'حقا', 'السباحة', 'أسرع', 'منك؟'] },
    { 'en': ["I'm", 'going', 'to', 'study.'], 'ar': ['أنا', 'ذاهب', 'لأدرس'] },
    { 'en': ['This', 'letter', 'is', 'addressed', 'to', 'you.'], 'ar': ['هذا', 'الخطاب', 'موجه', 'لك.'] },
    { 'en': ['I', 'admit', 'that', 'I', 'was', 'wrong.'], 'ar': ['أعترف', 'أني', 'كنت', 'مخطئًا.'] },
    { 'en': ['I', 'think', 'that', "you're", 'doing', 'the', 'right', 'thing.'], 'ar': ['أعتقد', 'بأنك', 'تفعل', 'الصواب'] },
    { 'en': ['She', 'is', 'very', 'intelligent.'], 'ar': ['إنها', 'ذكية', 'جداً.'] },
    { 'en': ["He's", 'a', 'tennis', 'player.'], 'ar': ['إنه', 'لاعب', 'تنس.'] },
    { 'en': ['Tom', "wasn't", 'aware', 'of', 'the', 'problem.'], 'ar': ['لم', 'يكن', '"توم"', 'يعي', 'المشكلة'] },
    { 'en': ["I'm", 'going', 'there', 'even', 'if', 'you', "don't", 'go.'], 'ar': ['انا', 'ذاهب', 'الى', 'هناك', 'حتى', 'ولو', 'لم', 'تذهب'] },
    { 'en': ['It', 'appears', 'the', 'storm', 'has', 'calmed', 'down.'], 'ar': ['يبدو', 'أن', 'العصافة', 'هدأت.'] },
    { 'en': ['I', 'worked', 'on', 'the', 'farm', 'all', 'day.'], 'ar': ['عملت', 'في', 'المزرعة', 'طوال', 'اليوم.'] },
    { 'en': ['Is', 'the', 'bank', 'far', 'from', 'here?'], 'ar': ['هل', 'المصرف', 'بعيد', 'عن', 'هنا؟'] },
    { 'en': ['I', 'remember', 'mailing', 'the', 'letter.'], 'ar': ['أذكر', 'أنني', 'أرسلت', 'الرسالة.'] },
    { 'en': ['I', "don't", 'expect', 'anything', 'from', 'you.'], 'ar': ['لا', 'أتوقع', 'منك', 'شيئا.'] },
    { 'en': ['I', 'really', 'need', 'to', 'ask', 'you', 'a', 'favor.'], 'ar': ['أنا', 'حقّا', 'بحاجة', 'لأطلب', 'منك', 'معروفا.'] },
    { 'en': ['We', 'played', 'soccer', 'yesterday.'], 'ar': ['لعبنا', 'كرة', 'القدم', 'بالأمس.'] },
    { 'en': ['Our', 'success', 'was', 'due', 'to', 'his', 'efforts.'], 'ar': ['نجاحنا', 'ثمرة', 'لمجهوداته.'] },
    { 'en': ['I', 'bought', 'every', 'book', 'on', 'Japan', 'I', 'could', 'find.'], 'ar': ['اِشتريت', 'كل', 'كتاب', 'وجدتّه', 'عن', 'اليابان.'] },
    { 'en': ['I', 'got', 'up', 'at', 'seven', 'this', 'morning.'], 'ar': ['استيقظت', 'السابعة', 'صباحاً.'] },
    { 'en': ['She', 'swindled', 'him.'], 'ar': ['لقد', 'إحتالت', 'عليه.'] },
    { 'en': ['I', 'have', 'to', 'go', 'the', 'bathroom.'], 'ar': ['يجب', 'عليي', 'الذهاب', 'إلى', 'الحمام'] },
    { 'en': ['I', 'heard', 'various', 'opinions.'], 'ar': ['لقد', 'سمعتُ', 'أراء', 'متنوعة.'] },
    { 'en': ['Lightning', 'precedes', 'thunder.'], 'ar': ['البرقُ', 'يسبقُ', 'الرعدَ'] },
    { 'en': ['Do', 'you', 'like', 'San', 'Francisco?'], 'ar': ['هل', 'تحب', 'سان', 'فرانسيسكو؟'] },
    { 'en': ['You', 'can', 'come', 'with', 'us.'], 'ar': ['أنتَ', 'يمكنكَ', 'أن', 'تأتي', 'معنا.'] },
    { 'en': ['They', 'know', 'Tom.'], 'ar': ['هم', 'يعرفون', 'توم'] },
    { 'en': ['Everyone', 'has', 'both', 'strong', 'and', 'weak', 'points.'], 'ar': ['كل', 'شخص', 'لديه', 'كلاً', 'من', 'نقاط', 'الضعف', 'والقوة.'] },
    { 'en': ['I', 'am', 'a', 'teacher,', 'too.'], 'ar': ['أنا', 'مدرس', 'أيضاً.'] },
    { 'en': ['How', 'was', 'your', "doctor's", 'appointment?'], 'ar': ['كيف', 'كان', 'موعدك', 'عند', 'الطّبيب؟'] },
    { 'en': ["It's", 'well', 'done.'], 'ar': ['حسنًا', 'عُمِلَ.'] },
    { 'en': ['I', 'asked', 'Tom', 'to', 'stop', 'following', 'us.'], 'ar': ['طلبت', 'من', 'توم', 'ألّا', 'يلاحقنا', 'أكثر.'] },
    { 'en': ["There's", 'been', 'a', 'lot', 'of', 'rain', 'this', 'year.'], 'ar': ['هطل', 'الكثير', 'من', 'المطر', 'هذه', 'السنة.'] },
    { 'en': ['Be', 'quiet', 'during', 'the', 'lesson.'], 'ar': ['حافظ', 'على', 'هدوئك', 'خلال', 'الدرس.'] },
    { 'en': ['She', 'likes', 'animals,', 'you', 'know?'], 'ar': ['لعلمك،', 'إنها', 'تحب', 'الحيوانات.'] },
    { 'en': ['Her', 'father', 'left', 'her', 'the', 'house', 'in', 'his', 'will.'], 'ar': ['ترك', 'أبوها', 'البيت', 'لها', 'في', 'وصيته.'] },
    { 'en': ['We', 'ran', 'in', 'the', 'park.'], 'ar': ['مشينا', 'في', 'الغابة'] },
    { 'en': ['The', 'castle', 'is', 'beautiful.'], 'ar': ['القلعة', 'خلابة.'] },
    { 'en': ['Today', 'is', 'Sunday.'], 'ar': ['اليوم', 'هو', 'الأحد'] },
    { 'en': ['I', "don't", 'think', 'Tom', 'would', 'want', 'to', 'do', 'that.'], 'ar': ['لا', 'أظنّن', 'توم', 'يريد', 'فعل', 'ذلك.'] },
    { 'en': ['What', 'does', 'he', 'see', 'in', 'her?'], 'ar': ['ما', 'الذي', 'يراهُ', 'فيها؟'] },
    { 'en': ['This', 'table', 'is', 'reserved.'], 'ar': ['حجزت', 'هذه', 'الطاولة.'] },
    { 'en': ['The', 'boss', 'gave', 'us', 'all', 'a', 'day', 'off.'], 'ar': ['أعطى', 'رئيس', 'العمل', 'الكل', 'إجازة.'] },
    { 'en': ["I'll", 'wait', 'upstairs.'], 'ar': ['سأنتظر', 'أعلى', 'الدرج.'] },
    { 'en': ['How', 'are', 'you', 'doing?'], 'ar': ['كيف', 'حالك؟'] },
    { 'en': ['My', 'mother', 'took', 'my', 'temperature.'], 'ar': ['قامت', 'أمي', 'بقياس', 'حرارة', 'جسدي.'] },
    { 'en': ["There's", 'been', 'an', 'accident.'], 'ar': ['حصل', 'حادث.'] },
    { 'en': ['You', 'arrived', 'too', 'early.'], 'ar': ['انتَ', 'وصلتَ', 'مبكراً', 'جداً.'] },
    { 'en': ['Life', 'in', 'a', 'small', 'town', 'is', 'boring.'], 'ar': ['الحياة', 'في', 'قرية', 'صغيرة', 'مملة.'] },
    { 'en': ['No', 'one', 'has', 'failed.'], 'ar': ['لم', 'يرسب', 'أحد.'] },
    { 'en': ['Is', 'this', 'fish', 'still', 'alive?'], 'ar': ['هل', 'هذه', 'السمكة', 'لازالت', 'حيّه', '؟'] },
    { 'en': ['I', 'like', 'hot', 'coffee.'], 'ar': ['أحب', 'أن', 'أشرب', 'القهوة', 'ساخنة'] },
    { 'en': ['Take', 'off', 'your', 'socks.'], 'ar': ['انزع', 'جوربيك.'] },
    { 'en': ['Easy', 'come,', 'easy', 'go.'], 'ar': ['ما', 'يأتي', 'سهلا', 'يذهب', 'سهلا.'] },
    { 'en': ['Is', 'somebody', 'out', 'there?'], 'ar': ['هل', 'هناك', 'أحد', 'بالخارج؟'] },
    { 'en': ['Why', 'would', 'we', 'want', 'to', 'do', 'that?'], 'ar': ['لم', 'سنرغب', 'بعمل', 'ذلك؟'] },
    { 'en': ['Almost', 'no', 'one', 'believed', 'her.'], 'ar': ['بالكاد', 'لم', 'يصدّقها', 'أحد.'] },
    { 'en': ['He', 'is', 'a', 'famous', 'artist.'], 'ar': ['إنه', 'فنّان', 'مشهور.'] },
    { 'en': ['You', 'can', 'swim,', "can't", 'you?'], 'ar': ['تستطيع', 'السباحة،', 'أليس', 'كذلك؟'] },
    { 'en': ["You're", 'rich.'], 'ar': ['أنت', 'غني'] },
    { 'en': ["We're", 'out', 'of', 'sugar.'], 'ar': ['لم', 'يعد', 'لدينا', 'سكر.'] },
    { 'en': ['There', 'was', 'no', 'one', 'in', 'the', 'room.'], 'ar': ['لم', 'يكن', 'هناك', 'أحد', 'في', 'الغرفة.'] },
    { 'en': ['Someone', 'is', 'watching', 'you.'], 'ar': ['هناك', 'من', 'ينظر', 'إليك.'] },
    { 'en': ['Good', 'morning.', 'We', 'have', 'good', 'weather', 'today.'], 'ar': ['صباح', 'الخير,', 'اليوم', 'لدينا', 'طقسُ', 'جميل.'] },
    { 'en': ["I'm", 'pleased', 'to', 'meet', 'you.'], 'ar': ['تشرّفت', 'بلقائك.'] },
    { 'en': ['The', 'police', 'got', 'to', 'the', 'scene', 'of', 'the', 'accident.'], 'ar': ['وصل', 'الشرطة', 'إلى', 'مكان', 'الحادثة.'] },
    { 'en': ['Check', 'everyone.'], 'ar': ['إفحص', 'كُل', 'واحد.'] },
    { 'en': ['After', 'taking', 'a', 'bath,', 'I', 'drank', 'juice.'], 'ar': ['شربت', 'عصيرا', 'بعد', 'الاستحمام.'] },
    { 'en': ['She', 'likes', 'oranges,', "doesn't", 'she?'], 'ar': ['إنها', 'تحب', 'البرتقال،', 'أليس', 'كذلك؟'] },
    { 'en': ['People', 'like', 'me.'], 'ar': ['الناس', 'يحبونني.'] },
    { 'en': ['Tom', 'is', 'extremely', 'handsome', 'and', 'he', 'knows', 'it.'], 'ar': ['توم', 'فائق', 'الجمال', 'وهو', 'يعلم', 'ذلك.'] },
    { 'en': ["There's", 'a', 'swimming', 'pool', 'and', 'a', 'bowling', 'alley.'], 'ar': ['يوجد', 'حمام', 'سياحة', 'وصالة', 'بولينج.'] },
    { 'en': ['You', 'must', 'do', 'your', 'best.'], 'ar': ['عليك', 'أن', 'تفعل', 'ما', 'بوسعك.'] },
    { 'en': ['This', 'is', 'the', 'biggest', 'cat', 'that', "I've", 'ever', 'seen.'], 'ar': ['هذه', 'أكبر', 'قطة', 'رأيتها', 'في', 'حياتي.'] },
    { 'en': ["You're", 'the', 'apple', 'of', 'my', 'eye.'], 'ar': ['أنتَ', 'قرة', 'عيني.'] },
    { 'en': ['My', 'friends', 'invited', 'me', 'to', 'dinner.'], 'ar': ['دعاني', 'أصحابي', 'على', 'العشاء.'] },
    { 'en': ["It's", 'cold', 'today!'], 'ar': ['الجو', 'بارد', 'اليوم!'] },
    { 'en': ['You', 'look', 'like', 'your', 'father.'], 'ar': ['أنت', 'تشبه', 'والدك.'] },
    { 'en': ['This', 'is', 'no', 'place', 'for', 'children.'], 'ar': ['هذا', 'ليس', 'مكانا', 'للأطفال.'] },
    { 'en': ['Lend', 'me', 'your', 'book,', 'please.'], 'ar': ['من', 'فضلك', 'أعرني', 'كتابك.'] },
    { 'en': ['If', 'you', 'want', 'to', 'dance,', "let's", 'dance', 'together.'], 'ar': ['إذا', 'اردت', 'أن', 'ترقُص,', 'هيا', 'نرقص', 'معاً.'] },
    { 'en': ['My', 'mother', 'is', 'making', 'a', 'cake', 'for', 'my', 'father.'], 'ar': ['تعد', 'أمي', 'كعكة', 'لأبي.'] },
    { 'en': ['You', "don't", 'know', 'who', 'I', 'am.'], 'ar': ['إنك', 'لا', 'تعرف', 'من', 'أكون.'] },
    { 'en': ['We', 'talked', 'for', 'a', 'while.'], 'ar': ['تحدثنا', 'لفترة.'] },
    { 'en': ['I', 'had', 'breakfast', 'at', '7:30.'], 'ar': ['تناولت', 'طعام', 'الفطور', 'الساعة', 'السابعة', 'و', 'النصف', 'صباحاً.'] },
    { 'en': ["I'm", 'a', 'little', 'tired.'], 'ar': ['إني', 'متعب', 'بعض', 'الشيء.'] },
    { 'en': ['He', "isn't", 'a', 'smoker.'], 'ar': ['إنه', 'ليس', 'مدخنا.'] },
    { 'en': ['A', 'good', 'idea', 'occurred', 'to', 'me.'], 'ar': ['خطر', 'لي', 'فكرة', 'جيدة.'] },
    { 'en': ['Why', 'did', 'you', 'buy', 'this', 'car?'], 'ar': ['لم', 'اشتريت', 'هذه', 'السيارة؟'] },
    { 'en': ["I'll", 'pay', 'the', 'bill.'], 'ar': ['سأدفع', 'الفاتورة.'] },
    { 'en': ['Take', 'care', 'of', 'yourself.'], 'ar': ['اعتنِ', 'بنفسك.'] },
    { 'en': ['Most', 'people', 'write', 'about', 'their', 'daily', 'life.'], 'ar': ['معظم', 'الناس', 'يكتبون', 'عن', 'حياتهم', 'اليومية.'] },
    { 'en': ['The', 'doctor', 'took', 'his', 'pulse.'], 'ar': ['قاس', 'الطبيب', 'نبضه.'] },
    { 'en': ['I', 'can', 'play', 'tennis.'], 'ar': ['أعرف', 'كيف', 'ألعب', 'كرة', 'المضرب.'] },
    { 'en': ['Can', 'you', 'prove', 'it?'], 'ar': ['أيمكنك', 'إثبات', 'ذلك؟'] },
    { 'en': ['Tom', 'said', 'that', 'Mary', 'was', 'a', 'good', 'swimmer.'], 'ar': ['توم', 'قال', 'ان', 'ماري', 'كانت', 'سباحة', 'جيدة.'] },
    { 'en': ['You', 'must', 'go.'], 'ar': ['عليك', 'الذهاب.'] },
    { 'en': ['I', 'like', 'you.'], 'ar': ['تعجبني.'] },
    { 'en': ["That's", 'pretty', 'normal.'], 'ar': ['هذا', 'طبيعي', 'نوعا', 'ما.'] },
    { 'en': ['They', 'started', 'working', 'right', 'away.'], 'ar': ['بدأوا', 'العمل', 'على', 'الفور.'] },
    { 'en': ['Have', 'we', 'met', 'before?'], 'ar': ['هل', 'تقابلنا', 'من', 'قبل؟'] },
    { 'en': ['Any', 'child', 'knows', 'it.'], 'ar': ['أي', 'طفل', 'يعرف', 'ذلك.'] },
    { 'en': ['I', 'owe', 'my', 'success', 'to', 'my', 'friend.'], 'ar': ['أدين', 'بنجاحي', 'لصديقي.'] },
    { 'en': ['I', 'bought', 'a', 'red', 'tie.'], 'ar': ['اِشتريت', 'ربطة', 'عنق', 'حمراء.'] },
    { 'en': ['Where', 'did', 'he', 'go?'], 'ar': ['أين', 'ذهب؟'] },
    { 'en': ['He', 'lost', 'two', 'sons', 'in', 'the', 'war.'], 'ar': ['فقد', 'ابنين', 'في', 'الحرب.'] },
    { 'en': ['Take', 'this', 'chair.', "It's", 'sturdy.'], 'ar': ['خذ', 'هذا', 'الكرسي', 'فهو', 'صلب.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['كيفَ', 'تعلمين', 'هذا؟'] },
    { 'en': ['Tom', 'and', 'Mary', 'drank', 'champagne.'], 'ar': ['توم', 'و', 'ماري', 'شربا', 'الشمبانيا'] },
    { 'en': ['Nobody', 'cares', 'about', 'that.'], 'ar': ['لا', 'أحد', 'يهتمّ', 'بهذا', '.'] },
    { 'en': ['I', 'also', 'found', 'this.'], 'ar': ['أنا', 'وجدت', 'ذلك', 'أيضا.'] },
    { 'en': ['He', 'fooled', 'her.'], 'ar': ['خدعها.'] },
    { 'en': ['Do', 'you', 'remember', 'what', 'you', 'did', 'last', 'Friday?'], 'ar': ['أتذكرُ', 'ما', 'فعلتَ', 'الجمعة', 'الماضية؟'] },
    { 'en': ['He', 'was', 'hit', 'by', 'a', 'car', 'and', 'died', 'right', 'away.'], 'ar': ['صدمته', 'سيارة', 'و', 'مات', 'على', 'الفور.'] },
    { 'en': ['Does', 'it', 'matter?'], 'ar': ['هل', 'يهم؟'] },
    { 'en': ["Don't", 'play', 'dumb!'], 'ar': ['لا', 'تتظاهر', 'بالغباء!'] },
    { 'en': ["You've", 'been', 'deceived.'], 'ar': ['لقد', 'خدعت.'] },
    { 'en': ["There's", 'a', 'possibility', 'of', 'war.'], 'ar': ['من', 'المحتمل', 'أن', 'تندلع', 'الحرب.'] },
    { 'en': ["It's", 'the', 'end', 'of', 'a', 'world.'], 'ar': ['انها', 'نهاية', 'العالم.'] },
    { 'en': ["It's", 'been', 'stolen.'], 'ar': ['لقد', 'تم', 'سرقتها.'] },
    { 'en': ['I', 'have', 'more', 'important', 'things', 'to', 'do.'], 'ar': ['لدي', 'أشياء', 'أهم', 'لأفعلها.'] },
    { 'en': ['Please', "don't", 'cry.'], 'ar': ['لا', 'تبكِ', 'من', 'فضلك.'] },
    { 'en': ['A', 'woman', 'without', 'a', 'man', 'is', 'nothing.'], 'ar': ['المرأة', 'بلا', 'رجل', 'لا', 'شيء.'] },
    { 'en': ['Tom', 'threw', 'Mary', 'out', 'of', 'the', 'bar.'], 'ar': ['رمى', 'توم', 'ماري', 'خارج', 'البار.'] },
    { 'en': ["I'm", 'still', 'here.'], 'ar': ['أنا', 'ما', 'زلت', 'هنا.'] },
    { 'en': ['A', 'little', 'knowledge', 'can', 'be', 'a', 'dangerous', 'thing.'], 'ar': ['القليل', 'من', 'المعرفة', 'يمكن', 'أن', 'يكون', 'شيء', 'خطير.'] },
    { 'en': ['The', 'shop', 'is', 'closed', 'on', 'Sunday.'], 'ar': ['المحل', 'مغلق', 'يوم', 'الأحد.'] },
    { 'en': ["He's", 'crazy', 'about', 'you.'], 'ar': ['إنه', 'مجنون', 'بحبك.'] },
    { 'en': ['The', 'bath', 'is', 'ready.'], 'ar': ['الحمّام', 'جاهز.'] },
    { 'en': ['What', 'are', 'you', 'looking', 'for?'], 'ar': ['عن', 'ماذا', 'تبحث؟'] },
    { 'en': ['My', 'dog', 'often', 'lies', 'on', 'the', 'grass.'], 'ar': ['عادة', 'ما', 'يستلقي', 'كلبي', 'على', 'العشب.'] },
    { 'en': ["There's", 'something', 'I', 'want', 'to', 'show', 'you.'], 'ar': ['هناك', 'شيئاً', 'ما', 'أريد', 'أن', 'ُأريكَ', 'إياه.'] },
    { 'en': ['Long', 'time,', 'no', 'see.'], 'ar': ['لم', 'أرك', 'منذ', 'زمن.'] },
    { 'en': ['She', 'can', 'speak', 'French.'], 'ar': ['بإمكانها', 'تحدث', 'الفرنسية.'] },
    { 'en': ['I', 'stay', 'in', 'Osaka.'], 'ar': ['أسكن', 'في', 'أوساكا.'] },
    { 'en': ['Stay', 'thin.'], 'ar': ['حافظي', 'على', 'جسم', 'نحيف.'] },
    { 'en': ['Tom', 'died', 'yesterday.'], 'ar': ['مات', 'توم', 'أمس.'] },
    { 'en': ["I'm", 'sure', "you're", 'mistaken.'], 'ar': ['أنا', 'متأكدة', 'أنكِ', 'مخطئة.'] },
    { 'en': ['Is', 'Tom', 'there,', 'too?'], 'ar': ['هل', 'توم', 'هناك', 'أيضاً؟'] },
    { 'en': ['I', 'made', 'her', 'angry.'], 'ar': ['أغضبتها.'] },
    { 'en': ['Have', 'you', 'ever', 'written', 'a', 'computer', 'program?'], 'ar': ['أكتبت', 'من', 'قبل', 'برنامجا', 'حاسوبيا؟'] },
    { 'en': ['Well,', "let's", 'get', 'going!'], 'ar': ['هيا', 'لنذهب!'] },
    { 'en': ['My', 'brother', 'uses', 'this', 'bike.'], 'ar': ['أخي', 'يركب', 'هذه', 'الدراجة.'] },
    { 'en': ['The', 'radio', 'will', 'not', 'work.'], 'ar': ['لن', 'يعمل', 'الراديو.'] },
    { 'en': ['Tom', 'came', 'up', 'with', 'a', 'new', 'technique.'], 'ar': ['جاء', 'توم', 'بتقنية', 'جديدة.'] },
    { 'en': ['I', "can't", 'accept', 'your', 'gift.'], 'ar': ['لا', 'أستطيع', 'قبول', 'هديتِكَ.'] },
    { 'en': ['You', 'are', 'always', 'complaining.'], 'ar': ['كل', 'ما', 'تفعله', 'هو', 'الشكوى.'] },
    { 'en': ['He', 'loves', 'rituals.'], 'ar': ['هو', 'يحبّ', 'الطّقوس.'] },
    { 'en': ['You', 'must', 'help', 'your', 'mother.'], 'ar': ['عليك', 'أن', 'تساعد', 'والدتك.'] },
    { 'en': ["Who's", 'your', 'favorite', 'pianist?'], 'ar': ['من', 'هو', 'أحب', 'عازفي', 'البيانو', 'إليك.'] },
    { 'en': ['He', 'took', 'a', 'picture', 'of', 'the', 'koala.'], 'ar': ['صوّر', 'الكوالا.'] },
    { 'en': ['You', 'look', 'sick.'], 'ar': ['يبدو', 'عليك', 'المرض.'] },
    { 'en': ['Put', 'it', 'down.'], 'ar': ['ضعها', 'أرضاً.'] },
    { 'en': ['That', 'dress', 'looks', 'good', 'on', 'you.'], 'ar': ['ذلك', 'الفستان', 'يبدو', 'جميلاً', 'عليك.'] },
    { 'en': ['She', 'has', 'very', 'few', 'close', 'friends.'], 'ar': ['لها', 'القليل', 'من', 'الأصدقاء', 'المقربين.'] },
    { 'en': ['I', 'went', 'to', 'the', 'hospital', 'yesterday.'], 'ar': ['ذهبت', 'إلى', 'المستشفى', 'البارحة.'] },
    { 'en': ['He', 'was', 'covered', 'in', 'mud', 'from', 'head', 'to', 'foot.'], 'ar': ['غطاه', 'الوحل', 'من', 'رأسه', 'إلى', 'أخمص', 'قدميه.'] },
    { 'en': ['You', 'can', 'say', 'whatever', 'you', 'want.'], 'ar': ['يُمكِنَك', 'أن', 'تقول', 'ما', 'تريد.'] },
    { 'en': ['This', 'bicycle', 'is', 'mine.'], 'ar': ['تلك', 'الدراجة', 'دراجتي.'] },
    { 'en': ['Tom', 'is', 'planning', 'to', 'study', 'abroad', 'next', 'year.'], 'ar': ['يخطط', 'توم', 'للدراسة', 'بالخارج', 'السنة', 'القادمة.'] },
    { 'en': ["I'm", 'not', 'good', 'at', 'lying.'], 'ar': ['لست', 'بارعا', 'في', 'الكذب.'] },
    { 'en': ['Where', 'are', 'the', 'lifeboats?'], 'ar': ['أين', 'زوارق', 'النجاة؟'] },
    { 'en': ['What', 'do', 'you', 'want', 'to', 'talk', 'about?'], 'ar': ['ما', 'الذي', 'تريد', 'أن', 'تتحدث', 'عنه؟'] },
    { 'en': ['There', 'was', 'a', 'car', 'accident', 'yesterday.'], 'ar': ['حصل', 'حادث', 'سير', 'البارحة.'] },
    { 'en': ['The', 'others', 'will', 'arrive', 'in', 'a', 'few', 'minutes.'], 'ar': ['الآخرون', 'على', 'وصول.'] },
    { 'en': ['Why', "couldn't", 'you', 'sleep', 'last', 'night?'], 'ar': ['لماذا', 'لم', 'تستطع', 'النوم', 'ليلة', 'أمس', '؟'] },
    { 'en': ['A', 'hungry', 'man', 'is', 'an', 'angry', 'man.'], 'ar': ['الجوعُ', 'كافرٌ.'] },
    { 'en': ['Just', 'imitate', 'what', 'he', 'does.'], 'ar': ['قلّده', 'و', 'حسب.'] },
    { 'en': ['They', 'arrived', 'one', 'after', 'another.'], 'ar': ['وصلوا', 'واحداً', 'خلف', 'الآخر.'] },
    { 'en': ['Rome', "wasn't", 'built', 'in', 'a', 'day.'], 'ar': ['لم', 'تُبنَ', 'روما', 'في', 'يوم.'] },
    { 'en': ['Stop', 'hitting', 'me!'], 'ar': ['توقفي', 'عن', 'ضربي.'] },
    { 'en': ['How', 'long', 'will', 'the', 'storm', 'last?'], 'ar': ['كم', 'ستستمر', 'العاصفة؟'] },
    { 'en': ['I', 'cooked', 'breakfast', 'for', 'you.'], 'ar': ['لقد', 'أعددت', 'لك', 'الفطور'] },
    { 'en': ['How', 'are', 'you', 'now?'], 'ar': ['كيف', 'حالك', 'الآن؟'] },
    { 'en': ['I', 'am', 'from', 'Shizuoka.'], 'ar': ['أنا', 'من', 'شزوكا.'] },
    { 'en': ["He'll", 'be', 'there', 'in', 'ten', 'minutes.'], 'ar': ['سيكون', 'هناك', 'بعد', 'عشر', 'دقائق.'] },
    { 'en': ['I', "don't", 'want', 'to', 'sleep.'], 'ar': ['لا', 'أريد', 'النوم'] },
    { 'en': ["It's", 'too', 'expensive!'], 'ar': ['إنها', 'غالية', 'جداً!'] },
    { 'en': ['I', 'knew', 'it', "couldn't", 'be', 'that', 'easy.'], 'ar': ['علمت', 'بأن', 'ذلك', 'لن', 'يكون', 'سهلا', 'بهذا', 'القدر.'] },
    { 'en': ["I'm", 'doing', 'this', 'for', 'you.'], 'ar': ['إنني', 'أقوم', 'بذلك', 'من', 'أجلك.'] },
    { 'en': ['You', 'should', 'pay', 'your', 'debts.'], 'ar': ['عليك', 'أن', 'تدفع', 'ما', 'عليك', 'من', 'دين.'] },
    { 'en': ['Give', 'me', 'your', 'opinion,', 'please.'], 'ar': ['أعطني', 'رأيك', 'من', 'فضلك.'] },
    { 'en': ['I', 'live', 'here.'], 'ar': ['أعيش', 'هنا.'] },
    { 'en': ['Does', 'this', 'remind', 'you', 'of', 'anybody?'], 'ar': ['هل', 'يذكّر', 'هذا', 'بشخص', 'ما؟'] },
    { 'en': ['We', 'named', 'my', 'son', 'after', 'my', 'grandfather.'], 'ar': ['سمّينا', 'إبننا', 'على', 'إسم', 'جدي.'] },
    { 'en': ['How', 'was', 'your', 'vacation?'], 'ar': ['كيف', 'كانت', 'إجازتك؟'] },
    { 'en': ['Tell', 'me', 'about', 'him.'], 'ar': ['احكِ', 'لي', 'عنه', '.'] },
    { 'en': ['We', 'hurried', 'to', 'the', 'train', 'station.'], 'ar': ['أسرعنا', 'إلى', 'محطة', 'القطار.'] },
    { 'en': ['Nobody', 'lives', 'in', 'this', 'house.'], 'ar': ['لا', 'يعيش', 'أحد', 'في', 'هذا', 'المنزل.'] },
    { 'en': ['I', 'will', 'transfer', 'the', 'money.'], 'ar': ['سأحوّل', 'مبلغ', 'المال.'] },
    { 'en': ['Will', 'you', 'sell', 'your', 'house', 'to', 'him?'], 'ar': ['هل', 'ستبيع', 'منزلك', 'له؟'] },
    { 'en': ['Your', 'guests', 'are', 'waiting.'], 'ar': ['ضيوفك', 'ينتظرون.'] },
    { 'en': ['Come', 'quickly!'], 'ar': ['تعالَ', 'بسرعة!'] },
    { 'en': ['They', 'are', 'proud', 'of', 'their', 'daughter.'], 'ar': ['إنهما', 'فخوران', 'بابنتهما.'] },
    { 'en': ['Here', 'comes', 'the', 'bus!'], 'ar': ['ها', 'قد', 'أتى', 'الباص!'] },
    { 'en': ['Did', 'you', 'actually', 'see', 'the', 'accident?'], 'ar': ['هل', 'رأيت', 'الحادث', 'حقا؟'] },
    { 'en': ['I', 'said', 'that.'], 'ar': ['قلت', 'ذلك.'] },
    { 'en': ['They', 'say', 'love', 'is', 'blind.'], 'ar': ['يُقال', 'أن', 'الحب', 'أعمى.'] },
    { 'en': ['Which', 'book', 'is', 'better?'], 'ar': ['أي', 'كتاب', 'أفضل؟'] },
    { 'en': ['Can', 'I', 'bring', 'someone?'], 'ar': ['هل', 'أستطيع', 'أن', 'أُحضر', 'أحداً', 'معي؟'] },
    { 'en': ['Guacamole', 'is', 'a', 'dip', 'made', 'from', 'avocados.'], 'ar': ['صلصة', 'غواكامولي', 'هي', 'صلصة', 'مصنوعة', 'من', 'الأفوكادو.'] },
    { 'en': ['Where', 'is', 'the', 'pain?'], 'ar': ['أين', 'تحس', 'بالوجع؟'] },
    { 'en': ['This', 'word', 'has', 'two', 'meanings.'], 'ar': ['هذه', 'الكلمة', 'لها', 'معنيان.'] },
    { 'en': ['I', 'like', 'helping', 'others.'], 'ar': ['أحب', 'مساعدة', 'الآخرين.'] },
    { 'en': ["I'm", 'not', 'going', 'to', 'turn', 'my', 'back', 'on', 'Tom.'], 'ar': ['لن', 'أدير', 'ظهري', 'لِتوم.'] },
    { 'en': ['I', 'usually', 'go', 'for', 'a', 'walk', 'every', 'morning.'], 'ar': ['عادة', 'ما', 'أتمشى', 'كل', 'صباح.'] },
    { 'en': ['My', 'aim', 'is', 'to', 'be', 'a', 'doctor.'], 'ar': ['حلمي', 'أن', 'أكون', 'طبيباً.'] },
    { 'en': ['Stay', 'thin.'], 'ar': ['إبقي', 'نحيفة.'] },
    { 'en': ['Though', 'I', 'was', 'tired,', 'I', 'did', 'my', 'best.'], 'ar': ['مع', 'أني', 'كنت', 'متعبا', 'إلا', 'أني', 'قدمت', 'أفضل', 'ما', 'عندي.'] },
    { 'en': ['She', 'looks', 'as', 'if', 'she', 'were', 'drunk.'], 'ar': ['بدت', 'كأنها', 'سكرت.'] },
    { 'en': ['Do', 'you', 'like', 'it', 'or', 'not?'], 'ar': ['هل', 'تحب', 'ذلك', 'أم', 'لا؟'] },
    { 'en': ['She', 'played', 'a', 'tune', 'on', 'the', 'piano.'], 'ar': ['عزفت', 'لحناً', 'على', 'البيانو.'] },
    { 'en': ["It's", 'on', 'the', 'eighth', 'floor.'], 'ar': ['إنه', 'في', 'الطابق', 'الثامن.'] },
    { 'en': ['Finally', 'we', 'arrived', 'at', 'the', 'lake.'], 'ar': ['أخيراً', 'وصلنا', 'للبحيرة.'] },
    { 'en': ['I', 'need', 'that', 'medicine.'], 'ar': ['أحتاج', 'هذا', 'العلاج.'] },
    { 'en': ['Tom', 'promised', 'to', 'be', 'here', 'at', '2:30.'], 'ar': ['وعدني', 'توم', 'بأنّه', 'سيكون', 'هنا', 'عند', '2:30.'] },
    { 'en': ['I', 'live', 'near', 'here.'], 'ar': ['أعيش', 'بالقرب', 'من', 'هنا.'] },
    { 'en': ['He', 'has', 'left', 'us.'], 'ar': ['هو', 'تركنا.'] },
    { 'en': ['Mary', 'wanted', 'to', 'know', 'if', 'Tom', 'had', 'a', 'girlfriend.'], 'ar': ['أرادت', 'ماري', 'معرفة', 'ما', 'إن', 'كان', 'لِتوم', 'حبيبة.'] },
    { 'en': ['This', 'story', 'is', 'based', 'on', 'a', 'true', 'story.'], 'ar': ['هذه', 'القصة', 'مُستمّدة', 'من', 'قصّةٍ', 'واقعية.'] },
    { 'en': ['I', 'can', 'jump.'], 'ar': ['يمكنني', 'أن', 'أقفز.'] },
    { 'en': ["It's", 'nearly', 'three', "o'clock."], 'ar': ['شارفت', 'الساعة', 'على', 'الثالثة.'] },
    { 'en': ["What's", 'everyone', 'eating', 'for', 'lunch?'], 'ar': ['ماذا', 'ستأكلون', 'على', 'الغداء؟'] },
    { 'en': ['We', 'were', 'all', 'present', 'at', 'her', 'wedding.'], 'ar': ['كنا', 'جميعنا', 'حاضرون', 'وقت', 'حفل', 'زفافها.'] },
    { 'en': ['Why', "didn't", 'you', 'believe', 'me?'], 'ar': ['لمَ', 'لم', 'تصدقني؟'] },
    { 'en': ['Do', 'you', 'think', 'it', 'was', 'an', 'accident?'], 'ar': ['أتظنّها', 'كانت', 'حادثة؟'] },
    { 'en': ['Tom', "doesn't", 'seem', 'to', 'know', 'a', 'lot', 'about', 'Mary.'], 'ar': ['لا', 'يبدو', 'بأن', 'توم', 'يعرف', 'الكثير', 'عن', 'ماري.'] },
    { 'en': ['Please', 'lock', 'the', 'door', 'when', 'you', 'leave.'], 'ar': ['يرجى', 'أن', 'تقفل', 'الباب', 'عند', 'مغادرتك.'] },
    { 'en': ['The', 'dog', 'is', 'blind', 'in', 'one', 'eye.'], 'ar': ['الكلب', 'أعور.'] },
    { 'en': ['I', 'have', 'a', 'friend', 'in', 'England.'], 'ar': ['أنا', 'لدي', 'صديق', 'في', 'إنجلترا.'] },
    { 'en': ['Are', 'you', 'going', 'with', 'us?'], 'ar': ['أذاهب', 'أنت', 'معنا؟'] },
    { 'en': ['He', 'works', 'hard', 'to', 'support', 'his', 'large', 'family.'], 'ar': ['يعمل', 'جاهداً', 'ليعيل', 'أسرته', 'الكبيرة.'] },
    { 'en': ['Your', 'pencils', 'need', 'sharpening.'], 'ar': ['تحتاج', 'أقلامك', 'إلى', 'بري.'] },
    { 'en': ['I', 'managed', 'to', 'get', 'in.'], 'ar': ['استطعت', 'الدخول.'] },
    { 'en': ['Good', 'luck.'], 'ar': ['بالتوفيق.'] },
    { 'en': ['All', 'you', 'have', 'to', 'do', 'is', 'try', 'your', 'best.'], 'ar': ['كل', 'ما', 'عليك', 'فعله', 'هو', 'بذل', 'جهدك.'] },
    { 'en': ['He', 'let', 'Tom', 'go.'], 'ar': ['هو', 'تركَ', 'توم', 'يذهب.'] },
    { 'en': ['You', 'have', 'a', 'good', 'memory.'], 'ar': ['عندك', 'ذاكرة', 'حادة.'] },
    { 'en': ['Why', 'do', 'these', 'things', 'happen', 'to', 'me?'], 'ar': ['لماذا', 'تحدث', 'هذه', 'الأشياء', 'لي؟'] },
    { 'en': ['Your', 'necktie', 'matches', 'your', 'suit.'], 'ar': ['ربطة', 'عنقك', 'تليق', 'ببزتك.'] },
    { 'en': ['May', 'I', 'hold', 'your', 'hand?'], 'ar': ['هل', 'تسمحين', 'لي', 'أن', 'أمسك', 'يدك؟'] },
    { 'en': ['Muslims', 'believe', 'there', 'is', 'only', 'one', 'God.'], 'ar': ['يؤمن', 'المسلمون', 'أنّه', 'هناك', 'ربّ', 'واحد', 'فقط.'] },
    { 'en': ['I', 'did', 'that', 'without', 'consulting', 'anyone.'], 'ar': ['فعلتُ', 'ذلك', 'دون', 'استشارة', 'أحد.'] },
    { 'en': ["What's", 'wrong', 'with', 'what', 'we', 'did?'], 'ar': ['ما', 'المشكلة', 'فيما', 'قمنا', 'به؟'] },
    { 'en': ["He's", 'accustomed', 'to', 'traveling.'], 'ar': ['إنه', 'معتاد', 'على', 'السفر.'] },
    { 'en': ['Does', 'it', 'look', 'OK?'], 'ar': ['هل', 'تبدو', 'بخير؟'] },
    { 'en': ['They', 'are', 'the', 'same', 'age.'], 'ar': ['هما', 'في', 'نفس', 'العمر.'] },
    { 'en': ['We', 'are', 'working', 'in', 'the', 'interest', 'of', 'peace.'], 'ar': ['نحن', 'نعمل', 'لأجل', 'السلام.'] },
    { 'en': ['How', 'many', 'rooms', 'are', 'there', 'in', 'your', 'house?'], 'ar': ['كم', 'غرفة', 'في', 'بيتك؟'] },
    { 'en': ['I', 'have', 'an', 'Italian', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جارة', 'إيطالية.'] },
    { 'en': ['Women', 'like', 'attention.'], 'ar': ['تحبّ', 'النّساء', 'جلب', 'الانتباه.'] },
    { 'en': ['Hamlet', 'is', 'a', 'play', 'by', 'Shakespeare.'], 'ar': ['هاملت', 'مسرحية', 'لشكسبير.'] },
    { 'en': ['I', 'would', 'like', 'to', 'speak', 'to', 'the', 'head', 'nurse.'], 'ar': ['أود', 'التحدث', 'إلى', 'رئيس', 'الممرضين.'] },
    { 'en': ['Any', 'book', 'will', 'do.'], 'ar': ['أي', 'كتاب', 'سيفي', 'بالغرض.'] },
    { 'en': ['She', 'might', 'come', 'tomorrow.'], 'ar': ['ربما', 'تأتي', 'غدًا.'] },
    { 'en': ['The', 'river', 'is', 'running', 'dry.'], 'ar': ['النهر', 'يجف.'] },
    { 'en': ["I'm", 'beginning', 'to', 'feel', 'cold.'], 'ar': ['بدأت', 'أبرد.'] },
    { 'en': ['I', 'came', 'by', 'car.'], 'ar': ['أتيت', 'بالسيارة.'] },
    { 'en': ['The', 'rain', 'lasted', 'three', 'days.'], 'ar': ['استمر', 'المطر', 'ثلاثة', 'أيام.'] },
    { 'en': ['The', 'baby', 'cried', 'all', 'night.'], 'ar': ['بقي', 'الرضيع', 'يبكي', 'طول', 'الليل.'] },
    { 'en': ['Does', 'Tom', 'have', 'a', 'daughter', 'named', 'Mary?'], 'ar': ['هل', 'لتوم', 'ابنة', 'اسمها', 'ماري؟'] },
    { 'en': ['Hi!', "What's", 'new?'], 'ar': ['هلا!', 'ما', 'الجديد؟'] },
    { 'en': ['Go', 'back', 'to', 'your', 'seat.'], 'ar': ['عُد', 'إلى', 'مقعدك.'] },
    { 'en': ['Can', 'I', 'take', 'some', 'of', 'the', 'samples?'], 'ar': ['هل', 'يمكنني', 'أن', 'آخذ', 'بعض', 'العينات؟'] },
    { 'en': ["They're", 'happy.'], 'ar': ['هم', 'سعداء'] },
    { 'en': ['Tom', 'turned', 'the', 'lights', 'off.'], 'ar': ['أطفئ', 'توم', 'الأنوار.'] },
    { 'en': ['Get', 'me', 'a', 'chair,', 'please.'], 'ar': ['أحضر', 'لي', 'كرسياً', 'من', 'فضلك.'] },
    { 'en': ['This', 'is', 'the', 'time', 'he', 'normally', 'arrives.'], 'ar': ['هذا', 'هو', 'الوقت', 'الذي', 'يصل', 'فيه', 'عادة.'] },
    { 'en': ["I'll", 'teach', 'you', 'how', 'to', 'drive', 'a', 'car.'], 'ar': ['سأعلمك', 'كيف', 'تقود', 'السيارة.'] },
    { 'en': ['I', "don't", 'have', 'a', 'daddy.'], 'ar': ['ليس', 'لديّ', 'أب.'] },
    { 'en': ['Are', 'you', 'sure', 'you', "haven't", 'forgotten', 'anything?'], 'ar': ['هل', 'أنت', 'متأكد', 'أنك', 'لم', 'تنسَ', 'شيئاً؟'] },
    { 'en': ['You', 'are', 'everything', 'to', 'me.'], 'ar': ['أنت', '-بالنسبة', 'لي-', 'كل', 'شيء.'] },
    { 'en': ["Let's", 'synchronize', 'our', 'watches.'], 'ar': ['لنضبط', 'ساعاتنا', 'على', 'نفس', 'التوقيت.'] },
    { 'en': ['Come', 'on!'], 'ar': ['هيّا'] },
    { 'en': ['A', 'loud', 'noise', 'in', 'the', 'night', 'scared', 'him.'], 'ar': ['الضوضاء', 'الصاخبه', 'في', 'الليل', 'اخافته.'] },
    { 'en': ["I've", 'decided', 'to', 'stay', 'for', 'a', 'few', 'more', 'days.'], 'ar': ['قرّرتُ', 'البقاء', 'لبضعة', 'أيام', 'أُخر.'] },
    { 'en': ['Carry', 'this.'], 'ar': ['احمل', 'هذا.'] },
    { 'en': ['Do', 'you', 'speak', 'Albanian?'], 'ar': ['هل', 'تتحدث', 'الألبانية؟'] },
    { 'en': ["I'm", 'going', 'to', 'need', 'some', 'more', 'time.'], 'ar': ['سأحتاج', 'إلى', 'المزيد', 'من', 'الوقت.'] },
    { 'en': ['Lots', 'of', 'famous', 'people', 'come', 'here.'], 'ar': ['الكثير', 'من', 'المشاهير', 'يأتون', 'إلى', 'هنا.'] },
    { 'en': ['Tom', 'liked', 'to', 'play', 'chess.'], 'ar': ['كان', 'توم', 'يحب', 'لعب', 'الشطرنج'] },
    { 'en': ['People', 'laughed', 'at', 'him.'], 'ar': ['ضحك', 'الناس', 'عليه.'] },
    { 'en': ['It', "didn't", 'happen', 'that', 'way.'], 'ar': ['لم', 'يحدث', 'هكذا.'] },
    { 'en': ['You', 'may', 'be', 'right.'], 'ar': ['من', 'الممكن', 'أن', 'يكون', 'ما', 'تقوله', 'صحيح.'] },
    { 'en': ['Is', 'dinner', 'ready?'], 'ar': ['هل', 'العشاء', 'جاهز؟'] },
    { 'en': ['You', 'can', 'always', 'ask', 'me', 'for', 'help.'], 'ar': ['يمكنكَ', 'دائماً', 'أن', 'تسألني', 'للمساعدة.'] },
    { 'en': ["I'll", 'be', 'there', 'till', '2:30.'], 'ar': ['سأكون', 'هناك', 'حتى', '2:30.'] },
    { 'en': ['My', 'clock', 'needs', 'to', 'be', 'fixed.'], 'ar': ['تحتاج', 'ساعتي', 'إلى', 'الإصلاح.'] },
    { 'en': ["You're", 'in', 'safe', 'hands', 'now.'], 'ar': ['إنك', 'بأيدي', 'أمينة', 'الآن.'] },
    { 'en': ['We', 'played', 'basketball', 'yesterday.'], 'ar': ['لعبنا', 'كرة', 'السلة', 'بالأمس.'] },
    { 'en': ['I', "wasn't", 'aware', 'that', 'Tom', 'should', 'do', 'that.'], 'ar': ['لم', 'أكن', 'أعلم', 'بأنه', 'على', 'توم', 'فعل', 'ذلك.'] },
    { 'en': ['Last', 'night,', 'he', 'studied', 'all', 'night', 'long.'], 'ar': ['درس', 'البارحة', 'طوال', 'الليل.'] },
    { 'en': ['Someone', 'finally', 'called', '911.'], 'ar': ['وأخيرا', 'اتصل', 'أحدهم', 'ب', '911.'] },
    { 'en': ['I', 'hear', 'you.'], 'ar': ['أنا', 'أسمعك'] },
    { 'en': ['I', 'really', 'miss', 'you.'], 'ar': ['اشتقت', 'إليك', 'حقا'] },
    { 'en': ['Tom', 'likes', 'cheeseburgers.'], 'ar': ['توم', 'يحب', 'شطائر', 'الجبنة.'] },
    { 'en': ['He', 'speaks', 'English', 'with', 'a', 'German', 'accent.'], 'ar': ['إنه', 'يتكلم', 'الإنجليزية', 'بلكنة', 'ألمانية.'] },
    { 'en': ['There', 'is', 'no', 'doubt', 'about', 'it.'], 'ar': ['لا', 'شك', 'في', 'ذلك.'] },
    { 'en': ['Tom', 'yawned.'], 'ar': ['توم', 'تثاءب.'] },
    { 'en': ['He', 'got', 'thrown', 'out', 'of', 'the', 'house.'], 'ar': ['أُلقي', 'خارج', 'المنزل.'] },
    { 'en': ['She', 'is', 'in', 'the', 'bathroom.'], 'ar': ['إنها', 'في', 'الحمام.'] },
    { 'en': ['Who', 'knows?'], 'ar': ['من', 'يدري؟'] },
    { 'en': ['Thank', 'you.'], 'ar': ['شكراً', 'لك'] },
    { 'en': ["It's", 'easier', 'to', 'have', 'fun', 'than', 'to', 'work.'], 'ar': ['الاستمتاع', 'أسهل', 'من', 'العمل.'] },
    { 'en': ['Invite', 'us', 'to', 'dinner', 'at', 'a', 'restaurant.'], 'ar': ['ادعنا', 'للعشاء', 'في', 'مطعم.'] },
    { 'en': ["I've", 'been', 'asked', 'to', 'help', 'you.'], 'ar': ['لقدطلب', 'مني', 'مساعدتك.'] },
    { 'en': ['Can', 'you', 'tell', 'me', 'the', 'way', 'to', 'the', 'library?'], 'ar': ['هل', 'يمكنك', 'إخباري', 'الطريق', 'إلي', 'المكتبة؟'] },
    { 'en': ['I', "don't", 'know', 'whether', 'Tom', 'is', 'hungry', 'or', 'not.'], 'ar': ['لا', 'أعلم', 'إذا', 'كان', 'توم', 'جائعاً', 'أم', 'لا'] },
    { 'en': ['No', 'one', 'knows', 'his', 'name.'], 'ar': ['لا', 'يعرف', 'أحد', 'اسمه.'] },
    { 'en': ['Is', 'that', 'a', 'fire', 'truck?'], 'ar': ['هل', 'تلك', 'شاحنة', 'إطفاء؟'] },
    { 'en': ['I', "don't", 'believe', 'he', 'is', 'a', 'lawyer.'], 'ar': ['لا', 'أصدق', 'أنه', 'محام.'] },
    { 'en': ['Everyone', 'dies.'], 'ar': ['"كل', 'من', 'عليها', 'فانٍ".'] },
    { 'en': ['Tom', 'lived', 'there.'], 'ar': ['توم', 'يعيش', 'هناك'] },
    { 'en': ['Do', 'not', 'open', 'your', 'book.'], 'ar': ['لا', 'تفتح', 'كتابك.'] },
    { 'en': ['It', 'looks', 'like', 'an', 'apple.'], 'ar': ['تبدو', 'كتفاحة.'] },
    { 'en': ['This', 'was', 'his', 'one', 'and', 'only', 'hope.'], 'ar': ['كان', 'ذلك', 'أمله', 'الوحيد', 'و', 'الأخير.'] },
    { 'en': ['Tom', "doesn't", 'like', "Mary's", 'attitude.'], 'ar': ['توم', 'لا', 'يحب', 'سلوك', 'ميري.'] },
    { 'en': ['Tell', 'me', 'what', 'the', 'matter', 'is.'], 'ar': ['أخبرني', 'ما', 'الخطب.'] },
    { 'en': ['Check', 'that.'], 'ar': ['إفحص', 'ذلك.'] },
    { 'en': ["It's", 'a', 'complicated', 'story.'], 'ar': ['إنّها', 'قصة', 'معقّدة.'] },
    { 'en': ["Don't", 'you', 'want', 'to', 'know', 'why?'], 'ar': ['الا', 'تريد', 'أن', 'تعرف', 'لماذا؟'] },
    { 'en': ['I', "don't", 'have', 'a', 'problem', 'with', 'this.'], 'ar': ['ليس', 'لدي', 'مشاكل', 'مع', 'هذه'] },
    { 'en': ['Does', 'Tom', 'always', 'speak', 'for', 'you?'], 'ar': ['هل', 'توم', 'دائما', 'يتكلم', 'من', 'أجلك؟'] },
    { 'en': ["We're", 'obedient.'], 'ar': ['نحن', 'مطيعون.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'website?'], 'ar': ['هل', 'عندك', 'موقع؟'] },
    { 'en': ['Tom', 'arrived', 'around', '2:30.'], 'ar': ['وصل', 'توم', 'حوالي', '2:30.'] },
    { 'en': ['She', 'speaks', 'English', 'very', 'fluently.'], 'ar': ['إنها', 'تتكلم', 'الإنجليزية', 'بطلاقة', 'شديدة.'] },
    { 'en': ['Not', 'everybody', 'can', 'be', 'a', 'poet.'], 'ar': ['ليس', 'بإمكان', 'أي', 'إنسان', 'أن', 'يصبح', 'شاعرا.'] },
    { 'en': ["I'll", 'drive', 'you', 'to', 'the', 'airport.'], 'ar': ['سأوصلك', 'إلى', 'المطار.'] },
    { 'en': ['I', "can't", 'live', 'without', 'a', 'TV.'], 'ar': ['لا', 'يمكنني', 'العيش', 'من', 'دون', 'تلفاز.'] },
    { 'en': ["It's", 'crowded,', 'so', "let's", 'take', 'the', 'next', 'train.'], 'ar': ['إن', 'المكان', 'مُزدحم،', 'دعنا', 'نستقل', 'القطار', 'القادم.'] },
    { 'en': ['What', 'choice', 'did', 'I', 'have?'], 'ar': ['أي', 'خيار', 'كان', 'لدي؟'] },
    { 'en': ['What', 'are', 'you', 'going', 'to', 'do?'], 'ar': ['ماذا', 'ستفعل؟'] },
    { 'en': ['Next', 'time,', "I'll", 'do', 'it', 'myself.'], 'ar': ['في', 'المرة', 'المقبلة', 'سأفعل', 'ذلك', 'بنفسي.'] },
    { 'en': ['My', 'sister', 'resembles', 'my', 'mother.'], 'ar': ['أختي', 'تشبه', 'أمي.'] },
    { 'en': ['Where', 'did', 'you', 'go?'], 'ar': ['إلى', 'أين', 'ذهبت؟'] },
    { 'en': ['Would', 'you', 'please', 'tell', 'me', 'your', 'name', 'again?'], 'ar': ['من', 'فضلك،', 'هلّا', 'أخبرتني', 'اسمك', 'ثانيةً؟'] },
    { 'en': ['I', 'will', 'make', 'her', 'happy.'], 'ar': ['سأسعدها.'] },
    { 'en': ['Is', 'he', 'Japanese?'], 'ar': ['هل', 'هو', 'ياباني؟'] },
    { 'en': ['I', 'love', 'to', 'dance.'], 'ar': ['أحب', 'الرقص.'] },
    { 'en': ["I'm", 'relying', 'on', 'you', 'to', 'help', 'us.'], 'ar': ['أعتمد', 'عليك', 'لتساعدنا.'] },
    { 'en': ["I'm", 'walking', 'with', 'her.'], 'ar': ['إني', 'أتمشى', 'معها.'] },
    { 'en': ["I'd", 'like', 'to', 'see', 'you', 'again.'], 'ar': ['أود', 'رؤيتك', 'مجددا'] },
    { 'en': ['He', 'made', 'up', 'his', 'mind', 'to', 'be', 'a', 'doctor.'], 'ar': ['عزم', 'على', 'أن', 'يصبح', 'طبيباً.'] },
    { 'en': ['I', "don't", 'have', 'tattoos.'], 'ar': ['ليست', 'لديّ', 'أوشام.'] },
    { 'en': ['We', 'are', 'happy.'], 'ar': ['نحن', 'سعداء'] },
    { 'en': ["I've", 'done', 'my', 'best.'], 'ar': ['بذلت', 'قصارى', 'جهدي.'] },
    { 'en': ["Let's", 'not', 'watch', 'TV.'], 'ar': ['دعنا', 'لا', 'نشاهد', 'التلفاز.'] },
    { 'en': ['He', 'looks', 'suspicious.'], 'ar': ['يبدو', 'كأنه', 'شخص', 'مثير', 'للشك.'] },
    { 'en': ["We're", 'Canadians.'], 'ar': ['نحن', 'كنديون.'] },
    { 'en': ["It's", 'lunch', 'time.'], 'ar': ['حان', 'وقت', 'طعام', 'الغداء.'] },
    { 'en': ['Where', 'did', 'you', 'see', 'Tom?'], 'ar': ['أين', 'رأيت', 'توم؟'] },
    { 'en': ['I', 'played', 'tennis.'], 'ar': ['لعبت', 'التنس.'] },
    { 'en': ['What', 'time', 'do', 'you', 'usually', 'go', 'to', 'bed?'], 'ar': ['متى', 'تنام', 'في', 'العادة؟'] },
    { 'en': ["We'll", 'kill', 'you.'], 'ar': ['سنقتلك.'] },
    { 'en': ['This', 'is', 'what', 'we', 'found.'], 'ar': ['هذا', 'ما', 'وجدناه.'] },
    { 'en': ['A', 'sane', 'man', "wouldn't", 'do', 'that.'], 'ar': ['رجلاً', 'عاقلاً', 'لن', 'يفعل', 'هذا.'] },
    { 'en': ['Were', 'you', 'at', 'school', 'at', 'that', 'time?'], 'ar': ['هل', 'كنت', 'في', 'المدرسة', 'حينها؟'] },
    { 'en': ['He', 'is', 'a', 'wolf', 'in', "sheep's", 'clothing.'], 'ar': ['هو', 'ذئب', 'في', 'ثياب', 'النعاج.'] },
    { 'en': ['See', 'you', 'again.'], 'ar': ['أراك', 'مجدداً.'] },
    { 'en': ['Think', 'about', 'your', 'future.'], 'ar': ['فكر', 'في', 'مستقبلك!'] },
    { 'en': ['Where', 'is', 'your', 'house?'], 'ar': ['أين', 'بيتك؟'] },
    { 'en': ['What', 'is', 'your', 'address?'], 'ar': ['ما', 'عنوانك؟'] },
    { 'en': ['Hurry', 'up,', 'or', 'you', 'will', 'be', 'late.'], 'ar': ['أسرع', 'و', 'إلا', 'تأخرت.'] },
    { 'en': ['Can', 'you', 'give', 'me', 'a', 'lift', 'to', 'the', 'station?'], 'ar': ['هل', 'لك', 'أن', 'تقلني', 'إلى', 'المحطة؟'] },
    { 'en': ['Do', 'you', 'promise?'], 'ar': ['أتعديني؟'] },
    { 'en': ["I'm", 'very', 'hungry.'], 'ar': ['أنا', 'جائع', 'جداً.'] },
    { 'en': ['Tom', 'understands', 'the', 'risks.'], 'ar': ['يفهم', 'توم', 'المخاطر.'] },
    { 'en': ['I', "don't", 'blame', 'him.'], 'ar': ['أنا', 'لا', 'ألومهُ.'] },
    { 'en': ['Tom', "can't", 'play', 'tennis.'], 'ar': ['توم', 'لا', 'يمكنه', 'لعب', 'التنس.'] },
    { 'en': ['We', 'are', 'all', 'convinced', 'that', "he's", 'guilty.'], 'ar': ['كلنا', 'مقتنعون', 'بأنه', 'مذنب.'] },
    { 'en': ['He', 'went', 'to', 'New', 'York', 'on', 'Monday.'], 'ar': ['ذهب', 'إلى', 'نيويورك', 'يوم', 'الإثنين.'] },
    { 'en': ['Tom', 'wants', 'you', 'to', 'check', 'it', 'out.'], 'ar': ['توم', 'يُريدَك', 'أن', 'تتحقق', 'من', 'ذلك.'] },
    { 'en': ['Where', 'is', 'the', 'bank?'], 'ar': ['أين', 'المصرف؟'] },
    { 'en': ['There', 'is', 'no', 'hope.'], 'ar': ['لا', 'يوجد', 'أمل.'] },
    { 'en': ['His', 'daughter', 'and', 'my', 'son', 'are', 'good', 'friends.'], 'ar': ['ابنته', 'وابني', 'صديقان', 'جيدان.'] },
    { 'en': ['I', 'will', 'go', 'to', 'America', 'tomorrow.'], 'ar': ['سأذهب', 'إلى', 'أمريكا', 'غداً.'] },
    { 'en': ['Do', 'you', 'believe', 'there', 'are', 'ghosts?'], 'ar': ['أتعتقد', 'أن', 'هناك', 'أشباح؟'] },
    { 'en': ['Tom', 'tried', 'his', 'hardest', 'to', 'persuade', 'Mary.'], 'ar': ['حاول', 'توم', 'كل', 'ما', 'بوسعه', 'لإقناع', 'ماري.'] },
    { 'en': ['My', 'brother', 'can', 'drive', 'a', 'car.'], 'ar': ['يستطيع', 'أخي', 'أن', 'يقود', 'سيارة.'] },
    { 'en': ['He', 'turned', 'down', 'my', 'application.'], 'ar': ['لقد', 'رفض', 'طلبي.'] },
    { 'en': ['What', 'do', 'you', 'expect', 'to', 'find', 'here?'], 'ar': ['ماذا', 'تتوقع', 'أن', 'تجد', 'هنا؟'] },
    { 'en': ['I', 'did', 'not', 'understand', 'him', 'at', 'all.'], 'ar': ['لم', 'أفهمه', 'على', 'الإطلاق.'] },
    { 'en': ['Do', 'you', 'like', 'that?'], 'ar': ['هل', 'تحب', 'ذلك؟'] },
    { 'en': ['I', 'traveled', 'at', 'my', 'own', 'expense.'], 'ar': ['سافرت', 'على', 'نفقتي.'] },
    { 'en': ['I', 'like', 'coffee', 'better', 'than', 'tea.'], 'ar': ['أحب', 'القهوة', 'أكثر', 'من', 'الشاي.'] },
    { 'en': ['Please', "don't", 'follow', 'this', 'advice.'], 'ar': ['لا', 'تعمل', 'بهذه', 'النصيحة', 'رجاءً.'] },
    { 'en': ["We're", 'safe', 'now.'], 'ar': ['نحن', 'بمأمن', 'الآن.'] },
    { 'en': ['Everyone', 'is', 'free', 'to', 'contribute.'], 'ar': ['يمكن', 'للجميع', 'أن', 'يشارك.'] },
    { 'en': ['It', 'is', 'our', 'destiny.'], 'ar': ['إنّه', 'قدرنا.'] },
    { 'en': ["How's", 'the', 'job?'], 'ar': ['كيف', 'حال', 'الوظيفة؟'] },
    { 'en': ['He', 'read', 'the', 'letter', 'over', 'and', 'over.'], 'ar': ['قرأ', 'الرسالة', 'مرة', 'بعد', 'مرة.'] },
    { 'en': ['No', 'one', 'believed', 'his', 'story.'], 'ar': ['لم', 'يصدّق', 'أحد', 'قصّته.'] },
    { 'en': ['The', 'war', 'lasted', 'two', 'years.'], 'ar': ['استمرت', 'الحرب', 'قرابة', 'السنتين.'] },
    { 'en': ['These', 'books', 'are', 'new.'], 'ar': ['هذه', 'الكتب', 'جديدة.'] },
    { 'en': ['When', 'was', 'printing', 'invented?'], 'ar': ['متى', 'اخترعت', 'الطباعة؟'] },
    { 'en': ['Why', "couldn't", 'you', 'come', 'yesterday?'], 'ar': ['لماذا', 'لم', 'تسطيع', 'أن', 'تأتي', 'بالأمس؟'] },
    { 'en': ['It', 'is', 'raining', 'hard', 'now.'], 'ar': ['إنها', 'تمطر', 'بشدة', 'الآن.'] },
    { 'en': ['Help', 'me.'], 'ar': ['النجدة!', 'ساعدني!'] },
    { 'en': ["I'm", 'afraid', "it's", 'unavoidable.'], 'ar': ['أخشى', 'بأنه', 'لا', 'يمكن', 'تجنبه.'] },
    { 'en': ['Money', 'is', 'not', 'everything.'], 'ar': ['المال', 'ليس', 'كل', 'شيء.'] },
    { 'en': ['Even', 'though', 'he', 'was', 'poor,', 'he', 'was', 'happy.'], 'ar': ['بالرغم', 'من', 'فقره', 'إلا', 'أنه', 'تغمره', 'السعادة.'] },
    { 'en': ['Did', 'I', 'miss', 'anything?'], 'ar': ['هل', 'هناك', 'ما', 'لم', 'أضع', 'في', 'الإعتبار؟'] },
    { 'en': ['What', 'plans', 'do', 'you', 'have', 'for', 'Sunday?'], 'ar': ['ماهي', 'خططك', 'ليوم', 'الأحد؟'] },
    { 'en': ['He', 'died', 'one', 'year', 'ago.'], 'ar': ['مات', 'السنة', 'الماضية.'] },
    { 'en': ['We', 'need', 'to', 'water', 'the', 'flowers.'], 'ar': ['علينا', 'أن', 'نسقي', 'الأزهار.'] },
    { 'en': ['He', 'plays', 'the', 'piano', 'better', 'than', 'I', 'do.'], 'ar': ['إنه', 'يعزف', 'على', 'البيانو', 'أفضل', 'مني.'] },
    { 'en': ["Where's", 'my', 'ticket?'], 'ar': ['أين', 'تذكرتي؟'] },
    { 'en': ['Have', 'you', 'been', 'shot?'], 'ar': ['هل', 'أصبت', 'بعيار', 'ناري؟'] },
    { 'en': ['Do', 'you', 'eat', 'meat?'], 'ar': ['هل', 'تأكل', 'اللحم؟'] },
    { 'en': ["Let's", 'play', 'volleyball.'], 'ar': ['لنلعب', 'كرة', 'الطائرة.'] },
    { 'en': ['My', 'joints', 'ache.'], 'ar': ['مفاصلي', 'تؤلمني.'] },
    { 'en': ['I', "haven't", 'eaten', 'anything', 'for', 'days.'], 'ar': ['أنا', 'لم', 'أذُقِ', 'الطعامَ', 'منذُ', 'أيامٍ.'] },
    { 'en': ['Take', 'Tom', 'upstairs.'], 'ar': ['أحضر', 'توم', 'للطابق', 'العلوي.'] },
    { 'en': ['I', 'know', 'that', 'he', 'was', 'busy.'], 'ar': ['أعلم', 'أنه', 'كان', 'مشغولاً.'] },
    { 'en': ['One', 'of', 'us', 'will', 'have', 'to', 'go.'], 'ar': ['واحد', 'منّا', 'سيضطر', 'للرحيل.'] },
    { 'en': ['I', 'hope', 'your', 'brother', 'is', 'better.'], 'ar': ['آمل', 'أن', 'أخاك', 'قد', 'تحسن.'] },
    { 'en': ['I', 'think', 'we', 'should', 'do', 'some', 'more.'], 'ar': ['أعتقد', 'أننا', 'يجب', 'أن', 'نفعل', 'المزيد.'] },
    { 'en': ['She', 'has', 'something', 'in', 'her', 'hand.'], 'ar': ['في', 'يدها', 'شيء', 'ما.'] },
    { 'en': ['What', 'do', 'you', 'want', 'to', 'be?'], 'ar': ['ماذا', 'تريد', 'أن', 'تصبح', 'في', 'المستقبل؟'] },
    { 'en': ['The', 'crowd', 'yelled', 'when', 'he', 'scored', 'a', 'goal.'], 'ar': ['هتف', 'الجمهور', 'عندما', 'سجل', 'هدفاً.'] },
    { 'en': ['Why', 'do', 'you', 'say', 'that?'], 'ar': ['لماذا', 'تقول', 'ذلك؟'] },
    { 'en': ["I'll", 'come', 'back.'], 'ar': ['سأعود.'] },
    { 'en': ["It's", 'an', 'indisputable', 'matter.'], 'ar': ['هذا', 'أمرٌ', 'مُسَلمٌ', 'بِهِ.'] },
    { 'en': ['Keep', 'the', 'money', 'in', 'a', 'safe', 'place.'], 'ar': ['احفظ', 'المال', 'في', 'مكان', 'آمن.'] },
    { 'en': ['It', 'is', 'new.'], 'ar': ['إنه', 'جديد'] },
    { 'en': ['I', 'congratulate', 'you', 'on', 'your', 'success.'], 'ar': ['أهنّئك', 'بنجاحك.'] },
    { 'en': ['Walk', 'as', 'fast', 'as', 'possible.'], 'ar': ['امش', 'بأسرع', 'ما', 'يمكنك.'] },
    { 'en': ['They', 'lived', 'in', 'Spain', 'for', 'several', 'years.'], 'ar': ['عاشوا', 'في', 'إسبانيا', 'بضع', 'سنوات.'] },
    { 'en': ['I', 'have', 'no', 'further', 'questions.'], 'ar': ['ليس', 'لدي', 'أي', 'أسئلة', 'أخرى.'] },
    { 'en': ['You', 'should', 'swear', 'on', 'the', 'Bible.'], 'ar': ['ينبغي', 'أن', 'تقسم', 'ويدك', 'على', 'الكتاب', 'المقدس.'] },
    { 'en': ['Can', 'I', 'speak', 'to', 'the', 'doctor?'], 'ar': ['هل', 'لي', 'أن', 'أتحدث', 'مع', 'الطبيب؟'] },
    { 'en': ['She', 'may', 'come.'], 'ar': ['ربما', 'ستأتي.'] },
    { 'en': ['I', 'bought', 'her', 'a', 'clock.'], 'ar': ['ابتعتُ', 'لها', 'ساعة.'] },
    { 'en': ['Swimming', 'is', 'easy.'], 'ar': ['السباحة', 'أمر', 'سهل.'] },
    { 'en': ['I', "don't", 'know', 'this', 'neighborhood', 'too', 'well.'], 'ar': ['لا', 'أعرف', 'هذا', 'الحي', 'جيدا.'] },
    { 'en': ["Tom's", 'happy.'], 'ar': ['توم', 'سعيد'] },
    { 'en': ['Are', 'you', 'happy?'], 'ar': ['هل', 'أنت', 'سعيد'] },
    { 'en': ['This', 'is', 'never', 'going', 'to', 'end.'], 'ar': ['لن', 'ينتهي', 'ذلك', 'أبداً.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'bought', 'your', 'painting?'], 'ar': ['هل', 'تعلم', 'من', 'اشترى', 'لوحتك؟'] },
    { 'en': ['I', 'like', 'coffee', 'hot.'], 'ar': ['أحب', 'القهوة', 'ساخنة.'] },
    { 'en': ["Don't", 'avoid', 'my', 'question.'], 'ar': ['لا', 'تتهرّب', 'من', 'سؤالي.'] },
    { 'en': ["I'm", 'in', 'the', 'car.'], 'ar': ['أنا', 'في', 'السيارة'] },
    { 'en': ['He', 'continued', 'reading', 'the', 'book.'], 'ar': ['استأنف', 'قراءة', 'الكتاب.'] },
    { 'en': ["I'm", 'giving', 'up', 'smoking.'], 'ar': ['أقلعت', 'عن', 'التدخين.'] },
    { 'en': ['He', "doesn't", 'like', 'coffee.'], 'ar': ['إنه', 'لا', 'يحب', 'شرب', 'القهوة.'] },
    { 'en': ["I'm", 'sorry', 'I', "didn't", 'reply', 'to', 'you', 'sooner.'], 'ar': ['أعتذر', 'لعدم', 'الرد', 'عليك', 'أبكر.'] },
    { 'en': ['Yesterday', 'my', 'uncle', 'bought', 'a', 'dog.'], 'ar': ['اشترى', 'عمي', 'البارحة', 'كلباً.'] },
    { 'en': ["How's", 'the', 'water', 'here?'], 'ar': ['كيف', 'الماء', 'هنا؟'] },
    { 'en': ['Do', 'you', 'like', 'to', 'run?'], 'ar': ['هل', 'تحب', 'الجري؟'] },
    { 'en': ['Seeing', 'the', 'policeman,', 'the', 'man', 'ran', 'away.'], 'ar': ['هرب', 'الرجل', 'عقب', 'رؤية', 'الشرطي.'] },
    { 'en': ['It', 'was', 'very', 'weird.'], 'ar': ['كان', 'غريبا', 'بحق.'] },
    { 'en': ['He', 'is', 'busy', 'doing', 'something.'], 'ar': ['إنه', 'مشغول', 'بفعل', 'شيء', 'ما.'] },
    { 'en': ['I', 'have', 'two', 'cousins.'], 'ar': ['عندي', 'ابنا', 'خالة.'] },
    { 'en': ['Tom', "isn't", 'the', 'right', 'man', 'for', 'the', 'job.'], 'ar': ['ليس', 'توم', 'هو', 'الشخص', 'المناسب', 'للوظيفة.'] },
    { 'en': ['The', 'whole', 'family', 'is', 'out', 'for', 'a', 'walk.'], 'ar': ['خرجت', 'العائلة', 'كلّها', 'لتتمشّى.'] },
    { 'en': ['Fasten', 'your', 'seat', 'belt,', 'please.'], 'ar': ['من', 'فضلك', 'اربط', 'حزام', 'الأمان.'] },
    { 'en': ['I', 'suppose', "you're", 'hungry.'], 'ar': ['أفترض', 'أنك', 'جائع.'] },
    { 'en': ['I', 'think', "you'll", 'approve.'], 'ar': ['أظنك', 'ستوافق.'] },
    { 'en': ['I', 'have', 'a', 'car.'], 'ar': ['لدي', 'سيارة'] },
    { 'en': ['It', 'may', 'rain', 'soon.'], 'ar': ['ربما', 'ستمطرُ', 'بعدَ', 'قليل.'] },
    { 'en': ['He', 'will', 'always', 'be', 'with', 'you.'], 'ar': ['هو', 'سيكون', 'دائماً', 'معكِ.'] },
    { 'en': ['Will', 'you', 'go', 'to', 'America', 'next', 'year?'], 'ar': ['هل', 'ستذهب', 'إلى', 'أمريكا', 'السنة', 'القادمة؟'] },
    { 'en': ['I', 'am', 'new', 'here.'], 'ar': ['أنا', 'جديد', 'هنا'] },
    { 'en': ['Come', 'to', 'me.'], 'ar': ['تعال', 'إلي'] },
    { 'en': ["I'm", 'only', 'trying', 'to', 'help.'], 'ar': ['أنا', 'فقط', 'أحاول', 'المساعدة.'] },
    { 'en': ['I', 'want', 'to', 'rent', 'out', 'my', 'house.'], 'ar': ['أريد', 'أن', 'أؤجر', 'منزلي.'] },
    { 'en': ['I', 'like', 'French.'], 'ar': ['أنا', 'أحب', 'الفرنسية'] },
    { 'en': ["We're", 'safe', 'now.'], 'ar': ['نحن', 'في', 'أمان', 'الآن.'] },
    { 'en': ['Do', 'you', 'feel', 'any', 'pain', 'in', 'your', 'stomach?'], 'ar': ['أتحس', 'بوجع', 'في', 'بطنك؟'] },
    { 'en': ['Tom', 'said', 'he', 'saw', 'nothing.'], 'ar': ['قال', 'توم', 'بأنه', 'لم', 'يرى', 'شيئا.'] },
    { 'en': ['Sorry,', 'but', 'I', "can't", 'hear', 'you', 'very', 'well.'], 'ar': ['المعذرة', 'لكنّي', 'لا', 'أستطيع', 'سماعك', 'جيّداً.'] },
    { 'en': ['Thanks', 'anyway.'], 'ar': ['شكراً', 'على', 'أي', 'حال.'] },
    { 'en': ['I', 'returned', "Tom's", 'book', 'to', 'him.'], 'ar': ['أعدت', 'كتاب', 'توم', 'إليه.'] },
    { 'en': ['There', 'are', 'no', 'clean', 'plates.'], 'ar': ['ليس', 'هناك', 'أطباق', 'نظيفة.'] },
    { 'en': ['All', 'of', 'my', 'grandparents', 'are', 'still', 'living.'], 'ar': ['كل', 'اجدادي', 'ما', 'زالوا', 'يعيشون'] },
    { 'en': ['This', "isn't", 'drinking', 'water.'], 'ar': ['هذه', 'المياه', 'غير', 'صالحة', 'للشرب.'] },
    { 'en': ['Here', 'is', 'your', 'change.'], 'ar': ['هذا', 'هو', 'الباقي.'] },
    { 'en': ['Come', 'on.', 'You', 'can', 'do', 'it.'], 'ar': ['هيا.', 'أنتَ', 'يمكنكَ', 'أن', 'تفعلها.'] },
    { 'en': ['Wait', 'in', 'the', 'waiting', 'room.'], 'ar': ['انتظر', 'في', 'غرفة', 'الانتظار.'] },
    { 'en': ['He', 'wants', 'vengeance.'], 'ar': ['إنهُ', 'يريد', 'الإنتقام.'] },
    { 'en': ['Listen', 'to', 'me', 'carefully.'], 'ar': ['اسمعني', 'جيداً.'] },
    { 'en': ["I'm", 'very', 'happy', 'in', 'Georgia.'], 'ar': ['أنا', 'سعيدٌ', 'جداً', 'في', 'جورجيا.'] },
    { 'en': ['That', "wasn't", 'what', 'I', 'wanted', 'to', 'hear.'], 'ar': ['لم', 'يكن', 'هذا', 'ما', 'أردت', 'سماعه.'] },
    { 'en': ['I', "wasn't", 'trying', 'to', 'hurt', 'anybody.'], 'ar': ['لم', 'أكن', 'أحاول', 'إيذاء', 'أحد.'] },
    { 'en': ['He', 'is', 'afraid', 'of', 'his', 'own', 'shadow.'], 'ar': ['إنه', 'يخافُ', 'من', 'خيالهِ.'] },
    { 'en': ['They', 'were', 'forced', 'to', 'withdraw.'], 'ar': ['أجبروا', 'على', 'الانسحاب.'] },
    { 'en': ['This', 'is', 'a', 'book.'], 'ar': ['هذا', 'كتاب.'] },
    { 'en': ['My', 'shoes', 'are', 'bigger', 'than', 'yours.'], 'ar': ['حذائي', 'أكبر', 'من', 'حذائك.'] },
    { 'en': ['The', 'world', 'is', 'full', 'of', 'gossipers', 'and', 'liars.'], 'ar': ['العالم', 'مليء', 'بمروجي', 'الشائعات', 'والكذابين.'] },
    { 'en': ['She', 'spoke', 'rapidly.'], 'ar': ['تحدثت', 'بسرعة.'] },
    { 'en': ['Harvard', 'was', 'founded', 'in', '1636.'], 'ar': ['أُنشئت', 'هارفارد', 'عام', '١٦٣٦م.'] },
    { 'en': ['Football', 'is', 'my', 'favorite', 'game.'], 'ar': ['كرة', 'القدم', 'هي', 'لعبتي', 'المفضلة.'] },
    { 'en': ['Tom', 'and', 'Mary', 'are', 'a', 'lot', 'alike.'], 'ar': ['توم', 'وماري', 'متشابهان', 'كثيراً.'] },
    { 'en': ['See', 'you', 'tomorrow', 'at', 'the', 'library.'], 'ar': ['أراك', 'غداً', 'عند', 'المكتبة.'] },
    { 'en': ['Where', 'is', 'the', 'tourist', 'information', 'office?'], 'ar': ['أين', 'هو', 'مكتب', 'المعلومات', 'السياحية؟'] },
    { 'en': ['Will', 'you', 'lend', 'me', 'your', 'dictionary?'], 'ar': ['أيمكنك', 'أن', 'تعيرني', 'قاموسك؟'] },
    { 'en': ['Have', 'you', 'finished', 'your', 'homework', 'already?'], 'ar': ['هل', 'أنهيت', 'واجبك', 'بهذه', 'السرعة؟'] },
    { 'en': ['They', 'forced', 'her', 'to', 'confess.'], 'ar': ['أجبروها', 'على', 'الإعتراف.'] },
    { 'en': ['Drop', 'your', 'weapons!'], 'ar': ['ألقوا', 'أسلحتكم!'] },
    { 'en': ['My', 'dog', 'goes', 'everywhere', 'with', 'me.'], 'ar': ['يذهب', 'معي', 'كلبي', 'إلى', 'أي', 'مكان', 'أذهب', 'إليه.'] },
    { 'en': ['The', 'door', 'remained', 'closed', 'all', 'day.'], 'ar': ['بقي', 'الباب', 'مغلقًا', 'طوال', 'اليوم.'] },
    { 'en': ['I', 'have', 'a', 'pen.'], 'ar': ['عندي', 'قلم.'] },
    { 'en': ["I'll", 'return', 'to', 'get', 'my', 'handbag.'], 'ar': ['سأعود', 'لآخذ', 'حقيبة', 'يدي.'] },
    { 'en': ['Grab', 'my', 'hand.'], 'ar': ['أمسك', 'يدي.'] },
    { 'en': ["There's", 'not', 'a', 'cloud', 'in', 'the', 'sky.'], 'ar': ['ليس', 'هناك', 'أي', 'غيوم', 'في', 'السماء.'] },
    { 'en': ['This', 'book', 'deals', 'with', 'China.'], 'ar': ['هذا', 'الكتاب', 'يتناول', 'موضوع', 'الصين.'] },
    { 'en': ['Tom', 'has', 'a', 'dentist', 'appointment', 'at', '2:30.'], 'ar': ['توم', 'لديه', 'موعد', 'اسنان', 'عند', 'الثانية', 'والنصف'] },
    { 'en': ['It', 'was', 'very', 'nice', 'seeing', 'you', 'again.'], 'ar': ['سررت', 'بلقائك.'] },
    { 'en': ['Tom', 'and', 'Mary', 'have', 'a', 'small', 'farm.'], 'ar': ['لتوم', 'وماري', 'مزرعة', 'صغيرة.'] },
    { 'en': ["We've", 'been', 'married', 'for', 'five', 'years.'], 'ar': ['إننا', 'متزوجان', 'منذ', 'خمس', 'سنين.'] },
    { 'en': ['She', 'was', 'on', 'the', 'verge', 'of', 'crying.'], 'ar': ['كادت', 'أن', 'تبكي.'] },
    { 'en': ['Be', 'supportive.'], 'ar': ['كُن', 'داعماً.'] },
    { 'en': ['Japan', 'relies', 'on', 'Arab', 'countries', 'for', 'oil.'], 'ar': ['تعتمد', 'اليابان', 'على', 'نفط', 'البلدان', 'العربية.'] },
    { 'en': ['It', 'is', 'so', 'early.'], 'ar': ['الوقت', 'مبكر', 'جدا.'] },
    { 'en': ['I', 'said', 'I', 'would', 'make', 'her', 'happy.'], 'ar': ['قلتُ', 'أني', 'سأسعدها.'] },
    { 'en': ['They', 'look', 'happy.'], 'ar': ['يبدون', 'سعداء'] },
    { 'en': ['What', 'do', 'you', 'want', 'then?'], 'ar': ['ماذا', 'تريد', 'بعد', 'ذلك؟'] },
    { 'en': ['Be', 'creative.'], 'ar': ['كُن', 'مُبدِعاً.'] },
    { 'en': ['You', "can't", 'attend?', 'Why', 'not?'], 'ar': ['لا', 'يمكنك', 'الحضور؟', 'لمَ', 'لا؟'] },
    { 'en': ['We', 'went', 'out', 'in', 'spite', 'of', 'the', 'rain.'], 'ar': ['خرجنا', 'بالرغم', 'من', 'هطول', 'المطر.'] },
    { 'en': ["Didn't", 'I', 'tell', 'you', 'so?'], 'ar': ['ألم', 'أقل', 'لك؟'] },
    { 'en': ['Do', 'you', 'intend', 'to', 'come', 'tomorrow?'], 'ar': ['هل', 'تنوي', 'القدوم', 'غدًا؟'] },
    { 'en': ['I', "wouldn't", 'want', 'you', 'to', 'be', 'late', 'for', 'class.'], 'ar': ['أنا', 'لا', 'أريد', 'أن', 'تتأخر', 'على', 'الصف.'] },
    { 'en': ['I', 'hope', 'my', 'dream', 'will', 'come', 'true.'], 'ar': ['آمل', 'أن', 'تتحقق', 'أحلامي.'] },
    { 'en': ['It', 'was', 'really', 'cheap.'], 'ar': ['كانت', 'رخيصة', 'الثمن', 'جداً.'] },
    { 'en': ['Can', 'you', 'tell', 'me', 'how', 'to', 'get', 'to', 'the', 'station?'], 'ar': ['هل', 'يمكنك', 'أن', 'تخبرني', 'كيف', 'أصل', 'إلى', 'المحطة؟'] },
    { 'en': ['May', 'I', 'see', 'your', "driver's", 'license,', 'sir?'], 'ar': ['هلا', 'أريتني', 'رخصتك،', 'سيدي؟'] },
    { 'en': ['I', 'was', 'happy', 'for', 'him.'], 'ar': ['كنت', 'سعيدة', 'من', 'أجله.'] },
    { 'en': ['I', 'have', 'no', 'money.'], 'ar': ['ليس', 'لدي', 'أي', 'مال.'] },
    { 'en': ['I', 'bought', 'a', 'book', 'yesterday.'], 'ar': ['إشتريت', 'كتاباً', 'بالأمس.'] },
    { 'en': ['My', 'father', 'is', 'very', 'angry', 'with', 'me.'], 'ar': ['إن', 'أبي', 'غاضب', 'مني', 'جداً.'] },
    { 'en': ["I'm", 'tired.', "It's", 'been', 'a', 'long', 'day.'], 'ar': ['أنا', 'متعب.', 'لقد', 'كان', 'يوماً', 'طويلاً.'] },
    { 'en': ['They', 'were', 'punished', 'for', 'their', 'crimes.'], 'ar': ['لقد', 'تم', 'معاقبتهم', 'على', 'جرائمهم.'] },
    { 'en': ['She', 'screamed', 'with', 'terror.'], 'ar': ['صرخت', 'رعباً.'] },
    { 'en': ['All', 'Tom', 'really', 'needed', 'was', 'a', 'hug.'], 'ar': ['كل', 'ما', 'كان', 'يحتاجه', 'توم', 'حقاً', 'هو', 'عناق.'] },
    { 'en': ["I've", 'learned', 'a', 'lot.'], 'ar': ['لقد', 'تعلّمت', 'الكثير.'] },
    { 'en': ['My', 'mother', 'is', 'making', 'a', 'cake', 'for', 'my', 'father.'], 'ar': ['تقوم', 'أمي', 'بإعداد', 'كعكة', 'لأجل', 'أبي.'] },
    { 'en': ["I'm", 'getting', 'ready', 'for', 'the', 'worst.'], 'ar': ['أنا', 'أستعد', 'للأسوأ.'] },
    { 'en': ["I'm", 'a', 'student.'], 'ar': ['أنا', 'طالب'] },
    { 'en': ['Hurry', 'up.'], 'ar': ['أسرع!'] },
    { 'en': ['I', 'also', 'like', 'cake.'], 'ar': ['أحب', 'الكعك', 'أيضاً.'] },
    { 'en': ['He', 'shut', 'the', 'door.'], 'ar': ['أقفل', 'الباب!'] },
    { 'en': ["What's", 'for', 'lunch?'], 'ar': ['ماذا', 'للغداء؟'] },
    { 'en': ['I', 'accept,', 'but', 'only', 'on', 'one', 'condition.'], 'ar': ['أنا', 'موافق,', 'ولكن', 'على', 'شرط', 'واحد.'] },
    { 'en': ["Don't", 'worry', 'about', 'the', 'results', 'of', 'your', 'test.'], 'ar': ['لا', 'تقلق', 'بشأن', 'نتائج', 'إمتحانك.'] },
    { 'en': ['I', 'was', 'in', 'the', 'mountains.'], 'ar': ['كنت', 'في', 'الجبال', '.'] },
    { 'en': ['Tom', 'looks', 'very', 'busy', 'today.'], 'ar': ['يبدو', 'توم', 'مشغولا', 'جدا', 'اليوم.'] },
    { 'en': ['It', 'may', 'rain.'], 'ar': ['من', 'الممكن', 'أن', 'تمطر.'] },
    { 'en': ['My', 'family', 'is', 'not', 'very', 'large.'], 'ar': ['عائلتي', 'ليست', 'كبيرة.'] },
    { 'en': ['Tell', 'me', 'your', 'impression', 'of', 'this', 'book.'], 'ar': ['أعطني', 'انطباعك', 'عن', 'هذا', 'الكتاب.'] },
    { 'en': ['Pass', 'me', 'the', 'butter,', 'please.'], 'ar': ['من', 'فضلك', 'ناولني', 'الزبدة.'] },
    { 'en': ["I'm", 'from', 'Canada.'], 'ar': ['أنا', 'من', 'كندا.'] },
    { 'en': ['History', 'repeats', 'itself.'], 'ar': ['التاريخ', 'يعيد', 'نفسه.'] },
    { 'en': ['I', 'will', 'transfer', 'the', 'money.'], 'ar': ['سأقوم', 'بتحويل', 'المبلغ.'] },
    { 'en': ['Can', 'you', 'tell', 'me', 'why', 'Tom', 'is', 'in', 'Boston?'], 'ar': ['هلّا', 'أخبرتني', 'عن', 'سبب', 'وجود', 'توم', 'في', 'بوسطن؟'] },
    { 'en': ['I', 'expect', 'him', 'to', 'come', 'soon.'], 'ar': ['أتوقّع', 'أن', 'يصل', 'قريباً.'] },
    { 'en': ['Tom', 'wanted', 'to', 'know', 'if', 'Mary', 'had', 'a', 'boyfriend.'], 'ar': ['أراد', 'توم', 'معرفة', 'ما', 'إن', 'كان', 'لِماري', 'حبيب.'] },
    { 'en': ['You', "don't", 'need', 'to', 'go.'], 'ar': ['ليس', 'عليك', 'الذهاب.'] },
    { 'en': ['What', 'are', 'you', 'going', 'to', 'name', 'your', 'baby?'], 'ar': ['ماذا', 'ستقومين', 'بتسمية', 'طفلك؟'] },
    { 'en': ['I', 'love', 'California.'], 'ar': ['أحب', 'كاليفورنيا.'] },
    { 'en': ['What', 'is', 'your', 'favorite', 'TV', 'program?'], 'ar': ['ما', 'هو', 'برنامجك', 'التلفزيوني', 'المفضل؟'] },
    { 'en': ['He', 'asked', 'us', 'to', 'help', 'him.'], 'ar': ['طلب', 'منا', 'المساعدة.'] },
    { 'en': ["I'd", 'like', 'you', 'to', 'help', 'Tom.'], 'ar': ['أودّ', 'منك', 'مساعدة', 'توم.'] },
    { 'en': ['I', 'usually', 'go', 'home', 'at', 'four.'], 'ar': ['عادةً', 'ما', 'أعود', 'إلى', 'المنزل', 'الساعة', 'الرابعة.'] },
    { 'en': ['Shut', 'up!'], 'ar': ['اسكت!'] },
    { 'en': ['Correct', 'the', 'errors,', 'if', 'there', 'are', 'any.'], 'ar': ['صحح', 'الأخطاء', '،', 'إن', 'وُجدت.'] },
    { 'en': ["We'll", 'talk', 'about', 'it', 'tomorrow.'], 'ar': ['سنتحدث', 'حول', 'ذلك', 'غداً.'] },
    { 'en': ['She', 'wrote', 'a', 'book', 'about', 'birds.'], 'ar': ['كتبت', 'كتاباً', 'عن', 'الطيور.'] },
    { 'en': ["What's", 'your', "horse's", 'name?'], 'ar': ['ما', 'اسم', 'حصانك؟'] },
    { 'en': ['She', 'became', 'famous.'], 'ar': ['أصبحت', 'مشهورة.'] },
    { 'en': ["I'm", 'poor.'], 'ar': ['أنا', 'فقير.'] },
    { 'en': ['You', 'can', 'come', 'with', 'me.'], 'ar': ['بإمكانك', 'القدوم', 'معي.'] },
    { 'en': ['I', 'feel', 'cold.'], 'ar': ['أشعر', 'بالبرد.'] },
    { 'en': ['Life', 'is', 'beautiful.'], 'ar': ['الحياة', 'جميلة.'] },
    { 'en': ['Tell', 'me', 'about', 'it.'], 'ar': ['أخبرني', 'عنها.'] },
    { 'en': ['Tom', 'asked', 'Mary', 'if', 'she', 'would', 'help', 'him.'], 'ar': ['سأل', 'توم', 'ماري', 'إن', 'كانت', 'ستساعده.'] },
    { 'en': ['You', "can't", 'swim', 'here.'], 'ar': ['لا', 'يمكنك', 'السباحة', 'هنا.'] },
    { 'en': ['Tom,', 'look', 'at', 'what', 'I', 'made!'], 'ar': ['توم.', 'شاهد', 'مافعلته.'] },
    { 'en': ['I', 'like', 'red', 'roses.'], 'ar': ['أحب', 'الورد', 'الأحمر.'] },
    { 'en': ["We're", 'lucky', 'Tom', 'is', 'here', 'to', 'help', 'us.'], 'ar': ['من', 'حظنا', 'وجود', 'توم', 'هنا', 'ليساعدنا.'] },
    { 'en': ["I'll", 'miss', 'your', 'cooking.'], 'ar': ['سأشتاق', 'إلى', 'طبخك.'] },
    { 'en': ["Who's", 'there?'], 'ar': ['من', 'هناك؟'] },
    { 'en': ['I', "don't", 'like', 'coffee.'], 'ar': ['لا', 'أحب', 'القهوة.'] },
    { 'en': ['Learning', 'a', 'foreign', 'language', 'is', 'difficult.'], 'ar': ['تعلم', 'لغة', 'أجنبية', 'صعب.'] },
    { 'en': ['I', 'always', 'catch', 'a', 'cold', 'in', 'the', 'winter.'], 'ar': ['أصاب', 'بالبرد', 'دائمًا', 'في', 'الشتاء.'] },
    { 'en': ['Everybody', 'was', 'very', 'well', 'dressed.'], 'ar': ['كان', 'الجميع', 'بملابس', 'أنيقة.'] },
    { 'en': ['We', "haven't", 'had', 'those', 'kinds', 'of', 'problems', 'yet.'], 'ar': ['لم', 'نواجه', 'هكذا', 'مشاكل', 'بعد.'] },
    { 'en': ['We', 'have', 'good', 'news.'], 'ar': ['لدينا', 'أخبار', 'جيدة.'] },
    { 'en': ['What', 'are', 'you', 'looking', 'at?'], 'ar': ['إلى', 'ماذا', 'تنظر؟'] },
    { 'en': ['How', 'beautiful', 'this', 'flower', 'is!'], 'ar': ['ما', 'أجمل', 'هذه', 'الزهرة!'] },
    { 'en': ["Don't", 'worry.', "I'll", 'do', 'it.'], 'ar': ['لا', 'تقلق.', 'أنا', 'سأفعلها.'] },
    { 'en': ['That', 'color', "doesn't", 'look', 'good', 'on', 'me.'], 'ar': ['هذا', 'اللون', 'لا', 'يناسبني.'] },
    { 'en': ["She's", 'happy.'], 'ar': ['هي', 'سعيدة'] },
    { 'en': ['See', 'you', 'again.'], 'ar': ['إلى', 'اللقاء.'] },
    { 'en': ["He's", 'looking', 'good.'], 'ar': ['يبدو', 'جيدا'] },
    { 'en': ['Will', 'you', 'lend', 'me', 'your', 'dictionary?'], 'ar': ['هل', 'يمكنك', 'إعارتي', 'قاموسك؟'] },
    { 'en': ['That', 'house', 'is', 'very', 'small.'], 'ar': ['ذاك', 'المنزل', 'صغير', 'جداً.'] },
    { 'en': ['What', 'do', 'you', 'like', 'to', 'eat?'], 'ar': ['ماذا', 'تحب', 'أن', 'تأكل؟'] },
    { 'en': ['Tom', 'met', 'Mary', 'in', 'the', 'hotel', 'lobby.'], 'ar': ['التقى', 'توم', 'بماري', 'في', 'ردهة', 'الفندق.'] },
    { 'en': ['Where', 'was', 'Tom', 'born?'], 'ar': ['أين', 'وُلِدَ', 'توم؟'] },
    { 'en': ['Please', 'call', 'me', 'up', 'if', 'you', 'want', 'me.'], 'ar': ['أرجو', 'أن', 'تتصل', 'بي', 'إن', 'أردتني.'] },
    { 'en': ['She', 'was', 'buried', 'in', 'her', 'hometown.'], 'ar': ['دفنت', 'في', 'مسقط', 'رأسها.'] },
    { 'en': ['She', 'remained', 'single', 'all', 'her', 'life.'], 'ar': ['ظلت', 'عازبة', 'طيلة', 'حياتها.'] },
    { 'en': ['He', 'does', 'not', 'smoke.'], 'ar': ['هو', 'لا', 'يدخن.'] },
    { 'en': ["I'll", 'miss', 'you', 'so', 'much.'], 'ar': ['سأفتقدك', 'كثيرًا.'] },
    { 'en': ['There', 'must', 'be', 'a', 'better', 'way', 'to', 'do', 'this.'], 'ar': ['لا', 'بدّ', 'أنّ', 'هناك', 'طريقة', 'أفضل', 'لفعل', 'هذا.'] },
    { 'en': ['Bring', 'it', 'here.'], 'ar': ['احضره', 'الى', 'هنا.'] },
    { 'en': ['You', 'are', 'a', 'good', 'boy.'], 'ar': ['أنت', 'ولد', 'مُطيع.'] },
    { 'en': ['Bring', 'the', 'key.'], 'ar': ['أحضر', 'المفتاح.'] },
    { 'en': ['Where', 'were', 'you?'], 'ar': ['أين', 'كنت؟'] },
    { 'en': ['You', 'look', 'pale.'], 'ar': ['تبدو', 'شاحب', 'الوجه.'] },
    { 'en': ['He', 'took', 'a', 'key', 'from', 'his', 'coat', 'pocket.'], 'ar': ['أخرج', 'مفتاحًا', 'من', 'جيب', 'معطفه.'] },
    { 'en': ['It', 'may', 'snow.'], 'ar': ['قد', 'ينزل', 'الثلج'] },
    { 'en': ['We', 'reached', 'London', 'at', 'midnight.'], 'ar': ['وصلنا', 'إلى', 'لندن', 'عند', 'منتصف', 'الليل.'] },
    { 'en': ['No', 'one', 'expected', 'Tom', 'to', 'win.'], 'ar': ['لم', 'يتوقّع', 'أحد', 'أن', 'يفوز', 'توم.'] },
    { 'en': ['I', 'am', 'pleased', 'to', 'see', 'you.'], 'ar': ['سررتُ', 'لرؤيتكَ.'] },
    { 'en': ['What', 'made', 'her', 'so', 'sad?'], 'ar': ['ما', 'الذي', 'أحزنها', 'هكذا؟'] },
    { 'en': ['Can', 'you', 'sing', 'the', 'Argentine', 'National', 'Anthem?'], 'ar': ['هل', 'يمكنك', 'غناء', 'النشيد', 'الوطني', 'الارجنتيني؟'] },
    { 'en': ['You', 'might', 'want', 'to', 'talk', 'to', 'Tom.'], 'ar': ['أنتَ', 'قد', 'ترغب', 'في', 'التحدُث', 'إلى', 'توم.'] },
    { 'en': ["I'm", 'going', 'out', 'in', 'an', 'hour.'], 'ar': ['سأخرج', 'في', 'غضون', 'ساعة.'] },
    { 'en': ['I', 'met', 'her', 'an', 'hour', 'ago.'], 'ar': ['قابلتها', 'منذ', 'ساعة.'] },
    { 'en': ['I', 'fixed', 'the', 'bike', 'yesterday.'], 'ar': ['أصلحت', 'الدراجة', 'بالأمس.'] },
    { 'en': ['He', 'looks', 'like', 'an', 'honest', 'man.'], 'ar': ['يبدو', 'رجلا', 'أمينا', '.'] },
    { 'en': ['When', 'did', 'you', 'arrive', 'there?'], 'ar': ['متى', 'وصلت', 'إلى', 'هناك؟'] },
    { 'en': ['I', 'love', 'French', 'movies.'], 'ar': ['أحب', 'الأفلام', 'الفرنسية.'] },
    { 'en': ['Tom', 'is', 'willing', 'to', 'stand', 'by', 'you.'], 'ar': ['توم', 'يرغب', 'بالوقوف', 'إلى', 'جانبك.'] },
    { 'en': ['The', 'rear', 'tire', 'of', 'my', 'bicycle', 'is', 'flat.'], 'ar': ['الاطار', 'الخلفي', 'لعجلتي', 'ضارب.'] },
    { 'en': ['It', 'may', 'rain', 'soon.'], 'ar': ['ستمطر', 'قريباً.'] },
    { 'en': ['Tom', 'got', 'on', 'the', 'wrong', 'train.'], 'ar': ['ركب', 'توم', 'القطار', 'الخطأ.'] },
    { 'en': ['I', 'am', 'in', 'London.'], 'ar': ['أنا', 'في', 'لندن.'] },
    { 'en': ['Tom', 'got', 'out', 'of', 'the', 'taxi.'], 'ar': ['خرج', 'توم', 'من', 'التاكسي.'] },
    { 'en': ['My', 'alarm', 'clock', "didn't", 'go', 'off', 'this', 'morning.'], 'ar': ['لم', 'ترن', 'ساعتي', 'المنبهة', 'هذا', 'الصباح.'] },
    { 'en': ['We', "aren't", 'going', 'to', 'lose.'], 'ar': ['لن', 'نخسر.'] },
    { 'en': ['We', 'are', 'coming.'], 'ar': ['نحن', 'آتون'] },
    { 'en': ['Do', 'you', 'promise?'], 'ar': ['أتعدني؟'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'umbrella?'], 'ar': ['هل', 'لي', 'أن', 'أستعير', 'مظلتك؟'] },
    { 'en': ["I'm", 'leaving', 'you', 'tomorrow.'], 'ar': ['سأتركك', 'غداً.'] },
    { 'en': ['His', 'lecture', 'started', 'on', 'time.'], 'ar': ['بدأت', 'محاضرته', 'في', 'موعدها.'] },
    { 'en': ['I', "don't", 'care.'], 'ar': ['لا', 'أهتم.'] },
    { 'en': ['Only', 'God', 'knows.'], 'ar': ['الله', 'وحده', 'يعلم.'] },
    { 'en': ['I', 'love', 'Arabic.'], 'ar': ['أحب', 'اللّغة', 'العربية.'] },
    { 'en': ['Take', 'a', 'look', 'at', 'this', 'report.'], 'ar': ['ألقي', 'نظرةً', 'على', 'هذا', 'التقرير.'] },
    { 'en': ["Let's", 'make', 'a', 'trade.'], 'ar': ['هيا', 'نصنع', 'تجارة.'] },
    { 'en': ["Let's", 'play', 'tennis', 'after', 'school.'], 'ar': ['لنلعب', 'كرة', 'المضرب', 'بعد', 'المدرسة.'] },
    { 'en': ['I', 'looked', 'all', 'over', 'the', 'house', 'for', 'that', 'letter.'], 'ar': ['بحثت', 'في', 'المنزل', 'كله', 'عن', 'تلك', 'الرسالة.'] },
    { 'en': ['Tom', 'loves', 'going', 'out.'], 'ar': ['توم', 'يحب', 'الخروج'] },
    { 'en': ['Is', 'she', 'Japanese?'], 'ar': ['هل', 'هي', 'يابانية؟'] },
    { 'en': ['We', "haven't", 'seen', 'you', 'in', 'the', 'past', 'four', 'years.'], 'ar': ['لم', 'نرك', 'منذ', 'أربع', 'سنين.'] },
    { 'en': ['Make', 'good', 'use', 'of', 'your', 'time.'], 'ar': ['استفد', 'من', 'وقتك', 'جيدا.'] },
    { 'en': ['Is', 'it', 'rainy?'], 'ar': ['هل', 'الجو', 'ممطر؟'] },
    { 'en': ['It', 'was', 'a', 'one', 'hundred', 'dollar', 'bill.'], 'ar': ['إنها', 'ورقة', 'نقدية', 'من', 'فئة', 'المائة', 'دولار.'] },
    { 'en': ['I', 'have', 'a', 'pain', 'in', 'my', 'shoulders.'], 'ar': ['لدي', 'ألم', 'في', 'أكتافي.'] },
    { 'en': ["Don't", 'talk', 'like', 'that.'], 'ar': ['لا', 'تتكلم', 'هكذا.'] },
    { 'en': ['It', 'looks', 'like', "Tom's", 'been', 'busy.'], 'ar': ['يبدو', 'أن', 'توم', 'كان', 'مشغولا.'] },
    { 'en': ['It', 'is', 'a', 'lot', 'of', 'fun', 'to', 'drive', 'a', 'car.'], 'ar': ['قيادة', 'السيارة', 'أمر', 'ممتع.'] },
    { 'en': ['Everybody', 'agrees', 'with', 'you.'], 'ar': ['الكل', 'يوافقك', 'الرأي.'] },
    { 'en': ['I', 'knew', 'something', 'funny', 'might', 'happen.'], 'ar': ['علمت', 'أن', 'شيئا', 'طريفا', 'قد', 'يحدث.'] },
    { 'en': ['He', 'gave', 'his', 'blood', 'to', 'help', 'his', 'sister.'], 'ar': ['لقد', 'أعطى', 'دمه', 'لكى', 'يساعد', 'أخته.'] },
    { 'en': ['I', "don't", 'have', 'any', 'evidence.'], 'ar': ['ليس', 'لدي', 'دليل.'] },
    { 'en': ['I', 'want', 'to', 'be', 'a', 'doctor.'], 'ar': ['أريد', 'أن', 'أصبح', 'طبيباً.'] },
    { 'en': ['Is', 'somebody', 'teaching', 'you', 'French?'], 'ar': ['هل', 'يوجد', 'شخص', 'ما', 'يعلمكَ', 'الفرنسية؟'] },
    { 'en': ["I've", 'seen', 'that.'], 'ar': ['لقد', 'رأيت', 'ذلك', 'من', 'قبل.'] },
    { 'en': ['Please', 'give', 'me', 'a', 'cup', 'of', 'tea.'], 'ar': ['رجاء،', 'أعطني', 'كوبا', 'من', 'الشاي.'] },
    { 'en': ['You', 'talk', 'as', 'if', 'you', 'were', 'the', 'boss.'], 'ar': ['تتكلم', 'و', 'كأنك', 'رئيسنا.'] },
    { 'en': ['Never', 'tell', 'a', 'lie!'], 'ar': ['لا', 'تكذب', 'أبداً!'] },
    { 'en': ['You', "shouldn't", 'go.'], 'ar': ['يُفضّل', 'ألا', 'تذهب.'] },
    { 'en': ["I've", 'been', 'busy.'], 'ar': ['كنت', 'مشغولا.'] },
    { 'en': ['Dr.', 'Smith', 'has', 'a', 'lot', 'of', 'patients.'], 'ar': ['لدى', 'الطبيب', 'سميث', 'الكثير', 'من', 'المرضى.'] },
    { 'en': ['Hey,', 'listen', 'to', 'me.'], 'ar': ['استمع', 'إلي'] },
    { 'en': ['The', 'well', 'ran', 'dry.'], 'ar': ['جف', 'البئر.'] },
    { 'en': ['The', 'bridge', 'was', 'built', 'by', 'the', 'Romans.'], 'ar': ['شيد', 'الجسر', 'على', 'يد', 'الروم.'] },
    { 'en': ['He', 'is', 'speaking', 'English.'], 'ar': ['إنه', 'يتكلم', 'الإنجليزية.'] },
    { 'en': ['Apparently,', 'the', 'bus', 'is', 'late.'], 'ar': ['يبدو', 'أن', 'الحافلة', 'تأخرت', 'عن', 'الوصول.'] },
    { 'en': ['Here', 'comes', 'the', 'bus.'], 'ar': ['ها', 'قد', 'أتت', 'الحافلة.'] },
    { 'en': ['I', "don't", 'remember', 'asking', 'for', 'your', 'opinion.'], 'ar': ['لا', 'أتذكر', 'بأنني', 'طلبت', 'منك', 'أن', 'تبدي', 'رأيك'] },
    { 'en': ['The', 'wedding', 'will', 'take', 'place', 'next', 'spring.'], 'ar': ['سيقام', 'العرس', 'في', 'الربيع', 'القادم.'] },
    { 'en': ['Here', 'is', 'your', 'book.'], 'ar': ['ها', 'هو', 'كتابك.'] },
    { 'en': ['English', 'is', 'easy', 'to', 'learn.'], 'ar': ['الإنجليزية', 'لغة', 'سهلة', 'التعلم.'] },
    { 'en': ['I', "don't", 'know.'], 'ar': ['لا', 'أدري.'] },
    { 'en': ['He', 'thinks', 'I', 'love', 'her.'], 'ar': ['هو', 'يعتقد', 'أنني', 'أحبها.'] },
    { 'en': ['How', 'much', 'for', 'half', 'a', 'kilo?'], 'ar': ['كم', 'ثمن', 'نصف', 'كيلو؟'] },
    { 'en': ['Why', 'is', 'this', 'happening', 'again?'], 'ar': ['لمَ', 'يحدث', 'هذا', 'ثانيةً؟'] },
    { 'en': ['I', 'admire', 'your', 'courage.'], 'ar': ['أنا', 'معجب', 'بشجاعتك.'] },
    { 'en': ['You', 'should', 'have', 'worked', 'harder.'], 'ar': ['كان', 'عليك', 'أن', 'تعمل', 'بجهد', 'أكبر.'] },
    { 'en': ['Tom', 'turned', 'on', 'the', 'faucet.'], 'ar': ['توم', 'افتح', 'الصنبور'] },
    { 'en': ['There', 'is', 'no', 'doubt', 'about', 'his', 'honesty.'], 'ar': ['لا', 'يوجد', 'شك', 'بخصوص', 'إخلاصه.'] },
    { 'en': ['I', "can't", 'see', 'anything.'], 'ar': ['لا', 'أستطيع', 'رؤية', 'شيء.'] },
    { 'en': ['I', 'know', 'it', 'was', 'serious.'], 'ar': ['أنا', 'أعلم', 'أنها', 'كانت', 'خطيرة'] },
    { 'en': ["He's", 'sitting', 'on', 'the', 'bench.'], 'ar': ['إنهُ', 'يجلس', 'على', 'المَقعد.'] },
    { 'en': ['I', 'like', 'coffee', 'hot.'], 'ar': ['أحب', 'شرب', 'القهوة', 'ساخنة.'] },
    { 'en': ["Let's", 'get', 'ready', 'to', 'go.'], 'ar': ['دعونا', 'نستعد', 'للذهاب.'] },
    { 'en': ['I', 'admire', 'his', 'skill', 'at', 'driving.'], 'ar': ['أنا', 'معجبةٌ', 'بمهارته', 'في', 'القيادة.'] },
    { 'en': ['The', 'police', 'officer', 'fired', 'a', 'warning', 'shot.'], 'ar': ['أطلق', 'الشرطي', 'رصاصة', 'تحذيرية.'] },
    { 'en': ['I', 'wish', "you'd", 'told', 'me', 'before.'], 'ar': ['اتمنى', 'لو', 'كنت', 'اخبرتنى', 'من', 'قبل'] },
    { 'en': ['Kyoto', 'is', 'not', 'as', 'large', 'as', 'Osaka.'], 'ar': ['كيوتو', 'ليست', 'كبيرة', 'كأوساكا.'] },
    { 'en': ['Tom', 'and', 'Mary', 'exchanged', 'smiles.'], 'ar': ['تبادل', 'توم', 'و', 'ماري', 'البسمات.'] },
    { 'en': ['I', 'was', 'in', 'shock.'], 'ar': ['شعرت', 'بالصّدمة.'] },
    { 'en': ['The', 'roof', 'is', 'on', 'fire.'], 'ar': ['السقف', 'يحترق'] },
    { 'en': ['Second', 'semester', 'has', 'ended.'], 'ar': ['انتهى', 'الفصل', 'الدراسي', 'الثاني.'] },
    { 'en': ['He', 'is', 'French.'], 'ar': ['إنه', 'من', 'فرنسا.'] },
    { 'en': ['Hope', 'is', 'not', 'a', 'strategy.'], 'ar': ['الأمل', 'ليس', 'خطةً.'] },
    { 'en': ['We', "aren't", 'going', 'to', 'do', 'that.'], 'ar': ['لن', 'نفعل', 'ذلك.'] },
    { 'en': ['You', "didn't", 'understand.'], 'ar': ['إنك', 'لم', 'تفهم.'] },
    { 'en': ['Is', 'that', 'an', 'order?'], 'ar': ['هل', 'هذا', 'أمر؟'] },
    { 'en': ['The', 'game', 'was', 'suspended.'], 'ar': ['أُلغيت', 'المباراة.'] },
    { 'en': ['I', 'never', 'played', 'golf.'], 'ar': ['لم', 'ألعب', 'الجولف', 'بتاتا.'] },
    { 'en': ['What', 'year', 'were', 'you', 'born?'], 'ar': ['في', 'أي', 'عام', 'ولدت؟'] },
    { 'en': ["I'm", 'thirsty.'], 'ar': ['أنا', 'عطشان.'] },
    { 'en': ['They', 'are', 'my', 'brothers.'], 'ar': ['هم', 'إخوتي.'] },
    { 'en': ['All', 'of', 'my', 'friends', 'are', 'out', 'of', 'town.'], 'ar': ['جميع', 'أفراد', 'عائلتي', 'خارج', 'المدينة.'] },
    { 'en': ["I'm", 'glad', 'to', 'see', 'you', 'back.'], 'ar': ['سررت', 'بعودتك.'] },
    { 'en': ['That', 'castle', 'is', 'beautiful.'], 'ar': ['تلك', 'القلعة', 'جميلة.'] },
    { 'en': ['Tom', 'is', 'getting', 'away.'], 'ar': ['توم', 'يهرب.'] },
    { 'en': ['I', 'know', 'you', 'are', 'rich.'], 'ar': ['أنا', 'أعلم', 'أنك', 'غني'] },
    { 'en': ['When', 'did', 'you', 'go', 'there?'], 'ar': ['متى', 'ذهبت', 'إلى', 'هناك؟'] },
    { 'en': ['Please', 'put', 'on', 'your', 'shoes.'], 'ar': ['من', 'فضلك', 'البس', 'حذاءك.'] },
    { 'en': ['She', 'asked', 'me', 'for', 'help.'], 'ar': ['طلبت', 'مني', 'المساعدة.'] },
    { 'en': ["It's", 'been', 'raining', 'since', 'last', 'night.'], 'ar': ['ما', 'زالت', 'تمطر', 'منذ', 'ليلة', 'البارحة.'] },
    { 'en': ['Stop', 'acting', 'like', 'an', 'idiot.'], 'ar': ['كفّ', 'عن', 'التّصرّف', 'كالأحمق.'] },
    { 'en': ["It's", 'so', 'dark.'], 'ar': ['إنه', 'مظلم', 'جداً.'] },
    { 'en': ['There', 'is', 'no', 'question', 'about', 'it.'], 'ar': ['لا', 'شك', 'في', 'ذلك.'] },
    { 'en': ['Children', 'love', 'dogs.'], 'ar': ['الأطفال', 'يحبّون', 'الكلاب.'] },
    { 'en': ['How', 'far', 'is', 'it', 'from', 'here', 'to', 'the', 'museum?'], 'ar': ['كم', 'يبعد', 'المتحف', 'عن', 'هنا؟'] },
    { 'en': ['She', 'likes', 'running.'], 'ar': ['إنها', 'تحب', 'الركض.'] },
    { 'en': ["He's", 'taller', 'than', 'me.'], 'ar': ['هو', 'أطول', 'مني'] },
    { 'en': ['How', 'unlucky', 'I', 'am!'], 'ar': ['يا', 'لسوء', 'حظي.'] },
    { 'en': ['That', 'book', 'is', 'small.'], 'ar': ['ذلك', 'الكتاب', 'صغير'] },
    { 'en': ['Tom', 'would', 'like', 'to', 'buy', 'a', 'house.'], 'ar': ['يودّ', 'توم', 'شراء', 'منزلًا.'] },
    { 'en': ['Where', 'are', 'you', 'going?'], 'ar': ['إلى', 'أين', 'أنت', 'ذاهب؟'] },
    { 'en': ['What', 'time', 'are', 'you', 'leaving?'], 'ar': ['متى', 'ستذهب؟'] },
    { 'en': ['You', 'look', 'stupid.'], 'ar': ['تبدو', 'أحمقاً.'] },
    { 'en': ['Do', 'you', 'speak', 'Esperanto?'], 'ar': ['هل', 'تتحدث', 'الإسبرانتو؟'] },
    { 'en': ['I', 'thought', 'you', 'would', 'never', 'come', 'here.'], 'ar': ['اعتقدت', 'انك', 'لا', 'تريد', 'ان', 'تأتي', 'الى', 'هنا', '.'] },
    { 'en': ['Smoking', 'is', 'a', 'bad', 'habit.'], 'ar': ['التدخين', 'عادة', 'سيئة.'] },
    { 'en': ['I', 'think', 'so.'], 'ar': ['أظن', 'ذلك.'] },
    { 'en': ['Your', 'friend', 'is', 'here.'], 'ar': ['صديقتك', 'هنا.'] },
    { 'en': ['You', 'must', 'face', 'the', 'facts.'], 'ar': ['يجب', 'أن', 'تواجه', 'الحقائق.'] },
    { 'en': ['He', 'can', 'do', 'it.'], 'ar': ['باستطاعته', 'القيام', 'بذلك.'] },
    { 'en': ["What's", 'wrong?'], 'ar': ['ما', 'الخطب؟'] },
    { 'en': ['Cut', 'the', 'cake', 'with', 'a', 'knife.'], 'ar': ['اِقطع', 'الكعكة', 'بالسكين.'] },
    { 'en': ['You', "aren't", 'responsible', 'for', 'that', 'mess.'], 'ar': ['أنتِ', 'لستِ', 'مسؤولة', 'عن', 'تلك', 'الفوضى.'] },
    { 'en': ['Our', 'teacher', 'will', 'go', 'to', 'the', 'USA', 'next', 'month.'], 'ar': ['سيسافر', 'معلمنا', 'إلى', 'أمريكا', 'الشهر', 'المقبل.'] },
    { 'en': ['You', 'have', 'to', 'come', 'with', 'me.'], 'ar': ['يجب', 'عليك', 'المجيء', 'معي.'] },
    { 'en': ["I'm", 'glad', 'I', "wasn't", 'here.'], 'ar': ['أنا', 'مسرورة', 'لعدم', 'وجودي', 'هناك.'] },
    { 'en': ['Tom', "didn't", 'act', 'quickly', 'enough.'], 'ar': ['لم', 'يتصرف', 'توم', 'بالسرعة', 'الكافية.'] },
    { 'en': ['I', 'have', 'no', 'words', 'to', 'express', 'my', 'gratitude.'], 'ar': ['الكلمات', 'لا', 'يمكنها', 'التعبير', 'عن', 'شكري', 'بما', 'يكفي.'] },
    { 'en': ['They', 'became', 'man', 'and', 'wife.'], 'ar': ['أصبحا', 'زوجاً', 'و', 'زوجة.'] },
    { 'en': ["I'm", 'still', 'not', 'ready.'], 'ar': ['لست', 'جاهزاً', 'بعد.'] },
    { 'en': ['Mary', 'seems', 'to', 'be', 'bored', 'with', 'the', 'game.'], 'ar': ['يظهر', 'أن', 'ماري', 'ملّتْ', 'من', 'هذه', 'اللعبة.'] },
    { 'en': ['I', 'caused', 'this.'], 'ar': ['هذا', 'بسببي.'] },
    { 'en': ['The', 'hostages', 'will', 'be', 'released.'], 'ar': ['سيُفرج', 'عن', 'الرهائن.'] },
    { 'en': ['She', 'is', 'mad', 'at', 'me.'], 'ar': ['إنها', 'غاضبة', 'عليّ.'] },
    { 'en': ['Not', 'all', 'children', 'like', 'apples.'], 'ar': ['لا', 'يحب', 'كل', 'الأطفال', 'التفاح.'] },
    { 'en': ["It's", 'new.'], 'ar': ['إنه', 'جديد'] },
    { 'en': ['Do', 'you', 'like', 'it?'], 'ar': ['هل', 'يعجبك', 'ذلك؟'] },
    { 'en': ["That's", 'not', 'my', 'wife.'], 'ar': ['هذه', 'ليست', 'زوجتي.'] },
    { 'en': ['My', 'grandfather', 'was', 'born', 'in', '1920.'], 'ar': ['وُلد', 'جدي', 'عام', 'عشرين', 'ميلادية.'] },
    { 'en': ['He', 'filled', 'the', 'cup', 'to', 'the', 'brim.'], 'ar': ['هو', 'أترعَ', 'الكأس.'] },
    { 'en': ['Are', 'you', 'ready', 'for', 'that?'], 'ar': ['هل', 'أنت', 'جاهز', 'لهذا؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'study', 'Arabic.'], 'ar': ['أود', 'أن', 'أتعلم', 'العربية.'] },
    { 'en': ['I', "don't", 'know', 'him.'], 'ar': ['لا', 'أعرفه.'] },
    { 'en': ["It's", 'easy', 'to', 'see', 'why', 'people', 'like', 'Tom.'], 'ar': ['مِن', 'السهل', 'معرفة', 'سبب', 'حبّ', 'الناس', 'لِتوم.'] },
    { 'en': ['All', 'you', 'have', 'to', 'do', 'is', 'tell', 'Tom', 'to', 'leave.'], 'ar': ['كل', 'ما', 'عليك', 'فعله', 'هو', 'إخبار', 'توم', 'بأن', 'يرحل.'] },
    { 'en': ['I', 'still', "don't", 'think', "it's", 'fair.'], 'ar': ['لازلت', 'غير', 'مقتنعا', 'بعدالة', 'ذلك.'] },
    { 'en': ['Travel', 'broadens', "one's", 'horizons.'], 'ar': ['السفر', 'يوسع', 'آفاق', 'تفكير', 'المرء.'] },
    { 'en': ['Here', 'we', 'are!'], 'ar': ['ها', 'نحن', 'ذا'] },
    { 'en': ["Can't", 'you', 'speak', 'English?'], 'ar': ['ألا', 'يمكنك', 'تكلم', 'الإنجليزية؟'] },
    { 'en': ['I', 'go', 'to', 'a', 'restaurant', 'every', 'other', 'day.'], 'ar': ['أذهب', 'إلى', 'مطعم', 'كلّ', 'يومين.'] },
    { 'en': ['I', "don't", 'have', 'an', 'excuse.'], 'ar': ['ليس', 'لديّ', 'عذر.'] },
    { 'en': ['He', 'claims', 'that', 'he', 'is', 'a', 'painter.'], 'ar': ['يدعي', 'أنه', 'رسام.'] },
    { 'en': ['Is', 'there', 'a', 'cash', 'machine', 'near', 'here?'], 'ar': ['هل', 'هناك', 'صرّاف', 'آلي', 'قريب', 'من', 'هنا؟'] },
    { 'en': ["You've", 'put', 'on', 'weight.'], 'ar': ['أنت', 'سمنت.'] },
    { 'en': ['Every', 'cloud', 'has', 'a', 'silver', 'lining.'], 'ar': ['رب', 'ضارّةٍ', 'نافعة.'] },
    { 'en': ["I'm", 'taking', 'a', 'walk', 'in', 'a', 'park.'], 'ar': ['أتمشى', 'في', 'الحديقة.'] },
    { 'en': ['I', 'like', 'playing', 'sports.'], 'ar': ['أحب', 'ممارسة', 'الألعاب', 'الرياضية.'] },
    { 'en': ['I', 'hope', 'you', "aren't", 'too', 'disappointed.'], 'ar': ['آمل', 'أن', 'ظنك', 'ما', 'خاب', 'كثيرا.'] },
    { 'en': ['Tom', 'got', 'a', 'little', 'carried', 'away.'], 'ar': ['اندفع', 'توم', 'زيادة.'] },
    { 'en': ["Don't", 'leave', 'me', 'alone.'], 'ar': ['لا', 'تتركني', 'وحيدا.'] },
    { 'en': ['Let', 'me', 'out!'], 'ar': ['دعني', 'أخرُج!'] },
    { 'en': ['Please', 'step', 'back.'], 'ar': ['من', 'فضلك', 'تنحّى', 'جانباً.'] },
    { 'en': ['Here', 'is', 'your', 'key.'], 'ar': ['ها', 'هو', 'مفتاحك.'] },
    { 'en': ["I'm", 'rich.'], 'ar': ['أنا', 'ثري.'] },
    { 'en': ['Is', 'this', 'your', 'family?'], 'ar': ['هل', 'هذه', 'عائلتك؟'] },
    { 'en': ['You', 'might', 'at', 'least', 'say', '"thank', 'you."'], 'ar': ['على', 'الأقل', 'قل', 'شكراً.'] },
    { 'en': ['He', 'found', 'my', 'bike.'], 'ar': ['وجد', 'دراجتي', 'الضائعة.'] },
    { 'en': ['I', 'may', 'be', 'gone', 'for', 'a', 'while.'], 'ar': ['ربما', 'ذهبت', 'لفتره'] },
    { 'en': ["Don't", 'you', 'walk', 'out', 'on', 'me.'], 'ar': ['لا', 'تتركني.'] },
    { 'en': ["I'll", 'give', 'you', 'this', 'camera.'], 'ar': ['سأعطيك', 'آلة', 'التصوير', 'هذه.'] },
    { 'en': ["It's", 'improved.'], 'ar': ['لقد', 'تحسنت.'] },
    { 'en': ['That', 'was', 'years', 'ago.'], 'ar': ['كان', 'ذلك', 'منذ', 'عدة', 'سنين.'] },
    { 'en': ['I', "wasn't", 'impressed', 'by', 'what', 'Tom', 'did.'], 'ar': ['لم', 'يرق', 'لي', 'ما', 'فعل', 'توم.'] },
    { 'en': ['We', 'were', 'ordered', 'to', 'do', 'that.'], 'ar': ['أمرنا', 'بفعل', 'ذلك.'] },
    { 'en': ['What', 'you', 'did', 'was', 'wrong.'], 'ar': ['ما', 'فعلته', 'أنتَ', 'كان', 'خاطئاً.'] },
    { 'en': ['He', 'instigated', 'the', 'riot.'], 'ar': ['هو', 'حرض', 'على', 'الشغب.'] },
    { 'en': ['What', 'happened?'], 'ar': ['ما', 'الذي', 'حصل؟'] },
    { 'en': ["Let's", 'wait', 'until', 'the', 'crowd', 'thins', 'out.'], 'ar': ['دعنا', 'ننتظر', 'حتى', 'يخف', 'الزحام.'] },
    { 'en': ['He', 'told', 'me', 'he', 'was', 'going', 'to', 'America.'], 'ar': ['أخبرني', 'أنه', 'ذاهب', 'إلى', 'أمريكا.'] },
    { 'en': ['We', 'have', 'eaten', 'there', 'three', 'times.'], 'ar': ['لقد', 'أكلنا', 'هناك', 'ثلاث', 'مرات.'] },
    { 'en': ['She', 'kept', 'on', 'working.'], 'ar': ['بقيت', 'تعمل.'] },
    { 'en': ['You', "don't", 'speak', 'English,', 'do', 'you?'], 'ar': ['أنت', 'لا', 'يمكنك', 'تحدث', 'الإنجليزية،', 'صحيح؟'] },
    { 'en': ['I', 'am', 'Chinese.'], 'ar': ['أنا', 'صيني'] },
    { 'en': ['Do', 'you', 'have', 'a', 'larger', 'size?'], 'ar': ['هل', 'هناك', 'مقاس', 'أكبر؟'] },
    { 'en': ['I', "can't", 'find', 'my', 'watch.'], 'ar': ['لا', 'أستطيع', 'أن', 'أجد', 'ساعتي.'] },
    { 'en': ['He', 'works', 'at', 'a', 'bank.'], 'ar': ['إنه', 'يعمل', 'في', 'المصرف.'] },
    { 'en': ['I', 'like', 'this', 'city', 'very', 'much.'], 'ar': ['هذه', 'المدينة', 'تعجبني', 'جداً.'] },
    { 'en': ["I'm", 'so', 'proud', 'of', 'you.'], 'ar': ['أنا', 'فخور', 'جداً', 'بكَ.'] },
    { 'en': ['Fighting', "won't", 'settle', 'anything.'], 'ar': ['لن', 'يحل', 'القتال', 'أي', 'شيء.'] },
    { 'en': ['Green', 'suits', 'you.'], 'ar': ['يناسبك', 'اللون', 'الأخضر.'] },
    { 'en': ['Have', 'you', 'done', 'your', 'homework?'], 'ar': ['هل', 'أنجزت', 'فرضك؟'] },
    { 'en': ['He', 'must', 'love', 'you.'], 'ar': ['لا', 'بد', 'أنه', 'يحبك.'] },
    { 'en': ['Taking', 'care', 'of', 'the', 'baby', 'is', 'my', 'job.'], 'ar': ['مهمتي', 'هي', 'الإهتمام', 'بالطفل.'] },
    { 'en': ['Japan', 'is', 'an', 'industrial', 'nation.'], 'ar': ['اليابان', 'بلد', 'صناعي.'] },
    { 'en': ['I', 'never', 'liked', 'biology.'], 'ar': ['لم', 'أحب', 'مادة', 'الأحياء', 'قط.'] },
    { 'en': ['Tom', 'can', 'run', 'fast.'], 'ar': ['يستطيع', 'توم', 'الركض', 'بسرعة.'] },
    { 'en': ['How', 'old', 'is', 'he?'], 'ar': ['كم', 'عمره؟'] },
    { 'en': ['She', 'speaks', 'Polish.'], 'ar': ['إنها', 'تتحدث', 'البولندية.'] },
    { 'en': ["Don't", 'worry', 'about', 'your', 'work.'], 'ar': ['لا', 'تقلق', 'بشأن', 'عملك.'] },
    { 'en': ['I', "don't", 'understand.'], 'ar': ['أنا', 'لا', 'أفهم'] },
    { 'en': ['The', 'maid', 'made', 'my', 'bed.'], 'ar': ['رتبت', 'الخادمة', 'سريري.'] },
    { 'en': ['I', 'stayed', 'there', 'for', 'three', 'days.'], 'ar': ['eng', 'I', 'stayed', 'there', 'for', 'three', 'days.'] },
    { 'en': ['She', 'is', 'not', 'here.'], 'ar': ['هي', 'ليست', 'هنا'] },
    { 'en': ['Tom', 'is', 'wearing', 'a', 'sports', 'coat.'], 'ar': ['توم', 'يرتدي', 'معطف', 'رياطي'] },
    { 'en': ["It's", 'brand', 'new.'], 'ar': ['إنها', 'جديدة.'] },
    { 'en': ['Paris', 'is', 'the', 'capital', 'of', 'France.'], 'ar': ['باريس', 'عاصمة', 'فرنسا.'] },
    { 'en': ['My', 'uncle', 'gave', 'him', 'a', 'present.'], 'ar': ['أعطاه', 'عمي', 'هدية.'] },
    { 'en': ['The', 'flame', 'went', 'out.'], 'ar': ['انطفأ', 'اللهب.'] },
    { 'en': ['I', 'want', 'to', 'know.'], 'ar': ['أود', 'أن', 'أعرف.'] },
    { 'en': ['The', 'park', 'is', 'filled', 'with', 'children.'], 'ar': ['تعجّ', 'الحديقة', 'بالأطفال.'] },
    { 'en': ['What', 'would', 'he', 'know?'], 'ar': ['ماذا', 'سيعرف؟'] },
    { 'en': ['I', 'bought', 'a', 'few', 'stamps.'], 'ar': ['اشتريت', 'بضع', 'طوابع', 'بريدية.'] },
    { 'en': ["Let's", 'go', 'see', 'if', 'Tom', 'can', 'help', 'us.'], 'ar': ['دعينا', 'نذهب', 'لنرى', 'إن', 'كان', 'توم', 'يستطيع', 'أن', 'يساعدنا.'] },
    { 'en': ['We', 'ran', 'for', '10', 'kilometers.'], 'ar': ['ركضنا', '10', 'كيلومترات.'] },
    { 'en': ['Come', 'back', 'soon.'], 'ar': ['عُد', 'قريبًا.'] },
    { 'en': ['Call', 'me', 'as', 'soon', 'as', 'you', 'find', 'Tom.'], 'ar': ['اتّصلي', 'بي', 'حالما', 'تجدين', 'توم.'] },
    { 'en': ['It', 'makes', 'me', 'so', 'happy.'], 'ar': ['يجعلني', 'سعيدة', 'جدا.'] },
    { 'en': ["That's", 'an', 'imitation.'], 'ar': ['إن', 'ذلك', 'تقليد.'] },
    { 'en': ['Have', 'you', 'finished', 'reading', 'that', 'novel?'], 'ar': ['هل', 'أنهيت', 'قراءة', 'تلك', 'الرواية؟'] },
    { 'en': ['Everybody', 'is', 'waiting', 'for', 'you.'], 'ar': ['الجميع', 'ينتظرونك.'] },
    { 'en': ['I', 'am', 'from', 'Norway.'], 'ar': ['أنا', 'نرويجي.'] },
    { 'en': ['My', 'father', 'loves', 'my', 'mother.'], 'ar': ['أبي', 'يحب', 'أمي.'] },
    { 'en': ['Ladies', 'first.'], 'ar': ['السيدات', 'أولاً.'] },
    { 'en': ['I', 'lost', 'my', 'wife', 'in', 'a', 'traffic', 'accident.'], 'ar': ['فقدت', 'زوجتي', 'في', 'حادث', 'سير.'] },
    { 'en': ['No', 'one', 'can', 'deny', 'the', 'fact.'], 'ar': ['لا', 'يستطيع', 'أحد', 'أن', 'ينكر', 'هذا.'] },
    { 'en': ['He', 'is', 'concerned', 'about', 'his', "father's", 'illness.'], 'ar': ['إنه', 'قلق', 'بسبب', 'مرض', 'والده.'] },
    { 'en': ['She', 'told', 'us', 'not', 'to', 'make', 'a', 'noise.'], 'ar': ['طلبت', 'منا', 'ألا', 'نصدر', 'صوتاً.'] },
    { 'en': ['The', 'war', 'lasted', 'for', 'about', 'two', 'years.'], 'ar': ['استمرت', 'الحرب', 'قرابة', 'السنتين.'] },
    { 'en': ['Tom', 'was', 'the', 'last', 'kid', 'to', 'go', 'home.'], 'ar': ['كان', 'توم', 'آخر', 'طفل', 'يذهب', 'إلى', 'منزله.'] },
    { 'en': ['She', 'will', 'get', 'well', 'in', 'a', 'week.'], 'ar': ['ستتحسن', 'خلال', 'أسبوع.'] },
    { 'en': ['The', 'power', 'went', 'out.'], 'ar': ['انقطعت', 'الكهرباء.'] },
    { 'en': ['I', 'walk', 'to', 'school.'], 'ar': ['أمشي', 'إلى', 'المدرسة.'] },
    { 'en': ['We', 'still', 'have', 'more', 'time.'], 'ar': ['ما', 'زال', 'لدينا', 'الكثير', 'من', 'الوقت.'] },
    { 'en': ['I', 'hate', 'this', 'weather.'], 'ar': ['كم', 'أكره', 'هذا', 'الطقس!'] },
    { 'en': ['I', 'need', 'some', 'rest.'], 'ar': ['أحتاج', 'بعض', 'الراحة.'] },
    { 'en': ['We', 'stayed', 'there', 'for', 'three', 'months.'], 'ar': ['سكنّا', 'هناك', 'ثلاثة', 'أشهر.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'larger', 'size?'], 'ar': ['أهناك', 'مقاس', 'أكبر؟'] },
    { 'en': ['The', 'boy', 'was', 'almost', 'drowned.'], 'ar': ['كاد', 'الولد', 'أن', 'يغرق.'] },
    { 'en': ['Watch', 'out!', "There's", 'a', 'big', 'hole', 'there.'], 'ar': ['انتبه!', 'هناك', 'حفرة', 'كبيرة', 'هناك.'] },
    { 'en': ['I', 'would', 'like', 'to', 'ask', 'a', 'question.'], 'ar': ['أود', 'أن', 'أسأل', 'سؤال.'] },
    { 'en': ['He', 'told', 'me', 'everything.'], 'ar': ['قال', 'لي', 'كل', 'شيء.'] },
    { 'en': ['She', 'devoted', 'her', 'life', 'to', 'music.'], 'ar': ['لقد', 'كرّست', 'حياتها', 'للموسيقا.'] },
    { 'en': ['Are', 'you', 'talking', 'to', 'me?'], 'ar': ['هل', 'تتكلم', 'معي؟'] },
    { 'en': ['I', 'love', 'both', 'cats', 'and', 'dogs.'], 'ar': ['أنا', 'أُحب', 'كُلاً', 'من', 'القطط', 'والكلاب.'] },
    { 'en': ['Here', 'they', 'come.'], 'ar': ['ها', 'قد', 'أَتَوْا.'] },
    { 'en': ['I', 'called', 'Tom', 'up.'], 'ar': ['اتصلت', 'بتوم.'] },
    { 'en': ['What', 'does', 'it', 'contain?'], 'ar': ['على', 'ماذا', 'يحتوي؟'] },
    { 'en': ['I', 'saw', 'him', 'jump.'], 'ar': ['رأيته', 'يقفز.'] },
    { 'en': ['I', 'met', 'an', 'old', 'woman.'], 'ar': ['قابلت', 'امرأة', 'عجوز.'] },
    { 'en': ["Don't", 'worry', 'about', 'us.'], 'ar': ['لا', 'تقلق', 'بشأننا.'] },
    { 'en': ['I', "don't", 'know', 'if', 'I', 'still', 'have', 'it.'], 'ar': ['لا', 'أعلم', 'إذا', 'ما', 'كان', 'لا', 'يزال', 'معي.'] },
    { 'en': ['Do', 'you', 'see', 'Tom', 'often?'], 'ar': ['هل', 'ترى', 'توم', 'كثيرا؟'] },
    { 'en': ['You', 'want', 'a', 'divorce,', "don't", 'you?'], 'ar': ['أنتِ', 'تريدين', 'الطلاق,', 'أليس', 'كذلك؟'] },
    { 'en': ['She', 'raised', 'her', 'hands.'], 'ar': ['لقد', 'رَفَعَت', 'يديها.'] },
    { 'en': ['It', 'was', 'such', 'a', 'shock.'], 'ar': ['يا', 'لها', 'من', 'صدمة.'] },
    { 'en': ['This', 'is', 'the', 'window', 'he', 'broke.'], 'ar': ['هذه', 'هي', 'النافذة', 'التي', 'كسرها.'] },
    { 'en': ['You', 'must', 'pay', 'in', 'advance.'], 'ar': ['عليك', 'أن', 'تدفع', 'مقدماً.'] },
    { 'en': ['Tom', 'turned', 'off', 'the', 'faucet.'], 'ar': ['توم', 'أغلق', 'الصنبور'] },
    { 'en': ['I', 'was', 'laughed', 'at', 'by', 'everyone.'], 'ar': ['ضحك', 'الجميع', 'عليّ.'] },
    { 'en': ['Take', 'off', 'your', 'socks.'], 'ar': ['انزعي', 'جوربيكِ.'] },
    { 'en': ['Speak', 'louder,', 'please.'], 'ar': ['لطفاً،', 'ارفع', 'صوتك.'] },
    { 'en': ['I', 'want', 'Tom', 'to', 'understand.'], 'ar': ['أريد', 'أن', 'يفهم', 'توم'] },
    { 'en': ['I', 'have', 'to', 'find', 'it.'], 'ar': ['عليّ', 'العثور', 'عليها.'] },
    { 'en': ['You', 'have', 'to', 'go.'], 'ar': ['عليك', 'الرحيل.'] },
    { 'en': ['Where', 'is', 'your', 'father?'], 'ar': ['أين', 'والدك؟'] },
    { 'en': ['Do', 'you', 'know', 'what', 'Tom', 'does', 'for', 'a', 'living?'], 'ar': ['هل', 'تعلم', 'ما', 'يعمله', 'توم', 'لكسب', 'لقمة', 'العيش؟'] },
    { 'en': ["There's", 'nothing', 'I', 'can', 'do.'], 'ar': ['ليس', 'باليد', 'حيلة.'] },
    { 'en': ['Which', 'student', 'went', 'out?'], 'ar': ['أيّ', 'طالب', 'خرج؟'] },
    { 'en': ['Tom', 'is', 'on', 'board', 'the', 'ship.'], 'ar': ['توم', 'على', 'ظهر', 'السفينة.'] },
    { 'en': ["I'll", 'buy', 'this.'], 'ar': ['سأشتري', 'ذلك.'] },
    { 'en': ["It's", 'on', 'the', 'table.'], 'ar': ['إنها', 'علي', 'الطاولة.'] },
    { 'en': ['This', 'apple', 'is', 'very', 'red.'], 'ar': ['هذه', 'التفاحة', 'شديدة', 'الحمرة.'] },
    { 'en': ['How', 'much', 'is', 'this', 'clock?'], 'ar': ['بكم', 'الساعة؟'] },
    { 'en': ['How', 'should', 'I', 'know?'], 'ar': ['كيف', 'لي', 'أن', 'أعرف؟'] },
    { 'en': ["It's", 'a', 'good', 'deal.'], 'ar': ['إنها', 'صفقة', 'جيدة.'] },
    { 'en': ['Do', 'you', 'remember', 'me?'], 'ar': ['هل', 'تذكرني؟'] },
    { 'en': ['Do', 'you', 'have', 'a', 'car?'], 'ar': ['هل', 'عندك', 'سيارة؟'] },
    { 'en': ['Wash', 'your', 'hands.'], 'ar': ['اِغسل', 'يديك.'] },
    { 'en': ['Did', 'you', 'hear', 'that', 'sound?'], 'ar': ['أسمعت', 'ذاك', 'الصوت؟'] },
    { 'en': ['Tom', 'swam', 'across', 'the', 'river.'], 'ar': ['قطع', 'توم', 'النهر', 'سباحة.'] },
    { 'en': ['Louder,', 'please.'], 'ar': ['من', 'فضلك،', 'ارفع', 'صوتك.'] },
    { 'en': ['Tom', 'almost', 'forgot', 'the', 'conference.'], 'ar': ['توم', 'تقريباً', 'نسيَ', 'المؤتمر.'] },
    { 'en': ['May', 'I', 'stay', 'here', 'tonight?'], 'ar': ['هل', 'يمكنني', 'البقاء', 'هنا', 'الليلة؟'] },
    { 'en': ['Is', 'this', 'the', 'book', "you're", 'looking', 'for?'], 'ar': ['أهذا', 'هو', 'الكتاب', 'الذي', 'تبحث', 'عنه؟'] },
    { 'en': ['Tom', 'had', 'a', 'bad', 'headache.'], 'ar': ['توم', 'لديه', 'الم', 'رأس', 'سيئ.'] },
    { 'en': ["Don't", 'you', 'think', 'you', 'should', 'get', 'some', 'rest?'], 'ar': ['ألا', 'تظنّ', 'أنّ', 'عليك', 'أخذ', 'قسطٍ', 'من', 'الراحة؟'] },
    { 'en': ['What', 'a', 'beautiful', 'design!'], 'ar': ['يا', 'له', 'من', 'تصميمٍ', 'جميل', '!'] },
    { 'en': ['The', "doctor's", 'office', 'is', 'on', 'the', 'second', 'floor.'], 'ar': ['عيادة', 'الطبيب', 'في', 'الدور', 'الثاني.'] },
    { 'en': ['She', 'was', 'about', 'to', 'go', 'out.'], 'ar': ['كان', 'على', 'وشك', 'أن', 'تخرج.'] },
    { 'en': ['We', 'took', 'him', 'to', 'the', 'hospital.'], 'ar': ['نحن', 'أخذناه', 'إلي', 'المستشفي.'] },
    { 'en': ['We', 'stayed', 'overnight', 'in', 'Hakone.'], 'ar': ['بِتْنا', 'في', 'هاكوني.'] },
    { 'en': ['What', 'do', 'you', 'want?'], 'ar': ['ماذا', 'تريد؟'] },
    { 'en': ['My', 'clock', 'needs', 'to', 'be', 'fixed.'], 'ar': ['أحتاج', 'إلى', 'أن', 'أصلح', 'ساعتي.'] },
    { 'en': ['I', 'have', 'not', 'finished', 'my', 'homework', 'yet.'], 'ar': ['لم', 'أُنهِ', 'واجبي', 'بعد.'] },
    { 'en': ["Don't", 'let', 'this', 'chance', 'slip', 'by.'], 'ar': ['لا', 'تدع', 'الفرصة', 'تفوتك.'] },
    { 'en': ['Tom', 'will', 'graduate', 'in', 'October.'], 'ar': ['سيتخرج', 'توم', 'في', 'أكتوبر.'] },
    { 'en': ['This', 'makes', 'no', 'sense', 'to', 'me.'], 'ar': ['هذا', 'لا', 'يبدو', 'لي', 'منطقيا.'] },
    { 'en': ['The', 'dog', 'attacked', 'the', 'little', 'boy.'], 'ar': ['هاجم', 'الكلب', 'الولد', 'الصغير.'] },
    { 'en': ['Any', 'child', 'can', 'do', 'that.'], 'ar': ['يستطيع', 'كل', 'طفل', 'فعل', 'ذلك.'] },
    { 'en': ['Everyone', 'was', 'happy.'], 'ar': ['الكل', 'كان', 'سعيداً.'] },
    { 'en': ['We', 'should', 'go', 'to', 'sleep.'], 'ar': ['علينا', 'الخلود', 'إلى', 'النوم.'] },
    { 'en': ["I'd", 'rather', 'go', 'swimming.'], 'ar': ['أفضّل', 'أن', 'أذهب', 'للسباحة.'] },
    { 'en': ["Let's", 'get', 'started', 'right', 'away.'], 'ar': ['دعونا', 'نبدأ', 'على', 'الفور.'] },
    { 'en': ["I'm", 'getting', 'off', 'the', 'train', 'at', 'the', 'next', 'stop.'], 'ar': ['سأنزل', 'من', 'القطار', 'عند', 'المحطة', 'القادمة.'] },
    { 'en': ['Either', 'you', 'or', 'I', 'must', 'attend', 'the', 'meeting.'], 'ar': ['يجب', 'أن', 'يحضر', 'الاجتماع', 'أحدنا،', 'إما', 'أنا', 'أو', 'أنت.'] },
    { 'en': ['Insist', 'on', 'the', 'best.'], 'ar': ['أصِرَّ', 'على', 'الأفضل.'] },
    { 'en': ['Your', 'shirt', 'is', 'inside', 'out.'], 'ar': ['قميصك', 'ملبوس', 'بالمقلوب.'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'sleep.'], 'ar': ['عليّ', 'الذهاب', 'إلى', 'النوم.'] },
    { 'en': ['They', "aren't", 'like', 'us.'], 'ar': ['إنهم', 'ليسو', 'مثلنا'] },
    { 'en': ['This', 'is', 'true.'], 'ar': ['إن', 'ذلك', 'صحيح.'] },
    { 'en': ["It's", 'as', 'old', 'as', 'the', 'hills.'], 'ar': ['إنها', 'قديمة', 'قِدم', 'التِلال.'] },
    { 'en': ["We're", 'out', 'of', 'sugar.'], 'ar': ['نفد', 'السكر.'] },
    { 'en': ['I', 'am', 'in', 'a', 'new', 'house.'], 'ar': ['أنا', 'في', 'بيت', 'جديد.'] },
    { 'en': ['I', 'love', 'that', 'commercial.'], 'ar': ['أحب', 'هذا', 'الإعلان', 'التلفزيوني.'] },
    { 'en': ["I'm", 'at', 'home.'], 'ar': ['أنا', 'في', 'البيت'] },
    { 'en': ['Would', 'you', 'like', 'to', 'dance', 'with', 'me?'], 'ar': ['هل', 'ترغبين', 'في', 'الرقص', 'معي؟'] },
    { 'en': ["I'm", 'not', 'tired', 'at', 'all.'], 'ar': ['أنا', 'لست', 'متعباً', 'على', 'الإطلاق.'] },
    { 'en': ['She', 'lives', 'in', 'New', 'York.'], 'ar': ['إنها', 'تعيش', 'في', 'نيويورك.'] },
    { 'en': ['Do', 'you', 'know', 'the', 'answer?'], 'ar': ['هل', 'تعرف', 'الإجابة؟'] },
    { 'en': ['You', 'are', 'stupid.'], 'ar': ['أنت', 'أحمق.'] },
    { 'en': ['The', 'lake', 'is', 'a', 'long', 'way', 'from', 'here.'], 'ar': ['البحيرة', 'بعيدة', 'جداً', 'عن', 'هنا.'] },
    { 'en': ['Those', 'are', 'our', "teachers'", 'cars.'], 'ar': ['تلك', 'سيارات', 'معلمينا.'] },
    { 'en': ['Can', 'I', 'take', 'this', 'with', 'me?'], 'ar': ['هل', 'أستطيع', 'أن', 'آخذ', 'هذهِ', 'معي؟'] },
    { 'en': ['That', 'red', 'sweater', 'looks', 'good', 'on', 'you.'], 'ar': ['يبدو', 'المعطف', 'الأحمر', 'جميلاً', 'عليك.'] },
    { 'en': ['She', 'needs', 'him', 'more', 'than', 'he', 'needs', 'her.'], 'ar': ['هي', 'تحتاجه', 'أكثر', 'ممّا', 'يحتاجها.'] },
    { 'en': ['I', 'bought', 'a', 'used', 'phone.'], 'ar': ['اشتريت', 'جوال', 'مستعمل.'] },
    { 'en': ['I', 'was', 'wondering', 'when', "you'd", 'be', 'back.'], 'ar': ['كنت', 'أتساءل', 'متى', 'ستعود؟'] },
    { 'en': ["We've", 'finished', 'cleaning', 'our', 'classroom.'], 'ar': ['نظّفنا', 'صفّنا.'] },
    { 'en': ['Please', 'copy', 'this', 'page.'], 'ar': ['من', 'فضلك', 'اِنسخ', 'هذه', 'الصفحة.'] },
    { 'en': ["I'll", 'see', 'you', 'tomorrow.'], 'ar': ['أراك', 'غداً.'] },
    { 'en': ['They', 'started', 'one', 'after', 'another.'], 'ar': ['بدأوا', 'واحداً', 'تلو', 'الآخر.'] },
    { 'en': ['English', 'is', 'taught', 'in', 'most', 'countries.'], 'ar': ['الانجليزية', 'تدرس', 'في', 'اغلب', 'الدول.'] },
    { 'en': ['She', 'wept', 'bitterly.'], 'ar': ['بكت', 'بحرارة.'] },
    { 'en': ['Stay', 'at', 'home.'], 'ar': ['ابق', 'في', 'البيت.'] },
    { 'en': ["What's", 'that', 'tall', 'building?'], 'ar': ['ما', 'ذلك', 'المبنى', 'الطويل؟'] },
    { 'en': ['You', 'have', 'a', 'bright', 'future.'], 'ar': ['لديك', 'مستقبل', 'واعد.'] },
    { 'en': ["Where's", 'your', 'school?'], 'ar': ['أين', 'مدرستك؟'] },
    { 'en': ['What', 'is', 'his', 'age?'], 'ar': ['كم', 'عمره؟'] },
    { 'en': ["Let's", 'get', 'out', 'of', 'here', 'before', 'Tom', 'sees', 'us.'], 'ar': ['دعينا', 'نخرج', 'من', 'هنا', 'قبل', 'أن', 'يرانا', 'توم.'] },
    { 'en': ['You', 'had', 'to', 'come', 'yesterday.'], 'ar': ['كان', 'عليك', 'المجيء', 'البارحة.'] },
    { 'en': ['I', 'married', 'off', 'my', 'daughter.'], 'ar': ['لقد', 'زوجت', 'إبنتي.'] },
    { 'en': ['You', 'always', 'try', 'to', 'blame', 'somebody', 'else.'], 'ar': ['تحاول', 'دائما', 'لوم', 'شخص', 'آخر.'] },
    { 'en': ['I', 'like', 'movies.'], 'ar': ['أحب', 'الأفلام.'] },
    { 'en': ['He', 'took', 'a', 'notebook', 'out.'], 'ar': ['أخرج', 'دفترًا.'] },
    { 'en': ['My', 'uncle', 'is', 'angry.'], 'ar': ['عمىِ', 'غاضب.'] },
    { 'en': ['She', 'is', 'as', 'tall', 'as', 'you.'], 'ar': ['إنها', 'بنفس', 'طولك.'] },
    { 'en': ['I', 'need', 'the', 'keys.'], 'ar': ['أحتاج', 'للمفاتيح.'] },
    { 'en': ['Stay', 'thin.'], 'ar': ['حافظ', 'على', 'نحافتك.'] },
    { 'en': ['Your', 'house', 'is', 'three', 'times', 'as', 'large', 'as', 'mine.'], 'ar': ['بيتك', 'أكبر', 'من', 'بيتي', 'ثلاث', 'مرات.'] },
    { 'en': ['I', 'would', 'like', 'you', 'to', 'meet', 'a', 'friend', 'of', 'mine.'], 'ar': ['أود', 'أن', 'تقابل', 'صديقاً', 'لي.'] },
    { 'en': ["Let's", 'share', 'the', 'expenses.'], 'ar': ['دعونا', 'نتقاسم', 'المصاريف.'] },
    { 'en': ["I've", 'shut', 'all', 'six', 'windows.'], 'ar': ['أغلقت', 'النوافذ', 'الستة', 'كلها.'] },
    { 'en': ['All', 'Tom', 'really', 'wanted', 'was', 'glass', 'of', 'water.'], 'ar': ['كل', 'ما', 'كان', 'يريده', 'توم', 'حقاً', 'كان', 'كأساً', 'من', 'الماء.'] },
    { 'en': ["I'll", 'never', 'betray', 'you.'], 'ar': ['لن', 'أخونك', 'أبدًا!'] },
    { 'en': ["Don't", 'worry', 'about', 'such', 'a', 'thing.'], 'ar': ['لا', 'تقلق', 'بشأن', 'أمر', 'كهذا.'] },
    { 'en': ['I', "don't", 'want', 'to', 'work.'], 'ar': ['لا', 'أريد', 'أن', 'أعمل.'] },
    { 'en': ['It', "wasn't", 'very', 'hot', 'last', 'night.'], 'ar': ['لم', 'يكن', 'الجو', 'حاراً', 'ليلة', 'الأمس.'] },
    { 'en': ["Tom's", 'speech', 'was', 'excellent.'], 'ar': ['كانت', 'خطبة', 'توم', 'ممتازة.'] },
    { 'en': ['Music', 'is', 'a', 'universal', 'language.'], 'ar': ['الموسيقى', 'لغة', 'عالمية.'] },
    { 'en': ['Everything', 'is', 'fine.'], 'ar': ['كل', 'شيء', 'على', 'ما', 'يرام.'] },
    { 'en': ["It's", 'just', 'what', 'we', 'need.'], 'ar': ['إنه', 'تماماً', 'ما', 'نحتاج.'] },
    { 'en': ["That's", 'what', 'I', 'loved', 'about', 'Tom.'], 'ar': ['هذا', 'ما', 'أحببته', 'بشأن', '"توم"'] },
    { 'en': ['Remember', 'what', 'happened', 'last', 'year.'], 'ar': ['ألا', 'تذكر', 'ما', 'حدث', 'السنة', 'الماضية؟'] },
    { 'en': ['Tom', 'is', 'clearly', 'worn', 'out.'], 'ar': ['توم', 'مرهق', 'بوضوح'] },
    { 'en': ['What', 'does', 'your', 'aunt', 'do?'], 'ar': ['ماذا', 'تعمل', 'عمتك؟'] },
    { 'en': ['This', 'will', 'take', 'a', 'lot', 'of', 'time.'], 'ar': ['سيأخذ', 'هذا', 'وقتا', 'طويلا.'] },
    { 'en': ['There', 'was', 'a', 'car', 'accident', 'yesterday.'], 'ar': ['كان', 'هناك', 'حادث', 'سيارة', 'بالأمس.'] },
    { 'en': ['Today', 'was', 'fun.'], 'ar': ['كان', 'اليوم', 'يوماً', 'ممتعاً.'] },
    { 'en': ['He', 'ran.'], 'ar': ['ركض.'] },
    { 'en': ['These', 'are', 'two', 'nice', 'pictures.'], 'ar': ['هاتان', 'صورتان', 'جميلتان.'] },
    { 'en': ['Tom', 'is', 'my', 'hostage.'], 'ar': ['توم', 'رهينتي.'] },
    { 'en': ['The', 'clock', 'is', 'ticking.'], 'ar': ['الساعة', 'تُتكتك', '.'] },
    { 'en': ['Turn', 'down', 'the', 'radio.'], 'ar': ['أخفض', 'صوت', 'المذياع.'] },
    { 'en': ['Tom', 'pretended', 'that', 'he', "didn't", 'see', 'Mary.'], 'ar': ['تظاهر', 'توم', 'بأنه', 'لم', 'يرى', 'ماري.'] },
    { 'en': ["I'm", 'a', 'man.'], 'ar': ['أنا', 'رجلٌ.'] },
    { 'en': ['They', "weren't", 'at', 'home', 'yesterday.'], 'ar': ['لم', 'تكن', 'في', 'المنزل', 'البارحة.'] },
    { 'en': ['No', 'one', 'knows', 'his', 'real', 'name.'], 'ar': ['لا', 'يعرف', 'أحد', 'اِسمه', 'الحقيقي.'] },
    { 'en': ['I', 'am', 'from', 'China.'], 'ar': ['أنا', 'من', 'الصين.'] },
    { 'en': ["It's", 'really', 'bothering', 'me.'], 'ar': ['إنه', 'يزعجني', 'حقا.'] },
    { 'en': ['Did', 'you', 'come', 'here', 'alone?'], 'ar': ['هل', 'جئت', 'وحدك؟'] },
    { 'en': ['I', 'know', 'how', 'to', 'survive.'], 'ar': ['انا', 'اعرف', 'كيفية', 'النجاة'] },
    { 'en': ['Are', 'you', 'a', 'maniac?'], 'ar': ['هل', 'أنت', 'مجنون؟'] },
    { 'en': ['I', 'have', 'to', 'memorize', 'a', 'lot', 'of', 'passwords.'], 'ar': ['يجب', 'عليَ', 'حفظ', 'الكثير', 'من', 'كلمات', 'السر.'] },
    { 'en': ['She', 'married', 'him', 'for', 'his', 'money.'], 'ar': ['تزوجته', 'من', 'أجل', 'ماله.'] },
    { 'en': ['Tom', 'likes', 'Mary', 'and', 'Mary', 'likes', 'Tom.'], 'ar': ['يحب', '"توم"', '"ماري"', 'و', 'هي', 'أيضا', 'تحبه'] },
    { 'en': ['My', 'briefcase', 'is', 'full', 'of', 'papers.'], 'ar': ['حقيبتي', 'مليئة', 'بالأوراق.'] },
    { 'en': ['An', 'old', 'lady', 'sang', 'to', 'me.'], 'ar': ['غنت', 'لي', 'امراة', 'عجوز.'] },
    { 'en': ['This', 'car', 'is', 'his.'], 'ar': ['هذه', 'السيارة', 'له'] },
    { 'en': ['Who', 'is', 'the', 'man', 'playing', 'the', 'piano?'], 'ar': ['من', 'ذلك', 'الرجل', 'الذي', 'يعزف', 'على', 'البيانو؟'] },
    { 'en': ['She', 'stayed', 'in', 'the', 'house', 'all', 'day.'], 'ar': ['بقيت', 'في', 'المنزل', 'طوال', 'اليوم.'] },
    { 'en': ['Tom', 'has', 'his', "father's", 'eyes.'], 'ar': ['عينا', 'توم', 'كعيني', 'أبيه.'] },
    { 'en': ['Stop', 'bothering', 'your', 'father.'], 'ar': ['كف', 'عن', 'إزعاج', 'والدك'] },
    { 'en': ['How', 'is', 'your', 'dad?'], 'ar': ['كيف', 'حال', 'أبيك؟'] },
    { 'en': ['I', 'know', 'him.'], 'ar': ['أعرفه.'] },
    { 'en': ['I', 'want', 'an', 'answer', 'from', 'you', 'ASAP.'], 'ar': ['أريد', 'إجابة', 'منك', 'في', 'أسرع', 'وقت', 'ممكن.'] },
    { 'en': ['I', 'never', 'actually', 'wanted', 'to', 'go', 'there.'], 'ar': ['لم', 'أرغب', 'ابدا', 'في', 'الواقع', 'أن', 'أذهب', 'إلى', 'هناك.'] },
    { 'en': ["We're", 'students.'], 'ar': ['نحن', 'طلاب'] },
    { 'en': ['When', 'are', 'you', 'busy?'], 'ar': ['متى', 'تكون', 'مشغولاً؟'] },
    { 'en': ['She', 'was', 'there', 'all', 'morning.'], 'ar': ['كانت', 'هناك', 'طوال', 'النهار.'] },
    { 'en': ["I'm", 'sorry,', 'but', "it's", 'no', 'longer', 'available.'], 'ar': ['أنا', 'آسف،', 'ولكن', 'هذا', 'لم', 'يعد', 'متاحاً.'] },
    { 'en': ['This', 'desk', 'takes', 'up', 'too', 'much', 'room.'], 'ar': ['هذا', 'المكتب', 'يأخذ', 'مساحة', 'كبيرة.'] },
    { 'en': ['The', 'committee', 'consists', 'of', 'four', 'members.'], 'ar': ['يتألف', 'المجلس', 'من', 'أربعة', 'أعضاء.'] },
    { 'en': ['Nothing', 'is', 'worse', 'than', 'war.'], 'ar': ['ليس', 'هناك', 'ما', 'هو', 'أسوأ', 'من', 'الحرب.'] },
    { 'en': ['Is', 'English', 'harder', 'than', 'Japanese?'], 'ar': ['هل', 'اللغة', 'الإنجليزية', 'أصعب', 'من', 'اليابانية؟'] },
    { 'en': ['Monday', 'comes', 'after', 'Sunday.'], 'ar': ['يأتي', 'الاثنين', 'بعد', 'الأحد.'] },
    { 'en': ['He', 'is', 'deaf,', 'but', 'knows', 'how', 'to', 'read', 'lips.'], 'ar': ['هو', 'أصم،', 'لكنه', 'يعرف', 'كيف', 'يقرأ', 'الشفاه.'] },
    { 'en': ['I', 'have', 'an', 'exam', 'tomorrow.'], 'ar': ['عندي', 'اختبار', 'في', 'الغد.'] },
    { 'en': ['She', 'turned', 'around', 'and', 'smiled.'], 'ar': ['استدارت', 'بابتسامة.'] },
    { 'en': ['I', 'have', 'three', 'cousins', 'on', 'my', "mother's", 'side.'], 'ar': ['لدي', 'ثلاث', 'أولاد', 'خالة.'] },
    { 'en': ["That's", 'because', "you're", 'a', 'girl.'], 'ar': ['هذا', 'لأنكِ', 'فتاة.'] },
    { 'en': ['I', "shouldn't", 'have', 'told', 'you', 'anything.'], 'ar': ['ما', 'كان', 'يجب', 'علي', 'أن', 'أخبرك', 'بكل', 'شيء'] },
    { 'en': ["I'll", 'eat', 'it', 'here.'], 'ar': ['سآكل', 'هاهنا.'] },
    { 'en': ['The', 'cat', 'is', 'on', 'the', 'mat.'], 'ar': ['القطة', 'على', 'السجادة.'] },
    { 'en': ['I', 'walked', 'from', 'the', 'station.'], 'ar': ['مشيت', 'من', 'المحطة.'] },
    { 'en': ['Why', 'is', 'it', 'so', 'hot?'], 'ar': ['لم', 'الجو', 'حار', 'هكذا؟'] },
    { 'en': ['You', 'are', 'not', 'Japanese.'], 'ar': ['أنت', 'لست', 'يابانياً.'] },
    { 'en': ["Don't", 'say', 'anything', 'without', 'thinking.'], 'ar': ['لا', 'تقل', 'شيئًا', 'قبل', 'التفكير', 'فيه.'] },
    { 'en': ['I', "didn't", 'want', 'to', 'cry', 'in', 'front', 'of', 'Tom.'], 'ar': ['لا', 'أريد', 'البكاء', 'أمام', 'توم.'] },
    { 'en': ['Will', 'you', 'allow', 'me', 'to', 'play', 'the', 'piano?'], 'ar': ['أتسمح', 'لي', 'بالعزف', 'على', 'البيانو؟'] },
    { 'en': ['You', 'must', 'control', 'yourself.'], 'ar': ['عليك', 'أن', 'تتحكم', 'بنزواتك.'] },
    { 'en': ["That's", 'how', 'I', 'grew', 'up.'], 'ar': ['هكذا', 'نشأت\\\\تربيت'] },
    { 'en': ['How', 'big', 'is', 'it?'], 'ar': ['ما', 'هو', 'حجمها؟'] },
    { 'en': ['I', 'want', 'you', 'to', 'try', 'it.'], 'ar': ['أريدك', 'أن', 'تجرّبَهُ.'] },
    { 'en': ['They', 'had', 'only', 'one', 'child.'], 'ar': ['كان', 'لديهما', 'ولد', 'واحد.'] },
    { 'en': ['I', 'need', 'to', 'rest', 'a', 'moment.'], 'ar': ['علي', 'أن', 'أرتاح', 'لدقيقة.'] },
    { 'en': ["It's", 'artificial.'], 'ar': ['إنه', 'صناعي.'] },
    { 'en': ['The', 'accident', 'happened', 'two', 'hours', 'ago.'], 'ar': ['وقع', 'الحادث', 'قبل', 'ساعتين.'] },
    { 'en': ['I', 'ate', 'caviar.'], 'ar': ['أكلت', 'الكافيار.'] },
    { 'en': ['Please', 'take', 'off', 'your', 'hat.'], 'ar': ['من', 'فضلك', 'إخلع', 'قبعتك.'] },
    { 'en': ["Don't", 'forget', 'that', 'we', 'have', 'homework.'], 'ar': ['لا', 'تنس', 'أن', 'لدينا', 'واجباً.'] },
    { 'en': ["She's", 'much', 'heavier', 'than', 'him.'], 'ar': ['إنها', 'أثقل', 'منه', 'بكثير.'] },
    { 'en': ["It's", 'annoying.'], 'ar': ['إنهُ', 'مزعج.'] },
    { 'en': ['Just', 'a', 'moment,', 'please.'], 'ar': ['لحظة', 'من', 'فضلك.'] },
    { 'en': ['I', "don't", 'have', 'the', 'address', 'with', 'me.'], 'ar': ['ليس', 'معي', 'العنوان.'] },
    { 'en': ["I'll", 'be', 'a', 'little', 'late', 'today.'], 'ar': ['سأتأخر', 'قليلا', 'اليوم.'] },
    { 'en': ['Nothing', 'remained', 'in', 'the', 'refrigerator.'], 'ar': ['لم', 'يبقَ', 'شيء', 'في', 'الثلاجة.'] },
    { 'en': ['It', 'is', 'dangerous', 'to', 'drive', 'so', 'fast.'], 'ar': ['إنه', 'خطر', 'أن', 'تقود', 'بتهور.'] },
    { 'en': ["It's", 'a', 'secret.'], 'ar': ['إنه', 'سر.'] },
    { 'en': ['Tom', 'refused', 'to', 'talk', 'to', 'me.'], 'ar': ['رفض', 'توم', 'التحدث', 'معي.'] },
    { 'en': ['You', 'will', 'succeed', 'some', 'day.'], 'ar': ['ستنجح', 'يوماً', 'ما.'] },
    { 'en': ['There', 'is', 'no', 'sign', 'of', 'life', 'on', 'Mars.'], 'ar': ['لا', 'أثر', 'لوجود', 'حياة', 'على', 'سطح', 'كوكب', 'المريخ.'] },
    { 'en': ['Hey,', 'what', 'are', 'you', 'guys', 'talking', 'about?'], 'ar': ['عمّ', 'تتكلّمون', 'يا', 'رفاق؟'] },
    { 'en': ['It', 'is', 'too', 'hot.'], 'ar': ['الجو', 'حار', 'جدا'] },
    { 'en': ['People', 'who', "don't", 'work,', "won't", 'be', 'fed.'], 'ar': ['لا', 'طعام', 'لمن', 'لا', 'يعمل.'] },
    { 'en': ["That's", 'a', 'great', 'poem.'], 'ar': ['هذه', 'قصيدة', 'رائعة.'] },
    { 'en': ["I'm", 'from', 'Turkey.'], 'ar': ['أنا', 'من', 'تركيا.'] },
    { 'en': ['The', 'old', 'man', 'starved', 'to', 'death.'], 'ar': ['جاع', 'الرجل', 'العجوز', 'حتى', 'الموت.'] },
    { 'en': ['I', 'bought', 'her', 'a', 'watch.'], 'ar': ['اشتريت', 'لها', 'ساعة.'] },
    { 'en': ['I', 'have', 'one', 'brother.'], 'ar': ['لديّ', 'أخ', 'واحد.'] },
    { 'en': ['Perhaps', 'you', 'should', 'do', 'that', 'now.'], 'ar': ['ربما', 'يجب', 'ان', 'تفعل', 'ذلك', 'الآن.'] },
    { 'en': ['She', "didn't", 'know', 'what', 'to', 'do', 'with', 'the', 'problem.'], 'ar': ['لم', 'تعرف', 'كيف', 'تتصرف', 'تجاه', 'المشكلة.'] },
    { 'en': ['Your', 'answer', 'is', 'right.'], 'ar': ['إجابتك', 'صحيحة.'] },
    { 'en': ['I', 'was', 'invited', 'to', 'their', 'wedding.'], 'ar': ['دُعيتُ', 'إلى', 'عرسهما.'] },
    { 'en': ['I', 'want', 'a', 'list', 'of', 'all', "Tom's", 'relatives.'], 'ar': ['أريد', 'قائمة', 'بجميع', 'أقارب', '"توم".'] },
    { 'en': ["I'm", 'from', 'Kyoto.'], 'ar': ['أنا', 'من', 'كيوتو.'] },
    { 'en': ['My', 'father', 'died', 'of', 'lung', 'cancer.'], 'ar': ['مات', 'أبي', 'بسبب', 'سرطان', 'الرئة.'] },
    { 'en': ["You'll", 'need', 'a', 'temporary', 'bridge.'], 'ar': ['ستحتاج', 'جسرًا', 'مؤقتًا.'] },
    { 'en': ['It', "isn't", 'mine.'], 'ar': ['ليس', 'ملكي.'] },
    { 'en': ['I', 'wanted', 'to', 'surprise', 'her.'], 'ar': ['أردت', 'أن', 'أفاجئها.'] },
    { 'en': ['Tom', 'paid', 'Mary', 'back.'], 'ar': ['أعاد', 'توم', 'المال', 'لماري.'] },
    { 'en': ['This', 'is', 'too', 'big.'], 'ar': ['هذا', 'كبير', 'جدا'] },
    { 'en': ['Enjoy', 'your', 'meal!'], 'ar': ['بالصحة', 'و', 'العافية!'] },
    { 'en': ['Why', 'is', 'that', 'wrong?'], 'ar': ['لماذا', 'هذا', 'خطأ؟'] },
    { 'en': ['I', 'thought', 'you', 'said', 'Tom', 'was', 'dead.'], 'ar': ['ظننتُكَ', 'قلتَ', 'أن', 'توم', 'كان', 'ميتاً.'] },
    { 'en': ['I', 'told', 'you', 'this', 'was', 'going', 'to', 'be', 'boring.'], 'ar': ['قلت', 'لك', 'أن', 'ذلك', 'سيكون', 'مملاً.'] },
    { 'en': ['He', 'is', 'a', 'naughty', 'boy.'], 'ar': ['هو', 'ولد', 'مشاغب.'] },
    { 'en': ['Hitler', 'led', 'Germany', 'into', 'war.'], 'ar': ['قاد', 'هتلر', 'بلاده', 'إلى', 'الحرب.'] },
    { 'en': ['This', 'is', "Tom's", 'favorite', 'book.'], 'ar': ['هذا', 'كتاب', 'توم', 'المفضل.'] },
    { 'en': ['I', 'just', 'finished', 'the', 'work.'], 'ar': ['لقد', 'أنهيت', 'عملي', 'للتو.'] },
    { 'en': ['They', 'say', 'that', 'Venice', 'is', 'a', 'beautiful', 'city.'], 'ar': ['يُقال', 'أن', 'البندقيّة', 'مدينة', 'جميلة.'] },
    { 'en': ['All', 'you', 'have', 'to', 'do', 'is', 'read', 'these', 'two', 'pages.'], 'ar': ['كل', 'ما', 'عليك', 'فعله', 'هو', 'قراءة', 'هاتين', 'الصفحتين.'] },
    { 'en': ['Are', 'they', 'still', 'angry?'], 'ar': ['أمازالوا', 'غاضبين؟'] },
    { 'en': ['I', 'want', 'to', 'live', 'in', 'the', 'country.'], 'ar': ['أريد', 'العيش', 'في', 'قرية.'] },
    { 'en': ['You', 'have', 'to', 'follow', 'the', 'rules.'], 'ar': ['عليك', 'التقيد', 'بالقوانين.'] },
    { 'en': ["It's", 'good', 'talking', 'to', 'you.'], 'ar': ['من', 'الجميل', 'محادثتك.'] },
    { 'en': ['I', 'think', 'life', 'is', 'what', 'you', 'make', 'it.'], 'ar': ['اعتقد', 'ان', 'الحياة', 'هي', 'ما', 'تصنعه.'] },
    { 'en': ['You', 'are', 'my', 'father.'], 'ar': ['أنت', 'والدي.'] },
    { 'en': ['Maybe', 'she', "won't", 'come.'], 'ar': ['من', 'الممكن', 'أن', 'لا', 'تأتي.'] },
    { 'en': ['Do', 'you', 'want', 'anything?'], 'ar': ['أتريد', 'شيئاً؟'] },
    { 'en': ['You', 'must', 'clean', 'your', 'room.'], 'ar': ['عليك', 'أن', 'تنظّف', 'غرفتك.'] },
    { 'en': ['The', 'card', 'you', 'drew', 'was', 'a', 'red,', "wasn't", 'it?'], 'ar': ['البطاقة', 'التي', 'سحبتها', 'حمراء،', 'أليس', 'كذلك؟'] },
    { 'en': ['The', 'old', 'man', 'lived', 'by', 'himself.'], 'ar': ['عاش', 'الرجل', 'العجوز', 'وحده.'] },
    { 'en': ['These', 'are', 'our', 'desks.'], 'ar': ['هذه', 'مكاتبنا.'] },
    { 'en': ['I', "don't", 'blame', 'you', 'for', 'hitting', 'him.'], 'ar': ['لا', 'ألومك', 'على', 'ضربه.'] },
    { 'en': ['May', 'I', 'play', 'the', 'piano?'], 'ar': ['أتسمح', 'لي', 'بالعزف', 'على', 'البيانو؟'] },
    { 'en': ['This', 'T-shirt', 'is', 'too', 'small', 'for', 'me.'], 'ar': ['هذا', 'القميص', 'صغير', 'عليّ.'] },
    { 'en': ['Tom', 'made', 'Mary', 'wash', 'his', 'car.'], 'ar': ['توم', 'جعل', 'ماري', 'تغسل', 'سيارتهُ.'] },
    { 'en': ['That', "doesn't", 'even', 'make', 'sense.'], 'ar': ['ليس', 'ذلك', 'منطقيا', 'بتاتا.'] },
    { 'en': ['I', 'forgot.'], 'ar': ['لقد', 'نسيت.'] },
    { 'en': ['She', 'went', 'upstairs', 'to', 'her', 'bedroom.'], 'ar': ['صعدت', 'إلى', 'غرفة', 'نومها', 'في', 'الطابق', 'العلوي.'] },
    { 'en': ['It', 'was', 'probably', 'not', 'true.'], 'ar': ['كانت', 'على', 'الأرجح', 'غير', 'صحيحة.'] },
    { 'en': ['You', 'are', 'absolutely', 'right.'], 'ar': ['إنك', 'محق', 'تماماً.'] },
    { 'en': ['She', 'may', 'not', 'come', 'here', 'tomorrow.'], 'ar': ['من', 'الممكن', 'أنها', 'لن', 'تأتي', 'غداً.'] },
    { 'en': ['She', 'turned', 'eighty-one.'], 'ar': ['أصبح', 'عمرها', 'واحداً', 'و', 'ثمانين', 'سنة.'] },
    { 'en': ['I', 'guess', "I'd", 'better', 'get', 'back', 'to', 'work.'], 'ar': ['لعله', 'من', 'الأفضل', 'أن', 'أعود', 'لعملي.'] },
    { 'en': ['What', 'are', 'you', 'going', 'to', 'do', 'with', 'it?'], 'ar': ['ماذا', 'ستفعل', 'بها؟'] },
    { 'en': ['The', 'lake', 'was', 'frozen.'], 'ar': ['تجمدت', 'البحيرة.'] },
    { 'en': ['I', 'like', 'to', 'study', 'foreign', 'languages.'], 'ar': ['أحبّ', 'دراسة', 'اللّغات', 'الأجنبيّة.'] },
    { 'en': ["That's", 'absolutely', 'right.'], 'ar': ['إن', 'ذلك', 'بالطبع', 'صحيح.'] },
    { 'en': ['I', 'have', 'no', 'homework', 'today.'], 'ar': ['ليس', 'عندي', 'وظيفة', 'مدرسية', 'اليوم.'] },
    { 'en': ['Can', 'I', 'help', 'you?'], 'ar': ['هل', 'لي', 'أن', 'أساعدك؟'] },
    { 'en': ['Is', 'she', 'a', 'doctor?'], 'ar': ['أهي', 'طبيبة؟'] },
    { 'en': ['My', 'conscience', 'is', 'clear.'], 'ar': ['ضميري', 'مرتاح.'] },
    { 'en': ['The', 'TV', 'remote', 'control', 'is', 'under', 'the', 'couch.'], 'ar': ['جهاز', 'تحكم', 'التلفاز', 'تحت', 'الأريكة.'] },
    { 'en': ['One', 'day,', 'you', 'will', 'understand.'], 'ar': ['ستفهم', 'ذلك', 'يوما', 'ما.'] },
    { 'en': ['Parents', 'love', 'their', 'children.'], 'ar': ['الآباء', 'يحبون', 'أولادهم.'] },
    { 'en': ['A', 'friend', 'in', 'need', 'is', 'a', 'friend', 'indeed.'], 'ar': ['الصديق', 'وقت', 'الضيق.'] },
    { 'en': ['Tom', 'is', 'a', 'good', 'cook.'], 'ar': ['توم', 'طباخ', 'جيد.'] },
    { 'en': ['May', 'I', 'go', 'to', 'the', 'movies', 'tonight?'], 'ar': ['هل', 'لي', 'أن', 'أخرج', 'لمشاهدة', 'فيلم', 'الليلة؟'] },
    { 'en': ['I', 'have', 'a', 'cough.'], 'ar': ['لدي', 'كحة.'] },
    { 'en': ['I', 'am', 'an', 'American', 'citizen.'], 'ar': ['أنا', 'مواطن', 'أمريكي.'] },
    { 'en': ['Every', 'house', 'had', 'a', 'garden.'], 'ar': ['كان', 'هناك', 'حديقة', 'عند', 'كل', 'منزل.'] },
    { 'en': ['I', 'think', 'he', "won't", 'come.'], 'ar': ['لا', 'أعتقد', 'أنه', 'سيأتي.'] },
    { 'en': ["We're", 'at', 'war.'], 'ar': ['نحن', 'في', 'حرب.'] },
    { 'en': ['Where', 'are', 'they', 'going', 'to?'], 'ar': ['إلى', 'أين', 'هم', 'ذاهبون؟'] },
    { 'en': ['This', 'boat', 'is', 'seaworthy.'], 'ar': ['هذا', 'القارب', 'صالحاً', 'للإبحار.'] },
    { 'en': ['Why', 'do', 'you', 'study?'], 'ar': ['لماذا', 'تدرس؟'] },
    { 'en': ['What', 'led', 'you', 'to', 'believe', 'that?'], 'ar': ['ما', 'الذي', 'جعلك', 'تعتقد', 'ذلك؟'] },
    { 'en': ['I', 'really', 'wish', 'to', 'see', 'you', 'today.'], 'ar': ['حقا', 'أريد', 'رؤيتك', 'اليوم.'] },
    { 'en': ['This', 'is', 'not', 'important.'], 'ar': ['هذا', 'ليس', 'مهماً.'] },
    { 'en': ['Spanish', 'is', 'her', 'mother', 'tongue.'], 'ar': ['الأسبانية', 'هي', 'لغتها', 'الأم.'] },
    { 'en': ['Tom', 'likes', 'cheese.'], 'ar': ['يحب', 'توم', 'الجبنة.'] },
    { 'en': ['What', 'are', 'you', 'talking', 'about?'], 'ar': ['ما', 'الذي', 'تتحدث', 'عنه؟'] },
    { 'en': ['You', 'will', 'miss', 'the', 'train.'], 'ar': ['سيفوتك', 'القطار.'] },
    { 'en': ["Don't", 'worry', 'about', 'my', 'dog.'], 'ar': ['لا', 'تقلق', 'بشأن', 'كلبي.'] },
    { 'en': ['Please', 'show', 'me', 'your', 'injured', 'arm.'], 'ar': ['أرني', 'ذراعك', 'المجروح', 'من', 'فضلك.'] },
    { 'en': ["Let's", 'start!'], 'ar': ['لنبدأ.'] },
    { 'en': ['What', 'are', 'you', 'concerned', 'about?'], 'ar': ['ما', 'الذي', 'يقلقك؟'] },
    { 'en': ['She', 'loves', 'Tom.'], 'ar': ['هي', 'تحب', 'توم'] },
    { 'en': ['My', 'father', 'goes', 'to', 'work', 'by', 'bike.'], 'ar': ['يركب', 'أبي', 'الدراجة', 'إلى', 'عمله.'] },
    { 'en': ['I', 'would', 'like', 'to', 'know', 'her', 'name.'], 'ar': ['أود', 'أن', 'أعرف', 'اسمها.'] },
    { 'en': ['Something', "doesn't", 'make', 'any', 'sense.'], 'ar': ['شيء', 'ليس', 'منطقيا', 'أبدا.'] },
    { 'en': ['I', 'will', 'fly', 'to', 'Germany.'], 'ar': ['سأُحَلِق', 'إلى', 'المانيا.'] },
    { 'en': ['You', 'are', 'the', 'man', "I've", 'been', 'looking', 'for.'], 'ar': ['أنت', 'الرجل', 'الذي', 'كنت', 'أبحث', 'عنه.'] },
    { 'en': ['It', 'is', 'neither', 'good', 'nor', 'bad.'], 'ar': ['انها', 'لا', 'جيدة', 'ولا', 'سيئة.'] },
    { 'en': ['Hurry', 'up,', 'or', "you'll", 'miss', 'the', 'train.'], 'ar': ['أسرع', 'و', 'إلا', 'فاتك', 'القطار.'] },
    { 'en': ['She', 'got', 'to', 'the', 'station', 'at', 'seven.'], 'ar': ['وصلت', 'إلى', 'المحطة', 'الساعة', 'السابعة.'] },
    { 'en': ['Have', 'you', 'ever', 'gone', 'skinny', 'dipping?'], 'ar': ['هل', 'سبق', 'و', 'ذهبت', 'للسباحة', 'عارياً.'] },
    { 'en': ['No', 'one', 'survived', 'the', 'plane', 'crash.'], 'ar': ['لم', 'ينجُ', 'أحد', 'من', 'تحطم', 'الطائرة.'] },
    { 'en': ['My', 'brother', 'is', 'an', 'idiot.'], 'ar': ['أخي', 'أحمق.'] },
    { 'en': ['You', "shouldn't", 'have', 'come', 'here.'], 'ar': ['لم', 'يكن', 'عليك', 'المجيء', 'هنا.'] },
    { 'en': ['I', "don't", 'have', 'the', 'address', 'now.'], 'ar': ['ليس', 'معي', 'العنوان', 'الآن.'] },
    { 'en': ['They', 'agreed', 'on', 'a', 'price.'], 'ar': ['إتفقوا', 'على', 'سعر.'] },
    { 'en': ['Why', "don't", 'you', 'ride', 'your', 'bicycle', 'to', 'work?'], 'ar': ['لماذا', 'لا', 'تركب', 'دراجتك', 'للعمل؟'] },
    { 'en': ['I', "don't", 'live', 'that', 'far', 'from', 'here.'], 'ar': ['لا', 'أسكن', 'مكانا', 'بعيدا', 'ذاك', 'البعد', 'عن', 'هنا.'] },
    { 'en': ['The', 'nurse', 'took', 'his', 'temperature.'], 'ar': ['قاست', 'الممرضة', 'حرارته.'] },
    { 'en': ['This', 'happened', 'a', 'long', 'time', 'ago.'], 'ar': ['لقد', 'حدث', 'هذا', 'منذ', 'زمن', 'طويل.'] },
    { 'en': ['We', 'want', 'to', 'know', 'why.'], 'ar': ['نريد', 'أن', 'نعرف', 'لماذا'] },
    { 'en': ['Does', 'Tom', 'want', 'to', 'come?'], 'ar': ['هل', 'يريد', 'توم', 'أن', 'يأتي؟'] },
    { 'en': ["We've", 'done', 'what', 'we', 'came', 'to', 'do.'], 'ar': ['أتممنا', 'ما', 'أتينا', 'لأجله.'] },
    { 'en': ['My', 'friend', 'studies', 'Korean.'], 'ar': ['صديقي', 'يدرس', 'اللغة', 'الكورية.'] },
    { 'en': ['Do', 'you', 'like', 'tennis?'], 'ar': ['هل', 'تحب', 'لعب', 'التنس؟'] },
    { 'en': ['It', 'will', 'probably', 'snow', 'tomorrow.'], 'ar': ['غالباً', 'سيهطل', 'الثلج', 'غداً.'] },
    { 'en': ["I'd", 'like', 'to', 'report', 'a', 'theft.'], 'ar': ['أريد', 'أن', 'أبلغ', 'عن', 'سرقة.'] },
    { 'en': ['Which', 'came', 'first,', 'the', 'chicken', 'or', 'the', 'egg?'], 'ar': ['أيهما', 'جاء', 'أولاً،', 'الدجاجة', 'أم', 'البيضة؟'] },
    { 'en': ['I', 'will', 'be', 'back', 'soon.'], 'ar': ['سأعود', 'قريباً.'] },
    { 'en': ['Who', 'am', 'I', 'talking', 'with?'], 'ar': ['من', 'معي؟'] },
    { 'en': ['The', 'rioters', 'beat', 'many', 'policemen', 'to', 'death.'], 'ar': ['قام', 'المتظاهرون', 'بضرب', 'العديد', 'من', 'رجال', 'الشرطة', 'حتى', 'الموت.'] },
    { 'en': ['I', "didn't", 'expect', 'so', 'many', 'people', 'to', 'be', 'here.'], 'ar': ['لم', 'أتوقع', 'أن', 'يكون', 'هناك', 'هذا', 'الحشد', 'من', 'الناس.'] },
    { 'en': ['She', 'got', 'good', 'grades', 'in', 'English.'], 'ar': ['حصلت', 'على', 'علامة', 'جيدة', 'في', 'مادة', 'الإنجليزية.'] },
    { 'en': ['What', 'did', 'you', 'major', 'in', 'at', 'college?'], 'ar': ['أي', 'تخصص', 'درست', 'في', 'الجامعة؟'] },
    { 'en': ['Be', 'discreet.'], 'ar': ['كُن', 'حَذِراً.'] },
    { 'en': ['Do', 'you', 'study', 'English?'], 'ar': ['هل', 'تدرس', 'اللغة', 'الإنكليزية؟'] },
    { 'en': ['I', "don't", 'like', "what's", 'going', 'on.'], 'ar': ['لا', 'أحب', 'ما', 'يجري.'] },
    { 'en': ['What', 'did', 'you', 'do', 'this', 'morning?'], 'ar': ['ماذا', 'فعلت', 'هذا', 'الصباح؟'] },
    { 'en': ['She', 'was', 'at', 'the', 'crime', 'scene.'], 'ar': ['إنها', 'كانت', 'في', 'موقع', 'الجريمة.'] },
    { 'en': ['Without', 'air,', 'nothing', 'could', 'live.'], 'ar': ['من', 'دون', 'الهواء', '،', 'لن', 'يبقى', 'شيء', 'على', 'قيد', 'الحياة.'] },
    { 'en': ['It', 'seems', 'interesting', 'to', 'me.'], 'ar': ['يبدو', 'لي', 'مثيرًا', 'للاهتمام.'] },
    { 'en': ['How', "I've", 'missed', 'you!'], 'ar': ['لكم', 'اشتقت', 'إليك!'] },
    { 'en': ['The', 'trees', 'are', 'green.'], 'ar': ['الأشجار', 'خضراء.'] },
    { 'en': ['She', 'has', 'a', 'few', 'books.'], 'ar': ['لديها', 'القليل', 'من', 'الكتب.'] },
    { 'en': ['I', 'felt', 'a', 'light', 'touch', 'on', 'my', 'shoulder.'], 'ar': ['لقد', 'شعرت', 'بلمسة', 'خفيفة', 'على', 'كتفي.'] },
    { 'en': ['You', 'can', 'study', 'here.'], 'ar': ['يمكنك', 'أن', 'تدرس', 'هنا.'] },
    { 'en': ['I', 'was', 'only', 'there', 'for', 'three', 'days.'], 'ar': ['كنت', 'هناك', 'لثلاثة', 'أيام', 'فقط.'] },
    { 'en': ['My', 'father', 'works', 'in', 'a', 'factory.'], 'ar': ['يعمل', 'أبي', 'في', 'مصنع.'] },
    { 'en': ['I', "haven't", 'had', 'a', 'chance', 'to', 'see', 'the', 'movie', 'yet.'], 'ar': ['لم', 'تسنح', 'لي', 'الفرصة', 'لمشاهدة', 'الفيلم.'] },
    { 'en': ['My', 'brother', 'helped', 'me', 'with', 'my', 'homework.'], 'ar': ['أخي', 'ساعدني', 'في', 'حل', 'واجبي.'] },
    { 'en': ['Can', 'I', 'go', 'out', 'to', 'play?'], 'ar': ['هل', 'يمكننى', 'أن', 'أخرج', 'للعب؟'] },
    { 'en': ['One', 'of', 'my', 'dreams', 'is', 'to', 'learn', 'Icelandic.'], 'ar': ['إحدى', 'أمنياتي', 'هي', 'أن', 'أتعلم', 'اللغة', 'الآيسلندية.'] },
    { 'en': ["Let's", 'begin.'], 'ar': ['لنبدأ.'] },
    { 'en': ['I', "didn't", 'want', 'to', 'look', 'stupid.'], 'ar': ['لم', 'أرغب', 'بأن', 'أبدوا', 'غبيا.'] },
    { 'en': ['Do', 'it', 'right', 'away.'], 'ar': ['أفعله', 'مباشرة'] },
    { 'en': ['Are', 'you', 'related', 'to', 'him?'], 'ar': ['هل', 'أنت', 'قريبه؟'] },
    { 'en': ['There', 'were', 'twenty', 'or', 'so', 'people', 'there.'], 'ar': ['كان', 'هناك', 'حوالي', 'عشرون', 'شخصاً.'] },
    { 'en': ['For', 'me,', "it's", 'important.'], 'ar': ['بالنسبة', 'لي', 'إن', 'ذلك', 'شيء', 'مهم.'] },
    { 'en': ['He', 'closed', 'the', 'door.'], 'ar': ['أغلقَ', 'الباب.'] },
    { 'en': ['How', 'old', 'is', 'your', 'sister?'], 'ar': ['كم', 'عمر', 'أختك', '؟'] },
    { 'en': ['Why', 'do', 'you', 'like', 'wearing', 'hats', 'so', 'much?'], 'ar': ['لماذا', 'تحب', 'ارتداء', 'القبعات', 'كثيرا؟'] },
    { 'en': ["There's", 'nowhere', 'to', 'hide.'], 'ar': ['ليس', 'هناك', 'مكان', 'للاختباء.'] },
    { 'en': ['I', 'feel', 'very', 'tired', 'tonight.'], 'ar': ['أنا', 'متعب', 'كثيرا', 'الليلة.'] },
    { 'en': ["She's", 'stupid,', 'but', 'honest.'], 'ar': ['هي', 'غبية,', 'ولكن', 'صادقة.'] },
    { 'en': ['I', 'think', 'he', "won't", 'come.'], 'ar': ['لا', 'أظن', 'أنه', 'آتٍ.'] },
    { 'en': ['She', 'began', 'crying.'], 'ar': ['أجهشت', 'بالبكاء.'] },
    { 'en': ["That's", 'a', 'good', 'plan.'], 'ar': ['هذه', 'خطة', 'جيدة.'] },
    { 'en': ["She's", 'not', 'here.'], 'ar': ['هي', 'ليست', 'هنا'] },
    { 'en': ['There', "isn't", 'any', 'soap.'], 'ar': ['لم', 'يعد', 'هناك', 'أي', 'صابون.'] },
    { 'en': ['Your', 'English', 'is', 'improving.'], 'ar': ['لغتك', 'الإنجليزية', 'تتحسن.'] },
    { 'en': ['Do', 'you', 'remember', 'what', 'she', 'said?'], 'ar': ['هل', 'تتذكر', 'ما', 'قالته؟'] },
    { 'en': ["You're", 'quite', 'right.'], 'ar': ['إنك', 'محق', 'تماماً.'] },
    { 'en': ['He', 'came', 'back', 'from', 'America.'], 'ar': ['عاد', 'من', 'أمريكا.'] },
    { 'en': ['I', 'may', 'be', 'old,', 'but', 'I', 'can', 'still', 'ride', 'a', 'bike.'], 'ar': ['لربّما', 'كنت', 'كبير', 'السن', 'ولكنني', 'لا', 'زلت', 'أستطيع', 'ركوب', 'الدراجة.'] },
    { 'en': ['Tom', 'attacked', 'Mary', 'with', 'a', 'knife.'], 'ar': ['هاجم', 'توم', 'ماري', 'بسكّين.'] },
    { 'en': ['She', 'waited', 'for', 'you', 'for', 'two', 'hours.'], 'ar': ['انتظرتك', 'لساعتين.'] },
    { 'en': ['Falling', 'rocks', 'present', 'a', 'danger', 'to', 'climbers.'], 'ar': ['الصخور', 'الواقعة', 'تشكل', 'خطرا', 'على', 'المتسلقين.'] },
    { 'en': ['Everyone', 'worked', 'hard.'], 'ar': ['الكلّ', 'عملَ', 'بجدّ'] },
    { 'en': ['He', 'is', 'not', 'available.'], 'ar': ['ليس', 'متاحاً.'] },
    { 'en': ['School', 'begins', 'at', '8:30', 'a.m.'], 'ar': ['تبدأ', 'المدرسة', 'الساعة', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ['When', 'does', 'the', 'restaurant', 'close?'], 'ar': ['متى', 'يُغلق', 'المطعم؟'] },
    { 'en': ['You', 'have', 'many', 'books.'], 'ar': ['لديك', 'الكثير', 'من', 'الكتب.'] },
    { 'en': ['This', 'question', 'is', 'not', 'easy.'], 'ar': ['هذا', 'السؤال', 'ليس', 'سهلاً.'] },
    { 'en': ['What', 'time', 'does', 'your', 'plane', 'leave?'], 'ar': ['في', 'أي', 'وقت', 'ستقلع', 'طائرتك؟'] },
    { 'en': ['Rear', 'end', 'collisions', 'often', 'cause', 'whiplash.'], 'ar': ['غالباً', 'ما', 'تسبب', 'الاصطدامات', 'الخلفية', 'اصابة', 'في', 'الرقبة', '.'] },
    { 'en': ['He', 'reached', 'home', 'shortly', 'before', 'five', "o'clock."], 'ar': ['وصل', 'إلى', 'بيته', 'قبل', 'الخامسة', 'بدقائق.'] },
    { 'en': ['They', 'shared', 'the', 'money.'], 'ar': ['إنهم', 'إقتسموا', 'المال.'] },
    { 'en': ['You', "don't", 'have', 'to', 'listen', 'to', 'what', 'he', 'says.'], 'ar': ['لا', 'يجب', 'عليك', 'أن', 'تستمع', 'إلی', 'ما', 'يقول.'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'the', 'hospital.'], 'ar': ['يجب', 'ان', 'اذهب', 'للمستشفى.'] },
    { 'en': ['Jump!'], 'ar': ['اقفز!'] },
    { 'en': ['This', 'is', 'the', 'case.'], 'ar': ['هذا', 'هو', 'الحال.'] },
    { 'en': ['Do', 'you', 'drink', 'coffee?'], 'ar': ['هل', 'تشرب', 'القهوة', 'عادةً؟'] },
    { 'en': ['I', 'am', 'a', 'Muslim.'], 'ar': ['أنا', 'مسلم'] },
    { 'en': ["Let's", 'have', 'some', 'watermelon.'], 'ar': ['لنأكل', 'بطيخاً!'] },
    { 'en': ['What', 'did', 'you', 'talk', 'about?'], 'ar': ['عن', 'ماذا', 'تحدّثت؟'] },
    { 'en': ['The', 'woman', 'is', 'reading.'], 'ar': ['المرأة', 'تقرأ'] },
    { 'en': ['I', 'run', 'every', 'day.'], 'ar': ['أركض', 'كل', 'يوم.'] },
    { 'en': ['All', 'our', 'attempts', 'failed.'], 'ar': ['باءت', 'كل', 'محاولاتنا', 'بالفشل.'] },
    { 'en': ['We', 'admire', 'her', 'for', 'her', 'bravery.'], 'ar': ['نحب', 'شجاعتها.'] },
    { 'en': ['He', 'disappeared', 'without', 'a', 'trace.'], 'ar': ['اختفى', 'فلم', 'يُرَ', 'له', 'أثر.'] },
    { 'en': ['Do', 'you', 'know', 'how', 'to', 'use', 'a', 'computer?'], 'ar': ['هل', 'تعرف', 'كيف', 'تستعمل', 'الحاسوب؟'] },
    { 'en': ['I', 'see', 'what', 'you', 'mean.'], 'ar': ['أفهم', 'قصدك.'] },
    { 'en': ['How', 'old', 'do', 'you', 'think', 'she', 'is?'], 'ar': ['ما', 'عمرها', 'برأيك؟'] },
    { 'en': ['I', "haven't", 'paid', 'for', 'the', 'tickets', 'yet.'], 'ar': ['لم', 'أدفع', 'ثمن', 'التذاكر', 'بعد.'] },
    { 'en': ['Everybody', 'likes', 'her.'], 'ar': ['الجميع', 'يحبها.'] },
    { 'en': ['The', 'air', 'conditioner', "doesn't", 'work.'], 'ar': ['المكيّف', 'لا', 'يعمل.'] },
    { 'en': ['You', 'must', 'go.'], 'ar': ['يتوجّب', 'عليك', 'الذهاب.'] },
    { 'en': ['You', 'may', 'go', 'anywhere.'], 'ar': ['بإمكانك', 'الذهاب', 'إلى', 'أيما', 'مكان', 'شئت.'] },
    { 'en': ["I'm", 'cutting', 'down', 'on', 'sweets.'], 'ar': ['سأخفف', 'من', 'أكل', 'الحلويات.'] },
    { 'en': ["You're", 'here.'], 'ar': ['أنت', 'هنا'] },
    { 'en': ['Was', 'the', 'book', 'interesting?'], 'ar': ['هل', 'كان', 'الكتاب', 'شيقاً؟'] },
    { 'en': ['The', 'storm', 'caused', 'a', 'lot', 'of', 'damage.'], 'ar': ['أحدثت', 'العاصفة', 'الكثير', 'من', 'الخسائر.'] },
    { 'en': ['Let', 'me', 'handle', 'this.'], 'ar': ['دعِ', 'الأمر', 'لي.'] },
    { 'en': ['That', 'is', 'your', 'book.'], 'ar': ['ذاك', 'كتابك.'] },
    { 'en': ['Where', 'are', 'you', 'going', 'to', 'eat', 'lunch?'], 'ar': ['أين', 'ستأكل', 'طعام', 'الغداء؟'] },
    { 'en': ['This', 'is', 'really', 'interesting.'], 'ar': ['هذا', 'حقا', 'مثير'] },
    { 'en': ['Do', 'you', 'play', 'soccer?'], 'ar': ['هل', 'تلعب', 'كرة', 'القدم؟'] },
    { 'en': ['To', 'make', 'matters', 'worse,', 'her', 'husband', 'died.'], 'ar': ['وما', 'زاد', 'الأمور', 'سوءًا،', 'أن', 'زوجها', 'توفي.'] },
    { 'en': ['I', "don't", 'like', 'swimming', 'in', 'pools.'], 'ar': ['أنا', 'لا', 'أحب', 'السباحة', 'في', 'برك', 'السباحة.'] },
    { 'en': ['Who', 'painted', 'these', 'pictures?'], 'ar': ['من', 'رسم', 'هذه', 'اللوحات؟'] },
    { 'en': ['The', 'car', 'is', 'not', 'illegally', 'parked.'], 'ar': ['هذه', 'السّيّارة', 'ليست', 'مركونة', 'بشكل', 'غير', 'قانوني.'] },
    { 'en': ['Tell', 'me', 'what', 'I', 'can', 'do', 'to', 'help.'], 'ar': ['قل', 'لي', 'كيف', 'أساعدك.'] },
    { 'en': ['Hold', 'on', 'a', 'moment,', 'please.'], 'ar': ['انتظر', 'لحظة', 'من', 'فضلك.'] },
    { 'en': ['I', 'have', 'lived', 'here', 'for', 'ten', 'years.'], 'ar': ['عشت', 'هنا', 'لعشر', 'سنين.'] },
    { 'en': ["Let's", 'take', 'a', 'short', 'break.'], 'ar': ['لنأخذ', 'استراحة', 'قصيرة.'] },
    { 'en': ["I'm", 'sure', 'Tom', 'will', 'tell', 'us', 'the', 'truth.'], 'ar': ['متأكد', 'بأن', 'توم', 'سيخبرنا', 'بالحقيقة.'] },
    { 'en': ['Tom', 'got', 'into', 'trouble', 'because', 'of', 'Mary.'], 'ar': ['وقع', 'توم', 'في', 'ورطة', 'بسبب', 'ماري.'] },
    { 'en': ['What', 'should', 'I', 'do?'], 'ar': ['ماذا', 'عليّ', 'أن', 'أفعل؟'] },
    { 'en': ['Why', 'are', 'you', 'here?'], 'ar': ['لماذا', 'أنت', 'هنا؟'] },
    { 'en': ['Where', 'are', 'you', 'off', 'to?'], 'ar': ['إلى', 'أين', 'أنتم', 'ذاهبون؟'] },
    { 'en': ['I', 'work', 'for', 'an', 'oil', 'company.'], 'ar': ['أعمل', 'لصالح', 'شركة', 'نفط.'] },
    { 'en': ['I', 'remember', 'many', 'things.'], 'ar': ['أتذكر', 'أشياء', 'كثيرة.'] },
    { 'en': ["Let's", 'get', 'a', 'cab.'], 'ar': ['دعونا', 'نحصل', 'على', 'سيارة', 'أجرة.'] },
    { 'en': ['Defend', 'yourself.'], 'ar': ['دافِع', 'عن', 'نَفسَك.'] },
    { 'en': ['Do', 'you', 'mind', 'if', 'I', 'smoke', 'here?'], 'ar': ['هل', 'تمانع', 'أن', 'أدخن', 'هنا؟'] },
    { 'en': ['I', "don't", 'know', 'anything.'], 'ar': ['لا', 'أعرف', 'شيئاً.'] },
    { 'en': ['There', 'are', 'a', 'lot', 'of', 'deer', 'in', 'Nara.'], 'ar': ['هناك', 'الكثير', 'من', 'الغزلان', 'في', 'نارا.'] },
    { 'en': ['This', 'apple', 'tastes', 'sour.'], 'ar': ['هذه', 'التفاحة', 'طعمها', 'حامض.'] },
    { 'en': ['You', "don't", 'have', 'to', 'study.'], 'ar': ['ليس', 'عليك', 'أن', 'تدرس.'] },
    { 'en': ["Don't", 'play', 'baseball', 'here.'], 'ar': ['لا', 'تلعب', 'كرة', 'القاعدة', 'هنا.'] },
    { 'en': ['I', 'love', 'comic', 'books.'], 'ar': ['أحب', 'القصص', 'المصورة.'] },
    { 'en': ['Many', 'trees', 'fell', 'down.'], 'ar': ['سقطت', 'الكثير', 'من', 'الأشجار.'] },
    { 'en': ['She', 'was', 'promoted.'], 'ar': ['تم', 'ترقيتها.'] },
    { 'en': ['Thank', 'you', 'for', 'your', 'kindness.'], 'ar': ['شكراً', 'لكِ', 'على', 'لطفكِ.'] },
    { 'en': ['He', 'was', 'covered', 'with', 'mud', 'from', 'head', 'to', 'foot.'], 'ar': ['غطاه', 'الوحل', 'من', 'رأسه', 'حتى', 'قدميه.'] },
    { 'en': ['Most', 'people', 'think', "I'm", 'crazy.'], 'ar': ['يعتقد', 'الكثيرون', 'أني', 'مجنون.'] },
    { 'en': ['I', 'listen', 'to', 'the', 'radio', 'every', 'night.'], 'ar': ['أستمع', 'إلى', 'المذياع', 'كل', 'ليلة.'] },
    { 'en': ['Do', 'you', 'want', 'me', 'to', 'give', 'you', 'a', 'hand?'], 'ar': ['أتود', 'أن', 'أساعدك؟'] },
    { 'en': ['You', 'have', 'a', 'point', 'there.'], 'ar': ['معك', 'حق', 'في', 'تلك', 'النقطة.'] },
    { 'en': ["What's", 'Tom', 'doing', 'now?'], 'ar': ['ماذا', 'يفعل', 'الآن', 'توم؟'] },
    { 'en': ['Tom', 'is', 'my', 'dad.'], 'ar': ['توم', 'هو', 'أبي'] },
    { 'en': ['May', 'I', 'ask', 'a', 'couple', 'of', 'questions?'], 'ar': ['هل', 'يمكنني', 'أن', 'أطرح', 'بعض', 'الأسئلة؟'] },
    { 'en': ["What's", 'going', 'on?'], 'ar': ['ما', 'الذي', 'يحدث؟'] },
    { 'en': ["You're", 'always', 'anticipating', 'trouble.'], 'ar': ['دائماً', 'ما', 'تتوقع', 'حدوث', 'المشاكل.'] },
    { 'en': ['I', 'want', 'vengeance.'], 'ar': ['أُريد', 'الإنتقام.'] },
    { 'en': ['Take', 'these', 'pills.'], 'ar': ['خذ', 'هذه', 'الأقراص.'] },
    { 'en': ['She', 'was', 'about', 'to', 'go', 'out.'], 'ar': ['كانت', 'على', 'وشك', 'أن', 'تخرج.'] },
    { 'en': ["I'm", 'reading', 'now.'], 'ar': ['أنا', 'أقرأ', 'الآن'] },
    { 'en': ['I', 'want', 'to', 'be', 'an', 'astronaut.'], 'ar': ['أُريد', 'أن', 'أكون', 'رائد', 'فضاء.'] },
    { 'en': ['Do', 'you', 'speak', 'Swedish?'], 'ar': ['هل', 'تتحدث', 'السويدية؟'] },
    { 'en': ['You', 'look', 'happy.'], 'ar': ['تبدو', 'سعيداً'] },
    { 'en': ['I', "can't", 'do', 'it.'], 'ar': ['لا', 'يمكنني', 'فعل', 'ذلك.'] },
    { 'en': ['I', "don't", 'know', 'what', 'it', 'means.'], 'ar': ['لا', 'أدري', 'ماذا', 'تعني.'] },
    { 'en': ['Come', 'tomorrow.'], 'ar': ['تعالَ', 'غداً.'] },
    { 'en': ['You', 'may', 'choose', 'whichever', 'you', 'want.'], 'ar': ['يمكنك', 'اختيار', 'أيا', 'كان', 'ما', 'تحب.'] },
    { 'en': ['You', 'put', 'in', 'too', 'much', 'pepper.'], 'ar': ['لقد', 'أضفت', 'فلفلاً', 'أكثر', 'من', 'اللازم.'] },
    { 'en': ['I', 'lost', 'my', 'watch,', 'so', 'I', 'have', 'to', 'buy', 'one.'], 'ar': ['أضعت', 'ساعتي', 'فعليّ', 'الآن', 'أن', 'أشتري', 'واحدة', 'أخرى.'] },
    { 'en': ['He', 'pretended', 'to', 'be', 'a', 'doctor.'], 'ar': ['تظاهر', 'بأنه', 'طبيب.'] },
    { 'en': ["Let's", 'play', 'tennis', 'after', 'school.'], 'ar': ['لنلعب', 'التنس', 'بعد', 'انتهاء', 'الدراسة.'] },
    { 'en': ["That's", 'extraordinary.'], 'ar': ['ذلك', 'خارق', 'للعادة.'] },
    { 'en': ["Let's", 'share', 'the', 'expenses.'], 'ar': ['دعينا', 'نتقاسم', 'النفقات.'] },
    { 'en': ['I', "don't", 'even', 'know', 'what', 'that', 'is.'], 'ar': ['أنا', 'حتى', 'لا', 'أعرف', 'ما', 'ذلك.'] },
    { 'en': ['She', 'likes', 'music', 'better', 'than', 'anything', 'else.'], 'ar': ['تحب', 'الموسيقى', 'أكثر', 'من', 'أي', 'شيء', 'آخر.'] },
    { 'en': ['We', 'were', 'late', 'because', 'of', 'the', 'storm.'], 'ar': ['تأخرنا', 'بسبب', 'العاصفة.'] },
    { 'en': ['Do', 'you', 'speak', 'Chinese?'], 'ar': ['هل', 'تتحدث', 'الصينية؟'] },
    { 'en': ['She', 'rarely', 'goes', 'out', 'on', 'Sundays.'], 'ar': ['نادراً', 'ما', 'تخرج', 'أيام', 'الأحد.'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['الجو', 'حار', 'جدا'] },
    { 'en': ['I', 'work', 'for', 'a', 'shipping', 'company.'], 'ar': ['أعمل', 'لدى', 'شركة', 'شحن.'] },
    { 'en': ['Achilles', 'was', 'an', 'ancient', 'Greek', 'hero.'], 'ar': ['أكيليز', 'بطل', 'أسطوري', 'عند', 'الإغريق.'] },
    { 'en': ['Do', 'you', 'know', 'me?'], 'ar': ['هل', 'تعرفني؟'] },
    { 'en': ['Visit', 'us', 'tomorrow.'], 'ar': ['قوموا', 'بزيارتنا', 'غداً.'] },
    { 'en': ['Tom', 'lived', 'with', 'us', 'for', 'three', 'months.'], 'ar': ['توم', 'عاش', 'معنا', 'لثلاثة', 'اشهر.'] },
    { 'en': ['I', "didn't", 'hear', 'what', 'you', 'said.'], 'ar': ['لم', 'أسمع', 'ما', 'قلته.'] },
    { 'en': ["Where's", 'the', 'nearest', 'art', 'gallery?'], 'ar': ['أين', 'أقرب', 'معرض', 'فني؟'] },
    { 'en': ['I', 'tell', 'you', 'the', 'truth.'], 'ar': ['أخبرك', 'الحقيقة.'] },
    { 'en': ['He', 'is', 'capable', 'of', 'teaching', 'French.'], 'ar': ['هو', 'قادر', 'على', 'تدريس', 'اللغة', 'الفرنسية.'] },
    { 'en': ['Are', 'you', 'in', 'a', 'hurry?'], 'ar': ['هل', 'أنت', 'مستعجل؟'] },
    { 'en': ['Our', 'water', 'pipes', 'burst.'], 'ar': ['انفجرت', 'أنابيب', 'المياه', 'عندنا.'] },
    { 'en': ['Will', 'it', 'rain', 'today?'], 'ar': ['أستمطر', 'اليوم؟'] },
    { 'en': ['You', 'are', 'free', 'to', 'go', 'out.'], 'ar': ['يمكنك', 'الخروج.'] },
    { 'en': ['Take', 'off', 'your', 'socks,', 'please.'], 'ar': ['إخلع', 'شرابك', 'من', 'فضلك.'] },
    { 'en': ['What', 'made', 'her', 'do', 'such', 'a', 'thing?'], 'ar': ['ما', 'الذي', 'حملها', 'على', 'فعل', 'شيء', 'كهذا؟'] },
    { 'en': ['How', 'tall', 'are', 'you?'], 'ar': ['كم', 'طولك؟'] },
    { 'en': ['He', 'is', 'in', 'the', 'tenth', 'grade.'], 'ar': ['هو', 'في', 'الصف', 'العاشر.'] },
    { 'en': ['Wonderful!'], 'ar': ['مذهل.'] },
    { 'en': ['She', 'is', 'getting', 'prettier', 'and', 'prettier.'], 'ar': ['تزداد', 'جمالاً', 'يوماً', 'بعد', 'يوم.'] },
    { 'en': ["Can't", 'you', 'feel', 'it?'], 'ar': ['ألا', 'تستطيع', 'الشعور', 'به', '؟'] },
    { 'en': ['When', 'do', 'I', 'get', 'my', 'turn?'], 'ar': ['متى', 'يحين', 'دوري؟'] },
    { 'en': ['The', 'new', 'plan', 'worked', 'well.'], 'ar': ['الخطة', 'الجديدة', 'نجحت.'] },
    { 'en': ['She', 'asked', 'me', 'where', 'I', 'was', 'going.'], 'ar': ['سألتني', 'إلى', 'أين', 'سأذهب.'] },
    { 'en': ['He', 'looks', 'young.'], 'ar': ['يبدو', 'شاباً.'] },
    { 'en': ['Which', 'goes', 'faster,', 'a', 'ship', 'or', 'a', 'train?'], 'ar': ['أيهما', 'أسرع،', 'السفينة', 'أم', 'القطار؟'] },
    { 'en': ['What', 'do', 'you', 'mean?'], 'ar': ['ماذا', 'تعني؟'] },
    { 'en': ['The', 'old', 'man', 'died', 'from', 'hunger.'], 'ar': ['مات', 'الرجل', 'العجوز', 'من', 'شدة', 'الجوع.'] },
    { 'en': ["I've", 'got', 'no', 'choice.'], 'ar': ['لم', 'أحصل', 'على', 'خيار.'] },
    { 'en': ['Where', 'does', 'it', 'hurt?'], 'ar': ['أين', 'تحس', 'بالألم؟'] },
    { 'en': ['What', 'time', 'will', 'you', 'leave?'], 'ar': ['في', 'أي', 'وقت', 'ستذهب؟'] },
    { 'en': ['I', 'would', 'rather', 'starve', 'than', 'steal.'], 'ar': ['أفضّل', 'أن', 'أتضوّر', 'جوعاّ', 'على', 'أن', 'أقوم', 'بالسرقة.'] },
    { 'en': ['Darwin', 'changed', 'the', 'way', 'we', 'see', 'the', 'world.'], 'ar': ['لقد', 'غيرَ', 'داروين', 'الطريقة', 'التي', 'نري', 'بها', 'العالم.'] },
    { 'en': ['What', 'a', 'relief!'], 'ar': ['يا', 'لها', 'من', 'راحة'] },
    { 'en': ['How', 'did', 'you', 'get', 'to', 'know', 'her?'], 'ar': ['كيف', 'تعرفت', 'عليها؟'] },
    { 'en': ['I', "didn't", 'study', 'at', 'all', 'yesterday.'], 'ar': ['لم', 'أدرس', 'على', 'الإطلاق', 'بالأمس.'] },
    { 'en': ['She', 'thought', 'that', 'I', 'was', 'a', 'doctor.'], 'ar': ['ظنت', 'أني', 'طبيب.'] },
    { 'en': ["Don't", 'look', 'at', 'me!'], 'ar': ['لا', 'تنظر', 'إلي!'] },
    { 'en': ['I', 'wonder', 'where', 'Tom', 'is', 'and', 'who', "he's", 'with.'], 'ar': ['أنا', 'أتساءل', 'أين', 'توم', 'ومع', 'من.'] },
    { 'en': ["Can't", 'you', 'put', 'it', 'off', 'until', 'tomorrow?'], 'ar': ['ألا', 'يمكنك', 'أن', 'تأجلها', 'إلى', 'الغد؟'] },
    { 'en': ['The', 'meat', 'has', 'begun', 'to', 'rot.'], 'ar': ['اللحم', 'بدأ', 'يتعفن.'] },
    { 'en': ['She', 'slapped', 'his', 'face.'], 'ar': ['صفعته', 'كفاً.'] },
    { 'en': ['This', 'is', 'my', 'house.'], 'ar': ['هذا', 'بيتي.'] },
    { 'en': ['Pass', 'me', 'the', 'butter,', 'please.'], 'ar': ['مرّر', 'إليّ', 'الزبدة', 'إذا', 'سمحت.'] },
    { 'en': ['Do', 'not', 'accept', 'the', 'world', 'as', 'it', 'is.'], 'ar': ['لا', 'تقبل', 'بالعالم', 'كما', 'هو.'] },
    { 'en': ['It', 'was', 'really', 'cheap.'], 'ar': ['كان', 'رخيصاً', 'جداً.'] },
    { 'en': ['Take', 'what', 'you', 'need.'], 'ar': ['خذ', 'ما', 'تحتاجهُ.'] },
    { 'en': ['What', 'do', 'you', 'think', 'now?'], 'ar': ['ماذا', 'تعتقد', 'الآن؟'] },
    { 'en': ['That', 'factory', 'makes', 'toys.'], 'ar': ['ذلك', 'المصنع', 'ينتج', 'الألعاب.'] },
    { 'en': ['Because', "he's", 'sick,', 'he', "can't", 'come.'], 'ar': ['لن', 'يكون', 'بوسعه', 'القدوم', 'بسبب', 'مرضه.'] },
    { 'en': ['Wait', 'a', 'minute,', 'Tom!'], 'ar': ['إنتظر', 'دقيقة,', 'توم!'] },
    { 'en': ['She', 'came', 'to', 'Japan', 'as', 'a', 'child.'], 'ar': ['جاءت', 'اإلى', 'اليابان', 'عندما', 'كانت', 'صغيرة.'] },
    { 'en': ['You', 'just', 'have', 'to', 'promise', 'me', 'one', 'thing.'], 'ar': ['أنتً', 'عليكَ', 'فقط', 'أن', 'تعدُني', 'بشيء', 'واحد.'] },
    { 'en': ['Thanks', 'to', 'your', 'help,', 'I', 'could', 'succeed.'], 'ar': ['استطعت', 'أن', 'أنجح', 'بفضلِ', 'مساعدتكَ.'] },
    { 'en': ['Each', 'country', 'has', 'its', 'own', 'customs.'], 'ar': ['كل', 'بلد', 'و', 'له', 'عاداته', 'و', 'تقاليده.'] },
    { 'en': ['Please', 'come.'], 'ar': ['من', 'فضلك', 'تعال.'] },
    { 'en': ["Don't", 'worry.', "I'll", 'take', 'care', 'of', 'it.'], 'ar': ['لا', 'تقلق.', 'أنا', 'سوف', 'أعتني', 'بها.'] },
    { 'en': ['Do', 'you', 'have', 'one?'], 'ar': ['هل', 'لديك', 'واحدة؟'] },
    { 'en': ["That's", 'what', 'makes', 'me', 'happy.'], 'ar': ['هذا', 'ما', 'يشعرني', 'بالسعادة.'] },
    { 'en': ['I', 'have', 'heard', 'the', 'story.'], 'ar': ['سمعت', 'تلك', 'القصة', 'من', 'قبل.'] },
    { 'en': ['I', 'disagree', 'with', 'you.'], 'ar': ['أختلف', 'معك.'] },
    { 'en': ['It', 'sounds', 'like', "they're", 'not', 'going', 'to', 'leave.'], 'ar': ['يبدو', 'كأنهم', 'لن', 'يغادروا.'] },
    { 'en': ['I', 'will', 'miss', 'you.'], 'ar': ['سأشتاق', 'إليك.'] },
    { 'en': ['Put', 'the', 'book', 'back', 'where', 'it', 'was.'], 'ar': ['أعد', 'الكتاب', 'إلى', 'مكانه.'] },
    { 'en': ['I', 'love', 'French.'], 'ar': ['أنا', 'أحب', 'الفرنسية'] },
    { 'en': ['We', 'have', 'to', 'do', 'the', 'shopping.'], 'ar': ['علينا', 'التبضع.'] },
    { 'en': ['Chew', 'your', 'food', 'well.'], 'ar': ['اِمضغ', 'طعامك', 'جيداً.'] },
    { 'en': ['Can', 'you', 'swim', 'as', 'fast', 'as', 'he', 'can?'], 'ar': ['أيمكنك', 'أن', 'تجاري', 'سرعته', 'في', 'السباحة؟'] },
    { 'en': ['What', 'led', 'you', 'to', 'believe', 'so?'], 'ar': ['ما', 'الذي', 'جعلك', 'تعتقد', 'ذلك؟'] },
    { 'en': ['All', 'you', 'can', 'do', 'is', 'wait.'], 'ar': ['كل', 'ما', 'يمكنك', 'فعله', 'هو', 'الانتظار.'] },
    { 'en': ['I', 'got', 'wet', 'to', 'the', 'skin.'], 'ar': ['تبللت', 'حتى', 'بلغ', 'البلل', 'بدني.'] },
    { 'en': ['Maybe', 'you', 'can', 'tell', 'me', 'where', 'I', 'can', 'find', 'Tom.'], 'ar': ['ربّما', 'تخبرينني', 'عن', 'مكانٍ', 'أجد', 'فيه', 'توم.'] },
    { 'en': ['I', "don't", 'blame', 'you.'], 'ar': ['أنا', 'لا', 'أضع', 'اللوم', 'عليك.'] },
    { 'en': ['You', 'can', 'rely', 'upon', 'him.'], 'ar': ['يمكنك', 'الإعتماد', 'عليه.'] },
    { 'en': ['Go', 'away.'], 'ar': ['اذهب', 'بعيداً.'] },
    { 'en': ['Come', 'in.'], 'ar': ['تعال', 'إلى', 'الداخل'] },
    { 'en': ['You', 'could', 'come', 'and', 'live', 'with', 'me.'], 'ar': ['يمكنك', 'المجيء', 'والعيش', 'معي.'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'sleep.'], 'ar': ['عليّ', 'أن', 'أنام.'] },
    { 'en': ['I', 'need', 'more', 'time', 'to', 'finish', 'my', 'homework.'], 'ar': ['أحتاج', 'لمزيد', 'من', 'الوقت', 'لأنهي', 'واجبي', 'المدرسي.'] },
    { 'en': ['They', 'let', 'me', 'go.'], 'ar': ['هُم', 'تركوني', 'أذهب.'] },
    { 'en': ['You', 'disappointed', 'me.'], 'ar': ['خيبت', 'أملي.'] },
    { 'en': ['Nothing', 'has', 'been', 'decided', 'yet.'], 'ar': ['لم', 'يتقرر', 'شئ', 'بعد.'] },
    { 'en': ["Let's", 'get', 'some', 'sleep.'], 'ar': ['دعينا', 'نأخذ', 'قسطاً', 'من', 'الراحة.'] },
    { 'en': ['He', 'was', 'poor', 'and', "couldn't", 'buy', 'a', 'coat.'], 'ar': ['كان', 'فقيراً', 'ولم', 'يستطع', 'شراء', 'المعطف.'] },
    { 'en': ['The', 'doctor', 'advised', 'that', 'she', 'take', 'a', 'holiday.'], 'ar': ['نصحها', 'الطبيب', 'بأخذ', 'إجازة.'] },
    { 'en': ['We', 'left', 'by', 'train.'], 'ar': ['غادرنا', 'بالقطار.'] },
    { 'en': ["She's", 'my', 'sister.'], 'ar': ['إنها', 'أختي.'] },
    { 'en': ['Bring', 'food.'], 'ar': ['أحضر', 'الطعام.'] },
    { 'en': ['Norway', 'is', 'the', 'richest', 'country', 'in', 'the', 'world.'], 'ar': ['النرويج', 'هي', 'أغنى', 'دولةٍ', 'في', 'العالم.'] },
    { 'en': ['I', "can't", 'wait', 'to', 'meet', 'you.'], 'ar': ['لا', 'استطيع', 'الانتظار', 'لمقابلتك.'] },
    { 'en': ["I'm", 'not', 'going.'], 'ar': ['أنا', 'لستُ', 'ذاهباً.'] },
    { 'en': ['He', 'was', 'laid', 'off.'], 'ar': ['تم', 'طرده'] },
    { 'en': ['Tom', 'knows', 'the', 'city', 'very', 'well.'], 'ar': ['يعرف', 'توم', 'المدينة', 'جيدا'] },
    { 'en': ['English', 'is', 'difficult,', "isn't", 'it?'], 'ar': ['أليست', 'اللغة', 'الإنجليزية', 'صعبة؟'] },
    { 'en': ['I', 'think', "you're", 'right.'], 'ar': ['أظن', 'أنك', 'محق.'] },
    { 'en': ['I', "don't", 'want', 'to', 'lose', 'you.'], 'ar': ['لا', 'أريد', 'خسارتكَ.'] },
    { 'en': ['What', 'did', 'you', 'do?'], 'ar': ['ماذا', 'فعلتَ؟'] },
    { 'en': ['We', 'enjoyed', 'playing', 'baseball.'], 'ar': ['تسلينا', 'بلعب', 'كرة', 'القاعدة.'] },
    { 'en': ['Somebody', 'has', 'stolen', 'my', 'suitcase.'], 'ar': ['أحدهم', 'سرق', 'حقيبتي.'] },
    { 'en': ['She', 'spoke', 'to', 'me', 'in', 'Spanish.'], 'ar': ['كلمتني', 'بالإسبانية.'] },
    { 'en': ['Do', 'you', 'travel', 'a', 'lot?'], 'ar': ['هل', 'تسافر', 'كثيراً؟'] },
    { 'en': ['Your', 'chair', 'is', 'identical', 'to', 'mine.'], 'ar': ['كرسيّك', 'مثل', 'كرسيي', 'تمامًا.'] },
    { 'en': ['You', 'must', 'follow', 'school', 'rules.'], 'ar': ['يجب', 'أن', 'تحترم', 'قوانين', 'المدرسة.'] },
    { 'en': ['Those', 'are', 'our', 'books.'], 'ar': ['تلك', 'كتبنا.'] },
    { 'en': ['The', 'train', 'leaves', 'at', 'nine', "o'clock."], 'ar': ['يغادر', 'القطار', 'في', 'الساعة', 'التاسعة.'] },
    { 'en': ['Tom', 'likes', 'cheeseburgers.'], 'ar': ['توم', 'يحب', 'التشيزبرقر.'] },
    { 'en': ['You', "aren't", 'responsible', 'for', 'that', 'mess.'], 'ar': ['أنتَ', 'لستُ', 'مسؤؤلاً', 'عن', 'تلك', 'الفوضى.'] },
    { 'en': ['Practice', 'makes', 'perfect.'], 'ar': ['الممارسة', 'تؤدي', 'إلى', 'الإتقان.'] },
    { 'en': ['I', 'did', 'it', 'myself.'], 'ar': ['فعلت', 'هذا', 'بنفسي.'] },
    { 'en': ['You', 'should', 'have', 'accepted', 'his', 'advice.'], 'ar': ['كان', 'عليك', 'قبول', 'نصيحته.'] },
    { 'en': ['He', 'heard', 'a', 'shout.'], 'ar': ['سمع', 'صراخاً.'] },
    { 'en': ['It', 'may', 'rain', 'tonight.'], 'ar': ['ربما', 'ستمطر', 'الليلة.'] },
    { 'en': ['There', 'are', 'some', 'boys', 'in', 'the', 'park.'], 'ar': ['هناك', 'بعض', 'الأولاد', 'في', 'المنتزه.'] },
    { 'en': ['Do', 'you', 'really', 'love', 'me?'], 'ar': ['أتحبينني', 'بحق؟'] },
    { 'en': ["I've", 'spoken', 'to', 'your', 'teachers.'], 'ar': ['لقد', 'تحدّثت', 'مع', 'مدرّسيك.'] },
    { 'en': ["What's", 'the', 'purpose', 'of', 'your', 'trip?'], 'ar': ['ما', 'الغرض', 'من', 'رحلتك؟'] },
    { 'en': ['Do', 'you', 'like', 'apples?'], 'ar': ['هل', 'تحب', 'التفاح؟'] },
    { 'en': ['The', 'enemy', 'attacked', 'us', 'at', 'night.'], 'ar': ['هاجمنا', 'العدو', 'ليلاً.'] },
    { 'en': ['Driving', 'too', 'fast', 'is', 'dangerous.'], 'ar': ['القيادة', 'بسرعات', 'عالية', 'خطر.'] },
    { 'en': ['He', 'wants', 'you', 'to', 'stay', 'here.'], 'ar': ['إنه', 'يريدك', 'أن', 'تبقى', 'هنا.'] },
    { 'en': ['There', 'has', 'been', 'an', 'accident', 'at', 'the', 'worksite.'], 'ar': ['لقد', 'حدث', 'حادث', 'في', 'ورشة', 'البناء.'] },
    { 'en': ['It', 'might', 'rain', 'tomorrow.'], 'ar': ['لربما', 'تمطر', 'غداً.'] },
    { 'en': ['Raise', 'your', 'hand.'], 'ar': ['ارفع', 'يديك.'] },
    { 'en': ["I'll", 'eat', 'it', 'here.'], 'ar': ['سآكل', 'هنا.'] },
    { 'en': ['Do', 'you', 'really', 'want', 'to', 'talk', 'about', 'that', 'now?'], 'ar': ['هل', 'أنتِ', 'حقاً', 'تريدين', 'التحدث', 'عن', 'هذا', 'الأن؟'] },
    { 'en': ['What', 'did', 'you', 'do', 'with', 'those', 'books?'], 'ar': ['ماذا', 'فعلت', 'بتلك', 'الكتب؟'] },
    { 'en': ['Please', 'tell', 'me', 'about', 'your', 'trip.'], 'ar': ['احكي', 'لي', 'عن', 'رحلة', 'سفرك', 'من', 'فضلك.'] },
    { 'en': ['I', 'think', 'about', 'you', 'all', 'day', 'long.'], 'ar': ['أنا', 'أفكر', 'فيك', 'طوال', 'اليوم.'] },
    { 'en': ["You're", 'wanted', 'on', 'the', 'phone.'], 'ar': ['أحدهم', 'على', 'الهاتف', 'يريدك.'] },
    { 'en': ['I', 'saw', 'him', 'tear', 'up', 'the', 'letter.'], 'ar': ['رأيته', 'يمزّق', 'الرسالة.'] },
    { 'en': ['This', 'book', 'contains', 'many', 'pictures.'], 'ar': ['يحتوي', 'هذا', 'الكتاب', 'على', 'صور', 'كثيرة.'] },
    { 'en': ['How', 'long', 'did', 'you', 'wait', 'for', 'me?'], 'ar': ['كم', 'من', 'الوقت', 'أنتظرتني؟'] },
    { 'en': ['Both', 'of', 'them', 'are', 'in', 'the', 'room.'], 'ar': ['كِلاً', 'من', 'هما', 'في', 'الغرفة.'] },
    { 'en': ['Whose', 'books', 'are', 'these?'], 'ar': ['لمن', 'هذه', 'الكتب؟'] },
    { 'en': ['How', 'nice', 'of', 'you!'], 'ar': ['كم', 'رائعة', 'أنتِ!'] },
    { 'en': ['Tom', 'likes', 'hot', 'curry.'], 'ar': ['توم', 'يحب', 'الكاري', 'الحار.'] },
    { 'en': ['He', 'was', 'arrested', 'for', 'drunken', 'driving.'], 'ar': ['اعتقل', 'لقيادته', 'و', 'هو', 'سكران.'] },
    { 'en': ['Tom', 'slept', 'at', 'the', 'office.'], 'ar': ['نام', 'توم', 'في', 'المكتب'] },
    { 'en': ['You', 'ought', 'to', 'thank', 'him.'], 'ar': ['يتوجب', 'عليك', 'أن', 'تشكره.'] },
    { 'en': ['He', 'stepped', 'on', 'my', 'foot', 'on', 'purpose.'], 'ar': ['تعمد', 'أن', 'يدوس', 'على', 'قدمي.'] },
    { 'en': ['The', 'shop', 'was', 'busy.'], 'ar': ['كان', 'المتجر', 'مكتظا.'] },
    { 'en': ['The', 'house', 'requires', 'repairs.'], 'ar': ['يحتاج', 'المنزل', 'إلى', 'إصلاحات.'] },
    { 'en': ['He', 'played', 'tennis', 'yesterday.'], 'ar': ['لعب', 'التنس', 'البارحة.'] },
    { 'en': ['I', "don't", 'know', 'how', 'to', 'express', 'my', 'thanks.'], 'ar': ['لا', 'أعرف', 'كيف', 'أشكرك.'] },
    { 'en': ['I', 'met', 'an', 'old', 'man', 'near', 'the', 'station.'], 'ar': ['قابلت', 'رجلاً', 'عجوزاً', 'قرب', 'المحطة.'] },
    { 'en': ['Tom', 'always', 'has', 'a', 'smile', 'on', 'his', 'face.'], 'ar': ['الابتسامة', 'لا', 'تفارق', 'وجه', 'توم.'] },
    { 'en': ['My', 'mother', 'left', 'me', 'a', 'message.'], 'ar': ['تركت', 'لي', 'أمي', 'رسالة.'] },
    { 'en': ['The', 'converse', 'is', 'also', 'true.'], 'ar': ['و', 'العكس', 'صحيح.'] },
    { 'en': ['She', 'left', 'with', 'her', 'friends.'], 'ar': ['خرجت', 'مع', 'أصدقائها.'] },
    { 'en': ["I've", 'got', 'a', 'new', 'bike.'], 'ar': ['اِشتريت', 'دراجة', 'جديدة.'] },
    { 'en': ['She', 'can', 'play', 'the', 'piano.'], 'ar': ['بإمكانها', 'العزف', 'على', 'البيانو.'] },
    { 'en': ['I', "didn't", 'see', 'the', 'need', 'for', 'it.'], 'ar': ['لا', 'أرى', 'لذلك', 'حاجة.'] },
    { 'en': ['I', 'found', 'him', 'a', 'job.'], 'ar': ['وجدت', 'له', 'عملا.'] },
    { 'en': ['Tom', 'and', 'I', 'are', 'dependent', 'on', 'each', 'other.'], 'ar': ['أنا', 'وتوم', 'نعتمد', 'على', 'بعضنا.'] },
    { 'en': ['I', 'work', 'every', 'day', 'except', 'Sunday.'], 'ar': ['أعمل', 'كل', 'يوم', 'ما', 'عدا', 'يوم', 'الأحد.'] },
    { 'en': ['You', 'must', 'build', 'up', 'your', 'courage.'], 'ar': ['يجب', 'أن', 'تُنَمِّيَ', 'شجاعتك.'] },
    { 'en': ['She', "didn't", 'come', 'before', 'two.'], 'ar': ['لم', 'تأت', 'قبل', 'الثانية.'] },
    { 'en': ['He', 'took', 'his', 'sister', 'along.'], 'ar': ['إصطحب', 'أخته.'] },
    { 'en': ['She', 'painted', 'the', 'wall', 'red.'], 'ar': ['دهنت', 'الحائط', 'بالأحمر.'] },
    { 'en': ['I', 'am', 'cold.'], 'ar': ['أشعر', 'بالبرد.'] },
    { 'en': ['He', 'plays', 'very', 'well.'], 'ar': ['يلعب', 'بمهارة.'] },
    { 'en': ['Whenever', 'I', 'call,', 'he', 'is', 'out.'], 'ar': ['كلما', 'أتصل', 'به', '،', 'يكون', 'قد', 'خرج.'] },
    { 'en': ["He's", 'talking', 'on', 'the', 'telephone.'], 'ar': ['إنه', 'يتحدث', 'على', 'الهاتف.'] },
    { 'en': ['Help', 'me!'], 'ar': ['ساعدني!'] },
    { 'en': ['He', 'will', 'not', 'be', 'ready.'], 'ar': ['هو', 'لن', 'يكونَ', 'مُسْتَعدّاً.'] },
    { 'en': ['He', 'is', 'digging', 'his', 'own', 'grave.'], 'ar': ['إنه', 'يحفر', 'قبره', 'بنفسه.'] },
    { 'en': ['Can', 'I', 'ride', 'this', 'horse', 'for', 'a', 'while?'], 'ar': ['أيمكنني', 'ركوب', 'هذا', 'الحصان', 'قليلاً؟'] },
    { 'en': ['Tom', 'was', 'supposed', 'to', 'meet', 'me', 'here.'], 'ar': ['كان', 'مفترضا', 'أن', 'يقابلني', 'توم', 'هنا.'] },
    { 'en': ["I'm", 'broke.'], 'ar': ['لقد', 'كُسِرت'] },
    { 'en': ['I', 'have', 'one', 'sister.'], 'ar': ['لي', 'أخت', 'واحدة.'] },
    { 'en': ["I've", 'gone', 'to', 'Kyoto', 'twice.'], 'ar': ['ذهبت', 'إلى', 'كيوتو', 'مرتين.'] },
    { 'en': ['I', "didn't", 'know', 'how', 'to', 'answer', 'his', 'question.'], 'ar': ['لم', 'أعرف', 'كيف', 'أجيب', 'على', 'سؤاله.'] },
    { 'en': ['I', 'was', 'eighteen', 'then.'], 'ar': ['كان', 'عمري', 'ثماني', 'عشرة', 'سنة', 'حينها.'] },
    { 'en': ['I', "don't", 'consider', 'Tom', 'a', 'friend.'], 'ar': ['لا', 'أعتبر', 'توم', 'صديقا', 'لي.'] },
    { 'en': ['What', 'is', 'that', 'huge', 'building?'], 'ar': ['ما', 'ذاك', 'المبنى', 'الضخم؟'] },
    { 'en': ['My', 'father', 'will', 'not', 'be', 'busy', 'tomorrow.'], 'ar': ['لن', 'يكون', 'أبي', 'مشغولاً', 'غداً.'] },
    { 'en': ['Tom', 'has', 'never', 'gone', 'to', 'school.'], 'ar': ['توم', 'لم', 'يذهب', 'ابدا', 'الى', 'المدرسة'] },
    { 'en': ['It', 'hurts.'], 'ar': ['هذا', 'مؤلم'] },
    { 'en': ['I', 'want', 'to', 'know', "who's", 'coming', 'with', 'us.'], 'ar': ['أريد', 'أن', 'أعرف', 'من', 'سيأتي', 'معنا.'] },
    { 'en': ["That's", 'because', "you're", 'a', 'girl.'], 'ar': ['ذلك', 'لأنك', 'بنت.'] },
    { 'en': ['Do', 'what', 'Tom', 'says.'], 'ar': ['إفعلى', 'ما', 'يقوله', 'توم.'] },
    { 'en': ['Who', 'is', 'it?'], 'ar': ['مَن؟'] },
    { 'en': ['He', 'will', 'hire', 'someone', 'who', 'speaks', 'English.'], 'ar': ['سيوظف', 'شخصا', 'يتحدث', 'الإنجليزية.'] },
    { 'en': ['They', 'require', 'extra', 'help.'], 'ar': ['هم', 'يحتاجون', 'المزيد', 'من', 'المساعدة.'] },
    { 'en': ["Let's", 'turn', 'off', 'the', 'television.'], 'ar': ['فلنغلق', 'التلفاز', '.'] },
    { 'en': ['I', "don't", 'have', 'any', 'evidence.'], 'ar': ['لا', 'أملك', 'دليلا.'] },
    { 'en': ['Because', "he's", 'sick,', 'he', "can't", 'come.'], 'ar': ['لن', 'يستطيع', 'المجيء', 'لأنه', 'مريض.'] },
    { 'en': ['This', 'is', 'your', 'book.'], 'ar': ['ذاك', 'كتابك.'] },
    { 'en': ["It's", 'my', "brother's."], 'ar': ['إنها', 'لأخي.'] },
    { 'en': ['The', 'bullet', 'went', 'through', 'his', 'body.'], 'ar': ['إخترقت', 'الرصاصة', 'جسده.'] },
    { 'en': ["That's", 'not', 'fair.'], 'ar': ['هذا', 'ليس', 'عدلاً.'] },
    { 'en': ['The', 'game', 'ended', 'at', 'nine', "o'clock."], 'ar': ['انتهت', 'المباراة', 'الساعة', 'التاسعة.'] },
    { 'en': ['You', "shouldn't", 'have', 'made', 'me', 'beg.'], 'ar': ['لم', 'يكن', 'يجدر', 'بك', 'أن', 'تجعلني', 'أتوسل.'] },
    { 'en': ['When', 'did', 'you', 'begin', 'studying', 'English?'], 'ar': ['متى', 'بدأتَ', 'دراسة', 'اللغة', 'الانجليزية؟'] },
    { 'en': ['Tom', 'is', 'finally', 'getting', 'married.'], 'ar': ['سيتزوج', 'توم', 'أخيرا.'] },
    { 'en': ['Please', "don't", 'ask.'], 'ar': ['لا', 'تسأل', 'إذا', 'سمحت.'] },
    { 'en': ['Tell', 'her', 'not', 'to', 'get', 'near', 'the', 'dog.'], 'ar': ['قل', 'لها', 'ألّا', 'تقرب', 'الكلب.'] },
    { 'en': ['Peach', 'trees', 'require', 'lots', 'of', 'sunshine.'], 'ar': ['أشجار', 'الخوخ', 'تتطلب', 'الكثير', 'من', 'أشعة', 'الشمس.'] },
    { 'en': ['What', 'are', 'you', 'staring', 'at?'], 'ar': ['بماذا', 'تحدّق؟'] },
    { 'en': ['Mary', 'had', 'a', 'tiff', 'with', 'her', 'boyfriend.'], 'ar': ['تشاجرت', 'ماري', 'مع', 'حبيبها', '.'] },
    { 'en': ['May', 'I', 'ask', 'a', 'couple', 'of', 'questions?'], 'ar': ['هل', 'لي', 'ببعض', 'الأسئلة؟'] },
    { 'en': ['My', 'father', 'gave', 'a', 'nice', 'watch', 'to', 'me.'], 'ar': ['أعطاني', 'أبي', 'ساعةً', 'حلوة.'] },
    { 'en': ['Who', 'are', 'those', 'people?'], 'ar': ['من', 'هؤلاء', 'الأشخاص؟'] },
    { 'en': ['Driving', 'a', 'car', 'is', 'really', 'very', 'simple.'], 'ar': ['قيادة', 'السيارة', 'أمر', 'سهل', 'للغاية.'] },
    { 'en': ['He', 'did', 'exactly', 'what', 'I', 'told', 'him', 'to', 'do.'], 'ar': ['فعل', 'ما', 'طلبت', 'منه', 'تماماً.'] },
    { 'en': ["It's", 'a', 'quote', 'from', 'the', 'book.'], 'ar': ['إنهُ', 'أقتباس', 'من', 'الكتاب.'] },
    { 'en': ['Innocence', 'is', 'a', 'beautiful', 'thing.'], 'ar': ['البراءة', 'شيءٌ', 'جميل.'] },
    { 'en': ['Everyone', 'noticed.'], 'ar': ['تم', 'إعلام', 'الجميع.'] },
    { 'en': ['My', 'father', 'used', 'to', 'be', 'a', 'strong', 'man.'], 'ar': ['والدي', 'اعتاد', 'أن', 'يكون', 'رجلاً', 'قوياً.'] },
    { 'en': ['The', 'car', 'broke', 'down,', 'so', 'they', 'had', 'to', 'walk.'], 'ar': ['تعطلت', 'السيارة', 'فعليهم', 'أن', 'يمشوا.'] },
    { 'en': ["I'll", 'bring', 'one', 'more', 'towel.'], 'ar': ['سأحضر', 'فوطة', 'أخرى.'] },
    { 'en': ['Father', 'is', 'angry', 'with', 'me.'], 'ar': ['أبي', 'غاضب', 'معي.'] },
    { 'en': ['I', 'went', 'to', 'the', 'wrong', 'address.'], 'ar': ['ذهبت', 'إلى', 'العنوان', 'الخطأ.'] },
    { 'en': ["I'm", 'looking', 'for', 'a', 'book', 'about', 'medieval', 'Spain.'], 'ar': ['إني', 'أبحث', 'عن', 'كتاب', 'عن', 'اسبانيا', 'في', 'العصور', 'الوسطى.'] },
    { 'en': ['Happy', 'New', 'Year!'], 'ar': ['عام', 'جديد', 'سعيد!'] },
    { 'en': ['Tom', 'asked', 'Mary', 'if', 'she', 'wanted', 'to', 'dance.'], 'ar': ['سأل', '"توم"', '"ماري"', 'ما', 'إذا', 'كانت', 'تود', 'الرقص'] },
    { 'en': ["I'll", 'be', 'calling', 'you', 'every', 'morning.'], 'ar': ['سأتصل', 'بِكَ', 'كُل', 'صباح.'] },
    { 'en': ['I', "don't", 'know', 'what', 'happens', 'here.'], 'ar': ['أنا', 'لا', 'أعرف', 'ما', 'يجري', 'هنا.'] },
    { 'en': ['War', 'broke', 'out', 'in', '1941.'], 'ar': ['اِندلعت', 'الحرب', 'عام', '١٩٤١م.'] },
    { 'en': ['Why', 'is', 'it', 'that', "you're", 'always', 'late?'], 'ar': ['لماذا', 'تتأخر', 'دائما؟'] },
    { 'en': ['What', 'is', 'over', 'there?'], 'ar': ['ماذا', 'يوجد', 'هناك؟'] },
    { 'en': ['Why', 'did', 'you', 'do', 'that?'], 'ar': ['لماذا', 'فعلت', 'ذلك؟'] },
    { 'en': ['Are', 'you', 'by', 'yourself', 'or', 'with', 'someone?'], 'ar': ['هل', 'أنتَ', 'وحدك', 'أو', 'مع', 'أحد؟'] },
    { 'en': ['Do', 'you', 'like', 'apples?'], 'ar': ['هل', 'تحب', 'أكل', 'التفاح؟'] },
    { 'en': ['He', 'has', 'his', 'own', 'car.'], 'ar': ['لديه', 'سيارته', 'الخاصة.'] },
    { 'en': ['Even', 'Homer', 'sometimes', 'nods.'], 'ar': ['لكلّ', 'جواد', 'كبوة.'] },
    { 'en': ['Tom', "wouldn't", 'let', 'Mary', 'in.'], 'ar': ['توم', 'لم', 'يسمح', 'لميري', 'بالدخول.'] },
    { 'en': ['Do', 'you', 'have', 'a', "driver's", 'license?'], 'ar': ['هل', 'تملك', 'رخصة', 'قيادة؟'] },
    { 'en': ['May', 'I', 'ask', 'some', 'questions?'], 'ar': ['هل', 'لي', 'ببعض', 'الأسئلة؟'] },
    { 'en': ["I'm", 'coming.'], 'ar': ['أنا', 'آتٍ'] },
    { 'en': ['I', 'love', 'trips.'], 'ar': ['أحب', 'الرحلات.'] },
    { 'en': ['He', 'has', 'three', 'sons.'], 'ar': ['عنده', 'ثلاثة', 'أولاد.'] },
    { 'en': ["That's", 'an', 'order,', 'Tom.'], 'ar': ['هذا', 'أمر', 'يا', 'توم.'] },
    { 'en': ['I', 'meant', 'it.'], 'ar': ['قصدت', 'ذلك.'] },
    { 'en': ['She', 'is', 'absent', 'because', 'of', 'sickness.'], 'ar': ['كانت', 'غائبة', 'بسبب', 'المرض.'] },
    { 'en': ['The', 'hard', 'work', 'begins', 'now.'], 'ar': ['العمل', 'الصعب', 'يبدأ', 'الآن.'] },
    { 'en': ['He', 'will', 'also', 'go.'], 'ar': ['سيذهب', 'كذلك.'] },
    { 'en': ['It', 'appears', 'to', 'me', 'that', 'you', 'are', 'all', 'mistaken.'], 'ar': ['يبدو', 'لي', 'أن', 'كلكم', 'مخطئون.'] },
    { 'en': ['It', "wasn't", 'like', 'that.'], 'ar': ['لم', 'يكن', 'كذلك.'] },
    { 'en': ['Tom', 'tried', 'to', 'kill', 'me.'], 'ar': ['حاول', 'توم', 'قتلي.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'scissors?'], 'ar': ['أيمكنني', 'استعارة', 'مقصك؟'] },
    { 'en': ["We're", 'not', 'dating.'], 'ar': ['نحن', 'لا', 'نتواعد.'] },
    { 'en': ['All', 'of', 'my', 'roommates', 'are', 'out', 'of', 'town.'], 'ar': ['جميع', 'زملاء', 'صفّي', 'خارج', 'المدينة.'] },
    { 'en': ['The', 'play', 'begins', 'at', '2', 'p.m.'], 'ar': ['ستبدأ', 'المسرحية', 'الساعة', 'الثانية', 'مساءً.'] },
    { 'en': ["I'm", 'on', 'my', 'way', 'home.'], 'ar': ['أنا', 'في', 'طريقي', 'إلی', 'المنزل'] },
    { 'en': ['Take', 'a', 'look', 'at', 'this', 'report.'], 'ar': ['ألق', 'نظرةً', 'على', 'هذا', 'التقرير.'] },
    { 'en': ['She', 'sang', 'better', 'than', 'him.'], 'ar': ['غنَّتْ', 'أفضل', 'منه.'] },
    { 'en': ['When', 'did', 'you', 'get', 'up?'], 'ar': ['متى', 'استيقظت؟'] },
    { 'en': ['I', 'thought', 'you', 'said', 'you', "didn't", 'like', 'Tom.'], 'ar': ['ظننتك', 'قلت', 'أنك', 'لا', 'تحب', 'توم.'] },
    { 'en': ['The', 'play', 'is', 'at', 'eight', "o'clock."], 'ar': ['ستبدأ', 'المسرحية', 'في', 'الساعة', 'الثامنة.'] },
    { 'en': ['You', 'know', 'the', 'rest', 'of', 'the', 'story.'], 'ar': ['أنتَ', 'تعرف', 'بقية', 'القصة.'] },
    { 'en': ["Don't", 'open', 'the', 'door.'], 'ar': ['لا', 'تفتح', 'الباب.'] },
    { 'en': ['My', 'hobby', 'is', 'playing', 'the', 'guitar.'], 'ar': ['هوايتي', 'هي', 'العزف', 'على', 'الجيتار.'] },
    { 'en': ['I', 'caught', 'a', 'beautiful', 'butterfly.'], 'ar': ['أمسكت', 'فراشة', 'جميلة.'] },
    { 'en': ['"May', 'I', 'come', 'in?"', '"Yes,', 'certainly."'], 'ar': ['"هل', 'تأذنين', 'لي', 'بالدخول؟"', '"نعم،', 'بالطبع".'] },
    { 'en': ['Stop', 'the', 'car.'], 'ar': ['أوقف', 'السيارة'] },
    { 'en': ['I', 'said', "I'm", 'sorry.'], 'ar': ['لقد', 'قلت', 'أنا', 'آسف'] },
    { 'en': ['A', 'number', 'of', 'experts', 'attended', 'the', 'meeting.'], 'ar': ['حضر', 'اللقاء', 'عدد', 'من', 'أصحاب', 'الإختصاص.'] },
    { 'en': ['I', 'think', "you're", 'right.'], 'ar': ['أعتقد', 'أن', 'ما', 'تقوله', 'صحيح.'] },
    { 'en': ["There's", 'nothing', 'I', 'can', 'do.'], 'ar': ['ليس', 'هناك', 'ما', 'يمكنني', 'فعله.'] },
    { 'en': ['I', "won't", 'talk', 'to', 'him', 'anymore.'], 'ar': ['لن', 'أكلمه', 'من', 'الآن', 'فصاعداً.'] },
    { 'en': ['Take', 'a', 'look', 'at', 'that.'], 'ar': ['انظر', 'إلى', 'ذلك'] },
    { 'en': ['When', 'did', 'the', 'accident', 'happen?'], 'ar': ['متى', 'حصل', 'الحادث؟'] },
    { 'en': ["I'm", 'not', 'satisfied', 'with', 'what', "you've", 'done.'], 'ar': ['لست', 'راضيا', 'عما', 'فعلته.'] },
    { 'en': ['My', 'sister', 'is', 'shorter', 'than', 'you.'], 'ar': ['أختي', 'أقصر', 'منك.'] },
    { 'en': ['She', 'talks', 'a', 'lot.'], 'ar': ['إنها', 'تتكلم', 'كثيراً.'] },
    { 'en': ['Raise', 'your', 'right', 'hand.'], 'ar': ['اِرفع', 'يدك', 'اليمنى.'] },
    { 'en': ['He', 'disregarded', 'my', 'advice.'], 'ar': ['تجاهل', 'نصيحتي.'] },
    { 'en': ['He', 'has', 'no', 'house', 'to', 'live', 'in.'], 'ar': ['ليس', 'لديه', 'بيت', 'ليعيش', 'فيه.'] },
    { 'en': ['Our', 'dog', 'was', 'run', 'over', 'by', 'a', 'truck.'], 'ar': ['دهست', 'شاحنة', 'كلبنا.'] },
    { 'en': ['Tom', 'likes', 'playing', 'chess.'], 'ar': ['توم', 'يحب', 'لعب', 'الشطرنج'] },
    { 'en': ['Tom', "doesn't", 'want', 'to', 'sell', 'his', 'farm.'], 'ar': ['لا', 'يرغب', 'توم', 'في', 'بيع', 'مزرعته.'] },
    { 'en': ['She', 'was', 'dressed', 'in', 'red.'], 'ar': ['كانت', 'ترتدي', 'فستاناً', 'أحمراً.'] },
    { 'en': ['That', 'fighter', 'plane', 'dropped', 'a', 'bomb.'], 'ar': ['الطائرة', 'المقاتلة', 'أسقطت', 'قنبلة.'] },
    { 'en': ['Come', 'and', 'help', 'us.'], 'ar': ['تعال', 'و', 'ساعدنا.'] },
    { 'en': ['I', "don't", 'know', 'why.'], 'ar': ['أنا', 'لا', 'أعرف', 'لماذا'] },
    { 'en': ['The', 'guy', 'standing', 'over', 'there', 'is', 'Tom.'], 'ar': ['من', 'يقف', 'هناك', 'هو', 'توم.'] },
    { 'en': ['Wait', 'just', 'a', 'moment.'], 'ar': ['انتظر', 'لحظة.'] },
    { 'en': ['She', "doesn't", 'like', 'sushi.'], 'ar': ['إنها', 'لا', 'تحب', 'السوشي.'] },
    { 'en': ['I', 'would', 'help', 'you', 'if', 'you', 'were', 'in', 'trouble.'], 'ar': ['إن', 'كنت', 'في', 'مشكل،', 'فسأساعدك.'] },
    { 'en': ['Tom', 'was', 'also', 'there.'], 'ar': ['كان', 'توم', 'هنا', 'أيضاً.'] },
    { 'en': ['What', 'a', 'surprise!'], 'ar': ['يا', 'لها', 'من', 'مفاجأة!'] },
    { 'en': ['Does', 'Tom', 'like', 'tomatoes?'], 'ar': ['هل', 'يحب', 'توم', 'الطماطم؟'] },
    { 'en': ['Do', 'you', 'have', 'a', 'fever?'], 'ar': ['هل', 'لديك', 'حمى؟'] },
    { 'en': ['Thank', 'you', 'very', 'much,', 'doctor.'], 'ar': ['شكراً', 'جزيلاً', 'لك', 'أيها', 'الطبيب.'] },
    { 'en': ['Is', 'it', 'a', 'recent', 'picture?'], 'ar': ['هل', 'أُخذت', 'هذه', 'الصورة', 'حديثاً؟'] },
    { 'en': ['Humans', 'were', 'never', 'meant', 'to', 'live', 'forever.'], 'ar': ['لم', 'يُقدَّر', 'للبشر', 'إطلاقًا', 'أن', 'يعيشوا', 'إلى', 'الأبد.'] },
    { 'en': ['Bring', 'me', 'a', 'cup', 'of', 'coffee,', 'will', 'you?'], 'ar': ['أحضر', 'لي', 'فنجاناً', 'من', 'القهوة؟'] },
    { 'en': ['I', 'will', 'come', 'with', 'you.'], 'ar': ['سأذهب', 'معك.'] },
    { 'en': ['You', 'should', 'have', 'started', 'by', 'now.'], 'ar': ['كان', 'عليك', 'أن', 'تكون', 'قد', 'بدأت', 'الآن.'] },
    { 'en': ['Above', 'all,', 'watch', 'your', 'diet.'], 'ar': ['أهم', 'شيء', 'أن', 'تهتم', 'بما', 'تأكله.'] },
    { 'en': ['How', 'did', 'you', 'make', 'this', 'soup?'], 'ar': ['كيف', 'حضّرت', 'هذا', 'الحساء؟'] },
    { 'en': ['I', 'am', 'all', 'ears.'], 'ar': ['كلّي', 'آذانٌ', 'صاغية.'] },
    { 'en': ['You', 'are', 'my', 'father.'], 'ar': ['أنت', 'أبي.'] },
    { 'en': ['Sport', 'is', 'good', 'for', 'your', 'health.'], 'ar': ['الرياضة', 'جيدة', 'لصحتك.'] },
    { 'en': ['I', 'told', 'them', 'to', 'send', 'me', 'another', 'ticket.'], 'ar': ['أخبرتُهُم', 'أن', 'يُرْسِلُوا', 'لي', 'تذِكرةً', 'أُخرى.'] },
    { 'en': ['When', 'I', 'want', 'to', 'cry,', 'I', 'think', 'of', 'you.'], 'ar': ['حين', 'أريد', 'أن', 'أبكي،', 'فإني', 'أفكر', 'فيك.'] },
    { 'en': ['My', 'house', 'is', 'near', 'the', 'school.'], 'ar': ['بيتي', 'بالقُرب', 'من', 'المدرسة.'] },
    { 'en': ['Can', 'it', 'be', 'phrased', 'in', 'another', 'way?'], 'ar': ['ألا', 'يمكن', 'صياغتها', 'بطَريقة', 'أخرى؟'] },
    { 'en': ['That', 'was', 'my', 'mistake.'], 'ar': ['إنها', 'غلطتي.'] },
    { 'en': ['What', 'do', 'you', 'actually', 'do', 'in', 'the', 'office?'], 'ar': ['ما', 'هي', 'طبيعة', 'عملك', 'في', 'المكتب؟'] },
    { 'en': ['Tom', 'is', 'going', 'to', 'sell', 'his', 'house.'], 'ar': ['سيبيع', 'توم', 'منزله.'] },
    { 'en': ['Please', 'correct', 'me', 'when', 'I', 'make', 'a', 'mistake.'], 'ar': ['من', 'فضلك,', 'صحِح', 'لي,', 'عندما', 'أُخطيء.'] },
    { 'en': ['The', 'dip', 'was', 'so', 'hot,', 'it', 'burned', 'my', 'tongue.'], 'ar': ['الصلصة', 'كانت', 'حارة', 'جداً', '،', 'انها', 'حرقت', 'لساني.'] },
    { 'en': ['I', 'have', 'two', 'cousins.'], 'ar': ['لدي', 'ابنا', 'عم.'] },
    { 'en': ['What', 'was', 'that', 'noise?'], 'ar': ['ما', 'تلك', 'الضجة؟'] },
    { 'en': ['How', "you've", 'grown!'], 'ar': ['لقد', 'كبرت!'] },
    { 'en': ['How', 'lucky', 'you', 'are!'], 'ar': ['كم', 'أنتَ', 'محظوظ!'] },
    { 'en': ['Tom', 'wants', 'to', 'know', 'if', 'it', 'hurts.'], 'ar': ['توم', 'يريد', 'أن', 'يعرف', 'م', 'إذا', 'كانت', 'تؤلِم.'] },
    { 'en': ['Look', 'at', 'that', 'house.'], 'ar': ['أنظر', 'إلى', 'هذا', 'المنزل.'] },
    { 'en': ['Yes,', "she'll", 'probably', 'come', 'home.'], 'ar': ['نعم،', 'على', 'الأرجح', 'أنها', 'سوف', 'تأتي', 'إلى', 'المنزل.'] },
    { 'en': ['Who', 'am', 'I?'], 'ar': ['من', 'أنا؟'] },
    { 'en': ["I'll", 'join', 'you', 'later.'], 'ar': ['سأوافيك', 'لاحقاً.'] },
    { 'en': ['I', 'think', 'they', 'saw', 'me.'], 'ar': ['أظن', 'أنهم', 'قد', 'رأوني'] },
    { 'en': ['The', 'house', 'is', 'haunted.'], 'ar': ['البيت', 'مسكون.'] },
    { 'en': ["That's", 'a', 'hard', 'question', 'to', 'answer.'], 'ar': ['هذا', 'سؤال', 'صعب.'] },
    { 'en': ['The', 'end', 'justifies', 'the', 'means.'], 'ar': ['الغايةُ', 'تبرّرُ', 'الوسيلة.'] },
    { 'en': ['I', 'usually', 'do', 'the', 'dishes.'], 'ar': ['عادة', 'ما', 'أغسل', 'الصحون.'] },
    { 'en': ['I', 'have', 'to', 'stay', 'in', 'bed', 'all', 'day.'], 'ar': ['علي', 'أن', 'ألازم', 'السرير', 'طول', 'اليوم.'] },
    { 'en': ['I', 'know', 'the', 'girl.'], 'ar': ['أعرف', 'تلك', 'الفتاة.'] },
    { 'en': ["That's", 'my', 'favorite', 'chair.'], 'ar': ['ذاك', 'كرسيي', 'المفضل.'] },
    { 'en': ['We', 'need', 'a', 'new', 'truck.'], 'ar': ['نحتاج', 'شاحنة', 'جديدة.'] },
    { 'en': ['Even', 'idiots', 'can', 'give', 'good', 'advice.'], 'ar': ['خذ', 'الحكمة', 'من', 'أفواه', 'المجانين.'] },
    { 'en': ['Wait', 'a', 'minute,', 'Tom!'], 'ar': ['إنتظر', 'دقيقة,', 'يا', 'توم!'] },
    { 'en': ['Thank', 'you', 'for', 'your', 'kindness.'], 'ar': ['شكراً', 'لكَ', 'على', 'لطفك.'] },
    { 'en': ['I', 'think', "he's", 'happy.'], 'ar': ['أظن', 'أنه', 'سعيد.'] },
    { 'en': ["Let's", 'take', 'a', 'trip', 'to', 'Australia.'], 'ar': ['دعونا', 'نقم', 'برحلة', 'إلى', 'أستراليا'] },
    { 'en': ['Could', 'you', 'repeat', 'that,', 'please?'], 'ar': ['أيمكنك', 'أن', 'تكرر', 'ما', 'قلته', 'من', 'فضلك؟'] },
    { 'en': ["Let's", 'get', 'drunk.'], 'ar': ['هيا', 'لنشرب.'] },
    { 'en': ['I', 'really', 'like', 'this', 'car.'], 'ar': ['انني', 'احب', 'هذه', 'السيارة', 'جدا.'] },
    { 'en': ['Could', 'you', 'give', 'me', 'a', 'ride', 'to', 'the', 'station?'], 'ar': ['هل', 'يمكنك', 'إيصالي', 'إلى', 'المحطة؟'] },
    { 'en': ['Tom', 'died.'], 'ar': ['توفي', 'توم.'] },
    { 'en': ['Can', 'you', 'give', 'me', 'a', 'ride', 'to', 'the', 'station?'], 'ar': ['هل', 'يمكنك', 'إيصالي', 'إلى', 'المحطة؟'] },
    { 'en': ['She', 'stays', 'in', 'touch', 'with', 'him.'], 'ar': ['هي', 'تبقى', 'على', 'إتصال', 'معه.'] },
    { 'en': ['He', 'stood', 'on', 'the', 'edge', 'of', 'the', 'cliff.'], 'ar': ['وقف', 'على', 'حافة', 'الجرف.'] },
    { 'en': ['Welcome.'], 'ar': ['اهلا', 'وسهلا'] },
    { 'en': ['How', 'are', 'you?'], 'ar': ['كيفَ', 'حالك؟'] },
    { 'en': ['Tom', 'loves', 'animals.'], 'ar': ['يحب', 'توم', 'الحيوانات'] },
    { 'en': ['That', 'fighter', 'plane', 'dropped', 'a', 'bomb.'], 'ar': ['الطائرة', 'المقاتلة', 'ألقت', 'قنبلة.'] },
    { 'en': ['You', 'did', 'an', 'excellent', 'job.'], 'ar': ['لقد', 'قمت', 'بعمل', 'رائع.'] },
    { 'en': ['I', 'miss', 'you', 'badly.'], 'ar': ['أنا', 'مشتاق', 'إليك', 'جداً.'] },
    { 'en': ['It', 'is', 'their', 'only', 'choice.'], 'ar': ['إنه', 'خيارهم', 'الوحيد.'] },
    { 'en': ['I', "wasn't", 'sure', 'what', 'was', 'going', 'on.'], 'ar': ['لم', 'أكن', 'واثقا', 'مما', 'يجري.'] },
    { 'en': ['Tom', "can't", 'understand', 'why', 'Mary', 'is', 'angry.'], 'ar': ['توم', 'لا', 'يستطيع', 'أن', 'يفهم', 'لماذا', 'مارى', 'غاضبة.'] },
    { 'en': ['No', 'words', 'can', 'express', 'her', 'deep', 'sorrow.'], 'ar': ['الكلمات', 'لا', 'يسعها', 'التعبير', 'عن', 'حزنها', 'العميق.'] },
    { 'en': ['He', 'works', 'in', 'the', 'lab.'], 'ar': ['إنه', 'يعمل', 'في', 'المختبر.'] },
    { 'en': ['Most', 'people', 'think', 'so.'], 'ar': ['يعتقد', 'الكثيرون', 'ذلك.'] },
    { 'en': ['Without', 'your', 'advice,', 'I', 'would', 'have', 'failed.'], 'ar': ['لولا', 'نصائحك', 'لفشلت.'] },
    { 'en': ['You', 'should', 'apologize', 'to', 'her.'], 'ar': ['عليك', 'أن', 'تتأسف', 'لها.'] },
    { 'en': ['He', 'plays', 'World', 'of', 'Warcraft.'], 'ar': ['إنه', 'يلعب', 'عالم', 'من', 'العلب.'] },
    { 'en': ['What', 'made', 'you', 'so', 'angry?'], 'ar': ['ما', 'الذي', 'أغضبك', 'بهذا', 'الشكل؟'] },
    { 'en': ['She', 'bought', 'two', 'pounds', 'of', 'butter.'], 'ar': ['اشترت', 'رطلين', 'من', 'الزبدة.'] },
    { 'en': ['She', 'has', 'wet', 'hair.'], 'ar': ['شعرها', 'مبلول.'] },
    { 'en': ['Keep', 'your', 'home', 'warm.'], 'ar': ['حافظ', 'على', 'دفئ', 'منزلك.'] },
    { 'en': ["It's", 'late.'], 'ar': ['إنها', 'ساعة', 'متأخرة.'] },
    { 'en': ['Please', "don't", 'cry.'], 'ar': ['من', 'فضلك', 'لا', 'تبكِ.'] },
    { 'en': ["I'm", 'OK', 'now.'], 'ar': ['أنا', 'بخير', 'الآن.'] },
    { 'en': ["I've", 'already', 'spoken', 'with', 'Tom.'], 'ar': ['سبق', 'أن', 'حادثت', 'توم.'] },
    { 'en': ['You', 'look', 'pale.'], 'ar': ['تبدو', 'شاحباً.'] },
    { 'en': ['I', 'liked', 'this', 'film.'], 'ar': ['أحببت', 'هذا', 'الفيلم.'] },
    { 'en': ['He', 'likes', 'to', 'read', 'newspapers.'], 'ar': ['يحب', 'قراءة', 'الصحف.'] },
    { 'en': ["I'll", 'leave', 'the', 'decision', 'to', 'you.'], 'ar': ['سأترك', 'القرار', 'الأخير', 'لك.'] },
    { 'en': ['She', 'was', 'afraid', 'to', 'travel', 'alone.'], 'ar': ['إنها', 'خائفة', 'من', 'السفر', 'وحدها.'] },
    { 'en': ['Tom', 'suggested', 'it.'], 'ar': ['توم', 'أقترحَ', 'ذَلك.'] },
    { 'en': ['The', 'engine', 'started', 'again.'], 'ar': ['المحرك', 'بدأ', 'مجدداً.'] },
    { 'en': ['Tom', 'always', 'shouts', 'when', 'he', 'is', 'angry.'], 'ar': ['توم', 'دائماً', 'يصرخ', 'عندما', 'يكون', 'غاضباً.'] },
    { 'en': ['Where', 'are', 'my', 'glasses?'], 'ar': ['أين', 'نظارتي؟'] },
    { 'en': ['Milk', "doesn't", 'mix', 'with', 'water.'], 'ar': ['لا', 'يختلط', 'الحليب', 'بالماء.'] },
    { 'en': ['Where', 'will', 'we', 'meet?'], 'ar': ['أين', 'سنلتقي؟'] },
    { 'en': ["I'm", 'right', 'behind', 'him.'], 'ar': ['أنا', 'خلفه', 'مباشرة', '.'] },
    { 'en': ['Why', 'does', 'Tom', 'worry', 'so', 'much?'], 'ar': ['لماذا', 'يقلق', 'توم', 'كثيراً؟'] },
    { 'en': ['You', 'can', 'always', 'come', 'here.'], 'ar': ['أنتَ', 'يمكنكَ', 'دائماً', 'القدوم', 'هنا.'] },
    { 'en': ['You', 'may', 'go', 'there.'], 'ar': ['يمكنك', 'الذهاب', 'إلى', 'هناك.'] },
    { 'en': ['Listen', 'to', 'your', 'mother.'], 'ar': ['استمع', 'إلى', 'أمك'] },
    { 'en': ['Are', 'these', 'your', 'dogs?'], 'ar': ['هل', 'هذه', 'كلابك؟'] },
    { 'en': ['I', 'was', 'a', 'teacher,', 'too.'], 'ar': ['أنا', 'أيضا', 'كنت', 'مدرّسا.'] },
    { 'en': ['Where', 'is', 'his', 'clinic', 'located?'], 'ar': ['أين', 'تقع', 'عيادته؟'] },
    { 'en': ['He', 'might', 'change', 'his', 'mind.'], 'ar': ['ربما', 'سيغير', 'رأيه.'] },
    { 'en': ["I've", 'got', 'a', 'pacemaker.'], 'ar': ['أستعمل', 'منظمًا', 'لضربات', 'القلب.'] },
    { 'en': ['Tom', 'said', 'he', 'wanted', 'something', 'else', 'to', 'eat.'], 'ar': ['قال', 'توم', 'بأنه', 'يريد', 'شيئا', 'آخر', 'يأكله.'] },
    { 'en': ["I'm", 'looking', 'forward', 'to', 'summer', 'vacation.'], 'ar': ['أنا', 'أتتطلع', 'لعطلة', 'الصيف.'] },
    { 'en': ['Does', 'anyone', 'speak', 'English?'], 'ar': ['هل', 'يتحدث', 'أحدٌ', 'الإنجليزية؟'] },
    { 'en': ['Those', 'students', 'are', 'Korean.'], 'ar': ['هؤلاء', 'الطلاب', 'كورييون.'] },
    { 'en': ['Your', 'book', 'is', 'on', 'the', 'desk.'], 'ar': ['كتابك', 'على', 'المكتب.'] },
    { 'en': ['Who', 'are', 'those', 'people?'], 'ar': ['من', 'هؤلاء', '؟'] },
    { 'en': ['Try', 'it', 'once', 'more.'], 'ar': ['جرب', 'مرة', 'أخرى.'] },
    { 'en': ["He's", 'a', 'biologist.'], 'ar': ['هو', 'عالم', 'أحيائي.'] },
    { 'en': ['What', 'kind', 'of', 'sandwich', 'do', 'you', 'want?'], 'ar': ['أي', 'نوع', 'من', 'الشطائر', 'تريد؟'] },
    { 'en': ['You', 'know', 'how.'], 'ar': ['أنت', 'تعلم', 'كيف'] },
    { 'en': ['Nobody', 'understands', 'me.'], 'ar': ['لا', 'يفهمني', 'أحد.'] },
    { 'en': ['Do', 'you', 'live', 'here?'], 'ar': ['هل', 'تعيش', 'هنا؟'] },
    { 'en': ['She', 'did', 'come', 'here.'], 'ar': ['أتَتْ', 'إلى', 'هنا', 'حقا.'] },
    { 'en': ['I', 'looked', 'around', 'me.'], 'ar': ['تفقدت', 'ما', 'حولي.'] },
    { 'en': ["I'm", 'sorry', 'for', 'what', 'happened.'], 'ar': ['آسف', 'لما', 'حدث.'] },
    { 'en': ['She', 'wants', 'to', 'work', 'at', 'the', 'hospital.'], 'ar': ['إنها', 'تريد', 'أن', 'تعمل', 'في', 'المستشفى.'] },
    { 'en': ['How', 'long', 'does', 'it', 'take?'], 'ar': ['كم', 'تستغرق', 'من', 'الوقت؟'] },
    { 'en': ['What', 'do', 'you', 'want', 'now?'], 'ar': ['ماذا', 'تريد', 'الآن؟'] },
    { 'en': ['I', 'convinced', 'him', 'that', 'he', 'was', 'wrong.'], 'ar': ['أقنعته', 'أنه', 'كان', 'مخطئا.'] },
    { 'en': ['We', 'talked', 'about', 'a', 'lot', 'of', 'things.'], 'ar': ['تحدثنة', 'عن', 'بعض', 'الاشياء'] },
    { 'en': ['Come', 'in.', 'The', 'door', 'is', 'open.'], 'ar': ['ادخل,', 'الباب', 'مفتوح.'] },
    { 'en': ['Please', 'turn', 'off', 'the', 'television.'], 'ar': ['من', 'فضلك', 'أطفأ', 'التلفاز.'] },
    { 'en': ["It's", 'too', 'far', 'away.'], 'ar': ['إنها', 'بعيدة', 'جداً.'] },
    { 'en': ["Don't", 'sleep', 'with', 'the', 'windows', 'open.'], 'ar': ['لا', 'تنم', 'والنوافذ', 'مفتوحة.'] },
    { 'en': ['Tom', "isn't", 'a', 'good', 'singer.'], 'ar': ['توم', 'ليس', 'بمغنٍّ', 'جيّد.'] },
    { 'en': ['Who', 'wrote', 'Hamlet?'], 'ar': ['من', 'كتب', 'هاملت؟'] },
    { 'en': ['I', 'have', 'four', 'times', 'more', 'CDs', 'than', 'you', 'do.'], 'ar': ['لدي', 'أربعة', 'أضعاف', 'ما', 'عندك', 'من', 'الأقراص', 'المدمجة.'] },
    { 'en': ['Why', 'do', 'you', 'want', 'to', 'leave', 'today?'], 'ar': ['لِمَ', 'تريد', 'المغادرة', 'اليوم؟'] },
    { 'en': ['Tom', 'and', 'Mary', 'met', 'in', '2013.'], 'ar': ['توم', 'و', 'ماري', 'تقابلا', 'في', '2013'] },
    { 'en': ['Everybody', 'agrees', 'with', 'you.'], 'ar': ['الجميع', 'يتفق', 'معك.'] },
    { 'en': ['I', 'have', 'lunch', 'at', 'noon', 'with', 'my', 'friends.'], 'ar': ['أتناول', 'الغداء', 'عند', 'الظهيرة', 'مع', 'أصدقائي.'] },
    { 'en': ['Tom', 'saved', 'her', 'from', 'the', 'fire.'], 'ar': ['أنقذها', 'توم', 'من', 'الحريق.'] },
    { 'en': ['Why', 'does', 'this', 'always', 'happen', 'to', 'me?'], 'ar': ['لماذا', 'يحدث', 'هذا', 'دائماً', 'لي؟'] },
    { 'en': ['The', 'rain', 'stopped', 'at', 'last.'], 'ar': ['أخيراً', 'توقف', 'المطر.'] },
    { 'en': ['He', 'talks', 'too', 'much.'], 'ar': ['إنه', 'يتكلم', 'أكثر', 'مما', 'يجب.'] },
    { 'en': ['Lock', 'the', 'doors.'], 'ar': ['أقفل', 'الأبواب.'] },
    { 'en': ["I'll", 'return', 'at', '6:30.'], 'ar': ['سأعود', 'في', 'السادسة', 'و', 'النصف.'] },
    { 'en': ['I', "didn't", 'like', 'it.'], 'ar': ['لم', 'أحبه.'] },
    { 'en': ['I', "didn't", 'really', 'mean', 'to', 'hit', 'you.'], 'ar': ['لم', 'أقصد', 'أن', 'أضربك.'] },
    { 'en': ['I', 'prefer', 'coffee', 'to', 'tea.'], 'ar': ['أفضل', 'شرب', 'القهوة', 'على', 'شرب', 'الشاي.'] },
    { 'en': ['You', 'may', 'go', 'there.'], 'ar': ['أسمح', 'لك', 'بالذهاب', 'إلى', 'هناك', 'إذا', 'أردت.'] },
    { 'en': ["Aren't", 'you', "Tom's", 'mother?'], 'ar': ['ألستِ', 'والدة', 'توم؟'] },
    { 'en': ['What', 'is', 'going', 'on?'], 'ar': ['ما', 'الذي', 'يحدث؟'] },
    { 'en': ['I', 'have', 'been', 'to', 'Rome.'], 'ar': ['لقد', 'زرت', 'روما.'] },
    { 'en': ['Tom', 'once', 'worked', 'as', 'a', 'tour', 'guide.'], 'ar': ['عمل', 'توم', 'ذات', 'مرة', 'كمرشد', 'سياحي', '.'] },
    { 'en': ['He', 'can', 'scarcely', 'write', 'his', 'name.'], 'ar': ['بالكاد', 'يستطيع', 'كتاب', 'اسمه.'] },
    { 'en': ['He', 'is', 'allergic', 'to', 'house', 'dust.'], 'ar': ['عنده', 'حساسية', 'من', 'غبار', 'المنزل.'] },
    { 'en': ['There', 'were', '150', 'passengers', 'on', 'the', 'plane.'], 'ar': ['كان', 'هناك', '150', 'راكباً', 'على', 'متن', 'الطائرة.'] },
    { 'en': ['I', 'passed', 'by', 'four', 'houses.'], 'ar': ['مررت', 'بأربعة', 'بيوت.'] },
    { 'en': ['Please', 'turn', 'the', 'page.'], 'ar': ['من', 'فضلك', 'إقلب', 'الصفحة.'] },
    { 'en': ["I'm", 'crazy', 'about', 'you.'], 'ar': ['أحبك', 'بجنون.'] },
    { 'en': ['This', 'is', 'not', 'important.'], 'ar': ['إن', 'ذلك', 'ليس', 'مهماً.'] },
    { 'en': ['Of', 'course.'], 'ar': ['طبعاً.'] },
    { 'en': ['Her', 'daughter', 'has', 'become', 'a', 'beautiful', 'woman.'], 'ar': ['أصبحت', 'ابنتها', 'امرأة', 'جميلة.'] },
    { 'en': ['Kites', 'were', 'invented', '2,000', 'years', 'ago.'], 'ar': ['لقد', 'تم', 'اختراع', 'الطائرة', 'الورقية', 'قبل', 'ألفَيْ', 'عام.'] },
    { 'en': ['She', 'has', 'dry', 'hair.'], 'ar': ['شعرها', 'جاف.'] },
    { 'en': ["I'll", 'buy', 'a', 'gift', 'for', 'Tom.'], 'ar': ['سوف', 'أشتري', 'هديّة', 'لتوم.'] },
    { 'en': ["I'm", 'not', 'telling', 'you', 'anything.'], 'ar': ['أنا', 'لا', 'أخبرك', 'أي', 'شئ.'] },
    { 'en': ['Sit', 'down', 'with', 'me.'], 'ar': ['اجلس', 'معي.'] },
    { 'en': ["She's", 'a', 'quiet', 'person.'], 'ar': ['إنها', 'هادئة.'] },
    { 'en': ['The', 'sea', 'is', 'rough.'], 'ar': ['البحر', 'هائج.'] },
    { 'en': ['Have', 'you', 'ever', 'played', 'baseball?'], 'ar': ['هل', 'لعبت', 'كرة', 'القاعدة', 'في', 'حياتك؟'] },
    { 'en': ['You', 'have', 'brought', 'shame', 'upon', 'our', 'family.'], 'ar': ['أنتَ', 'جلبتَ', 'العار', 'علىَ', 'عائلتنا.'] },
    { 'en': ['I', 'love', 'being', 'alone.'], 'ar': ['احب', 'أن', 'اكون', 'لوحدي'] },
    { 'en': ['By', 'the', 'way,', 'how', 'many', 'kids', 'are', 'going?'], 'ar': ['بالمناسبة،', 'كم', 'ولداً', 'سيذهب؟'] },
    { 'en': ['I', 'want', 'to', 'become', 'a', 'singer.'], 'ar': ['أريد', 'أن', 'أصير', 'مغنّيًا.'] },
    { 'en': ['Michael', 'Jackson', 'died.'], 'ar': ['توفي', 'مايكل', 'جاكسون.'] },
    { 'en': ['She', 'climbed', 'down', 'from', 'the', 'roof.'], 'ar': ['نزلت', 'عن', 'السقف.'] },
    { 'en': ['He', 'screamed', 'for', 'help.'], 'ar': ['صرخ', 'طالباً', 'النجدة.'] },
    { 'en': ['Right', 'now,', 'it', 'is', 'a', 'half', 'past', 'ten.'], 'ar': ['الساعة', 'الآن', 'العاشرة', 'والنصف.'] },
    { 'en': ['Tom', 'did', 'something', 'wrong.'], 'ar': ['ارتكب', 'توم', 'خطأ"', 'ما.'] },
    { 'en': ['I', 'got', 'what', 'she', 'was', 'saying.'], 'ar': ['فهمت', 'ما', 'قالته.'] },
    { 'en': ['It', 'is', 'raining.'], 'ar': ['الجو', 'ممطر.'] },
    { 'en': ['If', 'he', "doesn't", 'come,', "what'll", 'you', 'do?'], 'ar': ['ماذا', 'ستصنع', 'لو', 'لم', 'يأت؟'] },
    { 'en': ['Oh', 'my', 'God,', 'I', "can't", 'believe', 'this.'], 'ar': ['يا', 'إلهي،', 'أنا', 'لا', 'أصدق', 'هذا.'] },
    { 'en': ['The', 'conference', 'ended', 'two', 'hours', 'ago.'], 'ar': ['المؤتمر', 'إنتهى', 'قبل', 'ساعتين.'] },
    { 'en': ['A', 'child', 'is', 'afraid', 'of', 'the', 'dark.'], 'ar': ['طفل', 'خائف', 'من', 'الظلام.'] },
    { 'en': ['Tom', 'hid', 'under', 'the', 'table.'], 'ar': ['اختبأ', 'توم', 'تحت', 'الطاولة.'] },
    { 'en': ['Read', 'the', 'bottom', 'of', 'the', 'page.'], 'ar': ['إقرأ', 'أسفل', 'الصفحة.'] },
    { 'en': ["That's", 'what', "we've", 'always', 'used.'], 'ar': ['هذا', 'ما', 'نستخدمه', 'دائما.'] },
    { 'en': ["Don't", 'be', 'a', 'bad', 'boy.'], 'ar': ['لا', 'تكن', 'ولداً', 'سيئاً.'] },
    { 'en': ['May', 'I', 'see', 'the', 'telephone', 'directory?'], 'ar': ['ألي', 'بدليل', 'الهاتف؟'] },
    { 'en': ['Be', 'prepared.'], 'ar': ['كن', 'مستعداً'] },
    { 'en': ['You', 'are', 'not', 'supposed', 'to', 'smoke', 'here.'], 'ar': ['ليس', 'من', 'المفترض', 'بك', 'أن', 'تدخن', 'هنا.'] },
    { 'en': ["I'm", 'old', 'enough', 'to', 'vote.'], 'ar': ['أنا', 'كبير', 'بما', 'فيه', 'الكفاية', 'لأصوت.'] },
    { 'en': ["Let's", 'get', 'a', 'bottle', 'of', 'whiskey.'], 'ar': ['دعنا', 'نحصل', 'على', 'زجاجة', 'من', 'الويسكي.'] },
    { 'en': ["I'm", 'at', 'the', 'airport', 'now.'], 'ar': ['أنا', 'في', 'المطار', 'الآن.'] },
    { 'en': ['This', 'is', 'a', 'terrible', 'thing.'], 'ar': ['ذلك', 'شيءٌ', 'فظيع.'] },
    { 'en': ['What', 'have', 'you', 'eaten?'], 'ar': ['ماذا', 'أكلت؟'] },
    { 'en': ['She', 'does', 'nothing', 'but', 'complain.'], 'ar': ['كل', 'ما', 'تفعله', 'هو', 'التذمر.'] },
    { 'en': ["I'll", 'give', 'you', 'this', 'camera.'], 'ar': ['سأعطيك', 'هذه', 'الكاميرا.'] },
    { 'en': ['You', "should've", 'told', 'Tom', 'the', 'truth.'], 'ar': ['كان', 'يجب', 'عليك', 'أن', 'تقول', 'الحقيقة', 'لتوم.'] },
    { 'en': ["I've", 'heard', 'this', 'song', 'before.'], 'ar': ['لقد', 'سمعت', 'هذه', 'الأغنية', 'من', 'قبل.'] },
    { 'en': ['The', 'rain', 'is', 'wonderful.'], 'ar': ['المطر', 'رائع.'] },
    { 'en': ['I', 'gave', 'Tom', 'a', 'chance', 'to', 'work', 'with', 'me.'], 'ar': ['أنا', 'منحت', 'توم', 'فرصة', 'للعمل', 'معي.'] },
    { 'en': ['I', 'will', 'be', 'there', 'tomorrow.'], 'ar': ['سأكون', 'هناك', 'غداً.'] },
    { 'en': ['We', 'understand.'], 'ar': ['نحن', 'نفهم'] },
    { 'en': ['He', 'disclosed', 'his', 'intentions.'], 'ar': ['لقد', 'كشف', 'عن', 'نواياه.'] },
    { 'en': ['He', 'refused', 'to', 'pay.'], 'ar': ['لقد', 'رَفَضَ', 'أن', 'يدفع؟'] },
    { 'en': ['I', 'can', 'hardly', 'hear', 'you.'], 'ar': ['بالكاد', 'أسمعك.'] },
    { 'en': ['The', 'question', 'is', 'where', 'to', 'buy', 'the', 'book.'], 'ar': ['المشكلة', 'هي', 'أين', 'يمكن', 'أن', 'يُشترى', 'الكتاب.'] },
    { 'en': ['"How', 'old', 'are', 'you?"', '"I\'m', 'sixteen."'], 'ar': ['"كم', 'عمرك؟"', '"ستة', 'عشر', 'عاماً"'] },
    { 'en': ["I'll", 'call', 'you', 'every', 'night.'], 'ar': ['سأتصل', 'بك', 'كل', 'ليلة.'] },
    { 'en': ['What', 'do', 'you', 'say', 'to', 'taking', 'a', 'rest?'], 'ar': ['ما', 'رأيك', 'أن', 'تأخذ', 'استراحة؟'] },
    { 'en': ['He', 'will', 'be', 'at', 'home', 'tomorrow.'], 'ar': ['سوف', 'يكون', 'في', 'البيت', 'غداً.'] },
    { 'en': ['My', 'mother', 'plays', 'the', 'piano', 'well.'], 'ar': ['أمي', 'تعزف', 'البيانو', 'بمهارة.'] },
    { 'en': ['You', 'may', 'go.'], 'ar': ['يمكنك', 'الذهاب.'] },
    { 'en': ['I', 'was', 'very', 'tired', 'last', 'night.'], 'ar': ['كنت', 'متعباً', 'جداً', 'ليلة', 'البارحة.'] },
    { 'en': ['Is', 'this', 'your', 'umbrella?'], 'ar': ['أهذه', 'المظلة', 'لك؟'] },
    { 'en': ['Wait', 'a', 'minute.'], 'ar': ['انتظر', 'دقيقة'] },
    { 'en': ['You', 'had', 'better', 'stay', 'away', 'from', 'work', 'today.'], 'ar': ['كان', 'من', 'الأحسن', 'لو', 'تركت', 'العمل', 'اليوم.'] },
    { 'en': ['Here', 'we', 'are.'], 'ar': ['ها', 'قد', 'وصلنا.'] },
    { 'en': ['Well,', "it's", 'obvious', 'now.'], 'ar': ['بات', 'الأمر', 'واضحا', 'الآن.'] },
    { 'en': ['Where', 'did', 'you', 'go?'], 'ar': ['أين', 'ذهبت؟'] },
    { 'en': ['Is', 'this', 'your', 'bicycle?'], 'ar': ['هل', 'تلك', 'دراجتك؟'] },
    { 'en': ["Let's", 'just', 'be', 'friends.'], 'ar': ['دعونا', 'فقط', 'نصبح', 'أصدقاء.'] },
    { 'en': ['He', 'is', 'very', 'handsome.'], 'ar': ['إنه', 'وسيم.'] },
    { 'en': ['Tom', 'was', 'extraordinarily', 'handsome.'], 'ar': ['توم', 'كان', 'وسيماً', 'للغاية.'] },
    { 'en': ['You', 'might', 'have', 'told', 'me.'], 'ar': ['لربما', 'قد', 'سبق', 'و', 'قلت', 'لي', 'ذلك.'] },
    { 'en': ["I've", 'never', 'killed', 'anyone.'], 'ar': ['أنا', 'أبداً', 'لم', 'أقتل', 'أي', 'أحداً.'] },
    { 'en': ['I', 'have', 'to', 'talk', 'with', 'her', 'about', 'the', 'new', 'plan.'], 'ar': ['لا', 'بد', 'لي', 'من', 'التحدث', 'معها', 'حول', 'الخطة', 'الجديدة.'] },
    { 'en': ['Call', 'me.'], 'ar': ['اتصل', 'بي.'] },
    { 'en': ['I', 'want', 'to', 'marry', 'a', 'girl', 'like', 'her.'], 'ar': ['أريد', 'أن', 'أتزوج', 'فتاة', 'مثلها.'] },
    { 'en': ['She', 'rides', 'her', 'bike', 'to', 'school.'], 'ar': ['انها', 'تذهب', 'بدراجتها', 'للمدرسة.'] },
    { 'en': ['Let', 'us', 'go', 'home.'], 'ar': ['لنعُد', 'إلى', 'البيت.'] },
    { 'en': ['I', 'never', 'wanted', 'you', 'to', 'go.'], 'ar': ['أنا', 'أبداً', 'لم', 'أُرِد', 'أن', 'تذهب.'] },
    { 'en': ['Foxes', 'are', 'wild', 'animals.'], 'ar': ['الثعالب', 'حيوانات', 'برية'] },
    { 'en': ['I', 'like', 'pizza', 'very', 'much.'], 'ar': ['أحب', 'البيتزا', 'كثيراً.'] },
    { 'en': ['Will', 'you', 'help', 'me', 'with', 'my', 'English', 'homework?'], 'ar': ['هلّا', 'ساعدتني', 'في', 'واجب', 'اللغة', 'الإنجليزية؟'] },
    { 'en': ['Have', 'you', 'read', "today's", 'paper', 'yet?'], 'ar': ['هل', 'قرأت', 'جريدة', 'اليوم', 'بعد؟'] },
    { 'en': ['Kyoto', 'has', 'many', 'universities.'], 'ar': ['هناك', 'العديد', 'من', 'الجامعات', 'في', 'كيوتو.'] },
    { 'en': ['Tom', 'knows', 'that.'], 'ar': ['توم', 'يعلم', 'ذلك'] },
    { 'en': ['Put', 'on', 'your', 'cap.'], 'ar': ['البسي', 'قبّعتكِ.'] },
    { 'en': ['Where', 'is', 'your', 'father?'], 'ar': ['أين', 'أبوك؟'] },
    { 'en': ['Summer', 'has', 'ended.'], 'ar': ['ولى', 'فصل', 'الصيف.'] },
    { 'en': ['She', 'took', 'her', 'own', 'life.'], 'ar': ['قامت', 'بالإنتحار.'] },
    { 'en': ['He', 'has', 'no', 'girlfriend.'], 'ar': ['ليس', 'لديه', 'صديقة.'] },
    { 'en': ['Her', 'new', 'novel', 'will', 'be', 'out', 'next', 'month.'], 'ar': ['ستُنشر', 'روايتها', 'الجديدة', 'الشهر', 'القادم.'] },
    { 'en': ['I', "don't", 'have', 'much', 'desire', 'for', 'wealth.'], 'ar': ['لستُ', 'راغبًا', 'جدا', 'بالثروة.'] },
    { 'en': ['Tom', 'was', 'the', 'only', 'one', 'in', 'the', 'house.'], 'ar': ['توم', 'كان', 'وحده', 'في', 'المنزل.'] },
    { 'en': ["Let's", 'just', 'be', 'friends.'], 'ar': ['دعنا', 'فقط', 'نصبح', 'أصدقاء.'] },
    { 'en': ['I', 'agree', 'with', 'you.'], 'ar': ['أوافقك', 'الرأي.'] },
    { 'en': ["It's", 'not', 'broken.'], 'ar': ['إنها', 'ليست', 'مكسورة'] },
    { 'en': ['He', 'wished', 'she', 'had', 'more', 'time', 'for', 'him.'], 'ar': ['لقد', 'تمنى', 'أن', 'يكون', 'لديها', 'وقت', 'أكثر', 'لهُ.'] },
    { 'en': ['How', 'should', 'I', 'know?'], 'ar': ['و', 'كيف', 'لي', 'أن', 'أعلم؟'] },
    { 'en': ['A', 'bear', 'can', 'climb', 'a', 'tree.'], 'ar': ['يستطيع', 'الدب', 'تسلق', 'الشجرة.'] },
    { 'en': ['Europeans', 'like', 'to', 'drink', 'wine.'], 'ar': ['يحب', 'أهل', 'أوروبا', 'شرب', 'الخمر.'] },
    { 'en': ["I'm", 'on', 'a', 'hockey', 'team.'], 'ar': ['أنا', 'لاعب', 'في', 'فريق', 'هوكي.'] },
    { 'en': ['The', 'police', 'accused', 'him', 'of', 'murder.'], 'ar': ['اتهمته', 'الشرطة', 'بالقتل.'] },
    { 'en': ['You', "can't", 'swim,', 'can', 'you?'], 'ar': ['لا', 'يمكنك', 'أن', 'تسبح،', 'أليس', 'كذلك؟'] },
    { 'en': ['He', 'wrote', 'a', 'book', 'on', 'China.'], 'ar': ['كتب', 'كتاباً', 'عن', 'الصين.'] },
    { 'en': ["What'll", 'you', 'be', 'doing', 'over', 'the', 'weekend?'], 'ar': ['ماذا', 'ستفعل', 'في', 'عطلة', 'نهاية', 'الأسبوع؟'] },
    { 'en': ['I', 'think', 'that', 'she', 'knows', 'the', 'truth.'], 'ar': ['أعتقد', 'أنها', 'تعرف', 'الحقيقة.'] },
    { 'en': ["I'm", 'trying', 'to', 'sleep.'], 'ar': ['أنا', 'أحاول', 'أن', 'أنام.'] },
    { 'en': ['You', 'need', 'to', 'stop', 'smoking.'], 'ar': ['أنت', 'بحاجة', 'إلى', 'الإقلاع', 'عن', 'التدخين.'] },
    { 'en': ['Where', 'in', 'Austria', 'did', 'you', 'grow', 'up?'], 'ar': ['في', 'أي', 'مكان', 'في', 'النمسا', 'تربيت؟'] },
    { 'en': ['You', 'might', 'not', 'want', 'to', 'do', 'that.'], 'ar': ['أنتَ', 'قد', 'لا', 'تُريد', 'فِعل', 'ذلك.'] },
    { 'en': ['I', 'think', 'Tom', "can't", 'understand', 'French.'], 'ar': ['أظن', 'بأن', 'توم', 'لا', 'يفهم', 'الفرنسية.'] },
    { 'en': ['How', 'much', 'money', 'do', 'you', 'have?'], 'ar': ['كم', 'من', 'المال', 'لديك؟'] },
    { 'en': ['I', 'got', 'this', 'bicycle', 'for', 'free.'], 'ar': ['حصلت', 'على', 'هذه', 'الدراجة', 'مجاناً.'] },
    { 'en': ["I'll", 'never', 'forgive', 'you.'], 'ar': ['لن', 'أسامحك', 'أبدا.'] },
    { 'en': ['The', 'decision', 'is', 'not', 'final.'], 'ar': ['هذا', 'القرار', 'ليس', 'نهائياً.'] },
    { 'en': ['The', 'boy', 'washing', 'the', 'car', 'is', 'my', 'brother.'], 'ar': ['الولد', 'الذي', 'يغسل', 'السيارة', 'هو', 'أخي.'] },
    { 'en': ['I', 'need', 'your', 'help.'], 'ar': ['أحتاج', 'إلى', 'مساعدتك.'] },
    { 'en': ['Tom', "doesn't", 'usually', 'show', 'his', 'feelings.'], 'ar': ['لا', 'يظهر', 'توم', 'مشاعره', 'دائما.'] },
    { 'en': ["Aren't", 'you', 'going', 'to', 'finish', 'your', 'supper?'], 'ar': ['ألا', 'ترغب', 'في', 'إنهاء', 'عشائك؟'] },
    { 'en': ['I', 'laughed.'], 'ar': ['ضحكت.'] },
    { 'en': ['My', 'father', 'has', 'never', 'been', 'abroad.'], 'ar': ['لم', 'يسافر', 'أبي', 'إلى', 'الخارج', 'من', 'قبل.'] },
    { 'en': ['I', "haven't", 'been', 'completely', 'honest', 'with', 'you.'], 'ar': ['لم', 'أصدُق', 'معك', 'تماما.'] },
    { 'en': ['Tom', 'and', 'I', 'are', 'dependent', 'on', 'each', 'other.'], 'ar': ['أنا', 'وتوم', 'نعيل', 'بعضنا.'] },
    { 'en': ['Is', 'her', 'father', 'a', 'doctor?'], 'ar': ['هل', 'أبوها', 'طبيب؟'] },
    { 'en': ["She's", 'not', 'young,', 'is', 'she?'], 'ar': ['ليست', 'صغيرة', '،أليس', 'كذلك', '؟'] },
    { 'en': ['If', 'you', "don't", 'work,', 'you', "can't", 'eat.'], 'ar': ['إن', 'لم', 'تعمل', 'لن', 'تستطيع', 'الأكل.'] },
    { 'en': ['We', 'have', 'a', 'nice', 'school', 'library.'], 'ar': ['لدينا', 'مكتبة', 'مدرسية', 'جميلة.'] },
    { 'en': ['Do', 'you', 'have', 'anything', 'to', 'say?'], 'ar': ['هل', 'عندك', 'ما', 'تريد', 'قوله؟'] },
    { 'en': ["It's", 'fifty', 'kilometers', 'to', 'Paris.'], 'ar': ['تبعد', 'عن', 'باريس', 'خمسين', 'كيلومتراً.'] },
    { 'en': ['You', 'need', 'to', 'take', 'a', 'break.'], 'ar': ['عليك', 'أخذ', 'استراحة.'] },
    { 'en': ['Tom', "isn't", 'strict', 'enough', 'with', 'his', 'students.'], 'ar': ['توم', 'ليس', 'صارماً', 'بما', 'فيهِ', 'الكفاية', 'مع', 'طلابه.'] },
    { 'en': ['I', 'appreciate', 'your', 'help.'], 'ar': ['أُقَدِّر', 'مساعدتك.'] },
    { 'en': ['I', 'might', 'have', 'left', 'it', 'on', 'the', 'table.'], 'ar': ['لربما', 'تركتها', 'على', 'الطاولة.'] },
    { 'en': ['What', 'time', 'did', 'she', 'arrive', 'at', 'Narita', 'Airport?'], 'ar': ['متى', 'وصلت', 'إلى', 'مطار', 'ناريتا؟'] },
    { 'en': ['I', 'met', 'her', 'on', 'my', 'way', 'to', 'school.'], 'ar': ['قابلتها', 'في', 'طريقي', 'إلى', 'المدرسة.'] },
    { 'en': ["I've", 'never', 'seen', 'anything', 'like', 'it.'], 'ar': ['لم', 'أرى', 'شيئا', 'مثله', 'أبدا.'] },
    { 'en': ['You', "can't", 'speak', 'English,', 'can', 'you?'], 'ar': ['أنتَ', 'لا', 'يمكنك', 'تحدث', 'الإنجليزية,', 'هل', 'يمكنك؟'] },
    { 'en': ['You', 'look', 'sick.'], 'ar': ['تبدو', 'مريضاً.'] },
    { 'en': ['I', 'have', 'two', 'cars.'], 'ar': ['عندي', 'سيارتان.'] },
    { 'en': ['The', 'police', 'accused', 'him', 'of', 'murder.'], 'ar': ['وجهت', 'الشرطة', 'إليه', 'تهمة', 'ارتكاب', 'جريمة', 'قتل.'] },
    { 'en': ['I', 'just', 'wanted', 'to', 'check', 'my', 'email.'], 'ar': ['أنا', 'أردت', 'فقط', 'التحقق', 'من', 'بريدي', 'الإلكتروني.'] },
    { 'en': ["What's", 'your', 'home', 'address?'], 'ar': ['ما', 'عنوان', 'بيتك؟'] },
    { 'en': ['I', 'will', 'go', 'to', 'the', 'meeting.'], 'ar': ['سأذهب', 'إلى', 'الإجتماع.'] },
    { 'en': ['I', 'have', 'information', 'for', 'Tom.'], 'ar': ['لدي', 'معلومات', 'لتوم.'] },
    { 'en': ['All', 'Tom', 'really', 'wanted', 'was', 'something', 'to', 'eat.'], 'ar': ['كل', 'ما', 'كان', 'يحتاجه', 'توم', 'حقاً', 'كان', 'شيئاً', 'ما', 'ليأكله.'] },
    { 'en': ['That', 'is', 'our', 'school.'], 'ar': ['هذه', 'مدرستنا.'] },
    { 'en': ['He', 'was', 'caught', 'cheating', 'in', 'the', 'exam.'], 'ar': ['قُبض', 'عليه', 'و', 'هو', 'يغش', 'في', 'الإمتحان.'] },
    { 'en': ['Get', 'out', 'of', 'here.'], 'ar': ['إخرج', 'من', 'هنا.'] },
    { 'en': ['The', 'rain', 'continued', 'all', 'day.'], 'ar': ['استمر', 'المطر', 'بالهطول', 'طوال', 'اليوم.'] },
    { 'en': ["I'll", 'bring', 'one', 'more', 'towel.'], 'ar': ['سأحضر', 'منشفة', 'أخرى.'] },
    { 'en': ['Why', 'not?'], 'ar': ['لما', 'لا؟'] },
    { 'en': ['I', 'still', 'hate', 'you.'], 'ar': ['مازلت', 'أكرهك.'] },
    { 'en': ['I', 'prefer', 'not', 'to', 'comment.'], 'ar': ['أفضل', 'أن', 'لا', 'أعلّق.'] },
    { 'en': ['The', 'video', 'has', 'been', 'removed.'], 'ar': ['مُسح', 'الفيديو.'] },
    { 'en': ['Fish', 'is', 'cheap', 'today.'], 'ar': ['السمك', 'رخيص', 'اليوم.'] },
    { 'en': ['Are', 'you', 'the', 'one', 'who', 'organized', 'this', 'party?'], 'ar': ['أأنت', 'من', 'نظّم', 'الحفلة؟'] },
    { 'en': ['We', 'love', 'our', 'children.'], 'ar': ['نحن', 'نحب', 'أولادنا.'] },
    { 'en': ['She', 'told', 'me', 'to', 'open', 'the', 'window.'], 'ar': ['طلبت', 'مني', 'أن', 'أفتح', 'النافذة.'] },
    { 'en': ['The', 'gang', 'was', 'planning', 'a', 'robbery.'], 'ar': ['خططت', 'العصابة', 'لسرقة.'] },
    { 'en': ['How', 'much', 'is', 'the', 'dollar', 'now?'], 'ar': ['بكم', 'الدولار', 'الأن؟'] },
    { 'en': ['Bangkok', 'is', 'the', 'capital', 'of', 'Thailand.'], 'ar': ['بانكوك', 'هي', 'عاصمة', 'تايلاند.'] },
    { 'en': ['What', 'are', 'you', 'going', 'to', 'do', 'tonight?'], 'ar': ['ماذا', 'ستفعل', 'الليلة؟'] },
    { 'en': ['Tell', 'Tom', 'to', 'pick', 'up', 'the', 'phone.'], 'ar': ['قل', 'لتوم', 'أن', 'يرد', 'على', 'الهاتف.'] },
    { 'en': ["I'm", 'glad', 'no', "one's", 'around.'], 'ar': ['أنا', 'سعيد', 'لأنّه', 'لا', 'يوجد', 'أحد', 'هنا.'] },
    { 'en': ['My', 'heart', 'was', 'filled', 'with', 'happiness.'], 'ar': ['امتلأ', 'قلبي', 'بالسعادة.'] },
    { 'en': ["That's", 'very', 'serious.'], 'ar': ['هذا', 'جدي', 'للغاية.'] },
    { 'en': ["Don't", 'worry.', 'He', "doesn't", 'understand', 'German.'], 'ar': ['لا', 'تقلق.', 'إنهُ', 'لا', 'يفهم', 'الألمانية.'] },
    { 'en': ['I', 'got', 'my', 'foot', 'caught', 'in', 'a', 'train', 'door.'], 'ar': ['علقت', 'رجلي', 'بباب', 'القطار.'] },
    { 'en': ["It's", 'a', 'rainy', 'day.'], 'ar': ['اليوم', 'ممطر.'] },
    { 'en': ['I', 'treated', 'her', 'as', 'my', 'own', 'daughter.'], 'ar': ['عاملتها', 'كأنها', 'بنتي.'] },
    { 'en': ['I', 'will', 'ask', 'him', 'about', 'it', 'tomorrow,', 'then.'], 'ar': ['إذاً', 'سأسأله', 'عن', 'ذلك', 'غداً.'] },
    { 'en': ["I'm", 'neither', 'rich', 'nor', 'famous.'], 'ar': ['لست', 'لا', 'غنيًّا', 'ولا', 'مشهورًا.'] },
    { 'en': ['The', 'cat', 'slowly', 'approached', 'the', 'mouse.'], 'ar': ['إقتربت', 'القطة', 'ببطأ', 'من', 'الفأر.'] },
    { 'en': ["I'm", 'in', 'love', 'with', 'someone', 'else.'], 'ar': ['أنا', 'مغرم', 'بامرأة', 'أخرى.'] },
    { 'en': ['I', 'live', 'in', 'this', 'hotel.'], 'ar': ['أسكن', 'في', 'هذا', 'الفندق.'] },
    { 'en': ['Why', 'not?'], 'ar': ['لم', 'لا؟'] },
    { 'en': ['I', 'love', 'him', 'more', 'than', 'any', 'of', 'the', 'other', 'boys.'], 'ar': ['أحبه', 'أكثر', 'من', 'بقية', 'الأولاد.'] },
    { 'en': ['I', 'am', 'hoping', 'to', 'get', 'two', 'copies', 'of', 'this', 'book.'], 'ar': ['آمل', 'أن', 'أحصل', 'على', 'نسختين', 'من', 'هذا', 'الكتاب.'] },
    { 'en': ['You', 'need', 'to', 'eat', 'more', 'fiber.'], 'ar': ['عليك', 'أن', 'تأكل', 'أليافاً', 'أكثر.'] },
    { 'en': ["It's", 'not', 'important.'], 'ar': ['ليس', 'مهماً.'] },
    { 'en': ['He', 'was', 'known', 'to', 'the', 'English', 'as', 'King', 'Philip.'], 'ar': ['عرفه', 'الإنجليز', 'باسم', 'الملك', 'فيليب.'] },
    { 'en': ['That', 'was', 'not', 'my', 'intention.'], 'ar': ['كانت', 'نيّتي', 'كذلك.'] },
    { 'en': ['Which', 'one', 'of', 'those', 'is', 'yours?'], 'ar': ['أيّها', 'لك؟'] },
    { 'en': ['I', 'still', 'love', 'her.'], 'ar': ['ما', 'زلت', 'أحبها'] },
    { 'en': ['May', 'I', 'see', 'your', 'passport?'], 'ar': ['هل', 'لي', 'أن', 'أرى', 'جواز', 'سفرك؟'] },
    { 'en': ['Show', 'me', 'the', 'photos,', 'please.'], 'ar': ['أرني', 'الصور', 'من', 'فضلك.'] },
    { 'en': ["I'm", 'just', 'looking,', 'thank', 'you.'], 'ar': ['أنا', 'أبحث', 'فقط,', 'شكراً', 'لكِ.'] },
    { 'en': ["What's", 'your', 'real', 'purpose?'], 'ar': ['ما', 'هدفك', 'الحقيقي؟'] },
    { 'en': ['Tom', 'works', 'in', 'a', 'coffee', 'shop.'], 'ar': ['يعمل', 'توم', 'في', 'مقهى.'] },
    { 'en': ['They', 'had', 'only', 'one', 'child.'], 'ar': ['رزقوا', 'بولد', 'وحيد.'] },
    { 'en': ['Sport', 'is', 'good', 'for', 'your', 'health.'], 'ar': ['الرياضة', 'مفيدة', 'للصحة.'] },
    { 'en': ['Come', 'inside.'], 'ar': ['تعالَ', 'بالداخل.'] },
    { 'en': ["There's", 'a', 'book', 'here.'], 'ar': ['هناك', 'كتاب', 'هنا.'] },
    { 'en': ['She', 'bore', 'the', 'pain', 'bravely.'], 'ar': ['تحمّلت', 'الألم', 'بشجاعة.'] },
    { 'en': ['I', 'play', 'tennis', 'every', 'day.'], 'ar': ['ألعب', 'التنس', 'كل', 'يوم.'] },
    { 'en': ['The', 'lady', 'is', 'forty', 'years', 'old', 'at', 'most.'], 'ar': ['عمر', 'السيدة', 'أربعون', 'عامًا', 'على', 'الأرجح.'] },
    { 'en': ['The', 'hospital', 'opened', 'last', 'month.'], 'ar': ['افتُتحت', 'المستشفى', 'الشهر', 'الماضي.'] },
    { 'en': ['He', 'lay', 'on', 'the', 'bed.'], 'ar': ['استلقى', 'على', 'السرير.'] },
    { 'en': ["I'll", 'give', 'you', 'anything', 'but', 'this.'], 'ar': ['سأعطيك', 'أي', 'شيء', 'إلا', 'هذا.'] },
    { 'en': ['I', 'can', 'play', 'tennis.'], 'ar': ['بإمكاني', 'أن', 'ألعب', 'التنس.'] },
    { 'en': ['Tom', 'causes', 'me', 'a', 'lot', 'of', 'trouble.'], 'ar': ['توم', 'يسبب', 'لي', 'الكثير', 'من', 'المتاعب.'] },
    { 'en': ['Tom', 'looks', 'like', 'a', 'typical', 'American', 'tourist.'], 'ar': ['توم', 'يبدو', 'وكأنه', 'سائح', 'امريكي', 'نموذجي', '.'] },
    { 'en': ['I', "don't", 'really', 'know.'], 'ar': ['في', 'الحقيقة', 'لا', 'أدري.'] },
    { 'en': ['After', 'a', 'while,', 'the', 'man', 'came', 'into', 'the', 'room.'], 'ar': ['بعد', 'لحظة،', 'دخل', 'الرجل', 'الى', 'الغرفة.'] },
    { 'en': ['We', 'have', 'to', 'start', 'somewhere.'], 'ar': ['علينا', 'أن', 'نبدأً', 'من', 'نقطة', 'معيّنة.'] },
    { 'en': ['Tom', 'has', 'a', 'nice', 'smile.'], 'ar': ['.لتوم', 'إبتسامة', 'جميلة'] },
    { 'en': ['She', 'left', 'him', 'for', 'another', 'man.'], 'ar': ['تركته', 'لتكون', 'مع', 'رجل', 'آخر.'] },
    { 'en': ['Thank', 'you', 'for', 'your', 'interest.'], 'ar': ['شكراً', 'لكِ', 'على', 'إهتمامِك.'] },
    { 'en': ["He's", 'at', 'home', 'right', 'now.'], 'ar': ['إنه', 'موجود', 'في', 'البيت', 'الآن.'] },
    { 'en': ['We', 'have', 'two', 'ears.'], 'ar': ['لدينا', 'أذنان.'] },
    { 'en': ['Hello,', 'Tom.', 'Good', 'morning.'], 'ar': ['مرحباً,', 'توم.', 'صباح', 'الخير.'] },
    { 'en': ['You', 'will', 'be', 'busy', 'tomorrow.'], 'ar': ['ستكون', 'مشغولاً', 'في', 'الغد.'] },
    { 'en': ['She', 'concentrated', 'on', 'one', 'thing.'], 'ar': ['ركّزت', 'على', 'شيء', 'واحد.'] },
    { 'en': ['She', 'took', 'a', 'taxi', 'to', 'the', 'hospital.'], 'ar': ['ركبت', 'سيارة', 'أجرة', 'متجهة', 'إلى', 'المستشفى.'] },
    { 'en': ['Your', "hair's", 'beautiful.'], 'ar': ['شعرك', 'جميل.'] },
    { 'en': ['Did', 'you', 'play', 'tennis?'], 'ar': ['هل', 'لعبت', 'التنس؟'] },
    { 'en': ["Let's", 'get', 'ready', 'to', 'go.'], 'ar': ['دعينا', 'نستعد', 'للذهاب.'] },
    { 'en': ['What', 'was', 'the', 'final', 'score?'], 'ar': ['ما', 'كانت', 'النتيجة', 'النهائية؟'] },
    { 'en': ['How', 'much', 'did', 'the', 'tickets', 'cost?'], 'ar': ['كم', 'كلّفت', 'التذاكر؟'] },
    { 'en': ['How', 'many', 'boys', 'are', 'there', 'in', 'this', 'class?'], 'ar': ['كم', 'ولداً', 'موجود', 'في', 'هذا', 'الصف؟'] },
    { 'en': ["I'll", 'only', 'be', 'staying', 'a', 'few', 'days.'], 'ar': ['سأبقى', 'لعدة', 'أيام', 'فحسب.'] },
    { 'en': ['That', 'is', 'why', 'he', 'got', 'angry.'], 'ar': ['هذا', 'سبب', 'غضبه.'] },
    { 'en': ['I', "can't", 'think', 'of', 'a', 'suitable', 'example.'], 'ar': ['لا', 'أستطيع', 'أن', 'أفكر', 'في', 'مثال', 'مناسب.'] },
    { 'en': ['He', 'is', 'preparing', 'for', 'the', 'test.'], 'ar': ['إنه', 'يستعد', 'للامتحان.'] },
    { 'en': ['God', 'bless', 'you!'], 'ar': ['باركك', 'الله', '.'] },
    { 'en': ['I', 'doubt', 'if', 'he', 'is', 'a', 'lawyer.'], 'ar': ['أنا', 'أشك', 'في', 'أنه', 'محام.'] },
    { 'en': ['Of', 'course.'], 'ar': ['بالطبع.'] },
    { 'en': ['Yesterday', 'was', 'my', 'birthday.'], 'ar': ['البارحة', 'كانت', 'ليلة', 'عيد', 'ميلادي.'] },
    { 'en': ['My', 'father', 'has', 'never', 'been', 'abroad.'], 'ar': ['لم', 'يجرب', 'أبي', 'السفر', 'إلى', 'الخارج', 'قط.'] },
    { 'en': ["You're", 'an', 'angel!'], 'ar': ['يا', 'لك', 'من', 'ملاك!'] },
    { 'en': ['The', 'girls', 'are', 'shy.'], 'ar': ['الفتيات', 'خجولات.'] },
    { 'en': ['Be', 'creative.'], 'ar': ['كُن', 'خلاقاً.'] },
    { 'en': ['Wolves', "won't", 'usually', 'attack', 'people.'], 'ar': ['لا', 'تهاجم', 'الذئابُ', 'الناسَ', 'عادةً.'] },
    { 'en': ['The', 'man', 'kept', 'talking', 'for', 'an', 'hour.'], 'ar': ['استمر', 'الرجل', 'بالكلام', 'لمدة', 'ساعة.'] },
    { 'en': ["I'm", 'not', 'letting', 'Tom', 'go.'], 'ar': ['لن', 'أدع', 'توما', 'يذهب.'] },
    { 'en': ['Tom', 'left', 'the', 'next', 'morning.'], 'ar': ['غادر', 'توم', 'في', 'الصباح', 'التالي.'] },
    { 'en': ["You're", 'either', 'with', 'us', 'or', 'against', 'us.'], 'ar': ['أنتَ', 'إما', 'معنا,', 'أو', 'ضدنا.'] },
    { 'en': ["I'll", 'try', 'to', 'find', 'them.'], 'ar': ['سأحاول', 'إيجادهم.'] },
    { 'en': ['She', 'has', 'a', 'son', 'and', 'two', 'daughters.'], 'ar': ['لديها', 'ولد', 'و', 'ابنتين.'] },
    { 'en': ['Ostriches', "can't", 'fly.'], 'ar': ['النعام', 'لا', 'يمكنه', 'الطيران.'] },
    { 'en': ['Who', 'is', 'the', 'boss', 'of', 'this', 'company?'], 'ar': ['من', 'رئيس', 'هذه', 'الشركة؟'] },
    { 'en': ['There', 'is', 'a', 'fork', 'missing.'], 'ar': ['هناك', 'شوكة', 'ناقصة.'] },
    { 'en': ['Your', 'examination', 'results', 'are', 'excellent.'], 'ar': ['نتيجة', 'اختبارك', 'ممتازة.'] },
    { 'en': ["What's", 'your', 'job?'], 'ar': ['ماذا', 'تعمل؟'] },
    { 'en': ['I', "don't", 'know', 'whether', 'she', 'will', 'come.'], 'ar': ['لا', 'أعلم', 'إن', 'كانت', 'قادمة', 'أم', 'لا.'] },
    { 'en': ['He', 'has', 'an', 'unpleasant', 'look', 'in', 'his', 'eyes.'], 'ar': ['نظْرتُهُ', 'لا', 'تسُرّ.'] },
    { 'en': ['Tom', 'is', 'coming', 'for', 'you.'], 'ar': ['توم', 'آت', 'لأجلك/لك'] },
    { 'en': ['Are', 'we', 'good', 'to', 'go?'], 'ar': ['هل', 'نحن', 'مستعدون', 'للذهاب'] },
    { 'en': ['Look', 'it', 'up', 'in', 'your', 'dictionary.'], 'ar': ['ابحث', 'عنها', 'في', 'قاموسك.'] },
    { 'en': ['Mastering', 'a', 'foreign', 'language', "isn't", 'easy.'], 'ar': ['إتقان', 'لغة', 'أجنبية', 'ليس', 'بالأمر', 'السهل'] },
    { 'en': ['Do', 'you', 'live', 'here?'], 'ar': ['أتسكن', 'هنا؟'] },
    { 'en': ['She', 'is', 'a', 'singer.'], 'ar': ['إنها', 'مُغَنية.'] },
    { 'en': ['Love', 'and', 'hate', 'are', 'opposite', 'emotions.'], 'ar': ['الحب', 'و', 'الكره', 'شعوران', 'متعاكسان'] },
    { 'en': ['There', 'are', 'several', 'ways', 'to', 'measure', 'speed.'], 'ar': ['هناك', 'عدة', 'طرق', 'لقياس', 'السرعة.'] },
    { 'en': ['He', 'is', 'a', 'fast', 'speaker.'], 'ar': ['يتحدث', 'بسرعة.'] },
    { 'en': ['Where', 'is', 'the', 'station?'], 'ar': ['أين', 'المحطة؟'] },
    { 'en': ['I', 'want', 'your', 'opinion.'], 'ar': ['أريد', 'أن', 'أعرف', 'رأيك.'] },
    { 'en': ["I'll", 'study', 'your', 'report.'], 'ar': ['سألقي', 'نظرة', 'على', 'تقريرك.'] },
    { 'en': ['Is', 'that', 'car', 'yours?'], 'ar': ['هل', 'تلك', 'السيارة', 'لك؟'] },
    { 'en': ["You're", 'either', 'with', 'us', 'or', "you're", 'against', 'us.'], 'ar': ['أنتَ', 'إما', 'معنا,', 'أو', 'ضدنا.'] },
    { 'en': ['She', 'warmed', 'herself', 'by', 'the', 'fire.'], 'ar': ['تدفأت', 'علي', 'النار', '.'] },
    { 'en': ["Who's", 'idea', 'was', 'that?'], 'ar': ['فكرة', 'مَن', 'كانت', 'هذه؟'] },
    { 'en': ['Earth', 'is', 'a', 'beautiful', 'planet.'], 'ar': ['كوكب', 'الأرض', 'جميل.'] },
    { 'en': ['I', 'am', 'going.'], 'ar': ['أنا', 'ذاهب.'] },
    { 'en': ["It's", 'too', 'small.'], 'ar': ['إنها', 'صغيرة', 'جداً.'] },
    { 'en': ["We'll", 'find', 'a', 'solution,', "I'm", 'sure.'], 'ar': ['متأكّد', 'من', 'أننا', 'سنجد', 'حلّا.'] },
    { 'en': ['I', 'remember', 'seeing', 'her.'], 'ar': ['أتذكر', 'أني', 'رأيتها.'] },
    { 'en': ['There', 'is', 'nothing', 'to', 'be', 'scared', 'of.'], 'ar': ['لا', 'يوجد', 'أي', 'شيء', 'مدعاة', 'للخوف.'] },
    { 'en': ['I', 'listen', 'to', 'the', 'radio', 'every', 'night.'], 'ar': ['أستمع', 'إلى', 'الراديو', 'كل', 'ليلة.'] },
    { 'en': ['Wash', 'your', 'feet.'], 'ar': ['اِغسل', 'رجليك.'] },
    { 'en': ['I', 'told', 'you', 'I', "don't", 'know', 'how', 'to', 'do', 'that.'], 'ar': ['قلت', 'لك', 'أنني', 'لا', 'أعرف', 'كيف', 'أفعل', 'ذلك.'] },
    { 'en': ['Happy', 'New', 'Year!'], 'ar': ['كل', 'سنة', 'و', 'أنتم', 'بخير.'] },
    { 'en': ['I', "can't", 'stand', 'him.'], 'ar': ['لا', 'أطيقه.'] },
    { 'en': ['She', 'looked', 'lonely.'], 'ar': ['بدت', 'وحيدة.'] },
    { 'en': ['My', 'mother', 'has', 'good', 'handwriting.'], 'ar': ['خط', 'أمي', 'جميل.'] },
    { 'en': ['Raise', 'your', 'left', 'hand.'], 'ar': ['اِرفع', 'يدك', 'اليسرى.'] },
    { 'en': ['This', 'matter', 'does', 'not', 'concern', 'me.'], 'ar': ['هذا', 'الأمر', 'لا', 'يعنيني.'] },
    { 'en': ['That', 'is', 'his', 'car.'], 'ar': ['تلك', 'هي', 'سيارته'] },
    { 'en': ['I', 'have', 'missed', 'you', 'so', 'much.'], 'ar': ['لقد', 'افتقدتك', 'كثيراً.'] },
    { 'en': ['Did', 'you', 'enjoy', 'the', 'movies?'], 'ar': ['هل', 'استمتعت', 'بمشاهدة', 'الفلم؟'] },
    { 'en': ['Relax.'], 'ar': ['استرح.'] },
    { 'en': ['Do', 'you', 'have', 'these', 'shoes', 'in', 'my', 'size?'], 'ar': ['هل', 'لديك', 'حذاءً', 'بحجم', 'قدمي؟'] },
    { 'en': ['I', 'knew', "you'd", 'come.'], 'ar': ['لقد', 'علمت', 'أنك', 'قد', 'تأتي'] },
    { 'en': ['Tom', 'is', 'a', 'fluent', 'speaker', 'of', 'Japanese.'], 'ar': ['يتحدث', 'توم', 'اليابانية', 'بطلاقة.'] },
    { 'en': ['I', 'go', 'to', 'the', 'park.'], 'ar': ['أنا', 'ذاهب', 'إلى', 'الحديقة'] },
    { 'en': ['My', 'grandmother', 'lives', 'by', 'herself.'], 'ar': ['تعيش', 'جدتي', 'وحدها.'] },
    { 'en': ['I', 'burst', 'out', 'laughing', 'when', 'I', 'saw', 'him.'], 'ar': ['ضحكت', 'فجأةً', 'عندما', 'رأيته.'] },
    { 'en': ['He', 'built', 'a', 'fence', 'around', 'his', 'house.'], 'ar': ['بنى', 'سِياجاً', 'خشبياً', 'حول', 'بيته.'] },
    { 'en': ['Birds', 'fly.'], 'ar': ['تحلق', 'الطيور.'] },
    { 'en': ["I'm", 'still', 'looking', 'for', 'a', 'job.'], 'ar': ['أنا', 'ما', 'زلت', 'أبحث', 'عن', 'وظيفة.'] },
    { 'en': ['I', 'see', 'your', 'cat', 'in', 'the', 'garden.'], 'ar': ['أرى', 'قطتك', 'في', 'الحديقة.'] },
    { 'en': ['Do', 'you', 'really', 'think', 'that', 'Tom', "won't", 'come?'], 'ar': ['هل', 'تظن', 'حقا', 'أن', 'توم', 'لن', 'يأتي؟'] },
    { 'en': ['We', 'have', 'breakfast', 'in', 'the', 'kitchen.'], 'ar': ['نأكل', 'طعام', 'الفطور', 'في', 'المطبخ.'] },
    { 'en': ["I'm", 'bored.'], 'ar': ['مللت.'] },
    { 'en': ['Show', 'me', 'your', 'passport,', 'please.'], 'ar': ['أرِنِي', 'جواز', 'سفرك،', 'لو', 'سمحت.'] },
    { 'en': ['I', 'like', 'short', 'hair.'], 'ar': ['أحب', 'الشعر', 'القصير.'] },
    { 'en': ['I', 'want', 'to', 'be', 'a', 'pilot', 'in', 'the', 'future.'], 'ar': ['أريد', 'أن', 'أكون', 'طيارا', 'في', 'المستقبل', '.'] },
    { 'en': ['Be', 'vigilant.'], 'ar': ['توخى', 'الحذر.'] },
    { 'en': ['I', 'hope', 'everything', 'goes', 'OK.'], 'ar': ['آمل', 'أن', 'يسير', 'كل', 'شيء', 'على', 'ما', 'يرام'] },
    { 'en': ['My', 'cholesterol', 'levels', 'are', 'high.'], 'ar': ['معدل', 'الكلسترول', 'في', 'دمي', 'عالٍ.'] },
    { 'en': ['This', 'is', 'the', 'only', 'camera', "I've", 'got.'], 'ar': ['هذه', 'هي', 'الكاميرا', 'الوحيدة', 'لدي.'] },
    { 'en': ["I'll", 'attend.'], 'ar': ['سأحضر.'] },
    { 'en': ['Tom', 'and', 'Mary', 'traveled', 'together.'], 'ar': ['سافر', 'توم', 'وماري', 'معا.'] },
    { 'en': ['She', 'asked', 'a', 'very', 'good', 'question.'], 'ar': ['سألت', 'سؤالاً', 'مهماً.'] },
    { 'en': ['I', 'had', 'my', 'money', 'stolen.'], 'ar': ['سُرق', 'مالي.'] },
    { 'en': ['Why', "aren't", 'you', 'coming', 'with', 'us?'], 'ar': ['لم', 'لن', 'تأتِ', 'معنا؟'] },
    { 'en': ['I', 'told', 'him', 'to', 'come.'], 'ar': ['قلت', 'له', 'أن', 'يأتي.'] },
    { 'en': ['You', 'have', 'to', 'be', 'patient.'], 'ar': ['عليك', 'أن', 'تكون', 'صبوراً.'] },
    { 'en': ['I', 'am', 'already', 'late.'], 'ar': ['أنا', 'بالفعل', 'متأخر.'] },
    { 'en': ['My', 'heart', 'was', 'filled', 'with', 'happiness.'], 'ar': ['امتلأ', 'قلبي', 'فرحاً.'] },
    { 'en': ["I'd", 'like', 'to', 'work', 'in', 'Boston.'], 'ar': ['أود', 'العمل', 'في', 'بوستن.'] },
    { 'en': ['I', 'hope', "Tom's", 'predictions', 'are', 'wrong.'], 'ar': ['آمل', 'أن', 'تخطئ', 'توقعات', 'توم.'] },
    { 'en': ['She', 'spent', 'the', 'weekend', 'by', 'herself.'], 'ar': ['أمضت', 'نهاية', 'الأسبوع', 'كلها', 'لوحدها.'] },
    { 'en': ['Do', 'you', 'like', 'it?'], 'ar': ['هل', 'تحب', 'ذلك؟'] },
    { 'en': ['Take', 'care', 'of', 'the', 'kids.'], 'ar': ['اعتني', 'بالاطفال.'] },
    { 'en': ['Children', 'are', 'full', 'of', 'energy.'], 'ar': ['الأطفالُ', 'مفعمونَ', 'بالطاقة.'] },
    { 'en': ['Will', 'he', 'be', 'able', 'to', 'come', 'tomorrow?'], 'ar': ['هل', 'سيتمكن', 'من', 'القدوم', 'غداً؟'] },
    { 'en': ['I', 'know', 'he', 'is', 'watching', 'me.'], 'ar': ['أعلم', 'أنه', 'يراقبني.'] },
    { 'en': ['That', "won't", 'help', 'you.'], 'ar': ['لن', 'يساعدك', 'ذلك.'] },
    { 'en': ['Is', 'your', 'watch', 'correct?'], 'ar': ['هل', 'ساعتك', 'على', 'التوقيت', 'الصحيح؟'] },
    { 'en': ['Tom', 'knows', "I'm", 'home.'], 'ar': ['توم', 'يعلم', 'أنني', 'في', 'البيت'] },
    { 'en': ['He', 'went', 'to', 'the', 'store.'], 'ar': ['ذهب', 'إلى', 'المحل.'] },
    { 'en': ['Have', 'you', 'been', 'to', 'London?'], 'ar': ['هل', 'سبق', 'لك', 'أن', 'ذهبت', 'إلى', 'لندن؟'] },
    { 'en': ["I'm", 'not', 'a', 'problem-solver.'], 'ar': ['لست', 'بحلال', 'مشاكل.'] },
    { 'en': ["I'm", 'really', 'not', 'hungry.'], 'ar': ['أنا', 'حقاً', 'لستُ', 'جائعة.'] },
    { 'en': ['Tom', 'said', "he'd", 'be', 'here.'], 'ar': ['توم', 'قال', 'أنه', 'قد', 'يكون', 'هنا'] },
    { 'en': ['I', 'work', 'every', 'day', 'except', 'for', 'Sundays.'], 'ar': ['أعمل', 'كل', 'يوم', 'عدا', 'يوم', 'الأحد.'] },
    { 'en': ["We're", 'not', 'involved.'], 'ar': ['ليس', 'لنا', 'علاقة.'] },
    { 'en': ['Please', 'keep', 'it', 'secret.'], 'ar': ['من', 'فضلك', 'أبق', 'الأمر', 'سراً.'] },
    { 'en': ['What', 'did', 'you', 'say?'], 'ar': ['ماذا', 'قلت؟'] },
    { 'en': ['She', 'talks', 'a', 'lot.'], 'ar': ['إنها', 'تتحدث', 'أكثر', 'مما', 'يجب.'] },
    { 'en': ['Why', 'are', 'Chinese', 'tourists', 'so', 'rude?'], 'ar': ['لماذا', 'السياح', 'الصينيون', 'فظين', 'لدرجة', '؟'] },
    { 'en': ['Run!'], 'ar': ['اركض!'] },
    { 'en': ['My', 'mother', 'sent', 'me', 'a', 'birthday', 'present.'], 'ar': ['أرسلت', 'لي', 'أمي', 'هدية', 'لعيد', 'ميلادي.'] },
    { 'en': ["Sakura's", 'way', 'of', 'speaking', 'gets', 'on', 'my', 'nerves.'], 'ar': ['طريقة', 'ساكورا', 'في', 'الكلام', 'تغضبني.'] },
    { 'en': ['Tom', 'wiped', 'the', 'table.'], 'ar': ['مسح', 'توم', 'الطاولة.'] },
    { 'en': ['Tom', 'and', 'I', 'are', 'here', 'to', 'help.'], 'ar': ['أنا', 'وتوم', 'هنا', 'لمساعدتك.'] },
    { 'en': ['We', 'had', 'a', 'good', 'time', 'playing', 'chess.'], 'ar': ['أمضينا', 'وقتاً', 'ممتعاً', 'و', 'نحن', 'نلعب', 'الشطرنج.'] },
    { 'en': ['I', 'heard', 'her', 'speak', 'English', 'fluently.'], 'ar': ['سمعتها', 'تتكلم', 'الإنجليزية', 'بطلاقة.'] },
    { 'en': ['The', 'weather', 'has', 'been', 'good', 'until', 'today.'], 'ar': ['كان', 'الطقس', 'جيدا', 'حتى', 'اليوم.'] },
    { 'en': ["I'm", 'tired', 'now.'], 'ar': ['أنا', 'متعب', 'الآن.'] },
    { 'en': ['May', 'I', 'see', 'the', 'telephone', 'directory?'], 'ar': ['أيمكنني', 'أن', 'أتصفح', 'دليل', 'الهاتف؟'] },
    { 'en': ['I', "don't", 'know', 'where', 'the', 'bus', 'stop', 'is.'], 'ar': ['لا', 'أدري', 'أين', 'موقف', 'الباص.'] },
    { 'en': ['Whose', 'paintings', 'are', 'these?'], 'ar': ['لمن', 'هذه', 'اللوحات؟'] },
    { 'en': ['I', 'was', 'waiting', 'for', 'that', 'question.'], 'ar': ['كنت', 'أنتظر', 'هذا', 'السؤال.'] },
    { 'en': ["I'm", 'at', 'work', 'now,', 'so', "I'll", 'call', 'you', 'later.'], 'ar': ['أنا', 'في', 'العمل', 'الأن,', 'لذلك', 'سأتصل', 'بكَ', 'لاحقاً.'] },
    { 'en': ['Tom', "didn't", 'want', 'to', 'get', 'married', 'again.'], 'ar': ['لم', 'يرغب', 'توم', 'بالزواج', 'مجددا.'] },
    { 'en': ['Why', "don't", 'we', 'have', 'lunch', 'together?'], 'ar': ['لمَ', 'لا', 'نأكل', 'طعام', 'الغداء', 'معاً؟'] },
    { 'en': ["We're", 'just', 'going', 'to', 'talk.'], 'ar': ['إحنا', 'فقط', 'نتكلم'] },
    { 'en': ['If', 'anybody', 'can', 'help', 'us,', "it's", 'Tom.'], 'ar': ['إذا', 'كان', 'أحد', 'يستطيع', 'أن', 'يساعدنا,', 'فهوَ', 'توم.'] },
    { 'en': ['Tom', 'said', 'he', 'knew', 'Mary.'], 'ar': ['توم', 'قال', 'أنه', 'يعرف', 'ماري'] },
    { 'en': ['I', 'have', 'some', 'pictures', 'to', 'show', 'you.'], 'ar': ['لدي', 'بعض', 'الصور', 'لأريك.'] },
    { 'en': ['I', "don't", 'know.'], 'ar': ['لا', 'أعلم.'] },
    { 'en': ['He', 'drives', 'fast.'], 'ar': ['هو', 'يسوق', 'بسرعة.'] },
    { 'en': ['He', 'and', 'I', 'walked', 'together.'], 'ar': ['مشيت', 'أنا', 'و', 'هو', 'سوية.'] },
    { 'en': ['You', 'should', 'try', 'to', 'be', 'more', 'polite.'], 'ar': ['يجب', 'أن', 'تحاول', 'أن', 'تكون', 'أكثر', 'أدباً.'] },
    { 'en': ['Turn', 'off', 'the', 'light,', 'please.'], 'ar': ['أطفئ', 'الضوء', 'من', 'فضلك.'] },
    { 'en': ['When', 'did', 'you', 'begin', 'learning', 'German?'], 'ar': ['متى', 'بدأت', 'تعلّم', 'الألمانية؟'] },
    { 'en': ['My', 'father', 'is', 'in', 'good', 'health.'], 'ar': ['والدي', 'في', 'صحة', 'جيدة.'] },
    { 'en': ['We', 'do', 'not', 'know', 'him.'], 'ar': ['نحن', 'لا', 'نعرفه'] },
    { 'en': ['He', 'reached', 'for', 'the', 'apple.'], 'ar': ['مدّ', 'يده', 'لأخذ', 'التفاحة.'] },
    { 'en': ['You', 'should', 'meet', 'him.'], 'ar': ['عليك', 'أن', 'تقابله.'] },
    { 'en': ['I', 'wanna', 'go', 'to', 'Japan.'], 'ar': ['أريد', 'الذهاب', 'إلى', 'اليابان.'] },
    { 'en': ["I'm", 'new', 'here.'], 'ar': ['أنا', 'جديد', 'هنا'] },
    { 'en': ['We', 'enjoyed', 'swimming', 'in', 'the', 'river.'], 'ar': ['تمتعنا', 'بالسباحة', 'في', 'النهر.'] },
    { 'en': ['He', 'works', 'in', 'the', 'planning', 'section.'], 'ar': ['يعمل', 'في', 'قسم', 'التخطيط.'] },
    { 'en': ["It's", 'useless', 'to', 'argue.'], 'ar': ['لا', 'جدوى', 'من', 'الجدال.'] },
    { 'en': ['I', "don't", 'know', 'you.'], 'ar': ['أنا', 'لا', 'أعرفك'] },
    { 'en': ['Do', 'you', 'have', 'work', 'experience?'], 'ar': ['هل', 'لديك', 'خبرةً', 'في', 'العمل؟'] },
    { 'en': ['Lock', 'the', 'gate.'], 'ar': ['أغلق', 'البوابة.'] },
    { 'en': ['I', 'quit', 'smoking.'], 'ar': ['توقفت', 'عن', 'التدخين'] },
    { 'en': ['Look', 'out!'], 'ar': ['إحذر!'] },
    { 'en': ["Don't", 'feed', 'the', 'dog.'], 'ar': ['لا', 'تُطعم', 'الكلب.'] },
    { 'en': ['Please', 'help', 'me', 'fill', 'out', 'this', 'form.'], 'ar': ['الرجاء', 'مساعدتي', 'في', 'ملء', 'هذا', 'النموذج.'] },
    { 'en': ['The', 'Japanese', 'like', 'to', 'travel', 'in', 'groups.'], 'ar': ['يحب', 'اليابانيون', 'السفر', 'في', 'مجموعات.'] },
    { 'en': ['The', 'peace', 'talks', 'failed', 'once', 'again.'], 'ar': ['تعثرت', 'مفاوضات', 'السلام', 'مجدداً.'] },
    { 'en': ['She', 'is', 'a', 'student.'], 'ar': ['هي', 'طالبة'] },
    { 'en': ["She's", 'way', 'taller', 'than', 'me.'], 'ar': ['إنها', 'أطول', 'مني', 'بكثير.'] },
    { 'en': ['He', 'tried', 'to', 'get', 'rid', 'of', 'the', 'ants.'], 'ar': ['حاول', 'أن', 'يتخلص', 'من', 'النمل.'] },
    { 'en': ['I', 'play', 'with', 'him.'], 'ar': ['ألعب', 'معه.'] },
    { 'en': ['Clean', 'your', 'room.'], 'ar': ['نظف', 'غرفتك.'] },
    { 'en': ['If', 'you', 'want,', 'I', 'can', 'show', 'you', 'how', 'to', 'do', 'that.'], 'ar': ['إذا', 'أردت,', 'يمكنني', 'أن', 'أُريك', 'كيف', 'تفعل', 'ذلك.'] },
    { 'en': ['This', 'dog', 'runs', 'fast.'], 'ar': ['هذا', 'الكلب', 'يركض', 'بسرعة.'] },
    { 'en': ['If', 'we', 'leave', 'now,', 'we', 'should', 'make', 'it.'], 'ar': ['إن', 'غادرنا', 'الآن', 'فسنصل', 'على', 'الوقت.'] },
    { 'en': ["You're", 'such', 'a', 'chicken.'], 'ar': ['جبان', 'كالدجاجة.'] },
    { 'en': ['He', 'is', 'a', 'fast', 'speaker.'], 'ar': ['يتكلم', 'بسرعة.'] },
    { 'en': ['Whose', 'pen', 'is', 'this?'], 'ar': ['قلم', 'مَن', 'هذا؟'] },
    { 'en': ['I', 'intend', 'to', 'clean', 'my', 'room.'], 'ar': ['أنوي', 'أن', 'أنظف', 'غرفتي.'] },
    { 'en': ["Let's", 'go', 'see', 'if', 'Tom', 'can', 'help', 'us.'], 'ar': ['دعنا', 'نذهب', 'لنرى', 'إذا', 'كان', 'توم', 'يستطيع', 'أن', 'يساعدنا.'] },
    { 'en': ['I', 'was', 'late', 'because', 'of', 'the', 'rain.'], 'ar': ['تأخرت', 'بسبب', 'المطر.'] },
    { 'en': ['Tom', 'scored', 'a', 'goal.'], 'ar': ['أحرز', 'توم', 'هدفًا.'] },
    { 'en': ['Do', 'you', 'think', 'so?'], 'ar': ['أتعتقد', 'ذلك؟'] },
    { 'en': ['Stop', 'hitting', 'your', 'sister.'], 'ar': ['توقفي', 'عن', 'ضرب', 'أُختك.'] },
    { 'en': ['Maybe', 'she', 'will', 'not', 'come.'], 'ar': ['من', 'الممكن', 'أن', 'لا', 'تأتي.'] },
    { 'en': ["That's", 'the', 'snag.'], 'ar': ['ذاك', 'هو', 'العائق.'] },
    { 'en': ['I', 'would', 'do', 'anything', 'for', 'Tom.'], 'ar': ['سأفعل', 'أيّ', 'شيء', 'لِتوم.'] },
    { 'en': ["She's", 'anxious', 'to', 'know', 'the', 'results.'], 'ar': ['إنها', 'توّاقة', 'إلى', 'معرفة', 'النتائج.'] },
    { 'en': ['He', "doesn't", 'live', 'in', 'my', 'neighborhood.'], 'ar': ['إنه', 'لا', 'يسكن', 'في', 'حيي.'] },
    { 'en': ['A', 'glass', 'of', 'water,', 'please.'], 'ar': ['أريد', 'كأسا', 'من', 'الماء', 'من', 'فضلك.'] },
    { 'en': ['The', 'early', 'bird', 'catches', 'the', 'worm.'], 'ar': ['العصفور', 'المبكر', 'يلتقط', 'الدودة.'] },
    { 'en': ['Tom', 'has', 'a', 'car.'], 'ar': ['توم', 'لديه', 'سيارة'] },
    { 'en': ["I'm", 'from', 'Croatia.'], 'ar': ['أنا', 'من', 'كرواتيا.'] },
    { 'en': ['I', 'spent', 'all', 'afternoon', 'in', 'the', 'library.'], 'ar': ['أمضيت', 'كل', 'الظهيرة', 'في', 'المكتبة.'] },
    { 'en': ['They', 'move', 'from', 'place', 'to', 'place.'], 'ar': ['يتحركون', 'من', 'مكان', 'إلى', 'آخر.'] },
    { 'en': ['Could', 'you', 'please', 'move', 'out', 'of', 'my', 'way?'], 'ar': ['هل', 'يمكنك', 'أن', 'تفسح', 'لي', 'الطريق', 'من', 'فضلك؟'] },
    { 'en': ['Muslims', 'pray', 'to', 'God.'], 'ar': ['المسلمون', 'يصلّون', 'لله.'] },
    { 'en': ['Nobody', 'can', 'understand', 'him.'], 'ar': ['لا', 'أحد', 'يفهمه.'] },
    { 'en': ['I', 'accept,', 'but', 'only', 'under', 'one', 'condition.'], 'ar': ['أقبل', 'ولكن', 'بشرط', 'واحد.'] },
    { 'en': ["Don't", 'you', 'ever', 'sweat?'], 'ar': ['ألا', 'تتعرق', 'أبدا؟'] },
    { 'en': ['Do', 'you', 'like', 'rap?'], 'ar': ['أتحب', 'موسيقى', 'الراب؟'] },
    { 'en': ["I'm", 'happy.'], 'ar': ['أنا', 'سعيد'] },
    { 'en': ["It's", 'more', 'complicated', 'than', 'that.'], 'ar': ['الأمر', 'أكثر', 'تعقيدا.'] },
    { 'en': ['Your', 'house', 'is', 'fantastic.'], 'ar': ['منزلك', 'رائع.'] },
    { 'en': ['No', 'one', 'lets', 'me', 'have', 'fun', 'anymore.'], 'ar': ['لا', 'أحد', 'يدعني', 'أحصل', 'على', 'المتعة', 'بعد', 'الآن.'] },
    { 'en': ['Japan', 'is', 'an', 'industrial', 'country.'], 'ar': ['اليابان', 'بلد', 'صناعي.'] },
    { 'en': ['Tom', 'asked', 'Mary', 'to', 'stop', 'the', 'car.'], 'ar': ['طلب', 'توم', 'من', 'ماري', 'إيقاف', 'السيارة.'] },
    { 'en': ['When', 'do', 'we', 'eat?'], 'ar': ['متى', 'سنأكل؟'] },
    { 'en': ['He', 'tried', 'to', 'master', 'French.'], 'ar': ['حاول', 'أن', 'يتقن', 'الفرنسية.'] },
    { 'en': ['She', 'lives', 'in', 'comfort.'], 'ar': ['إنها', 'تعيش', 'حياة', 'مريحة.'] },
    { 'en': ['Here', 'comes', 'the', 'train.'], 'ar': ['ها', 'قد', 'وصل', 'القطار.'] },
    { 'en': ['You', 'are', 'in', 'a', 'safe', 'place.'], 'ar': ['أنت', 'في', 'مكان', 'آمن.'] },
    { 'en': ['The', 'rumor', "isn't", 'true.'], 'ar': ['تلك', 'الإشاعة', 'ليست', 'صحيحة.'] },
    { 'en': ['Maybe', "you're", 'right.'], 'ar': ['لربما', 'كنت', 'محقاً.'] },
    { 'en': ['My', 'children', 'really', 'like', 'animals.'], 'ar': ['أولادي', 'يحبون', 'الحيوانات', 'كثيراً.'] },
    { 'en': ['May', 'I', 'sit', 'down?'], 'ar': ['أيمكنني', 'الجلوس؟'] },
    { 'en': ['This', 'house', 'is', 'mine.'], 'ar': ['هذا', 'البيت', 'بيتي.'] },
    { 'en': ['She', 'found', 'a', 'ball', 'in', 'the', 'garden.'], 'ar': ['عثرت', 'على', 'كرة', 'في', 'الحديقة.'] },
    { 'en': ["Let's", 'get', 'ready', 'to', 'go.'], 'ar': ['دعنا', 'نستعد', 'للذهاب.'] },
    { 'en': ['She', 'began', 'crying.'], 'ar': ['اِنهالت', 'بالبكاء.'] },
    { 'en': ['My', 'father', "doesn't", 'let', 'me', 'drive', 'a', 'car.'], 'ar': ['لا', 'يسمح', 'لي', 'أبي', 'بقيادة', 'السيارة.'] },
    { 'en': ['He', 'avenged', 'his', "father's", 'death.'], 'ar': ['انتقم', 'لموت', 'أبيه.'] },
    { 'en': ['She', 'is', 'from', 'France.'], 'ar': ['إنها', 'من', 'فرنسا.'] },
    { 'en': ['This', 'house', 'is', 'not', 'new.'], 'ar': ['ليس', 'البيت', 'بجديد.'] },
    { 'en': ['What', 'is', 'it', 'that', 'you', 'want?'], 'ar': ['ما', 'الذي', 'تريده؟'] },
    { 'en': ['I', "didn't", 'know', 'where', 'it', 'came', 'from.'], 'ar': ['لا', 'أعرف', 'ما', 'مصدرها.'] },
    { 'en': ['She', 'kept', 'working.'], 'ar': ['ظلت', 'تعمل.'] },
    { 'en': ['Bottoms', 'up!'], 'ar': ['برشفة', 'واحدة', '.'] },
    { 'en': ['Fighting', "won't", 'settle', 'anything.'], 'ar': ['لن', 'يحل', 'الشجار', 'شيئاً.'] },
    { 'en': ['You', 'disappointed', 'me.'], 'ar': ['خنت', 'ظني', 'فيك.'] },
    { 'en': ['Let', 'us', 'go', 'home.'], 'ar': ['هيا', 'لنرجع', 'إلى', 'المنزل.'] },
    { 'en': ['When', 'will', 'you', 'be', 'back?'], 'ar': ['متى', 'ستعود؟'] },
    { 'en': ['We', 'had', 'unexpected', 'visitors.'], 'ar': ['أتانا', 'زوّارٌ', 'غيرُ', 'متوقعين.'] },
    { 'en': ['It', 'cannot', 'last', 'long.'], 'ar': ['لا', 'يمكن', 'أن', 'يدوم', 'طويلا.'] },
    { 'en': ['I', 'saw', 'him', 'tear', 'up', 'the', 'letter.'], 'ar': ['رأيته', 'يقطّع', 'الرسالة.'] },
    { 'en': ['She', 'usually', 'goes', 'to', 'bed', 'at', 'nine.'], 'ar': ['عادة', 'ما', 'تخلد', 'إلى', 'السرير', 'الساعة', 'التاسعة.'] },
    { 'en': ["I'm", 'very', 'happy.'], 'ar': ['أنا', 'سعيد', 'للغاية.'] },
    { 'en': ['I', 'paid', '1,500', 'yen', 'for', 'this', 'dictionary.'], 'ar': ['لقد', 'دفعت', '1,500', 'ين', 'لهذا', 'القاموس.'] },
    { 'en': ['Please', 'think', 'about', 'it.'], 'ar': ['من', 'فضلك', 'فكر', 'في', 'الأمر.'] },
    { 'en': ['This', 'hotel', 'is', 'better', 'than', 'that', 'hotel.'], 'ar': ['هذا', 'الفندق', 'أفضل', 'من', 'ذاك', 'الفندق.'] },
    { 'en': ['The', 'opera', 'starts', 'at', 'seven.'], 'ar': ['تبدأ', 'الأوبرا', 'في', 'الساعة', 'السابعة.'] },
    { 'en': ['Why', 'is', 'he', 'here?'], 'ar': ['لم', 'هو', 'هنا؟'] },
    { 'en': ['What', 'you', 'said', 'has', 'no', 'meaning.'], 'ar': ['ما', 'قلته', 'ليس', 'له', 'أي', 'معنى.'] },
    { 'en': ['These', 'are', 'my', 'pencils.'], 'ar': ['هذه', 'أقلامي.'] },
    { 'en': ['She', 'waited', 'for', 'my', 'reply.'], 'ar': ['انتظرتْ', 'ردي.'] },
    { 'en': ['China', 'is', 'a', 'large', 'country.'], 'ar': ['الصين', 'بلد', 'كبير'] },
    { 'en': ['I', 'am', 'Thai.'], 'ar': ['أنا', 'تايلاندي.'] },
    { 'en': ['I', 'have', 'no', 'sisters.'], 'ar': ['ليس', 'لدي', 'أخوات.'] },
    { 'en': ['Italy', 'is', 'a', 'very', 'beautiful', 'country.'], 'ar': ['إيطاليا', 'بلد', 'جميل.'] },
    { 'en': ['She', 'showed', 'me', 'around', 'the', 'campus.'], 'ar': ['أعطتني', 'جولة', 'في', 'الحرم', 'الجامعي.'] },
    { 'en': ['What', 'do', 'you', 'grow', 'on', 'your', 'farm?'], 'ar': ['ماذا', 'تزرع', 'في', 'مزرعتك؟'] },
    { 'en': ['Look', 'out!'], 'ar': ['اِنتبه!'] },
    { 'en': ["I'm", 'sure', 'Tom', 'would', 'never', 'do', 'that.'], 'ar': ['متأكد', 'أن', 'توم', 'لن', 'يفعل', 'ذلك.'] },
    { 'en': ["You'd", 'better', 'consult', 'the', 'doctor.'], 'ar': ['الأفضل', 'أن', 'تستشير', 'الطبيب.'] },
    { 'en': ['Then', 'what?'], 'ar': ['ثم', 'ماذا؟'] },
    { 'en': ['No', 'way!'], 'ar': ['غير', 'معقول!'] },
    { 'en': ['The', 'tank', 'has', 'a', 'capacity', 'of', 'fifty-gallons.'], 'ar': ['سعة', 'الخزان', 'خمسون', 'جالوناً.'] },
    { 'en': ['Are', 'you', 'going', 'to', 'attend', 'the', 'meeting?'], 'ar': ['هل', 'ستحضر', 'الإجتماع؟'] },
    { 'en': ['He', 'was', 'stupid', 'enough', 'to', 'believe', 'her.'], 'ar': ['لقد', 'كان', 'غبي', 'بما', 'فيه', 'الكفايه', 'ليصدقها.'] },
    { 'en': ['You', 'can', 'visit', 'me', 'tomorrow.'], 'ar': ['بإمكانك', 'زيارتي', 'غداً.'] },
    { 'en': ["Aren't", 'you', 'thirsty?'], 'ar': ['ألست', 'عطِشاً؟'] },
    { 'en': ['Let', 'him', 'go!'], 'ar': ['دعه', 'يذهب!'] },
    { 'en': ['She', 'lives', 'in', 'comfort.'], 'ar': ['إنها', 'تعيش', 'في', 'راحة.'] },
    { 'en': ['You', 'must', 'judge', 'for', 'yourself.'], 'ar': ['عليك', 'أن', 'تحكم', 'بنفسك.'] },
    { 'en': ['Shut', 'up!'], 'ar': ['أغلق', 'فمك!'] },
    { 'en': ['I', 'can', 'hardly', 'hear', 'you.'], 'ar': ['بالكاد', 'أستطيع', 'سماعك.'] },
    { 'en': ['The', 'bridge', 'is', 'about', 'to', 'collapse.'], 'ar': ['يوشك', 'الجسر', 'على', 'الانهيار.'] },
    { 'en': ['We', 'should', 'sit', 'down.'], 'ar': ['علينا', 'الجلوس.'] },
    { 'en': ["We're", 'in', 'a', 'hurry.'], 'ar': ['إننا', 'في', 'عجلة', 'من', 'أمرنا.'] },
    { 'en': ['I', 'am', 'thinking', 'of', 'going', 'to', 'the', 'mountains.'], 'ar': ['أفكر', 'في', 'الذهاب', 'إلى', 'الجبال.'] },
    { 'en': ["I'll", 'visit', 'you', 'tomorrow.'], 'ar': ['سأزورك', 'غداً.'] },
    { 'en': ['Nobody', 'was', 'injured.'], 'ar': ['لم', 'يصب', 'أحد', 'بأذى.'] },
    { 'en': ['May', 'I', 'go', 'to', 'the', 'river?'], 'ar': ['أيمكنني', 'الذهاب', 'إلى', 'النهر؟'] },
    { 'en': ['What', 'a', 'beautiful', 'garden!'], 'ar': ['ما', 'أجمل', 'هذه', 'الحديقة.'] },
    { 'en': ['Tom', "hasn't", 'written', 'to', 'Mary', 'in', 'a', 'long', 'time.'], 'ar': ['لم', 'يراسل', 'توم', 'ماري', 'منذ', 'زمن', 'بعيد.'] },
    { 'en': ['He', "isn't", 'here,', 'is', 'he?'], 'ar': ['إنه', 'ليس', 'هنا', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['What', 'happens', 'if', 'we', 'get', 'caught?'], 'ar': ['ما', 'الذي', 'سيحدث', 'إن', 'قُبض', 'علينا؟'] },
    { 'en': ['Watch', 'the', 'rear.'], 'ar': ['راقب', 'الجزء', 'الخلفي', '.'] },
    { 'en': ['She', 'has', 'already', 'left', 'the', 'office.'], 'ar': ['غادرت', 'المكتب', 'منذ', 'فترة.'] },
    { 'en': ['Are', 'you', 'Chinese', 'or', 'Japanese?'], 'ar': ['هل', 'أنت', 'صيني', 'أم', 'ياباني؟'] },
    { 'en': ['You', 'can', 'call', 'me', 'Tom.'], 'ar': ['أنتِ', 'يمكنكِ', 'أن', 'تناديني', 'توم.'] },
    { 'en': ['What', 'does', 'he', 'want?'], 'ar': ['ماذا', 'يريد؟'] },
    { 'en': ['He', 'is', 'deeply', 'in', 'debt', 'now.'], 'ar': ['إنه', 'غارق', 'في', 'الديون.'] },
    { 'en': ["What's", 'happening?'], 'ar': ['ماذا', 'حصل؟'] },
    { 'en': ["Don't", 'worry.', 'You', 'can', 'count', 'on', 'me.'], 'ar': ['لا', 'تقلق.', 'يمكنك', 'الإعتماد', 'علي.'] },
    { 'en': ['At', 'first,', 'it', 'is', 'difficult.'], 'ar': ['إنها', 'صعبة', 'في', 'البداية.'] },
    { 'en': ['Next', 'spring', 'I', 'want', 'to', 'graduate.'], 'ar': ['أريد', 'أن', 'أتخرج', 'الربيع', 'القادم.'] },
    { 'en': ['Accidents', 'do', 'happen.'], 'ar': ['إنّ', 'الحوادث', 'تحدث.'] },
    { 'en': ["It's", 'artificial.'], 'ar': ['إنها', 'صناعية.'] },
    { 'en': ['You', 'were', 'late', 'for', 'work.'], 'ar': ['لقد', 'تأخرت', 'عن', 'العمل.'] },
    { 'en': ["You're", 'a', 'patient', 'man.'], 'ar': ['أنت', 'رجل', 'صبور.'] },
    { 'en': ['I', 'swear,', 'I', "didn't", 'do', 'anything.'], 'ar': ['أقسم', 'أنني', 'لم', 'أفعل', 'أي', 'شيء.'] },
    { 'en': ['You', 'have', 'no', 'fever.'], 'ar': ['ليس', 'لديك', 'أي', 'حُمّى.'] },
    { 'en': ['Your', 'dog', 'is', 'here.'], 'ar': ['هنا', 'كلبك.'] },
    { 'en': ["I'm", 'starving!'], 'ar': ['أنا', 'في', 'غاية', 'الجوع.'] },
    { 'en': ['These', 'are', 'my', 'brothers.'], 'ar': ['هؤلاء', 'إخوتي'] },
    { 'en': ['I', 'want', 'a', 'personal', 'computer.'], 'ar': ['أريد', 'حاسوبًا', 'شخصيًّا.'] },
    { 'en': ['I', "don't", 'love', 'you.'], 'ar': ['أنا', 'لا', 'أحبك'] },
    { 'en': ['Let', 'me', 'know', 'if', 'you', 'hear', 'anything.'], 'ar': ['أعلميني', 'إن', 'سمعتِ', 'شيئًا.'] },
    { 'en': ['The', 'city', 'was', 'destroyed', 'by', 'fire.'], 'ar': ['دمر', 'الحريق', 'المدينة.'] },
    { 'en': ["I'm", 'going', 'to', 'take', 'a', 'bath.'], 'ar': ['سأستحِمّ.'] },
    { 'en': ['He', 'seldom', 'gives', 'his', 'wife', 'presents.'], 'ar': ['نادرًا', 'ما', 'يهدي', 'زوجته.'] },
    { 'en': ['I', 'looked', 'around', 'me.'], 'ar': ['نظرت', 'حولي.'] },
    { 'en': ["Don't", 'you', 'play', 'tennis?'], 'ar': ['ألا', 'تلعب', 'التنس؟'] },
    { 'en': ['The', 'castle', 'is', 'beautiful.'], 'ar': ['هذه', 'القلعة', 'جميلة.'] },
    { 'en': ['He', 'loves', 'trains.'], 'ar': ['إنه', 'يحب', 'القطارات.'] },
    { 'en': ['I', 'feel', 'dizzy', 'every', 'time', 'I', 'stand', 'up.'], 'ar': ['أشعر', 'بالدوار', 'كلما', 'وقفت.'] },
    { 'en': ['Choose', 'whichever', 'you', 'like.'], 'ar': ['اختر', 'ما', 'تريد.'] },
    { 'en': ['He', 'decided', 'to', 'be', 'a', 'lawyer.'], 'ar': ['قرر', 'أن', 'يصبح', 'محامياً'] },
    { 'en': ['Bring', 'me', "today's", 'paper,', 'please.'], 'ar': ['من', 'فضلك', 'أحضر', 'لي', 'جريدة', 'اليوم.'] },
    { 'en': ['We', 'took', 'turns', 'driving.'], 'ar': ['تبادلنا', 'الأدوار', 'في', 'القيادة.'] },
    { 'en': ['Everyone', 'laughed', 'at', 'him.'], 'ar': ['الكل', 'ضحك', 'عليه.'] },
    { 'en': ['It', 'was', 'at', 'school.'], 'ar': ['كان', 'في', 'المدرسة.'] },
    { 'en': ['I', 'doubt', 'his', 'story.'], 'ar': ['أنا', 'أشكّ', 'بروايته', '.'] },
    { 'en': ['It', 'was', 'like', 'Christmas', 'morning.'], 'ar': ['كانت', 'كصباح', 'الكرسمس'] },
    { 'en': ["Shouldn't", 'you', 'be', 'getting', 'ready', 'for', 'work?'], 'ar': ['ألا', 'يفترض', 'بك', 'أن', 'تستعد', 'للعمل؟'] },
    { 'en': ['Would', 'you', 'please', 'pour', 'me', 'a', 'cup', 'of', 'coffee?'], 'ar': ['هل', 'لك', 'أن', 'تصبّ', 'لي', 'كأساً', 'من', 'القهوة', 'من', 'فضلك؟'] },
    { 'en': ["Where's", 'the', 'nearest', 'drugstore?'], 'ar': ['أين', 'أقرب', 'صيدلية؟'] },
    { 'en': ['I', 'want', 'to', 'be', 'alone!'], 'ar': ['أريدُ', 'أن', 'أكونَ', 'وحيداً!'] },
    { 'en': ["You'd", 'better', 'go.'], 'ar': ['من', 'الأفضل', 'لك', 'أن', 'تغادر.'] },
    { 'en': ['Will', 'he', 'ever', 'come', 'back?'], 'ar': ['هل', 'سيعود', 'يوماً', 'ما؟'] },
    { 'en': ['The', 'house', 'is', 'said', 'to', 'be', 'haunted.'], 'ar': ['قيل', 'أن', 'هذا', 'المنزل', 'مسكون', 'بالأشباح.'] },
    { 'en': ['As', 'far', 'as', 'I', 'know,', "he's", 'an', 'excellent', 'student.'], 'ar': ['على', 'حد', 'علمي،', 'هو', 'طالب', 'ممتاز.'] },
    { 'en': ["That's", 'a', 'hotel.'], 'ar': ['ذلك', 'فندقٌ.'] },
    { 'en': ['Do', 'you', 'remember', 'when', 'I', 'saw', 'you', 'last?'], 'ar': ['هل', 'تذكر', 'متى', 'رأيتك', 'آخر', 'مرة؟'] },
    { 'en': ['I', 'like', 'it.'], 'ar': ['هذا', 'يعجبني.'] },
    { 'en': ["I've", 'got', 'to', 'speak', 'to', 'Tom.'], 'ar': ['أنا', 'تحدثت', 'مع', 'توم.'] },
    { 'en': ['The', 'competition', 'is', 'fierce.'], 'ar': ['المنافسة', 'قوية.'] },
    { 'en': ['The', 'plane', 'crashed', 'into', 'the', 'house.'], 'ar': ['تحطّمت', 'الطّائرة', 'على', 'المنزل.'] },
    { 'en': ['Which', 'color', 'do', 'you', 'like', 'more,', 'blue', 'or', 'red?'], 'ar': ['أي', 'لون', 'تحب', 'أكثر؟', 'الأزرق', 'أم', 'الأحمر؟'] },
    { 'en': ['I', 'think', "you're", 'right.'], 'ar': ['أعتقد', 'أنك', 'صائب.'] },
    { 'en': ['How', 'are', 'you?'], 'ar': ['كيف', 'حالك؟'] },
    { 'en': ['I', 'bought', 'two', 'bottles', 'of', 'milk.'], 'ar': ['اِشتريت', 'زجاجتَي', 'حليب.'] },
    { 'en': ['Tom', "isn't", 'ready', 'yet.'], 'ar': ['توم', 'ليس', 'مستعداً', 'بعد.'] },
    { 'en': ['If', 'you', 'were', 'in', 'my', 'place,', 'what', 'would', 'you', 'do?'], 'ar': ['ماذا', 'كنتِ', 'ستفعَلينَ', 'لو', 'كنتِ', 'في', 'مكاني؟'] },
    { 'en': ['The', 'students', 'learned', 'many', 'poems', 'by', 'heart.'], 'ar': ['حفظ', 'الطلبة', 'الكثير', 'من', 'القصائد.'] },
    { 'en': ['We', 'discussed', 'the', 'plan', 'with', 'him.'], 'ar': ['ناقشنا', 'الخطة', 'معه.'] },
    { 'en': ["I've", 'shut', 'all', 'six', 'windows.'], 'ar': ['أقفلت', 'النوافذ', 'الستة', 'جميعها.'] },
    { 'en': ['She', 'has', 'five', 'older', 'brothers.'], 'ar': ['لديها', 'خمسة', 'إخوان', 'كبار.'] },
    { 'en': ["Here's", 'the', 'bus.'], 'ar': ['ها', 'هو', 'الباص.'] },
    { 'en': ["Don't", 'use', 'my', 'pen.'], 'ar': ['لا', 'تستعمل', 'قلمي.'] },
    { 'en': ['One', 'must', 'do', "one's", 'best.'], 'ar': ['على', 'المرء', 'أن', 'يفعل', 'كلّ', 'ما', 'بوسعه.'] },
    { 'en': ['I', "don't", 'need', 'that.'], 'ar': ['لا', 'أحتاج', 'لهذا.'] },
    { 'en': ['We', 'want', 'peace', 'in', 'the', 'world.'], 'ar': ['نريد', 'السلام', 'في', 'العالم.'] },
    { 'en': ['She', 'says', 'she', 'will', 'call', 'you', 'later.'], 'ar': ['قالت', 'بأنها', 'ستتصل', 'بك', 'لاحقاً.'] },
    { 'en': ['Have', 'you', 'ever', 'played', 'baseball?'], 'ar': ['هل', 'جربت', 'أن', 'تلعب', 'كرة', 'القاعدة', 'من', 'قبل؟'] },
    { 'en': ['He', 'can', 'do', 'it', 'better', 'than', 'me.'], 'ar': ['هو', 'يستطيع', 'أن', 'يفعلها', 'أحسن', 'مني.'] },
    { 'en': ['She', 'asked', 'him', 'for', 'help.'], 'ar': ['طلبت', 'منه', 'المساعدة.'] },
    { 'en': ["I'm", 'just', 'a', 'plain', 'old', 'office', 'worker.'], 'ar': ['أنا', 'مجرد', 'موظف', 'كأي', 'موظف.'] },
    { 'en': ['Paris', 'is', 'the', 'capital', 'of', 'France.'], 'ar': ['باريس', 'هي', 'عاصمة', 'فرنسا.'] },
    { 'en': ["It's", 'impossible', 'to', 'learn', 'English', 'in', 'a', 'month.'], 'ar': ['من', 'المستحيل', 'تعلم', 'الإنجليزية', 'في', 'شهر.'] },
    { 'en': ["I've", 'worked', 'here', 'for', 'many', 'years.'], 'ar': ['لقد', 'عملت', 'هنا', 'لسنوات', 'عديدة.'] },
    { 'en': ['We', 'do', 'not', 'know', 'her.'], 'ar': ['نحن', 'لا', 'نعرفها'] },
    { 'en': ['They', 'live', 'in', 'the', 'same', 'state.'], 'ar': ['هُم', 'يعيشون', 'فى', 'نفس', 'الولاية.'] },
    { 'en': ['A', 'drowning', 'man', 'will', 'catch', 'at', 'a', 'straw.'], 'ar': ['الغريق', 'يتعلق', 'بقشة.'] },
    { 'en': ['My', 'wife', 'catches', 'colds', 'easily.'], 'ar': ['تُصاب', 'زوجتي', 'بالبرد', 'بسهولة.'] },
    { 'en': ['This', 'play', 'has', 'ended.'], 'ar': ['إنتهت', 'المسرحية.'] },
    { 'en': ['Tom', 'took', 'one', 'of', 'his', 'gloves', 'off.'], 'ar': ['خلع', 'توم', 'إحدى', 'قفازيه'] },
    { 'en': ['I', 'think', 'this', 'table', 'takes', 'up', 'too', 'much', 'space.'], 'ar': ['أعتقد', 'أن', 'هذه', 'الطاولة', 'تأخذ', 'مساحة', 'كبيرة.'] },
    { 'en': ['Your', 'English', 'is', 'improving.'], 'ar': ['إنجليزيتك', 'تتحسن.'] },
    { 'en': ['Those', 'flowers', 'have', 'died.'], 'ar': ['ذبلت', 'الأزهار.'] },
    { 'en': ['How', 'fascinating!'], 'ar': ['كم', 'هذا', 'رائع!.'] },
    { 'en': ['What', 'did', 'you', 'do', 'yesterday?'], 'ar': ['ماذا', 'فَعَلتَ', 'بِالأمس؟'] },
    { 'en': ['He', 'is', 'a', 'fast', 'speaker.'], 'ar': ['إنه', 'سريع', 'الكلام.'] },
    { 'en': ['My', 'wife', 'is', 'afraid', 'to', 'drive', 'my', 'new', 'car.'], 'ar': ['زوجتي', 'خائفة', 'من', 'قيادة', 'سيارة', 'جديدة.'] },
    { 'en': ["It's", 'time', 'to', 'go.'], 'ar': ['حان', 'وقت', 'الذهاب.'] },
    { 'en': ['This', 'book', 'is', 'easy', 'for', 'me', 'to', 'read.'], 'ar': ['هذا', 'الكتاب', 'سهل', 'علي', 'قراءته.'] },
    { 'en': ["Don't", 'give', 'up', 'yet.', "I'm", 'sure', 'you', 'can', 'do', 'it.'], 'ar': ['لا', 'تستسلم،', 'أنا', 'متأكد/متأكدة', 'أنك', 'تستطيع/تستطيعين', 'فعل', 'ذلك.'] },
    { 'en': ['He', 'always', 'tells', 'the', 'truth.'], 'ar': ['دائماً', 'ما', 'يصدق.'] },
    { 'en': ['Is', 'he', 'American?'], 'ar': ['أهو', 'أمريكي؟'] },
    { 'en': ['He', 'is', 'a', 'potential', 'world', 'champion.'], 'ar': ['بإمكانه', 'أن', 'يصبح', 'بطلاً', 'للعالم.'] },
    { 'en': ['We', "don't", 'know', 'him.'], 'ar': ['نحن', 'لا', 'نعرف', 'عنه', 'شيئاً.'] },
    { 'en': ['She', 'told', 'me', 'that', 'she', 'wanted', 'a', 'pet', 'dog.'], 'ar': ['أخبرتني', 'أنها', 'تريد', 'كلباً', 'أليفاً.'] },
    { 'en': ['My', 'father', 'is', 'a', 'doctor.'], 'ar': ['أبي', 'طبيب.'] },
    { 'en': ['School', 'begins', 'at', '8:30', 'a.m.'], 'ar': ['يبدأ', 'الدوام', 'الدراسي', 'في', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ['She', 'left', 'her', 'gloves', 'in', 'the', 'car.'], 'ar': ['تركت', 'قفازيها', 'في', 'السيارة.'] },
    { 'en': ['You', 'need', 'this.'], 'ar': ['ستحتاج', 'إلى', 'ذلك.'] },
    { 'en': ["I'm", 'one', 'of', 'the', 'good', 'guys.'], 'ar': ['أنا', 'أحد', 'الرجال', 'الصالحين.'] },
    { 'en': ['We', 'are', 'men.'], 'ar': ['إننا', 'رجال.'] },
    { 'en': ['This', 'is', 'a', 'joke.'], 'ar': ['هذه', 'نكتة.'] },
    { 'en': ['Almost', 'no', 'one', 'believed', 'her.'], 'ar': ['لم', 'يصدقها', 'أحد', 'البتّة.'] },
    { 'en': ['Does', 'this', 'look', 'familiar', 'to', 'you?'], 'ar': ['هل', 'يبدو', 'هذا', 'مألوفا', 'لك؟'] },
    { 'en': ["You're", 'such', 'a', 'good', 'friend.'], 'ar': ['أنت', 'صديق', 'رائع.'] },
    { 'en': ['Welcome', 'home.'], 'ar': ['مرحباً', 'بك', 'في', 'المنزل.'] },
    { 'en': ['We', 'still', 'have', 'to', 'do', 'that.'], 'ar': ['ما', 'زال', 'يتوجب', 'علينا', 'فعل', 'ذلك.'] },
    { 'en': ['Muammar', 'Kaddafi', 'escaped', 'unharmed.'], 'ar': ['هرب', 'معمر', 'القذافي', 'سليمًا.'] },
    { 'en': ['How', 'nice', 'of', 'you!'], 'ar': ['كم', 'رائع', 'أنتَ!'] },
    { 'en': ['Can', 'you', 'take', 'us', 'to', 'the', 'British', 'Museum?'], 'ar': ['أيمكنك', 'أخذنا', 'إلى', 'المتحف', 'البريطاني؟'] },
    { 'en': ['I', 'got', 'it.'], 'ar': ['فهمتُها.'] },
    { 'en': ['Take', 'it', 'easy.'], 'ar': ['لا', 'تحمل', 'هماً.'] },
    { 'en': ['She', 'is', 'learning', 'how', 'to', 'drive', 'a', 'car.'], 'ar': ['إنها', 'تتعلم', 'قيادة', 'السيارة.'] },
    { 'en': ['I', "can't", 'play', 'piano.'], 'ar': ['لا', 'أستطيع', 'عزفَ', 'البيانو.'] },
    { 'en': ['You', 'have', 'some', 'books.'], 'ar': ['لديك', 'بعض', 'الكتب.'] },
    { 'en': ['That', 'old', 'woman', 'lives', 'by', 'herself.'], 'ar': ['تلك', 'المرأة', 'العجوز', 'تسكن', 'بمفردها.'] },
    { 'en': ['Who', 'are', 'those', 'girls?'], 'ar': ['من', 'هذه', 'الفتيات؟'] },
    { 'en': ['Flowers', 'bloom.'], 'ar': ['الأزهار', 'تتفتح.'] },
    { 'en': ['I', "don't", 'have', 'a', 'solution.'], 'ar': ['لا', 'حل', 'لدي.'] },
    { 'en': ['I', 'like', 'dogs,', 'but', 'my', 'sister', 'likes', 'cats.'], 'ar': ['أنا', 'أُحب', 'الكلاب,', 'ولكن', 'أُختي', 'تحب', 'القطط.'] },
    { 'en': ['I', 'prefer', 'going', 'out', 'to', 'staying', 'at', 'home.'], 'ar': ['أفضل', 'الخروج', 'على', 'البقاء', 'في', 'البيت.'] },
    { 'en': ['They', 'gave', 'it', 'to', 'me.'], 'ar': ['هم', 'أعطوها', 'لي'] },
    { 'en': ['How', 'lucky', 'you', 'are!'], 'ar': ['يا', 'لك', 'من', 'محظوظ!'] },
    { 'en': ['That', 'was', 'what', 'Tom', 'told', 'me.'], 'ar': ['هذا', 'ما', 'قاله', 'توم', 'لي.'] },
    { 'en': ['Please', 'tell', 'her', 'to', 'call', 'me', 'back.'], 'ar': ['بلغها', 'أن', 'تعاود', 'الإتصال', 'بي', 'من', 'فضلك.'] },
    { 'en': ['A', 'dog', 'is', 'a', "man's", 'best', 'friend.'], 'ar': ['الكلب', 'صديق', 'وفي', 'للإنسان.'] },
    { 'en': ['People', 'sometimes', 'compare', 'death', 'to', 'sleep.'], 'ar': ['يقارن', 'الناس', 'أحيانًا', 'بين', 'الموت', 'والنوم.'] },
    { 'en': ['I', 'want', 'you', 'to', 'try', 'it.'], 'ar': ['أريدُك', 'أن', 'تجرّب', 'ذلِكَ.'] },
    { 'en': ['An', 'uncle', 'of', 'mine', 'is', 'a', 'doctor.'], 'ar': ['أحد', 'أعمامي', 'طبيب.'] },
    { 'en': ['He', 'claimed', 'he', 'knew', 'you', 'well.'], 'ar': ['زعم', 'أنه', 'يعرفك', 'جيدا.'] },
    { 'en': ['Hands', 'off.'], 'ar': ['لا', 'تلمسني.'] },
    { 'en': ['Come', 'sit', 'with', 'us.'], 'ar': ['تعال', 'و', 'اجلس', 'معنا.'] },
    { 'en': ['The', 'police', 'set', 'out', 'to', 'solve', 'the', 'crime.'], 'ar': ['الشرطة', 'انطلقت', 'لتحل', 'الجريمة'] },
    { 'en': ["He's", 'very', 'ill.'], 'ar': ['إنه', 'مريض', 'جداً.'] },
    { 'en': ['I', 'will', 'help', 'him', 'tomorrow.'], 'ar': ['سأساعده', 'غداً.'] },
    { 'en': ['I', "can't", 'explain', 'it', 'either.'], 'ar': ['لا', 'يمكنني', 'شرحهُ', 'أكثر.'] },
    { 'en': ['Make', 'a', 'sentence', 'with', 'each', 'of', 'these', 'words.'], 'ar': ['ألف', 'جملة', 'لكلٍ', 'من', 'هذه', 'الكلمات.'] },
    { 'en': ["Where's", 'the', 'nearest', 'hotel?'], 'ar': ['أين', 'أقرب', 'فندق؟'] },
    { 'en': ['He', 'is', 'French.'], 'ar': ['إنه', 'فرنسي.'] },
    { 'en': ["Here's", 'the', 'bill.'], 'ar': ['تفضل', 'الحساب'] },
    { 'en': ['You', 'may', 'go', 'anywhere.'], 'ar': ['يمكنك', 'الذهاب', 'إلى', 'أين', 'تشاء.'] },
    { 'en': ['When', 'shall', 'we', 'have', 'the', 'party?'], 'ar': ['متى', 'سنقيم', 'الحفلة؟'] },
    { 'en': ['What', 'will', 'become', 'of', 'Japan?'], 'ar': ['ماذا', 'سيحصل', 'لليابان؟'] },
    { 'en': ['Tom', 'and', 'Mary', 'live', 'in', 'a', 'house', 'by', 'the', 'river.'], 'ar': ['يعيش', 'توم', 'وماري', 'في', 'بيت', 'بمحاذاة', 'نهر'] },
    { 'en': ['Its', 'color', 'is', 'red.'], 'ar': ['لونه', 'أحمر.'] },
    { 'en': ['Why', 'did', 'you', 'abandon', 'me?'], 'ar': ['لم', 'تركتني؟'] },
    { 'en': ['Listen', 'up.'], 'ar': ['اسمع'] },
    { 'en': ['Can', 'you', 'guess', 'what', 'I', 'have?'], 'ar': ['أيمكنك', 'أن', 'تحزر', 'ما', 'لديّ؟'] },
    { 'en': ['When', 'will', 'you', 'be', 'back?'], 'ar': ['متى', 'سترجع؟'] },
    { 'en': ['Tom', 'unfolded', 'the', 'blanket.'], 'ar': ['مد', 'توم', 'الغطاء.'] },
    { 'en': ['What', 'do', 'you', 'want', 'now?'], 'ar': ['ما', 'الذي', 'تريده', 'الآن؟'] },
    { 'en': ['I', "don't", 'care', 'about', 'profit.'], 'ar': ['أنا', 'لا', 'أهتم', 'للربح'] },
    { 'en': ['When', 'does', 'it', 'begin?'], 'ar': ['متى', 'تبدأ؟'] },
    { 'en': ['The', 'box', 'is', 'almost', 'empty.'], 'ar': ['الصندوق', 'فارغ', 'تقريبا'] },
    { 'en': ['No', 'one', 'lives', 'here', 'anymore.'], 'ar': ['لا', 'أحد', 'يعيش', 'هنا', 'بعد', 'الآن.'] },
    { 'en': ['You', 'need', 'to', 'wait.'], 'ar': ['يجب', 'ان', 'تنتظر.'] },
    { 'en': ['I', 'thought', 'that', "you'd", 'never', 'come', 'here.'], 'ar': ['اعتقدت', 'انك', 'لن', 'تأتي', 'الى', 'هنا'] },
    { 'en': ['Did', 'you', 'accept', 'his', 'invitation?'], 'ar': ['هل', 'قبلت', 'دعوته؟'] },
    { 'en': ['How', 'about', 'playing', 'tennis', 'next', 'Saturday?'], 'ar': ['ما', 'رأيك', 'بلعب', 'التنس', 'السبت', 'القادم؟'] },
    { 'en': ['What', 'kind', 'of', 'fruit', 'do', 'you', 'want?'], 'ar': ['أي', 'نوع', 'من', 'الفواكه', 'تريد؟'] },
    { 'en': ['Who', 'do', 'you', 'think', 'you', 'are?'], 'ar': ['من', 'تظن', 'نفسك؟'] },
    { 'en': ['I', 'did', 'see', 'him.'], 'ar': ['لقد', 'قابلته.'] },
    { 'en': ['"Sit', 'down,', 'please,"', 'he', 'said.'], 'ar': ['قال', 'له:', '"تفضّل', 'بالجلوس', 'إذا', 'سمحت".'] },
    { 'en': ["He's", 'not', 'breaking', 'the', 'law.'], 'ar': ['هو', 'لا', 'يخرق', 'القانون.'] },
    { 'en': ['Get', 'back,', 'get', 'back!'], 'ar': ['إرجع!'] },
    { 'en': ["It's", 'fairly', 'warm', 'today.'], 'ar': ['الجو', 'دافئ', 'بعض', 'الشيء', 'اليوم.'] },
    { 'en': ['Speak', 'louder,', 'please.'], 'ar': ['رجاء،', 'تكلم', 'بصوت', 'أعلى.'] },
    { 'en': ['A', 'lion', 'is', 'an', 'animal.'], 'ar': ['الأسد', 'حيوان.'] },
    { 'en': ['We', 'talked', 'until', 'two', 'in', 'the', 'morning.'], 'ar': ['تحدثنا', 'حتى', 'الثانية', 'صباحاً', '.'] },
    { 'en': ['How', 'many', 'kids', 'do', 'you', 'have?'], 'ar': ['كم', 'ولداً', 'لديك؟'] },
    { 'en': ['He', 'talked', 'about', 'her', 'illness.'], 'ar': ['تحدّث', 'عن', 'مرضها.'] },
    { 'en': ['She', 'has', 'blue', 'eyes.'], 'ar': ['عيناها', 'زرقاوتان.'] },
    { 'en': ['What', 'time', 'will', 'the', 'flight', 'arrive', 'in', 'Tokyo?'], 'ar': ['متى', 'ستصل', 'الطائرة', 'إلى', 'طوكيو؟'] },
    { 'en': ['I', 'remained', 'until', 'Tom', 'arrived.'], 'ar': ['بقيت', 'حتى', 'وصل', 'توم.'] },
    { 'en': ['Come', 'on', 'in.'], 'ar': ['هيا', 'ادخل'] },
    { 'en': ['I', 'need', 'medical', 'help.'], 'ar': ['أحتاج', 'مساعدة', 'طبية.'] },
    { 'en': ['I', "don't", 'know', 'her.'], 'ar': ['أنا', 'لا', 'أعرفها'] },
    { 'en': ['Tom', "won't", 'change', 'his', 'opinion.'], 'ar': ['توم', 'لن', 'يغير', 'رأيه.'] },
    { 'en': ['He', 'pretended', 'that', 'he', 'was', 'a', 'lawyer.'], 'ar': ['تظاهر', 'بأنه', 'محامٍ.'] },
    { 'en': ['It', 'seems', 'to', 'me', 'that', 'you', 'are', 'honest.'], 'ar': ['يبدو', 'لي', 'أنكَ', 'صادق.'] },
    { 'en': ['Be', 'careful', 'when', 'you', 'cross', 'a', 'road.'], 'ar': ['التمس', 'الحذر', 'وأنت', 'تعبر', 'الطريق.'] },
    { 'en': ['I', 'am', 'busy', 'now.'], 'ar': ['أنا', 'مشغولة', 'الأن.'] },
    { 'en': ['This', 'house', 'and', 'this', 'land', 'are', 'mine.'], 'ar': ['هذا', 'بيتي', 'وهذه', 'أرضي!'] },
    { 'en': ['Japan', 'is', 'not', 'as', 'large', 'as', 'Canada.'], 'ar': ['ليست', 'اليابان', 'بحجم', 'كندا.'] },
    { 'en': ['We', 'sometimes', 'make', 'mistakes.'], 'ar': ['أحياناً', 'نخطئ.'] },
    { 'en': ['Does', 'Tom', 'speak', 'French,', 'too?'], 'ar': ['هل', 'يتحدث', 'توم', 'الفرنسية', 'ايضا؟'] },
    { 'en': ['Old', 'habits', 'die', 'hard.'], 'ar': ['الطَبعْ', 'يغلبُ', 'التطبّع.'] },
    { 'en': ['My', 'father', 'made', 'me', 'wash', 'the', 'car.'], 'ar': ['أرغمني', 'أبي', 'على', 'غسل', 'السيارة.'] },
    { 'en': ['Do', 'you', 'go', 'to', 'school', 'by', 'bus?'], 'ar': ['هل', 'تذهب', 'إلى', 'المدرسة', 'بالباص؟'] },
    { 'en': ['My', 'son', 'is', 'going', 'to', 'be', 'a', 'teacher.'], 'ar': ['سيصبح', 'ابني', 'معلماً.'] },
    { 'en': ['The', 'meat', 'tastes', 'bad.'], 'ar': ['طعم', 'اللحم', 'سيء.'] },
    { 'en': ['Sit', 'down,', 'please.'], 'ar': ['اِجلس', 'من', 'فضلك.'] },
    { 'en': ['He', 'really', 'ticked', 'me', 'off.'], 'ar': ['هو', 'اغضبني', 'حقاً', '.'] },
    { 'en': ["It's", 'Monday.'], 'ar': ['إنه', 'يوم', 'الاثنين'] },
    { 'en': ['Tom', 'told', 'me', 'not', 'to', 'go', 'into', 'the', 'cave.'], 'ar': ['توم', 'أخبرني', 'بأن', 'لا', 'أدخل', 'المغارة.'] },
    { 'en': ['I', "don't", 'like', 'you', 'anymore.'], 'ar': ['لم', 'أعد', 'أحبّك', 'بعد', 'الآن.'] },
    { 'en': ['We', 'agreed', 'to', 'share', 'the', 'housework.'], 'ar': ['اتفقنا', 'على', 'توزيع', 'مهام', 'تنظيف', 'البيت', 'فيما', 'بيننا.'] },
    { 'en': ['Where', 'was', 'the', 'mistake?'], 'ar': ['أين', 'كان', 'الخطأ؟'] },
    { 'en': ['Is', 'this', 'your', 'bicycle?'], 'ar': ['أهذه', 'دراجتك؟'] },
    { 'en': ['We', 'appreciate', 'your', 'help.'], 'ar': ['نُقَدِّر', 'مساعدتك.'] },
    { 'en': ['Run', 'as', 'fast', 'as', 'possible.'], 'ar': ['اركض', 'بأقصى', 'سرعة.'] },
    { 'en': ["We're", 'very', 'worried', 'about', 'you.'], 'ar': ['قلقلنا', 'من', 'أجلك', 'كثيرا.'] },
    { 'en': ['She', 'helped', 'the', 'old', 'man', 'cross', 'the', 'road.'], 'ar': ['لقد', 'ساعدتْ', 'الرجل', 'العجوز', 'على', 'عبور', 'الطريق.'] },
    { 'en': ['How', 'long', 'are', 'you', 'here', 'for?'], 'ar': ['منذ', 'متى', 'وأنت', 'هنا؟'] },
    { 'en': ['The', 'fire', 'started', 'in', 'the', 'kitchen.'], 'ar': ['بدأت', 'النار', 'فى', 'المطبخ'] },
    { 'en': ['His', 'bicycle', 'is', 'blue.'], 'ar': ['دراجته', 'زرقاء.'] },
    { 'en': ['She', 'is', 'eating.'], 'ar': ['إنها', 'تأكل.'] },
    { 'en': ["Tom's", 'not', 'home.'], 'ar': ['توم', 'ليس', 'في', 'البيت'] },
    { 'en': ['Make', 'your', 'choice.'], 'ar': ['اتخذ', 'خياراً.'] },
    { 'en': ["Who's", 'coming', 'with', 'me?'], 'ar': ['من', 'سيأتي', 'معي؟'] },
    { 'en': ["You're", 'not', 'ready', 'yet.'], 'ar': ['أنتَ', 'لستُ', 'جاهزاً', 'بعد.'] },
    { 'en': ['You', 'should', 'begin', 'right', 'away.'], 'ar': ['عليك', 'أن', 'تبدأ', 'حالاً.'] },
    { 'en': ['Do', 'you', 'hear', 'what', "I'm", 'saying?'], 'ar': ['هل', 'تسمع', 'ما', 'أقول', '؟'] },
    { 'en': ['I', "should've", 'done', 'this', 'months', 'ago.'], 'ar': ['كان', 'عليّ', 'فعل', 'هذا', 'منذ', 'أشهر', 'خلت.'] },
    { 'en': ['Do', 'you', 'remember', 'the', 'town', 'where', 'he', 'was', 'born?'], 'ar': ['هل', 'تذكر', 'المدينة', 'الذي', 'ولد', 'بها؟'] },
    { 'en': ['What', 'did', 'you', 'do', 'with', 'that', 'camera?'], 'ar': ['ماذا', 'فعلت', 'بتلك', 'الكاميرا؟'] },
    { 'en': ['The', 'car', 'crashed', 'into', 'the', 'truck.'], 'ar': ['إصطدمت', 'السيارة', 'بالشاحنة.'] },
    { 'en': ['Why', 'are', 'you', 'doing', 'this?'], 'ar': ['لماذا', 'تفعل', 'هذا؟'] },
    { 'en': ['He', 'likes', 'coffee', 'without', 'sugar.'], 'ar': ['إنه', 'يحب', 'القهوة', 'من', 'دون', 'سكر.'] },
    { 'en': ['Tom', 'got', 'soaking', 'wet.'], 'ar': ['ابتل', 'توم', 'بشدة.'] },
    { 'en': ['You', 'and', 'I', 'are', 'the', 'same', 'age.'], 'ar': ['أنا', 'و', 'أنت', 'في', 'نفس', 'العمر.'] },
    { 'en': ['We', 'took', 'the', 'enemy', 'by', 'surprise.'], 'ar': ['فاجأنا', 'العدو.'] },
    { 'en': ['Mom,', "Tom's", 'hitting', 'me!'], 'ar': ['أُمي,', 'توم', 'يضربني.'] },
    { 'en': ['This', 'book', 'is', 'older', 'than', 'that', 'one.'], 'ar': ['هذا', 'الكتاب', 'أقدم', 'من', 'ذاك.'] },
    { 'en': ['I', 'know', 'Tom', "wasn't", 'joking.'], 'ar': ['اعرف', 'ان', 'توم', 'لم', 'يكن', 'يمزح'] },
    { 'en': ["I'm", 'studying', 'English.'], 'ar': ['أدرس', 'اللغة', 'الإنجليزية.'] },
    { 'en': ['They', 'lost', 'the', 'battle.'], 'ar': ['خسروا', 'المعركة.'] },
    { 'en': ['You', 'ought', 'to', 'have', 'come', 'here', 'earlier.'], 'ar': ['كأن', 'عليك', 'أن', 'تأتي', 'إلى', 'هنا', 'مبكراً.'] },
    { 'en': ['"Can', 'somebody', 'help', 'me?"', '"I', 'will."'], 'ar': ['"هل', 'يستطيع', 'أحدٌ', 'مساعدتي؟"', '"سأفعل".'] },
    { 'en': ['This', 'afternoon', 'we', 'will', 'have', 'an', 'interview.'], 'ar': ['بعد', 'ظُهر', 'هذا', 'اليوم', 'سيكون', 'لدينا', 'مقابلة.'] },
    { 'en': ['I', "don't", 'need', 'your', 'help.'], 'ar': ['لا', 'أحتاج', 'إلى', 'مساعدتك.'] },
    { 'en': ['There', 'were', 'three', 'people', 'waiting', 'in', 'front', 'me.'], 'ar': ['كان', 'هناك', 'ثلاثة', 'ينتظرون', 'أمامي.'] },
    { 'en': ['Will', 'you', 'drink', 'another', 'cup', 'of', 'coffee?'], 'ar': ['هل', 'تشرب', 'كأسًا', 'آخر', 'من', 'القهوة.'] },
    { 'en': ["I'll", 'bring', 'you', 'the', 'bill', 'immediately.'], 'ar': ['سأجلب', 'لك', 'الفاتورة', 'حالًا.'] },
    { 'en': ['He', 'can', 'also', 'speak', 'French.'], 'ar': ['يستطيع', 'تحدُّثَ', 'الفرنسية', 'أيضاً.'] },
    { 'en': ['Where', 'can', 'I', 'exchange', 'money?'], 'ar': ['أين', 'يمكننى', 'تبادل', 'النقود؟'] },
    { 'en': ['Can', 'I', 'go', 'swimming,', 'Mother?'], 'ar': ['هل', 'لي', 'أن', 'أذهب', 'للسباحة', 'يا', 'أمي؟'] },
    { 'en': ['Happy', 'New', 'Year!'], 'ar': ['عاك', 'سعيد!'] },
    { 'en': ['Her', 'hat', 'looked', 'very', 'funny.'], 'ar': ['بدت', 'قبعتها', 'مضحكة', 'جداً.'] },
    { 'en': ['Are', 'you', 'talking', 'to', 'me?'], 'ar': ['هل', 'تحدثني؟'] },
    { 'en': ['Its', 'color', 'is', 'red.'], 'ar': ['لونها', 'أحمر.'] },
    { 'en': ['The', 'nurse', 'gave', 'me', 'a', 'shot.'], 'ar': ['أعطتني', 'الممرضة', 'حقنة.'] },
    { 'en': ['I', 'went', 'to', 'high', 'school', 'with', 'Tom.'], 'ar': ['ذهبت', 'إلى', 'المدرسة', 'الثانوية', 'مع', 'توم.'] },
    { 'en': ["It's", 'gotten', 'better.'], 'ar': ['تحسن', 'الوضع.'] },
    { 'en': ['I', 'promise', 'I', "won't", 'let', 'you', 'down.'], 'ar': ['أعدك', 'أنّني', 'لن', 'أخذلك.'] },
    { 'en': ['How', 'do', 'you', 'do', 'that?'], 'ar': ['كيف', 'تفعل', 'ذلك؟'] },
    { 'en': ['The', 'others', 'will', 'arrive', 'in', 'a', 'few', 'minutes.'], 'ar': ['سيصل', 'الاخرون', 'في', 'غضون', 'دقائق', 'معدودة.'] },
    { 'en': ['No', 'one', 'goes', 'there', 'anymore.'], 'ar': ['لا', 'أحد', 'يذهب', 'هناك', 'بعد', 'الآن.'] },
    { 'en': ['You', 'told', 'me', 'so', 'yourself.'], 'ar': ['أخبرتني', 'ذلك', 'بنفسك.'] },
    { 'en': ['The', 'train', 'is', 'about', 'to', 'leave.'], 'ar': ['القطار', 'سوف', 'يغادر.'] },
    { 'en': ['It', "must've", 'been', 'extremely', 'painful.'], 'ar': ['لابد', 'أنه', 'كان', 'مؤلماً', 'للغاية'] },
    { 'en': ['When', 'will', 'you', 'leave?'], 'ar': ['متى', 'ستغادر؟'] },
    { 'en': ['Everyone', 'sat', 'down.'], 'ar': ['جلس', 'الجميع.'] },
    { 'en': ['Tom', 'runs', 'very', 'fast.'], 'ar': ['يركض', 'توم', 'بسرعة', 'كبيرة.'] },
    { 'en': ['She', 'lost', 'her', 'purse.'], 'ar': ['فقدت', 'حقيبتها.'] },
    { 'en': ['The', 'hunter', 'shot', 'the', 'fox', 'dead.'], 'ar': ['أردى', 'الصيادُ', 'الثعلبَ.'] },
    { 'en': ["You'd", 'better', 'not', 'go.'], 'ar': ['من', 'الأفضل', 'لك', 'ألا', 'تذهب.'] },
    { 'en': ['They', 'fell', 'one', 'after', 'another.'], 'ar': ['وقعوا', 'واحداً', 'تلو', 'الآخر.'] },
    { 'en': ['She', 'spends', 'a', 'lot', 'of', 'money', 'on', 'books.'], 'ar': ['إنها', 'تقوم', 'بصرف', 'الكثير', 'من', 'المال', 'على', 'الكتب.'] },
    { 'en': ['I', 'had', 'nothing', 'else', 'to', 'do.'], 'ar': ['لم', 'يكن', 'لديّ', 'أيّ', 'شيء', 'آخر', 'أفعله.'] },
    { 'en': ["It's", 'fun', 'to', 'go', 'out', 'with', 'him.'], 'ar': ['إنهُ', 'من', 'الممتع', 'الخروج', 'معهُ.'] },
    { 'en': ['I', 'saw', 'many', 'tourists', 'on', 'the', 'beach.'], 'ar': ['رأيت', 'الكثير', 'من', 'السياح', 'على', 'الشاطئ.'] },
    { 'en': ['Lynn', 'runs', 'fast.'], 'ar': ['لين', 'تركض', 'بسرعة.'] },
    { 'en': ["He's", 'my', 'brother.'], 'ar': ['هو', 'أخي'] },
    { 'en': ["I'm", 'looking', 'for', 'work.'], 'ar': ['أبحث', 'عن', 'عمل.'] },
    { 'en': ['Take', 'a', 'look', 'at', 'this', 'map.'], 'ar': ['ألقِ', 'نظرةً', 'على', 'هذه', 'الخريطة.'] },
    { 'en': ['What', 'do', 'you', 'mean?'], 'ar': ['ماذا', 'تقصد؟'] },
    { 'en': ['What', 'did', 'you', 'put', 'into', 'the', 'boxes?'], 'ar': ['ماذا', 'وضعت', 'في', 'الصناديق؟'] },
    { 'en': ['A', 'prolonged', 'silence', 'followed.'], 'ar': ['حدث', 'بعده', 'سكون', 'طويل.'] },
    { 'en': ['She', 'attempted', 'to', 'kill', 'herself.'], 'ar': ['حاولت', 'قتل', 'نفسها.'] },
    { 'en': ['You', 'look', 'very', 'pale.'], 'ar': ['تبدو', 'شاحب', 'الوجه.'] },
    { 'en': ['Did', 'anyone', 'call', 'me', 'while', 'I', 'was', 'out?'], 'ar': ['هل', 'اتصل', 'بي', 'أحد', 'عندما', 'كنت', 'في', 'الخارج؟'] },
    { 'en': ['I', 'followed', 'her', 'into', 'the', 'room.'], 'ar': ['تَبِعْتُها', 'إلى', 'الغرفَة.'] },
    { 'en': ['Please', 'get', 'out', 'of', 'the', 'car.'], 'ar': ['اخرج', 'من', 'السّيّارة', 'من', 'فضلك.'] },
    { 'en': ['My', 'father', 'is', 'a', 'doctor.'], 'ar': ['يعمل', 'والدي', 'طبيباً.'] },
    { 'en': ['After', 'the', 'lightning,', 'came', 'the', 'thunder.'], 'ar': ['بعد', 'البرق', 'جاء', 'الرعد.'] },
    { 'en': ["I'm", 'observant.'], 'ar': ['أنا', 'شديد', 'الملاحظة.'] },
    { 'en': ['Does', 'he', 'know', 'what', 'you', 'did?'], 'ar': ['هل', 'يعلم', 'ما', 'فعلت؟'] },
    { 'en': ['We', 'want', 'something', 'new.'], 'ar': ['نريد', 'شيئاً', 'جديداً.'] },
    { 'en': ['You', 'must', 'go.'], 'ar': ['عليك', 'الرحيل.'] },
    { 'en': ['I', 'hope', 'I', 'can', 'find', 'a', 'job', 'in', 'Boston.'], 'ar': ['آمل', 'بأن', 'أجد', 'وظيفة', 'في', 'بوستن.'] },
    { 'en': ['I', 'need', 'my', 'key.'], 'ar': ['أحتاج', 'لمفتاحي.'] },
    { 'en': ['Terrific!'], 'ar': ['ممتاز!'] },
    { 'en': ["We're", 'happy.'], 'ar': ['نحن', 'سعداء'] },
    { 'en': ['I', 'hope', 'to', 'visit', 'Nara', 'next', 'time.'], 'ar': ['آمل', 'أن', 'أزور', 'نارا', 'المرة', 'المقبلة.'] },
    { 'en': ['I', "don't", 'mind', 'either', 'way.'], 'ar': ['لا', 'أمانع', 'في', 'كلتا', 'الحالتين.'] },
    { 'en': ['We', "don't", 'know', 'him.'], 'ar': ['نحن', 'لا', 'نعرفه'] },
    { 'en': ['Is', 'French', 'more', 'difficult', 'than', 'English?'], 'ar': ['هل', 'اللغة', 'الفرنسية', 'أصعب', 'من', 'الإنجليزية؟'] },
    { 'en': ['You', 'almost', 'crashed', 'into', 'my', 'car.'], 'ar': ['أنتَ', 'تقريباً', 'تحطمت', 'في', 'سيارتي.'] },
    { 'en': ['Speak', 'louder,', 'please.'], 'ar': ['رجاء،', 'ارفع', 'صوتك.'] },
    { 'en': ['Fish,', 'please.'], 'ar': ['سمك', 'من', 'فضلك.'] },
    { 'en': ['Look', 'at', 'those', 'clouds.'], 'ar': ['اُنظر', 'إلى', 'تلك', 'الغيوم.'] },
    { 'en': ['I', "don't", 'know', 'how', 'you', 'did', 'it.'], 'ar': ['لا', 'أدري', 'كيف', 'فعلتَها.'] },
    { 'en': ['I', 'like', 'Tom', 'because', "he's", 'honest.'], 'ar': ['أحب', 'توم', 'لأنه', 'أمين.'] },
    { 'en': ['He', 'was', 'not', 'invited.'], 'ar': ['إنهُ', 'لم', 'يكن', 'مدعو.'] },
    { 'en': ['I', "don't", 'know', 'what', "I'll", 'do.'], 'ar': ['لا', 'أعرف', 'ما', 'الذي', 'سأفعله؟'] },
    { 'en': ['I', 'tried', 'to', 'tell', 'Tom.'], 'ar': ['حاولت', 'إخبار', 'توم.'] },
    { 'en': ['Tom', 'lied.'], 'ar': ['لقد', 'كذِبَ', 'توم.'] },
    { 'en': ['You', "can't", 'do', 'two', 'things', 'at', 'once.'], 'ar': ['لا', 'يمكنك', 'أن', 'تفعل', 'شيئين', 'في', 'الوقت', 'نفسه.'] },
    { 'en': ['God', 'created', 'the', 'world', 'in', 'six', 'days.'], 'ar': ['خلق', 'الله', 'الأرض', 'في', 'ستة', 'أيام.'] },
    { 'en': ["It's", 'just', 'the', 'tip', 'of', 'the', 'iceberg.'], 'ar': ['ما', 'هذه', 'إلاّ', 'قمة', 'الجبل', 'الجليدي.'] },
    { 'en': ["There's", 'a', 'monster', 'under', 'my', 'bed.'], 'ar': ['هناك', 'وحش', 'تحت', 'سريري.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'invented', 'the', 'microscope?'], 'ar': ['هل', 'تعلم', 'من', 'هو', 'مخترع', 'المجهر؟'] },
    { 'en': ["I'm", 'here.'], 'ar': ['أنا', 'هنا'] },
    { 'en': ['Fill', 'it', 'up.'], 'ar': ['املئها.'] },
    { 'en': ['Tom', 'has', 'money.'], 'ar': ['توم', 'لديه', 'نقود'] },
    { 'en': ['Nothing', 'happens', 'overnight.'], 'ar': ['لاشيء', 'يحدث', 'بين', 'ليلة', 'وضحاها.'] },
    { 'en': ['This', 'question', 'is', 'one', 'of', 'great', 'importance.'], 'ar': ['هذا', 'سؤال', 'مهم', 'جداً.'] },
    { 'en': ['You', 'deserve', 'the', 'prize.'], 'ar': ['أنت', 'تستحق', 'الجائزة.'] },
    { 'en': ['I', 'once', 'wanted', 'to', 'be', 'an', 'astrophysicist.'], 'ar': ['أردتُ', 'مرّةً', 'أن', 'أتخصّصَ', 'في', 'الفيزياء', 'الفلكيّة.'] },
    { 'en': ['I', "don't", 'know', 'this', 'game.'], 'ar': ['لا', 'أعرفُ', 'هذه', 'اللعبَةَ.'] },
    { 'en': ["We'll", 'see', 'what', 'happens.'], 'ar': ['سنرى', 'ما', 'سيحدث.'] },
    { 'en': ['Playing', 'tennis', 'is', 'a', 'lot', 'of', 'fun.'], 'ar': ['إن', 'لعب', 'كرة', 'المضرب', 'ممتع.'] },
    { 'en': ['She', 'speaks', 'German.'], 'ar': ['إنها', 'تتحدث', 'الألمانية.'] },
    { 'en': ['Maybe', 'Tom', 'knows', 'something.'], 'ar': ['ربما', 'توم', 'يعرف', 'شيئاً.'] },
    { 'en': ['What', 'did', 'you', 'do', 'with', 'those', 'books?'], 'ar': ['ما', 'الذي', 'فعلته', 'بالكتب؟'] },
    { 'en': ['I', 'miss', 'you', 'very', 'much.'], 'ar': ['أشتاق', 'إليك', 'كثيراً.'] },
    { 'en': ['She', 'spent', 'her', 'life', 'in', 'pursuit', 'of', 'the', 'truth.'], 'ar': ['أمضت', 'حياتها', 'في', 'السعي', 'وراء', 'الحقيقة.'] },
    { 'en': ['They', 'must', 'be', 'crazy', 'to', 'believe', 'such', 'nonsense.'], 'ar': ['يجب', 'أن', 'يكونوا', 'قد', 'جُنّوا', 'للاعتقاد', 'في', 'مثل', 'هذا', 'الهراء.'] },
    { 'en': ['Go', 'brush', 'your', 'teeth.'], 'ar': ['إذهبي', 'واغسلي', 'أسنانك.'] },
    { 'en': ["You're", 'really', 'talented.'], 'ar': ['أنت', 'موهوبٌ', 'حقاً.'] },
    { 'en': ['Please', 'circle', 'the', 'right', 'answer.'], 'ar': ['رجاءً', 'ضع', 'دائرة', 'حول', 'الجواب', 'الصحيح.'] },
    { 'en': ['I', 'owe', 'her', '100,000', 'yen.'], 'ar': ['أدين', 'لها', 'بمئة', 'ألف', 'ين.'] },
    { 'en': ['I', 'usually', 'play', 'tennis.'], 'ar': ['عادةً', 'ما', 'ألعب', 'التنس.'] },
    { 'en': ['You', 'arrived', 'too', 'early.'], 'ar': ['أنتِ', 'وصلتِ', 'مبكراً', 'جداً.'] },
    { 'en': ['You', 'really', 'are', 'hopeless.'], 'ar': ['لا', 'فائدة', 'من', 'المحاولة', 'معك', 'حقاً.'] },
    { 'en': ['Put', 'the', 'carrots', 'in', 'the', 'pot.'], 'ar': ['ضع', 'الجزر', 'في', 'القدر.'] },
    { 'en': ['She', 'resembles', 'her', 'mother', 'in', 'every', 'respect.'], 'ar': ['تشبه', 'والدتها', 'في', 'كل', 'شيء.'] },
    { 'en': ['I', 'know', 'that', 'you', 'still', 'love', 'me.'], 'ar': ['أنا', 'أعرف', 'أنكَ', 'ما', 'زلت', 'تحبني.'] },
    { 'en': ['Well,', "let's", 'go.'], 'ar': ['إذاً', 'هيا', 'بنا', 'نذهب.'] },
    { 'en': ['My', 'father', 'lives', 'in', 'the', 'country.'], 'ar': ['والدي', 'يعيش', 'في', 'الريف.'] },
    { 'en': ['I', 'met', 'Tom', 'on', 'my', 'way', 'to', 'school.'], 'ar': ['صادفت', 'توم', 'في', 'طريقي', 'إلى', 'المدرسة.'] },
    { 'en': ['Tom', 'wants', 'to', 'set', 'up', 'a', 'meeting.'], 'ar': ['توم', 'يريد', 'إقامة', 'إجتماع.'] },
    { 'en': ["Shouldn't", 'we', 'ask', 'Tom?'], 'ar': ['أليس', 'علينا', 'سؤال', 'توم؟'] },
    { 'en': ["What's", 'new?'], 'ar': ['ما', 'الجديد؟'] },
    { 'en': ['I', 'got', 'wet', 'to', 'the', 'skin.'], 'ar': ['بلغ', 'البلل', 'بدني.'] },
    { 'en': ['Can', 'you', 'call', 'me', 'a', 'taxi?'], 'ar': ['هلا', 'طلبت', 'لي', 'سيارة', 'أجرة؟'] },
    { 'en': ["I'll", 'take', 'the', 'yellow', 'one.'], 'ar': ['سآخذ', 'الصفراء.'] },
    { 'en': ["I'd", 'like', 'to', 'reserve', 'a', 'table', 'for', 'two.'], 'ar': ['أريد', 'أن', 'أحجز', 'طاولة', 'لشخصيْن.'] },
    { 'en': ['He', 'died', 'without', 'having', 'made', 'a', 'will.'], 'ar': ['مات', 'من', 'دون', 'أن', 'يترك', 'وصية.'] },
    { 'en': ['Your', 'time', 'is', 'up.'], 'ar': ['إنتهى', 'وقتك.'] },
    { 'en': ['Do', 'you', 'really', 'think', 'Tom', 'will', 'do', 'that?'], 'ar': ['أحقا', 'تظن', 'أن', 'توم', 'سيفعل', 'ذلك؟'] },
    { 'en': ['We', 'left', 'the', 'final', 'decision', 'to', 'him.'], 'ar': ['تركنا', 'القرار', 'الأخير', 'إليه.'] },
    { 'en': ['Deal', 'us', 'the', 'cards.'], 'ar': ['وزّع', 'علينا', 'ورق', 'اللعب.'] },
    { 'en': ['He', 'was', 'silent', 'all', 'the', 'time.'], 'ar': ['بقي', 'صامتاً', 'طوال', 'الوقت.'] },
    { 'en': ["I'm", 'going', 'to', 'miss', 'Boston.'], 'ar': ['سأفتقد', 'بوسطن.'] },
    { 'en': ['Is', 'that', 'your', 'sister?'], 'ar': ['هل', 'هذه', 'أختك؟'] },
    { 'en': ['Now', 'I', 'remember.'], 'ar': ['الآن', 'تذكرت.'] },
    { 'en': ['How', 'do', 'you', 'know', 'that?'], 'ar': ['أنتَ', 'من', 'أينَ', 'تعلم', 'هذا؟'] },
    { 'en': ["You'll", 'forget', 'about', 'me', 'someday.'], 'ar': ['ستنسَني', 'يوماً', 'ما.'] },
    { 'en': ['Our', 'living', 'room', 'is', 'sunny.'], 'ar': ['غرفة', 'معيشتنا', 'مشمسة.'] },
    { 'en': ['Is', 'this', 'a', 'river?'], 'ar': ['هل', 'هذا', 'نهر؟'] },
    { 'en': ['Tom', 'is', 'a', 'construction', 'worker.'], 'ar': ['توم', 'عامل', 'بناء.'] },
    { 'en': ['My', 'friend', 'should', 'be', 'home', 'now.'], 'ar': ['صديقي', 'ينبغي', 'أن', 'يكون', 'في', 'المنزل', 'الأن.'] },
    { 'en': ['This', 'is', 'our', 'land', 'now.'], 'ar': ['باتت', 'هذه', 'أرضنا', 'الآن.'] },
    { 'en': ['You', 'will', 'never', 'get', 'bored', 'in', 'her', 'company.'], 'ar': ['لن', 'تشعر', 'أبداً', 'بالملل', 'في', 'صحبتها.'] },
    { 'en': ["I'm", 'old', 'enough', 'to', 'drive.'], 'ar': ['أنا', 'كبير', 'بما', 'يكفي', 'لأقود.'] },
    { 'en': ['I', 'have', 'read', 'the', 'book.'], 'ar': ['لقد', 'قرأت', 'الكتاب'] },
    { 'en': ['He', 'looks', 'suspicious.'], 'ar': ['يبدو', 'مريباً.'] },
    { 'en': ["That's", 'awesome,', 'right?'], 'ar': ['رائع،', 'صحيح؟'] },
    { 'en': ['It', 'was', 'a', 'computer', 'error.'], 'ar': ['كان', 'خطأ', 'حاسوبيّ.'] },
    { 'en': ['I', 'know', 'your', 'brother', 'very', 'well.'], 'ar': ['أعرف', 'أخاك', 'جيداً.'] },
    { 'en': ['I', "don't", 'blame', 'you.'], 'ar': ['أنا', 'لا', 'ألومك.'] },
    { 'en': ["I'd", 'do', 'anything', 'to', 'protect', 'you.'], 'ar': ['سأفعل', 'أيّ', 'شيء', 'لحمايتك.'] },
    { 'en': ['Did', 'you', 'watch', 'this', 'movie?'], 'ar': ['هل', 'شاهدت', 'هذا', 'الفلم.'] },
    { 'en': ['She', 'can', 'jump', 'high.'], 'ar': ['يمكنها', 'القفز', 'عالياً.'] },
    { 'en': ["Don't", 'take', 'chances.'], 'ar': ['لا', 'تخاطر.'] },
    { 'en': ['English', 'is', 'a', 'global', 'language.'], 'ar': ['الإنجليزية', 'لغةٌ', 'عالمية.'] },
    { 'en': ['When', 'do', 'you', 'get', 'up?'], 'ar': ['متى', 'تستيقظ؟'] },
    { 'en': ['This', 'knife', 'is', 'very', 'sharp.'], 'ar': ['هذه', 'السكين', 'حادة', 'للغاية.'] },
    { 'en': ['I', 'came', 'here', 'yesterday.'], 'ar': ['أتيت', 'إلى', 'هنا', 'البارحة.'] },
    { 'en': ['I', 'used', 'to', 'love', 'swimming.'], 'ar': ['كنت', 'أحب', 'السباحة.'] },
    { 'en': ['You', 'must', 'clean', 'your', 'room.'], 'ar': ['نظّف', 'غرفتك.'] },
    { 'en': ['I', "don't", 'like', 'swimming', 'in', 'salt', 'water.'], 'ar': ['أنا', 'لا', 'أحب', 'السباحة', 'في', 'المياه', 'المالحة'] },
    { 'en': ['War', 'is', 'a', 'terrible', 'thing.'], 'ar': ['الحرب', 'هو', 'شيءٌ', 'فظيع.'] },
    { 'en': ["I'm", 'freezing.'], 'ar': ['أشعر', 'بالبرد.'] },
    { 'en': ['Let', 'me', 'go', 'alone.'], 'ar': ['دعني', 'أذهب', 'وحدي.'] },
    { 'en': ['I', 'hated', 'school.'], 'ar': ['لقد', 'كَرِهتُ', 'المَدرَسَة.'] },
    { 'en': ['Look', 'at', 'that', 'red', 'building.'], 'ar': ['أنظر', 'إلى', 'ذاك', 'البناء', 'الأحمر.'] },
    { 'en': ['Leave', 'me', 'alone!'], 'ar': ['دعني', 'و', 'شأني!'] },
    { 'en': ['Prices', 'are', 'high.'], 'ar': ['الأسعار', 'مرتفعة.'] },
    { 'en': ['Come', 'on', 'in!'], 'ar': ['هيا', 'ادخل'] },
    { 'en': ['I', 'was', 'happy', 'then.'], 'ar': ['كنت', 'سعيداً', 'عند', 'ذلك.'] },
    { 'en': ['Tom', 'might', 'not', 'like', 'that.'], 'ar': ['قد', 'لا', 'يحب', 'توم', 'ذلك.'] },
    { 'en': ['My', 'older', 'sister', 'often', 'drinks', 'tea', 'with', 'milk.'], 'ar': ['كثيراً', 'ما', 'تشرب', 'أختي', 'الكبرى', 'الشاي', 'بالحليب.'] },
    { 'en': ['I', 'have', 'your', 'diary.'], 'ar': ['دفتر', 'يومياتك', 'عندي.'] },
    { 'en': ['Happy', 'New', 'Year!'], 'ar': ['سنة', 'جديدة', 'سعيدة!'] },
    { 'en': ["We're", 'going', 'to', 'Boston', 'to', 'see', 'Tom.'], 'ar': ['سوف', 'نذهب', 'إلى', 'بوسطن', 'حتى', 'نرى', 'توم.'] },
    { 'en': ['I', 'am', 'eating', 'an', 'apple.'], 'ar': ['آكُل', 'تفاحة.'] },
    { 'en': ['I', 'usually', 'walk.'], 'ar': ['عادةً', 'أمشي.'] },
    { 'en': ["It's", 'time', 'to', 'get', 'back', 'to', 'work.'], 'ar': ['إنه', 'وقت', 'العودة', 'للعمل.'] },
    { 'en': ['Forget', 'it!'], 'ar': ['انسَ', 'ذلك'] },
    { 'en': ["It's", 'your', 'book.'], 'ar': ['إنه', 'كتابك.'] },
    { 'en': ['Beauty', 'is', 'but', 'skin', 'deep.'], 'ar': ['الجمالُ', 'جمالُ', 'النفسِ.'] },
    { 'en': ['I', 'must', 'help', 'her.'], 'ar': ['يجب', 'أن', 'أساعدها.'] },
    { 'en': ['The', 'warning', 'came', 'too', 'late.'], 'ar': ['جاء', 'التحذير', 'متأخرا.'] },
    { 'en': ["Don't", 'forget', 'the', 'ticket.'], 'ar': ['لا', 'تنس', 'تذكرة', 'الدخول.'] },
    { 'en': ['Are', 'those', 'your', 'pictures?'], 'ar': ['هل', 'هذه', 'صورك؟'] },
    { 'en': ['The', 'painting', 'is', 'all', 'but', 'finished.'], 'ar': ['مازال', 'الرسم', 'بعيدا', 'عن', 'الاكتمال.'] },
    { 'en': ['How', 'many', 'books', 'does', 'he', 'have?'], 'ar': ['كم', 'كتاباً', 'لديه؟'] },
    { 'en': ['Tom', 'speaks', 'French', 'like', 'a', 'native.'], 'ar': ['يتحدث', 'توم', 'الفرنسية', 'كأهلها.'] },
    { 'en': ['You', 'understand', 'me.'], 'ar': ['أنت', 'تفهمني'] },
    { 'en': ["It's", 'no', 'laughing', 'matter.'], 'ar': ['هذا', 'ليس', 'أمراً', 'مضحكاً.'] },
    { 'en': ['I', 'just', 'have', 'one', 'other', 'thing', 'to', 'do.'], 'ar': ['هناك', 'شئ', 'واحد', 'آخرعليّ', 'فعله.'] },
    { 'en': ["I'm", 'just', 'glad', 'Tom', "wasn't", 'hurt.'], 'ar': ['سررت', 'لأن', 'توم', 'لم', 'يتأذى.'] },
    { 'en': ['We', 'discussed', 'the', 'plan', 'with', 'him.'], 'ar': ['ناقشناه', 'عن', 'الخطة.'] },
    { 'en': ['This', 'is', 'an', 'extremely', 'important', 'point.'], 'ar': ['هذه', 'ملحوظة', 'مهمة', 'للغاية.'] },
    { 'en': ["That's", 'what', 'I', 'thought.'], 'ar': ['ذلك', 'ما', 'ظننته'] },
    { 'en': ["I've", 'been', 'very', 'happy.'], 'ar': ['كنت', 'سعيدا', 'جدا.'] },
    { 'en': ["Don't", 'disappoint', 'me.'], 'ar': ['لا', 'تخيب', 'ظني', 'بك!'] },
    { 'en': ['Look', 'out!'], 'ar': ['انتبه'] },
    { 'en': ['What', 'time', 'do', 'you', 'get', 'up?'], 'ar': ['متى', 'تستيقظ؟'] },
    { 'en': ['We', 'helped', 'one', 'another.'], 'ar': ['ساعدنا', 'بعضنا.'] },
    { 'en': ['At', 'which', 'university', 'are', 'you', 'studying?'], 'ar': ['في', 'أي', 'جامعة', 'تدرس؟'] },
    { 'en': ['Forgive', 'me', 'for', 'being', 'late.'], 'ar': ['اعذرني', 'على', 'التأخير.'] },
    { 'en': ['Think', 'about', 'it.'], 'ar': ['فكر', 'في', 'ذلك.'] },
    { 'en': ["I'm", 'home.'], 'ar': ['لقد', 'عدت', 'إلى', 'البيت'] },
    { 'en': ['We', "can't", 'stay', 'for', 'dinner.'], 'ar': ['نحن', 'لا', 'نستطيع', 'الإنتظار', 'للعشاء.'] },
    { 'en': ['You', 'must', 'see', 'a', 'doctor.'], 'ar': ['عليك', 'أن', 'تزور', 'طبيباً.'] },
    { 'en': ["What's", 'going', 'on', 'here?'], 'ar': ['ما', 'الذي', 'يحدث', 'هنا؟'] },
    { 'en': ['I', "don't", 'know', 'if', 'I', 'have', 'the', 'time.'], 'ar': ['لا', 'أعرف', 'إذا', 'كان', 'لدي', 'الوقت', 'الكافي', 'لذلك.'] },
    { 'en': ['Do', 'you', 'love', 'me?'], 'ar': ['هل', 'تحبني؟'] },
    { 'en': ['I', 'thought', 'I', 'did', 'pretty', 'well.'], 'ar': ['ظننتني', 'أبليت', 'حسنا.'] },
    { 'en': ['Whose', 'car', 'is', 'that?'], 'ar': ['لمن', 'تلك', 'السيارة؟'] },
    { 'en': ['What', 'season', 'do', 'you', 'like', 'the', 'best?'], 'ar': ['أي', 'فصل', 'من', 'فصول', 'السنة', 'تحب', 'أكثر؟'] },
    { 'en': ['Call', 'me', 'tomorrow.'], 'ar': ['اتصل', 'بي', 'غداً.'] },
    { 'en': ['You', 'speak', 'like', 'your', 'mother.'], 'ar': ['إنك', 'تتكلم', 'كأمك.'] },
    { 'en': ['This', 'is', 'very', 'big.'], 'ar': ['هذا', 'كبير', 'جدا'] },
    { 'en': ['Come', 'quick!'], 'ar': ['تعال', 'بسرعة.'] },
    { 'en': ['Is', 'that', 'black', 'briefcase', 'yours?'], 'ar': ['هل', 'تلك', 'الحقيبة', 'السوداء', 'لك؟'] },
    { 'en': ["That's", 'not', 'my', 'car.'], 'ar': ['تلك', 'السيارة', 'ليست', 'ملكي'] },
    { 'en': ['What', 'are', 'you', 'up', 'to', 'tomorrow', 'afternoon?'], 'ar': ['ماذا', 'عندك', 'بعد', 'ظهر', 'غد؟'] },
    { 'en': ['Are', 'you', 'against', 'abortion?'], 'ar': ['هل', 'انت', 'ضد', 'الاجهاض', '؟'] },
    { 'en': ['We', 'will', 'play', 'soccer', 'after', 'school.'], 'ar': ['سنلعب', 'كرة', 'القدم', 'بعد', 'المدرسة.'] },
    { 'en': ['Bananas', 'are', 'yellow.'], 'ar': ['الموز', 'أصفر.'] },
    { 'en': ['You', 'owe', 'me', 'a', 'kiss.'], 'ar': ['إنّكِ', 'مدينة', 'لي', 'بقبلة.'] },
    { 'en': ['Where', 'does', 'your', 'uncle', 'live?'], 'ar': ['أين', 'يعيش', 'عمك؟'] },
    { 'en': ['I', 'was', 'hungry', 'and', 'thirsty.'], 'ar': ['كنت', 'جائعاً', 'و', 'عطشاً.'] },
    { 'en': ['I', 'am', 'thirsty.'], 'ar': ['أنا', 'أشعر', 'بالعطش.'] },
    { 'en': ['The', 'skies', 'are', 'clear.'], 'ar': ['السماء', 'صافية.'] },
    { 'en': ['I', 'wish', 'I', 'could', 'live', 'near', 'your', 'house.'], 'ar': ['أتمنى', 'لو', 'أعيش', 'قريباً', 'من', 'منزلك.'] },
    { 'en': ['Be', 'quiet', 'during', 'the', 'lesson.'], 'ar': ['كن', 'هادئاً', 'وقت', 'الدرس.'] },
    { 'en': ['A', 'stone', 'does', 'not', 'float.'], 'ar': ['الحجارة', 'لا', 'تطفو.'] },
    { 'en': ['I', 'have', 'been', 'waiting', 'for', 'almost', 'half', 'an', 'hour.'], 'ar': ['انتظرت', 'قرابة', 'النصف', 'ساعة.'] },
    { 'en': ['Throw', 'a', 'stick', 'and', 'watch', 'the', 'dog', 'fetch', 'it.'], 'ar': ['ارم', 'العصى', 'وشاهد', 'الكلب', 'يجلبها.'] },
    { 'en': ['Why', 'did', 'you', 'leave', 'your', 'job', 'on', 'the', 'farm?'], 'ar': ['لماذا', 'تركت', 'عملك', 'في', 'المزرعة؟'] },
    { 'en': ['Tom', 'will', 'drive', 'you', 'to', 'the', 'airport.'], 'ar': ['سيقلك', 'توم', 'للمطار.'] },
    { 'en': ['Merry', 'Christmas!'], 'ar': ['ميلاد', 'مجيد.'] },
    { 'en': ['I', 'like', 'reading', 'books.'], 'ar': ['أحب', 'قراءة', 'الكتب'] },
    { 'en': ["I'm", 'going', 'to', 'leave', 'school.'], 'ar': ['سأغادر', 'المدرسة.'] },
    { 'en': ['Everyone', 'likes', 'her.'], 'ar': ['الجميع', 'يحبها.'] },
    { 'en': ['Tom', 'saw', 'something', 'strange.'], 'ar': ['توم', 'رأى', 'شيئا', 'غريبا.'] },
    { 'en': ["Where's", 'the', 'pharmacy?'], 'ar': ['أين', 'الصيدلية؟'] },
    { 'en': ["Nobody's", 'around.'], 'ar': ['لا', 'أحد', 'هنا.'] },
    { 'en': ['I', 'think', 'maybe', 'I', 'can', 'help.'], 'ar': ['أظن', 'أنه', 'يمكنني', 'أن', 'أساعد.'] },
    { 'en': ['Is', 'it', 'not', 'black?'], 'ar': ['أليست', 'سوداء؟'] },
    { 'en': ['Maybe', 'Tom', 'likes', 'you.'], 'ar': ['ربما', 'توم', 'يحبك.'] },
    { 'en': ["I'm", 'getting', 'hungry.'], 'ar': ['بدأت', 'أحس', 'بالجوع.'] },
    { 'en': ["She's", 'correct', 'for', 'sure.'], 'ar': ['إنها', 'بلا', 'شك', 'محقة.'] },
    { 'en': ['What', 'if', 'he', 'fails?'], 'ar': ['ماذا', 'لو', 'فشِل؟'] },
    { 'en': ['What', 'time', 'did', 'you', 'arrive', 'there?'], 'ar': ['متى', 'وصلت', 'إلى', 'هناك؟'] },
    { 'en': ['She', 'was', 'nearly', 'hit', 'by', 'a', 'bicycle.'], 'ar': ['كادت', 'أن', 'تصدمها', 'دراجة.'] },
    { 'en': ['Which', 'diet', 'is', 'better?'], 'ar': ['أي', 'حمية', 'أفضل؟'] },
    { 'en': ["Let's", 'watch', 'TV.'], 'ar': ['لنشاهد', 'التلفاز.'] },
    { 'en': ['Take', 'off', 'your', 'socks,', 'please.'], 'ar': ['إخلع', 'خفيك', 'من', 'فضلك.'] },
    { 'en': ['The', 'pen', 'is', 'broken.'], 'ar': ['القلم', 'مكسور.'] },
    { 'en': ['You', 'talk', 'as', 'if', 'you', 'were', 'the', 'boss.'], 'ar': ['تتكلم', 'و', 'كأنك', 'رئيس', 'عملنا.'] },
    { 'en': ['He', "wouldn't", 'allow', 'me', 'to', 'drive', 'his', 'car.'], 'ar': ['لم', 'يسمح', 'لي', 'أن', 'أقود', 'سيارته', 'إطلاقاً.'] },
    { 'en': ["Don't", 'tell', 'anyone', 'this.'], 'ar': ['لا', 'تخبر', 'أحداً', 'بذلك.'] },
    { 'en': ['There', 'are', 'many', 'tourists', 'in', 'town.'], 'ar': ['هنالك', 'العديد', 'من', 'السياح', 'في', 'البلدة', '.'] },
    { 'en': ['My', 'hobby', 'is', 'making', 'model', 'planes.'], 'ar': ['هوايتي', 'هي', 'صنع', 'مجسمات', 'للطائرات.'] },
    { 'en': ['He', 'always', 'treats', 'me', 'like', 'a', 'child.'], 'ar': ['هو', 'دائماً', 'ما', 'يتعامل', 'معي', 'كطفل.'] },
    { 'en': ["You're", 'still', 'young.'], 'ar': ['ما', 'زلت', 'صغيراً.'] },
    { 'en': ['Englishmen', 'are,', 'on', 'the', 'whole,', 'conservative.'], 'ar': ['الإنجليز', 'أكثرهم', 'محافظين.'] },
    { 'en': ['Who', 'are', 'you', 'waiting', 'for?'], 'ar': ['من', 'تنتظر؟'] },
    { 'en': ['There', 'are', 'many', 'shrines', 'in', 'Kyoto.'], 'ar': ['يوجد', 'الكثير', 'من', 'المعابد', 'في', 'كيوتو.'] },
    { 'en': ['I', 'always', 'wondered', 'what', 'was', 'in', 'your', 'attic.'], 'ar': ['تساءلت', 'دائما', 'عن', 'ما', 'يوجد', 'في', 'عليتك'] },
    { 'en': ['Please', "don't", 'run', 'in', 'the', 'classroom.'], 'ar': ['من', 'فضلك', 'لا', 'تركض', 'في', 'الصف.'] },
    { 'en': ['Give', 'it', 'to', 'him.'], 'ar': ['أعطه', 'إياها.'] },
    { 'en': ['I', 'need', 'to', 'know', 'why', 'you', 'need', 'this.'], 'ar': ['يجب', 'أن', 'أعرف', 'لماذا', 'تحتاج', 'هذا.'] },
    { 'en': ['Can', 'you', 'keep', 'a', 'secret?'], 'ar': ['أيمكنك', 'أن', 'تحفظ', 'سراً؟'] },
    { 'en': ['Tom', 'has', 'the', 'ability', 'to', 'do', 'that.'], 'ar': ['لتوم', 'المقدرة', 'على', 'فعل', 'ذلك.'] },
    { 'en': ['Cancer', 'can', 'be', 'cured', 'if', 'discovered', 'in', 'time.'], 'ar': ['من', 'الممكن', 'علاج', 'السرطان', 'إذا', 'اكتشف', 'قبل', 'فوات', 'الأوان.'] },
    { 'en': ['I', 'ran', 'for', 'a', 'mile.'], 'ar': ['ركضت', 'لمسافة', 'ميل.'] },
    { 'en': ["It's", 'on', 'the', 'sofa.'], 'ar': ['إنها', 'على', 'الأريكة.'] },
    { 'en': ["Don't", 'worry', 'about', 'it!'], 'ar': ['لا', 'تقلق!'] },
    { 'en': ['Please', 'give', 'me', 'a', 'glass', 'of', 'water.'], 'ar': ['أعطني', 'كأساً', 'من', 'الماء', 'من', 'فضلك.'] },
    { 'en': ['They', 'should', 'have', 'known', 'better.'], 'ar': ['كان', 'عليهم', 'توقع', 'ذلك.'] },
    { 'en': ['Yeah.', 'I', 'think', 'so,', 'too.'], 'ar': ['نعم،', 'أظن', 'ذلك', 'أيضاً.'] },
    { 'en': ["I'm", 'bad', 'at', 'sports.'], 'ar': ['لا', 'أجيد', 'ممارسة', 'الرياضة', '.'] },
    { 'en': ['He', 'rested', 'his', 'hand', 'on', 'my', 'shoulder.'], 'ar': ['وضع', 'يديه', 'على', 'كتفي.'] },
    { 'en': ['Perhaps', 'it', 'will', 'snow', 'tomorrow.'], 'ar': ['لربما', 'تساقط', 'الثلج', 'غداً.'] },
    { 'en': ["I'm", 'not', 'surprised.'], 'ar': ['أنا', 'غير', 'متفاجئ.'] },
    { 'en': ['Is', 'it', 'windy?'], 'ar': ['هل', 'الجو', 'عاصف؟'] },
    { 'en': ['They', 'are', 'very', 'big.'], 'ar': ['إنهم', 'كبار', 'جداً'] },
    { 'en': ['This', 'is', 'the', 'end.'], 'ar': ['هذه', 'هي', 'النهاية.'] },
    { 'en': ['Translate', 'the', 'underlined', 'sentences.'], 'ar': ['ترجم', 'ما', 'تحته', 'خط.'] },
    { 'en': ['Recent', 'advances', 'in', 'medicine', 'are', 'remarkable.'], 'ar': ['التطورات', 'الاخيرة', 'في', 'الدواء', 'جديرة', 'بالملاحظة'] },
    { 'en': ["Don't", 'be', 'late', 'for', 'the', 'meeting', 'tomorrow.'], 'ar': ['لا', 'تتأخر', 'عن', 'اجتماع', 'الغد.'] },
    { 'en': ['Mathematics', 'is', 'my', 'favorite', 'subject.'], 'ar': ['الرياضيات', 'مادّتي', 'المفضّلة.'] },
    { 'en': ['The', 'rest', 'is', 'left', 'to', 'you!'], 'ar': ['الباقي', 'عليك!'] },
    { 'en': ['Tom', "can't", 'see', 'Mary', 'from', 'where', 'he', 'is.'], 'ar': ['لا', 'يستطيع', 'توم', 'رؤية', 'ماري', 'من', 'مكانه.'] },
    { 'en': ['What', 'did', 'you', 'answer?'], 'ar': ['بماذا', 'أجبت؟'] },
    { 'en': ["It's", 'going', 'to', 'be', 'quite', 'hot', 'today.'], 'ar': ['سيكون', 'اليوم', 'حار', 'جداً'] },
    { 'en': ['How', 'many', 'times', 'do', 'I', 'need', 'to', 'repeat', 'it?'], 'ar': ['كم', 'مرّة', 'عليّ', 'تكرار', 'ذلك؟'] },
    { 'en': ["Let's", 'finish', 'the', 'job.'], 'ar': ['دعنا', 'ننهي', 'الوظيفة.'] },
    { 'en': ['Do', 'you', 'own', 'a', 'computer?'], 'ar': ['هل', 'تمتلك', 'كمبيوتر؟'] },
    { 'en': ['I', "can't", 'talk.'], 'ar': ['لا', 'أستطيع', 'الكلام.'] },
    { 'en': ['Take', 'your', 'time.', "There's", 'no', 'hurry.'], 'ar': ['خذ', 'وقتك', '،', 'لسنا', 'مستعجلين.'] },
    { 'en': ['I', "haven't", 'met', 'him', 'before.'], 'ar': ['لم', 'أقابله', 'من', 'قبل.'] },
    { 'en': ['Do', 'you', 'really', 'want', 'Tom', 'to', 'do', 'that?'], 'ar': ['أتريد', 'حقا', 'من', 'توم', 'أن', 'يفعل', 'ذلك؟'] },
    { 'en': ['My', 'father', 'gave', 'me', 'a', 'game.'], 'ar': ['أعطاني', 'أبي', 'لعبةً.'] },
    { 'en': ['At', 'one', 'time,', 'there', 'was', 'a', 'bridge', 'here.'], 'ar': ['كان', 'يوجد', 'جسر', 'هنا', 'في', 'يوم', 'من', 'الأيام.'] },
    { 'en': ['I', 'have', 'a', 'brother.'], 'ar': ['لدي', 'أخ'] },
    { 'en': ['They', 'eat', 'with', 'a', 'knife', 'and', 'fork.'], 'ar': ['يأكلون', 'بالشوكة', 'و', 'السكين.'] },
    { 'en': ['Just', 'wait', 'right', 'here.'], 'ar': ['فقط', 'انتظر', 'هنا.'] },
    { 'en': ['None', 'of', 'my', 'classmates', 'live', 'near', 'here.'], 'ar': ['لا', 'أحد', 'من', 'زملائي', 'يعيشون', 'بالقرب', 'من', 'هنا.'] },
    { 'en': ['Can', 'you', 'eat', 'raw', 'oysters?'], 'ar': ['هل', 'تستطيع', 'أكل', 'المحار', 'النيء؟'] },
    { 'en': ["Don't", 'worry', 'about', 'me.'], 'ar': ['لا', 'تقلق', 'عليّ.'] },
    { 'en': ["I'd", 'love', 'to', 'know', "what's", 'going', 'on', 'here.'], 'ar': ['أودّ', 'أن', 'أعلم', 'ما', 'يحدث', 'هنا.'] },
    { 'en': ['He', 'seems', 'to', 'have', 'told', 'a', 'lie.'], 'ar': ['يظهر', 'أنه', 'أخبر', 'كِذبةً.'] },
    { 'en': ['This', 'is', 'really', 'interesting.'], 'ar': ['هذا', 'حقا', 'ممتع'] },
    { 'en': ['Do', 'you', 'have', 'any', 'sunscreen?'], 'ar': ['أعندك', 'كريم', 'لأشعة', 'الشمس؟'] },
    { 'en': ['My', 'legs', 'still', 'hurt.'], 'ar': ['قدمي', 'ما', 'زالت', 'تؤلمني.'] },
    { 'en': ['Mom', 'is', 'older', 'than', 'Dad.'], 'ar': ['أمي', 'تكبر', 'أبي', 'عمراً.'] },
    { 'en': ['We', 'kept', 'them', 'quiet.'], 'ar': ['أبقيناهم', 'صامتين.'] },
    { 'en': ['I', 'love', 'you.'], 'ar': ['أنا', 'أحبك.'] },
    { 'en': ['Go', 'to', 'your', 'room!'], 'ar': ['إذهب', 'إلىَ', 'غرفتك!'] },
    { 'en': ['Tom', 'is', 'playing', 'frisbee.'], 'ar': ['توم', 'يلعب', 'الصحن', 'الطائر.'] },
    { 'en': ['This', 'is', 'your', 'key.'], 'ar': ['ها', 'هو', 'مفتاحك.'] },
    { 'en': ['Who', 'owns', 'this', 'house?'], 'ar': ['من', 'يملك', 'هذا', 'المنزل؟'] },
    { 'en': ['Mary', 'plays', 'the', 'piano.'], 'ar': ['ماري', 'تعزف', 'البيانو.'] },
    { 'en': ['Come', 'alone.'], 'ar': ['تعالَ', 'وحدك.'] },
    { 'en': ['Write', 'that', 'down.'], 'ar': ['اكتب', 'ذلك.'] },
    { 'en': ['I', 'love', 'watching', 'basketball.'], 'ar': ['أحب', 'مشاهدة', 'مبارايات', 'كرة', 'السلة.'] },
    { 'en': ['Tom', 'tried', 'not', 'to', 'look', 'disappointed.'], 'ar': ['حاول', 'توم', 'أن', 'لا', 'يظهر', 'بأنه', 'خائب', 'الأمل.'] },
    { 'en': ['Mom', 'is', 'older', 'than', 'Dad.'], 'ar': ['أمي', 'أكبر', 'من', 'أبي', 'سناً.'] },
    { 'en': ['God', 'helps', 'those', 'who', 'help', 'themselves.'], 'ar': ['ساعد', 'نفسك', 'و', 'سيساعدك', 'الله.'] },
    { 'en': ['You', 'look', 'bored.'], 'ar': ['يبدو', 'عليك', 'الملل.'] },
    { 'en': ['It', 'was', 'cold', 'here', 'through', 'March.'], 'ar': ['كان', 'الطقس', 'باردًا', 'هنا', 'طوال', 'شهر', 'آذار-', 'مارس.'] },
    { 'en': ['He', 'lost', 'his', 'father', 'at', 'sea.'], 'ar': ['هوَ', 'فقد', 'والدهُ', 'في', 'البحر.'] },
    { 'en': ['Take', 'her', 'to', 'the', 'OR.'], 'ar': ['خذها', 'إلى', 'غرفة', 'العمليات'] },
    { 'en': ['I', 'trust', 'you.'], 'ar': ['أثق', 'بك.'] },
    { 'en': ['My', 'brother', 'is', 'an', 'idiot.'], 'ar': ['أخي', 'أبله.'] },
    { 'en': ['I', "can't", 'do', 'it.'], 'ar': ['لا', 'أستطيع', 'أن', 'أفعل', 'ذلك.'] },
    { 'en': ["I've", 'lost', 'your', 'phone', 'number.'], 'ar': ['أضعت', 'رقم', 'هاتفك.'] },
    { 'en': ['My', 'hobby', 'is', 'collecting', 'insects.'], 'ar': ['هوايتي', 'هي', 'جمع', 'الحشرات.'] },
    { 'en': ['I', 'want', 'to', 'read', 'that', 'book.'], 'ar': ['أريد', 'قراءة', 'ذلك', 'الكتاب.'] },
    { 'en': ["It's", 'as', 'easy', 'as', 'pie.'], 'ar': ['إنه', 'سهل', 'جدا.'] },
    { 'en': ['The', 'money', 'is', 'on', 'the', 'table.'], 'ar': ['المال', 'على', 'الطاولة.'] },
    { 'en': ["Can't", 'you', 'speak', 'English?'], 'ar': ['ألا', 'يمكنك', 'تحدث', 'الإنجليزية؟'] },
    { 'en': ['Leave', 'my', 'car', 'alone.'], 'ar': ['اترك', 'سيارتي', 'و', 'شأنها.'] },
    { 'en': ['When', 'shall', 'we', 'go?'], 'ar': ['متى', 'علينا', 'الذهاب؟'] },
    { 'en': ['Can', 'you', 'see', 'the', 'difference?'], 'ar': ['هل', 'بإمكانك', 'أن', 'ترى', 'الفرق؟'] },
    { 'en': ['The', 'teacher', 'went', 'through', 'my', 'purse.'], 'ar': ['فتّش', 'المدرّس', 'حقيبتي.'] },
    { 'en': ['We', 'must', 'go', 'there', 'whether', 'we', 'like', 'it', 'or', 'not.'], 'ar': ['علينا', 'الذهاب', 'إلى', 'هناك', 'شئنا', 'أم', 'أبينا.'] },
    { 'en': ["You're", 'on', 'your', 'own', 'now.'], 'ar': ['أنت', 'لوحدك', 'الآن.'] },
    { 'en': ['They', 'are', 'happy.'], 'ar': ['إنهم', 'سعداء'] },
    { 'en': ['We', 'accept', 'all', 'major', 'credit', 'cards.'], 'ar': ['نقبل', 'الدفع', 'بأي', 'نوع', 'من', 'بطاقات', 'الإئتمان', 'المعروفة.'] },
    { 'en': ['Would', 'you', 'pass', 'me', 'the', 'salt?'], 'ar': ['هل', 'لك', 'أن', 'تمرر', 'لي', 'الملح', 'من', 'فضلك؟'] },
    { 'en': ['My', 'father', 'sometimes', 'goes', 'abroad.'], 'ar': ['يسافر', 'أبي', 'إلى', 'الخارج', 'أحياناً.'] },
    { 'en': ["I'm", 'not', 'thin.'], 'ar': ['لست', 'نحيفا.'] },
    { 'en': ['How', 'pretty', 'you', 'look', 'today!'], 'ar': ['ما', 'أجمل', 'طلتك', 'اليوم!'] },
    { 'en': ['You', 'have', 'reached', 'your', 'destination.'], 'ar': ['لقد', 'وصلت', 'إلى', 'وجهتك.'] },
    { 'en': ['Is', 'something', 'on', 'your', 'mind?'], 'ar': ['هل', 'يوجد', 'شيئ', 'في', 'ذهنك؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'see', 'it.'], 'ar': ['أود', 'أن', 'أراها.'] },
    { 'en': ['The', 'bug', 'is', 'still', 'alive.'], 'ar': ['ما', 'زالت', 'الحشرة', 'حية.'] },
    { 'en': ['I', 'have', 'to', 'attend', 'this', 'class.'], 'ar': ['يجب', 'علي', 'حضور', 'هذه', 'المحاضرة'] },
    { 'en': ['Everyone', 'dies.'], 'ar': ['الجميع', 'يموت.'] },
    { 'en': ['Come', 'on.', "Let's", 'go', 'home.'], 'ar': ['هيا.', 'دعنا', 'نذهب', 'إلى', 'البيت.'] },
    { 'en': ['If', 'you', 'need', 'an', 'umbrella', "I'll", 'lend', 'you', 'one.'], 'ar': ['إن', 'كنت', 'تحتاج', 'مظلةً،', 'فسأعيرك', 'واحدةً.'] },
    { 'en': ['There', 'are', 'many', 'islands', 'in', 'Greece.'], 'ar': ['هناك', 'الكثير', 'من', 'الجُزُر', 'في', 'اليونان.'] },
    { 'en': ['I', 'will', 'gladly', 'help', 'you.'], 'ar': ['سوف', 'اكون', 'سعيد', 'لمساعدتك'] },
    { 'en': ['Her', 'ambition', 'is', 'to', 'become', 'an', 'ambassador.'], 'ar': ['طموحه', 'أن', 'يصبح', 'سفيرا.'] },
    { 'en': ["It's", 'a', 'quote', 'from', 'a', 'book.'], 'ar': ['إنهُ', 'إقتباس', 'من', 'كتاب.'] },
    { 'en': ['Why', 'were', 'you', 'late', 'this', 'morning?'], 'ar': ['لماذا', 'تأخرت', 'هذا', 'الصباح؟'] },
    { 'en': ['There', 'are', 'many', 'hotels', 'downtown.'], 'ar': ['هناك', 'الكثير', 'من', 'الفنادق', 'في', 'وسط', 'المدينة.'] },
    { 'en': ["It's", 'gotten', 'better.'], 'ar': ['تحسنت.'] },
    { 'en': ["Let's", 'turn', 'back.'], 'ar': ['لنعد', 'أدراجنا.'] },
    { 'en': ['I', 'lost', 'consciousness.'], 'ar': ['فقدت', 'الوعي.'] },
    { 'en': ['He', 'will', 'not', 'go.'], 'ar': ['لن', 'يذهب.'] },
    { 'en': ['I', 'want', 'to', 'try', 'this.'], 'ar': ['أريد', 'أن', 'أجرب', 'ذلك.'] },
    { 'en': ['Put', 'some', 'more', 'wood', 'on', 'the', 'fire.'], 'ar': ['زد', 'بعض', 'الحطب', 'إلى', 'النار.'] },
    { 'en': ['Did', 'you', 'miss', 'me?'], 'ar': ['هل', 'اشتقت', 'لي؟'] },
    { 'en': ['I', 'slapped', 'his', 'face.'], 'ar': ['صفعت', 'وجهه.'] },
    { 'en': ['Tom', 'is', 'all', 'alone', 'again.'], 'ar': ['توم', 'وحيد', 'تماما', 'من', 'جديد.'] },
    { 'en': ['Everything', 'happens', 'for', 'a', 'reason.'], 'ar': ['كل', 'ما', 'يحصل،', 'يحصل', 'لسبب.'] },
    { 'en': ['Magnesium', 'is', 'a', 'chemical', 'element.'], 'ar': ['المغنيزيوم', 'عنصر', 'كيميائي.'] },
    { 'en': ['We', 'are', 'happy.'], 'ar': ['نحن', 'مسرورون.'] },
    { 'en': ['I', 'believe', 'in', 'you.'], 'ar': ['أثق', 'في', 'قدرتك.'] },
    { 'en': ['I', 'have', 'not', 'felt', 'well', 'recently.'], 'ar': ['أنا', 'لا', 'أشعر', 'بالراحة', 'مؤخرا.'] },
    { 'en': ['Stop', 'teasing', 'your', 'sister!'], 'ar': ['توقف', 'عن', 'مضايقة', 'أُختك!'] },
    { 'en': ['Did', 'your', 'uncle', 'let', 'you', 'drive', 'his', 'car?'], 'ar': ['هل', 'سمح', 'لك', 'عمك', 'أن', 'تقود', 'سيارته؟'] },
    { 'en': ["I'll", 'call', 'the', 'cops.'], 'ar': ['سأتّصل', 'بالشّرطة.'] },
    { 'en': ['She', 'always', 'buys', 'expensive', 'clothes.'], 'ar': ['إنها', 'دائماً', 'تشتري', 'ثياباً', 'ثمينة.'] },
    { 'en': ['We', 'went', 'to', 'the', 'zoo.'], 'ar': ['ذهبنا', 'إلى', 'حديقة', 'الحيوانات.'] },
    { 'en': ['Close', 'the', 'door.'], 'ar': ['أقفل', 'الباب!'] },
    { 'en': ['I', 'envy', 'her.'], 'ar': ['أنا', 'أحسدها.'] },
    { 'en': ['He', 'lost', 'his', 'job.'], 'ar': ['فقد', 'عمله.'] },
    { 'en': ['Is', 'that', 'all?'], 'ar': ['أهذا', 'كل', 'شيء؟'] },
    { 'en': ['Please', 'close', 'the', 'door', 'quietly.'], 'ar': ['من', 'فضلك', 'أقفل', 'الباب', 'بهدوء.'] },
    { 'en': ['She', 'attempted', 'to', 'kill', 'herself.'], 'ar': ['حاولت', 'الإنتحار.'] },
    { 'en': ['He', 'made', 'a', 'fool', 'of', 'his', 'teacher.'], 'ar': ['أَضْحَك', 'الناس', 'على', 'أستاذه.'] },
    { 'en': ['In', 'case', 'of', 'fire,', 'telephone', 'the', 'fire', 'station.'], 'ar': ['في', 'حالة', 'الحريق،', 'اتصل', 'بمحظة', 'المطافي.'] },
    { 'en': ['Do', 'you', 'know', 'why?'], 'ar': ['هل', 'تعلم', 'لماذا؟'] },
    { 'en': ['I', 'suggest', 'you', 'do', 'what', 'Tom', 'says.'], 'ar': ['أقترح', 'بأن', 'تعمل', 'بما', 'يقوله', 'توم.'] },
    { 'en': ["They're", 'suffering', 'from', 'malaria.'], 'ar': ['انهم', 'يعانون', 'من', 'الملاريا.'] },
    { 'en': ["Don't", 'move.'], 'ar': ['لا', 'تتحرك.'] },
    { 'en': ['I', 'baked', 'an', 'apple', 'pie.'], 'ar': ['خبزت', 'فطيرة', 'تفاح.'] },
    { 'en': ['I', 'wanted', 'to', 'go', 'there.'], 'ar': ['أردت', 'الذهاب', 'إلى', 'هناك.'] },
    { 'en': ['You', 'should', 'sleep.'], 'ar': ['عليك', 'النوم.'] },
    { 'en': ['Those', 'books', 'are', 'mine.'], 'ar': ['هذه', 'كتبي.'] },
    { 'en': ["What's", 'your', 'favorite', 'type', 'of', 'pizza?'], 'ar': ['ما', 'نوع', 'البيتزا', 'المفضّل', 'لديك؟'] },
    { 'en': ['I', 'want', 'a', 'guitar.'], 'ar': ['أريد', 'الحصول', 'على', 'جيتار.'] },
    { 'en': ['Do', 'you', 'remember', 'me?'], 'ar': ['أما', 'زلت', 'تذكرني؟'] },
    { 'en': ['Tomorrow', "I'll", 'buy', 'what', 'I', 'need.'], 'ar': ['سأبتاع', 'غدا', 'ما', 'أحتاج.'] },
    { 'en': ['I', "don't", 'like', 'this', 'city', 'at', 'all.'], 'ar': ['لا', 'أحب', 'هذه', 'المدينة', 'على', 'الإطلاق.'] },
    { 'en': ['I', 'have', 'lost', 'my', 'wallet.'], 'ar': ['فقدت', 'محفظتي.'] },
    { 'en': ['You', 'should', 'keep', 'your', 'promise.'], 'ar': ['يجب', 'أن', 'تفي', 'بوعدك.'] },
    { 'en': ['Of', 'course.'], 'ar': ['بالتأكيد.'] },
    { 'en': ['How', 'long', 'did', 'you', 'stay?'], 'ar': ['كَم', 'أقمت؟'] },
    { 'en': ['There', 'were', 'no', 'roses', 'in', 'the', 'garden.'], 'ar': ['لم', 'يكن', 'هنالك', 'ورود', 'في', 'الحديقة.'] },
    { 'en': ["I'm", 'here', 'as', 'a', 'tourist.'], 'ar': ['انا', 'هنا', 'بصفتي', 'سائح', '.'] },
    { 'en': ['I', 'too', 'have', 'been', 'to', 'New', 'York.'], 'ar': ['أنا', 'أيضاً', 'كنتُ', 'في', 'نيويورك.'] },
    { 'en': ['Can', 'I', 'borrow', 'your', 'tennis', 'racket', 'today?'], 'ar': ['أيمكنني', 'استعارة', 'مضربك', 'للتنس', 'اليوم؟'] },
    { 'en': ['I', 'saw', 'Tom', 'dancing', 'with', 'another', 'girl.'], 'ar': ['رأيت', 'توم', 'يرقص', 'مع', 'فتاة', 'أخرى.'] },
    { 'en': ['Tom', "hasn't", 'read', 'the', 'letter', 'yet.'], 'ar': ['لم', 'يقرأ', 'توم', 'الرسالة', 'بعد.'] },
    { 'en': ['Artificial', 'light', 'is', 'produced', 'by', 'electricity.'], 'ar': ['الضوء', 'الإصطناعي', 'يُولّد', 'بالكهرباء.'] },
    { 'en': ['I', 'like', 'your', 'work.'], 'ar': ['أنا', 'أُحب', 'عملك.'] },
    { 'en': ['I', 'know', 'her.'], 'ar': ['أعرِفها.'] },
    { 'en': ["I'm", 'pleased', 'to', 'meet', 'you.'], 'ar': ['سررت', 'بمعرفتك.'] },
    { 'en': ['You', 'are', 'a', 'good', 'cook.'], 'ar': ['أنت', 'طباخ', 'جيد'] },
    { 'en': ['It', 'was', 'difficult', 'to', 'remove', 'the', 'coffee', 'stain.'], 'ar': ['كان', 'من', 'الصعب', 'إزالة', 'بقعة', 'القهوة.'] },
    { 'en': ['She', 'loves', 'Tom.'], 'ar': ['إنها', 'تحب', 'توم.'] },
    { 'en': ['Nobody', 'can', 'surpass', 'him.'], 'ar': ['لا', 'يستطيع', 'أحد', 'التفوق', 'عليه.'] },
    { 'en': ['There', 'are', 'many', 'books', 'in', 'my', 'room.'], 'ar': ['هناك', 'الكثير', 'من', 'الكتب', 'في', 'غرفتي.'] },
    { 'en': ['I', 'will', 'be', 'glad', 'to', 'help', 'you', 'anytime.'], 'ar': ['سيسعدني', 'أن', 'أساعدك', 'في', 'أي', 'وقت.'] },
    { 'en': ['Her', 'ambition', 'is', 'to', 'become', 'an', 'ambassador.'], 'ar': ['طموحها', 'هو', 'أن', 'تصبح', 'سفيرة.'] },
    { 'en': ['I', "can't", 'reveal', 'my', 'sources.'], 'ar': ['لا', 'استطيع', 'الكشف', 'عن', 'مصادرى.'] },
    { 'en': ['This', 'serves', 'the', 'purpose.'], 'ar': ['هذا', 'يفي', 'بالغرض.'] },
    { 'en': ['I', "don't", 'shampoo', 'my', 'hair', 'in', 'the', 'morning.'], 'ar': ['لا', 'أغسل', 'شعري', 'بالصابون', 'في', 'الصباح.'] },
    { 'en': ['You', 'are', 'a', 'good', 'student.'], 'ar': ['أنت', 'نِعْمَ', 'الطالب.'] },
    { 'en': ["I'm", 'pleased', 'to', 'meet', 'you.'], 'ar': ['سررتُ', 'بمعرفتكِ.'] },
    { 'en': ['He', 'continued', 'walking', 'for', 'some', 'time.'], 'ar': ['واصل', 'المشي', 'لبعض', 'الوقت.'] },
    { 'en': ["Don't", 'yell', 'at', 'me.'], 'ar': ['لا', 'تصرخ', 'في', 'وجهي.'] },
    { 'en': ['He', 'drinks', 'a', 'glass', 'of', 'water', 'every', 'morning.'], 'ar': ['إنه', 'يشرب', 'كأساً', 'من', 'الماء', 'كل', 'صباح.'] },
    { 'en': ['I', 'love', 'that', 'chair.'], 'ar': ['أحب', 'ذاك', 'الكرسي.'] },
    { 'en': ["He's", 'a', 'very', 'selfish', 'person.'], 'ar': ['إنه', 'شخص', 'أناني', 'للغاية.'] },
    { 'en': ['She', 'has', 'a', 'few', 'friends.'], 'ar': ['لديها', 'القليل', 'من', 'الأصدقاء.'] },
    { 'en': ['My', 'father', 'has', 'gone', 'to', 'China.'], 'ar': ['ذهب', 'أبي', 'إلى', 'الصين.'] },
    { 'en': ['Tom', 'almost', 'convinced', 'me.'], 'ar': ['أقنعني', 'توم', 'تقريباً'] },
    { 'en': ["I'm", 'left-handed.'], 'ar': ['أنا', 'أيسر.'] },
    { 'en': ['It', "wasn't", 'the', 'first', 'time', 'they', 'had', 'done', 'it.'], 'ar': ['إنها', 'لم', 'تكن', 'أول', 'مرة', 'يفعلوها.'] },
    { 'en': ['I', 'wanted', 'red', 'shoes.'], 'ar': ['أردت', 'الحذاء', 'الأحمر.'] },
    { 'en': ['She', 'can', 'count', 'from', 'one', 'to', 'ten.'], 'ar': ['يمكنها', 'ان', 'تعد', 'من', 'واحد', 'إلى', 'عشرة.'] },
    { 'en': ['Where', 'are', 'the', 'boys?'], 'ar': ['أين', 'الصبيان؟'] },
    { 'en': ['The', 'police', "didn't", 'find', 'any', 'clues.'], 'ar': ['لم', 'تعثر', 'الشرطة', 'على', 'أية', 'أدلة.'] },
    { 'en': ['We', 'really', 'enjoyed', 'ourselves.'], 'ar': ['تسلينا', 'كثيراً.'] },
    { 'en': ['He', 'raised', 'his', 'hand.'], 'ar': ['رفع', 'يده.'] },
    { 'en': ['This', 'is', 'not', 'true.'], 'ar': ['هذا', 'غير', 'صحيح.'] },
    { 'en': ['I', "wouldn't", 'swim', 'in', 'this', 'lake.'], 'ar': ['لن', 'أسبح', 'بهذه', 'البحيرة'] },
    { 'en': ['Yes,', 'I', 'know.'], 'ar': ['نعم', 'أنا', 'أعلم'] },
    { 'en': ['See', 'you', 'next', 'week!'], 'ar': ['أراك', 'الأسبوع', 'القادم!'] },
    { 'en': ['I', "can't", 'allow', 'you', 'to', 'do', 'that.'], 'ar': ['لا', 'يمكنني', 'السماح', 'لك', 'بفعل', 'ذلك.'] },
    { 'en': ['He', 'is', 'said', 'to', 'have', 'died', 'here.'], 'ar': ['يُقال', 'أنه', 'قد', 'مات', 'هنا.'] },
    { 'en': ['I', 'can', 'wait', 'four', 'days', 'at', 'the', 'longest.'], 'ar': ['أقصى', 'مدة', 'انتظار', 'يمكنني', 'تحملها', 'هي', 'أربعة', 'أيام.'] },
    { 'en': ['I', 'like', 'playing', 'baseball.'], 'ar': ['أحب', 'لعب', 'كرة', 'القاعدة.'] },
    { 'en': ['I', 'regret', 'missing', 'the', 'speech.'], 'ar': ['ندمت', 'على', 'عدم', 'حضوري', 'الخطبة.'] },
    { 'en': ["I'm", 'aware', 'of', 'your', 'problem.'], 'ar': ['أنا', 'أعي', 'مشكلتك.'] },
    { 'en': ['She', 'despised', 'him.'], 'ar': ['كرهته.'] },
    { 'en': ['The', 'women', 'are', 'very', 'supportive.'], 'ar': ['النساء', 'داعِمات', 'جدًّا.'] },
    { 'en': ['In', 'this', 'case,', 'I', 'think', 'he', 'is', 'correct.'], 'ar': ['في', 'هذه', 'الحالة،', 'أعتقد', 'أنه', 'على', 'حق.'] },
    { 'en': ['Will', 'she', 'go', 'to', 'America', 'next', 'year?'], 'ar': ['هل', 'ستذهب', 'إلى', 'أمريكا', 'العام', 'المقبل؟'] },
    { 'en': ["I'm", 'not', 'happy.'], 'ar': ['أنا', 'لست', 'سعيداً.'] },
    { 'en': ['You', 'can', 'ask', 'him', 'for', 'help.'], 'ar': ['يمكنك', 'أن', 'تطلب', 'منه', 'أن', 'يساعدك.'] },
    { 'en': ['The', 'magazine', 'comes', 'out', 'every', 'week.'], 'ar': ['تصدر', 'المجلة', 'كل', 'إسبوع.'] },
    { 'en': ['The', 'dog', 'is', 'dying.'], 'ar': ['الكلب', 'يحتضر.'] },
    { 'en': ['That', 'noise', 'was', 'deafing.'], 'ar': ['كان', 'ذلك', 'الضّجيج', 'يصمّ', 'الآذان.'] },
    { 'en': ['Listen', 'to', 'me,', 'Tom.'], 'ar': ['استمع', 'إلي'] },
    { 'en': ['Tom', "doesn't", 'hate', 'you,', 'Mary.'], 'ar': ['توم', 'لا', 'يكرهكِ', 'يا', 'ماري.'] },
    { 'en': ['The', 'consequences', 'are', 'profound.'], 'ar': ['العواقب', 'وخيمة.'] },
    { 'en': ['I', 'have', 'to', 'go', 'to', 'the', 'bank', 'today.'], 'ar': ['يجب', 'أن', 'أذهب', 'إلى', 'البنك', 'اليوم.'] },
    { 'en': ['Finally,', 'I', 'found', 'a', 'job.'], 'ar': ['أخيراً،', 'وجدت', 'وظيفة.'] },
    { 'en': ['Tom', 'said', 'that', 'Mary', "wasn't", 'tired.'], 'ar': ['قال', 'توم', 'بأن', 'ماري', 'لم', 'تكن', 'متعبة.'] },
    { 'en': ["You're", 'going', 'too', 'far.'], 'ar': ['لقد', 'تعدّيت', 'حدودك.'] },
    { 'en': ['I', 'promise', 'I', 'will', 'protect', 'you.'], 'ar': ['أعدك', 'بأن', 'أحميك.'] },
    { 'en': ['I', 'did', 'not', 'mean', 'to', 'disappoint', 'her.'], 'ar': ['لم', 'أكن', 'أقصد', 'إحباطها.'] },
    { 'en': ['The', 'new', 'plan', 'worked', 'well.'], 'ar': ['نجحت', 'الخطة', 'الجديدة.'] },
    { 'en': ['I', "won't", 'answer', 'questions.'], 'ar': ['لن', 'أردّ', 'على', 'الأسئلة.'] },
    { 'en': ['Welcome.'], 'ar': ['مرحبا!'] },
    { 'en': ['I', 'think', 'Tom', 'knows', 'why.'], 'ar': ['أظن', 'أن', 'توم', 'يعلم', 'لماذا'] },
    { 'en': ['A', 'fork', 'fell', 'off', 'the', 'table.'], 'ar': ['وقعت', 'شوكة', 'من', 'على', 'الطاولة.'] },
    { 'en': ['Go', 'away.'], 'ar': ['اتركني', 'و', 'شأني.'] },
    { 'en': ["I'm", 'working', 'in', 'Tokyo', 'now.'], 'ar': ['إنني', 'أعمل', 'في', 'طوكيو', 'حالياً.'] },
    { 'en': ["Don't", 'say', 'that.'], 'ar': ['لا', 'تقل', 'ذلك.'] },
    { 'en': ["I'm", 'an', 'honest', 'person.'], 'ar': ['أنا', 'شخص', 'صادق.'] },
    { 'en': ["I'll", 'love', 'you', 'forever.'], 'ar': ['سأحبك', 'إلى', 'الأبد.'] },
    { 'en': ['Bring', 'help.'], 'ar': ['أحضِر', 'المُساعدة.'] },
    { 'en': ['Are', 'these', 'books', 'yours?'], 'ar': ['هل', 'هي', 'كتبك', '؟'] },
    { 'en': ['Here', 'is', 'a', 'book.'], 'ar': ['إليك', 'بكتاب.'] },
    { 'en': ["He's", 'never', 'told', 'a', 'lie.'], 'ar': ['لم', 'تُسْمَع', 'منه', 'كِذبة', 'أبدا.'] },
    { 'en': ['How', 'many', 'close', 'friends', 'do', 'you', 'have?'], 'ar': ['كم', 'عندك', 'من', 'الأصدقاء', 'المُقَرّبين؟'] },
    { 'en': ['Tom', 'lost', 'three', 'million', 'dollars', 'last', 'year.'], 'ar': ['خسر', 'توم', 'ثلاثة', 'ملايين', 'دولار', 'السنة', 'الماضية.'] },
    { 'en': ['You', 'run', 'into', 'Japanese', 'tourists', 'everywhere.'], 'ar': ['انت', 'تواجه', 'السياح', 'اليابانيين', 'في', 'كل', 'مكان', '.'] },
    { 'en': ['He', 'seems', 'to', 'have', 'been', 'rich.'], 'ar': ['يبدو', 'عليه', 'أنه', 'كان', 'غنياً', 'في', 'يوم', 'من', 'الأيام.'] },
    { 'en': ["I'm", 'very', 'happy.'], 'ar': ['أنا', 'سعيد', 'جداً.'] },
    { 'en': ["He's", 'my', 'younger', 'brother.'], 'ar': ['إنه', 'أخي', 'الأصغر.'] },
    { 'en': ['Diamonds', 'are', 'a', "girl's", 'best', 'friends.'], 'ar': ['خير', 'أصدقاء', 'الفتاة:', 'الألماس.'] },
    { 'en': ['I', 'need', 'to', 'get', 'a', 'stamp.'], 'ar': ['أحتاج', 'الحصول', 'على', 'طابع', 'بريدي.'] },
    { 'en': ['Your', 'mother', 'is', 'in', 'critical', 'condition.'], 'ar': ['والدتك', 'في', 'حالة', 'حرجة.'] },
    { 'en': ['They', 'became', 'citizens', 'of', 'Japan.'], 'ar': ['أصبحوا', 'من', 'مواطني', 'اليابان.'] },
    { 'en': ['I', 'want', 'one,', 'too.'], 'ar': ['أريد', 'واحداً'] },
    { 'en': ["Here's", 'my', 'card.'], 'ar': ['هذه', 'بطاقتي.'] },
    { 'en': ['I', "don't", 'want', 'it', 'anymore.'], 'ar': ['لم', 'أعد', 'أريدها.'] },
    { 'en': ['The', 'cough', 'syrup', 'has', 'a', 'licorice', 'flavoring.'], 'ar': ['شراب', 'السعال', 'فيه', 'نكهة', 'عرق', 'السوس.'] },
    { 'en': ["Don't", 'despise', 'a', 'man', 'because', 'he', 'is', 'poor.'], 'ar': ['لا', 'تحتقر', 'إنساناً', 'لأنه', 'فقير.'] },
    { 'en': ['He', 'dipped', 'his', 'spoon', 'into', 'the', 'soup.'], 'ar': ['هو', 'غمس', 'ملعقته', 'في', 'الحساء.'] },
    { 'en': ['I', 'know', 'I', "don't", 'have', 'any', 'chance', 'of', 'winning.'], 'ar': ['اعلم', 'بانه', 'ليس', 'لدي', 'اي', 'فرصة', 'للفوز.'] },
    { 'en': ['I', 'remember', 'seeing', 'her', 'once', 'on', 'the', 'street.'], 'ar': ['أتذكر', 'أني', 'رأيتها', 'مرة', 'على', 'الطريق.'] },
    { 'en': ["We're", 'going', 'to', 'miss', 'Tom.'], 'ar': ['سنفتقد', 'توم.'] },
    { 'en': ['Clean', 'up', 'the', 'room.'], 'ar': ['نظف', 'الغرفة.'] },
    { 'en': ['How', 'did', 'you', 'reply?'], 'ar': ['و', 'كيف', 'أجبت؟'] },
    { 'en': ['Possibly', 'she', 'will', 'not', 'come.'], 'ar': ['من', 'الممكن', 'أن', 'لا', 'تأتي.'] },
    { 'en': ['My', 'uncle', 'came', 'to', 'see', 'me.'], 'ar': ['قدم', 'عمي', 'لرؤيتي.'] },
    { 'en': ['We', "don't", 'know', 'him.'], 'ar': ['نحن', 'لا', 'نعرفه.'] },
    { 'en': ["I've", 'promised', 'Tom', 'that', "I'd", 'be', 'there.'], 'ar': ['لقد', 'وعدت', 'توم', 'بأن', 'أكون', 'هناك.'] },
    { 'en': ['Nobody', 'listened', 'to', 'me.'], 'ar': ['لا', 'أحد', 'يستمع', 'إليّ.'] },
    { 'en': ['Please', 'introduce', 'me', 'to', 'Tom.'], 'ar': ['رجاء', 'عرفني', 'على', 'توم.'] },
    { 'en': ['Your', 'watch', 'has', 'been', 'found.'], 'ar': ['عُثر', 'على', 'ساعتك.'] },
    { 'en': ["I'm", 'not', 'your', 'boyfriend.'], 'ar': ['أنا', 'لستُ', 'صديقك.'] },
    { 'en': ['My', 'brother', 'is', 'small', 'but', 'strong.'], 'ar': ['أخي', 'صغير', 'لكنه', 'قوي.'] },
    { 'en': ['I', "don't", 'want', 'to', 'talk', 'to', 'Tom', 'right', 'now.'], 'ar': ['أنا', 'لا', 'أريد', 'أن', 'أتحدث', 'إلى', 'توم', 'الآن.'] },
    { 'en': ['He', 'and', 'I', 'walked', 'together.'], 'ar': ['مشيت', 'معه.'] },
    { 'en': ['I', 'think', 'something', 'may', 'have', 'happened', 'to', 'Tom.'], 'ar': ['أظن', 'أن', 'شيئا', 'ما', 'ربما', 'حدث', 'لتوم.'] },
    { 'en': ['She', 'always', 'keeps', 'her', 'room', 'clean.'], 'ar': ['تحافظ', 'على', 'غرفتها', 'مرتبة', 'دوماً.'] },
    { 'en': ['I', 'know', 'your', 'name.'], 'ar': ['أنا', 'أعرف', 'اسمك'] },
    { 'en': ["I've", 'lost', 'all', 'respect', 'for', 'you.'], 'ar': ['لقد', 'فقدت', 'كل', 'إحترامي', 'لك.'] },
    { 'en': ['Call', 'the', 'police!'], 'ar': ['اتصل', 'بالشرطة!'] },
    { 'en': ["Here's", 'a', 'yellow', 'rose.'], 'ar': ['هذه', 'وردة', 'صفراء.'] },
    { 'en': ['The', 'atomic', 'number', 'for', 'hydrogen', 'is', '1.'], 'ar': ['الرقم', 'الذري', 'للهيدروجين', 'هو', 'واحد.'] },
    { 'en': ['He', 'decided', 'to', 'quit', 'smoking.'], 'ar': ['قرر', 'أن', 'يقلع', 'عن', 'التدخين.'] },
    { 'en': ['You', 'should', 'keep', 'your', 'room', 'clean.'], 'ar': ['ينبغي', 'عليك', 'أن', 'تُبقي', 'غرفتك', 'نظيفة'] },
    { 'en': ['My', 'name', "doesn't", 'appear', 'on', 'the', 'list.'], 'ar': ['اسمي', 'لم', 'يظهر', 'في', 'القائمة.'] },
    { 'en': ['Sit', 'up', 'straight.'], 'ar': ['إجلس', 'مستقيماً.'] },
    { 'en': ["Don't", 'say', 'such', 'a', 'thing.'], 'ar': ['لا', 'تقل', 'شيئاً', 'كهذا.'] },
    { 'en': ['Maybe', "you're", 'right.'], 'ar': ['ربما', 'أنت', 'محق.'] },
    { 'en': ['They', "won't", 'let', 'me', 'see', 'Tom.'], 'ar': ['انهم', 'لن', 'يدعوني', 'ارى', 'توم.'] },
    { 'en': ['I', 'want', 'to', 'become', 'a', 'doctor.'], 'ar': ['أريد', 'أن', 'أصير', 'دكتورًا.'] },
    { 'en': ['I', 'am', 'married.'], 'ar': ['أنا', 'متزوج.'] },
    { 'en': ["He's", 'ready', 'now.'], 'ar': ['إنهُ', 'مُسعد', 'الأن.'] },
    { 'en': ['I', 'never', 'liked', 'biology.'], 'ar': ['لم', 'أحب', 'مادة', 'الأحياء', 'أبداً.'] },
    { 'en': ['The', 'king', 'governed', 'the', 'country.'], 'ar': ['حكم', 'الملك', 'البلد.'] },
    { 'en': ['We', 'really', 'want', 'to', 'help', 'Tom.'], 'ar': ['نريد', 'حقا', 'ان', 'نساعد', 'توم.'] },
    { 'en': ['Just', 'tell', 'me', 'what', 'you', 'want.'], 'ar': ['فقط', 'أخبريني', 'ما', 'الذي', 'تريدينهُ.'] },
    { 'en': ['Is', 'Tom', 'looking', 'at', 'me?'], 'ar': ['هل', 'يحدّق', 'توم', 'فيَّ؟'] },
    { 'en': ['He', 'lives', 'in', 'Tokyo.'], 'ar': ['إنه', 'يعيش', 'في', 'طوكيو.'] },
    { 'en': ['I', 'like', 'the', 'way', 'you', 'treat', 'me.'], 'ar': ['أحب', 'معاملتك', 'لي.'] },
    { 'en': ['Tom', 'gave', 'me', 'a', 'pen.'], 'ar': ['أعطاني', 'توم', 'قلماً.'] },
    { 'en': ['This', "isn't", 'French.'], 'ar': ['هذه', 'ليست', 'فرنسية'] },
    { 'en': ['The', 'ship', 'was', 'at', 'the', 'mercy', 'of', 'the', 'waves.'], 'ar': ['كانت', 'السفينة', 'تحت', 'رحمة', 'الأمواج.'] },
    { 'en': ['I', "don't", 'want', 'to', 'see', 'him', 'at', 'all.'], 'ar': ['لا', 'أريد', 'أن', 'أراه', 'بعد', 'الآن.'] },
    { 'en': ['Tom', 'got', 'these', 'tickets', 'for', 'free.'], 'ar': ['حصل', 'توم', 'على', 'هذه', 'التذاكر', 'بالمجان.'] },
    { 'en': ["You're", 'kidding!'], 'ar': ['لا', 'بد', 'أنك', 'تمزح!'] },
    { 'en': ['I', 'watched', 'TV', 'this', 'morning.'], 'ar': ['شاهدت', 'التلفاز', 'هذا', 'الصباح.'] },
    { 'en': ['The', 'brake', "didn't", 'work.'], 'ar': ['لم', 'تعمل', 'المكابح.'] },
    { 'en': ['We', 'are', 'men.'], 'ar': ['نحن', 'رجال.'] },
    { 'en': ['Wait.', 'I', 'want', 'to', 'tell', 'you', 'something.'], 'ar': ['انتظر،', 'أريد', 'إخبارك', 'بأمر', 'ما.'] },
    { 'en': ["He's", 'stupid', 'and', 'dishonest.'], 'ar': ['هو', 'غبي', 'وغير', 'أمين.'] },
    { 'en': ['She', 'bought', 'a', 'toy', 'for', 'her', 'child.'], 'ar': ['اشترت', 'لعبة', 'لطفلها.'] },
    { 'en': ['My', 'dream', 'has', 'come', 'true.'], 'ar': ['تحقق', 'حلمي.'] },
    { 'en': ['Tom', "isn't", 'going', 'to', 'win.'], 'ar': ['لن', 'يفوز', 'توم.'] },
    { 'en': ['What', 'is', 'going', 'on', 'here?'], 'ar': ['ماذا', 'يحدث', 'هنا؟'] },
    { 'en': ['Stop', 'teasing', 'your', 'brother!'], 'ar': ['توقف', 'عن', 'غيظ', 'أخيك!'] },
    { 'en': ["That's", 'a', 'relief.'], 'ar': ['هذا', 'يدعو', 'للراحة.'] },
    { 'en': ['She', 'provided', 'me', 'with', 'some', 'food.'], 'ar': ['هي', 'قدمت', 'لي', 'بعض', 'الطعام.'] },
    { 'en': ['Hawaii', 'is', 'a', 'popular', 'tourist', 'resort.'], 'ar': ['هاواي', 'هي', 'منتجع', 'سياحي', 'مشهور', '.'] },
    { 'en': ['He', "didn't", 'know', 'that.'], 'ar': ['إنه', 'لم', 'يعلم', 'بذلك', '.'] },
    { 'en': ['Does', 'she', 'know', 'you?'], 'ar': ['هل', 'تعرفك؟'] },
    { 'en': ['Shut', 'the', 'door,', 'will', 'you?'], 'ar': ['هل', 'لك', 'أن', 'تغلق', 'الباب؟'] },
    { 'en': ['I', 'just', 'wanted', 'to', 'hear', 'your', 'voice.'], 'ar': ['أردت', 'فقط', 'سماع', 'صوتك.'] },
    { 'en': ['I', 'think', "you're", 'mistaken.'], 'ar': ['أظنّ', 'أنّك', 'مخطئ.'] },
    { 'en': ['Tom', 'keeps', 'a', 'black', 'cat.'], 'ar': ['توم', 'يربّي', 'قطة', 'سوداء.'] },
    { 'en': ['When', 'do', 'you', 'plan', 'to', 'leave', 'for', 'Japan?'], 'ar': ['متى', 'تنوي', 'السفر', 'إلى', 'اليابان؟'] },
    { 'en': ['It', 'was', 'a', 'very', 'big', 'room.'], 'ar': ['كانت', 'غرفة', 'كبيرة.'] },
    { 'en': ['Tom', 'tried', 'to', 'kill', 'us.'], 'ar': ['حاول', 'توم', 'قتلنا!'] },
    { 'en': ['May', 'I', 'come', 'in?'], 'ar': ['هل', 'بإمكاني', 'الدخول؟'] },
    { 'en': ['We', 'have', 'to', 'expect', 'the', 'worst.'], 'ar': ['علينا', 'أن', 'نتوقع', 'الأسوأ.'] },
    { 'en': ['Let', 'me', 'sleep', 'for', 'another', 'ten', 'minutes.'], 'ar': ['دعني', 'أنم', 'عشر', 'دقائق', 'أخرى.'] },
    { 'en': ['You', 'must', 'do', 'your', 'duty.'], 'ar': ['عليك', 'أن', 'تقوم', 'بواجبك.'] },
    { 'en': ['He', 'lied', 'to', 'us.'], 'ar': ['كذب', 'علينا.'] },
    { 'en': ["There's", 'a', 'hotel', 'across', 'the', 'street.'], 'ar': ['يوجد', 'هناك', 'فندق', 'عبر', 'الشارع.'] },
    { 'en': ['My', 'aunt', 'gave', 'me', 'a', 'book', 'for', 'Christmas.'], 'ar': ['أهدتني', 'عمتي', 'كتابًا', 'في', 'عيد', 'ميلاد', 'المسيح.'] },
    { 'en': ['I', 'think', 'he', 'is', 'angry.'], 'ar': ['أنا', 'أعتقد', 'انهُ', 'غاضب.'] },
    { 'en': ['This', 'boat', 'is', 'no', 'longer', 'seaworthy.'], 'ar': ['هذا', 'القارب', 'لم', 'يعد', 'صالحاً', 'للإبحار.'] },
    { 'en': ["That's", 'his', 'specialty.'], 'ar': ['هذا', 'تخصصه.'] },
    { 'en': ['Of', 'course!'], 'ar': ['طبعاً!'] },
    { 'en': ['I', 'believe', 'you.'], 'ar': ['أصدقك.'] },
    { 'en': ['You', "don't", 'give', 'up', 'too', 'easily,', 'do', 'you?'], 'ar': ['أنت', 'لا', 'تستسلم', 'بسهولة', 'جداً،', 'أليس', 'كذلك؟'] },
    { 'en': ['He', 'has', 'a', 'car.'], 'ar': ['إنه', 'يملك', 'سيارة.'] },
    { 'en': ['He', 'feels', 'a', 'lot', 'better', 'today.'], 'ar': ['هو', 'اليوم', 'يشعر', 'أنه', 'أفضل', 'بكثير.'] },
    { 'en': ['I', "don't", 'think', "that's", 'correct.'], 'ar': ['أشكّ', 'في', 'صحة', 'ذلك.'] },
    { 'en': ['Has', 'Tom', 'really', 'come', 'back?'], 'ar': ['هل', 'عاد', 'توم', 'حقا؟'] },
    { 'en': ['Is', 'it', 'new?'], 'ar': ['هل', 'هذا', 'جديد؟'] },
    { 'en': ["I've", 'been', 'looking', 'forward', 'to', 'this', 'all', 'week.'], 'ar': ['كنت', 'أتطلع', 'لهذا', 'طوال', 'الأسبوع.'] },
    { 'en': ['What', 'do', 'you', 'want', 'to', 'do', 'in', 'the', 'future?'], 'ar': ['ماذا', 'تريد', 'أن', 'تفعل', 'في', 'المستقبل؟'] },
    { 'en': ["I'll", 'never', 'ask', 'Tom', 'for', 'help', 'again.'], 'ar': ['لن', 'أطلب', 'المساعدة', 'من', 'توم', 'مرة', 'أخرى.'] },
    { 'en': ['Where', 'are', 'they?'], 'ar': ['أين', 'هم؟'] },
    { 'en': ['We', 'know', 'him.'], 'ar': ['نحن', 'نعرفه'] },
    { 'en': ['I', 'bet', 'everyone', 'is', 'waiting.'], 'ar': ['أنا', 'متأكّد', 'أنّ', 'الجميع', 'ينتظر.'] },
    { 'en': ['Go', 'to', 'your', 'room!'], 'ar': ['إذهبي', 'إلىَ', 'غرقتِك!'] },
    { 'en': ['She', 'accused', 'me', 'of', 'being', 'a', 'liar.'], 'ar': ['اتهمتني', 'بالكذب.'] },
    { 'en': ['He', 'is', 'sick.'], 'ar': ['إنه', 'مريض.'] },
    { 'en': ['What', 'would', 'you', 'say', 'if', 'you', 'were', 'in', 'my', 'place?'], 'ar': ['ماذا', 'كنتَ', 'ستقول', 'لو', 'كنت', 'في', 'مكاني؟'] },
    { 'en': ["Where're", 'the', 'shoes?'], 'ar': ['أين', 'الأحذية؟'] },
    { 'en': ['You', 'ticked', 'them', 'off.'], 'ar': ['لقد', 'اغضبتهم', 'انت', '.'] },
    { 'en': ["Don't", 'say', 'such', 'a', 'thing.'], 'ar': ['لا', 'تقل', 'شيئاً', 'مثل', 'ذلك.'] },
    { 'en': ['I', 'no', 'longer', 'live', 'here.'], 'ar': ['أنا', 'لم', 'أعد', 'أعيش', 'هنا.'] },
    { 'en': ['She', 'likes', 'ice', 'cream.'], 'ar': ['إنها', 'تحب', 'المثلجات', '.'] },
    { 'en': ['He', 'must', 'find', 'work.'], 'ar': ['هوَ', 'لابد', 'أن', 'يجد', 'عملاً.'] },
    { 'en': ['Thanks', 'again.'], 'ar': ['شكرا', 'مرة', 'أخرى'] },
    { 'en': ['No', 'one', 'knows.'], 'ar': ['لا', 'أحد', 'يعلم'] },
    { 'en': ['Her', 'husband', 'is', 'now', 'living', 'in', 'Tokyo.'], 'ar': ['يعيش', 'زوجها', 'الآن', 'في', 'طوكيو.'] },
    { 'en': ['Show', 'me', 'how', 'it', 'works.'], 'ar': ['أرني', 'كيف', 'تعمل.'] },
    { 'en': ['We', 'are', 'facing', 'a', 'violent', 'crisis.'], 'ar': ['إننا', 'نواجه', 'أزمة', 'ضخمة.'] },
    { 'en': ['Switch', 'on', 'the', 'light.', 'I', "can't", 'see', 'anything.'], 'ar': ['أشعل', 'الضوء.', 'لا', 'يمكنني', 'أن', 'أرى', 'شيئاً.'] },
    { 'en': ['Whales', 'feed', 'on', 'small', 'fish.'], 'ar': ['تتغذى', 'الحيتان', 'على', 'الأسماك', 'الصغيرة.'] },
    { 'en': ["You're", 'crazy.'], 'ar': ['إنك', 'مجنون.'] },
    { 'en': ['Why', 'were', 'you', 'late?'], 'ar': ['لماذا', 'تأخرت؟'] },
    { 'en': ['Tom', 'died.'], 'ar': ['توم', 'مات.'] },
    { 'en': ["She's", 'been', 'working', 'all', 'day', 'long.'], 'ar': ['كانت', 'تعمل', 'طوال', 'اليوم.'] },
    { 'en': ["That's", 'a', 'brilliant', 'idea.'], 'ar': ['هذهِ', 'فكرة', 'رائعة.'] },
    { 'en': ['Can', 'you', 'really', 'not', 'swim?'], 'ar': ['أحقاً', 'لا', 'يمكنك', 'السباحة؟'] },
    { 'en': ['Congratulations!'], 'ar': ['مبارك!'] },
    { 'en': ["I've", 'just', 'finished', 'eating.'], 'ar': ['لقد', 'إنتهيت', 'للتو', 'من', 'تناول', 'الطعام.'] },
    { 'en': ['We', 'want', 'to', 'know', 'why.'], 'ar': ['نريد', 'معرفة', 'السبب.'] },
    { 'en': ['You', "can't", 'run', 'my', 'life.'], 'ar': ['لا', 'يمكنك', 'التحكم', 'في', 'حياتي.'] },
    { 'en': ["I've", 'never', 'seen', 'Tom', 'dance.'], 'ar': ['لم', 'أرَ', 'توم', 'يرقص', 'أبدا.'] },
    { 'en': ['I', 'want', 'to', 'go', 'with', 'you.'], 'ar': ['أريد', 'الذهاب', 'معك.'] },
    { 'en': ['I', 'was', 'robbed', 'of', 'my', 'bag.'], 'ar': ['سُرقت', 'مني', 'حقيبتي.'] },
    { 'en': ['You', 'go', 'first.'], 'ar': ['اذهب', 'أنت', 'أولاً.'] },
    { 'en': ["It's", 'because', 'he', 'loves', 'me.'], 'ar': ['بسبب', 'حبه', 'لي.'] },
    { 'en': ['She', 'speaks', 'relatively', 'quickly.'], 'ar': ['هي', 'تتحدث', 'بسرعة', 'نسبيا'] },
    { 'en': ['Please', 'look', 'at', 'me.'], 'ar': ['انظر', 'إليّ', 'من', 'فضلك.'] },
    { 'en': ['Your', 'house', 'is', 'very', 'nice.'], 'ar': ['ما', 'أروع', 'منزلك!'] },
    { 'en': ['I', 'want', 'an', 'MP3', 'player!'], 'ar': ['أريد', 'مشغل', 'MP3.'] },
    { 'en': ["They're", 'treating', 'me', 'like', 'a', 'child.'], 'ar': ['إنهم', 'يعاملوني', 'كطفل.'] },
    { 'en': ['I', 'have', 'been', 'dealt', 'four', 'aces.'], 'ar': ['لقد', 'رميت', 'اربع', 'اوراق', 'من', 'نوع', 'أس.'] },
    { 'en': ['What', 'should', 'I', 'do?'], 'ar': ['ماذا', 'أفعل', 'الآن؟'] },
    { 'en': ['What', 'do', 'you', 'mean', 'by', 'that?'], 'ar': ['ماذا', 'تقصد', 'بذلك؟'] },
    { 'en': ['Tom', "can't", 'speak', 'very', 'much', 'French.'], 'ar': ['لا', 'يستطيع', 'توم', 'التكلم', 'بالفرنسية', 'كثيرا.'] },
    { 'en': ['No', 'words', 'can', 'relieve', 'her', 'deep', 'sorrow.'], 'ar': ['الكلمات', 'لا', 'يسعها', 'التخفيف', 'عن', 'حزنها', 'العميق.'] },
    { 'en': ['This', 'book', 'belongs', 'to', 'the', 'library.'], 'ar': ['هذا', 'الكتاب', 'ملكٌ', 'للمكتبة.'] },
    { 'en': ['And', 'everyone', 'has', 'the', 'ability', 'to', 'contribute.'], 'ar': ['ويستطيع', 'الجميعُ', 'المساهمةَ.'] },
    { 'en': ['What', 'time', 'do', 'you', 'usually', 'go', 'to', 'bed?'], 'ar': ['متى', 'تخلد', 'إلى', 'النوم', 'عادةً؟'] },
    { 'en': ['Are', 'you', 'writing', 'a', 'letter?'], 'ar': ['هل', 'تكتب', 'رسالةً؟'] },
    { 'en': ["I'm", 'very', 'busy.'], 'ar': ['إنني', 'مشغول', 'جداً.'] },
    { 'en': ['My', 'orders', 'are', 'absolute.'], 'ar': ['أوامري', 'مُطْلَقَة.'] },
    { 'en': ["She's", 'eating', 'an', 'apple.'], 'ar': ['إنها', 'تأكل', 'تفاحة.'] },
    { 'en': ['I', 'was', 'rear-ended', 'by', 'a', 'pickup', 'truck.'], 'ar': ['لقد', 'اصطدمت', 'من', 'الخلف', 'بشاحنة', 'صغيرة', '.'] },
    { 'en': ['You', 'are', 'rich.'], 'ar': ['إنّك', 'غنيّ.'] },
    { 'en': ['Am', 'I', 'clear?'], 'ar': ['هل', 'هذا', 'واضح؟'] },
    { 'en': ['Look', 'it', 'up', 'in', 'your', 'dictionary.'], 'ar': ['طالعها', 'في', 'قاموسك.'] },
    { 'en': ['I', "don't", 'like', 'this', 'idea', 'at', 'all.'], 'ar': ['لا', 'تعجبني', 'هذه', 'الفكرة', 'البتة.'] },
    { 'en': ['Have', 'you', 'ever', 'been', 'to', 'America?'], 'ar': ['هل', 'سبق', 'أن', 'ذهبت', 'إلى', 'أمريكا؟'] },
    { 'en': ['I', 'caused', 'this.'], 'ar': ['أنا', 'سببّت', 'هذا.'] },
    { 'en': ['The', 'important', 'thing', 'is', "you're", 'here.'], 'ar': ['كل', 'ما', 'يهم', 'هو', 'أنك', 'هنا.'] },
    { 'en': ['You', 'should', 'obey', 'your', 'parents.'], 'ar': ['عليك', 'أن', 'تطيع', 'والديك.'] },
    { 'en': ['Watch', 'the', 'rear.', "I'll", 'watch', 'the', 'front.'], 'ar': ['راقب', 'الجزء', 'الخلفي', '.', 'ساراقب', 'انا', 'الجزء', 'الامامي.'] },
    { 'en': ['We', 'panicked', 'a', 'little', 'bit.'], 'ar': ['شعرنا', 'بالقليل', 'من', 'الذعر.'] },
    { 'en': ['He', 'loves', 'traveling.'], 'ar': ['إنه', 'يحب', 'السفر.'] },
    { 'en': ['My', 'hair', "isn't", 'as', 'long', 'as', "Tom's."], 'ar': ['شعري', 'ليس', 'بطول', 'شعر', 'توم.'] },
    { 'en': ['This', 'is', 'very', 'important.'], 'ar': ['هذا', 'مهم', 'جداً.'] },
    { 'en': ["Don't", 'interrupt', 'our', 'conversation.'], 'ar': ['لا', 'تقاطع', 'حديثنا.'] },
    { 'en': ['He', "hasn't", 'come', 'yet.'], 'ar': ['لم', 'يأتِ', 'بعد.'] },
    { 'en': ["She's", 'as', 'clever', 'as', 'they', 'make', "'em."], 'ar': ['إنها', 'حاذقة', 'الذكاء.'] },
    { 'en': ['I', "don't", 'really', 'know.'], 'ar': ['حقيقةً', 'لا', 'أعلم.'] },
    { 'en': ['I', 'am', 'determined', 'to', 'be', 'a', 'scientist.'], 'ar': ['عقدتُ', 'العزم', 'على', 'أن', 'أكون', 'عالمًا', 'في', 'العلوم', 'الطبيعية.'] },
    { 'en': ['They', "haven't", 'come', 'back', 'home', 'yet.'], 'ar': ['لم', 'يعودوا', 'إلى', 'المنزل', 'بعد.'] },
    { 'en': ['I', 'have', 'a', 'house.'], 'ar': ['عندي', 'بيت.'] },
    { 'en': ["I'll", 'get', 'my', 'bag.'], 'ar': ['سوفَ', 'أُحضرُ', 'حقيبتي.'] },
    { 'en': ['Thanks', 'for', 'your', 'explanation.'], 'ar': ['أشكرك', 'لشرحك', 'لي', 'ذلك.'] },
    { 'en': ['They', 'come', 'from', 'Sweden.'], 'ar': ['هم', 'من', 'السويد'] },
    { 'en': ['The', 'bank', "isn't", 'open', 'on', 'Sundays.'], 'ar': ['لا', 'يكون', 'المصرف', 'مفتوحاً', 'أيام', 'الأحد.'] },
    { 'en': ['You', "aren't", 'busy,', 'are', 'you?'], 'ar': ['لست', 'مشغولاً،', 'صحيح؟'] },
    { 'en': ['He', 'was', 'covered', 'in', 'mud', 'from', 'head', 'to', 'foot.'], 'ar': ['غطاه', 'الوحل', 'من', 'رأسه', 'حتى', 'قدميه.'] },
    { 'en': ['She', 'must', 'still', 'be', 'in', 'her', 'twenties.'], 'ar': ['لا', 'بد', 'أنها', 'ما', 'زالت', 'في', 'العشرينات', 'من', 'عمرها.'] },
    { 'en': ['The', 'house', 'has', 'three', 'floors.'], 'ar': ['المنزل', 'لديهِ', 'ثلاثة', 'طوابق.'] },
    { 'en': ['I', 'am', 'only', 'joking.'], 'ar': ['أنا', 'أضحك', 'فقط.'] },
    { 'en': ['She', 'finished', 'reading', 'the', 'letter.'], 'ar': ['لقد', 'أنهت', 'قراءة', 'الرسالة.'] },
    { 'en': ['Out', 'of', 'my', 'way,', 'boy.'], 'ar': ['ابتعد', 'عن', 'طريقي', 'يا', 'فتى.'] },
    { 'en': ['They', 'decided', 'to', 'get', 'married.'], 'ar': ['قررا', 'أن', 'يتزوجا.'] },
    { 'en': ['Who', 'am', 'I', 'talking', 'with?'], 'ar': ['مع', 'من', 'أتكلم؟'] },
    { 'en': ['I', 'miss', 'you', 'very', 'much.'], 'ar': ['أنا', 'مشتاق', 'إليك', 'جداً.'] },
    { 'en': ['My', 'father', 'has', 'never', 'been', 'abroad.'], 'ar': ['لم', 'يسبق', 'لأبي', 'أن', 'سافر', 'إلى', 'الخارج.'] },
    { 'en': ["That's", 'your', 'responsibility.'], 'ar': ['تلك', 'مسؤوليتك.'] },
    { 'en': ['What', 'do', 'you', 'suggest?'], 'ar': ['ماذا', 'تقترح؟'] },
    { 'en': ['You', 'have', 'made', 'the', 'very', 'same', 'mistake', 'again.'], 'ar': ['وقعت', 'في', 'نفس', 'الخطأ', 'مرة', 'أخرى.'] },
    { 'en': ['I', 'made', 'him', 'open', 'the', 'door.'], 'ar': ['أرغمته', 'أن', 'يفتح', 'الباب.'] },
    { 'en': ["I'm", 'from', 'Romania.'], 'ar': ['أنا', 'من', 'رومانيا.'] },
    { 'en': ['I', 'usually', 'go', 'home', 'at', 'four.'], 'ar': ['أعود', 'إلى', 'البيت', 'عادةً', 'عند', 'الساعة', 'الرابعة.'] },
    { 'en': ['They', "didn't", 'do', 'much.'], 'ar': ['لم', 'يقومو', 'بالكثير'] },
    { 'en': ['Tom', 'knew', 'I', "wasn't", 'happy.'], 'ar': ['علم', 'توم', 'أنني', 'لست', 'سعيدا'] },
    { 'en': ['This', 'is', 'just', 'what', 'Tom', "didn't", 'want', 'to', 'happen.'], 'ar': ['هذا', 'بالضبط', 'ما', 'لم', 'يكن', 'توم', 'يريد', 'حدوثه.'] },
    { 'en': ['I', 'just', "don't", 'know', 'what', 'to', 'say.'], 'ar': ['لا', 'أعرف', 'ماذا', 'أقول.'] },
    { 'en': ['I', "can't", 'stand', 'his', 'arrogance.'], 'ar': ['لا', 'أطيق', 'عجرفته.'] },
    { 'en': ['That', "isn't", 'fair.'], 'ar': ['هذا', 'ليس', 'عدلاً.'] },
    { 'en': ['Tom', 'needs', 'a', 'good', 'lawyer.'], 'ar': ['توم', 'يحتاج', 'الى', 'محامٍ', 'جيد.'] },
    { 'en': ['May', 'I', 'help', 'you?'], 'ar': ['هل', 'لي', 'أن', 'أساعدك؟'] },
    { 'en': ['I', 'need', 'a', 'pencil.'], 'ar': ['أحتاج', 'لقلم.'] },
    { 'en': ['You', 'should', 'be', 'ashamed', 'of', 'your', 'ignorance.'], 'ar': ['ينبغي', 'أن', 'تخجل', 'من', 'جهلك.'] },
    { 'en': ['Can', 'I', 'have', 'this', 'orange?'], 'ar': ['ألي', 'بهذه', 'البرتقالة؟'] },
    { 'en': ['The', 'doctor', 'examined', 'the', 'patients.'], 'ar': ['فحص', 'الطبيب', 'المرضى.'] },
    { 'en': ['My', 'mother', "can't", 'come.'], 'ar': ['لن', 'تسطيع', 'أمي', 'القدوم.'] },
    { 'en': ['Look', 'at', 'this.'], 'ar': ['أُنظر', 'إلى', 'هذا.'] },
    { 'en': ['My', 'favorite', 'color', 'is', 'red.'], 'ar': ['لوني', 'المفضّل', 'هو', 'الأحمر.'] },
    { 'en': ["You've", 'earned', 'it.'], 'ar': ['أنتَ', 'ربحتها.'] },
    { 'en': ['Which', 'is', 'your', 'bag?'], 'ar': ['أيها', 'حقيبتك؟'] },
    { 'en': ['I', "don't", 'want', 'to', 'lose', 'you.'], 'ar': ['لا', 'أريد', 'أن', 'أخسركَ.'] },
    { 'en': ["I'll", 'never', 'do', 'it', 'again.'], 'ar': ['لن', 'أفعلها', 'مجدداً.'] },
    { 'en': ['Do', 'you', 'have', 'a', 'violin?'], 'ar': ['هل', 'تملك', 'كمانًا؟'] },
    { 'en': ['Does', 'this', 'come', 'in', 'any', 'other', 'color?'], 'ar': ['أيتوافر', 'هذا', 'بلون', 'مختلف؟'] },
    { 'en': ['Do', 'you', 'speak', 'Catalan?'], 'ar': ['هل', 'تتحدث', 'الكتالونية؟'] },
    { 'en': ['I', 'think', "you've", 'mistaken', 'me', 'for', 'someone', 'else.'], 'ar': ['أعتقد', 'أنك', 'أخطأتني', 'بشخص', 'آخر.'] },
    { 'en': ['Is', 'this', 'the', 'way', 'to', 'the', 'station?'], 'ar': ['أهذا', 'هو', 'الطريق', 'إلى', 'المحطة؟'] },
    { 'en': ['The', 'cargo', 'ship', 'arrived', 'four', 'hours', 'early.'], 'ar': ['سفينة', 'الشحن', 'وصلت', 'مبكرة', 'باربع', 'ساعات.'] },
    { 'en': ['You', 'are', 'tired,', "aren't", 'you?'], 'ar': ['إنك', 'متعب', 'أليس', 'كذلك؟'] },
    { 'en': ['I', 'remember', 'giving', 'him', 'the', 'key.'], 'ar': ['أذكر', 'أني', 'أعطيته', 'المفتاح.'] },
    { 'en': ['He', "doesn't", 'know', 'a', 'lot', 'about', 'Japan.'], 'ar': ['إنه', 'لا', 'يعرف', 'الكثير', 'عن', 'اليابان.'] },
    { 'en': ['I', 'learned', 'to', 'live', 'without', 'her.'], 'ar': ['تعلّمتُ', 'العيش', 'دونها.'] },
    { 'en': ['Sunday', 'is', 'not', 'a', 'workday', 'for', 'me.'], 'ar': ['الأحد', 'ليس', 'يوم', 'عمل', 'بالنسبة', 'لي.'] },
    { 'en': ['Turn', 'down', 'the', 'TV.'], 'ar': ['أخفض', 'صوت', 'التلفاز.'] },
    { 'en': ['Who', 'is', 'standing', 'there?'], 'ar': ['من', 'يقف', 'هناك؟'] },
    { 'en': ['Friends', 'should', 'help', 'one', 'another.'], 'ar': ['على', 'الأصدقاء', 'مساعدة', 'بعضهم', 'البعض.'] },
    { 'en': ['I', 'know', 'how', 'Tom', 'feels.'], 'ar': ['اعرف', 'كيف', 'يشعر', 'توم.'] },
    { 'en': ['You', 'look', 'very', 'tired.'], 'ar': ['تبدو', 'متعباً', 'للغاية.'] },
    { 'en': ['Please', 'bring', 'it', 'back', 'tomorrow.'], 'ar': ['أعده', 'غدا', 'من', 'فضلك.'] },
    { 'en': ['Please', 'write', 'with', 'a', 'pen.'], 'ar': ['من', 'فضلك', 'اِكتب', 'بقلم', 'حبر.'] },
    { 'en': ['Why', 'do', 'you', 'ask?'], 'ar': ['لم', 'تسأل؟'] },
    { 'en': ['I', 'wonder', 'what', 'happened.'], 'ar': ['أتساءل', 'ما', 'الذي', 'حدث.'] },
    { 'en': ["There's", 'only', 'a', 'little', 'milk', 'left.'], 'ar': ['لم', 'يعد', 'هناك', 'إلا', 'القليل', 'من', 'الحليب.'] },
    { 'en': ['Can', 'you', 'see', 'the', 'difference?'], 'ar': ['أيمكنك', 'ملاحظة', 'الفرق؟'] },
    { 'en': ["He's", 'in', 'a', 'bad', 'state.'], 'ar': ['هو', 'في', 'حالةٍ', 'سيئة.'] },
    { 'en': ['The', 'explanation', 'was', 'beyond', 'my', 'understanding.'], 'ar': ['هذا', 'الشرح', 'يتخطى', 'طاقتي', 'الاستعابية.'] },
    { 'en': ['Why', 'did', 'she', 'come', 'home', 'early?'], 'ar': ['لماذا', 'عادت', 'إلى', 'المنزل', 'باكراً؟'] },
    { 'en': ['Do', 'you', 'want', 'fruit', 'juice?'], 'ar': ['هل', 'تريد', 'عصير', 'فاكهة؟'] },
    { 'en': ['I', 'like', 'studying', 'languages.'], 'ar': ['أحب', 'تعلم', 'اللغات.'] },
    { 'en': ['Tom', 'leaned', 'down', 'and', 'picked', 'up', 'a', 'rock.'], 'ar': ['انحنى', 'توم', 'والتقط', 'حجرا.'] },
    { 'en': ['George', 'Washington', 'was', 'born', 'in', '1732.'], 'ar': ['ولد', 'جورج', 'واشنطن', 'عام', '1732.'] },
    { 'en': ['Is', 'this', 'a', 'recent', 'photo?'], 'ar': ['هل', 'أُخذت', 'هذه', 'الصورة', 'حديثاً؟'] },
    { 'en': ['This', 'is', 'my', 'notebook.'], 'ar': ['هذا', 'دفتري.'] },
    { 'en': ['Fortunately,', 'no', 'one', 'was', 'hurt.'], 'ar': ['لحسن', 'الحظ', 'لم', 'يتأذ', 'أحد.'] },
    { 'en': ["She's", 'my', 'classmate.'], 'ar': ['إنها', 'زميلتي.'] },
    { 'en': ["Let's", 'not', 'be', 'in', 'too', 'much', 'of', 'a', 'hurry.'], 'ar': ['دعنا', 'لا', 'نستعجل', 'كثيرًا.'] },
    { 'en': ['We', 'live', 'in', 'the', 'atomic', 'age.'], 'ar': ['إنا', 'نعيش', 'في', 'عصر', 'الذّرّة.'] },
    { 'en': ['Where', 'would', 'you', 'like', 'to', 'go', 'first?'], 'ar': ['إلى', 'أين', 'تفضل', 'أن', 'تذهب', 'أولاً؟'] },
    { 'en': ['We', 'have', 'to', 'do', 'the', 'shopping.'], 'ar': ['علينا', 'التسوق.'] },
    { 'en': ['I', 'retired', 'last', 'year.'], 'ar': ['خرجت', 'على', 'المعاش', 'السنة', 'الفائتة.'] },
    { 'en': ['They', 'are', 'still', 'children.'], 'ar': ['ما', 'زالوا', 'صغارًا.'] },
    { 'en': ['Take', 'care.'], 'ar': ['اعتن', 'بنفسك.'] },
    { 'en': ['I', 'felt', 'sad.'], 'ar': ['شعرت', 'بالحزن.'] },
    { 'en': ['Why', 'do', 'you', 'want', 'to', 'study', 'abroad?'], 'ar': ['لماذا', 'تريد', 'الدراسة', 'في', 'الخارج؟'] },
    { 'en': ['We', 'are', 'going', 'to', 'check', 'it', 'right', 'away.'], 'ar': ['نحنُ', 'ذاهبون', 'للتحقق', 'من', 'ذلك', 'علي', 'الفور.'] },
    { 'en': ['It', 'is', 'a', 'difficult', 'problem.'], 'ar': ['هذه', 'معضلة.'] },
    { 'en': ['We', 'are', 'on', 'the', 'way', 'home.'], 'ar': ['نحن', 'في', 'طريقنا', 'إلى', 'المنزل.'] },
    { 'en': ['The', 'train', 'leaves', 'in', 'ten', 'minutes.'], 'ar': ['القطار', 'سينطلق', 'في', 'غضون', 'عشر', 'دقائق.'] },
    { 'en': ['We', 'all', 'desire', 'success.'], 'ar': ['كلنا', 'نريد', 'التفوق.'] },
    { 'en': ['Tom', 'listened', 'to', 'classical', 'music.'], 'ar': ['توم', 'استمع', 'إلى', 'الموسيقى', 'الكلاسيكية.'] },
    { 'en': ['She', 'is', 'looking', 'for', 'her', 'car', 'keys.'], 'ar': ['إنها', 'تبحث', 'عن', 'مفاتيح', 'سيارتها.'] },
    { 'en': ['The', 'floor', 'was', 'covered', 'with', 'blood.'], 'ar': ['كانت', 'الأرض', 'مغطاة', 'بالدم.'] },
    { 'en': ['He', 'taught', 'himself', 'French.'], 'ar': ['علم', 'نفسه', 'الفرنسية.'] },
    { 'en': ['Turn', 'off', 'the', 'light.'], 'ar': ['أطفئ', 'الضوء.'] },
    { 'en': ['Their', 'punishment', 'was', 'harsh,', 'but', 'just.'], 'ar': ['كان', 'عقابهم', 'قاسيًا', 'ولكنه', 'عادل.'] },
    { 'en': ['He', 'drowned', 'in', 'the', 'river.'], 'ar': ['غرق', 'في', 'النهر.'] },
    { 'en': ['Ostriches', "can't", 'fly.'], 'ar': ['لا', 'يمكن', 'للنعام', 'أن', 'يطير.'] },
    { 'en': ['I', 'heard', 'an', 'unusual', 'noise.'], 'ar': ['سمعت', 'صوت', 'ضجة', 'غريبة.'] },
    { 'en': ['She', 'filled', 'her', 'bag', 'with', 'apples.'], 'ar': ['ملأت', 'كيسها', 'بالتفاح.'] },
    { 'en': ['Was', 'I', 'really', 'boring?'], 'ar': ['هل', 'كنت', 'مملاً', 'بالفعل؟'] },
    { 'en': ['Are', 'these', 'your', 'things?'], 'ar': ['هل', 'تلك', 'الأشياء', 'لك؟'] },
    { 'en': ['One', 'way', 'or', 'another,', "we'll", 'find', 'Tom.'], 'ar': ['بطريقة', 'أو', 'بأخرى', 'سنجد', 'توم.'] },
    { 'en': ['That', 'old', 'woman', 'lives', 'by', 'herself.'], 'ar': ['تلك', 'المرأة', 'العجوز', 'تعيش', 'بمفردها.'] },
    { 'en': ['She', 'plays', 'the', 'violin', 'very', 'well.'], 'ar': ['إنها', 'تعزف', 'على', 'الكمان', 'بمهارة.'] },
    { 'en': ['Anyone', 'can', 'do', 'that.'], 'ar': ['أي', 'شخص', 'يمكنه', 'فعل', 'ذلك.'] },
    { 'en': ['I', 'just', 'got', 'my', 'textbooks', 'for', 'this', 'semester.'], 'ar': ['الآن', 'حصلت', 'على', 'كتب', 'هذا', 'الفصل.'] },
    { 'en': ['Tom', 'got', 'quite', 'a', 'few', 'positive', 'responses.'], 'ar': ['تلقى', 'توم', 'كما', 'لا', 'بأس', 'به', 'من', 'الردود', 'الجيدة.'] },
    { 'en': ['She', 'turned', 'down', 'my', 'request.'], 'ar': ['رفضت', 'طلبي.'] },
    { 'en': ['This', 'is', 'my', 'car.'], 'ar': ['هذه', 'سيارتي.'] },
    { 'en': ['We', 'are', 'boys.'], 'ar': ['إننا', 'صبية.'] },
    { 'en': ['Which', 'color', 'do', 'you', 'like', 'more,', 'blue', 'or', 'red?'], 'ar': ['أي', 'لون', 'تفضل؟', 'الأزرق', 'أم', 'الأحمر؟'] },
    { 'en': ['We', 'are', 'all', 'convinced', 'of', 'his', 'innocence.'], 'ar': ['جميعنا', 'متأكدون', 'من', 'براءته.'] },
    { 'en': ['She', 'accused', 'me', 'of', 'being', 'a', 'liar.'], 'ar': ['اتهمتني', 'بأني', 'كاذب.'] },
    { 'en': ['Your', 'face', 'is', 'red.'], 'ar': ['إحمرّ', 'وجهك.'] },
    { 'en': ['He', 'can', 'read', 'pretty', 'well.'], 'ar': ['يمكنه', 'القراءة', 'بشكل', 'جيد.'] },
    { 'en': ['Everybody', 'knows', 'that.'], 'ar': ['الجميع', 'يعرف', 'ذلك.'] },
    { 'en': ['Hey', 'you!', 'Please', 'wait.'], 'ar': ['أنت', 'هناك!', 'انتظر', 'من', 'فضلك.'] },
    { 'en': ['I', 'know', 'those', 'girls.'], 'ar': ['أنا', 'أعرف', 'هؤلاء', 'الفتيات'] },
    { 'en': ['Tom', 'came', 'just', 'in', 'the', 'nick', 'of', 'time.'], 'ar': ['جاء', 'توم', 'في', 'آخر', 'لحظة.'] },
    { 'en': ['Your', 'help', 'is', 'necessary', 'to', 'our', 'success.'], 'ar': ['مساعدتك', 'ضرورية', 'لنجاحنا.'] },
    { 'en': ["I'm", 'not', 'powerless.'], 'ar': ['لستُ', 'عاجزا.'] },
    { 'en': ['One', 'must', 'do', "one's", 'best.'], 'ar': ['على', 'المرء', 'أن', 'يجتهد.'] },
    { 'en': ['She', 'advised', 'him', 'not', 'to', 'eat', 'too', 'much.'], 'ar': ['نصحتْه', 'ألّا', 'يأكل', 'كثيراً.'] },
    { 'en': ['It', 'only', 'takes', 'a', 'few', 'minutes.'], 'ar': ['ستأخذ', 'بضع', 'دقائق.'] },
    { 'en': ['She', 'filled', 'the', 'glass', 'with', 'wine.'], 'ar': ['ملأت', 'الكأس', 'بالخمر.'] },
    { 'en': ['How', 'annoying!'], 'ar': ['كم', 'هذا', 'مزعج!'] },
    { 'en': ['He', 'is', 'alone.'], 'ar': ['إنه', 'وحيد.'] },
    { 'en': ['What', 'is', 'this', 'fish', 'called', 'in', 'English?'], 'ar': ['ما', 'اسم', 'تلك', 'السمكة', 'بالإنجليزية؟'] },
    { 'en': ['You', 'can', 'rely', 'on', 'him.'], 'ar': ['يمكنك', 'الإعتماد', 'عليه.'] },
    { 'en': ['Take', 'a', 'rest.'], 'ar': ['استرِح.'] },
    { 'en': ["I'm", 'very', 'proud', 'of', 'my', 'father.'], 'ar': ['أنا', 'فخورة', 'جدا', 'بوالدي.'] },
    { 'en': ['I', "can't", 'live', 'without', 'you.'], 'ar': ['لا', 'يمكنني', 'العيش', 'من', 'غيرك.'] },
    { 'en': ['Why', 'do', 'you', 'know', 'that?'], 'ar': ['لماذا', 'تعلم', 'ذلك؟'] },
    { 'en': ['He', 'likes', 'to', 'hunt.'], 'ar': ['هو', 'يحب', 'الصيد.'] },
    { 'en': ['Are', 'you', 'all', 'right', 'now?'], 'ar': ['هل', 'أنتِ', 'بخير', 'الآن؟'] },
    { 'en': ['She', 'likes', 'to', 'read.'], 'ar': ['إنها', 'تحب', 'القراءة.'] },
    { 'en': ['These', 'are', 'all', 'the', 'things', 'that', 'I', 'need.'], 'ar': ['هذا', 'كل', 'ما', 'احتاجه.'] },
    { 'en': ["You'd", 'better', 'not', 'wait', 'here.'], 'ar': ['يستحسن', 'ألا', 'تنتظر', 'هنا.'] },
    { 'en': ['The', 'town', 'is', 'always', 'crawling', 'with', 'tourists.'], 'ar': ['البلدة', 'دائما', 'ما', 'تدب', 'بالسياح', '.'] },
    { 'en': ['The', 'United', 'States', 'annexed', 'Texas', 'in', '1845.'], 'ar': ['ضمّت', 'الولاياتُ', 'المتحدةُ', 'الأمريكيةُ', 'تكساسَ', 'عام', '1845.'] },
    { 'en': ['She', 'was', 'arrested', 'by', 'the', 'police.'], 'ar': ['اعتقلتها', 'الشرطة.'] },
    { 'en': ['Women', 'age', 'faster', 'than', 'men.'], 'ar': ['النساء', 'يتقدمون', 'في', 'السن', 'أكثر', 'من', 'الرجال.'] },
    { 'en': ['Tom', "won't", 'let', 'you', 'go.'], 'ar': ['لن', 'يتخلى', 'عنك', 'توم.'] },
    { 'en': ["I'm", 'not', 'as', 'nice', 'as', 'you', 'think', 'I', 'am.'], 'ar': ['أنا', 'لست', 'لطيفًا', 'كما', 'تظنني.'] },
    { 'en': ['Do', 'you', 'know', 'what', 'I', 'mean?'], 'ar': ['هل', 'فهمت', 'ما', 'أقصده؟'] },
    { 'en': ['Give', 'me', 'that', 'book.'], 'ar': ['أعطني', 'ذلك', 'الكتاب'] },
    { 'en': ["We're", 'eating', 'apples.'], 'ar': ['إننا', 'نأكل', 'التفاح.'] },
    { 'en': ['She', 'provided', 'me', 'with', 'some', 'food.'], 'ar': ['هي', 'ذودتني', 'ببعض', 'الطعام.'] },
    { 'en': ['Tom', 'asked', 'Mary', 'to', 'stop', 'singing.'], 'ar': ['طلب', 'توم', 'من', 'ماري', 'التوقف', 'عن', 'الغناء.'] },
    { 'en': ['Tom', 'and', 'Mary', 'were', 'close', 'friends.'], 'ar': ['توم', 'وميري', 'كانا', 'صديقان', 'مقربان.'] },
    { 'en': ['Where', 'is', 'the', 'problem?'], 'ar': ['أين', 'المشكلة؟'] },
    { 'en': ['Are', 'you', 'sure', 'you', 'know', 'how', 'to', 'do', 'this?'], 'ar': ['أمتأكد', 'من', 'معرفتك', 'بكيفية', 'فعل', 'هذا؟'] },
    { 'en': ['I', 'was', 'afraid', 'of', 'getting', 'lost', 'in', 'the', 'dark.'], 'ar': ['كنت', 'خائفاً', 'من', 'أن', 'أضيع', 'في', 'الظلمة.'] },
    { 'en': ["Don't", 'forget', 'to', 'add', 'me', 'in.'], 'ar': ['لا', 'تنس', 'إضافتي.'] },
    { 'en': ['We', 'must', 'contact', 'the', 'police', 'at', 'once.'], 'ar': ['علينا', 'الإتصال', 'بالشرطة', 'حالاً.'] },
    { 'en': ['Did', 'my', 'father', 'really', 'die?'], 'ar': ['أحقا', 'مات', 'أبي؟'] },
    { 'en': ['I', 'need', 'his', 'help.'], 'ar': ['أحتاج', 'إلى', 'مساعدته.'] },
    { 'en': ['It', 'would', 'seem', 'that', 'the', 'weather', 'is', 'improving.'], 'ar': ['يظهر', 'أن', 'الطقس', 'يتحسن.'] },
    { 'en': ['I', 'have', 'a', 'problem.'], 'ar': ['عندي', 'مشكلة.'] },
    { 'en': ["I'm", 'not', 'OK.'], 'ar': ['لست', 'على', 'ما', 'يرام.'] },
    { 'en': ['Are', 'you', 'happy?'], 'ar': ['هل', 'أنت', 'سعيد؟'] },
    { 'en': ["Don't", 'forget', 'the', 'ticket.'], 'ar': ['لا', 'تنس', 'التذكرة.'] },
    { 'en': ['What', 'did', 'Tom', 'need?'], 'ar': ['ماذا', 'احتاج', 'توم؟'] },
    { 'en': ['She', 'swindled', 'him.'], 'ar': ['لقد', 'خدعتهُ.'] },
    { 'en': ['I', 'worked', 'hard', 'in', 'order', 'to', 'support', 'my', 'family.'], 'ar': ['عملت', 'عملا', 'شاقا', 'لكي', 'أدعم', 'عائلتي.'] },
    { 'en': ['When', 'he', 'heard', 'the', 'news,', 'he', 'turned', 'pale.'], 'ar': ['عندما', 'سَمِعَ', 'الأخبار,', 'تحولَ', 'شاحباً.'] },
    { 'en': ['You', 'can', 'rely', 'on', 'me.'], 'ar': ['بإمكانك', 'الإعتماد', 'عليّ.'] },
    { 'en': ['Take', 'a', 'look', 'in', 'there.'], 'ar': ['ألق', 'نظرة', 'هناك'] },
    { 'en': ['Please', 'speak', 'to', 'me', 'in', 'English.'], 'ar': ['تحدث', 'معي', 'بالإنجليزية', 'رجاءً.'] },
    { 'en': ["What's", 'happened', 'to', 'the', 'money?'], 'ar': ['ماذا', 'حصل', 'بالمال؟'] },
    { 'en': ["I've", 'never', 'met', 'him.'], 'ar': ['لم', 'يسبق', 'لي', 'أن', 'قابلته.'] },
    { 'en': ['Sit', 'up', 'straight.'], 'ar': ['اقعد', 'مستقيم', 'الظهر.'] },
    { 'en': ['I', 'have', 'more', 'experience', 'than', 'Tom.'], 'ar': ['لي', 'خبرة', 'أكثر', 'من', 'توم.'] },
    { 'en': ['How', 'many', 'horses', 'does', 'Tom', 'own?'], 'ar': ['كم', 'حصانا', 'لدى', 'توم؟'] },
    { 'en': ["I'm", 'not', 'going', 'to', 'go', 'to', 'school', 'today.'], 'ar': ['لن', 'أذهب', 'إلى', 'المدرسة', 'اليوم.'] },
    { 'en': ['How', 'did', 'you', 'sleep', 'last', 'night?'], 'ar': ['كيف', 'نِمتَ', 'الليلة', 'الماضية؟'] },
    { 'en': ['I', 'come', 'to', 'school', 'by', 'bus.'], 'ar': ['أحضر', 'إلى', 'المدرس', 'بالحافلة.'] },
    { 'en': ['I', 'met', 'her', 'on', 'my', 'way', 'home.'], 'ar': ['التقيت', 'بها', 'في', 'طريقي', 'إلی', 'المنزل'] },
    { 'en': ["I'm", 'so', 'fat.'], 'ar': ['أنا', 'سمين', 'جدا.'] },
    { 'en': ['See', 'you', 'at', 'home.'], 'ar': ['أراك', 'في', 'البيت'] },
    { 'en': ['Watch', 'your', 'mouth,', 'Tom.'], 'ar': ['انتبه', 'لما', 'تقوله', 'يا', 'توم.'] },
    { 'en': ["I'm", 'lucky.'], 'ar': ['انا', 'محظوظ.'] },
    { 'en': ["Doesn't", 'that', 'bother', 'you?'], 'ar': ['ألا', 'يزعجك', 'ذلك؟'] },
    { 'en': ['Perhaps', 'she', 'will', 'come', 'tomorrow.'], 'ar': ['لربما', 'جاءت', 'غداً.'] },
    { 'en': ['Your', 'friend', 'is', 'here.'], 'ar': ['صديقك', 'هنا.'] },
    { 'en': ['Most', 'students', 'come', 'to', 'school', 'on', 'foot.'], 'ar': ['معظم', 'الطلبة', 'يأتون', 'إلى', 'المدرسة', 'مشياً', 'على', 'الأقدام.'] },
    { 'en': ['I', "don't", 'want', 'to', 'go', 'shopping', 'by', 'myself.'], 'ar': ['لا', 'أريد', 'التّسوّق', 'بمفردي.'] },
    { 'en': ['Mars', 'resembles', 'our', 'planet', 'in', 'some', 'ways.'], 'ar': ['كوكب', 'المريخ', 'يشبه', 'كوكبنا', 'في', 'نواحي', 'عدّة.'] },
    { 'en': ['That', 'has', 'happened', 'to', 'me', 'several', 'times.'], 'ar': ['حدث', 'ذلك', 'لي', 'مرّات', 'عدّة.'] },
    { 'en': ['Let', 'me', 'see', 'it.'], 'ar': ['دعني', 'أراها.'] },
    { 'en': ['This', 'word', 'is', 'also', 'French', 'in', 'origin.'], 'ar': ['أصل', 'هذه', 'الكلمة', 'فرنسي', 'كذلك.'] },
    { 'en': ['She', 'told', 'me', 'an', 'interesting', 'story.'], 'ar': ['حكت', 'لي', 'قصة', 'شيّقة.'] },
    { 'en': ['This', 'is', 'the', 'right', 'answer.'], 'ar': ['هذه', 'هي', 'الإجابة', 'الصحيحة.'] },
    { 'en': ['We', 'have', 'to', 'start', 'somewhere.'], 'ar': ['علينا', 'أن', 'نبدأَ', 'من', 'مكانٍ', 'ما.'] },
    { 'en': ['Monday', 'is', 'my', 'busiest', 'day.'], 'ar': ['الاثنين', '-بالنسبة', 'لي-', 'هو', 'أكثر', 'الأيام', 'انشغالًا.'] },
    { 'en': ["It's", 'a', 'matter', 'of', 'life', 'and', 'death.'], 'ar': ['إنها', 'مسالة', 'حياة', 'أو', 'موت.'] },
    { 'en': ['How', 'is', 'everyone?'], 'ar': ['كيف', 'حال', 'الجميع؟'] },
    { 'en': ['Nobody', 'cares', 'for', 'me.'], 'ar': ['لا', 'أحد', 'يهتم', 'لأمري.'] },
    { 'en': ['He', 'has', 'a', 'car.'], 'ar': ['لديه', 'سيارة'] },
    { 'en': ['Really?'], 'ar': ['حقاً؟'] },
    { 'en': ['I', 'was', 'happy', 'yesterday.'], 'ar': ['كنت', 'سعيدًا', 'بالأمس.'] },
    { 'en': ["Let's", 'go.'], 'ar': ['هيا', 'بنا', 'نذهب.'] },
    { 'en': ['She', 'always', 'keeps', 'her', 'room', 'clean.'], 'ar': ['دائماً', 'ما', 'تبقي', 'غرفتها', 'نظيفة.'] },
    { 'en': ['I', 'have', 'been', 'here', 'since', 'five', "o'clock."], 'ar': ['أنا', 'هنا', 'منذ', 'الساعة', 'الخامسة.'] },
    { 'en': ['The', 'ambassador', 'returned.'], 'ar': ['عاد', 'السفير.'] },
    { 'en': ['It', 'is', 'getting', 'warmer', 'day', 'by', 'day.'], 'ar': ['يصبح', 'الجو', 'أدفأ', 'يوماً', 'بعد', 'يوم.'] },
    { 'en': ['I', 'know', 'him', 'by', 'his', 'first', 'name.'], 'ar': ['أعرفه', 'بإسمه', 'الأول.'] },
    { 'en': ['Sweet', 'dreams!'], 'ar': ['أحلاماً', 'سعيدة!'] },
    { 'en': ['I', 'am', 'terribly', 'hungry.'], 'ar': ['أنا', 'جائع', 'جداً.'] },
    { 'en': ['Do', 'what', 'Tom', 'says.'], 'ar': ['إفعل', 'ما', 'يقوله', 'توم.'] },
    { 'en': ['The', 'girl', 'screamed', 'when', 'she', 'saw', 'the', 'flames.'], 'ar': ['صرخت', 'الفتاة', 'حين', 'رأت', 'ألسنة', 'اللهب.'] },
    { 'en': ["Don't", 'play', 'here.'], 'ar': ['لا', 'تلعب', 'هنا.'] },
    { 'en': ["I'm", 'a', 'tourist.'], 'ar': ['أنا', 'سائح.'] },
    { 'en': ['The', 'answer', 'is', 'actually', 'quite', 'simple.'], 'ar': ['بالواقع', 'إن', 'الإجابة', 'بسيطة.'] },
    { 'en': ['Are', 'you', 'busy', 'tomorrow', 'morning?'], 'ar': ['هل', 'أنت', 'مشغول', 'صباح', 'الغد', '؟'] },
    { 'en': ['If', 'it', 'smells', 'funny,', "don't", 'eat', 'it.'], 'ar': ['إن', 'كانت', 'رائحتة', 'غريبة،', 'فلا', 'تأكله.'] },
    { 'en': ["That'll", 'change', 'nothing.'], 'ar': ['ذلك', 'لن', 'يغير', 'شيء.'] },
    { 'en': ['I', 'wish', 'I', 'could', 'go', 'to', 'the', 'concert.'], 'ar': ['تمنيت', 'لو', 'أنه', 'كان', 'بإمكاني', 'الذهاب', 'إلى', 'الحفلة', 'الغنائية.'] },
    { 'en': ['Is', 'there', 'anything', 'else', 'you', 'want', 'to', 'tell', 'me?'], 'ar': ['هل', 'يوجد', 'أي', 'شيء', 'أخر', 'تريد', 'أن', 'تُخبرني', 'به؟'] },
    { 'en': ['I', 'came', 'on', 'foot.'], 'ar': ['جئت', 'سيراً', 'على', 'الأقدام.'] },
    { 'en': ['When', 'did', 'you', 'begin', 'playing', 'golf?'], 'ar': ['متى', 'بدأت', 'لعب', 'الجولف؟'] },
    { 'en': ['I', 'live', 'in', 'Japan.'], 'ar': ['أعيش', 'في', 'اليابان.'] },
    { 'en': ["What's", 'happening?'], 'ar': ['ما', 'الذي', 'يحدث؟'] },
    { 'en': ['Tom', 'came', 'with', 'Mary.'], 'ar': ['توم', 'أتى', 'مع', 'ماري'] },
    { 'en': ['You', 'had', 'me', 'completely', 'fooled.'], 'ar': ['لقد', 'خدعتني', 'تماما.'] },
    { 'en': ['Japan', 'is', 'close', 'to', 'China.'], 'ar': ['اليابان', 'قريبة', 'من', 'الصين.'] },
    { 'en': ['You', 'should', 'have', 'refused', 'his', 'offer.'], 'ar': ['كان', 'عليك', 'أن', 'ترفض', 'عرضه.'] },
    { 'en': ["I'll", 'never', 'forget', 'you.'], 'ar': ['لن', 'أنساك', 'أبداً.'] },
    { 'en': ['The', 'teacher', 'approved', 'his', 'plan.'], 'ar': ['وافق', 'المدرس', 'على', 'خطته.'] },
    { 'en': ['My', 'goal', 'in', 'life', 'is', 'to', 'be', 'a', 'novelist.'], 'ar': ['هدفي', 'في', 'الحياة', 'هو', 'أن', 'أصبح', 'كاتباً', 'روائياً.'] },
    { 'en': ['I', 'agree', 'with', 'his', 'opinion.'], 'ar': ['أوافقه', 'الرأي.'] },
    { 'en': ['A', 'dolphin', 'is', 'a', 'kind', 'of', 'mammal.'], 'ar': ['الدلفين', 'نوع', 'من', 'الثدييات.'] },
    { 'en': ['English', 'is', 'an', 'international', 'language.'], 'ar': ['الإنجليزية', 'لغة', 'عالمية.'] },
    { 'en': ['She', 'turned', 'around', 'and', 'smiled.'], 'ar': ['استدارت', 'و', 'ابتسمت.'] },
    { 'en': ['Are', 'you', 'sure?'], 'ar': ['هل', 'أنت', 'متأكد؟'] },
    { 'en': ['She', 'may', 'not', 'come', 'here', 'tomorrow.'], 'ar': ['من', 'الممكن', 'ألا', 'تأتي', 'غداً.'] },
    { 'en': ['Translate', 'the', 'underlined', 'sentences.'], 'ar': ['ترجم', 'الجمل', 'التي', 'تحتها', 'خط.'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['إنها', 'حارة', 'جداً'] },
    { 'en': ['About', 'how', 'many', 'English', 'words', 'do', 'you', 'know?'], 'ar': ['كم', 'كلمة', 'انجليزية', 'تعرف؟'] },
    { 'en': ["I'll", 'pick', 'you', 'up', 'after', 'work.'], 'ar': ['سأقلك', 'بعد', 'العمل.'] },
    { 'en': ['Can', 'you', 'drive', 'a', 'car?'], 'ar': ['أيمكنك', 'أن', 'تقود', 'سيارة؟'] },
    { 'en': ['His', 'plan', 'is', 'dangerous!'], 'ar': ['خطته', 'خطرة!'] },
    { 'en': ["I've", 'always', 'loved', 'you.'], 'ar': ['لطالما', 'أحببتك.'] },
    { 'en': ['Her', 'only', 'hobby', 'is', 'collecting', 'stamps.'], 'ar': ['هوايتها', 'الوحيدة', 'هي', 'جمع', 'الطوابع.'] },
    { 'en': ['I', 'want', 'to', 'die.'], 'ar': ['أريد', 'أن', 'أموت.'] },
    { 'en': ['I', 'am', 'a', 'boy.'], 'ar': ['أنا', 'ولد.'] },
    { 'en': ['You', 'betrayed', 'me.'], 'ar': ['لقد', 'خنتني.'] },
    { 'en': ['The', 'airplane', 'made', 'a', 'safe', 'landing.'], 'ar': ['هبطت', 'الطائرة', 'بسلام.'] },
    { 'en': ["I've", 'never', 'been', 'sick', 'a', 'day', 'in', 'my', 'life.'], 'ar': ['لم', 'أمرض', 'يوما', 'في', 'حياتي.'] },
    { 'en': ['This', 'is', 'my', 'page', 'on', 'Facebook.'], 'ar': ['هذه', 'صفحتي', 'على', 'الفيسبوك.'] },
    { 'en': ['The', 'students', 'learned', 'this', 'poem', 'by', 'heart.'], 'ar': ['حفظ', 'التلاميذ', 'هذه', 'القصيدة', 'عن', 'ظهر', 'قلب.'] },
    { 'en': ['Are', 'these', 'yours?'], 'ar': ['هل', 'هذه', 'الأشياء', 'لك؟'] },
    { 'en': ['My', 'grandfather', 'comes', 'from', 'Osaka.'], 'ar': ['جدي', 'من', 'أوساكا.'] },
    { 'en': ["I've", 'climbed', 'Mt.', 'Fuji', 'twice.'], 'ar': ['تسلقت', 'جبل', 'فوجي', 'مرتين.'] },
    { 'en': ["I'm", 'going', 'to', 'the', 'bank.'], 'ar': ['أنا', 'ذاهب', 'إلى', 'البنك.'] },
    { 'en': ['May', 'I', 'take', 'a', 'photo?'], 'ar': ['أيمكنني', 'أخذ', 'صورة؟'] },
    { 'en': ['I', 'am', 'afraid', 'I', "don't", 'agree', 'with', 'you.'], 'ar': ['أخشي', 'أني', 'لا', 'أتفق', 'معك.'] },
    { 'en': ["It's", 'very', 'hot,', "isn't", 'it?'], 'ar': ['الجو', 'حار', 'جداً،', 'صحيح؟'] },
    { 'en': ['Do', 'you', 'have', 'one', 'a', 'little', 'bigger', 'than', 'these?'], 'ar': ['هل', 'عندك', 'واحد', 'أكبر', 'قليلًا', 'من', 'هذه؟'] },
    { 'en': ['I', 'heard', 'an', 'unusual', 'noise.'], 'ar': ['سمعت', 'ضجة', 'غريبة.'] },
    { 'en': ['The', 'Japanese', 'like', 'to', 'travel', 'in', 'groups.'], 'ar': ['يحب', 'اليابانيون', 'السفر', 'كمجموعات.'] },
    { 'en': ['I', 'am', 'coming', 'today.'], 'ar': ['أنا', 'قادمٌ', 'اليوم'] },
    { 'en': ['I', "don't", 'believe', 'you.', "You're", 'always', 'lying.'], 'ar': ['أنا', 'لا', 'أصدقُكِ,', 'أنتِ', 'دائماً', 'تكذبين.'] },
    { 'en': ['Try', 'it', 'again.'], 'ar': ['حاول', 'مرة', 'أخرى.'] },
    { 'en': ['Tom', 'got', 'on', 'the', 'wrong', 'bus.'], 'ar': ['استقل', 'توم', 'الحافلة', 'الخطأ.'] },
    { 'en': ['You', 'must', 'not', 'speak', 'loudly.'], 'ar': ['يجب', 'ألا', 'تتحدث', 'بصوت', 'عالٍ.'] },
    { 'en': ['He', 'was', 'covered', 'with', 'mud', 'from', 'head', 'to', 'foot.'], 'ar': ['غطاه', 'الوحل', 'من', 'رأسه', 'إلى', 'أخمص', 'قدميه.'] },
    { 'en': ['Do', 'you', 'trust', 'Tom?'], 'ar': ['ألا', 'تثق', 'بتوم؟'] },
    { 'en': ['Since', "you're", 'here,', 'we', 'might', 'as', 'well', 'begin.'], 'ar': ['بما', 'أنك', 'هنا،', 'لعلنا', 'نبدأ.'] },
    { 'en': ["Don't", 'underestimate', 'me.'], 'ar': ['لا', 'تستخف', 'بمقدرتي.'] },
    { 'en': ['He', 'felt', 'her', 'hand', 'on', 'his', 'shoulder.'], 'ar': ['شعر', 'بيدها', 'على', 'كتفه.'] },
    { 'en': ["I'm", 'sorry.'], 'ar': ['أنا', 'آسف.'] },
    { 'en': ['The', 'earth', 'is', 'round.'], 'ar': ['الأرض', 'كروية.'] },
    { 'en': ['Tom', "should've", 'been', 'there.'], 'ar': ['كان', 'على', 'توم', 'أن', 'يكون', 'هناك.'] },
    { 'en': ['What', 'are', 'you', 'thinking', 'about?'], 'ar': ['في', 'ماذا', 'تفكر؟'] },
    { 'en': ["John's", 'house', 'is', 'opposite', 'the', 'church.'], 'ar': ['منزل', 'جون', 'مقابل', 'الكنيسة.'] },
    { 'en': ['My', 'major', 'is', 'medieval', 'European', 'history.'], 'ar': ['تخصصي', 'هو', 'تاريخ', 'أوروبا', 'في', 'العصور', 'الوسطى.'] },
    { 'en': ['Tom', 'was', 'heartbroken', 'when', 'his', 'dog', 'died.'], 'ar': ['توم', 'كان', 'قلبه', 'مكسور', 'عندما', 'مات', 'كلبه'] },
    { 'en': ["You're", 'doing', 'it', 'wrong!'], 'ar': ['أنت', 'تفعله', 'بطريقة', 'خاطئة!'] },
    { 'en': ['How', 'will', 'we', 'pay', 'our', 'debts', 'now?'], 'ar': ['كيفَ', 'سنسدد', 'ديوننا', 'الآن؟'] },
    { 'en': ['He', 'is', 'my', 'brother.'], 'ar': ['هو', 'أخي'] },
    { 'en': ['My', 'uncle', 'brought', 'a', 'new', 'TV', 'set', 'for', 'us.'], 'ar': ['اشترى', 'لنا', 'عمّي', 'جهاز', 'تلفاز', 'جديد.'] },
    { 'en': ['I', 'like', 'love', 'stories.'], 'ar': ['أحب', 'قصص', 'الغرام.'] },
    { 'en': ['You', "can't", 'run', 'my', 'life.'], 'ar': ['لا', 'يمكنك', 'التدخل', 'في', 'حياتي.'] },
    { 'en': ['What', 'did', 'you', 'do', 'for', 'a', 'living?'], 'ar': ['ماذا', 'كانت', 'وظيفتك', 'في', 'الماضي؟'] },
    { 'en': ['Tom', 'got', 'to', 'Boston', 'yesterday.'], 'ar': ['بالأمس', 'وصل', 'توم', 'إلى', 'بوسطن.'] },
    { 'en': ["You'd", 'better', 'not', 'eat', 'too', 'much.'], 'ar': ['من', 'الأفضل', 'لك', 'ألّا', 'تأكل', 'كثيراً.'] },
    { 'en': ['I', 'want', 'to', 'visit', 'Korea.'], 'ar': ['أريد', 'أن', 'أزور', 'كوريا.'] },
    { 'en': ['Maybe', "you'd", 'better', 'come', 'with', 'us.'], 'ar': ['ربما', 'من', 'الأفضل', 'أن', 'تأتي', 'معنا.'] },
    { 'en': ['I', 'suggest', 'we', 'go', 'out', 'for', 'a', 'drink.'], 'ar': ['أقترح', 'أن', 'نخرج', 'لتناول', 'مشروب.'] },
    { 'en': ['I', 'love', 'the', 'taste', 'of', 'watermelon.'], 'ar': ['أحب', 'طعم', 'البطيخ.'] },
    { 'en': ['I', 'want', 'to', 'live.'], 'ar': ['أريد', 'أن', 'أعيش'] },
    { 'en': ['Why', 'is', 'he', 'angry', 'about', 'something', 'like', 'that?'], 'ar': ['لمَ', 'هو', 'غاضب', 'على', 'شيء', 'مثل', 'هذا؟'] },
    { 'en': ['Mathematics', 'is', 'difficult', 'for', 'me.'], 'ar': ['الرياضيات', 'مادة', 'صعبة', 'بالنسبة', 'لي.'] },
    { 'en': ['Mary', 'took', 'the', 'cookies', 'out', 'of', 'the', 'oven.'], 'ar': ['أخرجت', 'ماري', 'الكعكات', 'من', 'الفرن.'] },
    { 'en': ['Why', 'did', 'it', 'take', 'so', 'long?'], 'ar': ['لماذا', 'إستغرقَ', 'هذا', 'وقتاً', 'طويلاً؟'] },
    { 'en': ['I', 'would', 'like', 'to', 'ask', 'a', 'question.'], 'ar': ['أود', 'أن', 'أطرح', 'سؤالاً.'] },
    { 'en': ['The', 'tree', 'fell', 'down.'], 'ar': ['سقطت', 'الشجرة.'] },
    { 'en': ["I've", 'been', 'thinking', 'of', 'you', 'all', 'day.'], 'ar': ['كنت', 'أفكر', 'بك', 'طوال', 'اليوم.'] },
    { 'en': ['Is', 'it', 'so', 'difficult', 'to', 'write?'], 'ar': ['هل', 'هي', 'صعبة', 'للغاية', 'لكي', 'تُكتب؟'] },
    { 'en': ['Fat', 'hens', 'lay', 'few', 'eggs.'], 'ar': ['الدجاجات', 'السمينة', 'تبيض', 'أقل.'] },
    { 'en': ['There', 'is', 'an', 'orange', 'on', 'the', 'table.'], 'ar': ['هناك', 'برتقالة', 'على', 'الطاولة.'] },
    { 'en': ['Tom', 'is', 'watching.'], 'ar': ['توم', 'يشاهد'] },
    { 'en': ['He', 'is', 'very', 'kind.'], 'ar': ['إنه', 'لطيف', 'جداً.'] },
    { 'en': ['I', 'think', 'love', "doesn't", 'exist.'], 'ar': ['أنا', 'أعتقد', 'أن', 'الحب', 'غير', 'موجود.'] },
    { 'en': ['Now,', 'let', 'me', 'ask', 'you', 'a', 'question.'], 'ar': ['الآن،', 'اسمح', 'لي', 'أن', 'أطرح', 'عليك', 'سؤالاً.'] },
    { 'en': ['I', 'have', 'a', 'new', 'red', 'car.'], 'ar': ['لدي', 'سيارة', 'حمراء', 'جديدة.'] },
    { 'en': ['It', 'was', 'about', 'twenty', 'dollars.'], 'ar': ['كان', 'هناك', 'حوالي', 'عشرين', 'دولاراً.'] },
    { 'en': ["Let's", 'forget', 'about', 'this.'], 'ar': ['دعونا', 'ننسى', 'هذا.'] },
    { 'en': ['They', 'were', 'listening', 'to', 'the', 'radio.'], 'ar': ['كانوا', 'يستمعون', 'إلى', 'المذياع.'] },
    { 'en': ['They', "aren't", 'farmers.'], 'ar': ['ليسوا', 'مزارعين'] },
    { 'en': ['We', 'need', 'you', 'to', 'help', 'us', 'this', 'afternoon.'], 'ar': ['نحتاج', 'منك', 'أن', 'تساعدنا', 'هذا', 'المساء.'] },
    { 'en': ["I'm", 'not', 'in', 'a', 'position', 'to', 'accept', 'it.'], 'ar': ['أنا', 'لستُ', 'في', 'وضع', 'يسمح', 'بأن', 'أقبلها.'] },
    { 'en': ['I', 'will', 'stay', 'here', 'all', 'this', 'month.'], 'ar': ['سأبقى', 'هنا', 'هذا', 'الشهر', 'كله.'] },
    { 'en': ['I', 'exercise', 'because', 'I', 'want', 'to', 'stay', 'healthy.'], 'ar': ['أقوم', 'بالتمارين', 'الرياضية', 'لأحافظ', 'على', 'صحتي.'] },
    { 'en': ['He', 'speaks', 'Russian', 'perfectly.'], 'ar': ['إنه', 'يتكلم', 'الروسية', 'بطلاقة.'] },
    { 'en': ['The', 'boy', 'is', 'kind.'], 'ar': ['هذا', 'الولد', 'طيب.'] },
    { 'en': ['She', 'lives', 'in', 'a', 'two-story', 'house.'], 'ar': ['إنها', 'تعيش', 'في', 'بيت', 'ذا', 'طابقين.'] },
    { 'en': ['Can', 'I', 'help', 'you?'], 'ar': ['أيمكنني', 'مساعدتك؟'] },
    { 'en': ['No', 'one', 'wants', 'to', 'go', 'there.'], 'ar': ['لا', 'أحد', 'يريد', 'الذهاب', 'إلى', 'هناك.'] },
    { 'en': ['Do', 'you', 'wash', 'your', 'hands', 'before', 'meals?'], 'ar': ['هل', 'تغسل', 'يديك', 'قبل', 'الوجبات؟'] },
    { 'en': ['What', 'time', 'is', 'it?'], 'ar': ['كم', 'الساعة؟'] },
    { 'en': ['I', 'will', 'go', 'to', 'school.'], 'ar': ['سأذهب', 'إلى', 'المدرسة.'] },
    { 'en': ['Visit', 'us', 'tomorrow.'], 'ar': ['قُم', 'بزيارتنا', 'غداً.'] },
    { 'en': ['Can', 'you', 'recommend', 'a', 'good', 'doctor?'], 'ar': ['هلّا', 'نصحتني', 'بطبيب', 'جيّد؟'] },
    { 'en': ['She', 'chose', 'the', 'red', 'dress.'], 'ar': ['اِختارت', 'الفستان', 'الأحمر.'] },
    { 'en': ['Excuse', 'me,', 'who', 'is', 'this', 'woman?'], 'ar': ['المعذرة،', 'من', 'هذه', 'المرأة؟'] },
    { 'en': ['I', 'was', 'laughed', 'at', 'by', 'him.'], 'ar': ['ضحك', 'عليّ.'] },
    { 'en': ['Here', 'he', 'comes.'], 'ar': ['ها', 'قد', 'أتى.'] },
    { 'en': ["Isn't", 'there', 'something', 'you', 'want', 'to', 'say', 'to', 'me?'], 'ar': ['ألا', 'يوجد', 'ما', 'تريد', 'قوله', 'لي؟'] },
    { 'en': ["It's", 'impossible', 'to', 'tell', 'for', 'certain.'], 'ar': ['من', 'المستحيل', 'القول', 'بشكلٍ', 'قطعي.'] },
    { 'en': ['You', 'can', 'come', 'with', 'me.'], 'ar': ['يمكنك', 'المجيئ', 'معي.'] },
    { 'en': ['I', 'asked', 'him', 'where', 'he', 'was', 'going.'], 'ar': ['سألته', 'إلى', 'أي', 'مكان', 'هو', 'ذاهب.'] },
    { 'en': ['Tom', 'finally', 'stopped', 'laughing.'], 'ar': ['توقف', 'توم', 'عن', 'الضحك', 'أخيرا.'] },
    { 'en': ['I', 'have', 'to', 'go', 'now.'], 'ar': ['علي', 'الذهاب', 'الآن.'] },
    { 'en': ["You're", 'not', 'fast', 'enough.'], 'ar': ['لست', 'سريعاً', 'بما', 'فيه', 'الكفاية.'] },
    { 'en': ['What', 'are', 'you', 'watching?'], 'ar': ['ماذا', 'تشاهد؟'] },
    { 'en': ['May', 'I', 'borrow', 'your', 'knife?'], 'ar': ['أيمكنني', 'أن', 'أستعير', 'سِكّينَك؟'] },
    { 'en': ['These', 'shoes', 'are', 'too', 'big', 'for', 'me.'], 'ar': ['هذا', 'الحذاء', 'كبير', 'عليّ.'] },
    { 'en': ['Tom', 'used', 'to', 'work', 'for', 'a', 'big', 'firm', 'in', 'Boston.'], 'ar': ['كان', 'توم', 'يعمل', 'لمؤسسة', 'كبيرة', 'في', 'بوسطن.'] },
    { 'en': ['I', "can't", 'understand', 'what', "you're", 'saying.'], 'ar': ['لا', 'أفهم', 'ما', 'تقوله.'] },
    { 'en': ["It's", 'getting', 'warmer', 'and', 'warmer.'], 'ar': ['حرارة', 'الطقس', 'تتزايد.'] },
    { 'en': ['Shut', 'your', 'mouth.'], 'ar': ['اغلق', 'فمك.'] },
    { 'en': ['Do', 'you', 'know', 'who', 'I', 'am?'], 'ar': ['هل', 'تعلم', 'من', 'أنا', '؟'] },
    { 'en': ['Have', 'you', 'done', 'all', 'your', 'homework?'], 'ar': ['هل', 'أنجزت', 'جميع', 'فروضك؟'] },
    { 'en': ['Tom', 'was', 'moved', 'by', 'the', 'music.'], 'ar': ['توم', 'نقلته', 'الموسيقى'] },
    { 'en': ['Turn', 'off', 'the', 'sprinklers.'], 'ar': ['أقفل', 'المرشّات.'] },
    { 'en': ['What', 'a', 'cute', 'little', 'girl!'], 'ar': ['يا', 'لها', 'من', 'فتاة', 'جميلة!'] },
    { 'en': ['When', 'was', 'the', 'castle', 'built?'], 'ar': ['متى', 'بُنيت', 'القلعة؟'] },
    { 'en': ['I', "don't", 'understand.'], 'ar': ['لا', 'أفهم.'] },
    { 'en': ['Turn', 'off', 'the', 'light,', 'please.'], 'ar': ['أطفئ', 'النور', 'إذا', 'سمحت.'] },
    { 'en': ["I'd", 'give', 'you', 'more', 'if', 'I', 'could.'], 'ar': ['سأعطيك', 'مزيدا', 'إن', 'أمكنني', 'ذلك.'] },
    { 'en': ['I', 'came', 'to', 'Japan', 'last', 'year.'], 'ar': ['أتيت', 'إلى', 'اليابان', 'السنة', 'الماضية.'] },
    { 'en': ["I'm", 'not', 'tired.'], 'ar': ['أنا', 'لست', 'متعباً.'] },
    { 'en': ['Where', 'is', 'your', 'sister?'], 'ar': ['أين', 'أختك؟'] },
    { 'en': ['That', 'is', 'why', 'he', 'got', 'angry.'], 'ar': ['ذلك', 'سبب', 'انفعاله.'] },
    { 'en': ["I'll", 'meet', 'you', 'outside.'], 'ar': ['سأقابلك', 'في', 'الخارج.'] },
    { 'en': ["You'll", 'feel', 'better', 'tomorrow,', 'I', 'promise.'], 'ar': ['ستشعر', 'بتحسّن', 'غدا،', 'أعدك', 'بذلك.'] },
    { 'en': ['I', 'can', 'jump.'], 'ar': ['أستطيع', 'القفز.'] },
    { 'en': ['Tell', 'me', 'what', 'you', 'want', 'me', 'to', 'do.'], 'ar': ['وضح', 'لي', 'ما', 'تريدني', 'أن', 'أفعل.'] },
    { 'en': ['Tom', 'tried', 'not', 'to', 'look', 'at', 'Mary.'], 'ar': ['حاول', 'توم', 'ألا', 'ينظر', 'إلى', 'ماري.'] },
    { 'en': ['They', 'are', 'my', 'sisters.'], 'ar': ['هنّ', 'أخواتي.'] },
    { 'en': ['Tom', 'never', 'talked', 'about', 'Mary.'], 'ar': ['لم', 'يتحدث', 'توم', 'عن', 'ماري', 'قط.'] },
    { 'en': ["I'm", 'pregnant.'], 'ar': ['أنا', 'حامل.'] },
    { 'en': ['He', 'is', 'talking', 'on', 'the', 'phone.'], 'ar': ['إنهُ', 'يتحدث', 'على', 'التليفون.'] },
    { 'en': ['School', 'begins', 'at', 'half', 'past', 'eight.'], 'ar': ['تبدأ', 'المدرسة', 'الساعة', 'الثامنة', 'و', 'النصف.'] },
    { 'en': ['We', 'need', 'to', 'stay', 'focused.'], 'ar': ['يجب', 'أن', 'نبقى', 'منتبهين.'] },
    { 'en': ['Let', 'me', 'do', 'it.'], 'ar': ['دعني', 'أفعلها'] },
    { 'en': ['This', 'doll', 'is', 'a', 'gift', 'from', 'my', 'aunt.'], 'ar': ['تلك', 'الدمية', 'هدية', 'من', 'عمتي.'] },
    { 'en': ['Is', 'it', 'a', 'recent', 'picture?'], 'ar': ['هل', 'هذه', 'صورة', 'حديثة؟'] },
    { 'en': ["That's", 'what', 'people', 'say.'], 'ar': ['هذا', 'ما', 'يقوله', 'الناس.'] },
    { 'en': ['I', 'can', 'see', 'that', 'you', 'are', 'working.'], 'ar': ['أرى', 'أنك', 'تعمل.'] },
    { 'en': ['I', 'know', 'you', 'like', 'coffee.'], 'ar': ['أعلم', 'أنّك', 'تحبّين', 'القهوة.'] },
    { 'en': ["It's", 'a', 'nice', 'day.'], 'ar': ['إنه', 'يوم', 'جميل.'] },
    { 'en': ['This', 'is', 'a', 'dangerous', 'thing.'], 'ar': ['هذا', 'أمرٌ', 'خطير.'] },
    { 'en': ['Nothing', 'happened.'], 'ar': ['لم', 'يحدث', 'شيء.'] },
    { 'en': ["You'd", 'better', 'go.', "It's", 'getting', 'late.'], 'ar': ['من', 'الأفضل', 'أن', 'تغادر.', 'إن', 'الوقت', 'متأخر.'] },
    { 'en': ['You', 'have', 'lots', 'of', 'friends.'], 'ar': ['لديكم', 'الكثير', 'من', 'الأصدقاء.'] },
    { 'en': ['How', 'lucky', 'you', 'are!'], 'ar': ['كم', 'أنتِ', 'محظوظة!'] },
    { 'en': ['Just', 'tell', 'me', 'what', 'you', 'want.'], 'ar': ['فقط', 'أخبريني', 'ماذا', 'تريدين.'] },
    { 'en': ['Are', 'you', 'at', 'home?'], 'ar': ['هل', 'أنت', 'موجود', 'في', 'المنزل؟'] },
    { 'en': ['This', 'telescope', 'must', 'be', 'used', 'carefully.'], 'ar': ['يجب', 'استخدام', 'هذا', 'التلسكوب', 'بعناية.'] },
    { 'en': ['I', 'returned', 'the', 'book', 'to', 'the', 'library.'], 'ar': ['أعدت', 'الكتاب', 'إلى', 'المكتبة.'] },
    { 'en': ['I', 'agree', 'with', 'him.'], 'ar': ['أوافقه', 'الرأي.'] },
    { 'en': ['He', 'could', 'not', 'control', 'his', 'anger.'], 'ar': ['لم', 'يستطِع', 'أن', 'يسيطر', 'على', 'غضبه.'] },
    { 'en': ["He's", 'crazy', 'about', 'you.'], 'ar': ['إنه', 'مولع', 'بك.'] },
    { 'en': ['May', 'I', 'help', 'you?'], 'ar': ['هل', 'تحتاج', 'لمساعدة؟'] },
    { 'en': ['He', 'promised', 'me', 'that', 'he', 'would', 'come', 'at', 'four.'], 'ar': ['لقد', 'وعدني', 'أنه', 'سيأتي', 'على', 'الساعة', 'الرابعة.'] },
    { 'en': ['I', 'have', 'a', 'fear', 'of', 'the', 'dark.'], 'ar': ['لدي', 'خوف', 'من', 'الظلام.'] },
    { 'en': ['I', "don't", 'know', 'how', 'to', 'drive', 'a', 'car.'], 'ar': ['لا', 'أعرف', 'كيف', 'أقود', 'سيارة.'] },
    { 'en': ['When', 'did', 'you', 'arrive?'], 'ar': ['متى', 'وصلت؟'] },
    { 'en': ['She', 'asked', 'me', 'to', 'open', 'the', 'window.'], 'ar': ['طلبت', 'مني', 'أن', 'أفتح', 'النافذة.'] },
    { 'en': ["Don't", 'waste', 'your', 'time.'], 'ar': ['لا', 'تضع', 'وقتك', 'سدی'] },
    { 'en': ['Where', 'do', 'you', 'work?'], 'ar': ['أين', 'تذهب', 'للعمل؟'] },
    { 'en': ["There's", 'only', 'a', 'little', 'milk', 'left.'], 'ar': ['لم', 'يبقَ', 'إلا', 'القليل', 'من', 'الحليب.'] },
    { 'en': ['No', 'one', 'is', 'coming.'], 'ar': ['لن', 'يأتي', 'أحد.'] },
    { 'en': ['Why', 'are', 'you', 'working', 'here?'], 'ar': ['لمَ', 'تعمل', 'هنا؟'] },
    { 'en': ['Tom', 'won.'], 'ar': ['توم', 'فاز.'] },
    { 'en': ["I'm", 'a', 'hero.'], 'ar': ['أنا', 'بطل.'] },
    { 'en': ['Tom', 'lost', 'their', 'trust.'], 'ar': ['توم', 'خَسِرَ', 'ثِقَتَهُم.'] },
    { 'en': ['The', 'football', 'players', 'were', 'tired,', 'but', 'happy.'], 'ar': ['كان', 'لاعبي', 'كرة', 'القدم', 'متعبين', 'لكنّهم', 'سعداء.'] },
    { 'en': ['Who', 'is', 'this', 'girl?'], 'ar': ['من', 'هذه', 'الفتاة؟'] },
    { 'en': ['Is', 'this', 'book', 'yours?'], 'ar': ['هل', 'هذا', 'الكتاب', 'لك؟'] },
    { 'en': ['Come', 'on.', "Let's", 'get', 'out', 'of', 'here.'], 'ar': ['هيا.', 'دعنا', 'نخرج', 'من', 'هنا.'] },
    { 'en': ["I've", 'done', 'my', 'duty.'], 'ar': ['قمت', 'بواجبي.'] },
    { 'en': ["She's", 'stupid,', 'but', 'honest.'], 'ar': ['هي', 'غبية,', 'ولكن', 'أمينة.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'of', 'things', 'to', 'do.'], 'ar': ['لدي', 'الكثير', 'من', 'الأشياء', 'لأفعلها.'] },
    { 'en': ['Stop', 'or', "I'll", 'pull', 'the', 'car', 'over!'], 'ar': ['توقف', 'وإلا', 'سأسحب', 'السيارة', 'اكثر.'] },
    { 'en': ['May', 'I', 'ask', 'why?'], 'ar': ['أيمكنني', 'سؤالك', 'عن', 'السبب؟'] },
    { 'en': ['Please', 'get', 'out', 'of', 'the', 'car.'], 'ar': ['انزل', 'من', 'السّيّارة', 'من', 'فضلك.'] },
    { 'en': ["I'm", 'looking', 'for', 'my', 'gloves.'], 'ar': ['أبحث', 'عن', 'قفازاتي.'] },
    { 'en': ['I', 'also', 'went.'], 'ar': ['ذهبت', 'أيضاً.'] },
    { 'en': ['You', 'are', 'as', 'tall', 'as', 'I', 'am.'], 'ar': ['طولُك', 'مثل', 'طولي.'] },
    { 'en': ["I'll", 'admit', "I'm", 'wrong.'], 'ar': ['سأعترف', 'بأني', 'مخطئ.'] },
    { 'en': ['Do', 'you', 'mind', 'if', 'I', 'go?'], 'ar': ['هل', 'تمانع', 'إذا', 'ذهبت؟'] },
    { 'en': ["Where's", 'my', 'watch?'], 'ar': ['أين', 'ساعتي؟'] },
    { 'en': ['Slaves', 'did', 'most', 'of', 'the', 'work.'], 'ar': ['قام', 'العبيد', 'بمعظم', 'الأعمال.'] },
    { 'en': ["I'm", 'about', 'ready.'], 'ar': ['أنا', 'جاهز', 'تقريباً.'] },
    { 'en': ['I', 'am', 'watching', 'television.'], 'ar': ['أشاهد', 'التلفاز.'] },
    { 'en': ['I', 'like', 'her', 'younger', 'sister', 'very', 'much.'], 'ar': ['أحب', 'أختها', 'الصغرى', 'كثيراً.'] },
    { 'en': ['Correct', 'the', 'errors,', 'if', 'there', 'are', 'any.'], 'ar': ['صَحِح', 'الأخطاء,', 'إذا', 'كان', 'هناك', 'أي', 'منها.'] },
    { 'en': ['Have', 'you', 'finished', 'it?'], 'ar': ['هل', 'أنهيتها؟'] },
    { 'en': ["I've", 'waited', 'for', 'more', 'than', 'a', 'week.'], 'ar': ['انتظرت', 'لأكثر', 'من', 'أسبوع.'] },
    { 'en': ["I'm", 'not', 'breaking', 'the', 'law.'], 'ar': ['أنا', 'لا', 'أخرق', 'القانون.'] },
    { 'en': ['Words', 'express', 'thoughts.'], 'ar': ['تعبر', 'الكلمات', 'عن', 'الأفكار.'] },
    { 'en': ['The', 'sea', 'is', 'calm.'], 'ar': ['البحر', 'هادئ.'] },
    { 'en': ['She', 'waited', 'for', 'hours', 'and', 'hours.'], 'ar': ['انتظرت', 'لساعات', 'و', 'ساعات.'] },
    { 'en': ['The', 'houses', 'caught', 'fire', 'one', 'after', 'another.'], 'ar': ['احترقت', 'المنازل', 'واحدة', 'تلو', 'الأخرى.'] },
    { 'en': ["Let's", 'party.'], 'ar': ['لنحتفل!'] },
    { 'en': ['We', 'immediately', 'became', 'friends.'], 'ar': ['أصبحنا', 'صديقين', 'على', 'الفور.'] },
    { 'en': ["It's", 'Saturday.'], 'ar': ['هل', 'اليوم', 'هو', 'السبت؟'] },
    { 'en': ['Thank', 'you', 'in', 'advance', 'for', 'your', 'help.'], 'ar': ['أشكرك', 'على', 'مساعدتك', 'مقدمًا.'] },
    { 'en': ['I', 'will', 'sue', 'you.'], 'ar': ['سأرفع', 'عليك', 'قضية.'] },
    { 'en': ['The', 'check,', 'please.'], 'ar': ['الفاتورة', 'من', 'فضلك.'] },
    { 'en': ['I', 'feel', 'chilled', 'to', 'the', 'bone', 'today.'], 'ar': ['أشعر', 'ببرد', 'في', 'عظامي', 'اليوم.'] },
    { 'en': ['They', "don't", 'even', 'know', 'why.'], 'ar': ['إنهم', 'لا', 'يعرفون', 'لماذا', 'حتى.'] },
    { 'en': ['Who', 'are', 'those', 'guys?'], 'ar': ['من', 'هؤلاء؟'] },
    { 'en': ['He', 'closely', 'resembles', 'his', 'father.'], 'ar': ['إنه', 'يشبه', 'أباه', 'كثيراً.'] },
    { 'en': ['I', 'have', 'a', 'cough.'], 'ar': ['لدي', 'سعال.'] },
    { 'en': ['Stop', 'yelling!'], 'ar': ['كف', 'عن', 'الصراخ!'] },
    { 'en': ['Allow', 'me', 'to', 'go.'], 'ar': ['اسمح', 'لي', 'بالذهاب.'] },
    { 'en': ['I', 'saw', 'a', 'fox', 'run', 'across', 'the', 'road', 'just', 'now.'], 'ar': ['رأيت', 'ثعلبًا', 'يقطع', 'الطريق', 'للتو.'] },
    { 'en': ["She'll", 'love', 'him', 'forever.'], 'ar': ['ستحبه', 'إلى', 'الأبد.'] },
    { 'en': ["It's", 'the', 'queen.'], 'ar': ['إنها', 'الملكة.'] },
    { 'en': ['I', 'came', 'on', 'foot.'], 'ar': ['أتيت', 'سيراً', 'على', 'الأقدام.'] },
    { 'en': ['You', 'are', 'really', 'talented.'], 'ar': ['أنت', 'موهوبٌ', 'حقاً.'] },
    { 'en': ['I', "don't", 'drink', 'alcohol.'], 'ar': ['أنا', 'لا', 'أشرب', 'الكحول.'] },
    { 'en': ["I'm", 'trying', 'to', 'help', 'you.'], 'ar': ['أنا', 'أحاول', 'أن', 'أساعدك.'] },
    { 'en': ['He', 'lived', 'a', 'long', 'life.'], 'ar': ['عاش', 'حياة', 'مديدة.'] },
    { 'en': ['I', 'will', 'not', 'blame', 'him', 'for', 'the', 'accident.'], 'ar': ['أنا', 'لن', 'ألومه', 'على', 'الحادث.'] },
    { 'en': ['Can', 'I', 'use', 'my', 'credit', 'card?'], 'ar': ['أيمكنني', 'استخدام', 'بطاقة', 'إئتماني؟'] },
    { 'en': ['Would', 'you', 'please', 'shut', 'the', 'window?'], 'ar': ['هلّا', 'أغلقت', 'النافذة', 'من', 'فضلك؟'] },
    { 'en': ['Tom', 'lost.'], 'ar': ['لقد', 'خَسِرَ', 'توم.'] },
    { 'en': ['I', 'tore', 'the', 'newspaper', 'into', 'pieces.'], 'ar': ['مزقت', 'الصحيفة', 'إلى', 'قطع.'] },
    { 'en': ['I', 'think', "it's", 'much', 'better', 'now.'], 'ar': ['أظنه', 'أفضل', 'بكثير', 'الآن.'] },
    { 'en': ['I', 'bought', 'a', 'red', 'sports', 'car.'], 'ar': ['اِشتريت', 'سيارة', 'رياضية', 'حمراء.'] },
    { 'en': ['Tom', 'is', 'the', 'one', 'who', 'paid', 'for', 'the', 'tickets.'], 'ar': ['توم', 'هو', 'الذي', 'دفع', 'ثمن', 'التذاكر.'] },
    { 'en': ['This', 'will', 'cost', '€30.'], 'ar': ['سيكلفك', 'ذلك', 'ثلاثين', 'يورو.'] },
    { 'en': ['A', 'lot', 'of', 'tourists', 'come', 'here.'], 'ar': ['الكثير', 'من', 'السياح', 'ياتون', 'هنا', '.'] },
    { 'en': ['Write', 'your', 'address,', 'please.'], 'ar': ['اِكتب', 'عنوانك', 'من', 'فضلك.'] },
    { 'en': ['You', 'look', 'like', 'a', 'boy.'], 'ar': ['أنتَ', 'تبدو', 'مثل', 'الولد.'] },
    { 'en': ["Don't", 'cut', 'down', 'those', 'trees.'], 'ar': ['لا', 'تقطع', 'هذه', 'الأشجار.'] },
    { 'en': ['I', "don't", 'know.'], 'ar': ['لا', 'أعرف.'] },
    { 'en': ['This', 'park', 'is', 'really', 'beautiful', 'and', 'clean.'], 'ar': ['هذا', 'المنتزه', 'جميل', 'و', 'نظيف', 'حقاً.'] },
    { 'en': ['My', 'daughter', 'likes', 'egg', 'yolks.'], 'ar': ['ابنتي', 'تحب', 'صفار', 'البيض.'] },
    { 'en': ['Karim', 'has', 'a', 'significant', 'role', 'in', 'the', 'movie.'], 'ar': ['كريم', 'لديهِ', 'دور', 'هام', 'في', 'الفيلم.'] },
    { 'en': ['Tom', 'moved.'], 'ar': ['لقد', 'تحرك', 'توم.'] },
    { 'en': ['He', 'likes', 'watching', 'TV.'], 'ar': ['إنه', 'يحب', 'مشاهدة', 'التلفاز.'] },
    { 'en': ['I', 'think', 'different', 'from', 'you.'], 'ar': ['أفكّر', 'بشكل', 'مختلف', 'عنك.'] },
    { 'en': ['We', "didn't", 'believe', 'it.'], 'ar': ['لم', 'نصدق', 'ذلك'] },
    { 'en': ['Thank', 'you', 'for', 'coming.'], 'ar': ['شكرًا', 'على', 'قدومك.'] },
    { 'en': ['My', 'father', 'helped', 'me', 'with', 'my', 'homework.'], 'ar': ['ساعدني', 'أبي', 'في', 'حل', 'واجبي.'] },
    { 'en': ['You', 'talk', 'too', 'much.'], 'ar': ['إنك', 'تتكلم', 'كثيراً.'] },
    { 'en': ['Do', 'you', 'like', 'music?'], 'ar': ['أتحب', 'الموسيقى؟'] },
    { 'en': ['I', "can't", 'remember', 'where', 'I', 'parked', 'the', 'car.'], 'ar': ['لا', 'أتذكر', 'اين', 'اوقفت', 'السيارة'] },
    { 'en': ['English', 'is', 'not', 'easy,', 'but', 'it', 'is', 'interesting.'], 'ar': ['اللغة', 'الإنجليزية', 'ليست', 'سهلة', 'و', 'لكنها', 'مثيرة', 'للإهتمام.'] },
    { 'en': ['I', 'never', 'asked', 'you', 'to', 'help', 'me.'], 'ar': ['لم', 'أطلب', 'منك', 'يومًا', 'مساعدتي.'] },
    { 'en': ['You', 'must', 'be', 'more', 'polite.'], 'ar': ['عليك', 'أن', 'تكون', 'مهذباً', 'أكثر.'] },
    { 'en': ['My', 'wife', 'looked', 'surprised.'], 'ar': ['تفاجأت', 'زوجتي.'] },
    { 'en': ['Clean', 'the', 'dirt', 'off', 'the', 'mirror.'], 'ar': ['أزل', 'الغُبار', 'عن', 'المرآة.'] },
    { 'en': ['Children', 'play', 'with', 'toys.'], 'ar': ['يلعب', 'الأولاد', 'باللعب.'] },
    { 'en': ['My', 'father', 'is', 'an', 'electric', 'engineer.'], 'ar': ['أبي', 'مهندس', 'كهربائي.'] },
    { 'en': ['Why', 'is', 'she', 'angry?'], 'ar': ['ما', 'سبب', 'غضبها؟'] },
    { 'en': ['I', 'can', 'speak', 'English.'], 'ar': ['أستطيع', 'تحدث', 'الإنجليزية.'] },
    { 'en': ["He's", 'very', 'honest,', 'so', 'we', 'can', 'depend', 'on', 'him.'], 'ar': ['إنهُ', 'صادق', 'جداً,', 'لذا', 'يمكننا', 'أن', 'نعتمد', 'عليه.'] },
    { 'en': ['No', 'one', 'believed', 'me', 'at', 'first.'], 'ar': ['لم', 'يصدقني', 'أحد', 'في', 'البداية'] },
    { 'en': ['The', 'clock', 'is', 'above', 'the', 'fireplace.'], 'ar': ['الساعة', 'مثبتة', 'فوق', 'الحائط.'] },
    { 'en': ['He', 'always', 'wears', 'blue', 'shirts.'], 'ar': ['دائماً', 'ما', 'يلبس', 'القمصان', 'الزرقاء.'] },
    { 'en': ['I', 'could', 'not', 'come', 'because', 'of', 'the', 'heavy', 'rain.'], 'ar': ['لم', 'أستطع', 'أن', 'آتي', 'بسبب', 'المطر', 'الغزير'] },
    { 'en': ['Every', 'house', 'had', 'a', 'garden.'], 'ar': ['كان', 'لكل', 'بيت', 'حديقة.'] },
    { 'en': ['I', 'saw', 'him', 'enter', 'the', 'room.'], 'ar': ['رأيته', 'يدخل', 'الغرفة.'] },
    { 'en': ['My', 'mother', "can't", 'come.'], 'ar': ['لا', 'يمكن', 'لأمي', 'أن', 'تأتي.'] },
    { 'en': ['When', 'will', 'his', 'new', 'novel', 'come', 'out?'], 'ar': ['متى', 'ستنشر', 'روايته', 'الجديدة؟'] },
    { 'en': ['My', 'friends', 'invited', 'me', 'to', 'dinner.'], 'ar': ['قام', 'أصدقائي', 'بدعوتي', 'إلى', 'العشاء.'] },
    { 'en': ['The', 'hospital', 'had', 'a', 'grand', 'opening', 'last', 'month.'], 'ar': ['افتُتحت', 'المستشفى', 'الشهر', 'الماضي.'] },
    { 'en': ['I', "didn't", 'know', 'she', 'was', 'ill.'], 'ar': ['لم', 'أكن', 'على', 'علم', 'بمرضها.'] },
    { 'en': ["It's", 'over', 'between', 'us.'], 'ar': ['لقد', 'انتهي', 'الأمر', 'بيننا'] },
    { 'en': ['When', 'did', 'Tom', 'graduate', 'from', 'college?'], 'ar': ['متى', 'تخرّج', 'توم', 'من', 'الكلية؟'] },
    { 'en': ['Where', 'did', 'you', 'meet', 'Tom?'], 'ar': ['أين', 'التقيت', 'بتوم', '؟'] },
    { 'en': ['So', 'far', 'everything', 'has', 'been', 'successful.'], 'ar': ['حتى', 'الآن،', 'كل', 'شيء', 'كان', 'ناجحا.'] },
    { 'en': ['Can', 'I', 'use', 'your', 'pencil?'], 'ar': ['هل', 'يمكنني', 'أن', 'أستعمل', 'قلمك', 'الرصاص؟'] },
    { 'en': ['You', 'should', 'apologize.'], 'ar': ['عليك', 'أن', 'تتأسف.'] },
    { 'en': ['I', 'have', 'a', 'weird', 'neighbor.'], 'ar': ['أنا', 'لدي', 'جارة', 'غريبة.'] },
    { 'en': ['We', 'arrived', 'at', 'the', 'office', 'on', 'time.'], 'ar': ['وصلنا', 'إلى', 'المكتب', 'في', 'الوقت', 'المطلوب.'] },
    { 'en': ['I', "can't", 'see', 'it.'], 'ar': ['لا', 'يمكنني', 'رؤيته.'] },
    { 'en': ['Who', 'said', 'that?'], 'ar': ['من', 'قال', 'هذا؟'] },
    { 'en': ['The', 'rain', 'spoiled', 'our', 'picnic.'], 'ar': ['أفسد', 'المطر', 'نزهتنا.'] },
    { 'en': ['Do', 'you', 'mean', 'me?'], 'ar': ['هل', 'تقصدني؟'] },
    { 'en': ["You'll", 'love', 'this.'], 'ar': ['سيروق', 'لك', 'ذلك.'] },
    { 'en': ['Can', 'you', 'speak', 'French?'], 'ar': ['أيمكنك', 'أن', 'تتكلم', 'اللغة', 'الفرنسية؟'] },
    { 'en': ['Tom', 'wanted', 'an', 'economy', 'car.'], 'ar': ['أراد', 'توم', 'سيارةً', 'اقتصادية.'] },
    { 'en': ['How', 'can', 'I', 'help', 'you?'], 'ar': ['كيف', 'يمكنني', 'أن', 'أساعدك؟'] },
    { 'en': ['Is', 'Tom', 'really', 'going', 'to', 'Boston', 'by', 'himself?'], 'ar': ['أحقّا', 'سيذهب', 'توم', 'إلى', 'بوسطن', 'لوحده؟'] },
    { 'en': ['I', 'wonder', 'why', 'Tom', 'said', 'that.'], 'ar': ['أنا', 'أتعجب', 'لماذا', 'قال', 'توم', 'ذلك'] },
    { 'en': ['I', 'prefer', 'swimming', 'to', 'skiing.'], 'ar': ['أنا', 'أفضل', 'السباحة', 'على', 'التزلج.'] },
    { 'en': ['Follow', 'him.'], 'ar': ['اتبعه.'] },
    { 'en': ['I', 'have', 'the', 'ace', 'of', 'clubs.'], 'ar': ['لدي', 'الآس', 'الكوبا.'] },
    { 'en': ['You', "can't", 'afford', 'to', 'neglect', 'your', 'health.'], 'ar': ['لا', 'يسعك', 'أن', 'تهمل', 'صحتك.'] },
    { 'en': ['Perhaps', "that's", 'true.'], 'ar': ['لعل', 'ذلك', 'صحيح.'] },
    { 'en': ['Learn', 'these', 'names', 'by', 'heart.'], 'ar': ['احفظ', 'هذه', 'الأسماء', 'عن', 'ظهر', 'قلب.'] },
    { 'en': ['If', 'you', "don't", 'hurry,', "you'll", 'miss', 'the', 'train.'], 'ar': ['إذا', 'لم', 'تسرع', 'سيفوتك', 'القطار.'] },
    { 'en': ['He', 'lost', 'all', 'of', 'his', 'money.'], 'ar': ['خسر', 'كل', 'ماله.'] },
    { 'en': ['Can', 'we', 'go', 'to', 'the', 'lake', 'and', 'watch', 'the', 'sunrise?'], 'ar': ['هل', 'بإمكاننا', 'الذّهاب', 'إلى', 'البحيرة', 'لمشاهدة', 'شروق', 'الشّمس؟'] },
    { 'en': ['Could', 'you', 'please', 'tell', 'me', 'why', 'you', 'love', 'her?'], 'ar': ['هل', 'يمكنك', 'أن', 'تقول', 'لي', 'لماذا', 'تحبها؟'] },
    { 'en': ['We', 'should', 'have', 'been', 'more', 'careful.'], 'ar': ['كان', 'من', 'المفترض', 'أن', 'نكون', 'حذرين', 'أكثر.'] },
    { 'en': ['The', 'phone', 'rang', 'when', 'I', 'was', 'having', 'lunch.'], 'ar': ['رنّ', 'جرس', 'الهاتف', 'و', 'أنا', 'آكل', 'طعام', 'الغداء.'] },
    { 'en': ['Someone', 'will', 'close', 'the', 'window.'], 'ar': ['سيغلق', 'أحدهم', 'النافذة.'] },
    { 'en': ["I'm", 'thirsty.'], 'ar': ['أنا', 'عطش.'] },
    { 'en': ['I', "can't", 'understand', 'his', 'ideas', 'at', 'all.'], 'ar': ['لا', 'أستطيع', 'فهم', 'أفكاره', 'على', 'الإطلاق.'] },
    { 'en': ['Go', 'and', 'help', 'them,', 'Bud.'], 'ar': ['إذهب', 'ساعدهم', 'يا', 'باد.'] },
    { 'en': ['He', 'got', 'up', 'at', 'eight', 'in', 'the', 'morning.'], 'ar': ['نهض', 'من', 'النوم', 'في', 'الساعة', 'الثامنة', 'صباحاً.'] },
    { 'en': ['What', 'time', 'is', 'it', 'now?'], 'ar': ['ما', 'الساعة', 'الآن؟'] },
    { 'en': ['I', 'hope', 'to', 'see', 'you', 'again.'], 'ar': ['آمل', 'أن', 'أراك', 'مجدداً.'] },
    { 'en': ['All', 'you', 'need', 'to', 'do', 'is', 'wait.'], 'ar': ['كل', 'ما', 'عليك', 'القيام', 'به', 'هو', 'الإنتظار.'] },
    { 'en': ['He', 'is', 'always', 'laughing.'], 'ar': ['دائماً', 'ما', 'أراه', 'ضاحكاً.'] },
    { 'en': ['English', 'has', 'many', 'loan', 'words', 'from', 'French.'], 'ar': ['استعارت', 'اللغة', 'الإنجليزية', 'كلمات', 'كثيرة', 'من', 'الفرنسية.'] },
    { 'en': ['No', 'one', 'will', 'know.'], 'ar': ['لن', 'يعرف', 'أحد.'] },
    { 'en': ['Her', 'garden', 'is', 'a', 'work', 'of', 'art.'], 'ar': ['حديقتها', 'عمل', 'فني.'] },
    { 'en': ['It', 'seems', 'to', 'me', 'that', 'you', 'are', 'honest.'], 'ar': ['يبدو', 'لي', 'أنكَ', 'أمين.'] },
    { 'en': ['The', 'lecture', 'lasted', 'for', 'two', 'hours.'], 'ar': ['استمرت', 'المحاضرة', 'ساعتين.'] },
    { 'en': ['Tom', 'tried', 'to', 'avoid', 'looking', 'at', 'Mary.'], 'ar': ['حاول', 'توم', 'أن', 'يتجنب', 'النظر', 'إلى', 'ماري.'] },
    { 'en': ['It', "wasn't", 'the', 'first', 'time.'], 'ar': ['لم', 'تكن', 'أول', 'مرة.'] },
    { 'en': ['Mind', 'your', 'own', 'business!'], 'ar': ['لا', 'تتدخل', 'في', 'ما', 'لا', 'يعنيك!'] },
    { 'en': ['We', 'can', 'hide', 'in', 'here.'], 'ar': ['يمكننا', 'الاختباء', 'هنا.'] },
    { 'en': ['You', 'must', 'show', 'your', 'passport.'], 'ar': ['يجب', 'أن', 'تريني', 'جواز', 'سفرك.'] },
    { 'en': ['Stop', 'hitting', 'your', 'brother.'], 'ar': ['توقف', 'عن', 'ضرب', 'أخوك.'] },
    { 'en': ['Study', 'hard', 'so', 'you', "don't", 'fail.'], 'ar': ['ادرس', 'بجد', 'كي', 'لا', 'ترسب.'] },
    { 'en': ['I', 'will', 'accompany', 'you.'], 'ar': ['سأقوم', 'بمرافقتك.'] },
    { 'en': ['I', "don't", 'want', 'to', 'work.'], 'ar': ['لا', 'أريد', 'العمل.'] },
    { 'en': ["It's", 'about', 'a', 'ten-minute', 'drive', 'from', 'here.'], 'ar': ['تبعد', 'مسافة', 'عشر', 'دقائق', 'بالسيارة', 'عن', 'هنا.'] },
    { 'en': ['You', 'must', 'study', 'English', 'hard.'], 'ar': ['عليك', 'أن', 'تدرس', 'الإنجليزية', 'بجهد', 'أكبر.'] },
    { 'en': ['You', 'can', 'go', 'if', 'you', 'want.'], 'ar': ['إمكانك', 'الذهاب', 'إذا', 'أردت.'] },
    { 'en': ['This', 'medicine', 'will', 'relieve', 'your', 'headache.'], 'ar': ['من', 'شأن', 'هذا', 'الدواء', 'أن', 'يخفف', 'من', 'شأن', 'حدة', 'صداع', 'رأسك.'] },
    { 'en': ['Prices', 'went', 'up.'], 'ar': ['ارتفعت', 'الأسعار.'] },
    { 'en': ["Let's", 'watch', 'TV.'], 'ar': ['لنشاهد', 'التلفزيون.'] },
    { 'en': ["You're", 'welcome.'], 'ar': ['عفوًا.'] },
    { 'en': ['I', "couldn't", 'catch', 'on', 'to', 'the', 'joke', 'he', 'told', 'us.'], 'ar': ['لم', 'أفهم', 'الطرفة', 'التي', 'قصها', 'علينا.'] },
    { 'en': ['Thanks', 'a', 'lot.', 'I', 'appreciate', 'that.'], 'ar': ['شكراً', 'جزيلاً', '.', 'أنا', 'أقدر', 'هذا.'] },
    { 'en': ['I', 'know', 'his', 'name.'], 'ar': ['أعرف', 'اسمه.'] },
    { 'en': ["I'm", 'no', 'good', 'for', 'you.'], 'ar': ['أنا', 'لست', 'من', 'تبحثين', 'عنه'] },
    { 'en': ['Try', 'it', 'and', 'tell', 'me', 'what', 'it', 'tastes', 'like.'], 'ar': ['ذوقيه', 'و', 'أخبريني', 'عن', 'طعمه.'] },
    { 'en': ['Where', 'can', 'I', 'pick', 'up', 'my', 'ticket?'], 'ar': ['أين', 'يمكنني', 'إستلام', 'تذكرتي؟'] },
    { 'en': ['She', 'lost', 'her', 'way.'], 'ar': ['ضلّت', 'طريقها.'] },
    { 'en': ['I', "don't", 'want', 'to', 'watch', 'that', 'movie.'], 'ar': ['لا', 'أريد', 'مشاهدة', 'ذلك', 'الفيلم'] },
    { 'en': ['She', 'helped', 'the', 'old', 'man', 'cross', 'the', 'road.'], 'ar': ['لقد', 'ساعدتْ', 'العجوز', 'على', 'عبور', 'الطريق.'] },
    { 'en': ['She', 'finished', 'reading', 'the', 'letter.'], 'ar': ['انتهت', 'من', 'قراءة', 'الرسالة.'] },
    { 'en': ['Not', 'all', 'birds', 'can', 'fly.'], 'ar': ['ليس', 'بإمكان', 'جميع', 'الطيور', 'أن', 'تطير.'] },
    { 'en': ['I', 'got', 'scolded', 'severely', 'by', 'that', 'teacher.'], 'ar': ['وبختني', 'تلك', 'المعلمة', 'كثيرًا.'] },
    { 'en': ['I', 'love', 'lasagna.'], 'ar': ['أحب', 'أكل', 'اللازانيا.'] },
    { 'en': ['War', 'broke', 'out', 'in', '1939.'], 'ar': ['اِندلعت', 'الحرب', 'عام', '١٩٣٩', 'ميلادية.'] },
    { 'en': ["You'll", 'find', 'this', 'map', 'very', 'useful.'], 'ar': ['ستجد', 'أن', 'هذه', 'الخريطة', 'مفيدة', 'للغاية.'] },
    { 'en': ['He', 'treated', 'me', 'badly.'], 'ar': ['إنهُ', 'عاملني', 'بسوء.'] },
    { 'en': ['You', 'should', 'go.'], 'ar': ['عليك', 'الذهاب.'] },
    { 'en': ['It', 'is', 'thirty', 'meters', 'in', 'length.'], 'ar': ['طوله', 'ثلاثون', 'مترًا.'] },
    { 'en': ['Everyone', 'attacked', 'my', 'opinion.'], 'ar': ['هاجم', 'الجميع', 'رأيي.'] },
    { 'en': ['It', 'appears', 'to', 'me', 'that', 'you', 'are', 'all', 'mistaken.'], 'ar': ['يبدو', 'لي', 'أن', 'جميعكم', 'مخطئون.'] },
    { 'en': ['We', 'are', 'going', 'to', 'Germany', 'tomorrow.'], 'ar': ['سنذهب', 'إلى', 'ألمانيا', 'غداً.'] },
    { 'en': ['Do', 'I', 'have', 'to', 'answer', 'all', 'of', 'the', 'questions?'], 'ar': ['هل', 'علي', 'أن', 'أجيب', 'علی', 'أسئلتك', 'كلها؟'] },
    { 'en': ['Please', 'show', 'it', 'to', 'me', 'again.'], 'ar': ['من', 'فضلك', 'أرني', 'إياها', 'مرة', 'أخرى.'] },
    { 'en': ["I've", 'just', 'finished', 'watching', 'episode', 'three.'], 'ar': ['انتهيت', 'لتوّي', 'من', 'مشاهدة', 'الحلقة', 'الثالثة.'] },
    { 'en': ["Where's", 'the', 'toilet?'], 'ar': ['أين', 'الحمام؟'] },
    { 'en': ['Do', 'you', 'know', 'which', 'one', 'of', 'those', 'guys', 'is', 'Tom?'], 'ar': ['أتعرف', 'مَن', 'يكون', 'توم', 'من', 'بين', 'هؤلاء؟'] },
    { 'en': ['Are', 'you', 'alone?'], 'ar': ['هل', 'أنت', 'وحدك؟'] },
    { 'en': ['Please', 'forgive', 'me.'], 'ar': ['سامحني', 'من', 'فضلك.'] },
    { 'en': ['Tom', "doesn't", 'think', 'about', 'others.'], 'ar': ['لا', 'يفكّر', 'توم', 'بالآخرين.'] },
    { 'en': ['Why', 'do', 'you', 'think', 'so?'], 'ar': ['لماذا', 'تعتقد', 'ذلك؟'] },
    { 'en': ['Be', 'quiet', 'during', 'the', 'lesson.'], 'ar': ['اِبقَ', 'هادئاً', 'عند', 'شرح', 'الدرس.'] },
    { 'en': ['I', 'love', 'my', 'dad.'], 'ar': ['أنا', 'أحب', 'أبي'] },
    { 'en': ['Jump', 'as', 'high', 'as', 'you', 'can.'], 'ar': ['اقفز', 'بأعلى', 'ما', 'يمكنك.'] },
    { 'en': ["Let's", 'split.'], 'ar': ['دعينا', 'ننقسم.'] },
    { 'en': ["Don't", 'tell', 'anyone.'], 'ar': ['لا', 'تُخبِر', 'أحداً.'] },
    { 'en': ['Tell', 'me', 'what', 'I', 'can', 'do', 'to', 'help.'], 'ar': ['أخبرني', 'ما', 'الذي', 'يمكنني', 'فعله', 'لمساعدتك.'] },
    { 'en': ['He', 'does', 'speak', 'well.'], 'ar': ['إنه', 'يحسن', 'الكلام.'] },
    { 'en': ['We', 'need', 'to', 'do', 'the', 'important', 'things', 'first.'], 'ar': ['نحن', 'بحاجة', 'الى', 'القيام', 'بالاشياء', 'المهمة', 'اولا'] },
    { 'en': ['Maybe', 'he', 'will', 'be', 'a', 'good', 'teacher.'], 'ar': ['لربما', 'أصبح', 'معلماً', 'ماهراً.'] },
    { 'en': ['Have', 'you', 'paid', 'your', 'phone', 'bill?'], 'ar': ['هل', 'سددت', 'فاتورة', 'هاتفك؟'] },
    { 'en': ['This', 'is', 'where', 'he', 'lives.'], 'ar': ['هذا', 'هو', 'المكان', 'الذي', 'يعيش', 'فيه.'] },
    { 'en': ['What', 'do', 'you', 'think', "I've", 'been', 'doing?'], 'ar': ['ماذا', 'تعتقدين', 'أني', 'كنت', 'أفعل؟'] },
    { 'en': ['What', 'time', 'does', 'your', 'watch', 'say', 'it', 'is', 'now?'], 'ar': ['ما', 'الوقت', 'الذي', 'تشير', 'إليه', 'ساعتك', 'الآن؟'] },
    { 'en': ["I'll", 'pay.'], 'ar': ['سأدفع', 'أنا.'] },
    { 'en': ['May', 'I', 'have', 'a', 'word', 'with', 'you?'], 'ar': ['هل', 'تسمح', 'لي', 'بكلمة', 'معك؟'] },
    { 'en': ['I', 'eat', 'here.'], 'ar': ['سآكل', 'هنا.'] },
    { 'en': ['I', 'was', 'supposed', 'to', 'tell', 'Tom', 'where', 'to', 'go.'], 'ar': ['كان', 'من', 'المفترض', 'أن', 'أخبر', 'توم', 'أين', 'عليه', 'الذهاب.'] },
    { 'en': ['Please', 'do', 'what', 'you', 'consider', 'necessary.'], 'ar': ['من', 'فضلك', 'إفعل', 'ما', 'تراهُ', 'ضرورياً.'] },
    { 'en': ["I'm", 'going', 'to', 'be', 'late', 'for', 'the', 'meeting.'], 'ar': ['سأتأخر', 'على', 'الاجتماع.'] },
    { 'en': ['Do', 'it', 'now.'], 'ar': ['افعل', 'ذلك', 'الآن.'] },
    { 'en': ['I', 'work', 'every', 'day', 'except', 'Sunday.'], 'ar': ['أعمل', 'كل', 'يوم', 'إلا', 'يوم', 'الأحد.'] },
    { 'en': ['This', 'has', 'got', 'to', 'mean', 'something.'], 'ar': ['لا', 'بد', 'أن', 'لهذا', 'معنى.'] },
    { 'en': ['Your', 'answer', 'is', 'wrong.'], 'ar': ['إجابتك', 'خاطئة.'] },
    { 'en': ['If', 'you', 'want', 'to', 'succeed', 'in', 'life,', 'work', 'hard.'], 'ar': ['إن', 'أردت', 'النجاح', 'في', 'حياتك', 'فاعمل', 'بجد.'] },
    { 'en': ["He's", 'so', 'young.'], 'ar': ['إنهُ', 'صغير', 'جداً.'] },
    { 'en': ['Look', 'at', 'me.'], 'ar': ['انظر', 'إلي'] },
    { 'en': ["Let's", 'go', 'by', 'bus.'], 'ar': ['لنذهب', 'بالحافلة.'] },
    { 'en': ['The', 'jets', 'took', 'off', 'one', 'after', 'another.'], 'ar': ['أقلعت', 'الطائرات', 'النفاثة', 'واحدة', 'تلو', 'الأخرى.'] },
    { 'en': ['We', "haven't", 'received', 'any', 'help', 'from', 'anyone.'], 'ar': ['لم', 'نلق', 'مساعدة', 'من', 'أحد.'] },
    { 'en': ['I', "don't", 'like', 'her.'], 'ar': ['إنها', 'لا', 'تعجبني.'] },
    { 'en': ["It's", 'up', 'to', 'you', 'to', 'decide.'], 'ar': ['الأمر', 'يعود', 'إليك.'] },
    { 'en': ['Tom', 'asked', 'me', 'if', 'I', 'knew', 'where', 'Mary', 'lived.'], 'ar': ['سألني', 'توم', 'إن', 'كنت', 'أعرف', 'أين', 'كانت', 'تعيش', 'ماري.'] },
    { 'en': ['He', 'is', 'poor,', 'but', 'happy.'], 'ar': ['إنه', 'فقير،', 'لكنه', 'بالرغم', 'من', 'ذلك', 'سعيد.'] },
    { 'en': ['We', 'need', 'the', 'rain.'], 'ar': ['نحن', 'نحتاج', 'إلي', 'المطر.'] },
    { 'en': ["I'm", 'listening', 'to', 'the', 'radio.'], 'ar': ['إنني', 'أستمع', 'إلى', 'المذياع.'] },
    { 'en': ["Tom's", 'next', 'crime', 'will', 'be', 'his', 'last.'], 'ar': ['جريمة', 'توم', 'التالية', 'ستكون', 'الأخيرة.'] },
    { 'en': ['She', 'has', 'sunglasses.'], 'ar': ['لديها', 'نظارة', 'شمسية.'] },
    { 'en': ['I', 'think', "you're", 'making', 'a', 'big', 'mistake.'], 'ar': ['أعتقد', 'أنك', 'تعمل', 'خطأً', 'كبير'] },
    { 'en': ['Success', 'in', 'life', 'calls', 'for', 'constant', 'efforts.'], 'ar': ['النجاح', 'في', 'الحياة', 'يتطلب', 'جهودًا', 'متواصلة.'] },
    { 'en': ['Open', 'your', 'mouth!'], 'ar': ['إفتح', 'فمك!'] },
    { 'en': ['I', 'saw', 'him', 'playing', 'baseball.'], 'ar': ['رأيته', 'يلعب', 'كرة', 'القاعدة.'] },
    { 'en': ['War', 'suddenly', 'broke', 'out.'], 'ar': ['اِندلعت', 'الحرب', 'فجأة.'] },
    { 'en': ['We', 'received', 'word', 'of', 'his', 'death.'], 'ar': ['لقد', 'تلقينا', 'خبر', 'وفاته.'] },
    { 'en': ["I'll", 'sue', 'you.'], 'ar': ['سأقاضيك.'] },
    { 'en': ['You', 'can', 'read', 'anything', 'you', 'want.'], 'ar': ['تستطيع', 'قراءة', 'اي', 'شيء', 'تريد.'] },
    { 'en': ['Tom', 'knows', 'Mary,', "doesn't", 'he?'], 'ar': ['يعرف', 'توم', 'ماري', '،', 'أليس', 'كذلك؟'] },
    { 'en': ['My', 'children', 'are', 'a', 'blessing.'], 'ar': ['أولادي', 'نعمة.'] },
    { 'en': ['How', 'about', 'you?'], 'ar': ['ماذا', 'عنك؟'] },
    { 'en': ['I', 'wish', 'to', 'be', 'a', 'doctor.'], 'ar': ['أتمنى', 'أن', 'أكون', 'طبيبا.'] },
    { 'en': ['Tom', 'told', 'me', 'that', 'we', 'were', 'out', 'of', 'time.'], 'ar': ['أخبرني', 'توم', 'أن', 'الوقت', 'نَفَذَ', 'منّا.'] },
    { 'en': ['Tom', 'had', 'to', 'make', 'a', 'decision.'], 'ar': ['توجب', 'على', 'توم', 'أن', 'يتخذ', 'قرارا.'] },
    { 'en': ['Look', 'out!', "There's", 'a', 'truck', 'coming!'], 'ar': ['انتبه!', 'هناك', 'شاحنة', 'قادمة!'] },
    { 'en': ['How', 'do', 'you', 'feel', 'about', 'that', 'now?'], 'ar': ['ما', 'رأيك', 'في', 'ذلك', 'الآن؟'] },
    { 'en': ['She', 'still', 'depends', 'on', 'her', 'parents.'], 'ar': ['ما', 'زالت', 'تعتمد', 'على', 'والديها.'] },
    { 'en': ['That', "won't", 'work!'], 'ar': ['لن', 'يصلح', 'ذلك!'] },
    { 'en': ['Stop', 'trying', 'to', 'cheer', 'me', 'up.'], 'ar': ['توقف', 'عن', 'محاولة', 'إبهاجي.'] },
    { 'en': ['Who', 'invented', 'this', 'machine?'], 'ar': ['من', 'اخترع', 'هذه', 'الآلة؟'] },
    { 'en': ['You', "haven't", 'changed', 'at', 'all.'], 'ar': ['أنت', 'لم', 'تتغير', 'مُطلقاً.'] },
    { 'en': ['Complaining', "won't", 'change', 'anything.'], 'ar': ['لن', 'يغير', 'التذمر', 'شيئاً.'] },
    { 'en': ['She', 'ironed', 'his', 'shirts.'], 'ar': ['كوت', 'قمصانه.'] },
    { 'en': ['You', 'can', 'dance,', "can't", 'you?'], 'ar': ['يمكنك', 'الرقص،', 'أليس', 'كذلك؟'] },
    { 'en': ['Place', 'it', 'wherever', 'you', 'like.'], 'ar': ['ضعها', 'في', 'أي', 'مكان', 'تشاء.'] },
    { 'en': ["Don't", 'look', 'back.'], 'ar': ['لا', 'تنظر', 'وراءك.'] },
    { 'en': ['Are', 'you', 'through', 'with', 'the', 'phone?'], 'ar': ['هل', 'انتهيت', 'من', 'استعمال', 'الهاتف؟'] },
    { 'en': ['Come', 'again.'], 'ar': ['تعالَ', 'مُجدداً.'] },
    { 'en': ['Let', 'me', 'have', 'a', 'look', 'at', 'the', 'picture.'], 'ar': ['دعني', 'ألقي', 'نظرة', 'على', 'الصورة.'] },
    { 'en': ['I', 'am', 'coming.'], 'ar': ['أنا', 'آتٍ'] },
    { 'en': ['I', 'hate', 'politics.'], 'ar': ['أكره', 'السياسة.'] },
    { 'en': ['She', 'can', 'speak', 'Japanese.'], 'ar': ['يمكنها', 'تحدث', 'اليابانيّة.'] },
    { 'en': ['Would', 'you', 'like', 'to', 'dance', 'with', 'me?'], 'ar': ['هل', 'ترغب', 'في', 'الرقص', 'معي؟'] },
    { 'en': ["Here's", 'my', 'wallet.'], 'ar': ['ها', 'هي', 'محفظتي.'] },
    { 'en': ['Is', 'it', 'still', 'raining?'], 'ar': ['أما', 'زالت', 'تمطر؟'] },
    { 'en': ['Are', 'you', 'meeting', 'someone', 'here?'], 'ar': ['هل', 'ستلتقي', 'بأحد', 'هنا؟'] },
    { 'en': ["It's", 'too', 'hot.'], 'ar': ['الجو', 'حار', 'جداً.'] },
    { 'en': ["I'm", 'looking', 'for', 'a', 'gift', 'for', 'my', 'father.'], 'ar': ['أبحث', 'عن', 'هدية', 'لأبي.'] },
    { 'en': ['Call', 'me.'], 'ar': ['هاتفني.'] },
    { 'en': ["I'm", 'very', 'thirsty.'], 'ar': ['أنا', 'عطشانٌ', 'جداً.'] },
    { 'en': ['I', 'have', 'a', 'lot', 'to', 'do', 'today.'], 'ar': ['لدي', 'الكثير', 'لأفعله', 'اليوم.'] },
    { 'en': ['I', 'must', 'help', 'my', 'mother.'], 'ar': ['علي', 'أن', 'أساعد', 'أمي.'] },
    { 'en': ["That's", 'happened', 'to', 'me', 'before.'], 'ar': ['حدث', 'ذلك', 'لي', 'سابقًا.'] },
    { 'en': ['California', 'is', 'famous', 'for', 'its', 'fruit.'], 'ar': ['اشتهرت', 'كاليفورنيا', 'بفواكهها.'] },
    { 'en': ['What', 'time', 'do', 'you', 'leave', 'your', 'work?'], 'ar': ['في', 'أي', 'وقت', 'تخرج', 'من', 'عملك؟'] },
    { 'en': ['What', 'does', 'this', 'stand', 'for?'], 'ar': ['إلامَ', 'يرمز', 'ذلك؟'] },
    { 'en': ['I', 'hear', 'the', 'drum.'], 'ar': ['أسمع', 'الطبول.'] },
    { 'en': ['Who', 'knows', 'what', 'might', 'happen', 'tomorrow?'], 'ar': ['من', 'يعلم', 'ما', 'قد', 'يحص', 'غدًا؟'] },
    { 'en': ['Would', 'you', 'please', 'pay', 'attention?'], 'ar': ['هلّا', 'أصغيتم', 'رجاءً؟'] },
    { 'en': ["What's", 'for', 'dinner?'], 'ar': ['ماذا', 'للعشاء؟'] },
    { 'en': ['She', 'wept', 'bitterly.'], 'ar': ['بكت', 'بمرارة.'] },
    { 'en': ['Tom', 'looks', 'pale.'], 'ar': ['يبدو', 'توم', 'شاحب', 'الوجه.'] },
    { 'en': ['I', 'made', 'a', 'nightmarish', 'mistake.'], 'ar': ['ارتكبت', 'خطأً', 'فادحاً.'] },
    { 'en': ['An', 'accident', 'just', 'happened.'], 'ar': ['حصل', 'حادث', 'للتو.'] },
    { 'en': ['I', 'need', 'more', 'time', 'to', 'finish', 'my', 'homework.'], 'ar': ['أحتاج', 'لمزيد', 'من', 'الوقت', 'لأنهي', 'واجبي.'] },
    { 'en': ['They', 'claimed', 'that', "he'd", 'killed', 'her.'], 'ar': ['زعموا', 'أنه', 'قتلها.'] },
    { 'en': ['Our', 'band', 'needs', 'a', 'distinctive', 'logo.'], 'ar': ['تحتاج', 'فرقتنا', 'لرمز', 'يميّزها.'] },
    { 'en': ['What', 'did', 'you', 'do', 'on', 'your', 'vacation?'], 'ar': ['ماذا', 'فعلت', 'في', 'إجازتك؟'] },
    { 'en': ['I', 'need', 'to', 'speak', 'with', 'you', 'right', 'away.'], 'ar': ['احتاج', 'ان', 'اتحدث', 'معك', 'الآن.'] },
    { 'en': ['He', 'painted', 'the', 'door', 'blue.'], 'ar': ['طلى', 'الباب', 'باللون', 'الأزرق.'] },
    { 'en': ['I', 'have', 'a', 'runny', 'nose.'], 'ar': ['أنفي', 'يسيل.'] },
    { 'en': ['He', 'will', 'always', 'be', 'with', 'you.'], 'ar': ['هو', 'سيكون', 'دائماً', 'معك.'] },
    { 'en': ['I', 'bought', 'this', 'yesterday.'], 'ar': ['اشتريته', 'بالأمس.'] },
    { 'en': ["Let's", 'hope', 'it', 'works.'], 'ar': ['دعنا', 'نأمل', 'أنها', 'تعمل.'] },
    { 'en': ['Being', 'very', 'tired,', 'I', 'went', 'to', 'bed', 'early.'], 'ar': ['كنت', 'متعباً', 'فخلدت', 'إلى', 'النوم', 'باكراً.'] },
    { 'en': ['Stand', 'back,', 'please.'], 'ar': ['ارجع', 'إلى', 'الوراء', 'من', 'فضلك.'] },
    { 'en': ['What', 'do', 'you', 'like?'], 'ar': ['ماذا', 'تُفضّل؟'] },
    { 'en': ['This', 'flag', 'is', 'very', 'pretty.'], 'ar': ['هذا', 'العلم', 'جميل', 'جدا.'] },
    { 'en': ['I', 'know', 'what', 'it', 'is', "you're", 'looking', 'for.'], 'ar': ['أنا', 'أعرف', 'ما', 'الذي', 'تبحث', 'عنهُ.'] },
    { 'en': ['Are', 'you', 'all', 'ready?'], 'ar': ['هل', 'الجميع', 'جاهز؟'] },
    { 'en': ['My', 'dog', 'goes', 'everywhere', 'with', 'me.'], 'ar': ['يرافقني', 'كلبي', 'في', 'كل', 'مكان', 'أذهب', 'إليه.'] },
    { 'en': ["Let's", 'play', 'baseball', 'when', 'the', 'rain', 'stops.'], 'ar': ['لنلعب', 'كرة', 'القاعدة', 'حين', 'يتوقف', 'المطر.'] },
    { 'en': ['I', 'told', 'them', 'to', 'stay', 'in', 'their', 'room.'], 'ar': ['لقد', 'قلت', 'لهم', 'أن', 'يبقوا', 'في', 'غرفتهم.'] },
    { 'en': ['He', 'is', 'not', 'a', 'smoker.'], 'ar': ['إنه', 'ليس', 'مدخنا.'] },
    { 'en': ['I', 'would', 'like', 'to', 'hear', 'your', 'voice,', 'too.'], 'ar': ['أودّ', 'سماع', 'صوتك', 'أيضاً.'] },
    { 'en': ['I', 'saw', 'the', 'man', 'jump.'], 'ar': ['رأيت', 'الرجل', 'يقفز.'] },
    { 'en': ['I', 'no', 'longer', 'love', 'him.'], 'ar': ['لم', 'أعد', 'أحبه.'] },
    { 'en': ['My', 'brother', 'likes', 'music.'], 'ar': ['أخي', 'يحب', 'الموسيقى.'] },
    { 'en': ['I', 'am', 'here.'], 'ar': ['أنا', 'هنا'] },
    { 'en': ['They', 'are', 'both', 'in', 'the', 'room.'], 'ar': ['هما', 'في', 'الغرفة.'] },
    { 'en': ['What', 'are', 'you', 'punishing', 'them', 'for?'], 'ar': ['ما', 'الذي', 'تعاقبهم', 'عليه؟'] },
    { 'en': ['Pick', 'your', 'favorite', 'bat.'], 'ar': ['اختر', 'مضربك', 'المفضل.'] },
    { 'en': ['When', 'did', 'you', 'get', 'married?'], 'ar': ['متى', 'تزوجت؟'] },
    { 'en': ['No', 'one', 'comes', 'to', 'visit', 'me', 'anymore.'], 'ar': ['لا', 'أحد', 'يأتي', 'لزيارتي', 'بعد', 'الآن.'] },
    { 'en': ["It's", 'more', 'difficult', 'than', 'you', 'think.'], 'ar': ['إنه', 'أصعب', 'مما', 'تظن.'] },
    { 'en': ['If', "you've", 'got', 'something', 'to', 'say,', 'just', 'say', 'it.'], 'ar': ['إن', 'كان', 'لديك', 'ما', 'تقول،', 'فقُله.'] },
    { 'en': ['My', 'friend', "doesn't", 'play', 'tennis.'], 'ar': ['صديقتي', 'لا', 'تلعب', 'كرة', 'المضرب.'] },
    { 'en': ["She's", 'not', 'a', 'doctor.'], 'ar': ['إنها', 'ليست', 'طبيبة.'] },
    { 'en': ['A', 'friend', 'of', 'mine', 'is', 'studying', 'abroad.'], 'ar': ['لي', 'صديق', 'يدرس', 'في', 'الخارج.'] },
    { 'en': ['I', 'gave', 'some', 'books', 'to', 'him.'], 'ar': ['أعطيت', 'بعض', 'الكتب', 'له.'] },
    { 'en': ['I', 'miss', 'you.'], 'ar': ['افتقدتك.'] },
    { 'en': ["I'm", 'not', 'busy.'], 'ar': ['لست', 'مشغولاً.'] },
    { 'en': ['I', "don't", 'believe', 'it!'], 'ar': ['لا', 'يمكنني', 'تصديق', 'ذلك!'] },
    { 'en': ['The', 'problem', 'is', 'too', 'difficult', 'to', 'solve.'], 'ar': ['هذه', 'المشكلة', 'صعبة', 'الحل.'] },
    { 'en': ['I', 'knit', 'every', 'day.'], 'ar': ['أحيك', 'كل', 'يوم.'] },
    { 'en': ['Was', 'it', 'cloudy', 'in', 'Tokyo', 'yesterday?'], 'ar': ['هل', 'كانت', 'سماء', 'طوكيو', 'ملبدة', 'بالغيوم', 'البارحة؟'] },
    { 'en': ['The', 'computer', 'is', 'new.'], 'ar': ['الحاسوب', 'جديد.'] },
    { 'en': ['Tom', "wasn't", 'here', 'yesterday.'], 'ar': ['لم', 'يكن', 'توم', 'هنا', 'البارحة.'] },
    { 'en': ['I', 'want', 'to', 'know', 'why', 'you', 'did', 'that.'], 'ar': ['أودّ', 'معرفة', 'سبب', 'فعلك', 'لذلك.'] },
    { 'en': ['I', 'have', 'a', 'stomachache.'], 'ar': ['لدي', 'مغص.'] },
    { 'en': ['Please', 'help', 'me', 'fill', 'out', 'this', 'form.'], 'ar': ['الرجاء', 'مساعدتي', 'في', 'ملء', 'هذه', 'الإستمارة.'] },
    { 'en': ['I', 'agree', 'with', 'you.'], 'ar': ['أتفق', 'معك.'] },
];