/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
import axios from 'axios';
import { toast } from 'react-toastify';
import { create } from 'zustand';
import { decode, encode } from 'base-64';
import { Navigate } from 'react-router-dom';
import sold from './assets/sold.png';
const API_URL = `${process.env.REACT_APP_AUTH}/`;
export const soldPng = sold;
const rand = Math.random();

export const userStore = create((set, get, use) => {
  return {
    set,
    get,
    senderName: '',
    userId: 0,
    socketId: '',
    sessionId: '',
    connected: false,
    items: [],
    language: 'en',
    messages: [],
    myoffers: [],
    notifications: [],
    redirect: false,
    email: '',
    newmessage: false,
    chatmessages: [],
    errorMsgConnection: '',
    socketStatus: '',
    password: '',
    firstname: '',
    lastname: '',
    country: '',
    currency: '',
    city: '',
    theme: 'dark',
    privateChats: [],
    newPrivateChat: [],
    image: '',
    loggedIn: false,
    error: '',
    color: rand < 0.33 ? 'red' : rand < 0.66 ? 'blue' : 'green',
  };
});
export const logout = () => {
  let data = axios.get(API_URL + 'logout');
  sessionStorage.removeItem('session');
  sessionStorage.removeItem('data');
  userStore.setState({
    loggedIn: false,
    sessionId: '',
    email: '',
    name: '',
    image: '',
  });
};
export const getCurrentUser = () => {
  if (localStorage.getItem('data') && JSON.parse(DecodeNow(localStorage.getItem('data')))) {
    let data = JSON.parse(DecodeNow(localStorage.getItem('data')));
    let values = {
      userId: data?.userId,
      firstname: data?.firstname,
      lastname: data?.lastname,
      senderName: data?.senderName || `${data?.firstname} ${data?.lastname}`,
      email: data?.email,
      image: data?.image,
      token: data?.token,
      loggedIn: true,
      redirect: true,
      roles: data?.roles || [],
    };
    userStore.setState(values);
    return values;
  } else {
    userStore.setState({
      userId: 0,
      firstname: '',
      lastname: '',
      senderName: '',
      image: '',
      email: '',
      token: '',
      loggedIn: false,
      redirect: false,
      roles: [],
    });
    return '';
  }
};

//
export const getItems = (page = 0, limit = 10, user_id = false) => {
  return new Promise((resolve) => {
    axios
      .get(`${API_URL}getitems?page=${page}${user_id ? `&user_id=${user_id}` : ''}`)
      .then((response) => {
        userStore.setState({ items: response.data });
        resolve(response.data);
      });
  });
};
export const login = (email, password) => {
  axios.post(API_URL + 'login', { email, password }).then((response) => {
    let encoded = encode(
      JSON.stringify({
        token: response.token,
        email: response.email,
        userId: response.user_id,
        loggedIn: true,
        redirect: true,
        sessionId: JSON.stringify(response.user_id),
        name: response?.name || `${response?.firstname} ${response?.lastname}`,
        senderName: response?.name || `${response?.firstname} ${response?.lastname}`,
        country: response?.country || '',
        city: response?.city || '',
        image: response?.image || '',
      })
    );
    sessionStorage.setItem('data', encoded);
    localStorage.setItem('data', encoded);
    userStore.setState({
      token: response.token,
      email: response.email,
      userId: response.user_id,
      loggedIn: true,
      redirect: true,
      sessionId: JSON.stringify(response.user_id),
      name: response?.name || `${response?.firstname} ${response?.lastname}`,
      senderName: response?.name || `${response?.firstname} ${response?.lastname}`,
      country: response?.country || '',
      city: response?.city || '',
      image: response?.image || '',
    });
    return response.data;
  });
};
export const signup = (name, email, password, token) => {
  axios.post(API_URL + 'signup', { name, email, password, token }).then((response) => {
    userStore.setState({ name: response.name || '', redirect: true });
    return response.data || [];
  });
};
export const changepic = (image, user_id) => {
  return new Promise((resolve) => {
    axios.put(`${API_URL}profilePic`, { image: image, user_id: user_id }).then((response) => {
      resolve(response.data);
    });
  });
};
export const changePrice = (item_id, price) => {
  return axios
    .post(`${API_URL}changeprice`, { price: price, item_id: item_id })
    .then((response) => {
      userStore.setState({ image: response[0].image });
      return response.data || [];
    });
};
export const getMessages = () => {
  return axios
    .post(API_URL + 'messages?limit=100', { id: userStore.getState().userId })
    .then((response) => {
      userStore.setState({ messages: response?.data || [] });
      return response?.data;
    });
};
export const getOffers = (user_id, page = 0, limit = 10) => {
  return new Promise((resolve) => {
    return axios
      .post(`${API_URL}myoffers?limit=${limit}&page=${page}`, { user_id: user_id })
      .then((response) => {
        userStore.setState({ myoffers: response?.data || [] });
        resolve(response?.data);
      });
  });
};
export const getNotifications = () => {
  return axios
    .post(API_URL + 'notifications', { user_id: userStore.getState().userId })
    .then((response) => {
      userStore.setState({ notifications: response?.data || [] });
      return response?.data || [];
    });
};

export const getSeller = (user_id) => {
  return new Promise((resolve) => {
    return axios.post(API_URL + 'getseller', { user_id: user_id }).then((response) => {
      resolve(response?.data);
    });
  });
};
export const searchNow = (query, page = 0, limit = 10) => {
  return new Promise((resolve) => {
    return axios
      .post(`${process.env.REACT_APP_AUTH}/search?q=${query}&page=${page}&limit=${limit}`)
      .then((data) => resolve(data.data || []));
  });
};

export const addItem = (data) => {
  return new Promise((resolve) => {
    axios.post(API_URL + 'sell', data).then((x) => {
      return resolve(x?.data);
    });
  });
};
export const updateItem = (data) => {
  return new Promise((resolve) => {
    axios.post(API_URL + 'updateitem', data).then((x) => {
      return resolve(x?.data);
    });
  });
};

export const getMyItems = (userId, page = 0, limit = 10) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_AUTH}/getpost?page=${page}&limit=${limit}`, { id: userId })
      .then((x) => {
        return resolve(x?.data);
      });
  });
};

export const getOneItem = (item_id) => {
  return new Promise((resolve) => {
    axios.get(`${process.env.REACT_APP_AUTH}/getitem/${item_id}`).then((data) => {
      resolve(data?.data);
    });
  });
};
export const offerResponseSubmit = (user_id, offer_id, response) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_AUTH}/offeranswer`, {
        user_id,
        offer_id,
        response,
      })
      .then((data) => {
        resolve(data?.data);
      });
  });
};
export const DecodeNow = (base64data) => {
  try {
    let decoded = JSON.parse(decode(base64data));
    return decoded;
  } catch (error) {
    return false;
  }
};

export const EncodeNow = (text) => {
  let encoded = encode(JSON.stringify(text));
  return encoded;
};

export const syncCache = (data) => {
  let encoded = EncodeNow(JSON.stringify(data));
  sessionStorage.setItem('data', encoded);
  localStorage.setItem('data', encoded);
};

export const create_slug = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\u0621-\u064A0-9-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};
