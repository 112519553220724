import React, { useEffect, useState } from 'react';

/**
 * CustomModal
 * @param {props} props
 * const { title1, details1, id, steps, actionone, actiontwo } = props;
 * @returns
 */
export default function CustomModal(props) {
  const { title1, details1, title2, details2, id, steps, actionone, actiontwo, btnText, status } =
    props;
  const [classid, setclassid] = useState('modal-details');
  useEffect(() => {
    if (props.classid) {
      setclassid(props.classid);
    }
  }, []);

  return (
    <>
      <div
        className={'modal hide text-black fade'}
        id={`${classid}`}
        data-bs-keyboard="false"
        data-bs-backdrop="true"
        aria-hidden="true"
        aria-labelledby={`#${classid}Label`}
      >
        <div className="modal-dialog  modal-xl">
          <div className="modal-content" style={{ zIndex: '9999' }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={`${classid}Label`}>
                {title1 || ''}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{details1}</div>
            {steps && (
              <div className="modal-footer">
                <button
                  className="button-85"
                  data-bs-target={`#${classid}2`}
                  data-bs-toggle="modal"
                >
                  {btnText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal"
        id={`${classid}2`}
        aria-hidden="true"
        aria-labelledby={`#${classid}Label2`}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: '9999' }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={`${classid}Label2`}>
                {title2 || ''}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="content">{details2}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
