import React, { useEffect, useState } from 'react';
import { getOneItem, offerResponseSubmit, userStore } from '../../../core/userStore';
import { translator } from '../../../core/languages/translator';
import { Link } from 'react-router-dom';
import Loading from '../../../core/Loading';

export default function ItemStatusUpdate(props) {
  const [item, setItem] = useState([]);
  const [info, setinfo] = useState([]);
  const [loaded, setloading] = useState(false);
  const userId = userStore((s) => s.userId);
  useEffect(() => {
    setinfo(props?.info)
    getOneItem(props?.itemid).then((x) => setItem(x));
    setloading(true);
   }, [props]);

  const offerResponse = async (response, e) => {
    e.preventDefault();
    await offerResponseSubmit(userId, props?.offerid, response);
    window.location.reload(false);
  };
  if (item[0]?.user_id !== userId) {
    return <></>;
  }
  if(!loaded) return <Loading />
  return  (
    <div className="m-5 p-2">
      {' '}
      <div className="col-6">
        <button
          style={{ width: '100%' }}
          type="button"
          data-bs-dismiss="modal"
          className=" button-85  mt-2"
          onClick={(e) => offerResponse('accepted', e)}
        >
          {translator('Accept')} `{props?.price} {props?.info?.currency}`
        </button>
      </div>
      <div className="col-6">
        <button
          style={{ width: '100%' }}
          type="button"
          data-bs-dismiss="modal"
          className=" button-85 mt-2"
          onClick={(e) => offerResponse('rejected', e)}
        >
          {translator('Reject')} `{props?.price} {props?.info?.currency}`
        </button>
      </div>
    </div>
  );
}
