// eslint-disable-next-line react/prop-types
import { en } from './english';
import { ar } from './arabic';
import { userStore } from '../userStore';
import {fullLangs} from './langs'

function searchNestedValue(obj, valueToFind, language) {
  let foundParent = null;
  let found = false;
  let id = null;

  const search = (currentObj, parentObj = null) => {
    if (currentObj === null || typeof currentObj !== 'object') {
      return;
    }

    for (const key in currentObj) {
      if (currentObj[key] === valueToFind) {
        foundParent = parentObj;
        found = true;
        id = key;
        return;
      }
      if (typeof currentObj[key] === 'object') {
        search(currentObj[key], currentObj);
        if (found) {
           return;
        }
      }
    }
  };

  search(obj);
  return foundParent !== null ? foundParent[language] ? foundParent[language][id] ? foundParent[language][id] : foundParent[language][0] : valueToFind : valueToFind;
}

function translateAll(str, language) {
  //  create a dictionary
 const find = searchNestedValue(fullLangs, str?.toLowerCase(), language)
  // const find = transLangs?.findIndex(x => x?.en === str?.toLowerCase());
  // const final = find > -1 ? transLangs[find][language] : str;
  return find;
}

export const translator = (text, lang = false) => {
  let language = lang || userStore.getState().language;
  if (text && typeof text === 'string') {
    const res =  translateAll(text, language);
    if(res){
      return res
    }else{
      text
    }
  } else {
    return text;
  }
};
